import React from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import range from "lodash/range";
import {monthsRu as months} from '@utils/form';
import {shortDate} from "@utils/date";

const CustomDatePicker = ({
                              value = new Date(), onChange, name = 'DatePicker', maxDate = null, minDate = null,
                              placeholderText, dateFormat, className = '', onBlur, inputKey = '', disabled = false
                          }) => {
    const newMinDate = minDate ? new Date(minDate) : null;
    const newMaxDate = maxDate ? new Date(maxDate) : null;
    const years = range(newMinDate ? newMinDate.getFullYear() : 1900, newMaxDate ? newMaxDate.getFullYear() + 1 : getYear(new Date()));

    return (
        <DatePicker
            renderCustomHeader={({
                                     date,
                                     changeYear,
                                     decreaseMonth,
                                     increaseMonth,
                                     prevMonthButtonDisabled,
                                     nextMonthButtonDisabled
                                 }) => <div className="react-datepicker__select-group">
                <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                    aria-label="Previous Month"
                >
                    Previous Month
                </button>
                <div className="react-datepicker__selected_month">
                    {months[getMonth(date)]}
                </div>
                <select
                    value={getYear(date)}
                    onChange={({target: {value}}) => changeYear(value)}
                    className="react-datepicker__select react-datepicker__select_years"
                >
                    {years.map(option => <option key={option} value={option}>{option}</option>)}
                </select>
                <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    type="button"
                    className="react-datepicker__navigation react-datepicker__navigation--next"
                    aria-label="Next Month"
                >
                    Next Month
                </button>
            </div>
            }
            key={inputKey}
            selected={Date.parse(value)}
            onChange={onChange ? date => onChange({target: {name: name, value: shortDate(date)}}) : null}
            locale={ru}
            maxDate={newMaxDate || new Date()}
            minDate={newMinDate || new Date("01-01-1900")}
            placeholderText={placeholderText}
            dateFormat={dateFormat}
            dropDownMode="select"
            popperPlacement="bottom-start"
            className={className}
            onBlur={onBlur}
            onChangeRaw={e => e.preventDefault()}
            disabled={disabled}
        />
    );
}

export default CustomDatePicker;
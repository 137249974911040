const initialReducerData = (profileData = {}) => {
    const {legal_form, legal_form_type, user} = profileData;
    const {
        fio, birth_date, inn, birth_place, address_matches, registration_address,
        residence_address, ogrnip, without_patronymic, snils, position
    } = legal_form?.personal || {};

    const fieldPosition = legal_form_type === 'entity' ? {position: position || ''} : {};
    const snilsField = user?.roles[0]?.name === 'borrower' && legal_form_type !== 'indiv' ? {snils: snils || ''} : {};
    const fieldOgrnip = legal_form_type === 'ie' ? {ogrnip: ogrnip || ''} : {};
    const fieldForBorrower = user?.roles[0]?.name === 'borrower' && legal_form_type !== 'indiv'
        ? {
            residence_address: address_matches ? registration_address || '' : residence_address || '',
            address_matches: !!address_matches
        }
        : {}

    const initData = {
        fio: fio || '',
        without_patronymic: without_patronymic || false,
        birth_date: birth_date || '',
        phone: profileData.phone || '',
        phone_code: '',
        ...fieldPosition,
        ...fieldOgrnip,
        ...snilsField,
        inn: inn || '',
        birth_place: birth_place || '',
        registration_address: registration_address || '',
        ...fieldForBorrower
    }

    return initData
}

const getPersonalDataToDisplay = (data, qualifiedApplication, forInnerUser) => {
    if (!Object.keys(data || {}).length) return []

    const {legal_form, user, phone, legal_form_type, id} = data || {};
    const {
        fio, birth_date, inn, birth_place, registration_address,
        residence_address, ogrnip, address_matches, snils, position
    } = legal_form?.personal || {};

    return [
        ...(qualifiedApplication
                ? [{
                    name: 'ID профиля',
                    desc: id || '',
                    type: 'link',
                    link: `/client_account/${id}`
                }]
                : []
        ),
        {name: 'ФИО', desc: fio || ''},
        {name: 'Дата рождения', desc: birth_date || ''},
        {name: 'Телефон', desc: phone || ''},
        {name: legal_form_type === 'entity' ? 'Должность' : null, desc: position || ''},
        {name: legal_form_type === 'ie' ? 'ОГРНИП' : null, desc: ogrnip || ''},
        {name: user.roles[0].name === 'borrower' && legal_form_type !== 'ie' ? 'СНИЛС' : null, desc: snils || ''},
        {name: 'ИНН', desc: inn || ''},
        {name: 'Место рождения', desc: birth_place || ''},
        {name: 'Адрес регистрации по месту жительства', desc: registration_address || ''},
        ...(user.roles[0].name === 'borrower' && legal_form_type !== 'indiv'
                ? [{
                    name: 'Адрес фактического пребывания',
                    desc: address_matches ? registration_address : residence_address || '',
                }]
                : []
        ),
    ]
}

export {
    initialReducerData,
    getPersonalDataToDisplay
}
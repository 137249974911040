import React from 'react';
import {Button} from "reactstrap";
import {useSelector} from "react-redux";
import RemarksNotice from "@shared/components/account/accStatusNotice/general/RemarksNotice";

const ProfileAccreditedReturnedNotice = ({profileDataEditingStatus, setFormEditStatus}) => {
    const remarks = useSelector(state => state.profiles.profileData?.accreditation?.remarks || []);

    return (
        <div className="accStatusNotice">
            <div className="accStatusNotice__header">
                <p>Аккредитация профиля</p>
            </div>
            <div className="accStatusNotice__content">
                <p>
                    Ваш профиль не прошел проверку. Доступ к сервисам платформы ограничен.
                    Внесите изменения в соответствии с замечаниями, а затем подтвердите данные.
                </p>
            </div>
            <RemarksNotice data={remarks}/>
            <div className="accStatusNotice__footer">
                {
                    !profileDataEditingStatus &&
                    <div className="confirmQualifiedInvestor__btnGroup">
                        <Button
                            color="primary"
                            size="lg"
                            onClick={setFormEditStatus}
                        >
                            Внести изменения и подтвердить
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProfileAccreditedReturnedNotice;
import React from 'react';
import logoPdf from '@shared/img/pdf/logo_pdf.png';
import {fonts, requisitesStyles} from './helpers';

const AccountReplenishmentDetails = ({QRCode = null}) => {
    const styles = StyleSheet.create(requisitesStyles);
    /*Font.register(fonts);*/

    /*const MyDoc = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    <Image style={styles.image} src={logoPdf} alt="logo"/>
                    <Text style={styles.title}>Пополнение счета</Text>
                    <Text style={styles.subtitle}>Реквизиты платформы INCROWD</Text>
                </View>
                <View style={styles.list}>
                    <View style={styles.listGroup}>
                        <Text style={styles.listTitle}>Получатель платежа</Text>
                        <Text style={styles.listDesc}>ООО “ИНКРАУД”</Text>
                    </View>
                    <View style={styles.listGroup}>
                        <Text style={styles.listTitle}>Номинальный счет</Text>
                        <Text style={styles.listDesc}>40702810501500068008</Text>
                    </View>
                    <View style={styles.listGroup}>
                        <Text style={styles.listTitle}>ИНН получателя</Text>
                        <Text style={styles.listDesc}>9704007489</Text>
                    </View>
                    <View style={styles.listGroup}>
                        <Text style={styles.listTitle}>КПП получателя</Text>
                        <Text style={styles.listDesc}>770401001</Text>
                    </View>
                    <View style={styles.listGroup}>
                        <Text style={styles.listTitle}>Наименование банка</Text>
                        <Text style={styles.listDesc}>Точка ПАО Банка «Финансовая Корпорация Открытие»</Text>
                    </View>
                    <View style={styles.listGroup}>
                        <Text style={styles.listTitle}>Корр. счет</Text>
                        <Text style={styles.listDesc}>30101810845250000999</Text>
                    </View>
                    <View style={styles.listGroup}>
                        <Text style={styles.listTitle}>БИК банка</Text>
                        <Text style={styles.listDesc}>044525999</Text>
                    </View>
                    <View style={styles.listGroup}>
                        <Text style={styles.listTitle}>Назначение платежа</Text>
                        <Text style={styles.blueColor}>
                            Пополнение счета 10004447 ООО “АСП” ИНН 744713465222 по договору
                            оказания услуг по содействию инвестированию от 10.08.2020
                        </Text>
                    </View>
                </View>
                <View style={styles.QRCode}>
                    {
                        QRCode
                            ? <Image style={styles.QRCodeImage} source={{uri: QRCode}} alt="QRCode"/>
                            : null
                    }
                </View>
            </Page>
        </Document>
    );
*/
    return (
        <div>
            {/*<PDFDownloadLink document={<MyDoc/>} fileName="Реквизиты-платформы-INCROWD.pdf">
                {({loading}) =>
                    loading ? 'Подгатовка доекмента...' : 'Скачать реквизиты'
                }
            </PDFDownloadLink>*/}
        </div>
    )
}

export default AccountReplenishmentDetails;
const SET_DATA_VALUE = 'SET_DATA_VALUE';
const CONFIRMATION_FIELD_VISIBLE_CONFIRM_BTN = 'CONFIRMATION_FIELD_VISIBLE_CONFIRM_BTN';
const CONFIRMATION_FIELD_VISIBLE_SPINNER = 'CONFIRMATION_FIELD_VISIBLE_SPINNER';
const CONFIRMATION_FIELD_VISIBLE_CHECK = 'CONFIRMATION_FIELD_VISIBLE_CHECK';
const SET_LEGAL_FORM_TYPE = 'SET_LEGAL_FORM_TYPE';
const SET_VISIBLE_SECOND_STAGE = 'SET_VISIBLE_SECOND_STAGE';

const setDataValue = (name, value) => ({
    type: SET_DATA_VALUE,
    payload: {name, value}
})

export {
    setDataValue
}

export default {
    SET_DATA_VALUE,
    CONFIRMATION_FIELD_VISIBLE_CONFIRM_BTN,
    CONFIRMATION_FIELD_VISIBLE_SPINNER,
    CONFIRMATION_FIELD_VISIBLE_CHECK,
    SET_LEGAL_FORM_TYPE,
    SET_VISIBLE_SECOND_STAGE
}
import React, {useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import {EDIT_AVATAR_MODAL, SELECT_IMAGE_MODAL} from "@shared/components/modal/constants";
import {Button} from "reactstrap";

const Selfie = ({showModal, hideModal, setModalData, setPreviousModal}) => {
    const dispatch = useDispatch();
    const videoEle = useRef(null);
    const canvasEle = useRef(null);

    const startCamera = async () => {
        try {
            videoEle.current.srcObject = await navigator.mediaDevices.getUserMedia({video: true});
        } catch (err) {
            alert('Необходимо разрешить использовать камеру!')
        }
    }

    const takeSelfie = async () => {
        // Get the exact size of the video element.
        const width = videoEle.current.videoWidth;
        const height = videoEle.current.videoHeight;

        // get the context object of hidden canvas
        const ctx = canvasEle.current.getContext('2d');

        // Set the canvas to the same dimensions as the video.
        canvasEle.current.width = width;
        canvasEle.current.height = height;

        // Draw the current frame from the video on the canvas.
        ctx.drawImage(videoEle.current, 0, 0, width, height);

        // Get an image dataURL from the canvas.
        const imageDataURL = await canvasEle.current.toDataURL('image/png');
        stopCam();

        //setPreviousModal(WEBCAM_MODAL)(dispatch)
        showModal(SELECT_IMAGE_MODAL, {image: imageDataURL})(dispatch)
    }

    const stopCam = () => {
        const stream = videoEle.current.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach(track => {
            track.stop();
        });
    }

    const cancelWebCam = () => {
        stopCam();
        setPreviousModal(null)(dispatch)
        showModal(EDIT_AVATAR_MODAL, null)(dispatch)
    }

    useEffect(() => {
        startCamera()
    })

    return (
        <div className="selfie">
            <div className="cam">
                <video width="100%" height="100%" className="video-player" autoPlay={true} ref={videoEle}/>
            </div>
            <canvas ref={canvasEle} style={{display: 'none'}}/>
            <div className="webCam__btn_group">
                <Button
                    color="primary"
                    className='account__btn account__btn_primary webCam__btn'
                    onClick={takeSelfie}
                >
                    Выбрать
                </Button>
                <Button
                    color="primary"
                    className='account__btn account__btn_cancel webCam__btn'
                    onClick={cancelWebCam}
                    outline
                >
                    Отмена
                </Button>
            </div>
        </div>
    )
}

export default Selfie;
import React, {useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {Button, Spinner} from "reactstrap";
import {useDispatch} from "react-redux";
import {getClientAccountData} from "@actions/innerUsersActions";
import {BanService, modalDesc} from './helpers';
import {UnbanProfileService} from "@services/ban.service";


const BanCommentModal = ({hideModal, modalData}) => {
    const dispatch = useDispatch();
    const [comment, setComment] = useState('');
    const [formError, setFormError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resetValidate, setResetValidate] = useState(false);
    const {id, isBanned, object, profileId} = modalData;
    const isUnlockProfile = isBanned && object === 'Profile';

    const handleOnChange = e => setComment(e.target.value);

    const handleBanProfile = () => {
        if (isUnlockProfile) {
            UnbanProfileService.create({}, id)
                .then(() => {
                    getClientAccountData(profileId)(dispatch)
                    setLoading(false)
                    hideModal()
                })
                .catch(() => setLoading(false))
        } else if (comment) {
            setLoading(true);

            BanService[object][isBanned].create({comment}, id)
                .then(() => {
                    getClientAccountData(profileId)(dispatch)
                    setLoading(false)
                    hideModal()
                })
                .catch(() => setLoading(false))
        } else {
            setFormError(["rejectionText"])
            setResetValidate(true)
        }
    }

    return <div className="moderatorAccModal__reject">
        {
            isUnlockProfile
                ? <p className="modalFullScreen__title">Вы уверены,что хотите разблокировать профиль?</p>
                : <>
                    <p className="modalFullScreen__title">{modalDesc[object].title[isBanned]}</p>
                    <InputText
                        name="rejectionText"
                        id="rejectionText"
                        type='textarea'
                        label={modalDesc[object].inputDesc[isBanned]}
                        rows={2}
                        formError={formError}
                        onChange={handleOnChange}
                        setFormError={setFormError}
                        resetValidate={resetValidate}
                        value={comment}
                        containerClassName="profileTabs__edit_item moderatorAccModal__reject_inputGroup"
                        validator={[{required: true}]}
                    />
                </>
        }
        <div className="modalFullScreen__btnGroup moderatorAccModal__reject_btnGroup">
            <Button
                onClick={() => hideModal()}
                color="primary"
                className="account__btn_cancel moderatorAccModal__reject_btnDanger"
                outline
            >
                Отмена
            </Button>
            <Button
                color="primary"
                className="account__btn_primary moderatorAccModal__reject_btnPrimary"
                onClick={handleBanProfile}
            >
                <div className="btn-spinner-group">
                    <Spinner className={`btn-spinner ${loading ? 'btn-spinner-visible' : ''}`}/>
                    {modalDesc[object].buttonDesc[isBanned]}
                </div>
            </Button>
        </div>
    </div>
}

export default BanCommentModal;

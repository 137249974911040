import React, {useEffect, useState} from 'react';
import classnames from 'classnames';

const AddProjectMenu = ({data, className, role}) => {
    const [newData, setNewData] = useState([]);
    const [activeElem, setActiveElem] = useState(0);

    const menuClass = classnames({
        'addProject__menu': true,
        [className]: className
    })

    const scrollToRef = ref => {
        if (ref?.current?.offsetTop) {
            let i = 0;
            let int = setInterval(function () {
                window.scrollTo(0, i);
                i += 20;
                if (i >= ref.current.offsetTop - 80) clearInterval(int);
            }, 5);
        }
    }

    const renderMenuItems = item => (
        <div
            key={item.id}
            className={`addProject__menu_item ${activeElem === item.id ? 'active' : ''}`}
            onClick={() => {
                setActiveElem(item.id);
                scrollToRef(item.ref);
            }}
        >
            <span>{item.title}</span>
        </div>
    )

    useEffect(() => {
        if (data) {
            if (data.role && role) {
                setNewData(data.links[role])
            } else {
                setNewData(data.links)
            }
        }
    }, [role, data])

    return (
        <div className={menuClass}>
            <div className="addProject__menu_fixed">
                <div className="addProject__menu_inner">
                    {newData.map(renderMenuItems)}
                </div>
            </div>
        </div>
    )
}

export default AddProjectMenu;
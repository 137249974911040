import React, {useEffect, useReducer, useState} from 'react';
import ModerationProjectsFilter from "@containers/Moderator/ModerationProjects/ModerationProjectsFilter";
import {filterInitialState, filterReducer} from "@containers/Moderator/ModerationProjects/helpers";
import ModerationProjectsTable from "@containers/Moderator/ModerationProjects/ModerationProjectsTable";
import Pagination from "@shared/components/pagination";
import {ModerationsListService} from '@services/moderator.service';

const ModerationProjectsList = ({activeTab}) => {
    let unmounted = false;
    const [filterData, setFilterData] = useReducer(filterReducer, filterInitialState);
    const [filterError, setFilterError] = useState([]);
    const [moderationList, setModerationList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);


    const getProjectList = (resetData) => {
        const newFilterData = JSON.parse(JSON.stringify(resetData || filterData));

        if (filterData.search) {
            newFilterData.search = `project.id:${newFilterData.search};project.profile.id:${newFilterData.search}`;
        }
        //project.profile.legal_form.personal.fio:${newFilterData.search}
        //if (activeTab === 'completion') newFilterData.type = 1;

        newFilterData.sortedBy = newFilterData.sortedBy.join(';');
        newFilterData.orderBy = newFilterData.orderBy.join(';');
        newFilterData.page = currentPage;
        newFilterData.status = activeTab === 'completion' ? 'projects_returned' : 'projects_checking';

        !unmounted && setLoading(true)
        ModerationsListService.getList(newFilterData)
            .then(res => !unmounted && setModerationList(res?.data || []))
            .catch(error => console.log(error))
            .finally(() => !unmounted && setLoading(false))
    }

    useEffect(() => {
        getProjectList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => unmounted = true;
    }, [])


    useEffect(() => {
        getProjectList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => unmounted = true;
    }, [filterData.sortedBy, filterData.orderBy, currentPage])

    useEffect(() => {
        setFilterData({reset: true});
        getProjectList(filterInitialState)

        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => unmounted = true;
    }, [activeTab])


    return (
        <div className="accreditationList moderationProjectsList">
            <div className="accreditationList__inner moderationProjectsList__inner">
                <ModerationProjectsFilter
                    activeTab={activeTab}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    filterError={filterError}
                    setFilterError={setFilterError}
                    loading={loading}
                    getProjectList={getProjectList}
                />
                <ModerationProjectsTable
                    moderationList={moderationList?.data || []}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    loading={loading}
                    activeTab={activeTab}
                />
                <Pagination
                    className="pagination-bar"
                    meta={moderationList.meta}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    )
}

export default ModerationProjectsList;

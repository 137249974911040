import React from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {CalendarIcon, SearchIcon} from "@shared/img/iconSvg";
import {Button} from "reactstrap";
import classnames from "classnames";
import classNames from "classnames";
import {filterInitialState} from "@containers/Moderator/ModerationProjects/helpers";

const ModerationProjectsFilter = ({
                                      filterData,
                                      setFilterData,
                                      filterError,
                                      setFilterError,
                                      loading,
                                      getProjectList,
                                      activeTab
                                  }) => {
    const searchInputClass = classNames({
        'filter_fullSearch': activeTab === 'completion',
        'moderationProjectsList__filter__md': true,
        'moderationProjectsList__filter_search': true
    });

    const btnGroupClass = classNames({
        'filter_fullBtnGroup': activeTab === 'completion',
        'moderationProjectsList__filter_btn': true,
    });

    const handleOnChange = ({target}) => {
        setFilterData({
            name: target.name,
            value: target.value,
        });
    }

    const resetFilterData = () => {
        setFilterData({reset: true});
        getProjectList(filterInitialState);
    }


    return (
        <div className="moderationProjectsList__filterGroup">
            <div className="moderationProjectsList__filter">
                <div className="moderationProjectsList__filter__md">
                    <p>Минимальная сумма</p>
                    <div className="filter__inputGroup">
                        <InputText
                            type="text"
                            inputWithDesc
                            noLabel={true}
                            name="from_min"
                            inputLabelLeft={true}
                            formError={filterError}
                            inputWithDescValue="от"
                            onChange={handleOnChange}
                            value={filterData.from_min}
                            setFormError={setFilterError}
                            validator={[{onlyNumbers: true}]}
                            containerClassName="addProject__fullInput"
                        />
                        <div className="filter__inputGroup_separator">
                            <span className="accreditationList__filter_separator"/>
                        </div>
                        <InputText
                            type="text"
                            name="to_min"
                            inputWithDesc
                            noLabel={true}
                            inputLabelLeft={true}
                            inputWithDescValue="До"
                            formError={filterError}
                            onChange={handleOnChange}
                            value={filterData.to_min}
                            setFormError={setFilterError}
                            validator={[{onlyNumbers: true}]}
                            containerClassName="addProject__fullInput"
                        />
                    </div>
                </div>
                <div className="moderationProjectsList__filter__md">
                    <p>Целевая сумма</p>
                    <div className="filter__inputGroup">
                        <InputText
                            type="text"
                            inputWithDesc
                            noLabel={true}
                            name="from_target"
                            inputLabelLeft={true}
                            formError={filterError}
                            inputWithDescValue="от"
                            onChange={handleOnChange}
                            value={filterData.from_target}
                            setFormError={setFilterError}
                            validator={[{onlyNumbers: true}]}
                            containerClassName="addProject__fullInput"
                        />
                        <div className="filter__inputGroup_separator">
                            <span className="accreditationList__filter_separator"/>
                        </div>
                        <InputText
                            type="text"
                            inputWithDesc
                            noLabel={true}
                            name="to_target"
                            inputLabelLeft={true}
                            inputWithDescValue="До"
                            formError={filterError}
                            onChange={handleOnChange}
                            value={filterData.to_target}
                            setFormError={setFilterError}
                            validator={[{onlyNumbers: true}]}
                            containerClassName="addProject__fullInput"
                        />
                    </div>
                </div>
                <div className="moderationProjectsList__filter__sm">
                    <p>Наличие обеспечения</p>
                    <div className="filter__inputGroup">
                        <button
                            className={`mainBtn__white from__custom-radio-btn ${filterData.pledge === 2 ? 'active' : ''}`}
                            onClick={() => handleOnChange({target: {name: 'pledge', value: 2}})}
                        >
                            <span>Нет</span>
                        </button>
                        <button
                            className={`mainBtn__white from__custom-radio-btn ${filterData.pledge === 1 ? 'active' : ''}`}
                            onClick={() => handleOnChange({target: {name: 'pledge', value: 1}})}
                        >
                            <span>Да</span>
                        </button>
                    </div>
                </div>
                {
                    activeTab === 'pending' && (
                        <div className="moderationProjectsList__filter__md">
                            <p>Тип проверки</p>
                            <div className="filter__inputGroup">
                                <button
                                    className={`mainBtn__white from__custom-radio-btn ${filterData.type === 1 ? 'active' : ''}`}
                                    onClick={() => handleOnChange({target: {name: 'type', value: 1}})}
                                >
                                    <span>Первичная</span>
                                </button>
                                <button
                                    name='type'
                                    value="2"
                                    className={`mainBtn__white from__custom-radio-btn ${filterData.type === 2 ? 'active' : ''}`}
                                    onClick={() => handleOnChange({target: {name: 'type', value: 2}})}
                                >
                                    <span>Повторная</span>
                                </button>
                            </div>
                        </div>
                    )
                }
                {
                    activeTab === 'completion' && (
                        <>
                            <div className="moderationProjectsList__filter__md">
                                <p>На доработке дней</p>
                                <div className="filter__inputGroup">
                                    <InputText
                                        type="text"
                                        inputWithDesc
                                        noLabel={true}
                                        name="from_returned"
                                        inputLabelLeft={true}
                                        formError={filterError}
                                        inputWithDescValue="от"
                                        onChange={handleOnChange}
                                        value={filterData.from_returned}
                                        setFormError={setFilterError}
                                        validator={[{onlyNumbers: true}]}
                                        containerClassName="addProject__fullInput"
                                    />
                                    <div className="filter__inputGroup_separator">
                                        <span className="accreditationList__filter_separator"/>
                                    </div>
                                    <InputText
                                        type="text"
                                        name="to_returned"
                                        inputWithDesc
                                        noLabel={true}
                                        inputLabelLeft={true}
                                        inputWithDescValue="До"
                                        formError={filterError}
                                        onChange={handleOnChange}
                                        value={filterData.to_returned}
                                        setFormError={setFilterError}
                                        validator={[{onlyNumbers: true}]}
                                        containerClassName="addProject__fullInput"
                                    />
                                </div>
                            </div>
                            <div className="moderationProjectsList__filter__md">
                                <p>Дата создания проекта</p>
                                <div className="filter__inputGroup">
                                    <InputText
                                        type="date"
                                        name="from_project"
                                        onChange={handleOnChange}
                                        inputWithDesc
                                        noLabel={true}
                                        maxDate={new Date()}
                                        value={filterData.from_project}
                                        inputWithDescValue={<CalendarIcon/>}
                                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                                    />
                                    <div className="filter__inputGroup_separator">
                                        <span className="accreditationList__filter_separator"/>
                                    </div>
                                    <InputText
                                        type="date"
                                        name="to_project"
                                        onChange={handleOnChange}
                                        inputWithDesc
                                        noLabel={true}
                                        maxDate={new Date()}
                                        value={filterData.to_project}
                                        inputWithDescValue={<CalendarIcon/>}
                                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                                    />
                                </div>
                            </div>
                        </>
                    )
                }
                <div className={searchInputClass}>
                    <div className="accreditationList__filter_searchInput">
                        <InputText
                            type="text"
                            name="search"
                            value={filterData.search}
                            onChange={handleOnChange}
                            placeholder="ФИО/Наименование, Правовая форма, ID профиля, E-mail"
                            inputWithDesc
                            inputWithDescValue={<SearchIcon/>}
                            containerClassName="addProject__fullInput"
                        />
                    </div>
                </div>
                <div className={btnGroupClass}>
                    <Button
                        color="primary"
                        className={classnames('account__btn_primary addProject__btn', {disabled: loading})}
                        onClick={() => getProjectList()}
                    >
                        Применить
                    </Button>
                    <Button
                        color="danger"
                        className={classnames('addProject__btn account__btn account__btn_cancel', {disabled: loading})}
                        onClick={resetFilterData}
                        outline
                    >
                        Сбросить
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ModerationProjectsFilter;
import React from 'react';
import PropTypes from 'prop-types';
import HeaderMenu from '@shared/components/headerMenu';

const AccountWrapper = ({children}) => {

    return (
        <div className="account account--not-photo">
            <div className="container account__container">
                <HeaderMenu/>
                <div className="account__wrapper-vn">
                    {children}
                </div>
            </div>
        </div>
    );
};

AccountWrapper.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AccountWrapper;


/*import React from 'react';
import { Row, Col } from 'reactstrap';
import formLogo from '@shared/img/account/form_logo.svg'

const AccountWrapper = ({children, className}) => {
  return (
      <div className="account account--not-photo">
        <div className="account__wrapper">
          <div className="account__wrapper-vn">
            <Row>
              <Col xl={6} lg={5} md={5} sm={4} className="account-image-wrapper d-none d-sm-block">
                <div className="account-image" />
              </Col>
              <Col xl={6} lg={7} md={7} sm={8} xs={12} className="account-form-wrapper">
                <div className={`account-form-container ${className ? className : ''}`}>
                  { children }
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
  );
};

export default AccountWrapper;*/

import React, {useEffect} from 'react';
import {addProjectMenu} from '@containers/Projects/AddProject/helpers';
import AttractingInvestments from './AttractingInvestments';
import PaymentType from './PaymentType';
import {useDispatch, useSelector} from "react-redux";
import {setProjectFormIsCompleted} from "@actions/projectsActions";

const ProjectTerms = ({step}) => {
    const dispatch = useDispatch();
    const {condition} = useSelector(state => state.projects.projectEditData);
    const {projectFormError: formError, formIsCompleted} = useSelector(state => state.projects);
    const disabledKey = [
        'type_key', 'own_version', 'penalty_per_day', 'charge_penalty_first_day',
        'percents_calculation', 'output_key', 'deffer_period'
    ];

    // Проверяем заполнены ли все поля, если да, то активируем кнопку далее
    useEffect(() => {
        let status = true;

        for (let key in condition) {
            if (!disabledKey.includes(key) && !condition[key]) status = false;
            if (!condition.type_key && !condition.own_version) status = false;
            if (condition.repayment_key === 'deferred_annuity' && !condition.deffer_period) status = false;
        }

        if (status !== formIsCompleted) setProjectFormIsCompleted("attractingInvestments", status, dispatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition])

    return (
        <>
            <AttractingInvestments
                fRef={addProjectMenu[step].links[0].ref}
                data={condition}
                formError={formError}
            />
            <PaymentType
                fRef={addProjectMenu[step].links[1].ref}
                data={condition}
                formError={formError}
            />
        </>
    )
}

export default ProjectTerms;

export const ADD_PROJECT = 'ADD_PROJECT';
export const WEBCAM_MODAL = 'WEBCAM_MODAL';
export const REFILL_MODAL = 'REFILL_MODAL';
export const NOTICE_MODAL = 'NOTICE_MODAL';
export const ABOUT_TARIFFS = 'ABOUT_TARIFFS';
export const QUALIFIED_FORM = 'QUALIFIED_FORM';
export const CANCEL_PROJECT = 'CANCEL_PROJECT';
export const PAYMENT_DETAILS = 'PAYMENT_DETAILS';
export const ADD_NEW_PROFILE = 'ADD_NEW_PROFILE';
export const SIGNATURE_MODAL = 'SIGNATURE_MODAL';
export const SIGNATURE_SIGN_MODAL = 'SIGNATURE_SIGN_MODAL';
export const SIGNATURE_SHOW_MODAL = 'SIGNATURE_SHOW_MODAL';
export const EARLY_REPAYMENT = 'EARLY_REPAYMENT';
export const PLANNED_PAYMENT = 'PLANNED_PAYMENT';
export const VIEW_FILE_DETAIL = 'VIEW_FILE_DETAIL';
export const INVESTMENT_MODAL = 'INVESTMENT_MODAL';
export const EDIT_AVATAR_MODAL = 'EDIT_AVATAR_MODAL';
export const BAN_COMMENT_MODAL = 'BAN_COMMENT_MODAL';
export const CEP_SIGNATURE_FILE = 'CEP_SIGNATURE_FILE';
export const PEP_SIGNATURE_FILE = 'PEP_SIGNATURE_FILE';
export const PLATFORM_SERVICES = 'PLATFORM_SERVICES';
export const SELECT_IMAGE_MODAL = 'SELECT_IMAGE_MODAL';
export const MODAL_WITH_SETTING = 'MODAL_WITH_SETTING';
export const MODAL_NOTIFICATION = 'MODAL_NOTIFICATION';
export const DETAIL_AVATAR_MODAL = 'DETAIL_AVATAR_MODAL';
export const PAYMENT_TYPES_MODAL = 'PAYMENT_TYPES_MODAL';
export const QUALIFIED_NEW_CHECK = 'QUALIFIED_NEW_CHECK';
export const WITHDRAW_FUNDS_MODAL = 'WITHDRAW_FUNDS_MODAL';
export const SIGNATURE_FILE_MODAL = 'SIGNATURE_FILE_MODAL';
export const ADD_INNER_USER_MODAL = 'ADD_INNER_USER_MODAL';
export const QUALIFIED_APPLICATION = 'QUALIFIED_APPLICATION';
export const AMOUNT_EXCEEDED_MODAL = 'AMOUNT_EXCEEDED_MODAL';
export const ACCREDITATION_PROFILE = 'ACCREDITATION_PROFILE';
export const ACCREDITATION_PHONE = 'ACCREDITATION_PHONE';
export const ACCREDITATION_UNFILLED = 'ACCREDITATION_UNFILLED';
export const CAP_NOT_REQUIRED_MODAL = 'CAP_NOT_REQUIRED_MODAL';
export const PAYMENT_SCHEDULE_MODAL = 'PAYMENT_SCHEDULE_MODAL';
export const DESIGNATE_ACCOUNT_MAIN = 'DESIGNATE_ACCOUNT_MAIN';
export const MODERATOR_ACC_NEW_CHECK = 'MODERATOR_ACC_NEW_CHECK';
export const MODERATOR_PROJECT_REJECT = 'MODERATOR_PROJECT_REJECT';
export const DELETE_INNER_USER_MODAL = 'DELETE_INNER_USER_MODAL';
export const QUALIFIED_INVESTOR_DETAIL = 'QUALIFIED_INVESTOR_DETAIL';
export const INVESTMENTS_CODE_SMS_MODAL = 'INVESTMENTS_CODE_SMS_MODAL';
export const VIEW_QUALIFIED_APPLICATION = 'VIEW_QUALIFIED_APPLICATION';
export const INVESTMENTS_PLATFORMS_MODAL = 'INVESTMENTS_PLATFORMS_MODAL';
export const INVESTMENT_CONFIRM_QEP_MODAL = 'INVESTMENT_CONFIRM_QEP_MODAL';
export const MODERATOR_ACC_PROFILE_REJECT = 'MODERATOR_ACC_PROFILE_REJECT';
export const CONFIRM_INVESTMENT_SMS_MODAL = 'CONFIRM_INVESTMENT_SMS_MODAL';
export const APPLICATION_QUALIFIED_ARCHIVE = 'APPLICATION_QUALIFIED_ARCHIVE';
export const APPLICATION_QUALIFIED_INVESTOR = 'APPLICATION_QUALIFIED_INVESTOR';
export const PROFILE_IS_NOT_ACCREDITED_MODAL = 'PROFILE_IS_NOT_ACCREDITED_MODAL';
export const QUALIFICATION_APPLICATION_REJECT = 'QUALIFICATION_APPLICATION_REJECT';
export const INNER_USERS_QUALIFIED_APPLICATION = 'INNER_USERS_QUALIFIED_APPLICATION';
export const PRELIMINARY_PAYMENT_SCHEDULE_MODAL = 'PRELIMINARY_PAYMENT_SCHEDULE_MODAL';
export const INVESTMENTS_PLATFORMS_EDITING_MODAL = 'INVESTMENTS_PLATFORMS_EDITING_MODAL';
export const QUALIFICATION_REPORT_NON_COMPLIANCE = 'QUALIFICATION_REPORT_NON_COMPLIANCE';
export const INVESTMENTS_PLATFORMS_EDITING_TWO_MODAL = 'INVESTMENTS_PLATFORMS_EDITING_TWO_MODAL';

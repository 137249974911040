import React from 'react';

const ProfileAccreditedRejectedNotice = () => {
    return <div className="accStatusNotice accStatusNotice__rejected">
        <div className="accStatusNotice__content">
            <p>
                К сожалению, ваш профиль отклонен. Редактирование данных и повторная аккредитация не доступны.
                Доступ к сервисам платформы ограничен.
            </p>
        </div>
    </div>
}

export default ProfileAccreditedRejectedNotice;
import React from "react";
import {useDispatch} from "react-redux";
import {showModal} from "@actions/modalActions";
import {documentIcon} from "@shared/img/iconSvg";
import {VIEW_FILE_DETAIL} from "@shared/components/modal/constants";
import FileItemControls from "@shared/components/uploadField/FileItemControls";

const FileItem = (props) => {
    const {data, files, fileKey, onChange, viewDetail} = props;
    const {id, extension, name} = data;
    const dispatch = useDispatch();

    // Удалить файл
    const removeFile = () => {
        const filesId = [], filesNew = [];

        files.forEach(item => {
            if (item.id !== id) {
                filesId.push(item.id);
                filesNew.push(item);
            }
        });

        onChange({target: {name: fileKey, value: filesNew}});
    }

    const openFileDetail = () => {
        if (viewDetail) {
            showModal(VIEW_FILE_DETAIL, data)(dispatch)
        }
    }


    return (
        <div className="uploadField__item">
            <div className="uploadField__item_group" onClick={openFileDetail}>
                <div className="uploadField__fileIcon">{documentIcon()}</div>
                <div className="uploadField__name">{`${name}.${extension}`}</div>
            </div>
            <div className="uploadField__download_group">
                <FileItemControls {...props} removeFile={removeFile}/>
            </div>
        </div>
    )
}

export default FileItem;
import React from 'react';
import {DownloadIcon} from "@shared/img/iconSvg";


const MediaDetail = ({data}) => {
    const {file, url} = data;

    const downloadMediaDetail = () => {
        const link = window.document.createElement("a");
        link.href = url;
        link.download = `${file.name}.${file.extension}` || 'file';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div
            className="mediaPresentation__bg mediaPresentation__detail"
            style={{background: `url(${url})`}}
        >
            <div className="uploadField__download mediaPresentation__download" onClick={downloadMediaDetail}>
                <DownloadIcon/>
            </div>
        </div>
    )
}

export default MediaDetail;

export const ddu = {
    address: null,
    conditional_number: null,
    date: null,
    developer: null,
    documents: [],
    floor: null,
    floors_number: null,
    number: null,
    ogrn_developer: null,
    other_information: null,
    plans: [],
    registration_number: null,
    registration_region: null,
    sum: null,
    total_area: null,
    transfer_date: null,
    total_area_type: "ga"
}

export const movable = {
    body: null,
    body_color: null,
    brand_model: null,
    chassis: null,
    documents: [],
    engine_model_number: null,
    engine_power: null,
    engine_type: null,
    engine_volume: null,
    images: [],
    name: null,
    pts_date: null,
    pts_issued_address: null,
    pts_issued_by: null,
    sts_issued_by: null,
    pts_scans: [],
    pts_series_number: null,
    sts_series_number: null,
    sts_date: null,
    sts_scans: [],
    ts_mfr: null,
    vin: null,
    year: null,
    gos_number: null,
    address: null,
    other_information: null,
}

export const other = {
    address: null,
    characteristics: null,
    date: null,
    documents: [],
    images: [],
    name: null,
    object_documents: [],
    other_information: null,
    serial_number: null
}

export const propertyApartment = {
    type_key: "apartment",
    type: {key: "apartment", value: "Квартира"},
    apartment: {
        address: null,
        apartment_number: null,
        certificate_date: null,
        certificate_number: null,
        certificates: [],
        documents: [],
        extract_egrn_from: null,
        extracts: [],
        floor: null,
        images: [],
        living_area: null,
        number: null,
        other_information: null,
        plans: [],
        rooms_count: null,
        total_area: null
    }
}

export const propertyHouse = {
    type_key: "house",
    type: {key: "house", value: "Жилой дом и земельный участок"},
    house: {
        address: null,
        certificate_date: null,
        certificate_number: null,
        certificates: [],
        documents: [],
        extract_egrn_from: null,
        extracts: [],
        images: [],
        living_area: null,
        number: null,
        other_information: null,
        plans: [],
        total_area: null,
    },
    land: {
        address: null,
        certificate_date: null,
        certificate_number: null,
        certificates: [],
        documents: [],
        extract_egrn_from: null,
        extracts: [],
        images: [],
        land_category: null,
        number: null,
        other_information: null,
        permitted_use: null,
        total_area: null,
        total_area_type: "ga"
    }
}

export const propertyLand = {
    type_key: "land",
    type: {key: "land", value: "Земельный участок"},
    land: {
        address: null,
        certificate_date: null,
        certificate_number: null,
        certificates: [],
        documents: [],
        extract_egrn_from: null,
        extracts: [],
        images: [],
        land_category: null,
        number: null,
        other_information: null,
        permitted_use: null,
        total_area: null,
        total_area_type: "ga"
    }
}

export const propertyPremise = {
    type_key: "premise",
    type: {key: "premise", value: "Нежилое помещение/нежилые помещения"},
    premise: {
        address: null,
        certificate_date: null,
        certificate_number: null,
        certificates: [],
        contract_type: null,
        contract: null,
        documents: [],
        extract_egrn_from: null,
        extracts: [],
        floors: null,
        floors_number: null,
        images: [],
        number: null,
        other_information: null,
        plans: [],
        premise_type: "residential",
        residential_total_area: null,
        rooms: null,
        total_area: null,
    },
}

export const propertyBuilding = {
    type_key: "building",
    type: {key: "building", value: "Здание"},
    building: {
        address: null,
        contract_type: null,
        documents: [],
        floors_number: null,
        image_id: null,
        images: [],
        number: null,
        number_type: "cadastral",
        object_composition: null,
        other_information: null,
        plans: [],
        contract: null,
        total_area: null
    }
}

export const propertyContracts = {
    ownership: {
        address: null,
        number: null,
        total_area_type: 'ga',
        total_area: null,
        land_category: null,
        permitted_use: null,
        certificate_date: null,
        certificate_number: null,
        extract_egrn_from: null,
        other_information: null,
        extracts: [],
        documents: [],
        certificates: []
    },
    lease: {
        address: null,
        number: null,
        total_area_type: 'ga',
        total_area: null,
        land_category: null,
        permitted_use: null,
        other_information: null,
        rent_type: 'year',
        rent: null,
        documents: [],
        contract_name: null,
        contract_date: null,
        contract_number: null,
        contract_tenant: null,
        contract_registered_date: null,
        contract_registered_company: null,
        contract_registered_number: null,
    },
    uses: {
        address: null,
        number: null,
        total_area_type: 'ga',
        total_area: null,
        land_category: null,
        permitted_use: null,
        other_information: null,
        uses_type: 'year',
        uses: null,
        rent_confirmed: 'contract',
        contract_name: null,
        contract_date: null,
        contract_number: null,
        contract_tenant: null,
        contract_registered_date: null,
        contract_registered_company: null,
        contract_registered_number: null,
        order_company: null,
        order_number: null,
        order_date: null,
        documents: []
    }
}

export const aboutCompanyRequiredKey = ['primary_occupation', 'foundation_year', 'revenue']

export const dduRequiredKey = [
    'number', 'date', 'developer', 'ogrn_developer', 'registration_region', 'registration_number',
    'sum', 'address', 'total_area', 'floor', 'floors_number', 'conditional_number', 'transfer_date'
]

export const movableRequiredKey = [
    'body', 'body_color', 'brand_model', 'chassis', 'engine_model_number', 'engine_power', 'engine_type',
    'engine_volume', 'images', 'name', 'pts_date', 'pts_issued_address', 'pts_issued_by', 'pts_scans',
    'pts_series_number', 'ts_mfr', 'vin', 'year', 'address'
]

export const otherRequiredKey = [
    'address', 'characteristics', 'date', 'images', 'name', 'object_documents', 'serial_number'
]

export const contractsRequiredKey = {
    ownership: [
        'address', 'number', 'total_area_type', 'total_area', 'land_category', 'permitted_use',
        'extract_egrn_from', 'extracts'
    ],
    lease: [
        'address', 'number', 'total_area_type', 'total_area', 'land_category', 'permitted_use',
        'rent_type', 'rent', 'contract_name', 'contract_date', 'contract_number', 'contract_tenant',
        'contract_registered_date', 'contract_registered_company', 'contract_registered_number',
    ],
    uses: {
        contract: [
            'address', 'number', 'total_area_type', 'total_area', 'land_category', 'permitted_use',
            'uses_type', 'uses', 'rent_confirmed', 'contract_name', 'contract_date', 'contract_number', 'contract_tenant',
            'contract_registered_date', 'contract_registered_company', 'contract_registered_number',
        ],
        order: [
            'address', 'number', 'total_area_type', 'total_area', 'land_category', 'permitted_use',
            'uses_type', 'uses', 'rent_confirmed', 'order_company', 'order_number', 'order_date'
        ]
    }
}

export const propertyRequiredKey = {
    apartment: [
        'address', 'apartment_number', 'extract_egrn_from', 'extracts', 'floor', 'images', 'living_area',
        'number', 'rooms_count', 'total_area'
    ],
    house: {
        house: ['address', 'extract_egrn_from', 'extracts', 'images', 'living_area', 'number', 'total_area'],
        land: [
            'address', 'extract_egrn_from', 'extracts', 'images', 'land_category', 'number', 'permitted_use',
            'total_area', 'total_area_type'
        ]
    },
    land: [
        'address', 'extract_egrn_from', 'extracts', 'images', 'land_category', 'number', 'permitted_use',
        'total_area', 'total_area_type'
    ],
    premise: [
        'address', 'certificate_date', 'certificate_number', 'extract_egrn_from', 'extracts', 'floors', 'floors_number',
        'images', 'number', 'residential_total_area', 'rooms', 'total_area'
    ],
    building: ['address', 'floors_number', 'images', 'number', 'number_type', 'object_composition', 'total_area'],
}

export const defaultPledge = id => ({
    id,
    property: {...propertyApartment},
    property_type: "property",
})


export const propertySelectOptions = [
    {value: 'apartment', label: 'Квартира'},
    {value: 'house', label: 'Жилой дом'},
    {value: 'premise', label: 'Нежилое помещение'},
    {value: 'land', label: 'Земельный участок'},
    {value: 'building', label: 'Здание'}
]

export const propertyLandSelectOptions = [
    {value: 'ga', label: 'га'},
    {value: 'meter', label: 'кв.м.'},
]

export const financeSelectOptions = [
    {value: 'profit', label: 'Прибыль'},
    {value: 'lesion', label: 'Убыток'},
]

export const propertyPremiseSelectOptions = [
    {value: 'residential', label: 'Жилое'},
    {value: 'non-residential', label: 'Не жилое'},
]

export const propertyPremiseContractSelectOptions = [
    {value: 'ownership', label: 'Право собственности'},
    {value: 'lease', label: 'Право аренды'},
    {value: 'uses', label: 'Право пользования'},
    {value: null, label: 'Имущественные права отсутствуют'},
]

export const propertyBuildingNumberSelectOptions = [
    {value: 'cadastral', label: 'Кадастровый номер'},
    {value: 'conditional', label: 'Условный номер'},
    {value: 'inventory', label: 'Инвентарный номер'},
]

export const propertyRentTypeSelectOptions = [
    {value: 'year', label: 'Год'},
    {value: 'month', label: 'Месяц'},
    {value: 'date', label: 'До'},
    {value: 'infinity', label: 'На неопределенный срок'},
]

export const propertyLeaseRentTypeSelectOptions = [
    {value: 'year', label: 'лет'},
    {value: 'month', label: 'месяцев'},
]

export const propertyUsesTypeSelectOptions = [
    {value: 'year', label: 'лет'},
    {value: 'month', label: 'месяцев'},
    {value: 'date', label: 'дата'},
    {value: 'infinity', label: 'бессрочное'},
]

export const propertyRentConfirmedSelectOptions = [
    {value: 'contract', label: 'Договор'},
    {value: 'order', label: 'Распоряжение'},
]

import React, {useState} from 'react';
import classnames from 'classnames';
import {TabContent, TabPane} from 'reactstrap';
import ChangePassword from "./ChangePassword";
import ChangeEmail from "@shared/components/modal/settings/ModalWithSettings/ChangeEmail";
import MailingSettings from "@shared/components/modal/settings/ModalWithSettings/MailingSettings";

const ModalWithSettings = ({hideModal}) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div>
            <p className="modalFullScreen__title">Настройки</p>
            <div className="modalWithSettings__tabs profileTabs">
                <div className="profileTabs__tabs">
                    <span
                        className={classnames({'profileTabs__link': true, active: activeTab === '1'})}
                        onClick={() => {
                            toggle('1');
                        }}
                    >
                        Смена пароля
                    </span>
                    <span
                        className={classnames({'profileTabs__link': true, active: activeTab === '2'})}
                        onClick={() => {
                            toggle('2');
                        }}
                    >
                        Смена почты
                    </span>
                    <span
                        className={classnames({'profileTabs__link': true, active: activeTab === '3'})}
                        onClick={() => {
                            toggle('3');
                        }}
                    >
                        Настройка рассылки
                    </span>
                </div>
                <TabContent activeTab={activeTab} className="accountContent__wrap modalWithSettings__wrap">
                    <TabPane tabId="1" className="profileTabs__tab">
                        <ChangePassword/>
                    </TabPane>
                    <TabPane tabId="2" className="profileTabs__tab">
                        <ChangeEmail/>
                    </TabPane>
                    <TabPane tabId="3" className="profileTabs__tab">
                        <MailingSettings/>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    )
}

export default ModalWithSettings;
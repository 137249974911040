import {BaseService} from "@services/base.service";
import {ErrorWrapper} from "@utils/wrapper";

export class SignatureService extends BaseService {
    static get entity() {
        return '/signatures'
    }

    static async download(id) {
        try {
            return await this.request({auth: true}).get(`${this.entity}/download/${id}`, {})
        } catch (error) {
            throw new ErrorWrapper(error, "error to get signature")
        }
    }
}
import React from 'react';
import PledgesProjectProperty from "@shared/components/presentation/PledgesProject/property";
import PledgesProjectMovable from "@shared/components/presentation/PledgesProject/movable";
import PledgesProjectDDU from "@shared/components/presentation/PledgesProject/ddu";
import PledgesProjectOther from "@shared/components/presentation/PledgesProject/other";
import {navigationMenu} from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/helpers";

const Pledge = React.memo(({data, step, idx}) => {
    switch (data.property_type) {
        case "property":
            return <PledgesProjectProperty
                data={data}
                idx={idx}
                fRef={navigationMenu[step].links[idx].ref}
            />
        case "movable":
            return <PledgesProjectMovable
                data={data}
                idx={idx}
                fRef={navigationMenu[step].links[idx].ref}
            />
        case "ddu":
            return <PledgesProjectDDU
                data={data}
                idx={idx}
                fRef={navigationMenu[step].links[idx].ref}
            />
        case "other":
            return <PledgesProjectOther
                data={data}
                idx={idx}
                fRef={navigationMenu[step].links[idx].ref}
            />
        default:
            return null
    }
})

export default Pledge;

import React, {useState} from 'react';
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";
import InvestInputSum from "@shared/components/projects/projectDetail/ProjectInvest/modal/investInputSum";
import {Tooltip} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {INVESTMENTS_PLATFORMS_EDITING_TWO_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const InvestmentsPlatformsEditingModal = () => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className='platformsEditingModal investmentsPlatformsModal'>
            <BackButton/>
            <span className='textTitle'>Инвестиции на других платформах</span>
            <span className='investmentsPlatformsModal__desc fontSizeText colorSecondary'>
                В соответствии с Федеральным законом № 259 ФЗ сумма инвестиций для физ.
                лиц не должна превышать 600 тыс. <br/> руб. в календарном году совокупно на всех инвестиционных платформах.
                Для снятия ограничения необходимо подтвердить статус квалифицированного инвестора на странице Профиль
            </span>
            <div className='platformsEditingModal__block'>
                <div className='platformsEditingModal__block_info'>
                    <span className='fontSizeSubtitle'>Инвестиции на других платформах за ГГГГ год - </span>
                    <span>
                          <InvestInputSum id={"primaryTooltip"}/>
                          <Tooltip
                              placement={"bottom"}
                              isOpen={tooltipOpen}
                              autohide={false}
                              target={'primaryTooltip'}
                              toggle={toggle}
                          >
                              Раннее указанная сумма инвестиций на других платформах не может быть уменьшена
                          </Tooltip>
                    </span>
                    <span
                        className='fontSizeText textLine greenColor'
                        onClick={() => showModal(INVESTMENTS_PLATFORMS_EDITING_TWO_MODAL)(dispatch)}
                    >
                        Подтвердить
                    </span>
                    <span
                        className='fontSizeText textLine colorError'
                        onClick={() => history.push("/project-detail/101010")}
                    >
                        Отменить
                    </span>
                </div>
                <div className='platformsEditingModal__block_subtitle colorSecondary'>
                    После подтверждения уменьшить сумму будет нельзя
                </div>
            </div>
        </div>
    )
};

export default InvestmentsPlatformsEditingModal;
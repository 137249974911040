import React from 'react';
import {Button} from "reactstrap";

const ProjectStatusNotification = ({Icon, title, desc, onClick, btnTitle}) => {
    return (
        <div className="projectStatusNotification">
            <div className="projectStatusNotification__inner">
                <Icon/>
                <p className="projectStatusNotification__title">{title}</p>
                <p className="projectStatusNotification__desc">{desc}</p>
                <Button
                    color="success"
                    className="account__btn_success"
                    onClick={onClick}
                    outline
                >
                    {btnTitle}
                </Button>
            </div>
        </div>
    )
}

export default ProjectStatusNotification;
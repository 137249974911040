import React, {useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import {SettingsDocumentsService} from "@services/settings.service";
import CheckDataToDisplay from "@shared/components/presentation/CheckDataToDisplay";
import GetFile from "@shared/components/uploadField/GetFile";

const ReplacingDocuments = () => {
    const [data, setData] = useState(null);
    const {
        consent_to_the_processing_of_personal_data, investment_platform_rules,
        personal_data_processing_policy, risk_declaration
    } = data?.value || {};

    const saveDocuments = ({target}) => {
        const dataFormat = {...data, value: {...data.value, [target.name]: target.value[0].id}};

        SettingsDocumentsService.update(null, dataFormat)
            .then(res => setData(res.data))
            .catch(error => console.log(error))
    }

    useEffect(() => {
        SettingsDocumentsService.getList()
            .then(res => setData(res.data.data))
            .catch(error => console.log(error))
    }, [])

    return (
        <div className="accreditationList__inner replacingDocuments">
            <p className="adminSection__tableTitle">Статические файлы</p>
            <CheckDataToDisplay data={data}>
                <div className="replacingDocuments__content">
                    <Row>
                        <Col md={6}>
                            <GetFile
                                fileId={investment_platform_rules}
                                fileKey="investment_platform_rules"
                                fileType="settings"
                                onChange={saveDocuments}
                                title="Правила инвестиционной платформы"
                            />
                        </Col>
                        <Col md={6}>
                            <GetFile
                                fileId={personal_data_processing_policy}
                                fileKey="personal_data_processing_policy"
                                fileType="settings"
                                onChange={saveDocuments}
                                title="Политика обработки персональных данных"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <GetFile
                                fileId={risk_declaration}
                                fileKey="risk_declaration"
                                fileType="settings"
                                onChange={saveDocuments}
                                title="Декларация о рисках"
                            />
                        </Col>
                        <Col md={6}>
                            <GetFile
                                fileId={consent_to_the_processing_of_personal_data}
                                fileKey="consent_to_the_processing_of_personal_data"
                                fileType="settings"
                                onChange={saveDocuments}
                                title="Согласие на обработку персональных данных"
                            />
                        </Col>
                    </Row>
                </div>
            </CheckDataToDisplay>
        </div>
    )
}

export default ReplacingDocuments;
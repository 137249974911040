import React from 'react';
import TotalEstimatedCollateral from "@shared/components/presentation/TotalEstimatedCollateral";
import AddedList from "@shared/components/form/addedList";
import ProjectPledges from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/steps/ProjectPledges";
import classnames from "classnames";

const ModerationProjectPledge = ({
                                     data,
                                     step,
                                     remarkList,
                                     setRemarkList,
                                     pledgesState,
                                     pledgesDispatch,
                                     pledgesValidStatus,
                                     showResult
                                 }) => {
    const classes = classnames({
        'moderationProject__showResult': showResult
    })

    return <div className={classes}>
        <ProjectPledges
            data={data?.project?.pledges || []}
            step={step}
            pledgesState={pledgesState}
            pledgesDispatch={pledgesDispatch}
            pledgesValidStatus={pledgesValidStatus}
        />
        {
            data?.project?.pledges?.length
                ? <TotalEstimatedCollateral pledgesState={pledgesState}/>
                : null
        }
        <p className="moderationProject__title">Решения модератора</p>
        <AddedList
            disabled={showResult}
            data={remarkList}
            label="Замечания"
            placeholder="Замечание"
            onChange={setRemarkList}
            dataType="moderation_pledge"
            className="moderationProject__remarks"
        />
    </div>
}

export default ModerationProjectPledge;

import React, {memo} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {Col, Row} from "reactstrap";
import UploadField from "@shared/components/uploadField";
import {SET_ERROR, SET_PASSPORT} from "@shared/components/account/EditProfile/helpers/constants";
import {CalendarIcon} from "@shared/img/iconSvg";
import PrimaryTooltip from "@shared/components/tooltip";

const PassportData = ({data, resetValidate, formError, dispatch, errorDispatch, birth_date}) => {
    const {issue_date, issued_by, serial_number, department_code, first_photo, second_photo} = data;

    const issueMaxDate = new Date();
    const issueMinDate = birth_date ? new Date(birth_date) : new Date();

    issueMaxDate.setDate(issueMaxDate.getDate() - 1);

    if (!birth_date) issueMinDate.setFullYear(1900)

    const handleOnChange = (event) => {
        dispatch({
            type: SET_PASSPORT,
            payload: {
                name: event.target.name,
                value: event.target.value,
            }
        })
    }

    const setFormError = (error) => {
        errorDispatch({
            type: SET_ERROR,
            payload: {
                name: 'passport',
                value: error,
            }
        })
    }

    /*const setResetValidate = () => {
        dispatch({type: SET_RESET_VALIDATE})
    }*/

    return (
        <div className="profileTabs__tabItem accountPersonalData">
            <div className="profileTabs__tabItem_title">
              <span>
                  Паспортные данные
                  <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                      <p>
                        Паспортные данные необходимо заполнять в полном соответствии
                        с документов подтверждающим личность (с сохранением всех сокращений)
                      </p>
                </PrimaryTooltip>
              </span>
            </div>
            <div className="profileTabs__tabItem_list">
                <div className="profileTabs__edit">
                    <InputText
                        type="mask"
                        name="serial_number"
                        id="serial_number"
                        label="Серия и номер паспорта"
                        onChange={handleOnChange}
                        value={serial_number}
                        validator={[{required: true}, {numbersCount: 10}]}
                        mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        formError={formError}
                        resetValidate={resetValidate}
                        setFormError={setFormError}
                        checkAfterFocus={false}
                    />
                    <Row>
                        <Col md={6}>
                            <InputText
                                type="date"
                                name="issue_date"
                                icon={CalendarIcon}
                                maxDate={issueMaxDate}
                                minDate={issueMinDate}
                                label="Дата выдачи"
                                onChange={handleOnChange}
                                value={issue_date}
                                validator={[{required: true}]}
                                containerClassName="date-picker profileTabs__edit_item"
                                formError={formError}
                                resetValidate={resetValidate}
                                setFormError={setFormError}
                                checkAfterFocus={false}
                            />
                        </Col>
                        <Col md={6}>
                            <InputText
                                type="mask"
                                name="department_code"
                                label="Код подразделения"
                                onChange={handleOnChange}
                                value={department_code}
                                validator={[{required: true}, {numbersCount: 6}]}
                                mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                containerClassName="profileTabs__edit_item"
                                formError={formError}
                                resetValidate={resetValidate}
                                setFormError={setFormError}
                                checkAfterFocus={false}
                            />
                        </Col>
                    </Row>
                    <InputText
                        type="text"
                        name="issued_by"
                        label="Кем выдан"
                        onChange={handleOnChange}
                        value={issued_by}
                        validator={[{required: true}, {maxLength: 300}]}
                        containerClassName="profileTabs__edit_item"
                        formError={formError}
                        resetValidate={resetValidate}
                        setFormError={setFormError}
                        checkAfterFocus={false}
                    />
                    <div className="inputText__wrapper profileTabs__edit_item">
                        <label>
                            <span className="position-relative">
                              Страница с фотографией
                              <PrimaryTooltip name="firstPhotoScanEditTooltip" title="?"
                                              className="profileTabs__tabItem_tooltip">
                                <p>Первая страница паспорта</p>
                              </PrimaryTooltip>
                            </span>
                        </label>
                        <UploadField
                            viewDetail
                            files={first_photo}
                            edit={true}
                            multiple={false}
                            title="паспорт"
                            onChange={handleOnChange}
                            required={true}
                            resetValidate={resetValidate}
                            formError={formError}
                            setFormError={setFormError}
                            fileKey="first_photo"
                            fileType="passport"
                            accept=".jpeg, .jpg, .png, .pdf"
                            checkAfterFocus={false}
                        />
                    </div>
                    <div className="inputText__wrapper profileTabs__edit_item">
                        <label>
                            <span className="position-relative">
                              Страница с регистрацией
                              <PrimaryTooltip name="secondPhotoScanEditTooltip" title="?"
                                              className="profileTabs__tabItem_tooltip">
                                <p>Страница с регистрацией</p>
                              </PrimaryTooltip>
                            </span>
                        </label>
                        <UploadField
                            viewDetail
                            files={second_photo}
                            edit={true}
                            multiple={false}
                            required={true}
                            resetValidate={resetValidate}
                            formError={formError}
                            setFormError={setFormError}
                            title="паспорт"
                            onChange={handleOnChange}
                            fileKey="second_photo"
                            fileType="passport"
                            accept=".jpeg, .jpg, .png, .pdf"
                            checkAfterFocus={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(PassportData);
import React from 'react';

const LoanTransfer = () => {
    return (
        <div className="accreditationList__inner">
            <p className="adminSection__tableTitle">Перевод собранных заемных средств заемщику</p>
        </div>
    )
}

export default LoanTransfer;
import React from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import UploadField from "@shared/components/uploadField";
import {CalendarIcon} from "@shared/img/iconSvg";

const Apartment = ({data, setData, formError, setFormError, projectId}) => {
    const {
        address, apartment_number, certificate_date, certificate_number, certificates, documents, extract_egrn_from,
        extracts, floor, images, living_area, number, other_information, plans, rooms_count, total_area
    } = data;

    return (
        <>
            <InputText
                formError={formError}
                name="address"
                id="addressInput"
                onChange={setData}
                label="Адрес"
                setFormError={setFormError}
                type="text"
                value={address || ''}
                validator={[{maxLength: 255}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                formError={formError}
                name="number"
                id="numberInput"
                onChange={setData}
                label="Кадастровый номер"
                setFormError={setFormError}
                type="text"
                value={number || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
            />
            <InputText
                name="total_area"
                id="totalAreaInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Общая площадь"
                value={total_area || ''}
                labelClass="requiredMark"
                validator={[{maxLength: 10}, {onlyNumbers: true}, {required: true}]}
                inputWithDesc
                inputWithDescValue={<>м<sup>2</sup></>}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="living_area"
                id="livingAreaInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Жилая площадь"
                value={living_area || ''}
                labelClass="requiredMark"
                validator={[{maxLength: 10}, {required: true}]}
                inputWithDesc
                inputWithDescValue={<>м<sup>2</sup></>}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="apartment_number"
                id="apartmentNumberInput"
                onChange={setData}
                label="Номер квартиры"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={apartment_number || ''}
                validator={[{maxLength: 10}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__smallInput"
                inputWrap={true}
            />
            <InputText
                name="rooms_count"
                id="roomsCountInput"
                onChange={setData}
                label="Количество комнат"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={rooms_count || ''}
                validator={[{maxLength: 3}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__smallInput"
                inputWrap={true}
            />
            <InputText
                name="floor"
                id="floorInput"
                onChange={setData}
                label="Этаж"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={floor || ''}
                validator={[{maxLength: 3}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__smallInput"
                inputWrap={true}
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Технический план (скан)</label>
                <UploadField
                    files={plans}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Технический план"
                    onChange={setData}
                    fileKey="plans"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <div className="pledge__inputGroup inputText__wrapper">
                <label>Свидетельство о праве собственности (при наличии)</label>
                <div className="pledge__inputGroup_inner">
                    <InputText
                        name="certificate_number"
                        id="certificateNumberInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setData}
                        noLabel={true}
                        value={certificate_number || ''}
                        validator={[{maxLength: 50}]}
                        inputWithDesc
                        inputWithDescValue="№"
                        inputLabelLeft={true}
                        containerClassName="addProject__middleInput"
                    />
                    <InputText
                        name="certificate_date"
                        id="certificateDateInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setData}
                        noLabel={true}
                        type="date"
                        value={certificate_date || ''}
                        inputWithDesc
                        inputWithDescValue="от"
                        inputLabelLeft={true}
                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                    />
                </div>
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Скан свидетельства о праве собственности</label>
                <UploadField
                    files={certificates}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан свидетельства о праве собственности"
                    onChange={setData}
                    fileKey="certificates"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <InputText
                name="extract_egrn_from"
                id="extractEgrnFromInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Выписка из Единого государственного реестра недвижимости, дата"
                type="date"
                value={extract_egrn_from || ''}
                validator={[{required: true}]}
                labelClass="requiredMark"
                inputWithDesc
                inputWithDescValue={<CalendarIcon/>}
                containerClassName="addProject__middleInput addProject__dateInput date-picker"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Скан выписки</label>
                <UploadField
                    files={extracts}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан выписки из ЕГРН"
                    onChange={setData}
                    fileKey="extracts"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    required={true}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <InputText
                rows={2}
                type="textarea"
                onChange={setData}
                formError={formError}
                name="other_information"
                value={other_information || ''}
                id="otherInformationInput"
                setFormError={setFormError}
                validator={[{maxLength: 500}]}
                label="Иные сведения"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Иные документы</label>
                <UploadField
                    files={documents}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Иные документы"
                    onChange={setData}
                    fileKey="documents"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Фото залога</label>
                <UploadField
                    files={images}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Фото залога"
                    onChange={setData}
                    fileKey="images"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png"
                    required={true}
                />
            </div>
        </>
    )

}

export default Apartment;
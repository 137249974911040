import React from 'react';
import UploadField from "@shared/components/uploadField";

const DocumentationInfo = ({data}) => {
    return (
        <div className='documentationInfo'>
            <div className='documentationInfo__desc documentationInfo__documents'>
                {
                    data.documents.map(item => (
                        <div key={item.id} className='documentationInfo__descCompany'>
                            <span className='documentationInfo__descCompany_subtitle'>{item.label}</span>
                            <UploadField viewDetail files={item.files} edit={false} notControls/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

export default DocumentationInfo;
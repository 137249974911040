import React from "react";
import {NavLink} from "react-router-dom";
import classnames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";

// Заголовки вкладок для инвестора и заемщика
export const tabLinks = {
    investor: [
        {key: 'profileData', title: "Данные профиля", id: 1},
        {key: 'bankDetails', title: "Банковские реквизиты", id: 2},
    ],
    borrower: [
        {key: 'profileData', title: "Данные профиля", id: 1},
        {key: 'bankDetails', title: "Банковские реквизиты", id: 2}
    ]
}

// Переключение вкладок
const toggle = (tab, activeTab, setActiveTab) => {
    if (activeTab !== tab) setActiveTab(tab);
};

// Если ширина экрана меньше 540px (width <= 540) вывести вкладки в виде слайдера
export const renderSwiperSlide = (role_key, activeTab, setActiveTab, checkAccreditation) => {
    return (
        <Swiper
            spaceBetween={35}
            slidesPerView='auto'
            onClick={(swiper, event) => swiper.slideTo(event.target.dataset.id, 300)}
            breakpoints={{376: {spaceBetween: 35,}, 0: {spaceBetween: 27,}}}
        >
            {
                tabLinks[role_key || 'investor'].map((item, idx) => <SwiperSlide key={item.id}>
              <span
                  data-id={idx}
                  key={item.id}
                  className={classnames({'profileTabs__link': true, active: activeTab === item.id})}
                  onClick={() => toggle(item.id, activeTab, setActiveTab)}
              >
                {item.title}
                  {
                      !checkAccreditation && item.key === 'profileData'
                          ? <span className="profileTabs__link_notice">!</span>
                          : ''
                  }
              </span>
                    </SwiperSlide>
                )
            }
        </Swiper>
    )
}

// Если ширина экрана больше 540px (width > 540) вывести обычные вкладки
export const renderTabLink = (role_key, activeTab, setActiveTab, checkAccreditation) => {

    return tabLinks[role_key || 'investor'].map(item => <span
            key={item.id}
            className={classnames({'profileTabs__link': true, active: activeTab === item.id})}
            onClick={() => toggle(item.id, activeTab, setActiveTab)}
        >
        {item.title}
            {
                !checkAccreditation && item.key === 'profileData'
                    ? <span className="profileTabs__link_notice">!</span>
                    : ''
            }
      </span>
    )
}

// Элементы в блоках "Данные компании", "Персональные данные", "Паспортные данные"
export const accountDataItem = (data = []) => {
    return data.map((item, idx) => {
        if (item.name) {
            return <div key={idx} className="profileTabs__tabItem_elem">
                <span className="profileTabs__tabItem_name">{item.name}</span>
                <span className={`profileTabs__tabItem_desc ${!item.desc ? 'error' : ''}`}>
          {
              item.type === 'link'
                  ? <NavLink to={item.link} className="green-color">{item.desc}</NavLink>
                  : item.desc || 'Заполните поле'
          }
        </span>
            </div>
        }
        return null
    })
}
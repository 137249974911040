import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import {qualificationsRegistryStatusKey} from "@containers/Registry/RegistryQualifications/data";

const getApplicationData = (data, primaryCheck) => {
    const {created_at, id, profile, moderator, remark} = data || {};
    const {qualification_status_key, qualified_at, unqualified_at} = profile || {};

    if (primaryCheck) return [
        {name: 'Номер заявления', desc: id ? `№${id}` : ''},
        {name: 'Дата подачи заявления', desc: created_at || ''},
    ]

    return [
        {name: 'Номер заявления', desc: id ? `№${id}` : ''},
        {
            name: 'Статус заявления',
            desc: qualification_status_key ? qualificationsRegistryStatusKey[qualification_status_key] : ''
        },
        {name: 'Дата подачи заявления', desc: created_at || ''},
        ...(
            qualification_status_key === 'qualification_qualified'
                ? [{name: 'Дата одобрения заявления', desc: qualified_at || '-'}]
                : []
        ),
        ...(
            ['qualification_not_qualified', 'qualification_rejected'].includes(qualification_status_key)
                ? [
                    {name: 'Дата аннулирования заявления', desc: unqualified_at || '-'},
                    {
                        name: 'Причины отклонения',
                        desc: <DataCard className="dataCard__line"
                                        data={[{name: `${unqualified_at}, ${moderator?.user?.fio || ''}`, desc: remark}]}/>
                    }
                ]
                : []
        )
    ]
}


const getOtherInfoDataFormat = data => {
    return data.map(item => {
        const {created_at, profile, message} = item;
        return {name: `${created_at}, ${profile?.user?.fio || ''}`, desc: message}
    })
}

export {getApplicationData, getOtherInfoDataFormat}

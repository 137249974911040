import Login from "@account/Login";
import Profile from "@account/Profile";
import Register from "@account/Register";
import NotFound404 from "@defaultPage/404";
import NoAccess403 from "@defaultPage/403";
import Portfolio from "@containers/Portfolio";
import OperationsHistory from "@containers/OperationsHistory";
import ResetPassword from "@account/ResetPassword";
import YourDocuments from "@containers/YourDocuments";
import AddProject from "@containers/Projects/AddProject";
import ProjectsFeed from "@containers/Projects/ProjectsFeed";
import AccreditationList from "@containers/Accreditation/AccreditationList"
import AccreditationProfile from "@containers/Accreditation/AccreditationProfile"
import VerificationQualification from "@containers/Moderator/VerificationQualification";
import ModerationProjects from "@containers/Moderator/ModerationProjects";
import ModerationProjectDetail from "@containers/Moderator/ModerationProjects/ModerationProjectDetail";
import Registry from "@containers/Moderator/Registry";
import RegistryProfile from "@containers/Moderator/Registry/RegistryProfile";
import RegistryProject from "@containers/Moderator/Registry/RegistryProject";
import ClientAccount from "@containers/InnerUsers/ClientAccount";
import AdminSection from "@containers/InnerUsers/AdminSection";
import AnalyticsSection from "@containers/InnerUsers/AnalyticsSection";
import SdlSection from "@containers/InnerUsers/SdlSection";
import QualificationResults from "@containers/InnerUsers/Qualification/QualificationResults";
import RegistryActionProfile from "@containers/Registry/RegistryActionProfile";
import BansAccountsRegistry from "@containers/Registry/BansAccountsRegistry";
import RegistryQualifications from "@containers/Registry/RegistryQualifications";
import InnerUsersRegistry from "@containers/Registry/InnerUsersRegistry";
import InnerUserPage from "@containers/InnerUsers/InnerUserPage";
import ModerationProjectsResults from "@containers/Moderator/ModerationProjects/ModerationProjectsResults";
import AccreditationProfileResults from "@containers/Accreditation/AccreditationProfile/AccreditationProfileResults";
import AnalyticsInvestor from "@containers/AnalyticsInvestor";
import ProjectDetailBorrower from "@containers/Projects/ProjectDetail/ProjectDetailBorrower";
import ProjectDetailInvestor from "@containers/Projects/ProjectDetail/ProjectDetailInvestor";

export const mainRouters = [
    {
        path: "/",
        auth: true,
        exact: true,
        layout: true,
        component: ProjectsFeed,
        permissions: ['borrower', 'investor']
    },
    {
        path: "/",
        auth: true,
        exact: true,
        layout: true,
        component: AccreditationList,
        permissions: ['moderator']
    },
    {
        path: "/accreditation_qualifications",
        auth: true,
        exact: true,
        layout: true,
        component: AccreditationList,
        permissions: ['admin']
    },
    {
        path: "/analytics_section",
        auth: true,
        exact: true,
        layout: true,
        component: AnalyticsSection,
        permissions: ['admin']
    },
    {
        path: "/analytics_investor",
        auth: true,
        exact: true,
        layout: true,
        component: AnalyticsInvestor,
        permissions: ['investor']
    },
    {
        path: "/sdl_section",
        auth: true,
        exact: true,
        layout: true,
        component: SdlSection,
        permissions: ['admin']
    },
    {
        path: "/",
        auth: true,
        layout: true,
        exact: true,
        component: AdminSection,
        permissions: ['admin']
    },
    {
        path: "/projects_feed",
        auth: true,
        exact: true,
        layout: true,
        component: ProjectsFeed,
        permissions: ['moderator', 'admin']
    },
    {
        path: "/portfolio",
        auth: true,
        layout: true,
        exact: true,
        component: Portfolio
    },
    {
        path: "/account",
        auth: true,
        layout: true,
        exact: true,
        component: Profile
    },
    {
        path: "/login",
        noAuth: true,
        component: Login
    },
    {
        path: "/register",
        noAuth: true,
        exact: true,
        component: Register
    },
    {
        path: "/reset-password",
        noAuth: true,
        exact: true,
        component: ResetPassword
    },
    {
        path: "/documents",
        auth: true,
        layout: true,
        exact: true,
        component: YourDocuments
    },
    {
        path: "/operations_history",
        auth: true,
        layout: true,
        exact: true,
        component: OperationsHistory
    },
    {
        path: "/404",
        component: NotFound404
    },
    {
        path: "/403",
        component: NoAccess403
    },
    {
        path: "/projects/add",
        auth: true,
        layout: true,
        exact: true,
        component: AddProject
    },
    {
        path: "/accreditation/:id",
        auth: true,
        layout: true,
        exact: true,
        component: AccreditationProfile
    },
    {
        path: "/accreditation_profile_results/:id",
        auth: true,
        layout: true,
        exact: true,
        component: AccreditationProfileResults
    },
    {
        path: "/qualification/:id",
        auth: true,
        layout: true,
        exact: true,
        component: VerificationQualification
    },
    {
        path: "/moderation_projects",
        auth: true,
        layout: true,
        exact: true,
        component: ModerationProjects
    },
    {
        path: "/moderation_projects/:id",
        auth: true,
        layout: true,
        exact: true,
        component: ModerationProjectDetail
    },
    {
        path: "/moderation_projects_results/:id",
        auth: true,
        layout: true,
        exact: true,
        component: ModerationProjectsResults
    },
    {
        path: "/registry",
        auth: true,
        layout: true,
        exact: true,
        component: Registry
    },
    {
        path: "/registry/profile",
        auth: true,
        layout: true,
        exact: true,
        component: RegistryProfile
    },
    {
        path: "/registry/project",
        auth: true,
        layout: true,
        exact: true,
        component: RegistryProject
    },
    {
        path: "/registry/project/:profile_id",
        auth: true,
        layout: true,
        exact: true,
        component: RegistryProject
    },
    {
        path: "/registry/profile_actions",
        auth: true,
        layout: true,
        exact: true,
        component: RegistryActionProfile
    },
    {
        path: "/registry/qualifications",
        auth: true,
        layout: true,
        exact: true,
        component: RegistryQualifications
    },
    {
        path: "/registry/bans_accounts",
        auth: true,
        layout: true,
        exact: true,
        component: BansAccountsRegistry
    },
    {
        path: "/registry/internal_users",
        auth: true,
        layout: true,
        exact: true,
        component: InnerUsersRegistry
    },
    {
        path: "/client_account/:id",
        auth: true,
        layout: true,
        exact: true,
        component: ClientAccount,
        permissions: ['moderator', 'admin']
    },
    {
        path: "/qualification_results/:id",
        auth: true,
        layout: true,
        exact: true,
        component: QualificationResults,
        permissions: ['moderator', 'admin']
    },
    {
        path: "/inner_user/:id",
        auth: true,
        layout: true,
        exact: true,
        component: InnerUserPage,
        permissions: ['moderator', 'admin']
    },
    {
        path: "/project-detail/:id",
        auth: true,
        layout: true,
        exact: true,
        component: ProjectDetailBorrower,
        permissions: ['borrower']
    },
    {
        path: "/project-detail/:id",
        auth: true,
        layout: true,
        exact: true,
        component: ProjectDetailInvestor,
        permissions: ['investor']
    },
]

export const accountMenu = [
    {
        title: "Привлечь инвестиции",
        path: "/attract-investment",
        noAuth: true,
        exact: true,
        component: NotFound404
    },
    {
        title: "FAQ",
        path: "/faq",
        noAuth: true,
        exact: true,
        component: NotFound404
    },
    {
        title: "Новости",
        path: "/news",
        noAuth: true,
        exact: true,
        component: NotFound404
    },
    {
        title: "Контакты",
        path: "/contacts",
        noAuth: true,
        exact: true,
        component: NotFound404
    },
]

export default [...mainRouters, ...accountMenu];

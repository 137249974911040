import {accStatusKey} from "@utils/statusKey";

export const tableHeader = [
    {id: 0, name: 'number', title: 'Номер', cellSize: 'sm'},
    {id: 1, name: 'id', title: 'Id профиля', filterType: 'searchField', cellSize: 'sm'},
    {
        id: 2,
        name: 'legal_form.personal.registration_address',
        title: 'Регион',
        role: 'borrower',
        cellSize: 'md'
    },
    {
        id: 3,
        name: 'legal_form_type',
        title: 'Правовая форма',
        cellSize: 'sm',
        filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'indiv', label: 'Физ. лицо'},
            {id: 2, name: 'ie', label: 'ИП'},
            {id: 3, name: 'entity', label: 'Юр. лицо'},
        ],
    },
    {id: 4, name: 'fio', title: 'Наименование профиля', filterType: 'searchField', cellSize: 'md'},
    {
        id: 5,
        name: 'legal_form.personal.fio',
        title: 'ФИО руководителя',
        filterType: 'searchField',
        legalFormType: 'entity',
        cellSize: 'md'
    },
    {
        id: 6,
        name: 'legal_form.company.inn',
        title: 'ИНН организации',
        legalFormType: 'entity',
        filterType: 'searchField',
        cellSize: 'sm'
    },
    {
        id: 7,
        name: 'legal_form.company.ogrn',
        title: 'ОГРН',
        legalFormType: 'entity',
        filterType: 'searchField',
        cellSize: 'md'
    },
    {
        id: 8,
        name: 'legal_form.company.kpp',
        title: 'КПП',
        legalFormType: 'entity',
        filterType: 'searchField',
        cellSize: 'sm'
    },
    {id: 9, name: 'legal_form.personal.inn', title: 'ИНН заявителя', filterType: 'searchField', cellSize: 'sm'},
    {
        id: 10,
        name: 'legal_form.personal.ogrnip',
        title: 'ОГРНИП',
        filterType: 'searchField',
        legalFormType: 'ie',
        cellSize: 'md'
    },
    {id: 11, name: 'legal_form.personal.snils', title: 'СНИЛС', filterType: 'searchField', cellSize: 'sm'},
    {
        id: 12,
        name: 'legal_form.personal.birth_date',
        title: 'Дата рождения заявителя',
        filterType: 'date',
        cellSize: 'sm'
    },
    {
        id: 13, name: 'user.role.name', title: 'Роль', cellSize: 'sm', filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'investor', label: 'Инвестор'},
            {id: 2, name: 'borrower', label: 'Заемщик'},
        ],
    },
    {
        id: 14,
        name: 'accreditation_status_key',
        title: 'Статус',
        cellSize: 'sm',
        filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: accStatusKey.accredited, label: 'Аккредитован'},
            {id: 2, name: accStatusKey.notAccredited, label: 'Не аккредитован'},
            {id: 3, name: accStatusKey.checking, label: 'На проверке'},
            {id: 3, name: accStatusKey.rejected, label: 'Отклонен'},
        ],
    },
    {
        id: 15, name: 'qualification_status_key',
        title: 'Квалификация',
        role: 'investor',
        legalFormType: 'indiv',
        cellSize: 'md',
        filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'qualification_qualified', label: 'Квалификация подтверждена'},
            {id: 2, name: 'qualification_not_qualified', label: 'Квалификация не подтверждена'},
            {id: 3, name: 'qualification_checking', label: 'На проверке'},
            {id: 3, name: 'qualification_rejected', label: 'Отклонен'},
        ],
    },
    {id: 16, name: 'created_at', title: 'Дата регистрации', filterType: 'date', cellSize: 'md'},
    {id: 17, name: 'accreditation.updated_at', title: 'Дата аккредитации', filterType: 'date', cellSize: 'md'},
    {id: 18, name: 'phone', title: 'Номер телефона', filterType: 'searchField', cellSize: 'md'},
    {id: 19, name: 'user.email', title: 'Почта', filterType: 'searchField', cellSize: 'md'},
    {id: 20, name: 'completedTransactions', title: 'Количество состоявшихся сделок', cellSize: 'md'},
    {
        id: 21,
        name: 'amountInvestmentsTransactions',
        title: 'Сумма вложенных инвестиций по состоявшимся сделкам',
        role: 'investor',
        cellSize: 'lg'
    },
    {
        id: 22,
        name: 'amountReservedInvestment',
        title: 'Сумма зарезервированных инвестиций',
        role: 'investor',
        cellSize: 'lg'
    },
    {
        id: 23,
        name: 'amountAttractedInvestments',
        title: 'Сумма привлеченных инвестиций',
        role: 'borrower',
        cellSize: 'md'
    },
]

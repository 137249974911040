import React, {useCallback, useEffect, useState} from 'react';
import {handleFilesValidate} from "@shared/components/uploadField/helpers";
import {FilesService} from "@services/file.service";
import {useDropzone} from "react-dropzone";
import {Spinner} from "reactstrap";
import {DownloadIcon} from "@shared/img/iconSvg";
import styled from "styled-components";
import classNames from 'classnames';
import FileItem from "@shared/components/uploadField/FileItem";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  color: rgba(135, 151, 182, 0.5);
  outline: none;
  transition: border .24s ease-in-out;
  min-height: 49px;
  padding: 8px 7px;
  cursor: pointer;
  &:hover {border-color: #58BF8F;}
`;

const UploadField = ({
                         files = [],
                         edit,
                         update,
                         onChange = () => {
                         },
                         fileKey,
                         accept,
                         resetValidate = false,
                         parentId = null,
                         parentType,
                         fileType,
                         multiple,
                         maxFiles = 5,
                         required,
                         formError,
                         setFormError,
                         placeholder,
                         title,
                         viewDetail,
                         titleShow,
                         notControls
                     }) => {
    const dropzonePlaceholder = accept ? `${accept} не более 5 MB` : 'не более 5 MB';
    const [spinner, setSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const onDrop = useCallback((acceptedFiles, files) => {
        if (acceptedFiles.length) {
            // Вывести сообщение об ошибке, если выбранное кол-во файлов больше чем максимально допустимое
            if ((acceptedFiles.length + files.length) > maxFiles) {
                setErrorMessage(`Максимум ${maxFiles} файлов!`)
                return null;
            }

            const addedFiles = [...files];
            let progress = 0;

            acceptedFiles.forEach(item => {
                setSpinner(true);

                const formData = new FormData();
                formData.append('file', item);
                formData.append('file_type', fileType);

                if (parentType && parentId) formData.append(parentType, parentId);


                FilesService.create(formData)
                    .then(res => {
                        if (multiple) {
                            addedFiles.push(res.data.data);
                            onChange({target: {name: fileKey, value: addedFiles}})
                        } else {
                            onChange({target: {name: fileKey, value: [res.data.data]}})
                        }
                    })
                    .catch(error => {
                        if (!errorMessage) {
                            setErrorMessage(error.message)
                        }
                    })
                    .finally(() => {
                        ++progress;
                        acceptedFiles.length === progress && setSpinner(false)
                    })
            })
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections, open
    } = useDropzone({
        accept,
        multiple: multiple,
        maxSize: 5242880,
        maxFiles: maxFiles,
        minSize: 0,
        onDrop: acceptedFiles => onDrop(acceptedFiles, files)
    });

    // Вывод текста ошибки
    useEffect(() => {
        if (fileRejections.length) {
            handleFilesValidate(fileRejections[0].errors[0].code, setErrorMessage)
        } else if (errorMessage) {
            setErrorMessage(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileRejections])

    // Сохраняем ключи невалидных полей
    useEffect(() => {
        if (required) {
            if (errorMessage) {
                const index = formError.indexOf(fileKey);
                if (index === -1) {
                    const newFormError = [...formError];
                    newFormError.push(fileKey)
                    setFormError(newFormError)
                }
            } else {
                const index = formError.indexOf(fileKey);
                if (index !== -1) {
                    const newFormError = [...formError];
                    newFormError.splice(index, 1)
                    setFormError(newFormError)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage])

    const dropzoneClass = classNames({
        uploadField__dropzone: true,
        'inputText__error': errorMessage,
        'dropzone__hide': files.length,
        'dropzone__isDragActive': isDragActive,
        'dropzone__viewDetail': viewDetail
    });

    useEffect(() => {
        if (required && resetValidate && files.length === 0) {
            setErrorMessage('Обязательно для заполнения!')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetValidate])

    return (
        <div className="uploadField__wrapper">
            {title && titleShow ? <p className="uploadField__title">{title}</p> : null}
            <div className={dropzoneClass}>
                <Container {...getRootProps({isDragActive, isDragAccept, isDragReject, errorMessage})}>
            <span className="uploadField__dropzone_desc">
              {placeholder || dropzonePlaceholder}
                <input {...getInputProps()} />
            </span>
                    <div className="uploadField__spinner_group">
                        {
                            spinner
                                ? <Spinner className={`btn-spinner ${spinner ? 'btn-spinner-visible' : ''}`}/>
                                : <div className="uploadField__download">{DownloadIcon()}</div>
                        }
                    </div>
                </Container>
                <div className="uploadField__dropzone_fiels">
                    {
                        files.length
                            ? <div className={`uploadField ${edit ? 'uploadField__edit' : ''}`}>
                                {files.map((item, idx) => (
                                    <FileItem
                                        key={idx}
                                        data={item}
                                        edit={edit}
                                        update={update}
                                        files={files}
                                        fileKey={fileKey}
                                        onChange={onChange}
                                        viewDetail={viewDetail}
                                        uploadMore={!!multiple && idx + 1 === files.length}
                                        openDropzone={open}
                                        maxFiles={maxFiles}
                                        spinner={spinner}
                                        notControls={notControls}
                                    />
                                ))}
                            </div>
                            : ''
                    }
                </div>
                {errorMessage && <span className="form__form-error">{errorMessage}</span>}
            </div>
        </div>
    );
}

export default UploadField;

import React from 'react';
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";
import Reminder from "@shared/components/projects/projectDetail/ProjectInvest/modal/reminder";
import {showModal} from "@actions/modalActions";
import {INVESTMENTS_PLATFORMS_EDITING_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";

const InvestmentsPlatformsModal = () => {
    const dispatch = useDispatch();

    return (
        <div className='investmentsPlatformsModal'>
            <BackButton/>
            <span className='textTitle'>Инвестиции на других платформах</span>
            <span className='colorSecondary fontSizeText'>
                В соответствии с Федеральным законом № 259 ФЗ сумма инвестиций для физ.
                лиц не должна превышать 600 тыс. <br/> руб. в календарном году совокупно на всех инвестиционных платформах.
                Для снятия ограничения необходимо подтвердить статус квалифицированного инвестора на странице Профиль
            </span>
            <div className='investmentsPlatformsModal__block'>
                <span className='investmentsPlatformsModal__block_desc fontSizeSubtitle'>
                    Инвестиции на других платформах за ГГГГ год - ** ₽
                    <Reminder/>
                </span>
                <span
                    className='textLine greenColor fontSizeText'
                    onClick={() => showModal(INVESTMENTS_PLATFORMS_EDITING_MODAL)(dispatch)}
                >
                    Изменить
                </span>
            </div>
        </div>
    )
};

export default InvestmentsPlatformsModal;
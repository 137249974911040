import React from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {Col, Row} from "reactstrap";
import UploadField from "@shared/components/uploadField";

const QualifiedConditionItem = ({
                                    id,
                                    inputOnChange,
                                    item,
                                    handleOnChangeFile,
                                    viewMode = false,
                                    formValidationCheck,
                                    formError
                                }) => {
    if (viewMode && !item.selected) return null

    const newData = viewMode ? {...item, items: item.items.filter(elem => elem.files.length)} : item;

    return (
        <div className="qualifiedInvestor__conditions_item">
            <InputText
                type="checkbox"
                label={`Условие ${id + 1}`}
                className='noMiddleName'
                onChange={viewMode ? () => {
                } : e => inputOnChange(e, id)}
                value={newData.selected}
                disabled={viewMode}
            />
            <p>{newData.text}</p>
            {
                newData.selected && newData?.items?.length
                    ? <div className="qualifiedInvestor__files">
                        <p>Документы, подтверждающие соблюдение условий</p>
                        <Row>
                            {
                                newData.items.map((file, fileIdx) => (
                                    <Col lg={6} key={fileIdx} className="inputText__wrapper">
                                        <label>{file.label}</label>
                                        <UploadField
                                            files={file.files}
                                            edit={!viewMode}
                                            multiple={true}
                                            maxFiles={10}
                                            title={file.label}
                                            onChange={e => handleOnChangeFile(e, id, fileIdx)}
                                            fileKey="files"
                                            fileType="qualification"
                                            accept=".jpeg, .png, .pdf"
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                        {
                            formValidationCheck && formError.includes(id)
                                ? <div className="qualifiedInvestor__form_error">
                                    <span className="error-color">Необходимо добавить не менее одного документа!</span>
                                </div>
                                : null
                        }
                    </div>
                    : null

            }
        </div>
    )
}

export default QualifiedConditionItem;
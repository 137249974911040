import React from 'react';
import {tabKeys} from "@containers/InnerUsers/AdminSection/helpers";
import LoanTransfer from "@containers/InnerUsers/AdminSection/AdminSectionContent/LoanTransfer";
import NominalAccountStatus from "@containers/InnerUsers/AdminSection/AdminSectionContent/NominalAccountStatus";
import PaymentCalendar from "@containers/InnerUsers/AdminSection/AdminSectionContent/PaymentCalendar";
import ConfirmationCodes from "@containers/InnerUsers/AdminSection/AdminSectionContent/ConfirmationCodes";
import PlatformRates from "@containers/InnerUsers/AdminSection/AdminSectionContent/PlatformRates";
import ReplacingDocuments from "@containers/InnerUsers/AdminSection/AdminSectionContent/ReplacingDocuments";

const AdminSectionContent = ({activeTab}) => {
    const renderContent = () => {
        switch (activeTab) {
            case tabKeys[0].key:
                return <LoanTransfer/>
            case tabKeys[1].key:
                return <NominalAccountStatus/>
            case tabKeys[2].key:
                return <PaymentCalendar/>
            case tabKeys[3].key:
                return (
                    <div className="adminSection__confirmCodes">
                        <ConfirmationCodes type="email" title="Коды подтверждения Email"/>
                        <ConfirmationCodes type="phone" title="Коды подтверждения СМС"/>
                    </div>
                )
            case tabKeys[4].key:
                return <ReplacingDocuments/>
            case tabKeys[5].key:
                return <PlatformRates/>
            default:
                return null
        }
    }

    return (
        <div className="accreditationList">
            {renderContent()}
        </div>
    )
}

export default AdminSectionContent;
import React from 'react';
import PrimaryTooltip from "@shared/components/tooltip";

const LoanCollectedFunds = () => (
    <div className='loanCollectedFunds'>
        <div className='loanCollectedFunds__title'>
            <span className='loanCollectedFunds__title_text'>Счет, на который будут переведены собранные средства</span>
            <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                <p>
                    1. Проект перешел к стадии подписания документов.
                    2. Проект перешел к стадии выплат
                    3. Проект перешел в просрочку
                    4. Проект завершен
                    5. Проект отменен
                    6. Проект не состоялся
                </p>
            </PrimaryTooltip>
        </div>
        <div className='loanCollectedFunds__desc'>
            <div className='loanCollectedFunds__desc_block'>
                <span>Наименование банка</span>
                <span>“Тинькофф Банк”</span>
            </div>
            <div className='loanCollectedFunds__desc_block'>
                <span>ИНН банка</span>
                <span>7707083893</span>
            </div>
            <div className='loanCollectedFunds__desc_block'>
                <span>БИК</span>
                <span>7707083893</span>
            </div>
            <div className='loanCollectedFunds__desc_block'>
                <span>Корр. счет</span>
                <span>30101810800000000132</span>
            </div>
            <div className='loanCollectedFunds__desc_block'>
                <span>Расчетный счет</span>
                <span>30101810800000000132</span>
            </div>
        </div>
    </div>
);


export default LoanCollectedFunds;
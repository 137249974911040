import {ModerationsService} from "@services/moderator.service";
import {OuterProfilesService, OuterProjectsService} from "@services/outer.service";

export const SET_MODERATION_PROFILE_ID = 'SET_MODERATION_PROFILE_ID';

export const getProjectModeration = (id) => {
    const params = {
        include: [
            'project.profile.legal_form',
            'project.profile.user',
            'project.profile.user.roles',
            'project.condition',
            'project.company',
            'project.medias',
            'project.documents',
            'project.pledges',
            'project.government',
            'project.analytic',
            'project.profile.bank_details',
            'project.moderations',
            'project.moderations.remarks.profile',
            'project.moderations.remarks.profile.user',
            'remarks.profile.user',
        ].join(',')
    }

    return ModerationsService.getById(id, params)
        .catch(error => console.log(error))
}

export const getModerationProfiles = filterState => {
    let searchFormat = '';
    let searchFieldsFormat = '';
    let fio = filterState?.search?.fio || '';
    const include = [
        'legal_form',
        'legal_form.questionnaire',
        'user',
        'user.roles',
        'accreditation',
    ];

    Object.keys(filterState.search).forEach(item => {
        if (filterState.search[item] && filterState.search[item].length && item !== 'fio') {
            searchFormat += `${item}:${typeof filterState.search[item] === 'string' ? filterState.search[item].trim() : filterState.search[item]};`
            searchFieldsFormat += `${item}:${filterState.searchFields[item]};`
        }
    })

    const filterDataFormat = {
        include: include.join(','),
        sortedBy: filterState.sortedBy.join(';'),
        orderBy: filterState.orderBy.join(';'),
        search: searchFormat,
        searchFields: searchFieldsFormat,
        page: filterState.currentPage,
        limit: 20,
        fio: fio.trim()
    };

    return OuterProfilesService.getList(filterDataFormat)
        .catch(error => console.log(error))
}

export const getModerationProjects = filterState => {
    let searchFormat = '';
    let searchFieldsFormat = '';
    const include = [
        'condition',
        'profile',
        'profile.legal_form',
        'pledges'
    ];

    Object.keys(filterState.search).forEach(item => {
        if (filterState.search[item] && filterState.search[item].length) {
            if (Array.isArray(filterState.search[item])) {
                const sortingEmpty = filterState.search[item].filter(elem => elem);

                if (sortingEmpty.length) searchFormat += `${item}:${sortingEmpty};`
            } else {
                searchFormat += `${item}:${filterState.search[item].trim()};`
            }

            searchFieldsFormat += `${item}:${filterState.searchFields[item]};`
        }
    })

    const filterDataFormat = {
        include: include.join(','),
        sortedBy: filterState.sortedBy.join(';'),
        orderBy: filterState.orderBy.join(';'),
        search: searchFormat,
        searchFields: searchFieldsFormat,
        page: filterState.currentPage,
        limit: 20
    };

    return OuterProjectsService.getList(filterDataFormat)
        .catch(error => console.log(error))
}




import React from 'react';

const ProfileIsBanned = ({comment}) => {
    return (
        <div className="accStatusNotice accStatusNotice__error clientAccount__notice">
            <div className="accStatusNotice__header">
                <p>Профиль заблокирован</p>
            </div>
            {
                comment
                    ? <div className="accStatusNotice__content">
                        <p>{comment}</p>
                    </div>
                    : null
            }
        </div>
    )
}

export default ProfileIsBanned;
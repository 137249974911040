import React, {useReducer} from 'react';
import {Button, Spinner} from "reactstrap";
import {initState, innerUserFormReducer} from "@containers/InnerUsers/InnerUserPage/InnerUserForm/helpers";
import innerUserFormActions from "@containers/InnerUsers/InnerUserPage/InnerUserForm/action";
import InnerUserForm from "@containers/InnerUsers/InnerUserPage/InnerUserForm";
import {UsersService} from "@services/users.service";

const InnerUserEditData = ({data: initData = {}, setEditStatus, setUpdateUserData}) => {
    const [state, dispatch] = useReducer(innerUserFormReducer, {...initData, formType: 'update'}, initState);
    const {data, oldData, formError, userAddStatus: userDataUpdateStatus} = state || {};
    const {editState} = innerUserFormActions;

    const onChangeState = (key, value) => editState(key, value)(dispatch)

    const updateData = () => {
        const emptyFields = Object.keys(data).filter(item => !data[item] && item !== 'other_information');
        onChangeState('resetValidate', true)

        if (!formError.length && !emptyFields.length) {
            const dataWithoutSpaces = {};

            onChangeState('userAddStatus', true)
            Object.keys(data)
                .filter(item => data[item] !== oldData[item])
                .forEach(item => dataWithoutSpaces[item] = item === 'roles' ? data[item] : data[item].trim())

            UsersService.update(initData.id, {
                ...dataWithoutSpaces,
                other_information: data.other_information,
                include: 'roles'
            })
                .then(() => {
                    setUpdateUserData(prev => !prev)
                    onChangeState('userAddStatus', false)
                    setEditStatus(false)
                })
                .catch(error => {
                    if (error?.errors) onChangeState('requestErrors', error.errors)
                    onChangeState('userAddStatus', false)
                })
        }
    }

    return (
        <div className="addProject__page">
            <div className="dataCard innerUserPage__editData">
                <p className="dataCard__title">Данные пользователя</p>
                <div className="innerUserPage__form">
                    <InnerUserForm state={state} dispatch={dispatch} formType="update"/>
                </div>
            </div>
            <div className="innerUserPage__btnGroup">
                <Button outline color="danger" size="sm" onClick={() => setEditStatus(false)}>
                    Отменить
                </Button>
                <Button color="primary" size="sm" onClick={updateData}>
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${userDataUpdateStatus ? 'btn-spinner-visible' : ''}`}/>
                        Сохранить
                    </div>
                </Button>
            </div>
        </div>
    )
};

export default InnerUserEditData;
import React from 'react';
import AccountWrapper from '@account/AccountWrapper';
import AccountFormWrapper from '@shared/components/account/AccountFormWrapper';
import RegisterForm from "@shared/components/form/registerForm";

const Register = () => {
    return (
        <AccountWrapper>
            <AccountFormWrapper title="Регистрация" form={RegisterForm}/>
        </AccountWrapper>
    );
};

export default Register;

import React, {useEffect, useState} from 'react';
import {Button} from "reactstrap";
import Masonry from "react-masonry-css";
import {QualificationsService} from "@services/qualifications.service";
import QualifiedFormConditions from "../QualifiedForm/QualifiedFormConditions";
import QualifiedFormGuarantee from "../QualifiedForm/QualifiedFormGuarantee";
import AccountPersonalData from "@shared/components/account/accountPersonalData";
import AccountPassportData from "@shared/components/account/accountPassportData";
import ApplicationData from "@shared/components/presentation/Qualification/ApplicationData";
import {getQualificationsRequestInclude} from "@containers/Moderator/VerificationQualification/helpers";

const InnerUsersQualifiedApplication = ({modalData}) => {
    const [data, setData] = useState(null);
    const [checkRequired, setCheckRequired] = useState(null);
    const qualificationId = modalData;

    const newCheck = () => {
    }

    const getQualificationData = () => (
        QualificationsService.getById(qualificationId, {include: getQualificationsRequestInclude.join(',')})
            .then(res => setData(res?.data?.data))
            .catch(error => console.error(error))
    )

    useEffect(() => {
        if (qualificationId) getQualificationData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qualificationId])

    return (
        <div className="qualifiedInvestor__modal">
            <p className="modalFullScreen__title">Заявление о признании физ. лица квалифицированным инвестором</p>
            <div className="qualifiedInvestor__info profileTabs">
                <Masonry
                    breakpointCols={{default: 2, 1400: 1}}
                    className="my-masonry-grid clientAccount__masonryGrid custom-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    <AccountPersonalData editForm={false} qualifiedApplication profileData={data?.profile || {}}/>
                    <ApplicationData data={data} getQualificationData={getQualificationData}/>
                    <AccountPassportData editForm={false} profileData={data?.profile || {}}/>
                </Masonry>
            </div>
            <div className="qualifiedInvestor__form qualifiedInvestor__innerUsersForm">
                <p className="modalFullScreen__subTitle">Выбранные клиентом условия</p>
                {
                    data?.conditions?.conditions?.length && data?.conditions?.guarantee &&
                    <>
                        <QualifiedFormConditions
                            applicationData={data?.conditions?.conditions}
                            setData={setData}
                            viewMode={true}
                        />
                        <QualifiedFormGuarantee
                            data={data}
                            setData={setData}
                            checkRequired={checkRequired}
                            setCheckRequired={setCheckRequired}
                            applicationData={data?.conditions?.guarantee}
                            viewMode={true}
                        />
                    </>
                }
            </div>
            <div>
                <Button color="primary" size="lg" onClick={newCheck}>
                    Начать проверку
                </Button>
            </div>
        </div>
    )
}

export default InnerUsersQualifiedApplication;
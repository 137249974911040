export const initialData = (data = {}) => {
    if (!data) return {};

    const {legal_form: {company, personal, passport, questionnaire}, legal_form_type, user, phone} = data;
    const {issue_date, issued_by, serial_number, department_code, first_photo, second_photo} = passport || {};
    const {name, inn, ogrn, legal_address, actual_address, address_matches, charters} = company || {};
    const responses = questionnaire?.responses || [];

    const {
        fio, birth_date, inn: innPersonal, birth_place, address_matches: addressMatchesPersonal, registration_address,
        residence_address, ogrnip, without_patronymic, snils, position
    } = personal || {};

    const fieldPosition = legal_form_type === 'entity' ? {position: position || ''} : {};
    const snilsField = user?.roles[0]?.name === 'borrower' && legal_form_type !== 'indiv' ? {snils: snils || ''} : {};
    const fieldOgrnip = legal_form_type === 'ie' ? {ogrnip: ogrnip || ''} : {};
    const fieldForBorrower = user?.roles[0]?.name === 'borrower' && legal_form_type !== 'indiv'
        ? {
            residence_address: addressMatchesPersonal ? registration_address || '' : residence_address || '',
            address_matches: !!addressMatchesPersonal
        }
        : {}

    const additionalInfo = responses.map((item, idx) => {
        let person = {};
        let persons = {};
        let personIdx = 0;
        const {values = []} = item;
        values.forEach(item => {
            if (personIdx === item.person) {
                person[item.field.key] = {
                    field_id: item.field_id,
                    person: item.person,
                    value: item.value,
                    field: item.field,
                    files: item.files ? item.files : null
                }
            } else {
                persons[`person${personIdx}`] = {...person};
                person = {};
                person[item.field.key] = {
                    field_id: item.field_id,
                    person: item.person,
                    value: item.value,
                    field: item.field,
                    files: item.files ? item.files : null
                }
                personIdx = item.person
            }
        });

        persons[`person${personIdx}`] = {...person}
        persons.id = item.id

        return persons;
    });

    return {
        is_draft: false,
        resetValidate: false,
        additionalInfo,
        additionalInfoResponses: responses,
        company: {
            name: name || '',
            inn: inn || '',
            ogrn: ogrn || '',
            charters: charters || [],
            legal_address: legal_address || '',
            actual_address: address_matches ? legal_address : actual_address || '',
            address_matches: address_matches || false,
        },
        personal: {
            fio: fio || '',
            without_patronymic: without_patronymic || false,
            birth_date: birth_date || '',
            phone: phone || '',
            phone_code: '',
            ...fieldPosition,
            ...fieldOgrnip,
            ...snilsField,
            inn: innPersonal || '',
            birth_place: birth_place || '',
            registration_address: registration_address || '',
            ...fieldForBorrower
        },
        passport: {
            issue_date: issue_date || '',
            issued_by: issued_by || '',
            serial_number: serial_number || '',
            department_code: department_code || '',
            first_photo: first_photo ? [first_photo] : [],
            second_photo: second_photo ? [second_photo] : [],
        },
    }
}

export const errorInitialData = (data) => {
    const responses = data?.legal_form?.questionnaire?.responses || [];
    const additionalInfo = new Array(responses.length).fill([]);

    return ({
        company: [],
        personal: [],
        passport: [],
        additionalInfo,
    })
}
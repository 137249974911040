import {initState} from './data';
import React from 'react';

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_CHECKBOX_VALUE = 'SET_CHECKBOX_VALUE';
export const SET_DATE_VALUE = 'SET_DATE_VALUE';
export const SET_SEARCH_FIELDS_VALUE = 'SET_SEARCH_FIELDS_VALUE';
export const FILTER_RESET = 'FILTER_RESET';
export const SET_VISIBLE = 'SET_VISIBLE';
export const SET_ALL_VISIBLE = 'SET_ALL_VISIBLE';
export const SET_SORTED = 'SET_SORTED';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const reducer = (state, action) => {
    const {name, value, key, sort} = action.payload || {};

    switch (action.type) {
        case SET_SEARCH_VALUE:
            return {...state, search: {...state.search, [name]: value}}
        case SET_SEARCH_FIELDS_VALUE:
            return {...state, searchFields: {...state.searchFields, [name]: value}}
        case SET_VISIBLE:
            const newTableHeaderVisible = [...state.tableHeaderVisible];
            const index = newTableHeaderVisible.indexOf(name);
            index !== -1 ? newTableHeaderVisible.splice(index, 1) : newTableHeaderVisible.push(name)

            return {...state, tableHeaderVisible: newTableHeaderVisible}
        case SET_CHECKBOX_VALUE:
            const searchCheckboxCopy = [...state.search[name]];
            const valueIndex = searchCheckboxCopy.indexOf(value);
            valueIndex !== -1 ? searchCheckboxCopy.splice(valueIndex, 1) : searchCheckboxCopy.push(value)

            return {...state, search: {...state.search, [name]: searchCheckboxCopy}}
        case SET_ALL_VISIBLE:
            return {...state, tableHeaderVisible: initState.tableHeaderVisible}
        case SET_DATE_VALUE:
            const newSearchDate = [...state.search[key]];
            let newFieldsDate = '';
            name === 'from' ? newSearchDate[0] = value : newSearchDate[1] = value

            if (newSearchDate[0] && newSearchDate[1]) newFieldsDate = 'between'
            if (!newSearchDate[0] && newSearchDate[1]) newFieldsDate = '<'
            if (newSearchDate[0] && !newSearchDate[1]) newFieldsDate = '>'

            return {
                ...state,
                search: {...state.search, [key]: newSearchDate},
                searchFields: {...state.searchFields, [key]: newFieldsDate}
            }
        case SET_SORTED:
            const sortedByCopy = [...state.sortedBy];
            const orderByCopy = [...state.orderBy];
            const orderIndex = orderByCopy.indexOf(key);

            if (orderIndex !== -1) {
                sortedByCopy[orderIndex] = sort;
            } else {
                orderByCopy.push(key)
                sortedByCopy.push(sort)
            }

            return {...state, sortedBy: sortedByCopy, orderBy: orderByCopy}
        case FILTER_RESET:
            return {...initState}
        case SET_CURRENT_PAGE:
            return {...state, currentPage: action.payload}

        default:
            return {...state}
    }
}

export const dataFormat = (data, reduxDispatch) => {
    const openReasonBlocking = id => {
        console.log("openReasonBlocking")
    }

    if (data?.length) {
        return data.map((item, idx) => {
            const {id, user, banned_at, bannedBy} = item || {};

            return {
                'link': ['bannedBy.user.fio'],
                'linkUrl': [`/inner_user/${bannedBy?.user?.id}`],
                'number': `№${id}`,
                'user.email': user.email || '-',
                'banned_at': banned_at || '-',
                'bannedBy.user.fio': bannedBy?.user?.fio || '-',
                'reason': <button
                    className="green-color btn-reset"
                    onClick={() => openReasonBlocking(id)}
                >
                    Подробнее
                </button>
            }
        })
    }

    return []
}



import React, {useEffect, useState} from 'react';
import {ConfirmationsAdminService} from "@services/confirmations.service";
import ConfirmationCodesTable from "./ConfirmationCodesTable";
import {tableHeader} from "./helpers";
import Pagination from "@shared/components/pagination";

const ConfirmationCodes = ({type, title}) => {
    const [codeList, setCodeList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        ConfirmationsAdminService.getList({
            type,
            page: currentPage,
            limit: 5,
            sortedBy: 'desc',
            orderBy: 'created_at'
        })
            .then(res => setCodeList(res?.data || []))
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }, [currentPage, type])

    return (
        <div className="adminSection__confirmCodes_table">
            <ConfirmationCodesTable
                tableHeader={tableHeader[type]}
                tableContent={codeList.data}
                loading={loading}
                title={title}
            />
            <Pagination
                className="pagination-bar"
                meta={codeList?.meta || {}}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>
    )
}

export default ConfirmationCodes;
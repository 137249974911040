// Обработка ошибок при подтверждении почты
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import PhoneInTalkOutlineIcon from "mdi-react/PhoneInTalkOutlineIcon";
import {phoneMask} from "@shared/components/account/helpers";

const getConfirmError = (error, setError, parentType) => {
    const errorMessage = error?.errors[parentType]?.length ? error.errors[parentType][0] : null;

    switch (error.status) {
        case 422:
            setError(errorMessage ? errorMessage : 'Ошибка валидации!');
            break;
        case 500:
            setError(`Не удалось отправить ${parentType === 'email' ? 'письмо' : 'смс'}!`);
            break;
        default:
            setError('Неизвестная ошибка!')
    }
}

// Настройки поля в зависимости от типа (email|phone)
const getInputSetting = type => {
    return {
        fieldIcon: type === 'email' ? AlternateEmailIcon : PhoneInTalkOutlineIcon,
        fieldLabel: type === 'email' ? 'Email' : 'Телефон',
        fieldType: type === 'email' ? 'email' : 'mask',
        fieldMask: type === 'email' ? '' : phoneMask,
        fieldPlaceholder: type === 'email' ? 'exaple@gmail.com' : '+7 (999) 999-99-99',
        fieldValidator: type === 'email'
            ? [{maxLength: 255}, {email: true}, {required: true}]
            : [{numbersCount: 11}, {required: true}],
    }
}

export {
    getConfirmError,
    getInputSetting
}
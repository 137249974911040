import React from 'react';
import {documentIcon, DownloadFile} from "@shared/img/iconSvg"
import {Link} from "react-router-dom";

const CreditHistoryCheck = ({fRef = null}) => {
    return (
        <div className="creditCheck" ref={fRef}>
            <span className="creditCheck__title">Проверка кредитной истории</span>
            <div className="creditCheck__group">
                <div className="creditCheck__icon">
                    {documentIcon()}
                </div>
                <div className="creditCheck__content">
                    <div className="creditCheck__doc creditCheck__flex">
                        <span>Согласие на проверку кредитной истории.doc</span>
                        <div className="creditCheck__doc_icon">
                            <DownloadFile/>
                        </div>
                    </div>
                    <div className="creditCheck__services creditCheck__flex">
                        <div className="creditCheck__mess creditCheck__mess_error">
                            <span>Ожидается ваша подпись </span>
                        </div>
                        <div className="creditCheck__services_link">
                            <Link to="#">Перейти к подписанию</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreditHistoryCheck;
import React, {useEffect, useState} from 'react';
import {clock, getFullDate} from '@utils/date';
import TopbarProfile from './TopbarProfile';
import TopbarNotification from "@layout/topbar/TopbarNotification";
import {Link} from "react-router-dom";
import {WalletIcon} from "@shared/img/iconSvg";

const Topbar = ({changeMobileSidebarVisibility}) => {
    const [clockValue, setClockValue] = useState(clock());
    const [topBarBg, setTopBarBg] = useState(false);

    // Обнавление часов
    useEffect(() => {
        const clockInterval = setInterval(() => setClockValue(clock()), 20000);
        return () => clearInterval(clockInterval);
    }, []);

    // При скроле страницы добавить фон к шапке
    useEffect(() => {
        if (typeof window !== "undefined") window.onscroll = () => setTopBarBg(window.pageYOffset > 30)
        return () => window.onscroll = null;
    }, []);

    return (
        <div className={`topbar ${topBarBg ? 'topbar__scroll' : ''}`}>
            <div className="topbar__wrapper">
                <div className="topbar__left">
                    <div className="topbar__button--mobile">
                        <Link to="/">
                            <div className="topbar__logo--mobile"/>
                        </Link>
                        <div className="topbar__toggler" onClick={changeMobileSidebarVisibility}>
                            <svg width="28" height="19" viewBox="0 0 28 19" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.575195" y="0.564453" width="27.3508" height="2.00126" rx="1" fill="#8797B6"
                                      fillOpacity="0.5"/>
                                <rect x="0.575195" y="8.49927" width="27.3508" height="2.00126" rx="1" fill="#8797B6"
                                      fillOpacity="0.5"/>
                                <rect x="0.575195" y="16.4346" width="27.3508" height="2.00126" rx="1" fill="#8797B6"
                                      fillOpacity="0.5"/>
                            </svg>
                        </div>
                    </div>
                    <div className="topbar__date">
                        <span>{`${getFullDate()}, ${clockValue}`}</span>
                    </div>
                </div>
                <div className="topbar__right">
                    <div className="topbar__balance topbar__notification">
                        <WalletIcon/>
                        <p>Баланс: <span>90 000 000,00 ₽</span></p>
                    </div>
                    <TopbarNotification/>
                    <TopbarProfile/>
                </div>
            </div>
        </div>
    )
};

export default Topbar;

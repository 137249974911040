import React, {useState} from 'react';
import {EDIT_PROJECT_VALUE} from "@actions/projectsActions";
import {useDispatch} from "react-redux";
import {Button} from "reactstrap";
import UploadField from "@shared/components/uploadField";

const DetailedCalculations = ({data}) => {
    const dispatch = useDispatch();
    const {repayment_key} = data;
    const repaymentKeys = ['principal_and_annual_interest', 'principal_and_fixed_interest'];
    const [repaymentMethod, setRepaymentMethod] = useState(repaymentKeys.includes(repayment_key) ? 0 : 1);

    const setData = (name, value) => {
        dispatch({
            type: EDIT_PROJECT_VALUE,
            payload: {
                name,
                value: value || null,
                dataKey: 'condition',
            }
        })
    }

    const handleOnChangeRepaymentMethod = value => {
        setData('repayment_key', null);
        setRepaymentMethod(value)
    }

    return (
        <div className='detailedCalculations'>
            <span className='detailedCalculations__title'>Детализация расчетов</span>
            <div className='detailedCalculations__desc'>
                <div className='detailedCalculations__descButton'>
                    <span className='detailedCalculations__descButton_title'>Тип расчета</span>
                    <div className="addProject__formGroup paymentType__mainBtn">
                        <button
                            className={`from__custom-radio-btn ${repaymentMethod === 0 && 'active'}`}
                            onClick={() => handleOnChangeRepaymentMethod(0)}
                        >
                            <span>Общий</span>
                        </button>
                        <button
                            className={`from__custom-radio-btn ${repaymentMethod === 1 && 'active'}`}
                            onClick={() => handleOnChangeRepaymentMethod(1)}
                        >
                            <span>По инвестору</span>
                        </button>
                    </div>
                </div>
                <div className='detailedCalculations__descInput'>
                    <span className='detailedCalculations__descInput_title'>ID инвестора</span>
                    <div className='detailedCalculations__descInput_info'>
                        <input type="text"/>
                        <Button
                            className='notAccreditedProject__button'
                            color="primary"
                            size="lg"
                        >
                            Сформировать
                        </Button>
                    </div>
                </div>
            </div>
            <div className='detailedCalculations__document'>
                <span>Расчет задолженности по договору займа №12345 от хх.хх.хххх</span>
                <UploadField viewDetail files={[data.company.logo]} edit={false}/>
            </div>
        </div>
    )
};

export default DetailedCalculations;
import React from 'react';
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";
import {Button} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {CAP_NOT_REQUIRED_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const InvestmentsPlatformsEditingTwoModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className='platformsEditingTwoModal investmentsPlatformsModal'>
            <BackButton/>
            <span className='textTitle'>Вы уверены что хотите изменить сумму инвестиций на других платформах? </span>
            <span className='investmentsPlatformsModal__desc fontSizeText colorSecondary'>
                После подтверждения уменьшить сумму будет нельзя
            </span>
            <div className='investModalButtons'>
                <Button
                    className='loanOptionsEditButton__button'
                    color="danger"
                    size="sm"
                    outline
                    onClick={() => history.push("/project-detail/101010")}
                >
                    Отменить
                </Button>
                <Button
                    color="primary"
                    size="lg"
                    onClick={() => showModal(CAP_NOT_REQUIRED_MODAL)(dispatch)}
                >
                    Подтвердить
                </Button>
            </div>
        </div>
    )
};

export default InvestmentsPlatformsEditingTwoModal;
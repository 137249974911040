import React, {useState} from 'react';
import {DocumentNotice, DocumentSearch} from "@shared/img/iconSvg";
import ProjectsFeedInfo from "@containers/Projects/ProjectsFeed/ProjectsFeedHeader/ProjectsFeedInfo";

const NoProjectsNotification = () => {
    const [emailNotice, setEmailNotice] = useState(false);

    const handleBtnOnClick = () => {
        setEmailNotice(!emailNotice)
    }

    if (!emailNotice) {
        return (
            <ProjectsFeedInfo
                Icon={DocumentSearch}
                btnTitle="Оповестить, когда проекты появятся"
                btnOnClick={handleBtnOnClick}
                desc="Пока на платформе нет доступных проектов"
            />
        )
    }

    return (
        <ProjectsFeedInfo
            Icon={DocumentNotice}
            btnTitle="Выключить уведомления"
            btnOnClick={handleBtnOnClick}
            desc="Email-уведомления о новых проектах включены. Мы пришлем информацию, когда проекты появятся"
        />
    )
}

export default NoProjectsNotification;
import React from 'react';
import {addProjectMenu} from "@containers/Projects/AddProject/helpers";
import DocumentsForm from './DocumentsForm';
import {useSelector} from "react-redux";

const Documents = ({step}) => {
    const {documents, id} = useSelector(state => state.projects.projectEditData);

    return <DocumentsForm
        fRef={addProjectMenu[step].links[0].ref}
        data={documents}
        projectId={id}
    />
}

export default Documents;
import React from 'react';
import UploadField from "@shared/components/uploadField";

const AboutTheBorrowerInfo = ({data}) => {
    const dataAboutTheBorrower = [
        {id: 0, name: 'Наименование/ФИО', value: data.profile.legal_form.personal.fio},
        {id: 1, name: 'Год основания', value: data.company.foundation_year},
        {id: 2, name: 'ОГРН/ОГРНИП', value: '1234567890'},
        {id: 3, name: 'ИНН', value: data.profile.legal_form.personal.inn},
        {id: 4, name: 'КПП', value: '1234567890'},
        {id: 5, name: 'Сайт', value: 'https//incrowd.ru'},
        {id: 6, name: 'Основной вид деятельности', value: data.company.primary_occupation},
        {id: 7, name: 'Юридический адрес', value: data.profile.legal_form.personal.registration_address},
        {id: 8, name: 'Фактический адрес', value: data.profile.legal_form.personal.registration_address},
        {id: 9, name: 'Логотип', type: 'file', value: data.company.logo},
    ]

    return (
        <div className='aboutTheBorrower__info'>
            <div className='aboutTheBorrower__infoList'>
                <span className='aboutTheBorrower__infoList_data'>Данные актуальны на **.**.****г.</span>
                <span className='aboutTheBorrower__infoList_title'>О заемщике</span>
                {
                    dataAboutTheBorrower.map(elem => (
                        <div key={elem.id} className='aboutTheBorrower__infoList_block'>
                            <span>{elem.name}</span>
                            {
                                elem.type === 'file'
                                    ? <div className='aboutTheBorrower__infoList_logo'><UploadField viewDetail
                                                                                                    files={[elem.value]}
                                                                                                    edit={false}/></div>
                                    : <span
                                        className={elem.name === 'Сайт'
                                            ? 'aboutTheBorrower__infoList_website' : ''}
                                    >
                                            {elem.value}
                                    </span>
                            }
                        </div>
                    ))
                }
            </div>
            <div className='aboutTheBorrower__intelligence'>
                    <span className='aboutTheBorrower__infoList_title'>
                        Сведения о структуре и персональном составе
                        органов управления юридического лица
                    </span>
                <span className='aboutTheBorrower__intelligence_subtitle'>Общее собрание</span>
                <div className='aboutTheBorrower__intelligenceDesc aboutTheBorrower__infoList_block'>
                    <div className='aboutTheBorrower__intelligenceDesc_block'>
                        <span>Наименование участника</span>
                        <span>{data.government.participants_name}</span>
                    </div>
                    <div className='aboutTheBorrower__intelligenceDesc_block'>
                        <span>Процент доли в УК</span>
                        <span>23%</span>
                    </div>
                </div>
                <div className='aboutTheBorrower__intelligenceDesc aboutTheBorrower__infoList_block'>
                    <div className='aboutTheBorrower__intelligenceDesc_block'>
                        <span>Наименование участника</span>
                        <span>{data.government.participants_name}</span>
                    </div>
                    <div className='aboutTheBorrower__intelligenceDesc_block'>
                        <span>Процент доли в УК</span>
                        <span>23%</span>
                    </div>
                </div>
                <span className='aboutTheBorrower__intelligence_subtitle'>
                        Сведения о лицах, имеющих право распоряжаться не менее
                        чем 10 процентами голосов в высшем органе управления
                        юридического лица, если таким лицом является корпорация
                    </span>
                <div className='aboutTheBorrower__infoList_block'>
                    <div className='aboutTheBorrower__intelligenceDesc'>
                        <div className='aboutTheBorrower__intelligenceDesc_block'>
                            <span>Наименование участника</span>
                            <span>{data.government.participants_name}</span>
                        </div>
                        <div className='aboutTheBorrower__intelligenceDesc_block'>
                            <span>Процент доли в УК</span>
                            <span>23%</span>
                        </div>
                    </div>
                    <div
                        className='aboutTheBorrower__intelligenceDesc_block aboutTheBorrower__intelligenceDesc_address'>
                        <span>Адрес местонахождения</span>
                        <span>{data.government.persons_information[0].address}</span>
                    </div>
                </div>
                <div className='aboutTheBorrower__infoList_block'>
                    <div className='aboutTheBorrower__intelligenceDesc'>
                        <div className='aboutTheBorrower__intelligenceDesc_block'>
                            <span>Наименование участника</span>
                            <span>{data.government.participants_name}</span>
                        </div>
                        <div className='aboutTheBorrower__intelligenceDesc_block'>
                            <span>Процент доли в УК</span>
                            <span>23%</span>
                        </div>
                    </div>
                    <div
                        className='aboutTheBorrower__intelligenceDesc_block aboutTheBorrower__intelligenceDesc_address'>
                        <span>Адрес местонахождения</span>
                        <span>{data.government.persons_information[0].address}</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AboutTheBorrowerInfo;
// Настройки поля в зависимости от типа (fio|company)
const getInputSetting = type => {
    return {
        fieldLabel: type === 'fio' ? 'ФИО' : 'Наименование',
        fieldPlaceholder: type === 'fio' ? 'Иванов Иван Иванович' : 'ООО Ромашка',
    }
}

export {
    getInputSetting
}
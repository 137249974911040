import React from 'react';
import PropTypes from "prop-types";
import InvoiceTemplates from '@shared/components/account/bankDetails/borrower/InvoiceTemplates';
import {Row} from "reactstrap";
import {BankDetailsRender} from "@shared/components/account/bankDetails/helpers";

const BankDetailsBorrower = ({data}) => {
    return (
        <>
            <div className="bankDetails bankDetails__borrower">
                <InvoiceTemplates bankData={data?.bank_details || []}/>
            </div>
            <Row className="bankDetails bankDetails__borrower">
                {
                    data?.bank_details?.length
                        ? data.bank_details.map((item, idx) => {
                            return <BankDetailsRender notEditable={data.isBanned} data={item} key={idx}/>
                        })
                        : null
                }
            </Row>
        </>
    )
}

BankDetailsBorrower.propTypes = {
    bankData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

BankDetailsBorrower.defaultProps = {
    bankData: [],
};

export default BankDetailsBorrower;
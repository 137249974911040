import {dateOptions} from "@shared/components/account/helpers";

const months = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
];

const days = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

export const clock = () => {
    const date = new Date();
    const hours = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours();
    const minutes = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes();

    return `${hours}:${minutes}`
}

export const getFullDate = () => {
    const d = new Date();
    const date = d.getDate();
    const monthName = months[d.getMonth()];
    const dayName = days[d.getDay()]

    return `${date} ${monthName}, ${dayName.toLowerCase()}`
}

export const dateNewDate = date => {
    if (typeof date === 'string' && date.length) {
        return new Date(date.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'))
    }

    return date
}

export const shortDate = date => {
    if (!!date) {
        const newDate = new Date(dateNewDate(date));
        return newDate.toLocaleString("ru", dateOptions);
    }

    return date
}



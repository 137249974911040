import React from 'react';

const ExpertOpinion = () => {
    return (
        <div>
            <div className='expertOpinion'>
                <span className='expertOpinion__title'>Заключение эксперта</span>
                <span className='expertOpinion__subtitle'>
                    Прежде всего, существующая теория обеспечивает широкому кругу
                    (специалистов) участие в формировании дальнейших направлений развития.
                </span>
                <span className='expertOpinion__desc'>
                    Сложно сказать, почему реплицированные с зарубежных источников,
                    современные исследования преданы социально-демократической анафеме.
                    Безусловно, повышение уровня гражданского сознания в значительной
                    степени обусловливает важность системы массового участия.
                </span>
            </div>
        </div>
    )
};

export default ExpertOpinion;
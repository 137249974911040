import React, {useEffect, useState} from 'react';
import {navigationMenu} from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/helpers";
import EstimatedCollateral from "@shared/components/form/moderation/EstimatedСollateral";
import Pledge from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/steps/ProjectPledges/Pledge";

const ProjectPledges = ({data, step, pledgesState, pledgesDispatch, pledgesValidStatus}) => {
    const [refCreateStatus, setRefCreateStatus] = useState(false);

    const renderPledge = (item, idx) => (
        <div className="" key={item.id}>
            <Pledge data={item} step={step} idx={idx}/>
            <EstimatedCollateral
                dispatch={pledgesDispatch}
                key={item.id}
                data={pledgesState.pledges.filter(pledge => pledge.id === item.id)}
                validate={pledgesState.validate}
                pledgesValidStatus={pledgesValidStatus}
            />
        </div>
    )

    useEffect(() => {
        data.forEach((item, idx) => {
            navigationMenu[step].links.push(
                {id: idx, ref: React.createRef(), title: `Залог ${idx + 1}`},
            )
        })

        setRefCreateStatus(true)
        return () => navigationMenu[step].links = [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="moderationProject__pledges">
            {refCreateStatus && data.map(renderPledge)}
        </div>
    )
}

export default ProjectPledges;

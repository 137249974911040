import React from 'react';

const CalculationsStatus = ({status}) => {
    return (
        <span className={
            status.key === 'partialEarlyRepayment' || status.key === 'fullEarlyRepayment'
                ? 'projectCalculations__desc_dot projectCalculations__descDot'
                : 'projectCalculations__desc_dot'
        }
              style={{backgroundColor: status.color}}
        />
    )
};

export default CalculationsStatus;
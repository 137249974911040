import React from 'react';
import {useSelector} from "react-redux";
import InvestorNotQualifiedNotice from './InvestorNotQualifiedNotice';
import InvestorSuccessQualifiedNotice from './InvestorSuccessQualifiedNotice';
import InvestorCheckingQualifiedNotice from './InvestorCheckingQualifiedNotice';
import InvestorRejectedQualifiedNotice from './InvestorRejectedQualifiedNotice';

const InvestorQualifiedNotice = () => {
    const {qualification_status_key} = useSelector(state => state.profiles?.profileData || {});

    switch (qualification_status_key) {
        case 'qualification_not_qualified':
            return <InvestorNotQualifiedNotice/>
        case 'qualification_qualified':
            return <InvestorSuccessQualifiedNotice/>
        case 'qualification_checking':
            return <InvestorCheckingQualifiedNotice/>
        case 'qualification_rejected':
            return <InvestorRejectedQualifiedNotice/>
        default:
            return null;
    }

}

export default InvestorQualifiedNotice;
import React from "react";
import UserAvatar from "@shared/components/account/avatar";
import {useSelector} from "react-redux";

const DetailAvatarModal = () => {
    const {avatar} = useSelector(state => state.user.profile)
    return <>
        <p className="modalRoot__title">Фото профиля</p>
        <div className="detailAvatar">
            <UserAvatar detailAvatar hasAvatar={!!avatar}/>
        </div>
    </>
}

export default DetailAvatarModal;
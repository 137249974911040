import {BaseService} from "@services/base.service";

export class BanProfileService extends BaseService {
    static get entity() {
        return '/profiles/ban'
    }
}

export class UnbanProfileService extends BaseService {
    static get entity() {
        return '/profiles/unban'
    }
}

export class BansProfilesService extends BaseService {
    static get entity() {
        return '/bans/profiles'
    }
}

export class BansUsersService extends BaseService {
    static get entity() {
        return 'bans/users'
    }
}

export class UsersBanService extends BaseService {
    static get entity() {
        return 'users/ban'
    }
}

export class UsersUnbanService extends BaseService {
    static get entity() {
        return 'users/unban'
    }
}
import React from 'react';

const ProfileAccreditedCheckingNotice = () => {
    return <div className="accStatusNotice">
        <div className="accStatusNotice__header">
            <p>Аккредитация профиля</p>
        </div>
        <div className="accStatusNotice__content">
            <p>Ваш профиль находится на проверке. Доступ к сервисам платформы будет доступен после прохождения
                проверки.</p>
        </div>
    </div>
}

export default ProfileAccreditedCheckingNotice;
import {projectStatus, repayment_key} from "./helpers";

export const tableHeader = [
    {
        id: 0,
        name: 'number',
        title: 'Номер',
        cellSize: 'sm'
    },
    {
        id: 1,
        name: 'id',
        title: 'Id проекта',
        filterType: 'searchField',
        cellSize: 'sm'
    },
    {
        id: 2,
        name: 'condition.type_key',
        title: 'Цель привлечения инвестиций',
        cellSize: 'lg',
        filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'purchase', label: 'Приобретение основных средств'},
            {id: 2, name: 'refinancing', label: 'Рефинансирование'},
            {id: 3, name: 'replenishment', label: 'Пополнение оборотных средств'},
        ],
    },
    {
        id: 3,
        name: 'moderation_status_key',
        title: 'Статус проекта',
        cellSize: 'sm',
        filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'projects_collecting', label: projectStatus.projects_collecting},
            {id: 2, name: 'projects_signing_stage', label: projectStatus.projects_signing_stage},
            {id: 3, name: 'projects_payment_stage', label: projectStatus.projects_payment_stage},
            {id: 4, name: 'projects_delay', label: projectStatus.projects_delay},
            {id: 5, name: 'projects_completed', label: projectStatus.projects_completed},
            {id: 6, name: 'projects_not_carried_out', label: projectStatus.projects_not_carried_out},
            {id: 7, name: 'projects_canceled', label: projectStatus.projects_canceled},
            {id: 8, name: 'projects_checking', label: projectStatus.projects_checking},
            {id: 9, name: 'projects_returned', label: projectStatus.projects_returned},
            {id: 10, name: 'projects_rejected', label: projectStatus.projects_rejected},
            {id: 11, name: ' ', label: projectStatus.is_draft}, // уточнить ключ
        ],
    },
    {
        id: 4,
        name: 'profile.id',
        title: 'ID профиля заемщика',
        filterType: 'searchField',
        cellSize: 'sm'
    },
    {
        id: 5,
        name: 'profile.legal_form_type',
        title: 'Правовая форма заемщика',
        cellSize: 'md',
        filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'ie', label: 'ИП'},
            {id: 2, name: 'entity', label: 'Юр. лицо'},
        ],
    },
    {
        id: 6,
        name: 'profile.legal_form.personal.fio',
        title: 'Наименовавние заемщика',
        filterType: 'searchField',
        cellSize: 'md'
    },
    {
        id: 7,
        name: 'condition.target',
        title: 'Целевая сумма',
        filterType: 'interval',
        filterPlaceholder: '₽',
        cellSize: 'sm'
    },
    {
        id: 8,
        name: 'condition.min',
        title: 'Минимальная сумма',
        filterType: 'interval',
        filterPlaceholder: '₽',
        cellSize: 'sm'
    },
    {
        id: 9,
        name: 'date_publication',
        title: 'Дата публикации проекта',
        //filterType: 'date',
        cellSize: 'sm'
    },
    {
        id: 10,
        name: 'placement_period',
        title: 'Срок размещения проекта',
        //filterType: 'interval',
        //filterPlaceholder: 'дней',
        cellSize: 'sm'
    },
    {
        id: 11,
        name: 'funds_raised',
        title: 'Собрано средств',
        //filterType: 'interval',
        //filterPlaceholder: '₽',
        cellSize: 'sm'
    },
    {
        id: 12,
        name: 'condition.repayment_key',
        title: 'Тип выплат',
        cellSize: 'md',
        filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'annuity', label: repayment_key.annuity},
            {id: 2, name: 'deferred_annuity', label: repayment_key.deferred_annuity},
            {id: 3, name: 'principal_and_annual_interest', label: repayment_key.principal_and_annual_interest},
            {id: 4, name: 'principal_and_fixed_interest', label: repayment_key.principal_and_fixed_interest},
        ],
    },
    {
        id: 13,
        name: 'condition.percent',
        title: 'Ставка',
        filterType: 'interval',
        filterPlaceholder: '%',
        cellSize: 'sm'
    },
    {
        id: 14,
        name: 'condition.loan',
        title: 'Срок',
        filterType: 'interval',
        filterPlaceholder: 'мес.',
        cellSize: 'sm'
    },
    {
        id: 15,
        name: 'pledges',
        title: 'Залог',
        cellSize: 'sm',
        filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'yes', label: 'Есть'},
            {id: 2, name: 'no', label: 'Нет'},
        ],
    },
    {
        id: 16,
        name: 'number_investors',
        title: 'Количество инвесторов',
        //filterType: 'interval',
        cellSize: 'sm'
    },
    {
        id: 17,
        name: 'project_investor_id',
        title: 'ID инвесторов проекта',
        //filterType: 'searchField',
        cellSize: 'md'
    },
    {
        id: 18,
        name: 'investor_legal_form_type',
        title: 'Правовая форма инвесторов проекта',
        cellSize: 'md',
        /*filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'indiv', label: 'Физ. лицо'},
            {id: 2, name: 'ie', label: 'ИП'},
            {id: 3, name: 'entity', label: 'Юр. лицо'},
        ],*/
    },
    {
        id: 19,
        name: 'fio_investor',
        title: 'Наименование инвесторов',
        //filterType: 'searchField',
        cellSize: 'md'
    },
    {
        id: 20,
        name: 'acceptance_date',
        title: 'Дата акцепта инвестиционного предложения инвесторами',
        cellSize: 'md',
        //filterType: 'date',
        //notSort: true
    },
    {
        id: 21,
        name: 'amount_invested',
        title: 'Сумма инвестированных средств в разрезе каждого инвестора',
        cellSize: 'lg',
        //filterType: 'interval',
        //filterPlaceholder: '₽',
        //notSort: true
    },
    {
        id: 22,
        name: 'agreement_date',
        title: 'Дата заключения договора',
        //filterType: 'date',
        cellSize: 'sm',
    },
    {
        id: 23,
        name: 'commission_size',
        title: 'Размер оплаченной комиссии по проекту',
        //filterType: 'interval',
        //filterPlaceholder: '₽',
        cellSize: 'lg',
    },
    {
        id: 24,
        name: 'checkout',
        title: 'Выписка',
        cellSize: 'sm'
    },
]

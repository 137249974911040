import React from 'react';
import Shimmer from "@shared/components/skeletons/Shimmer";
import SkeletonElement from "@shared/components/skeletons/SkeletonElement";
import classnames from 'classnames';

const SkeletonSidebarProfileInfo = ({skeletonWrapperBg}) => {
    const skeletonClass = classnames({
        'skeleton-wrapper': true,
        'bg': skeletonWrapperBg,
    })

    const skeletonArticleClass = classnames({
        'd-flex': true,
        'flex-column': true,
        'skeleton-article': true,
        'align-items-center': true,
    })

    return (
        <div className={skeletonClass}>
            <div className={skeletonArticleClass}>
                <SkeletonElement type="avatar"/>
                <SkeletonElement type="text" className="skeletonSidebarProfileInfo__sm"/>
                <SkeletonElement type="text"/>
                <SkeletonElement type="text" className="skeletonSidebarProfileInfo__md"/>
            </div>
            <Shimmer/>
        </div>
    )
}

export default SkeletonSidebarProfileInfo;
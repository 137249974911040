import React, {useEffect} from 'react';
import {Button} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {ADD_NEW_BANK_TEMPLATE, SET_BANK_DETAILS} from "@redux/actions/profilesActions";

const InvoiceTemplates = ({bankData}) => {
    const dispatch = useDispatch();
    const {addNewBankTemplate, editProfileId} = useSelector(state => state.profiles);

    const newTemplate = {
        newTemplate: true,
        name: "",
        bik: "",
        correspondent_account: "",
        inn: "",
        checking_account: ""
    }

    const handleAddTemplate = () => {
        if (!addNewBankTemplate) {
            dispatch({type: ADD_NEW_BANK_TEMPLATE, payload: true})
            dispatch({
                type: SET_BANK_DETAILS,
                payload: [newTemplate, ...bankData]
            })
        }
    }

    useEffect(() => {
        if (addNewBankTemplate) {
            const newBankData = [...bankData];
            newBankData.shift()
            dispatch({type: ADD_NEW_BANK_TEMPLATE, payload: false});
            dispatch({
                type: SET_BANK_DETAILS,
                payload: newBankData
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editProfileId])

    return (
        <div className="InvoiceTemplates">
            <span className="InvoiceTemplates__title">Шаблоны счетов</span>
            <div className="InvoiceTemplates__content">
                <div className="InvoiceTemplates__btn">
                    <Button
                        color="success"
                        className="account__btn account__btn_success"
                        onClick={handleAddTemplate}
                        disabled={addNewBankTemplate}
                        outline
                    >
                        Добавить шаблон
                    </Button>
                </div>
                <span className="InvoiceTemplates__desc">Вывод средств возможен только на счета владельца профиля</span>
            </div>
        </div>
    )
}

export default InvoiceTemplates;
import React, {useEffect, useState} from 'react';
import RadioButtonField from "@shared/components/form/RadioButton";
import {Button} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {PAYMENT_SCHEDULE_MODAL, PAYMENT_TYPES_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";
import InputText from "@shared/components/form/profileForm/InputText";
import {EDIT_PROJECT_VALUE, setProjectFormError} from "@actions/projectsActions";

const PaymentType = ({fRef, data = {}, formError}) => {
    const dispatch = useDispatch();
    const {repayment_key, deffer_period, loan} = data;
    const [defferPeriodError, setDefferPeriodError] = useState(false);
    const repaymentKeys = ['principal_and_annual_interest', 'principal_and_fixed_interest'];
    const [repaymentMethod, setRepaymentMethod] = useState(repaymentKeys.includes(repayment_key) ? 0 : 1);

    const setData = (name, value) => {
        dispatch({
            type: EDIT_PROJECT_VALUE,
            payload: {
                name,
                value: value || null,
                dataKey: 'condition',
            }
        })
    }

    const handleOnChangeRepaymentMethod = value => {
        setData('repayment_key', null);
        setRepaymentMethod(value)
    }

    const setFormError = data => {
        setProjectFormError(data, dispatch)
    }

    useEffect(() => {
        if (repayment_key === 'deferred_annuity') {
            if (formError.includes('deffer_period') || !deffer_period) {
                setDefferPeriodError(true)
            } else {
                setDefferPeriodError(false)
            }
        } else {
            setDefferPeriodError(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const renderPaymentTypeItems = () => {
        return (
            <div className="addProject__form_content">
                <div className="addProject__formGroup paymentType__mainBtn">
                    <button
                        className={`from__custom-radio-btn ${repaymentMethod === 1 && 'active'}`}
                        onClick={() => handleOnChangeRepaymentMethod(1)}
                    >
                        <span>Аннуитетный</span>
                    </button>
                    <button
                        className={`from__custom-radio-btn ${repaymentMethod === 0 && 'active'}`}
                        onClick={() => handleOnChangeRepaymentMethod(0)}
                    >
                        <span>Индивидуальный</span>
                    </button>
                </div>
                {
                    repaymentMethod === 1
                        ? <div className="paymentType__radioGroup">
                            <RadioButtonField
                                name="annuity"
                                label="Аннуитет"
                                radioValue="annuity"
                                value={repayment_key}
                                onChange={() => setData('repayment_key', 'annuity')}
                            />
                            <RadioButtonField
                                name="deffer_period"
                                label="Аннуитет с отсрочкой"
                                radioValue="deferred_annuity"
                                value={repayment_key}
                                onChange={() => setData('repayment_key', 'deferred_annuity')}
                            />
                        </div>
                        : null
                }
                {
                    repaymentMethod === 1 && repayment_key === 'deferred_annuity'
                        ? <div className="paymentType__textInput">
                            <InputText
                                name="deffer_period"
                                formError={formError}
                                setFormError={setFormError}
                                onChange={e => setData('deffer_period', +(e.target.value.replace(/\D/g, '')))}
                                label="Срок отсрочки"
                                value={deffer_period || ''}
                                labelClass="requiredMark"
                                validator={[{digitLimit: 2}, {maxNumber: loan ? +loan - 1 : 0}, {onlyNumbers: true}, {required: true}]}
                                validatorMessages={{
                                    digitLimit: 'Максимальная длина 2 цифры!',
                                    maxNumber: `Максимальный срок отсрочки ${loan ? +loan - 1 : 0} мес.`
                                }}
                                inputWithDesc
                                inputWithDescValue="мес."
                                containerClassName="addProject__smallInput"
                            />
                        </div>
                        : ""
                }
                {
                    repaymentMethod === 0
                        ? <div className="paymentType__radioGroup paymentType__radioGroup_column">
                            <RadioButtonField
                                name="principal_and_annual_interest"
                                label="ОД и проценты (в годовых) в конце срока"
                                radioValue="principal_and_annual_interest"
                                value={repayment_key}
                                onChange={() => setData('repayment_key', 'principal_and_annual_interest')}
                            />
                            <RadioButtonField
                                name="principal_and_fixed_interest"
                                label="ОД и проценты (фиксированная сумма вознаграждения) в конце срока"
                                radioValue="principal_and_fixed_interest"
                                value={repayment_key}
                                onChange={() => setData('repayment_key', 'principal_and_fixed_interest')}
                            />
                        </div>
                        : ""
                }
            </div>
        )
    }

    return (
        <div className="addProject__form addProject__paymentType" ref={fRef}>
            <div className="addProject__titleGroup">
                <p className="addProject__form_title">Способ погашения:</p>
                <button className="simpleGreenBtn" onClick={() => showModal(PAYMENT_SCHEDULE_MODAL)(dispatch)}>
                    Подробнее
                </button>
            </div>
            {renderPaymentTypeItems()}
            <Button
                color="success"
                className={`account__btn_success paymentType__schedule_btn ${defferPeriodError ? 'disabled' : ''}`}
                disabled={defferPeriodError}
                onClick={() => showModal(PAYMENT_TYPES_MODAL)(dispatch)}
                outline
            >
                Ожидаемый график платежей
            </Button>
        </div>
    )
}

export default PaymentType;

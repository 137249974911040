import React from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {CalendarIcon, DownloadIcon} from "@shared/img/iconSvg";
import {Button, Table} from 'reactstrap';
import {CSVLink} from "react-csv";
import {headers, tableData} from "./helpers";

const ReportOnNDFL = ({data}) => {
    return (
        <div className='reportOnNDFL'>
            <span className='reportOnNDFL__title'>{`Расчёт НДФЛ по проекту №${data.id}`}</span>
            <p className="accreditationList__filter_title">Дата подачи заявления</p>
            <div className='reportOnNDFL__header'>
                <div className="reportOnNDFL__header_desc accreditationList__filter_dateGroup date-picker">
                    <InputText
                        type="date"
                        name="accreditationFilterDateStart"
                        id="accreditationFilterDateStart"
                        //onChange={({target}) => setAccFilterDateStart(target.value)}
                        inputWithDesc
                        noLabel={true}
                        maxDate={new Date()}
                        value={''}
                        inputWithDescValue={<CalendarIcon/>}
                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                    />
                    <span className="accreditationList__filter_separator"/>
                    <InputText
                        type="date"
                        name="accreditationFilterDateEnd"
                        id="accreditationFilterDateEnd"
                        //onChange={({target}) => setAccFilterDateEnd(target.value)}
                        inputWithDesc
                        noLabel={true}
                        maxDate={new Date()}
                        value={''}
                        inputWithDescValue={<CalendarIcon/>}
                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                    />
                    <Button
                        className='notAccreditedProject__button'
                        color="primary"
                        size="lg"
                    >
                        Сформировать
                    </Button>
                </div>
                <CSVLink data={tableData} headers={headers} filename={`Расчёт НДФЛ по проекту №${data.id}`}>
                    <button className="btn-reset registryTable__services_download">
                        <div className="uploadField__download">
                            <DownloadIcon/>
                        </div>
                        <span>Скачать в Excel</span>
                    </button>
                </CSVLink>
            </div>
            <Table className='reportOnNDFL__table'>
                <thead>
                <tr>
                    {
                        headers.map((elem, idx) => (
                            <th key={idx} className='reportOnNDFL__table_header'
                                style={{width: elem.width}}>{elem.label}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    tableData.map((elem) => (
                        <tr key={elem.id} className='reportOnNDFL__table_desc'>
                            <td>{elem.paymentDate}</td>
                            <td>{elem.idInvestor}</td>
                            <td>{elem.fullNameInvestor}</td>
                            <td>{elem.amountOfPayment}</td>
                            <td>{elem.mainDebt}</td>
                            <td>{elem.percent}</td>
                            <td>{elem.penalties}</td>
                            <td>{elem.personalIncomeTax}</td>
                            <td>{elem.investorData}</td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </div>
    )
};

export default ReportOnNDFL;
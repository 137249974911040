import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {AccreditationService} from "@services/accreditation.service";
import {SET_ACCREDITATION_PROFILE_ID} from "@actions/accreditationAction";

const ModeratorAccNewCheck = ({hideModal, modalData}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {accreditationId, accreditationStatus} = modalData;
    const [loading, setLoading] = useState(false);

    const newCheck = () => {
        if (accreditationStatus === 'accreditation_checking') {
            hideModal()
            history.push(`/accreditation/${accreditationId}`)
        } else {
            setLoading(true)
            AccreditationService.create({type: "accreditation_checking"}, accreditationId)
                .then(res => {
                    setLoading(false)
                    dispatch({type: SET_ACCREDITATION_PROFILE_ID, payload: res?.data?.data?.id})
                    hideModal()
                    history.push(`/accreditation/${res?.data?.data?.id}`)
                })
                .catch(error => console.log(error))
        }

    }

    return accreditationId && accreditationStatus
        ? <div className="moderatorAccModal__newCheck">
            <p className="modalFullScreen__title">Вы уверены, что хотите начать новую проверку аккредитации?</p>
            <div className="btn-wrap moderatorAccModal__newCheck_bnt">
                <Button outline size="sm" color="danger" onClick={() => hideModal()}>
                    Отмена
                </Button>
                <Button color="primary" size="lg" onClick={newCheck}>
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${loading ? 'btn-spinner-visible' : ''}`}/>
                        Начать проверку
                    </div>
                </Button>
            </div>
        </div>
        : null
}

export default ModeratorAccNewCheck;
import React from 'react';
import PropTypes from "prop-types";
import {moneyIcon} from "@shared/img/iconSvg";
import {BankDetailsRender} from "@shared/components/account/bankDetails/helpers";

const BankDetailsInvestor = ({data}) => {
    return (
        <div className="profile__row profileTabs__tab_group bankDetails bankDetails__investor">
            {
                data?.bank_details?.length
                    ? data.bank_details.map((item, idx) => {
                        return <BankDetailsRender notEditable={data.isBanned} data={item} key={idx}/>
                    })
                    : null
            }
            <div className="profileTabs__col profile__col">
                <div className="bankDetails__investor_desc">
                    {moneyIcon()}
                    <span>Реквизиты банковского счета необходимы для вывода денежных средств с вашего счета</span>
                </div>
            </div>
        </div>
    )
}

BankDetailsInvestor.propTypes = {
    bankData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

BankDetailsInvestor.defaultProps = {
    bankData: [],
};

export default BankDetailsInvestor;
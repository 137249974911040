import {AuthService} from "@services/auth.service";
import axios from "axios";

export class Http {
    constructor(status) {
        this.isAuth = status && status.auth
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            accept: 'application/x.incrowd.v1+json',
            headers: {'Accept-Language': 'ru'}
        });

        return this.init()
    }

    init() {
        if (this.isAuth) {
            this.instance.interceptors.request.use(request => {
                request.headers.authorization = AuthService.getBearer()

                if (!AuthService.checkTokenAndExp()) {
                    return AuthService.redirectToLogin()
                } else if (AuthService.mustRunRefresh()) {
                    return AuthService.debounceRefreshTokens()
                        .then(() => {
                            request.headers.authorization = AuthService.getBearer()
                            return request
                        })
                        .catch(error => Promise.reject(error))
                } else {
                    return request
                }

            }, error => {
                return Promise.reject(error)
            })
        }

        return this.instance
    }
}
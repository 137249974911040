import React, {useEffect, useState} from "react";
import {ConfirmationsProfilePhoneCheckService, ConfirmationsProfilePhoneService} from "@services/confirmations.service";
import InputText from "@shared/components/form/profileForm/InputText";
import {Button} from "reactstrap";
import {ProfilesOwnService} from "@services/profiles.service";
import {SET_PROFILE_DATA} from "@actions/profilesActions";

const AccreditationPhoneModal = ({hideModal, modalData, hideAllModals}) => {
    const [code, setCode] = useState("")
    const [errorMessage, setErrorMessage] = useState(null) // Ошибка при подтверждении кода

    useEffect(() => {
        sendConfirmation()
    }, [])

    const sendConfirmation = () => {
        ConfirmationsProfilePhoneService.create({type: "accreditation_request"})
    }

    const handleChangeCode = e => {
        if (e.target.value.length > 4) return
        if (!!errorMessage) setErrorMessage('') // Скрываем информацию об ошибке
        const onlyNums = e.target.value.replace(/[^\d]/g, '') // Только цифры
        setCode(onlyNums)
    }

    const checkCode = () => {
        if (validate()) {
            ConfirmationsProfilePhoneCheckService.create({
                code: code,
                type: "accreditation_request"
            }).then(() => {
                hideModal(true, code)
            }).catch((error) => {
                if (error.status === 400) {
                    setErrorMessage('Неправильный код!');
                }
            })
        }
    }

    // Валидация при потере фокуса
    const validate = () => {
        if (!code.length) {
            setErrorMessage('Обязательно для заполнения!');
            return false;
        }

        if (code.length !== 4) {
            setErrorMessage('Код состоит из 4 символов');
            return false;
        }
        return true;
    }

    return (
        <div className="">
            <p className="modalFullScreen__title">
                Для подтверждения введите одноразовый пароль, отправленный на номер {modalData}
            </p>
            <div className={"code-confirmation-container"}>
                <InputText
                    type="text"
                    name="code"
                    label=""
                    className={"code-confirmation"}
                    onChange={handleChangeCode}
                    value={code}
                    containerClassName="profileTabs__edit_item"
                />
                <span className="form__form-error">{errorMessage}</span>
            </div>
            <div className="modalFullScreen__btnGroup">
                <Button
                    color="primary"
                    className="account__btn account__btn_primary"
                    onClick={checkCode}
                >
                    <div className="btn-spinner-group">
                        Подтвердить
                    </div>
                </Button>
                <Button
                    onClick={() => hideModal()}
                    color="primary"
                    className="account__btn account__btn_cancel"
                    outline
                >
                    Отмена
                </Button>
            </div>
        </div>
    )
}
export default AccreditationPhoneModal;
import {BaseService} from "@services/base.service";

export class OtherAccreditationService extends BaseService {
    static get entity() {
        return '/accreditations/send'
    }
}

export class AccreditationService extends BaseService {
    static get entity() {
        return '/accreditations'
    }
}

export class ModeratorAccreditationsService extends BaseService {
    static get entity() {
        return '/moderator/accreditations'
    }
}

export class ModeratorQualificationsService extends BaseService {
    static get entity() {
        return '/moderator/qualifications'
    }
}

export class ModeratorAccreditationsProfileService extends BaseService {
    static get entity() {
        return '/moderator/accreditations/profile'
    }
}

export class ModeratorAccreditationsCheckService extends BaseService {
    static get entity() {
        return '/moderator/accreditations/check'
    }
}
import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

const AccountFormWrapper = ({form, title}) => {
    const FormComponent = form;

    return (
        <>
            <div className="accountFormWrap__mobile_header">
                <h1>{title}</h1>
                <NavLink exact to="login" className="accountFormWrap__mobile_auth">Вход</NavLink>
            </div>
            <div className="accountFormWrap">
                <div className="accountFormWrap__bg"/>
                <div className="accountFormWrap__wrap">
                    <div className="accountFormWrap__content">
                        <h1>{title}</h1>
                        <FormComponent/>
                    </div>
                </div>
            </div>
        </>
    )
}

AccountFormWrapper.propTypes = {
    form: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}

AccountFormWrapper.defaultProps = {
    form: () => {
    },
    title: '',
};

export default AccountFormWrapper;
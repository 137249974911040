export const SET_RATE_ERROR = 'SET_RATE_ERROR';
export const SET_INPUT_EDIT_VISIBLE = 'SET_INPUT_EDIT_VISIBLE';
export const HIDE_INPUT_EDIT = 'HIDE_INPUT_EDIT';

export const initRateState = (data = []) => {
    return {
        rate: {
            support: data?.support || '',
            tariff: data?.tariff || ''
        },
        validate: [],
        supportEdit: false,
        tariffEdit: false
    }
}

export const rateReducer = (state, action) => {
    switch (action.type) {
        case SET_INPUT_EDIT_VISIBLE:
            return {
                ...state,
                [action.payload]: !state[action.payload]
            }
        case HIDE_INPUT_EDIT:
            return {
                ...state,
                supportEdit: false,
                tariffEdit: false
            }
        case SET_RATE_ERROR:
            return {
                ...state,
                validate: action.payload
            }
        default:
            return {
                ...state,
                rate: {
                    ...state.rate,
                    [action.payload.name]: action.payload.value
                }
            }
    }
}

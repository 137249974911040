import React from 'react';
import PrimaryTooltip from "@shared/components/tooltip";

const Reminder = () => {
    return (
        <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
            <p>
                1. Проект перешел к стадии подписания документов.
                2. Проект перешел к стадии выплат
                3. Проект перешел в просрочку
                4. Проект завершен
                5. Проект отменен
                6. Проект не состоялся
            </p>
        </PrimaryTooltip>
    )
};

export default Reminder;
export const SET_EDITABLE_FIELD_KEY = 'SET_EDITABLE_FIELD_KEY';
export const SET_DATA = 'SET_DATA';
export const SET_ERRORS = 'SET_ERRORS';
export const RESET_VALUE = 'RESET_VALUE';
export const SET_SAVE_STATUS = 'SET_SAVE_STATUS';
export const SET_UPDATED_DATA = 'SET_UPDATED_DATA';

export const initState = data => {
    const dataFormat = {
        accessFirstColumnTo: data.access[0].to,
        accessFirstColumnFrom: data.access[0].from,
        accessFirstColumnPercentOne: data.access[0].percents[0],
        accessFirstColumnPercentTwo: data.access[0].percents[1],
        accessFirstColumnPercentThree: data.access[0].percents[2],
        accessSecondColumnTo: data.access[1].to,
        accessSecondColumnFrom: data.access[1].from,
        accessSecondColumnPercentOne: data.access[1].percents[0],
        accessSecondColumnPercentTwo: data.access[1].percents[1],
        accessSecondColumnPercentThree: data.access[1].percents[2],
        tariff: data.tariff,
        support: data.support,
    }

    return {
        data: {...dataFormat},
        dataCopy: {...dataFormat},
        edit: [],
        formError: [],
        saveStatus: false
    }
}

export const platformRatesReducer = (state, action) => {
    const {name, value, fieldFrom, fieldTo} = action?.payload || {};

    switch (action.type) {
        case SET_EDITABLE_FIELD_KEY: {
            const {edit, dataCopy, data} = state;
            const newData = edit.length
                ? {...data, [edit[0]]: dataCopy[edit[0]], [edit[1]]: dataCopy[edit[1]]}
                : {...data}

            return {
                ...state,
                edit: action.payload || [],
                data: newData
            }
        }
        case SET_DATA: {
            return {
                ...state,
                data: {...state.data, [name]: value}
            }
        }
        case SET_ERRORS: {
            return {
                ...state,
                formError: action.payload
            }
        }
        case RESET_VALUE: {
            const newData = {...state.data, [fieldFrom]: state.dataCopy[fieldFrom], [fieldTo]: state.dataCopy[fieldTo]}

            return {
                ...state,
                data: newData,
                edit: [],
                saveStatus: false
            }
        }
        case SET_SAVE_STATUS: {
            return {
                ...state,
                saveStatus: !state.saveStatus
            }
        }
        case SET_UPDATED_DATA: {
            return {
                ...initState(action.payload)
            }
        }
        default:
            return {...state}
    }
}

// Конвертор строковых чисел в числа с округлением до 2 знаков после запятой
const convertToNumbers = (data = {}) => {
    return Object.keys(data).reduce((newData, key) => {
        newData[key] = +(Math.round(data[key] + "e+2") + "e-2")
        return newData
    }, {})
}

export const formatData = data => {
    const {
        accessFirstColumnTo, accessFirstColumnFrom, accessFirstColumnPercentOne, accessFirstColumnPercentTwo,
        accessFirstColumnPercentThree, accessSecondColumnTo, accessSecondColumnFrom, accessSecondColumnPercentOne,
        accessSecondColumnPercentTwo, accessSecondColumnPercentThree, tariff, support
    } = convertToNumbers(data) || {};

    return {
        value: {
            access: [
                {
                    to: accessFirstColumnTo,
                    from: accessFirstColumnFrom,
                    percents: [accessFirstColumnPercentOne, accessFirstColumnPercentTwo, accessFirstColumnPercentThree]
                },
                {
                    to: accessSecondColumnTo,
                    from: accessSecondColumnFrom,
                    percents: [accessSecondColumnPercentOne, accessSecondColumnPercentTwo, accessSecondColumnPercentThree]
                }
            ],
            tariff,
            support
        }
    }
}
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {accountMenu} from "@router/Routers";
import {NavLink} from "react-router-dom";
import logo_light from "@shared/img/logo/logo_light.svg";
import logo_dark from "@shared/img/account/form_logo.svg";
import user from "@shared/img/icons/user.svg";
import toggler from "@shared/img/icons/toggler.svg";

const HeaderMenu = () => {
    const theme = useSelector(state => state.theme.className);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const renderMenu = () => (
        <ul className="headerMenu__menu">
            {
                accountMenu.length
                    ? accountMenu.map(item => (
                        <NavLink
                            key={item.path}
                            to={item.path}
                            exact={!!item.exact}
                            activeClassName="headerMenu__active"
                        >
                            <li className="headerMenu__link">
                                {item.title}
                            </li>
                        </NavLink>
                    ))
                    : null
            }
        </ul>
    )

    const renderMenuAuthLink = () => {
        const {pathname} = window.location

        return pathname && pathname === "/login"
            ? (
                <div>
                    <span className="headerMenu__auth_link_desc">Впервые на платформе?</span>
                    <NavLink exact to="register" className="headerMenu__auth_link">Зарегистрироваться</NavLink>
                    <div className="headerMenu__auth_icon">
                        <NavLink exact to="register">
                            <img src={user} alt="login"/>
                        </NavLink>
                    </div>
                </div>
            )
            : (
                <div>
                    <span className="headerMenu__auth_link_desc">У вас уже есть аккаунт?</span>
                    <NavLink exact to="login" className="headerMenu__auth_link">Вход</NavLink>
                    <div className="headerMenu__auth_icon">
                        <NavLink exact to="login">
                            <img src={user} alt="register"/>
                        </NavLink>
                    </div>
                </div>
            )

    }

    return (
        <div className={`headerMenu__container ${isOpen ? 'active' : ''}`}>
            <div className="headerMenu__logo_group">
                <div className="headerMenu__logo">
                    <img alt="logo" src={theme === "theme-light" ? logo_light : logo_dark}/>
                </div>
                <div className="headerMenu__inner">
                    {renderMenu()}
                </div>
            </div>
            <div className="headerMenu__auth">
                <div className="headerMenu__toggler" onClick={toggle}>
                    <img src={toggler} alt="toggler"/>
                </div>
                {renderMenuAuthLink()}
            </div>
            <div className={`headerMenu__mobile ${isOpen ? 'active' : ''}`}>
                {renderMenu()}
            </div>
        </div>
    );
}

export default HeaderMenu;
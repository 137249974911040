import {constants} from '@redux/actions/innerUsersActions';

const initialState = {
    clientAccount: {
        data: null,
        clientAccountDataLoading: false,
    },
}

export default function (state = initialState, action) {
    switch (action.type) {
        case constants.SET_CLIENT_ACCOUNT_DATA:
            return {
                ...state,
                clientAccount: {
                    ...state.clientAccount,
                    data: action.payload
                }
            }
        case constants.CLIENT_ACCOUNT_DATA_LOADING:
            return {
                ...state,
                clientAccount: {
                    ...state.clientAccount,
                    clientAccountDataLoading: !state.clientAccount.clientAccountDataLoading
                }
            }
        default:
            return {...state}
    }
}
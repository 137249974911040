import React from 'react';
import {Table} from 'reactstrap';
import {useSelector} from "react-redux";

const AboutTariffs = () => {
    const {rate} = useSelector(state => state.projects.projectEditData);
    const {access = [], support = null} = rate || {};

    const thRender = () => {
        return access.map((item, idx) => (
            <th key={idx} className="pt-0 pl-0">
          <span className="aboutTariffs__smTitle">
            {`от ${item?.from || ''} до ${item?.to || ''}`}
          </span>
            </th>
        ))
    }

    return (
        <div className="aboutTariffs">
            <p className="modalFullScreen__title">
                Подробнее о тарифах
            </p>
            <div className="aboutTariffs__table">
                <Table className="table--bordered" responsive>
                    <thead>
                    <tr className="aboutTariffs__table_header">
                        <th className="pl-0 aboutTariffs__mediumItem">
                            <span className="aboutTariffs__table_title">Наименование услуги</span>
                        </th>
                        <th className="aboutTariffs__largeItem pl-0">
                            <span className="aboutTariffs__table_title requiredMark">Размер вознаграждения</span>
                            <span
                                className="aboutTariffs__table_subtitle greyColor">% от суммы привлеченных инвестиций</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                    <span className="aboutTariffs__item_title purpleColor aboutTariffs__services_title">
                      Услуги Оператора по предоставлению доступа к сервисам инвестиционной платформы INCROWD и
                      привлечению инвестиций (размещение инвестиционного предложения, привлечение инвестиций,
                      заключение и обслуживание договора инвестирования)
                    </span>
                        </td>
                        <td className="aboutTariffs__item_content aboutTariffs__vnTable aboutTariffs__largeItem pl-0">
                            <div className="aboutTariffs__vnTable_desc aboutTariffs__vnTable_title">
                                Срок действия инвестиционного предложения, указанный инициатором
                                <span className="greyColor"> (календарные дни):</span>
                            </div>
                            {
                                access && access.length
                                    ? <Table className="aboutTariffs__notBorder-table" responsive>
                                        <thead>
                                        <tr>
                                            <th className="pt-0 pl-0">
                                <span className="aboutTariffs__vnTable_title aboutTariffs__smallTitle">
                                  Заключение договора инвестирования в рамках размещенного:
                                </span>
                                            </th>
                                            {thRender()}
                                        </tr>
                                        </thead>
                                        <tbody className="aboutTariffs__vnTable_content purpleColor">
                                        <tr>
                                            <td>1-ого инвестиционного предложения</td>
                                            <td className="pl-0">{`${access[0]?.percents[0]}%` || ''}</td>
                                            <td className="pl-0">{`${access[1]?.percents[0]}%` || ''}</td>
                                        </tr>
                                        <tr className="aboutTariffs__vnTable_line"/>
                                        <tr>
                                            <td>2-ого инвестиционного предложения</td>
                                            <td className="pl-0">{`${access[0]?.percents[1]}%` || ''}</td>
                                            <td className="pl-0">{`${access[1]?.percents[1]}%` || ''}</td>
                                        </tr>
                                        <tr className="aboutTariffs__vnTable_line"/>
                                        <tr>
                                            <td>3-его инвестиционного предложения</td>
                                            <td className="pl-0">{`${access[0]?.percents[2]}%` || ''}</td>
                                            <td className="pl-0">{`${access[1]?.percents[2]}%` || ''}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    : ''
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem pt-4">
                    <span className="aboutTariffs__item_title purpleColor">
                      Услуги Оператора по сопровождению государственной
                      регистрации залога движимого/недвижимого имущества
                    </span>
                        </td>
                        <td className="aboutTariffs__largeItem pt-4">
                    <span className="aboutTariffs__vnTable_title">
                      Единовременная оплата без учета государственной пошлины, услуги нотариуса):
                        {
                            support
                                ? <span className="requiredMark purpleColor aboutTariffs__notification">
                              {`${support} ₽ / 1 объект залога`}
                            </span>
                                : ''
                        }
                    </span>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="aboutTariffs__desc">
                <div className="aboutTariffs__desc_item">
            <span className="greyColor">
              <span className="greenLightColor">*</span> Оператор вправе предоставить скидку на размер вознаграждения в рамках рекламных акций, действующих
              на момент размещения инвестиционного предложения
            </span>
                </div>
                <div className="aboutTariffs__desc_item">
            <span className="greyColor">
              <span className="greenLightColor">**</span> Инициатор оплачивает в полном объеме все расходы, связанные с государственной регистрации залога
              движимого/недвижимого имущества, включая государственную пошлину, услуги нотариуса,
              иные обязательные сборы, путем перечисления денежных средств на расчетный счет
              Оператора на основании счета.
            </span>
                </div>
            </div>
        </div>
    )
}

export default AboutTariffs;
import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";

const QualificationReportNonCompliance = ({hideModal}) => {
    const [loading, setLoading] = useState(null);

    const handleConfirm = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }

    return (
        <div className="qualifiedInvestor__nonCompliance">
            <p className="modalFullScreen__title">Исключение из реестра квалифицированных инвесторов</p>
            <p className="modalFullScreen__desc">
                Вы подтверждаете, что вы перестали соответствовать условиям,
                указанным в заявлении? После исключения из реестра вы сможете подать
                новое заявление, указав другие условия соответствия
            </p>
            <div className="modalFullScreen__btnGroup">
                <Button
                    onClick={() => hideModal()}
                    color="danger"
                    size="md"
                    outline
                >
                    Отмена
                </Button>
                <Button
                    color="primary"
                    size=" "
                    onClick={handleConfirm}
                >
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${loading ? 'btn-spinner-visible' : ''}`}/>
                        Подтверждаю
                    </div>
                </Button>
            </div>
        </div>
    )
};

export default QualificationReportNonCompliance;
import React from 'react';
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";
import {Button} from "reactstrap";
import UploadField from "@shared/components/uploadField";
import {useHistory} from "react-router-dom";
import {showModal} from "@actions/modalActions";
import {CONFIRM_INVESTMENT_SMS_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";

const CapNotRequiredModal = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <div className='capNotRequiredModal detailedCalculations'>
            <BackButton/>
            <span className='textTitle'>Подтверждение инвестиции</span>
            <div className='detailedCalculations__document'>
                {/*files={[data.company.logo]}*/}
                <UploadField viewDetail edit={false}/>
            </div>
            <span className=' capNotRequiredModal__subtitle fontSizeText'>Сумма 100 000,00 ₽ на срок 12 месяцев</span>
            <div className='investModalButtons'>
                <Button
                    className='loanOptionsEditButton__button'
                    color="danger"
                    size="sm"
                    outline
                    onClick={() => history.push("/project-detail/101010")}
                >
                    Отменить
                </Button>
                <Button
                    color="primary"
                    size="lg"
                    onClick={() => showModal(CONFIRM_INVESTMENT_SMS_MODAL)(dispatch)}
                >
                    Подтвердить
                </Button>
            </div>
        </div>
    )
};

export default CapNotRequiredModal;
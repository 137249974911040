import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Button, Spinner} from "reactstrap";
import {isBorrower, isInvestor} from "@utils/profile";
import InputText from "@shared/components/form/profileForm/InputText";
import {reducer} from "@shared/components/account/helpers";
import {showModal} from "@actions/modalActions";
import {ACCREDITATION_PHONE, SIGNATURE_MODAL} from "@shared/components/modal/constants";
import SignatureFile from "@shared/components/uploadField/SignatureFile";
import {DocumentsService} from "@services/document.service";
import {FilesService} from "@services/file.service";
import {ProfilesOuterService, ProfilesOwnService} from "@services/profiles.service";

const AccreditationProfileModal = ({hideModal, modalData, modalUpdate, modalParentData, hideAllModals}) => {
    const [profile, setProfile] = useState(null)
    const dispatch = useDispatch();
    const isBorrowerProfile = isBorrower();
    const isInvestorProfile = isInvestor();
    const [file, setFile] = useState({})
    const [formData, setFormData] = useReducer(reducer, {
        platform_acceptance: false,
        risk_declaration_acceptance: false,
        file_id: null,
        code: null
    });

    const [spinnerStatus, setSpinnerStatus] = useState(false);

    const handleOnChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        })
    }

    const validate = useMemo(() => {
        if (isInvestorProfile && !formData.risk_declaration_acceptance) return false
        if (!formData.platform_acceptance) return false

        return true
    }, [formData]);


    const validateAll = useMemo(() => {
        if (isInvestorProfile && !formData.code) return false
        if (isBorrowerProfile && !formData.file_id) return false

        return true
    }, [formData]);

    const platformRulesAcceptance = () => {
        return <>
            Я ознакомился с <Link to="/">Правилами платформы</Link>, согласен с условиями и подтверждаю соответствие
            требованиям,
            предъявляемым к инвестору
        </>
    }

    const riskDeclarationAcceptance = () => {
        return <>
            Я ознакомился с <Link to="/">Декларацией о рисках</Link>, и осознаю, что инвестирование на платформе
            является высокорискованным, может привести к потере инвестиций, и принимаю такие риски
        </>
    }

    const phoneConfirmation = () => {
        return <>
            Для подтверждения введите одноразовый пароль, отправленный на номер {profile?.phone}
        </>
    }

    useEffect(() => {
        if (isBorrowerProfile) {
            DocumentsService.make('accreditation').then((res) => {
                setFile(res.data.data)
            })
        }
    }, [])

    useEffect(() => {
        if (isInvestorProfile) {
            ProfilesOwnService.getList({}).then(res => {
                setProfile(res.data.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }, [])

    useEffect(() => {
        if (isInvestorProfile && modalParentData !== null) {
            setFormData({
                name: 'code',
                value: modalParentData,
            })
        }
    }, [modalUpdate, modalParentData])

    useEffect(() => {
        if (isBorrowerProfile && !!file?.id) {
            FilesService.getById(file?.id, {include: 'signature'})
                .then(res => {
                    const file = res.data.data
                    if (file && !!file.signature) {
                        setFormData({
                            name: 'file_id',
                            value: file.id,
                        })
                    }
                })
                .catch(error => console.log(error))
        }
    }, [modalUpdate, file])

    const confirmAccreditation = () => {
        if (isInvestorProfile && validate) {
            showModal(ACCREDITATION_PHONE, profile?.phone)(dispatch)
        }
    }
    const confirmSignature = () => {
        if (isBorrowerProfile && validate && file?.id) {
            showModal(SIGNATURE_MODAL, file.id)(dispatch)
        }
    }
    const send = () => {
        if (validateAll && validate) {
            ProfilesOuterService.updateWithAccreditation({
                ...modalData,
                accreditation: {
                    ...formData
                }
            }).then(() => {
                hideAllModals()
            })
        }
    }

    return (
        <div className="designateAccountMain addNewProfile accreditationModal">
            <p className="modalFullScreen__title">Аккредитация профиля</p>
            <p className="modalFullScreen__desc">
                Пожалуйста, отнеситесь с вниманием к процессу заполнения персональных данных профиля. После того, как
                аккредитация профиля будет пройдена, внесение любых изменений в данные профиля повлечет повторную
                проверку всего профиля.
            </p>
            <div className="modalFullScreen__checkboxGroup">

                {
                    isBorrowerProfile
                        ? <div style={{width: "505px"}}>
                            <SignatureFile
                                viewDetail={true}
                                id={file?.id}
                                update={!!formData.file_id}
                            />
                        </div>
                        : ''
                }
                <InputText
                    type="checkbox"
                    name="platform_acceptance"
                    id="platform_acceptance"
                    label={platformRulesAcceptance()}
                    onChange={handleOnChange}
                    value={formData.platform_acceptance}
                    containerClassName="profileTabs__edit_item profileTabs__edit_check"
                />
                {
                    isInvestorProfile
                        ? <InputText
                            type="checkbox"
                            name="risk_declaration_acceptance"
                            id="risk_declaration_acceptance"
                            label={riskDeclarationAcceptance()}
                            onChange={handleOnChange}
                            value={formData.risk_declaration_acceptance}
                            containerClassName="profileTabs__edit_item profileTabs__edit_check"
                        />
                        : ''
                }
            </div>
            {
                isBorrowerProfile
                    ? <div className="modalFullScreen__notes">
                        <p>*Для подтверждения изменений нужна КЭП</p>
                    </div>
                    : null
            }
            {
                isInvestorProfile
                    ? <div className="phone-desc">
                        {phoneConfirmation()}
                    </div>
                    : null
            }
            {
                isInvestorProfile && !!formData.code ? <div className="code-desc">
                    <InputText
                        type="text"
                        name="code"
                        label="Код из смс"
                        className={"code-confirmation"}
                        disabled={true}
                        value={formData.code}
                        containerClassName="profileTabs__edit_item"
                    />
                </div> : ''
            }


            <div className="modalFullScreen__btnGroup">
                <Button
                    onClick={() => hideModal(false)}
                    color="primary"
                    className="account__btn account__btn_cancel"
                    outline
                >
                    Отмена
                </Button>
                {
                    isInvestorProfile && !formData.code ? <Button
                        color="primary"
                        className="account__btn account__btn_primary"
                        onClick={confirmAccreditation}
                        disabled={!validate}
                    >
                        <div className="btn-spinner-group">
                            <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                            Подтвердить
                        </div>
                    </Button> : ''
                }
                {
                    isBorrowerProfile && formData.file_id === null ? <Button
                        color="primary"
                        className="account__btn account__btn_primary"
                        onClick={confirmSignature}
                        disabled={!validate}
                    >
                        <div className="btn-spinner-group">
                            <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                            Подписать КЭП
                        </div>
                    </Button> : ''
                }
                {
                    validateAll ? <Button
                        color="primary"
                        className="account__btn account__btn_primary"
                        onClick={send}
                        disabled={!validate}
                    >
                        <div className="btn-spinner-group">
                            <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                            Подтвердить
                        </div>
                    </Button> : ''
                }
            </div>
        </div>
    )
}

export default AccreditationProfileModal;
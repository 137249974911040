// Количество слов в строке
import {leastOneNumber, lowercaseLetter, onlyCyrillic, uppercaseLetter} from "@shared/components/form/validate";

export const stCount = st => {
    if (st && st.length) {
        return st.trim().split(/\s+/).length
    } else {
        return 0
    }
}

// Валидация для компонента InputText
export const handleValidate = (value, rule, message, setMessage, errorType, setErrorType, validatorMessages, rank) => {
    switch (Object.keys(rule)[0]) {
        case "maxLength":
            if (value.length > rule.maxLength) {
                setErrorType("maxLength")
                setMessage(`Максимальная длина ${rule.maxLength} символов!`)
            } else if (message && errorType === "maxLength") {
                setMessage('')
            }
            break;
        case "minLength":
            if (value.length < rule.minLength) {
                setErrorType("minLength")
                setMessage(`Минимальная длина ${rule.minLength} символов!`)
            } else if (message && errorType === "minLength") {
                setMessage('')
            }
            break;
        case "onlyNumbers":
            if (value.length && !/^\d+$/.test(value)) {
                setErrorType("onlyNumbers")
                setMessage('Только цифры!')
            } else if (message && errorType === "onlyNumbers") {
                setMessage('')
            }
            break;
        case "leastOneNumber":
            if (value.length && !leastOneNumber.test(value)) {
                setErrorType("leastOneNumber")
                setMessage('Хотя бы одно число!')
            } else if (message && errorType === "leastOneNumber") {
                setMessage('')
            }
            break;
        case "leastOneUppercase":
            if (value.length && !uppercaseLetter.test(value)) {
                setErrorType("leastOneUppercase")
                setMessage('Хотя бы одна прописная буква!')
            } else if (message && errorType === "leastOneUppercase") {
                setMessage('')
            }
            break;
        case "leastOneLowercase":
            if (value.length && !lowercaseLetter.test(value)) {
                setErrorType("leastOneLowercase")
                setMessage('Хотя бы одна строчная буква!')
            } else if (message && errorType === "leastOneLowercase") {
                setMessage('')
            }
            break;
        case "required":
            if (!value) {
                setMessage('Обязательно для заполнения!')
                setErrorType("required")
            } else if (message && errorType === "required") {
                setMessage('')
            }
            break;
        case "arrayRequired":
            if (!value.length) {
                setMessage('Обязательно для заполнения!')
                setErrorType("arrayRequired")
            } else if (message && errorType === "arrayRequired") {
                setMessage('')
            }
            break;
        case "maxWords":
            if (stCount(value) > rule.maxWords) {
                setMessage(`Максимальная длина ${rule.maxWords} слова!`)
                setErrorType("maxWords")
            } else if (message && errorType === "maxWords") {
                setMessage('')
            }
            break;
        case "wordCount":
            if (stCount(value) !== rule.wordCount) {
                setMessage(`Необходимо ввести ${rule.wordCount} слова!`)
                setErrorType("wordCount")
            } else if (message && errorType === "wordCount") {
                setMessage('')
            }
            break;
        case "digitLimit":
            if (value.toString().length > rule.digitLimit) {
                if (validatorMessages.digitLimit) {
                    setMessage(validatorMessages.digitLimit)
                } else {
                    setMessage(`Максимальная длина ${rule.digitLimit} цифр!`)
                }
                setErrorType("digitLimit")
            } else if (message && errorType === "digitLimit") {
                setMessage('')
            }
            break;
        case "maxNumber":
            const newValue = rank ? +(('' + value).replace(/\s/g, '')) : value;

            if (newValue) {
                if (rule.maxNumber !== null && newValue > rule.maxNumber) {
                    if (validatorMessages.maxNumber) {
                        setMessage(validatorMessages.maxNumber)
                    } else {
                        setMessage(`Максимальное значение ${rule.maxNumber}!`)
                    }
                    setErrorType("maxNumber")
                } else if (message && errorType === "maxNumber") {
                    setMessage('')
                }
            }
            break;
        case "minNumber":
            if (rule.minNumber !== null && (+value < rule.minNumber)) {
                if (validatorMessages.minNumber) {
                    setMessage(validatorMessages.minNumber)
                } else {
                    setMessage(`Минимальное значение ${rule.minNumber}!`)
                }
                setErrorType("minNumber")
            } else if (message && errorType === "minNumber") {
                setMessage('')
            }
            break;
        case "minWords":
            if (value.length && stCount(value) < rule.minWords) {
                setErrorType("minWords")
                setMessage('Введите ФИО полностью!')
            } else if (message && errorType === "minWords") {
                setMessage('')
            }
            break;
        case "onlyCyrillic":
            if (onlyCyrillic.test(value)) {
                setErrorType("onlyCyrillic")
                setMessage('Ввод только кириллицы!')
            } else if (message && errorType === "onlyCyrillic") {
                setMessage('')
            }
            break;
        case "address":
            if (/[^,.а-яА-ЯїЇєЄіІёЁ0-9\s]/g.test(value)) {
                setErrorType("address")
                setMessage('Ввод только кириллицы!')
            } else if (message && errorType === "address") {
                setMessage('')
            }
            break;
        case "numbersCount":
            if (value.length && !new RegExp("(.*\\d.*){" + rule.numbersCount + "}").test(value)) {
                setErrorType("numbersCount")
                setMessage(`Минимальная длина ${rule.numbersCount} цифр!`)
            } else if (message && errorType === "numbersCount") {
                setMessage('')
            }
            break;
        case "numbersCountBig":
            if (value.length && !(value.length === rule.numbersCountBig)) {
                setErrorType("numbersCountBig")
                setMessage(`Минимальная длина ${rule.numbersCountBig} цифр!`)
            } else if (message && errorType === "numbersCountBig") {
                setMessage('')
            }
            break;
        case "email":
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                setErrorType("email")
                setMessage(`Введите Email!`)
            } else if (message && errorType === "email") {
                setMessage('')
            }
            break;
        case "decimalOrNumeric":
            if (!/^[0-9]\d*(\.\d+)?$/.test(value)) {
                setErrorType("decimalOrNumeric")
                setMessage(`Только десятичные или числовые значения!`)
            } else if (message && errorType === "decimalOrNumeric") {
                setMessage('')
            }
            break;
        default:
            break;
    }
}

// Название 12 месяцев года на русском
export const monthsRu = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
export const monthsRuRP = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

// Разделить число на разряды
export const getRank = value => {
    const newValue = String(value);

    if (/^\d+$/.test(+newValue[newValue.length - 1])) return Number(removeSpaces(newValue)).toLocaleString();
    if (newValue.length === 0) return ''

    return newValue.substr(0, value.length - 1)
}

// Удалить все пробелы из строки
export const removeSpaces = value => value.replace(/\D/g, '');
import React, {useEffect, useReducer, useState} from 'react';
import {getQualificationsList} from "@actions/registryActions";
import RegistryTable from "@containers/Moderator/Registry/RegistryTable";
import Pagination from "@shared/components/pagination";
import {dataFormat, FILTER_RESET, reducer} from './helpers';
import {initState, tableHeader} from './data';
import {ColumnIcon} from "@shared/img/iconSvg";
import {
    SET_ALL_VISIBLE,
    SET_CURRENT_PAGE,
    SET_VISIBLE
} from "@containers/Moderator/Registry/~RegistryActionProfile/helpers";
import InputText from "@shared/components/form/profileForm/InputText";
import NotificationsBlock from "@shared/components/notifications/NotificationsBlock";
import {useDispatch} from "react-redux";

const RegistryQualifications = () => {
    const reduxDispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(null);
    const [data, setData] = useState(null);
    const [columnFilterIsOpen, setColumnFilterIsOpen] = useState(false);
    const [state, dispatch] = useReducer(reducer, initState);

    const excelTableData = [];
    const tableDataFormat = dataFormat(data?.data || [], reduxDispatch);
    const tableHeaderVisible = tableHeader.filter(item => state.tableHeaderVisible.includes(item.name));
    const excelTableHeaders = tableHeaderVisible
        .filter(item => !item?.noExport)
        .map(item => ({label: item.title, key: item.name}));

    const filterReset = () => dispatch({type: FILTER_RESET})

    // Excel data format
    tableDataFormat.forEach(item => {
        let dataItem = {};
        excelTableHeaders.forEach(elem => dataItem[elem.key] = item[elem.key])
        excelTableData.push(dataItem);
    })

    const excelData = {
        data: excelTableData,
        headers: excelTableHeaders,
        filename: 'Реестр заявлений о признании квал. инвесторами платформы.csv'
    };

    const columnFilterOnChange = e => {
        dispatch({
            type: SET_VISIBLE,
            payload: {name: e.target.name}
        })
    }

    const setCurrentPage = page => {
        dispatch({
            type: SET_CURRENT_PAGE,
            payload: page
        })
    }

    useEffect(() => {
        let unmounted = false;
        setLoading(true);
        clearTimeout(timer);

        setTimer(setTimeout(() => {
            getQualificationsList(state)
                .then(res => !unmounted && setData(res?.data || {}))
                .finally(() => !unmounted && setLoading(false))
        }, 500))

        return () => unmounted = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.search, state.sortedBy, state.orderBy, state.currentPage])

    return (
        <div className="registry registry__qualifications">
            <h1 className="page-title">Реестр заявлений о признании квал. инвесторами платформы</h1>
            <div className="registry__content">
                <div className="registryTableWrap">
                    <RegistryTable
                        state={state}
                        initState={initState}
                        filterDispatch={dispatch}
                        tableHeader={tableHeaderVisible}
                        tableData={tableDataFormat}
                        loading={loading}
                        filterReset={filterReset}
                        excelData={excelData}
                        tableTitle="Реестр заявлений о признании квал. инвесторами платформы"
                        className="registry__actionProfile"
                    />
                    <Pagination
                        className="pagination-bar"
                        meta={data?.meta || {}}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
                <div className="registry__columnFilter">
                    <button className="btn-reset" onClick={() => setColumnFilterIsOpen(true)}>
                        <ColumnIcon/>
                        <span>Колонки</span>
                    </button>
                </div>
                <NotificationsBlock isOpen={columnFilterIsOpen} setIsOpen={setColumnFilterIsOpen}>
                    <div className="registry__columnFilter_inner">
                        <div className="registry__columnFilter_header">
                            <button
                                className="btn-reset"
                                onClick={() => dispatch({type: SET_ALL_VISIBLE})}
                            >
                                Выбрать все
                            </button>
                        </div>
                        <div className="registry__columnFilter_content">
                            {
                                tableHeader
                                    .filter(item => !item?.noExport)
                                    .map((item, idx) => (
                                        <InputText
                                            key={idx}
                                            type="checkbox"
                                            name={item.name}
                                            label={item.title}
                                            onChange={columnFilterOnChange}
                                            value={!!state.tableHeaderVisible.includes(item.name)}
                                            containerClassName="profileTabs__edit_item profileTabs__edit_check"
                                        />
                                    ))
                            }
                        </div>
                    </div>
                </NotificationsBlock>
            </div>
        </div>
    )
};

export default RegistryQualifications;
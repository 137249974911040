import React from 'react';
import {accountDataItem} from "@containers/Account/Profile/helpers";
import EditAccountCompanyData from "@shared/components/account/accountCompanyData/EditAccountCompanyData";
import UploadField from "@shared/components/uploadField";

const AccountCompanyData = ({profileData, editForm}) => {
    const {name, inn, ogrn, legal_address, actual_address, charters} = profileData?.legal_form?.company || {};

    const data = [
        {name: 'Наименования компании', desc: name || ''},
        {name: 'ИНН', desc: inn || ''},
        {name: 'ОГРН', desc: ogrn || ''},
        {name: 'Юридический адрес', desc: legal_address || ''},
        {name: 'Фактический адрес', desc: actual_address || ''}
    ]

    return (
        <>
            <div className="profileTabs__tabItem accountPersonalData">
                <div className="profileTabs__tabItem_title">
                    <span>Данные компании</span>
                </div>
                <div className="profileTabs__tabItem_list">
                    {
                        editForm
                            ? <EditAccountCompanyData profileData={profileData}/>
                            : <>
                                {accountDataItem(data)}
                                {/*<div className="profileTabs__tabItem_files">
                                    <div className="profileTabs__tabItem_elem">
                                        <span className="profileTabs__tabItem_name">Устав</span>
                                        {
                                            charters.length
                                                ? <UploadField files={charters} edit={false} title="Устав"/>
                                                : <span className="profileTabs__tabItem_desc error">Заполните поле</span>
                                        }
                                    </div>
                                </div>*/}
                            </>
                    }
                </div>
            </div>
        </>
    )
}

export default AccountCompanyData;

import React, {useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import UploadField from "@shared/components/uploadField";
import {CalendarIcon} from "@shared/img/iconSvg";
import Select from "react-select";
import {propertyLandSelectOptions} from "@containers/Projects/AddProject/forms/Pledge/data";

const Land = ({data, setData, formError, setFormError, projectId}) => {
    const [selectValue, setSelectValue] = useState({value: 'ga', label: 'га'});
    const {
        address, certificate_date, certificate_number, documents, extract_egrn_from, extracts, images, land_category,
        number, other_information, permitted_use, total_area, certificates
    } = data;

    const handleOnChangeSelect = data => {
        setSelectValue(data);
        setData({
            target: {
                name: 'total_area_type',
                value: data.value
            }
        })
    }

    return (
        <>
            <InputText
                formError={formError}
                name="address"
                id="landAddressInput"
                onChange={setData}
                label="Адрес"
                setFormError={setFormError}
                type="text"
                value={address || ''}
                validator={[{maxLength: 200}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                formError={formError}
                name="number"
                id="landNumberInput"
                onChange={setData}
                label="Кадастровый номер"
                setFormError={setFormError}
                type="text"
                value={number || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
            />
            <div className="addProject__landSelectGroup">
                <InputText
                    name="total_area"
                    id="totalAreaInput"
                    formError={formError}
                    setFormError={setFormError}
                    onChange={setData}
                    label="Площадь"
                    value={total_area || ''}
                    labelClass="requiredMark"
                    validator={[{maxLength: 10}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue={
                        <Select
                            options={propertyLandSelectOptions}
                            value={selectValue}
                            isSearchable={false}
                            className="pledge__select pledge__landSelect_wrap"
                            classNamePrefix="pledge__landSelect"
                            onChange={handleOnChangeSelect}
                        />
                    }
                    containerClassName="addProject__middleInput"
                />
            </div>
            <InputText
                name="land_category"
                id="landCategoryInput"
                onChange={setData}
                label="Категория земель"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={land_category || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                rows={2}
                type="textarea"
                onChange={setData}
                formError={formError}
                name="permitted_use"
                value={permitted_use || ''}
                id="permittedUseInput"
                setFormError={setFormError}
                validator={[{maxLength: 200}, {required: true}]}
                labelClass="requiredMark"
                label="Разрешенное использование"
                containerClassName="addProject__fullInput"
            />
            <div className="pledge__inputGroup inputText__wrapper">
                <label>Свидетельство о праве собственности (при наличии)</label>
                <div className="pledge__inputGroup_inner">
                    <InputText
                        name="certificate_number"
                        id="landCertificateNumberInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setData}
                        noLabel={true}
                        value={certificate_number || ''}
                        validator={[{maxLength: 50}, {required: true}]}
                        labelClass="requiredMark"
                        inputWithDesc
                        inputWithDescValue="№"
                        inputLabelLeft={true}
                        containerClassName="addProject__middleInput"
                    />
                    <InputText
                        name="certificate_date"
                        id="landCertificateDateInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setData}
                        noLabel={true}
                        type="date"
                        value={certificate_date || ''}
                        validator={[{required: true}]}
                        labelClass="requiredMark"
                        inputWithDesc
                        inputWithDescValue="от"
                        inputLabelLeft={true}
                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                    />
                </div>
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Скан свидетельства о праве собственности</label>
                <UploadField
                    files={certificates}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан свидетельства о праве собственности"
                    onChange={setData}
                    fileKey="certificates"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <InputText
                name="extract_egrn_from"
                id="landExtractEgrnFromInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Выписка из Единого государственного реестра недвижимости, дата"
                type="date"
                value={extract_egrn_from || ''}
                validator={[{required: true}]}
                labelClass="requiredMark"
                inputWithDesc
                inputWithDescValue={<CalendarIcon/>}
                containerClassName="addProject__middleInput addProject__dateInput date-picker"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Скан выписки</label>
                <UploadField
                    files={extracts}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан выписки из ЕГРН"
                    onChange={setData}
                    fileKey="extracts"
                    multiple={true}
                    maxFiles={5}
                    required={true}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <InputText
                rows={2}
                type="textarea"
                onChange={setData}
                formError={formError}
                name="other_information"
                value={other_information || ''}
                id="landOtherInformationInput"
                setFormError={setFormError}
                validator={[{maxLength: 500}]}
                label="Иные сведения"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Иные документы</label>
                <UploadField
                    files={documents}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Иные документы"
                    onChange={setData}
                    fileKey="documents"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Фото залога</label>
                <UploadField
                    files={images}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Фото залога"
                    onChange={setData}
                    fileKey="images"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png"
                    required={true}
                />
            </div>
        </>
    )

}

export default Land;
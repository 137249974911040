import React from 'react';
import UploadField from "@shared/components/uploadField";
import {Button} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {INVESTMENTS_CODE_SMS_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const ConfirmInvestmentSmsModal = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className='confirmInvestmentSmsModal'>
            <span className='textTitle'>Подтверждение инвестиции</span>
            <div className='documentationInfo__descCompany'>
                <UploadField viewDetail edit={false} notControls/>
            </div>
            <span className='capNotRequiredModal__subtitle fontSizeText'>Сумма 100 000,00 ₽ на срок 12 месяцев</span>
            <span className='fontSizeText colorSecondary'>
                Для подтверждения введите одноразовый пароль, отправленный на номер +7 (999) 969-59-06
            </span>
            <div className='investModalButtons'>
                <Button
                    className='loanOptionsEditButton__button'
                    color="danger"
                    size="sm"
                    outline
                    onClick={() => history.push("/project-detail/101010")}
                >
                    Отменить
                </Button>
                <Button
                    color="primary"
                    size="lg"
                    onClick={() => showModal(INVESTMENTS_CODE_SMS_MODAL)(dispatch)}
                >
                    Подтвердить
                </Button>
            </div>
        </div>
    )
};

export default ConfirmInvestmentSmsModal;
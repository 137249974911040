import React from 'react';
import {Button} from "reactstrap";
import PrimaryTooltip from "@shared/components/tooltip";
import {showModal} from "@actions/modalActions";
import {INVESTMENT_MODAL, PROFILE_IS_NOT_ACCREDITED_MODAL} from "@shared/components/modal/constants";
import {useDispatch, useSelector} from "react-redux";
import {accStatusKey} from "@utils/statusKey";

const LoanOptionsInvest = ({data}) => {
    const dispatch = useDispatch();
    const accreditationStatusKey = useSelector(state => state.user.profile.accreditation_status_key);

    return (
        <div className='optionsInvest'>
            <div className='optionsInvest__item'>
                <span className='optionsInvest__item_desc'>Можно инвестировать до </span>
                <span className='optionsInvest__item_sums'> 50 000 ₽</span>
                <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                    <p>
                        1. Проект перешел к стадии подписания документов.
                        2. Проект перешел к стадии выплат
                        3. Проект перешел в просрочку
                        4. Проект завершен
                        5. Проект отменен
                        6. Проект не состоялся
                    </p>
                </PrimaryTooltip>
            </div>
            <div className='optionsInvest__item'>
                <span className='optionsInvest__item_desc'>Наличие КЭП для инвестирования: </span>
                <span className='optionsInvest__item_text'>Требуется</span>
                <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                    <p>
                        1. Проект перешел к стадии подписания документов.
                        2. Проект перешел к стадии выплат
                        3. Проект перешел в просрочку
                        4. Проект завершен
                        5. Проект отменен
                        6. Проект не состоялся
                    </p>
                </PrimaryTooltip>
            </div>
            <Button
                color="primary"
                size="lg"
                onClick={() => accreditationStatusKey === accStatusKey.accredited
                    ? showModal(INVESTMENT_MODAL)(dispatch)
                    : showModal(PROFILE_IS_NOT_ACCREDITED_MODAL)(dispatch)
                }
            >
                Инвестировать
            </Button>
        </div>
    )
};

export default LoanOptionsInvest;
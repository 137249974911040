import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import MediaPresentation from "@shared/components/presentation/MediaPresentation";

const PledgesProjectOther = ({data, idx, fRef}) => {
    const {
        other_information, documents, name, characteristics, address, object_documents, serial_number, date, images
    } = data.property;

    const dataFormat = [
        {name: 'Наименование объекта', desc: name || ''},
        {name: 'Качественные и количественные характеристики', desc: characteristics || ''},
        {name: 'Адрес местонахождения', desc: address || ''},
        {name: 'Документы на объект', desc: object_documents || '', type: 'file'},
        {name: 'Условный или идентификационный/серийный номер', desc: serial_number || ''},
        {name: 'Дата приобретения права собственности на объект', desc: date || ''},
        {name: 'Иные сведения', desc: other_information || ''},
        {name: 'Иные документы', desc: documents || '', type: 'file'},
        {
            name: 'Фото залога',
            desc: images ? <MediaPresentation files={images} className="moderationProject__medias"/> : ''
        }
    ]

    return <DataCard data={dataFormat} title={`Залог ${idx + 1}`} fRef={fRef}/>
}

export default PledgesProjectOther;

import {BaseService} from "@services/base.service";

export class QualificationsService extends BaseService {
    static get entity() {
        return '/qualifications'
    }
}

export class QualificationsSendService extends BaseService {
    static get entity() {
        return '/qualifications/send'
    }
}

export class QualificationsCheckService extends BaseService {
    static get entity() {
        return '/moderator/qualifications/check'
    }
}

export class QualificationsRemarksService extends BaseService {
    static get entity() {
        return '/moderator/qualifications/remarks'
    }
}

export class QualificationsProfileService extends BaseService {
    static get entity() {
        return '/qualifications/profile'
    }
}


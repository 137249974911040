import React, {Fragment, useEffect, useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import IconBtn from "@shared/components/form/IconBtn";
import {Table} from "reactstrap";
import {
    ADD_GOVERNING_PERSON,
    REMOVE_GOVERNING_PERSON,
    SET_GOVERNING_PERSON_VALUE,
    SET_MEETING_PERSON_ERROR
} from "../helpers";
import classnames from "classnames";
import {innMaskEntity, ogrnMask} from "@shared/components/account/helpers";

const GoverningPerson = ({state, dispatch, governingValidStatus, showResult}) => {
    const {persons_information, governingPersonValid} = state;
    const [addPersonBtnStatus, setAddPersonBtnStatus] = useState(false);
    const [runGoverningPersonValidate, setRunGoverningPersonValidate] = useState(false);

    const classes = classnames({
        'dataCard': true,
        'governingPerson': true,
        'moderationProject__showResult': showResult
    })

    const setFormError = (error, idx) => {
        dispatch({
            type: SET_MEETING_PERSON_ERROR,
            payload: {error, idx}
        })
    }

    const removeMeeting = idx => {
        dispatch({
            type: REMOVE_GOVERNING_PERSON,
            payload: {idx}
        })
    }


    const addMeeting = () => {
        if (addPersonBtnStatus) {
            dispatch({type: ADD_GOVERNING_PERSON})
            setRunGoverningPersonValidate(false)
        } else {
            setRunGoverningPersonValidate(true)
        }
    }

    const handleOnChangeMeetingList = (e, idx) => {
        dispatch({
            type: SET_GOVERNING_PERSON_VALUE,
            payload: {
                idx,
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    useEffect(() => {
        let status = true;

        for (let i = 0; i < governingPersonValid.length; i++) {
            if (governingPersonValid[i].length) {
                status = false
                break;
            }
        }

        persons_information.forEach((item, idx) => {
            for (let key in item) {
                if (!item[key]) {
                    status = false
                    break;
                }
            }
        })

        if (status !== setAddPersonBtnStatus) setAddPersonBtnStatus(status)
    }, [governingPersonValid, persons_information, runGoverningPersonValidate])

    return (
        <div className={classes}>
            <p className="dataCard__title">
                Сведения о лицах, имеющих право распоряжаться не менее чем 10 процентами голосов в высшем органе
                управления юридического лица, если таким лицом является корпорация
            </p>
            <div className="dataCard__inner">
                {
                    persons_information.length
                        ? <div className="governingPerson__wrap">
                            <Table className="governingPerson__table" borderless>
                                <thead>
                                <tr>
                                    <th style={{width: '34%'}}>Наименование участника</th>
                                    <th style={{width: '21%'}}>% доли в УК</th>
                                    <th style={{width: '21%'}}>ИНН</th>
                                    <th style={{width: '21%'}}>ОГРН</th>
                                    <th style={{width: '3%'}}/>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    persons_information.map((item, idx) => {
                                        const {name, share, inn, ogrn, address} = item;

                                        return (
                                            <Fragment key={idx}>
                                                <tr>
                                                    <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                                        <InputText
                                                            noLabel
                                                            noErrorMessage
                                                            name="name"
                                                            type="text"
                                                            value={name || ''}
                                                            onChange={e => handleOnChangeMeetingList(e, idx)}
                                                            validator={[{maxLength: 50}, {required: true}]}
                                                            formError={governingPersonValid[idx]}
                                                            setFormError={error => setFormError(error, idx)}
                                                            containerClassName="addProject__middleInput"
                                                            resetValidate={!governingValidStatus || runGoverningPersonValidate}
                                                        />
                                                    </td>
                                                    <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                                        <InputText
                                                            noLabel
                                                            noErrorMessage
                                                            inputWithDesc
                                                            name="share"
                                                            type="text"
                                                            inputWithDescValue="%"
                                                            value={share || ''}
                                                            onChange={e => handleOnChangeMeetingList(e, idx)}
                                                            validator={[{digitLimit: 3}, {maxNumber: 100}, {onlyNumbers: true}, {required: true}]}
                                                            formError={governingPersonValid[idx]}
                                                            setFormError={error => setFormError(error, idx)}
                                                            containerClassName="addProject__middleInput"
                                                            resetValidate={!governingValidStatus || runGoverningPersonValidate}
                                                        />
                                                    </td>
                                                    <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                                        <InputText
                                                            noLabel
                                                            noErrorMessage
                                                            name="inn"
                                                            type="mask"
                                                            value={inn || ''}
                                                            onChange={e => handleOnChangeMeetingList(e, idx)}
                                                            validator={[{numbersCount: 10}, {required: true}]}
                                                            mask={innMaskEntity}
                                                            formError={governingPersonValid[idx]}
                                                            setFormError={error => setFormError(error, idx)}
                                                            containerClassName="addProject__middleInput"
                                                            resetValidate={!governingValidStatus || runGoverningPersonValidate}
                                                        />
                                                    </td>
                                                    <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                                        <InputText
                                                            noLabel
                                                            noErrorMessage
                                                            name="ogrn"
                                                            type="mask"
                                                            value={ogrn || ''}
                                                            onChange={e => handleOnChangeMeetingList(e, idx)}
                                                            validator={[{numbersCount: 13}, {required: true}]}
                                                            mask={ogrnMask}
                                                            formError={governingPersonValid[idx]}
                                                            setFormError={error => setFormError(error, idx)}
                                                            containerClassName="addProject__middleInput"
                                                            resetValidate={!governingValidStatus || runGoverningPersonValidate}
                                                        />
                                                    </td>
                                                    <td>
                                                        <IconBtn
                                                            remove={true}
                                                            onClick={() => removeMeeting(idx)}
                                                            className="projectGoverning__removeIcon"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="1">
                                                        <div className="governingPerson__address_label">
                                                            <span>Адрес местонаходения</span>
                                                        </div>
                                                    </td>
                                                    <td colSpan="3">
                                                        <InputText
                                                            noLabel
                                                            noErrorMessage
                                                            name="address"
                                                            type="text"
                                                            value={address || ''}
                                                            onChange={e => handleOnChangeMeetingList(e, idx)}
                                                            validator={[{maxLength: 50}, {required: true}]}
                                                            formError={governingPersonValid[idx]}
                                                            setFormError={error => setFormError(error, idx)}
                                                            containerClassName="addProject__fullInput governingPerson__address"
                                                            resetValidate={!governingValidStatus || runGoverningPersonValidate}
                                                        />
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )
                                    })
                                }
                                </tbody>
                            </Table>
                            <IconBtn
                                title="Добавить участника"
                                onClick={addMeeting}
                                className={classnames({projectGoverning__addBtn: true, disabled: !addPersonBtnStatus})}
                            />
                        </div>
                        : <IconBtn
                            title="Добавить участника"
                            onClick={addMeeting}
                        />
                }
            </div>
        </div>
    )
}

export default GoverningPerson;

import React from 'react';
import Selfie from '@shared/components/camera/Selfie';
import PropTypes from "prop-types";

const WebCamModal = ({showModal, hideModal, setModalData, setPreviousModal}) => {
    return (
        <>
            <p className="modalRoot__title">Выберите область фото для сохранения</p>
            <Selfie
                showModal={showModal}
                hideModal={hideModal}
                setModalData={setModalData}
                setPreviousModal={setPreviousModal}
            />
        </>
    )
}

WebCamModal.propTypes = {
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    setPreviousModal: PropTypes.func.isRequired,
}

WebCamModal.defaultProps = {
    showModal: () => () => {
    },
    hideModal: () => () => {
    },
    setPreviousModal: () => () => {
    },
};

export default WebCamModal
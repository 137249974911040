import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import MainWrapper from '@app/MainWrapper';
import {Router} from "@router/Router";
import Routers from "@router/Routers";
import RouterContainer from "@router/RouterContainer";
import {matchPath, useHistory, useLocation} from "react-router-dom";
import ModalRoot from "@shared/components/modal/ModalRoot";
import ErrorBoundary from "@app/ErrorBoundary";
import {hideAllModals} from "@actions/modalActions";
import Layout from "@layout/index";
import Footer from "@layout/footer/Footer";
import {isAuth} from "@utils/auth";

const AppRouter = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [routers, setRouters] = useState(null);
    const [router, setRouter] = useState(null);

    const roles = useSelector(state => state.user.user.roles);

    useEffect(() => {
        if (roles.length > 0 || !isAuth()){
            setRouters(Routers.map((router, key) => <Router key={key} {...router}/>))
        }

    }, [roles])

    const location = useLocation()

    useEffect(() => {
        setRouter(Routers.find(router => matchPath(location.pathname, router)))
    }, [history, location])

    useEffect(() => {
        return history.listen(() => {
            hideAllModals()(dispatch)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history])

    return (
        <MainWrapper>
            <ErrorBoundary>
                <main>
                    <RouterContainer>
                        <div>
                            {router && router.layout && <Layout/>}
                            {routers}
                            {router && router.layout && <Footer/>}
                        </div>
                        <ModalRoot/>
                    </RouterContainer>
                </main>
            </ErrorBoundary>
        </MainWrapper>
    )
}


export default AppRouter;

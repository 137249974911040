import React, {useState} from 'react';
import ImageInfoBlock from "@shared/components/infoBlock/ImageInfoBlock";
import infoBlockImg from "@shared/img/projects/securityInfoBlock.png";
import {addProjectMenu, checkFormIsCompleted, getFileFormat} from "@containers/Projects/AddProject/helpers";
import {Button, Spinner} from "reactstrap";
import AddProjectMenu from "@containers/Projects/AddProject/AddProjectMenu";
import {saveProject} from "@actions/projectsActions";
import {useSelector} from "react-redux";
import {remove} from "@utils/storage";
import ProjectTerms from "@containers/Projects/AddProject/forms/ProjectTerms";
import ProjectDesc from "@containers/Projects/AddProject/forms/ProjectDesc";
import Documents from "@containers/Projects/AddProject/forms/Documents";
import Pledge from "@containers/Projects/AddProject/forms/Pledge";
import BankDetails from "@containers/Projects/AddProject/forms/BankDetails";

const AddProjectControlButtons = ({step, setStep, setProjectIsDraft, setVisibleProjectAddStatus}) => {
    const [saveProjectSpinnerStatus, setSaveProjectSpinnerStatus] = useState(false);
    const [saveDraftProjectSpinnerStatus, setSaveDraftProjectSpinnerStatus] = useState(false);
    const {projectFormError, formIsCompleted, projectEditData} = useSelector(state => state.projects);

    const handleOnClickSaveProject = isDraft => {
        const formatData = getFileFormat(projectEditData);
        formatData.is_draft = isDraft;
        isDraft ? setSaveDraftProjectSpinnerStatus(true) : setSaveProjectSpinnerStatus(true)

        saveProject(formatData)
            .then(() => {
                setProjectIsDraft(isDraft);
                setVisibleProjectAddStatus(true);
                remove('editProjectId');
            })
            .catch(error => console.log(error))
            .finally(() => isDraft
                ? setSaveDraftProjectSpinnerStatus(false)
                : setSaveProjectSpinnerStatus(false)
            )
    }

    const SaveAsDraftBtn = () => (
        <Button
            color="success"
            size="lg"
            onClick={() => handleOnClickSaveProject(true)}
            outline
        >
            <div className="btn-spinner-group">
                <Spinner className={`btn-spinner ${saveDraftProjectSpinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                Сохранить как черновик
            </div>
        </Button>
    )

    const renderForm = () => {
        switch (step) {
            case 0:
                return <ProjectTerms step={step}/>
            case 1:
                return <ProjectDesc step={step}/>
            case 2:
                return <Documents step={step}/>
            case 3:
                return <Pledge step={step}/>
            case 4:
                return <BankDetails step={step}/>
            default:
                return null;
        }
    }

    return (
        <div className={`${step > 4 ? "" : 'addProject__wrap'}`}>
            {
                step === 3
                    ? <div className="addProject__infoBlock">
                        <ImageInfoBlock
                            image={infoBlockImg}
                            title="Возможно пропустить этот раздел"
                            desc="Наличие залога и поручителей значительно повышает интерес со стороны инвесторов"
                            btnTitle="Пропустить"
                            onClick={() => setStep(prev => prev + 1)}
                        />
                    </div>
                    : ""
            }
            <div className={`${step > 4 ? "" : 'addProject__content_group'}`}>
                <div className="addProject__content">
                    <div className="addProject__content_inner">
                        {renderForm()}
                    </div>
                    <div className={`addProject__controls ${step === 4 ? 'addProject__fullControls' : ''}`}>
                        {step < 4 ? <SaveAsDraftBtn/> : null}
                        {
                            step > 0 && step < 5
                                ? <Button color="success" size="sm" onClick={() => setStep(prev => prev - 1)} outline>
                                    Назад
                                </Button>
                                : null
                        }
                        {
                            step === 4
                                ? <>
                                    <SaveAsDraftBtn/>
                                    <Button
                                        color="primary"
                                        className="account__btn_primary addProject__btn addProject__middleBtn"
                                        onClick={() => handleOnClickSaveProject(false)}
                                    >
                                        <div className="btn-spinner-group">
                                            <Spinner
                                                className={`btn-spinner ${saveProjectSpinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                                            Отправить на проверку
                                        </div>
                                    </Button>
                                </>
                                : step < 4
                                    ? <Button
                                        color="primary"
                                        size="sm"
                                        disabled={!!projectFormError.length || !checkFormIsCompleted(step, formIsCompleted)}
                                        onClick={() => setStep(prev => prev + 1)}
                                    >
                                        Далее
                                    </Button>
                                    : ''
                        }
                    </div>
                </div>
                {
                    step < 5
                        ? <AddProjectMenu data={addProjectMenu[step]}/>
                        : ''
                }
            </div>
        </div>
    )
}

export default AddProjectControlButtons;

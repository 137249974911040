export const PreliminaryPaymentScheduleData = [
    {
        id: 0,
        data: '20.20.2020',
        operation: 'Заем',
        payment: '9 410 ',
        amortization: '9 000 ',
        interest: '410 ',
        balanceOwed: '85 240 ',
    },
    {
        id: 1,
        data: '20.20.2020',
        operation: '1 платеж',
        payment: '9 410 ',
        amortization: '9 000 ',
        interest: '410 ',
        balanceOwed: '85 240 ',
    },
    {
        id: 2,
        data: '20.20.2020',
        operation: '2 платеж',
        payment: '9 410 ',
        amortization: '9 000 ',
        interest: '410 ',
        balanceOwed: '85 240 ',
    },
    {
        id: 2,
        data: '20.20.2020',
        operation: '2 платеж',
        payment: '9 410 ',
        amortization: '9 000 ',
        interest: '410 ',
        balanceOwed: '85 240 ',
    },
    {
        id: 3,
        data: '20.20.2020',
        operation: '3 платеж',
        payment: '9 410 ',
        amortization: '9 000 ',
        interest: '410 ',
        balanceOwed: '85 240 ',
    },
    {
        id: 4,
        data: '20.20.2020',
        operation: '4 платеж',
        payment: '9 410 ',
        amortization: '9 000 ',
        interest: '410 ',
        balanceOwed: '85 240 ',
    },
    {
        id: 5,
        data: '20.20.2020',
        operation: '5 платеж',
        payment: '9 410 ',
        amortization: '9 000 ',
        interest: '410 ',
        balanceOwed: '85 240 ',
    },
    {
        id: 6,
        data: '20.20.2020',
        operation: '6 платеж',
        payment: '9 410 ',
        amortization: '9 000 ',
        interest: '410 ',
        balanceOwed: '85 240 ',
    },
    {
        id: 7,
        data: 'Итого',
        operation: '',
        payment: '85 240 ',
        amortization: '81 240 ',
        interest: '21 240 ',
        balanceOwed: '',
    },
]
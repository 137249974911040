import React from 'react';
import {Button} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {REFILL_MODAL, WITHDRAW_FUNDS_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";

const ProjectsFeedBalance = () => {
    const dispatch = useDispatch();

    return (
        <div className="projectsFeed__balance projectsFeed__header_item">
            <div className="projectsFeed__balance_items">
                <div className="projectsFeed__balance_item">
                    <span className="projectsFeed__balance_value">90 000 000,00 ₽</span>
                    <span className="projectsFeed__balance_desc">Доступно</span>
                </div>
                <div className="projectsFeed__balance_item">
                    <span className="projectsFeed__balance_value">0,00 ₽</span>
                    <span className="projectsFeed__balance_desc">В резерве</span>
                </div>
            </div>
            <div className="projectsFeed__balance_btnGroup">
                <Button
                    color="primary"
                    className="account__btn_primary"
                    onClick={() => showModal(REFILL_MODAL)(dispatch)}
                >
                    Пополнить
                </Button>
                <Button
                    color="success"
                    className="account__btn_success"
                    onClick={() => showModal(WITHDRAW_FUNDS_MODAL)(dispatch)}
                    outline
                >
                    Вывести
                </Button>
            </div>
        </div>
    )
}

export default ProjectsFeedBalance;
import React, {useEffect, useState} from 'react';
import {getProjectsList} from "@actions/projectsActions";
import ProjectsListItem from './ProjectsListItem';


const ProjectsList = ({isDraft = false, hideModal}) => {
    const [projects, setProjects] = useState([]);
    const listTitle = isDraft ? 'У вас есть черновики проектов' : 'У вас уже есть активные проекты';

    useEffect(() => {
        getProjectsList(isDraft)
            .then(res => {
                setProjects(res?.data?.data || [])
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderProjects = () => (
        projects.map(item => <ProjectsListItem key={item.id} data={item} isDraft={isDraft} hideModal={hideModal}/>)
    )


    return projects.length
        ? (
            <div className="draftProjects">
                <p className="draftProjects__title modalFullScreen__title">{listTitle}</p>
                <div className="draftProjects__items">
                    {renderProjects()}
                </div>
            </div>
        )
        : null
}

export default ProjectsList;
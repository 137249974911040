import React from 'react';
import DocumentationInfo from "@shared/components/projects/projectDetail/Documentation/DocumentationInfo";
import PledgeDocuments from "@shared/components/projects/projectDetail/Documentation/PledgeDocuments";

const Documentation = ({data}) => {
    return (
        <div className='documentation'>
            <PledgeDocuments data={data}/>
            <DocumentationInfo data={data}/>
        </div>
    )
};
export default Documentation;
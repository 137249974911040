import React from 'react';

const QualifiedInvestorDetail = () => {
    return <div className="qualifiedInvestor__detail qualifiedInvestor__modal">
        <p className="modalFullScreen__title">Разъяснение</p>
        <div className="qualifiedInvestor__detail_desc">
            <p>
                В соответствии со статьей 7 закона №259-ФЗ для физ. лиц, не являющихся квалифицированными инвесторами,
                установлено ограничение суммы инвестирования с использованием инвестиционных платформ
                в размере <br/>600 000 руб. в год.
            </p>
            <p>
                Согласно указанию Банка России № 3629-У Физ. лицо может быть признано квалифицированным инвестором,
                если оно отвечает любому из следующих требований.
            </p>
            <p>
                1. Общая стоимость ценных бумаг, которыми владеет это лицо, и (или) общий размер обязательств из
                договоров,
                являющихся производными финансовыми инструментами и заключенных за счет этого лица, рассчитанные в
                порядке,
                предусмотренном пунктом 2.4 настоящего Указания, должны составлять
                не менее 6 миллионов рублей. <sup>*<sup>1</sup></sup>
            </p>
            <p>
                2. Имеет опыт работы в российской и (или) иностранной организации, которая совершала сделки с ценными
                бумагами и (или) заключала договоры, являющиеся производными финансовыми инструментами:
            </p>
            <ul>
                <li>
                    не менее двух лет, если такая организация (организации) является квалифицированным инвестором
                    в соответствии с пунктом 2 статьи 51.2 Федерального закона "О рынке ценных бумаг";
                </li>
                <li>не менее 3 лет в иных случаях.</li>
            </ul>
            <p>
                3. Совершало сделки с ценными бумагами и (или) заключало договоры, являющиеся производными финансовыми
                инструментами, за последние четыре квартала в среднем не реже 10 раз в квартал, но не реже одного
                раза в месяц. При этом совокупная цена таких сделок (договоров) должна составлять не менее 6 миллионов
                рублей.
            </p>
            <p>4. Размер имущества, принадлежащего лицу, составляет не менее 6 миллионов
                рублей. <sup>*<sup>2</sup></sup></p>
            <p>
                5. Имеет высшее экономическое образование, подтвержденное документом государственного образца
                Российской Федерации о высшем образовании, выданным образовательной организацией высшего
                профессионального образования, которая на момент выдачи указанного документа осуществляла
                аттестацию граждан в сфере профессиональной деятельности на рынке ценных бумаг, или любой из
                следующих аттестатов и сертификатов: квалификационный аттестат специалиста финансового рынка,
                квалификационный аттестат аудитора, квалификационный аттестат страхового актуария, сертификат
                "Chartered Financial Analyst (CFA)", сертификат "Certified International Investment Analyst (CIIA)",
                сертификат "Financial Risk Manager (FRM)"
            </p>
        </div>
        <div className="qualifiedInvestor__detail_note">
            <p><span>1</span> При расчете этой суммы учитываются следующие финансовые инструменты:</p>
            <ul>
                <li>
                    государственные ценные бумаги Российской Федерации, государственные ценные бумаги субъектов
                    Российской Федерации и муниципальные ценные бумаги;
                </li>
                <li>акции и облигации российских эмитентов;</li>
                <li>государственные ценные бумаги иностранных государств;</li>
                <li>акции и облигации иностранных эмитентов;</li>
                <li>российские депозитарные расписки и иностранные депозитарные расписки на ценные бумаги;</li>
                <li>инвестиционные паи паевых инвестиционных фондов и паи (акции) иностранных инвестиционных фондов;
                </li>
                <li>ипотечные сертификаты участия;</li>
                <li>заключаемые на организованных торгах договоры, являющиеся производными финансовыми инструментами.
                </li>
            </ul>
            <p><span>2</span> Учитывается только следующее имущество:</p>
            <ul>
                <li>
                    денежные средства, находящиеся на счетах и (или) во вкладах (депозитах), открытых в кредитных
                    организациях в соответствии с нормативными актами Банка России, и (или) в иностранных банках,
                    с местом учреждения в государствах, указанных в подпунктах 1 и 2 пункта 2 статьи 51.1 Федерального
                    закона "О рынке ценных бумаг", и суммы начисленных процентов;
                </li>
                <li>
                    требования к кредитной организации выплатить денежный эквивалент драгоценного металла по учетной
                    цене соответствующего драгоценного металла;
                </li>
                <li>
                    ценные бумаги, в том числе переданные физическим лицом в доверительное управление, предусмотренные в
                    п. 1
                    настоящего разъяснения (в соответствии с пунктом 2.3 Указа Банка России № 3629-У)
                </li>
            </ul>
        </div>
    </div>

}

export default QualifiedInvestorDetail;
import React, {memo, useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {Col, Row} from "reactstrap";
import UploadField from "@shared/components/uploadField";
import {SET_COMPANY, SET_ERROR} from "@shared/components/account/EditProfile/helpers/constants";

const CompanyData = ({data, resetValidate, formError, dispatch, errorDispatch}) => {
    const {name, inn, ogrn, charters, legal_address, actual_address, address_matches} = data;
    const [actualAddressResetValidate, setActualAddressResetValidate] = useState(false)

    const handleOnChange = (event) => {
        dispatch({
            type: SET_COMPANY,
            payload: {
                name: event.target.name,
                value: event.target.value,
            }
        })
    }

    const setFormError = (error) => {
        errorDispatch({
            type: SET_ERROR,
            payload: {
                name: 'company',
                value: error,
            }
        })
    }

    const addressMatchesOnChange = (event) => {
        handleOnChange(event);
        dispatch({
            type: SET_COMPANY,
            payload: {
                name: 'actual_address',
                value: legal_address,
            }
        })
        setActualAddressResetValidate(!actualAddressResetValidate)
    }

    /*const setResetValidate = () => {
        dispatch({type: SET_RESET_VALIDATE})
    }*/

    return (
        <div className="profileTabs__tabItem accountPersonalData">
            <div className="profileTabs__tabItem_title">
                <span>Данные компании</span>
            </div>
            <div className="profileTabs__tabItem_list">
                <div className="profileTabs__edit">
                    <InputText
                        type="text"
                        name="name"
                        id="nameInput"
                        label="Наименования компании"
                        onChange={handleOnChange}
                        value={name}
                        validator={[{required: true}, {maxLength: 300}]}
                        resetValidate={resetValidate}
                        containerClassName="profileTabs__edit_item"
                        formError={formError}
                        setFormError={setFormError}
                        tooltip="Правовая форма и наименование указываются без сокращений"
                        tooltipClass="profileTabs__companyNameTooltip"
                        checkAfterFocus={false}
                    />
                    <Row>
                        <Col md={6}>
                            <InputText
                                type="mask"
                                name="inn"
                                id="inn"
                                label="ИНН"
                                onChange={handleOnChange}
                                value={inn}
                                validator={[{required: true}, {numbersCount: 10}]}
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                formError={formError}
                                setFormError={setFormError}
                                resetValidate={resetValidate}
                                containerClassName="profileTabs__edit_item"
                                checkAfterFocus={false}
                            />
                        </Col>
                        <Col md={6}>
                            <InputText
                                type="mask"
                                name="ogrn"
                                id="ogrn"
                                label="ОГРН"
                                onChange={handleOnChange}
                                value={ogrn}
                                validator={[{required: true}, {numbersCount: 13}]}
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                formError={formError}
                                setFormError={setFormError}
                                resetValidate={resetValidate}
                                containerClassName="profileTabs__edit_item"
                                checkAfterFocus={false}
                            />
                        </Col>
                    </Row>
                    <div className="inputText__wrapper profileTabs__edit_item">
                        <label>Устав</label>
                        <UploadField
                            files={charters}
                            edit={true}
                            multiple={true}
                            maxFiles={5}
                            title="Устав"
                            onChange={handleOnChange}
                            formError={formError}
                            setFormError={setFormError}
                            resetValidate={resetValidate}
                            fileKey="charters"
                            fileType="company"
                            accept=".jpeg, .jpg, .png, .pdf"
                            checkAfterFocus={false}
                        />
                    </div>
                    <InputText
                        type="text"
                        name="legal_address"
                        id="legal_address"
                        label="Юридический адрес"
                        onChange={handleOnChange}
                        value={legal_address}
                        validator={[{required: true}, {maxLength: 500}]}
                        containerClassName="profileTabs__edit_item"
                        formError={formError}
                        setFormError={setFormError}
                        resetValidate={resetValidate}
                        checkAfterFocus={false}
                    />
                    <InputText
                        type="text"
                        name="actual_address"
                        id="actual_address"
                        label="Фактический адрес"
                        onChange={handleOnChange}
                        value={address_matches ? legal_address : actual_address}
                        validator={address_matches ? [] : [{required: true}, {maxLength: 500}]}
                        disabled={address_matches}
                        containerClassName="profileTabs__edit_item"
                        formError={formError}
                        setFormError={setFormError}
                        resetValidate={actualAddressResetValidate}
                        setResetValidate={setActualAddressResetValidate}
                        checkAfterFocus={false}
                    />
                    <InputText
                        type="checkbox"
                        name="address_matches"
                        id="company_address_matches"
                        label="Совпадает с юридическим адресом"
                        onChange={addressMatchesOnChange}
                        resetValidate={resetValidate}
                        formError={formError}
                        setFormError={setFormError}
                        value={address_matches}
                        containerClassName="profileTabs__edit_item profileTabs__edit_check"
                        checkAfterFocus={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(CompanyData);
import React, {useState} from 'react';
import classnames from "classnames";
import classNames from "classnames";
import {TriangleIcon} from "@shared/img/iconSvg";
import {UncontrolledTooltip} from "reactstrap";

const RegistryTableHeaderItem = ({item, registryTableFilter}) => {
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

    if (item.filterType) {
        const tooltipClass = classnames({
            'registryTable__tooltip': true,
            'registryTable__tooltip_md': ['interval', 'searchField', 'date'].includes(item.filterType)
        })

        return (
            <th className={item.cellSize || ''}>
                <div
                    id={`registryTable${item.id}`}
                    onClick={() => setTooltipIsOpen(prev => !prev)}
                    className={classnames({
                        registryTable__headerItem: true,
                        accreditationTable__headerItem: true,
                        registryTable__headerItem_filter: true
                    })}
                >
                    <span>{item.title}</span>
                    <div className={classNames({accreditationTable__sortedIcon: true, active: false})}>
                        <TriangleIcon/>
                    </div>
                </div>
                <UncontrolledTooltip
                    isOpen={tooltipIsOpen}
                    popperClassName={tooltipClass}
                    placement="bottom-start"
                    target={`registryTable${item.id}`}
                    trigger="click"
                    container="main"
                >
                    {registryTableFilter(item)}
                </UncontrolledTooltip>
                <button
                    type="button"
                    className={classNames({'registryTable__tooltip_close': true, 'show': tooltipIsOpen})}
                    onClick={() => setTooltipIsOpen(false)}
                />
            </th>
        )
    }

    return (
        <th className={item.cellSize || ''}>
            <div className="registryTable__headerItem accreditationTable__headerItem">
                <span>{item.title || ''}</span>
            </div>
        </th>
    )
}

export default RegistryTableHeaderItem;

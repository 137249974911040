import React from 'react';
import classNames from "classnames";
import {RightArrowIcon} from "@shared/img/iconSvg";
import {useSelector} from "react-redux";
import SkeletonSidebarUserInfo from '@shared/components/skeletons/SkeletonSidebar/SkeletonSidebarProfileInfo'
import EditAvatar from "@shared/components/sidebar/avatar/EditAvatar";
import {accStatusKey} from "@utils/statusKey";
import {checkInnerUser} from "@utils/users";
import {getRoles, getRolesWithInfo, getRoleWithInfo} from "@utils/auth";

const SelectedProfile = ({listVisible, setListVisible, profiles, selectedProfileId}) => {
    const profileData = profiles.find(item => item.id === selectedProfileId);
    const roles = getRoles()
    const innerUserFio = useSelector(state => state?.user?.user?.fio ? state.user.user.fio : '');
    const accreditationStatus = profileData?.accreditation_status?.value || '';
    const accreditationStatusKey = profileData?.accreditation_status_key || null;

    const accreditationStatusClass = classNames({
        profileSelection__status: true,
        'green-color': accreditationStatusKey === accStatusKey.accredited,
        'error-color': accreditationStatusKey === accStatusKey.notAccredited,
        'primary-color': accreditationStatusKey === accStatusKey.checking,
        'disabled-color': accreditationStatusKey === accStatusKey.rejected,
    })

    const profileSelectionListIcon = classNames({
        profileSelection__list_icon: true,
        'profileSelection__list_icon--open': listVisible,
    });

    const getProfileFio = () => {
        if (checkInnerUser(roles)) return innerUserFio

        if (profileData.legal_form_type === 'entity') {
            return profileData.legal_form?.company?.name || profileData.legal_form?.personal?.fio || ''
        }

        return profileData.legal_form?.personal?.fio || '';
    }

    const handleListVisibility = () => setListVisible(!listVisible);

    const renderProfileInfo = () => {
        return getRoleWithInfo().display_name && profileData
            ? <div className="profileSelection__wrap">
                <div className="profileSelection__avatar">
                    <EditAvatar/>
                </div>
                <div className="profileSelection__group">
                    <div className="profileSelection__type">
                        {getRolesWithInfo().map((role, idx) => <div key={idx}>{role.display_name}</div>)}
                    </div>
                    <div className="profileSelection__name">
                        {profileData ? getProfileFio() : ''}
                    </div>
                    <div className={accreditationStatusClass}>
                        {accreditationStatus}
                    </div>
                    {
                        !checkInnerUser(roles)
                            ? <div className={profileSelectionListIcon} onClick={handleListVisibility}>
                                <span>Выбрать профиль</span>
                                <RightArrowIcon/>
                            </div>
                            : null
                    }
                </div>
            </div>
            : <SkeletonSidebarUserInfo/>
    }

    return (
        <div className="profileSelection__user_info sidebar-hr">
            {renderProfileInfo()}
        </div>
    )
}

export default SelectedProfile;
import {ProjectsBorrowerService, ProjectsService} from "@services/projects.service";
import {set} from '@utils/storage';

// Constants
export const REMOVE_PLEDGE = 'REMOVE_PLEDGE';
export const SET_PLEDGE_TYPE = 'SET_PLEDGE_TYPE';
export const SET_PROJECT_LIST = 'SET_PROJECT_LIST';
export const SET_PLEDGE_VALUE = 'SET_PLEDGE_VALUE';
export const SET_PROPERTY_TYPE = 'SET_PROPERTY_TYPE';
export const SET_PROPERTY_VALUE = 'SET_PROPERTY_VALUE';
export const EDIT_PROJECT_VALUE = 'EDIT_PROJECT_VALUE';
export const ADD_DEFAULT_PLEDGE = 'ADD_DEFAULT_PLEDGE';
export const SET_PROJECT_EDIT_DATA = 'SET_PROJECT_EDIT_DATA';
export const SET_PROJECT_FORM_ERROR = 'SET_PROJECT_FORM_ERROR';
export const EDIT_PROJECT_DOCUMENTS = 'EDIT_PROJECT_DOCUMENTS';
export const SET_PLEDGE_SELECT_VALUE = 'SET_PLEDGE_SELECT_VALUE';
export const EDIT_PROJECT_BUSINESSES = 'EDIT_PROJECT_BUSINESSES';
export const PLEDGE_FORM_IS_COMPLETED = 'PLEDGE_FORM_IS_COMPLETED';
export const EDIT_PLEDGE_CONTRACT_VALUE = 'EDIT_PLEDGE_CONTRACT_VALUE';
export const SET_PROJECT_PAGINATION_DATA = 'SET_PROJECT_PAGINATION_DATA';
export const EDIT_PROJECT_BUSINESSES_OPEN = 'EDIT_PROJECT_BUSINESSES_OPEN';
export const SET_PROJECT_FORM_IS_COMPLETED = 'SET_PROJECT_FORM_IS_COMPLETED';
export const EDIT_PROJECT_COMPANY_ONE_VALUE = 'EDIT_PROJECT_COMPANY_ONE_VALUE';
export const SET_PLEDGE_PROPERTY_SELECT_VALUE = 'SET_PLEDGE_PROPERTY_SELECT_VALUE';

export const addProject = profile_id => {
    const params = {include: 'company,condition,documents,medias,pledges,bank_detail'};

    return dispatch => {
        if (profile_id) {
            return ProjectsBorrowerService.create({}, null, params)
                .then(res => {
                    dispatch({
                        type: SET_PROJECT_EDIT_DATA,
                        payload: res.data.data
                    })
                    // Сохраняем id редактируемого объекта в LocalStorage
                    set('editProjectId', res.data.data.id);
                })
                .catch(error => console.log(error))
        }
    }
}

export const saveProject = data => {
    return ProjectsBorrowerService.update(data.id, data)
}

export const getProjectsList = (isDraft) => {
    const params = {
        orderBy: 'created_at',
        limit: 5,
        page: 1,
        search: isDraft !== undefined ? `is_draft:${isDraft}` : '',
        include: 'company,bank_detail'
    };

    return ProjectsBorrowerService.getList(params)
        .then(res => res)
        .catch(error => console.log(error))
}

export const getProject = (projectId, dispatch) => {
    const params = {include: 'company,condition,documents,medias,pledges,bank_detail'};

    return dispatch => {
        return ProjectsBorrowerService.getById(projectId, params)
            .then(res => {
                if (res?.data?.data) {
                    dispatch({
                        type: SET_PROJECT_EDIT_DATA,
                        payload: res.data.data
                    })
                }
            })
            .catch(error => console.log(error))
    }
}

export const setProjectFormError = (data, dispatch) => {
    dispatch({
        type: SET_PROJECT_FORM_ERROR,
        payload: data
    })
}

export const setProjectFormIsCompleted = (name, value, dispatch) => {
    dispatch({
        type: SET_PROJECT_FORM_IS_COMPLETED,
        payload: {name, value}
    })
}

export const setSelectValue = (data, name, pledgeId) => {
    const value = {key: data.value, value: data.label};

    return dispatch => {
        dispatch({
            type: SET_PLEDGE_SELECT_VALUE,
            payload: {pledgeId, name, value}
        })
    }

}

export const setPropertySelectValue = (data, name, typeKey, pledgeId) => {
    const value = {key: data.value, value: data.label};

    return dispatch => {
        dispatch({
            type: SET_PROPERTY_VALUE,
            payload: {pledgeId, name, typeKey, value}
        })
    }

}

export const getFullProjectList = (status, page) => {
    const params = {
        status,
        limit: 6,
        page: page || 1,
        orderBy: 'created_at',
        include: 'company,condition,rating',
        search: 'is_draft:false',
    };

    return dispatch => {
        return ProjectsService.getList(params)
            .then(res => {
                dispatch({
                    type: page ? SET_PROJECT_PAGINATION_DATA : SET_PROJECT_LIST,
                    payload: res.data
                })
                return res;
            })
            .catch(error => console.log(error))
    }
}

export const getSingleProject = (id) => {
    const params = {
        include: [
            'profile.legal_form',
            'profile.user',
            'profile.user.roles',
            'condition',
            'company',
            'medias',
            'documents',
            'pledges',
            'government',
            'analytic',
            'profile.bank_details',
            'project_rating',
            'moderation'
        ].join(',')
    }

    return ProjectsService.getById(id, params)
        .then(data => data?.data?.data)
        .catch(error => console.log(error))
}




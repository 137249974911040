import React from 'react';
import MediaPresentation from "@shared/components/presentation/MediaPresentation";
import VideoPresentation from "@shared/components/presentation/VideoPresentation";

const AboutTheProject = ({data}) => {
    const {description, stage, medias, videos} = data;

    return (
        <div className='aboutTheProject'>
            <div className='aboutTheProject__info'>
                <span className='aboutTheProject__info_title'>О проекте</span>
                <div className='aboutTheProject__info_desc'>
                    <span>Описание проекта</span>
                    <span>{description}</span>
                </div>
                <div className='aboutTheProject__info_desc'>
                    <span>Перечень приобретаемого имущества</span>
                    <span>
                        Прежде всего, существующая теория обеспечивает широкому кругу
                        (специалистов) участие в формировании дальнейших направлений развития.
                    </span>
                </div>
                <div className='aboutTheProject__projectStage aboutTheProject__info_desc'>
                    <span>Стадия проекта</span>
                    <span className='aboutTheProject__projectStage_title'>{stage.key.toUpperCase()}</span>
                    <span>{stage.value}</span>
                </div>
            </div>
            <div className='aboutTheProject__media'>
                <span className='aboutTheProject__media_title'>Медиа</span>
                <div>
                    <MediaPresentation files={medias} className="moderationProject__medias"/>
                    <VideoPresentation videos={videos} width={262} height={147} className="moderationProject__video"/>
                </div>
            </div>
        </div>
    )
};

export default AboutTheProject;
import React, {useState} from 'react';
import cn from "classnames";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import InputText from "@shared/components/form/profileForm/InputText";
import RegisterPasswordValidateInfo from "./RegisterPasswordValidateInfo";
import RegisterPasswordFieldService from "./RegisterPasswordFieldService";

const RegisterPasswordField = ({state, dispatch, setData, setFormError, validateInfoVisible}) => {
    const [fieldFocused, setFieldFocused] = useState(false);
    const {formError, data, showPassword, formValidationCheck} = state;
    const fieldType = showPassword ? 'text' : 'password';
    const fieldErrorStatus = formError.includes('password');
    const fieldValidateRules = [
        {minLength: 8}, {leastOneNumber: true}, {leastOneUppercase: true},
        {leastOneLowercase: true}, {required: true}
    ]

    const registerFieldWrapClass = cn({
        'form__form-group-field': true,
        errorInput: fieldErrorStatus
    })

    const handleBlur = () => setFieldFocused(true)
    const handleOnChange = e => {
        if (!/[а-яА-ЯїЇєЄіІёЁ ]/g.test(e.target.value) && e.target.value.length < 255) setData(e)
    }

    return (
        <div className="register__emailField register__field">
            <span className="form-label">Пароль</span>
            <div className={registerFieldWrapClass}>
                <div className="form__form-group-icon">
                    <KeyVariantIcon/>
                </div>
                <InputText
                    noLabel
                    checkAfterFocus
                    noErrorMessage
                    type={fieldType}
                    name="password"
                    placeholder="Пароль"
                    className="account-input"
                    value={data.password || ''}
                    onChange={handleOnChange}
                    formError={formError}
                    setFormError={setFormError}
                    validator={fieldValidateRules}
                    onBlur={handleBlur}
                    resetValidate={formValidationCheck}
                    setResetValidate={value => dispatch({name: 'formValidationCheck', value})}
                />
                <RegisterPasswordFieldService state={state} dispatch={dispatch}/>
            </div>
            <RegisterPasswordValidateInfo
                fieldFocused={fieldFocused}
                visibleStatus={validateInfoVisible || fieldErrorStatus}
                value={data.password}
                dispatch={dispatch}
            />
        </div>
    )
};

export default RegisterPasswordField;
import React from 'react';
import {AddIcon, RemoveIcon} from "@shared/img/iconSvg";

const IconBtn = ({
                     title = '', onClick = () => {
    }, disabled = false, className, remove = false
                 }) => (
    <button
        className={`iconBtn profileTabs__edit_add ${remove ? 'iconBtn__remove' : ''} ${className ? className : ''}`}
        onClick={onClick}
        disabled={!!disabled}
    >
        <div className="iconBtn__circle_wrap">
            <div className="uploadField__download iconBtn__circle">
                {
                    remove
                        ? <RemoveIcon/>
                        : <AddIcon/>
                }
            </div>
        </div>
        {title ? <span>{title}</span> : null}
    </button>
)

export default IconBtn;
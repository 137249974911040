import {BaseService} from "@services/base.service";

export class OuterProfilesService extends BaseService {
    static get entity() {
        return '/outer/profiles'
    }
}

export class OuterProjectsService extends BaseService {
    static get entity() {
        return '/outer/projects'
    }
}

export const ADD_MEETING = 'ADD_MEETING';
export const REMOVE_MEETING = 'REMOVE_MEETING';
export const ADD_GOVERNING_PERSON = 'ADD_GOVERNING_PERSON';
export const REMOVE_GOVERNING_PERSON = 'REMOVE_GOVERNING_PERSON';
export const SET_MEETING_LIST_VALUE = 'SET_MEETING_LIST_VALUE';
export const SET_MEETING_PARTICIPANTS_ERROR = 'SET_MEETING_PARTICIPANTS_ERROR';
export const SET_MEETING_ERROR = 'SET_MEETING_ERROR';
export const SET_MEETING_PERSON_ERROR = 'SET_MEETING_PERSON_ERROR';
export const SET_GOVERNING_PERSON_VALUE = 'SET_GOVERNING_PERSON_VALUE';

export const initState = data => (
    {
        ...JSON.parse(JSON.stringify(data)),
        governingValid: [],
        participantsValid: [],
        governingPersonValid: []
    }
)

export const governingStructureReducer = (state, action) => {
    const {idx = null, name, value, error = []} = action.payload || {};

    switch (action.type) {
        case SET_GOVERNING_PERSON_VALUE:
            const dataPersonCopyList = [...state.persons_information];
            dataPersonCopyList[idx][name] = value

            return {
                ...state,
                persons_information: dataPersonCopyList,
            }
        case ADD_GOVERNING_PERSON:
            return {
                ...state,
                persons_information: [...state.persons_information, {
                    inn: '',
                    name: '',
                    ogrn: '',
                    share: '',
                    address: ''
                }],
                governingPersonValid: [...state.governingPersonValid, []]
            }
        case REMOVE_GOVERNING_PERSON:
            const dataPersonRemoveCopy = [...state.persons_information];
            const dataPersonValidRemoveCopy = [...state.governingPersonValid];

            dataPersonRemoveCopy.splice(idx, 1)
            dataPersonValidRemoveCopy.splice(idx, 1)

            return {
                ...state,
                persons_information: dataPersonRemoveCopy,
                governingPersonValid: dataPersonValidRemoveCopy,
            }
        case SET_MEETING_PERSON_ERROR:
            const personErrorCopy = [...state.governingPersonValid];
            personErrorCopy[idx] = [...error]

            return {
                ...state,
                governingPersonValid: personErrorCopy
            }
        case REMOVE_MEETING:
            const dataCopy = [...state.participants];
            const dataValidCopy = [...state.participantsValid];

            dataCopy.splice(idx, 1)
            dataValidCopy.splice(idx, 1)

            return {
                ...state,
                participants: dataCopy,
                participantsValid: dataValidCopy,
            }
        case ADD_MEETING:
            return {
                ...state,
                participants: [...state.participants, {name: '', share: ''}],
                participantsValid: [...state.participantsValid, []]
            }
        case SET_MEETING_LIST_VALUE:
            const dataCopyList = [...state.participants];
            dataCopyList[idx][name] = value

            return {
                ...state,
                participants: dataCopyList,
            }
        case SET_MEETING_ERROR:
            return {
                ...state,
                governingValid: action.payload
            }
        case SET_MEETING_PARTICIPANTS_ERROR:
            const participantsValidCopy = [...state.participantsValid];
            participantsValidCopy[idx] = [...error]

            return {
                ...state,
                participantsValid: participantsValidCopy
            }
        default:
            return {
                ...state,
                [name]: value
            }
    }
}

import React from 'react';
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import SchedulePanel from "@shared/components/modal/projects/AddProject/PaymentScheduleModal/SchedulePanel";

const data = [
    {month: '01.01.2019', b: 30000, a: 18000},
    {month: '01.03.2019', b: 30000, a: 19000},
    {month: '01.05.2019', b: 30000, a: 20000},
    {month: '01.07.2019', b: 30000, a: 21000},
    {month: '01.09.2019', b: 30000, a: 22000},
    {month: '01.11.2019', b: 30000, a: 23000},
    {month: '01.01.2020', b: 30000, a: 24000},
    {month: '01.03.2020', b: 30000, a: 25000},
    {month: '01.05.2020', b: 30000, a: 26000},
    {month: '01.07.2020', b: 30000, a: 27000},
    {month: '01.09.2020', b: 30000, a: 28000},
    {month: '01.11.2020', b: 30000, a: 29000},
    {month: '01.01.2021', b: 30000, a: 30000},
];

const CustomizedAxisTick = props => {
    const {x, y, payload} = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={15} textAnchor="end" fill="#666" transform="rotate(-49.08)">
                {payload.value}
            </text>
        </g>
    );
}

const AnnuitySchedule = () => {
    return (
        <SchedulePanel
            title="1. Аннуитет"
            desc="Аннуитетная схема предусматривает погашение кредита периодическими равновеликими платежами,
          которые включают как выплату основного долга, так и процентный платеж за пользование кредитом.
          В начале периода большую часть суммы выплаты составляют проценты за пользование кредитом,
          в дальнейшем – проценты уменьшаются, а тело кредита увеличивается "
        >
            <ResponsiveContainer height={320} className="dashboard__area">
                <AreaChart
                    data={data}
                    margin={{top: 10, right: 30, left: 0, bottom: 0}}
                    className="addProject__chart"
                >
                    <Legend
                        verticalAlign="top"
                        height={36}
                        align="right"
                        iconType="circle"
                        iconSize={11}
                    />
                    <defs>
                        <linearGradient id="colorA">
                            <stop offset="0%" stopColor="#55C983"/>
                            <stop offset="100%" stopColor="#97A4F0"/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid/>
                    <XAxis
                        interval={0}
                        tickCount={13}
                        dataKey="month"
                        tickLine={false}
                        axisLine={false}
                        tick={<CustomizedAxisTick/>}
                        height={70}
                    />
                    <YAxis
                        tickCount={7}
                        tickLine={false}
                        axisLine={false}
                        interval={0}
                        tickMargin={12}
                        tickFormatter={item => item.toLocaleString()}
                    />
                    {/*<Tooltip content={renderTooltipContent} />*/}
                    <Area type="monotone" dataKey="a" name="Тело" stackId="1" fillOpacity="0.75" stroke="url(#colorA)"
                          fill="url(#colorA)"/>
                    <Area type="monotone" dataKey="b" name="%" stackId="2" fillOpacity="0.75" stroke="#DEE9FF"
                          fill="#DEE9FF"/>
                </AreaChart>
            </ResponsiveContainer>
        </SchedulePanel>
    );
}

export default AnnuitySchedule;


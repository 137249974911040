import React from 'react';
import classnames from "classnames";
import {yourDocumentsTabs} from './helpers';

const DocumentTabs = ({activeTab, setActiveTab, setDocuments, documentsCount}) => {
    const toggle = (key) => {
        if (activeTab !== key) {
            setDocuments(null)
            setActiveTab(key)
        }
    };

    return yourDocumentsTabs.map(item => {
        const {id, key, title} = item;
        const classes = classnames({
            'profileTabs__link': true,
            active: activeTab === key
        })

        return (
            <span key={id} className={classes} onClick={() => toggle(key)}>
                {title}
                {
                    yourDocumentsTabs[0].key === key && (activeTab === yourDocumentsTabs[0].key && documentsCount > 0)
                        ? <span className="profileTabs__link_notice">!</span>
                        : null
                }
            </span>
        )
    })
};

export default DocumentTabs;
export const projectDetailMenuDesc = [
    {name: 'Параметры займа', id: 0},
    {name: 'О проекте', id: 1},
    {name: 'О заемщике', id: 2},
    {name: 'Опыт заемщика', id: 3},
    {name: 'Обеспечение', id: 4},
    {name: 'Заключение эксперта', id: 5},
    {name: 'Документы', id: 6},
    {name: 'Расчеты по проекту', id: 7},
    {name: 'Отчет по НДФЛ', id: 8},
]


export const projectDetailMenuDescInvestor = [
    {name: 'Параметры займа', id: 0},
    {name: 'О проекте', id: 1},
    {name: 'О заемщике', id: 2},
    {name: 'Опыт заемщика', id: 3},
    {name: 'Обеспечение', id: 4},
    {name: 'Заключение эксперта', id: 5},
    {name: 'Документы', id: 6},
    {name: 'Расчеты по проекту', id: 7},
]
import React from 'react';
import BankDetailsInvestor from "@shared/components/account/bankDetails/investor";
import BankDetailsBorrower from "@shared/components/account/bankDetails/borrower";
import {getRole} from "@utils/auth";

const ProfileTabsBankDetail = ({profileData}) => {
    const role = getRole()

    return role === 'investor'
        ? <BankDetailsInvestor data={profileData}/>
        : <BankDetailsBorrower data={profileData}/>
}

export default ProfileTabsBankDetail;
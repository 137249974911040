import React from 'react';

const Notification = ({desc}) => {
    return (
        <div className='profileIsNotAccredited__header'>
            {desc}
        </div>
    )
};

export default Notification;
import {SET_ACCREDITATION_PROFILE_ID} from "@actions/accreditationAction";

const initialState = {
    accreditationProfileId: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ACCREDITATION_PROFILE_ID:
            return {
                ...state,
                accreditationProfileId: action.payload
            };
        default:
            return state;
    }
}

import React from 'react';
import {LeftArrowIcon} from "@shared/img/iconSvg";
import PrimaryTooltip from "@shared/components/tooltip";
import {useHistory} from "react-router-dom";

const PlatformServices = () => {
    const history = useHistory();
    return (
        <div className="moderatorAccModal__reject platformServicesModal">
            <div
                className='platformServicesModal__feed'
                onClick={() => history.push("/project-detail/101010")}
            >
                <div className='platformServicesModal__feed__button'><LeftArrowIcon/></div>
                <span>Назад</span>
            </div>
            <div className='platformServicesModal__title'>
                <p className="modalFullScreen__title">Услуги платформы</p>
                <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                    <p>
                        1. Проект перешел к стадии подписания документов.
                        2. Проект перешел к стадии выплат
                        3. Проект перешел в просрочку
                        4. Проект завершен
                        5. Проект отменен
                        6. Проект не состоялся
                    </p>
                </PrimaryTooltip>
            </div>
            <div className='platformServicesModal__subtitle'>Подробнее о тарифах</div>
            <div className='platformServicesModal__services'>
                <span className='platformServicesModal__services__desc'>
                    Услуги по предоставлению доступа к сервисам инвестиционной
                    платформы INCROWD и привлечению инвестиций
                </span>
                <div className='platformServicesModal__services_rate'>
                    <span>Тариф: </span>
                    <span>20% от суммы займа</span>
                </div>
                <div className='platformServicesModal__services_rate'>
                    <span>Сумма вознаграждения: </span>
                    <span>50 000 ₽</span>
                    <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                        <p>
                            1. Проект перешел к стадии подписания документов.
                            2. Проект перешел к стадии выплат
                            3. Проект перешел в просрочку
                            4. Проект завершен
                            5. Проект отменен
                            6. Проект не состоялся
                        </p>
                    </PrimaryTooltip>
                </div>
            </div>
            <div className='platformServicesModal__services'>
                <span className='platformServicesModal__services__desc'>
                    Услуги платформы INCROWD по сопровождению государственной регистрации
                    залога движимого/недвижимого имущества
                </span>
                <div className='platformServicesModal__services_rate'>
                    <span>Тариф: </span>
                    <span>3 000 ₽ / 1 объект залога </span>
                </div>
                <div className='platformServicesModal__services_rate'>
                    <span>Сумма вознаграждения: </span>
                    <span>4</span>
                </div>
            </div>
            <div className='platformServicesModal__remuneration'>
                <span>Сумма вознаграждения: </span>
                <span>12 000 ₽</span>
            </div>
            <div className='platformServicesModal__price'>
                <div className='platformServicesModal__remuneration'>
                    <span>Сумма к оплате: </span>
                    <span>62 000 ₽</span>
                </div>
                <span className='platformServicesModal__price_desc'>Стоимость услуг платформы удерживается из суммы займа</span>
            </div>
        </div>
    )
};

export default PlatformServices;
import React from 'react';
import {Table} from 'reactstrap';
import {accBorrowerAndInvestorTableHeader, confirmInvestorTableHeader} from '../helpers';
import {TriangleIcon} from '@shared/img/iconSvg';
import classNames from "classnames";
import AccreditationUserListTableItem from "./AccreditationUserListTableItem";

const AccreditationUserListTable = ({sortedBy, setSortedBy, accType, userList = [], loading}) => {
    const sortedClass = classNames({
        accreditationTable__sortedIcon: true,
        active: sortedBy,
    });

    const tableClass = classNames({
        accreditationTable: true,
        accreditationTable__load: loading,
    });

    const renderHeaderItem = item => {
        return item.sorted
            ? <th key={item.id}>
                <div
                    onClick={() => setSortedBy(prev => !prev)}
                    className="accreditationTable__headerItem accreditationTable__sortedItem"
                >
                    <div className={sortedClass}>
                        <TriangleIcon/>
                    </div>
                    <span>{item.title}</span>
                </div>
            </th>
            : <th key={item.id} className="accreditationTable__headerItem">
                <span>{item.title}</span>
            </th>
    }

    const renderTableBody = () => userList.map(item => {
        const data = accType === 'qualificationsInvestor'
            ? {...item, profile: {...item?.profile}}
            : item;

        return <AccreditationUserListTableItem key={item.id} item={data} accType={accType}/>
    })

    const tableHeaderRender = () => {
        return accType === 'qualificationsInvestor'
            ? confirmInvestorTableHeader.map(renderHeaderItem)
            : accBorrowerAndInvestorTableHeader.map(renderHeaderItem)
    }

    const tableColgroupRender = () => {
        return accType === 'qualificationsInvestor'
            ? <colgroup>
                <col className="accreditationTable__xl"/>
                <col className="accreditationTable__lg"/>
                <col className="accreditationTable__xl"/>
                <col className="accreditationTable__md"/>
                <col className="accreditationTable__sm"/>
            </colgroup>
            : <colgroup>
                <col className="accreditationTable__xl"/>
                <col className="accreditationTable__md"/>
                <col className="accreditationTable__md"/>
                <col className="accreditationTable__xl"/>
                <col className="accreditationTable__lg"/>
                <col className="accreditationTable__sm"/>
            </colgroup>
    }

    return userList.length
        ? <div className={tableClass}>
            <Table className="table--bordered" responsive>
                {tableColgroupRender()}
                <thead>
                <tr>
                    {tableHeaderRender()}
                </tr>
                </thead>
                <tbody>
                {renderTableBody()}
                </tbody>
            </Table>
            {
                loading
                    ? <div className="accreditationTable__loader">
                        <div className="load__icon-wrap">
                            <svg className="load__icon">
                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                            </svg>
                        </div>
                    </div>
                    : ''
            }
        </div>
        : null
}

export default AccreditationUserListTable;
import React from 'react';

const SchedulePanel = ({title, desc, children}) => {
    return (
        <div className="schedulePanel">
            <div className="schedulePanel__title">
                <p>{title}</p>
            </div>
            <div className="schedulePanel__content">
                {children}
            </div>
            <div className="schedulePanel__desc">
                <p>{desc}</p>
            </div>
        </div>
    )
}

export default SchedulePanel;
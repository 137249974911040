import React from "react";
import {CloseIcon, DownloadIcon, UpdateIcon} from "@shared/img/iconSvg";
import UploadMoreFiles from "@shared/components/uploadField/UploadMoreFiles";
import {downloadBlobFile} from "@utils/files";
import classnames from "classnames";
import {Spinner} from "reactstrap";

const FileItemControls = props => {
    const {data, edit, openDropzone, spinner, update, removeFile, notControls} = props;
    const {id, extension, name} = data;

    const closeIconClasses = classnames({
        'uploadField__close': true,
        'disabled': spinner
    })

    const spinnerClasses = classnames({
        'btn-spinner': true,
        'green-color': true,
        'btn-spinner-visible': spinner
    })

    const handleOnClickClose = () => {
        if (!spinner) removeFile()
    }

    const handleOnClickDownload = () => {
        downloadBlobFile(id, `${name}.${extension}`)
    }

    if (update) {
        return spinner
            ? <Spinner className={spinnerClasses}/>
            : <div className="uploadField__download uploadField__update" onClick={openDropzone}>
                <UpdateIcon/>
            </div>
    }

    if (edit) {
        return (
            <>
                <div className={closeIconClasses} onClick={handleOnClickClose}>
                    <CloseIcon/>
                </div>
                <UploadMoreFiles {...props} />
            </>
        )
    }

    if (notControls) return null


    return (
        <div className="uploadField__download" onClick={handleOnClickDownload}>
            <DownloadIcon/>
        </div>
    )
}

export default FileItemControls;
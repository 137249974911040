import React from 'react';
import {Spinner} from "reactstrap";
import formCheckIcon from "@shared/img/account/check.svg";
import {AuthService} from "@services/auth.service";
import {getConfirmError} from './helpers';
import constants from '../../action';
import cn from "classnames";

const ConfirmationFieldService = ({state, dispatch, customErrorMessage, setCustomErrorMessage, parentType}) => {
    const {confirmationFieldServiceType, formError, data} = state;
    const {
        CONFIRMATION_FIELD_VISIBLE_CONFIRM_BTN, CONFIRMATION_FIELD_VISIBLE_SPINNER,
        CONFIRMATION_FIELD_VISIBLE_CHECK
    } = constants;
    const inputErrorStatus = customErrorMessage ? false : formError.includes(parentType);
    const requestType = parentType === 'email' ? 'signup' : 'confirmPhone';

    const confirmBtnClass = cn({
        'btn': true,
        'btn-outline-green': true,
        'btn-not-hover': customErrorMessage
    })

    const handleConfirm = () => {
        dispatch({name: 'confirmationFieldResetValidate', value: true})

        if (!inputErrorStatus && data[parentType]) {
            dispatch({name: 'confirmationFieldServiceType', value: CONFIRMATION_FIELD_VISIBLE_SPINNER})

            if (customErrorMessage) setCustomErrorMessage(null)

            AuthService[requestType](data[parentType])
                .then(() => dispatch({name: 'visibleConfirmCodeField', value: true}))
                .catch(error => getConfirmError(error, setCustomErrorMessage, parentType))
                .finally(() => dispatch({
                    name: 'confirmationFieldServiceType',
                    value: CONFIRMATION_FIELD_VISIBLE_CONFIRM_BTN
                }))
        }
    }

    switch (confirmationFieldServiceType) {
        case CONFIRMATION_FIELD_VISIBLE_CONFIRM_BTN:
            return (
                <div className="confirm-mail-btn">
                    <button
                        className={confirmBtnClass}
                        disabled={inputErrorStatus && !customErrorMessage}
                        onClick={handleConfirm}
                    >
                        Подтвердить
                    </button>
                </div>
            )
        case CONFIRMATION_FIELD_VISIBLE_SPINNER:
            return (
                <div className="form__input-icon-wrap">
                    <Spinner style={{color: '#58BF8F'}}/>
                </div>
            )
        case CONFIRMATION_FIELD_VISIBLE_CHECK:
            return (
                <div className="form__input-icon-wrap form__input-icon-check">
                    <img src={formCheckIcon} alt="confirmed"/>
                </div>
            )
        default:
            return null
    }
};

export default ConfirmationFieldService;
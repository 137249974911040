import {combineReducers, createStore} from 'redux';
import {reducer as reduxFormReducer} from 'redux-form';
import * as reducers from '@reducers/index';

const reducer = combineReducers({
    user: reducers.userReducer,
    auth: reducers.authReducer,
    form: reduxFormReducer,
    theme: reducers.themeReducer,
    sidebar: reducers.sidebarReducer,
    rtl: reducers.rtlReducer,
    modal: reducers.modalReducer,
    profiles: reducers.profilesReducer,
    projects: reducers.projectsReducer,
    accreditation: reducers.accreditationReducer,
    moderation: reducers.moderationReducer,
    innerUsers: reducers.innerUsersReducer,
    loading: reducers.loadingReducer,
});

const store = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
import React from 'react';
import {bool, object} from 'prop-types';
import Masonry from "react-masonry-css";
import {isEntity} from "@utils/profile";
import AccountCompanyData from "@shared/components/account/accountCompanyData";
import AccountPersonalData from "@shared/components/account/accountPersonalData";
import AccountPassportData from "@shared/components/account/accountPassportData";
import AdditionalInfo from "@shared/components/account/additionalInfo";
import {usePresenter} from "./hooks/usePresenter";
import EditProfile from "@shared/components/account/EditProfile";
import {Button} from "reactstrap";

const ProfileDataTab = props => {
    const {profileDataEditingStatus, profileData} = props;
    const addInfoForms = profileData?.legal_form?.questionnaire?.responses || [];
    const {setFormEditStatus} = usePresenter();

    return (
        <>
            <p className="clientAccount__tab_subtitle">Анкетные данные</p>
            {
                profileDataEditingStatus
                    ? <EditProfile profileData={profileData}/>
                    : (
                        <>
                            <Masonry
                                breakpointCols={{default: 2, 1400: 1}}
                                className="my-masonry-grid custom-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {isEntity() && <AccountCompanyData profileData={profileData}/>}
                                <AccountPersonalData profileData={profileData}/>
                                <AccountPassportData profileData={profileData}/>
                                {
                                    addInfoForms.map(item => (
                                        <div key={item.id} className="profileTabs__addInfo_item">
                                            <AdditionalInfo data={item}/>
                                        </div>
                                    ))
                                }
                            </Masonry>
                            <div className="accountProfiles__controls">
                                <Button color="primary" size="lg" onClick={setFormEditStatus}>
                                    Редактировать
                                </Button>
                            </div>
                        </>
                    )
            }
        </>
    )
};

ProfileDataTab.propTypes = {
    profileDataEditingStatus: bool.isRequired,
    profileData: object.isRequired
};

ProfileDataTab.defaultProps = {
    profileDataEditingStatus: false,
    profileData: {}
};

export default ProfileDataTab;
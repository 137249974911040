import React from 'react';
import {Button} from "reactstrap";

const ImageInfoBlock = ({
                            image = "", title = "", desc = "", btnTitle = null, onClick = () => {
    }, Icon
                        }) => {
    return (
        <div className="imageInfoBlock">
            <div className="imageInfoBlock__img">
                {image ? <img src={image} alt="Info Block"/> : <Icon/>}
            </div>
            <div className="imageInfoBlock__group">
                <div className="imageInfoBlock__desc">
                    <p className="imageInfoBlock__desc_title">{title}</p>
                    <p className="imageInfoBlock__desc_text">{desc}</p>
                </div>
                {
                    btnTitle
                        ? <div className="imageInfoBlock__btn">
                            <Button
                                color="success"
                                className="account__btn_success addProject__btn"
                                onClick={onClick}
                                outline
                            >
                                {btnTitle}
                            </Button>
                        </div>
                        : ""
                }
            </div>
        </div>
    )
}

export default ImageInfoBlock;
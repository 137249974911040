import React, {useState} from 'react';
import {useLocation} from "react-router-dom";
import {TabContent, TabPane} from 'reactstrap';
import ProfileTabsLinks from "@account/Profile/components/ProfileTabsLinks";
import ProfileTabsBankDetail from "@account/Profile/components/ProfileTabsBankDetail";
import ProfileDataTab from "@account/Profile/components/ProfileData/ProfileDataTab";


const ProfileTabs = props => {
    const query = new URLSearchParams(useLocation().search);
    const queryBankDetails = query.get("bank_details");
    const [activeTab, setActiveTab] = useState(queryBankDetails !== null ? 2 : 1);

    return (
        <div className="profileTabs">
            <ProfileTabsLinks activeTab={activeTab} setActiveTab={setActiveTab}/>
            <TabContent activeTab={activeTab} className="accountContent__wrap">
                <TabPane tabId={1} className="profileTabs__tab">
                    <ProfileDataTab {...props} />
                </TabPane>
                <TabPane tabId={2} className="profileTabs__tab">
                    <ProfileTabsBankDetail {...props} />
                </TabPane>
            </TabContent>
        </div>
    );
};

export default ProfileTabs;

import {constants} from "./helpers";

/*const setResetValidate = () => {
    editState('resetValidate')
}*/

const handleOnChange = ({target}) => dispatch => dispatch({
    type: constants.EDIT_FORM_VALUE,
    payload: {
        name: target.name,
        value: target.value,
    }
})

const handleOnChangeSelect = role => dispatch => dispatch({
    type: constants.SET_SELECT_VALUE,
    payload: role
})

const setFormError = error => dispatch => dispatch({
    type: constants.SET_FORM_ERROR,
    payload: error
})

const editState = (key, value) => dispatch => dispatch({
    type: constants.EDIT,
    payload: {key, value}
})

export default {
    handleOnChange,
    handleOnChangeSelect,
    setFormError,
    editState
}
import React, {useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import Select from "react-select";
import {propertyLandSelectOptions} from "@containers/Projects/AddProject/forms/Pledge/data";
import UploadField from "@shared/components/uploadField";
import {CalendarIcon} from "@shared/img/iconSvg";
import {EDIT_PLEDGE_CONTRACT_VALUE} from "@actions/projectsActions";
import {useDispatch} from "react-redux";

const Ownership = ({data, formError, setFormError, pledgeId, typeKey, projectId}) => {
    const dispatch = useDispatch();
    const [selectValue, setSelectValue] = useState({value: 'ga', label: 'га'});
    const {
        address, number, total_area, land_category, permitted_use, certificate_date,
        certificate_number, extract_egrn_from, other_information, extracts, documents, certificates
    } = data

    const setContractData = event => {
        dispatch({
            type: EDIT_PLEDGE_CONTRACT_VALUE,
            payload: {
                pledgeId,
                typeKey,
                name: event.target.name,
                value: event.target.value || null,
            }
        })
    }

    const handleOnChangeSelect = data => {
        setSelectValue(data);

        dispatch({
            type: EDIT_PLEDGE_CONTRACT_VALUE,
            payload: {
                pledgeId,
                typeKey,
                name: 'total_area_type',
                value: data.value,
            }
        })
    }

    return (
        <>
            <p className="addProject__form_title font-weight-normal">Право собственности на Земельный участок</p>
            <InputText
                formError={formError}
                name="address"
                id="addressInput"
                onChange={setContractData}
                label="Адрес"
                setFormError={setFormError}
                type="text"
                value={address || ''}
                validator={[{maxLength: 200}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                formError={formError}
                name="number"
                id="numberInput"
                onChange={setContractData}
                label="Кадастровый номер"
                setFormError={setFormError}
                type="text"
                value={number || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
            />
            <div className="addProject__landSelectGroup">
                <InputText
                    name="total_area"
                    id="totalAreaInput"
                    formError={formError}
                    setFormError={setFormError}
                    onChange={setContractData}
                    label="Площадь"
                    value={total_area || ''}
                    labelClass="requiredMark"
                    validator={[{maxLength: 10}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue={
                        <Select
                            options={propertyLandSelectOptions}
                            value={selectValue}
                            isSearchable={false}
                            className="pledge__select pledge__landSelect_wrap"
                            classNamePrefix="pledge__landSelect"
                            onChange={handleOnChangeSelect}
                        />
                    }
                    containerClassName="addProject__middleInput"
                />
            </div>
            <InputText
                name="land_category"
                id="landCategoryInput"
                onChange={setContractData}
                label="Категория земель"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={land_category || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                rows={2}
                type="textarea"
                onChange={setContractData}
                formError={formError}
                name="permitted_use"
                value={permitted_use || ''}
                id="permittedUseInput"
                setFormError={setFormError}
                validator={[{maxLength: 200}, {required: true}]}
                labelClass="requiredMark"
                label="Разрешенное использование"
                containerClassName="addProject__fullInput"
            />
            <div className="pledge__inputGroup inputText__wrapper">
                <label>Свидетельство о праве собственности</label>
                <div className="pledge__inputGroup_inner">
                    <InputText
                        name="certificate_number"
                        id="certificateNumberInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setContractData}
                        noLabel={true}
                        value={certificate_number || ''}
                        validator={[{maxLength: 50}]}
                        inputWithDesc
                        inputWithDescValue="№"
                        inputLabelLeft={true}
                        containerClassName="addProject__middleInput"
                    />
                    <InputText
                        name="certificate_date"
                        id="certificateDateInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setContractData}
                        noLabel={true}
                        type="date"
                        value={certificate_date || ''}
                        inputWithDesc
                        inputWithDescValue="от"
                        inputLabelLeft={true}
                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                    />
                </div>
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Скан свидетельства о праве собственности</label>
                <UploadField
                    files={certificates}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан свидетельства о праве собственности"
                    onChange={setContractData}
                    fileKey="certificates"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <InputText
                name="extract_egrn_from"
                id="extractEgrnFromInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setContractData}
                label="Выписка из Единого государственного реестра недвижимости, дата"
                type="date"
                value={extract_egrn_from || ''}
                validator={[{required: true}]}
                labelClass="requiredMark"
                inputWithDesc
                inputWithDescValue={<CalendarIcon/>}
                containerClassName="addProject__middleInput addProject__dateInput date-picker"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Скан выписки</label>
                <UploadField
                    files={extracts}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан выписки из ЕГРН"
                    onChange={setContractData}
                    fileKey="extracts"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                    required={true}
                />
            </div>
            <InputText
                rows={2}
                type="textarea"
                onChange={setContractData}
                formError={formError}
                name="other_information"
                value={other_information || ''}
                id="otherInformationInput"
                setFormError={setFormError}
                validator={[{maxLength: 500}]}
                label="Иные сведения"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Иные документы</label>
                <UploadField
                    files={documents}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Иные документы"
                    onChange={setContractData}
                    fileKey="documents"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
        </>
    )
}

export default Ownership;
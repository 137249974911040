import React, {useEffect, useState} from 'react';
import Select from "react-select";
import classnames from "classnames";
import InputText from "@shared/components/form/profileForm/InputText";
import {phoneMask} from "@shared/components/account/helpers";
import RegFormPassword from "@shared/components/form/registerForm/mailConfirmForm/RegFormPassword";
import actions from './action';
import {roleSelectData} from './helpers';

const InnerUserForm = ({state, dispatch, formType}) => {
    const [phoneRequestErrorMessage, setPhoneRequestErrorMessage] = useState(null);
    const [emailRequestErrorMessage, setEmailRequestErrorMessage] = useState(null);
    const {
        data, roles, formError, resetValidate, visiblePassValidInfo,
        passValidStatus, showPassError, requestErrors
    } = state || {};
    const {email, phone, fio, other_information} = data || {};
    const {handleOnChange, handleOnChangeSelect, setFormError, editState} = actions;

    const inputClasses = classnames({
        addNewProfile__input: formType !== 'update'
    })

    const selectClasses = classnames({
        pledge__select: true,
        inputErrorGroup: formType === 'update',
        addNewProfile__input: formType !== 'update',
        addInnerUser__select_error: !roles.length
    })

    const saveInputData = e => handleOnChange(e)(dispatch)
    const saveSelectData = e => handleOnChangeSelect(e)(dispatch)
    const saveFormError = e => setFormError(e)(dispatch)
    const onChangeState = (key, value) => editState(key, value)(dispatch)

    const renderPasswordField = () => {
        if (formType === 'update') return null

        return (
            <div className="modalWithSettings__item modalWithSettings__item_validate">
                <RegFormPassword
                    noDisabled
                    noPlaceholder
                    label="Пароль"
                    labelClass="requiredMark"
                    onFocus={() => onChangeState('visiblePassValidInfo', true)}
                    setValidStatus={value => onChangeState('passValidStatus', value)}
                    validateInfo={visiblePassValidInfo && !passValidStatus}
                    handleOnChangePassword={value => saveInputData({target: {name: 'password', value}})}
                    showError={showPassError}
                    setShowError={value => onChangeState('showPassError', value)}
                    resetValidate={resetValidate}
                    setResetValidate={() => onChangeState('resetValidate', !resetValidate)}
                />
            </div>
        )
    }

    useEffect(() => {
        if (requestErrors) {
            if (requestErrors?.phone?.length) setPhoneRequestErrorMessage(requestErrors.phone[0])
            if (requestErrors?.email?.length) setEmailRequestErrorMessage(requestErrors.email[0])
        }
    }, [requestErrors])

    return (
        <>
            <InputText
                id="fio"
                name="fio"
                label="ФИО"
                placeholder="Иванов Иван Иванович"
                className={inputClasses}
                labelClass="requiredMark"
                value={fio}
                onChange={saveInputData}
                validator={[{required: true}]}
                resetValidate={resetValidate}
                formError={formError}
                setFormError={saveFormError}
            />
            <InputText
                type="mask"
                name="phone"
                label="Телефон"
                labelClass="requiredMark"
                placeholder="+7 (999) 999-99-99"
                containerClassName={inputClasses}
                value={phone}
                onChange={saveInputData}
                validator={[{required: true}]}
                mask={phoneMask}
                formError={formError}
                setFormError={saveFormError}
                resetValidate={resetValidate}
                customErrorMessage={phoneRequestErrorMessage}
                setCustomErrorMessage={setPhoneRequestErrorMessage}
            />
            <InputText
                checkAfterFocus
                id="email"
                name="email"
                label="Email"
                labelClass="requiredMark"
                className={inputClasses}
                value={email}
                onChange={saveInputData}
                validator={[{email: true}, {required: true}]}
                resetValidate={resetValidate}
                formError={formError}
                setFormError={saveFormError}
                customErrorMessage={emailRequestErrorMessage}
                setCustomErrorMessage={setEmailRequestErrorMessage}
            />
            <div className="addInnerUser__select inputText__wrapper">
                <label className="requiredMark">Роль</label>
                <Select
                    isMulti
                    placeholder="Роли"
                    className={selectClasses}
                    classNamePrefix="pledge__select addInnerUser__select"
                    value={roles}
                    options={roleSelectData}
                    isSearchable={false}
                    onChange={saveSelectData}
                />
                {
                    !roles.length
                        ? <span className="form__form-error">Обязательно для заполнения!</span>
                        : null
                }
            </div>
            {renderPasswordField()}
            <InputText
                rows={2}
                type="textarea"
                id="other_information"
                name="other_information"
                label="Иная информация"
                className={inputClasses}
                value={other_information || ''}
                onChange={saveInputData}
                validator={[{maxLength: 500}]}
                resetValidate={resetValidate}
                formError={formError}
                setFormError={saveFormError}
            />
        </>
    )
};

export default InnerUserForm;
import store from "@redux/storeConfig/store";
import {getRole} from "@utils/auth";

export const isEntity = () => {
    const state = store.getState();
    const type = state?.profiles?.profileData?.legal_form_type || null;

    return type === "entity"

}

export const isBorrower = () => {
    const role = getRole();

    return role === "borrower"
}

export const isInvestor = () => {
    const role = getRole();

    return role === "investor"
}




import React, {useState} from 'react';
import {Spinner} from "reactstrap";
import {useDispatch} from 'react-redux';
import {useHistory} from "react-router-dom";
import {EditCircle} from "@shared/img/iconSvg";
import {getProject} from "@actions/projectsActions";
import {set} from "@utils/storage";
import {ProjectLogo} from '../helpers';

const ProjectsListItem = ({isDraft, data = {}, hideModal}) => {
    const history = useHistory()
    const dispatch = useDispatch();
    const {id, title, moderation_status, company} = data;
    const [spinnerStatus, setSpinnerStatus] = useState(false);
    const [logoError, setLogoError] = useState(false);

    const continueFilling = projectId => {
        // Сохраняем id редактируемого объекта в LocalStorage
        set('editProjectId', id);
        setSpinnerStatus(true)

        getProject(projectId)(dispatch).finally(() => {
            setSpinnerStatus(false)
            history.push('/projects/add') // Перенаправляем на страницу добавления/редактиррования проекта
            hideModal()
        })
    }


    return (
        <div key={id} className="draftProjects__item">
            <div className="draftProjects__item_group">
                <div
                    className={`draftProjects__item_icon ${!company.logo || logoError ? 'draftProjects__defaultIcon' : ''}`}>
                    {
                        company.logo
                            ? <ProjectLogo id={id} setLogoError={setLogoError}/>
                            : <EditCircle/>
                    }
                </div>
                <div className="draftProjects__item_desc">
                    <p className="draftProjects__item_number">{`№${id}`}</p>
                    <p className="draftProjects__item_name">{title || `Проект${id}`}</p>
                </div>
            </div>
            {
                isDraft
                    ? <div className="draftProjects__item_btn">
                        <button onClick={() => continueFilling(id)}>
                            <div className="btn-spinner-group addProject__list_btnGroup">
                                <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                                Продолжить заполнение
                            </div>
                        </button>
                    </div>
                    : <span
                        className="draftProjects__notification">{moderation_status ? moderation_status.value : ''}</span>
            }
        </div>
    )
}

export default ProjectsListItem;
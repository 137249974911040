import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import {
    propertyBuildingNumberSelectOptions,
    propertyPremiseContractSelectOptions
} from "@containers/Projects/AddProject/forms/Pledge/data";
import {getContractData} from "@shared/components/presentation/PledgesProject/helpers";
import MediaPresentation from "@shared/components/presentation/MediaPresentation";

const Building = ({data, idx, fRef}) => {
    const {
        address,
        number_type,
        total_area,
        object_composition,
        floors_number,
        plans,
        other_information,
        documents,
        images,
        number,
        contract_type,
        contract
    } = data.building;

    const dataFormat = [
        {name: 'Здание', type: 'subtitle'},
        {name: 'Адрес', desc: address || ''},
        {
            name: propertyBuildingNumberSelectOptions.filter(item => item.value === number_type)[0].label,
            desc: number || ''
        },
        {name: 'Общая площадь', desc: total_area || ''},
        {name: 'Состав объекта (описание согласно правоустанавливающему документу)', desc: object_composition || ''},
        {name: 'Этажность', desc: floors_number || ''},
        {name: 'Технический план (скан)', desc: plans || '', type: 'file'},
        {name: 'Иные сведения', desc: other_information || ''},
        {name: 'Иные документы', desc: documents || '', type: 'file'},
        {
            name: 'Фото залога',
            desc: images ? <MediaPresentation files={images} className="moderationProject__medias"/> : ''
        },
        {name: 'Имущественные права на земельный участок', type: 'subtitle'},
        {
            name: propertyPremiseContractSelectOptions.filter(item => item.value === contract_type)[0].label || '',
            type: 'subtitle'
        },
    ]

    return <DataCard data={getContractData(dataFormat, contract, contract_type)} title={`Залог ${idx + 1}`}
                     fRef={fRef}/>
}

export default Building;

export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY = 'CHANGE_MOBILE_SIDEBAR_VISIBILITY';
export const MENU_COLLAPSE = 'MENU_COLLAPSE';
export const HIDE_MENU_COLLAPSE = 'HIDE_MENU_COLLAPSE';

export function changeSidebarVisibility() {
    return {
        type: CHANGE_SIDEBAR_VISIBILITY,
    };
}

export function changeMobileSidebarVisibility() {
    return {
        type: CHANGE_MOBILE_SIDEBAR_VISIBILITY,
    };
}

export const changeMenuCollapse = (menu, status) => dispatch => dispatch({
    type: MENU_COLLAPSE,
    payload: {menu, status}
})

export const hideMenuCollapse = menu => dispatch => dispatch({
    type: HIDE_MENU_COLLAPSE
})
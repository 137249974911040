import React, {useEffect, useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {CalendarIcon} from "@shared/img/iconSvg";
import classnames from "classnames";
import IconBtn from "@shared/components/form/IconBtn";
import {
    ADD_EGRUL,
    REMOVE_EGRUL,
    SET_ANALYTIC_ERROR,
    SET_ARBITRATION_ERROR,
    SET_ARBITRATION_VALUE,
    SET_EGRUL_ERROR,
    SET_EGRUL_LIST_VALUE,
    SET_GOVERNMENT_PURCHASES_ERROR,
    SET_GOVERNMENT_PURCHASES_VALUE
} from "./helpers";
import {Table} from "reactstrap";

const AnalyticUnit = ({state, dispatch, analyticValidStatus, fRef}) => {
    const [addEgrulBtnStatus, setAddEgrulBtnStatus] = useState(false);
    const [egrulRunValidate, setEgrulRunValidate] = useState(false);
    const {
        analyticValid, analyticEgrulValid, credit_history, suspension, balance, revenue, net_profit, nds,
        contributions, total_paid, debt, entries_egrul = [], arbitration_cases = {}, execution_proceedings,
        availability_lists, government_purchases = {}, arbitrationCasesValid, governmentPurchasesValid
    } = state;

    const handleOnChange = e => {
        dispatch({
            payload: {
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const handleOnChangeEgrul = (e, idx) => {
        dispatch({
            type: SET_EGRUL_LIST_VALUE,
            payload: {
                idx,
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const setFormError = error => {
        dispatch({
            type: SET_ANALYTIC_ERROR,
            payload: error
        })
    }

    const arbitrationCasesSetFormError = data => {
        dispatch({
            type: SET_ARBITRATION_ERROR,
            payload: data
        })
    }

    const governmentPurchasesSetFormError = data => {
        dispatch({
            type: SET_GOVERNMENT_PURCHASES_ERROR,
            payload: data
        })
    }

    const addEgrul = () => {
        if (addEgrulBtnStatus) {
            dispatch({type: ADD_EGRUL})
            setEgrulRunValidate(false)
        } else {
            setEgrulRunValidate(true)
        }
    }

    const removeEgrul = idx => {
        dispatch({
            type: REMOVE_EGRUL,
            payload: {idx}
        })
    }

    const setEgrulFormError = (error, idx) => {
        dispatch({
            type: SET_EGRUL_ERROR,
            payload: {error, idx}
        })
    }

    const handleOnChangeArbitration = data => {
        dispatch({
            type: SET_ARBITRATION_VALUE,
            payload: data
        })
    }

    const handleOnChangeGovernmentPurchases = data => {
        dispatch({
            type: SET_GOVERNMENT_PURCHASES_VALUE,
            payload: data
        })
    }

    useEffect(() => {
        let status = true;

        for (let i = 0; i < analyticEgrulValid.length; i++) {
            if (analyticEgrulValid[i].length) {
                status = false
                break;
            }
        }

        entries_egrul.forEach((item, idx) => {
            for (let key in item) {
                if (!item[key]) {
                    status = false
                    break;
                }
            }
        })

        if (status !== addEgrulBtnStatus) setAddEgrulBtnStatus(status)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analyticEgrulValid, entries_egrul, egrulRunValidate])

    return (
        <div className="dataCard analyticUnit addProject__page" ref={fRef}>
            <p className="dataCard__title">
                Аналитический блок
            </p>
            <div className="dataCard__inner">
                <InputText
                    rows={2}
                    type="textarea"
                    onChange={handleOnChange}
                    formError={analyticValid}
                    setFormError={setFormError}
                    name="credit_history"
                    value={credit_history || ''}
                    resetValidate={!analyticValidStatus}
                    validator={[{maxLength: 200}, {required: true}]}
                    label="Кредитная история"
                    labelClass="requiredMark"
                    containerClassName="addProject__fullInput"
                />
                <InputText
                    rows={2}
                    type="textarea"
                    onChange={handleOnChange}
                    formError={analyticValid}
                    setFormError={setFormError}
                    name="suspension"
                    value={suspension || ''}
                    resetValidate={!analyticValidStatus}
                    validator={[{maxLength: 200}, {required: true}]}
                    label="Приостановки по банковским счетам"
                    labelClass="requiredMark"
                    containerClassName="addProject__fullInput"
                />
                <p className="dataCard__title">Финансы на конец года</p>
                <InputText
                    name="balance"
                    formError={analyticValid}
                    setFormError={setFormError}
                    onChange={e => handleOnChange({
                        target: {name: e.target.name, value: +(e.target.value.replace(/\D/g, ''))}
                    })}
                    label="Баланс"
                    value={balance || ''}
                    labelClass="requiredMark"
                    resetValidate={!analyticValidStatus}
                    validator={[{maxLength: 50}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue="₽"
                    containerClassName="addProject__middleInput"
                    rank={true}
                />
                <InputText
                    name="revenue"
                    formError={analyticValid}
                    setFormError={setFormError}
                    onChange={e => handleOnChange({
                        target: {name: e.target.name, value: +(e.target.value.replace(/\D/g, ''))}
                    })}
                    label="Выручка"
                    value={revenue || ''}
                    labelClass="requiredMark"
                    resetValidate={!analyticValidStatus}
                    validator={[{maxLength: 50}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue="₽"
                    containerClassName="addProject__middleInput"
                    rank={true}
                />
                <InputText
                    name="net_profit"
                    formError={analyticValid}
                    setFormError={setFormError}
                    onChange={e => handleOnChange({
                        target: {name: e.target.name, value: +(e.target.value.replace(/\D/g, ''))}
                    })}
                    label="Чистая прибыль"
                    value={net_profit || ''}
                    labelClass="requiredMark"
                    resetValidate={!analyticValidStatus}
                    validator={[{maxLength: 50}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue="₽"
                    containerClassName="addProject__middleInput"
                    rank={true}
                />
                <p className="dataCard__title">Налоги и сборы</p>
                <InputText
                    name="nds"
                    formError={analyticValid}
                    setFormError={setFormError}
                    onChange={e => handleOnChange({
                        target: {name: e.target.name, value: +(e.target.value.replace(/\D/g, ''))}
                    })}
                    label="НДС"
                    value={nds || ''}
                    labelClass="requiredMark"
                    resetValidate={!analyticValidStatus}
                    validator={[{maxLength: 50}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue="₽"
                    containerClassName="addProject__middleInput"
                    rank={true}
                />
                <InputText
                    name="contributions"
                    formError={analyticValid}
                    setFormError={setFormError}
                    onChange={e => handleOnChange({
                        target: {name: e.target.name, value: +(e.target.value.replace(/\D/g, ''))}
                    })}
                    label="Взносы в ПФР"
                    value={contributions || ''}
                    labelClass="requiredMark"
                    resetValidate={!analyticValidStatus}
                    validator={[{maxLength: 50}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue="₽"
                    containerClassName="addProject__middleInput"
                    rank={true}
                />
                <InputText
                    name="total_paid"
                    formError={analyticValid}
                    setFormError={setFormError}
                    onChange={e => handleOnChange({
                        target: {name: e.target.name, value: +(e.target.value.replace(/\D/g, ''))}
                    })}
                    label="Всего уплачено"
                    value={total_paid || ''}
                    labelClass="requiredMark"
                    resetValidate={!analyticValidStatus}
                    validator={[{maxLength: 50}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue="₽"
                    containerClassName="addProject__middleInput"
                    rank={true}
                />
                <InputText
                    name="debt"
                    formError={analyticValid}
                    setFormError={setFormError}
                    onChange={e => handleOnChange({
                        target: {name: e.target.name, value: +(e.target.value.replace(/\D/g, ''))}
                    })}
                    label="Задолженность на конец года"
                    value={debt || ''}
                    labelClass="requiredMark"
                    resetValidate={!analyticValidStatus}
                    validator={[{maxLength: 50}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue="₽"
                    containerClassName="addProject__middleInput"
                    rank={true}
                />
                <p className="dataCard__title">Записи в Егрюл</p>
                {
                    entries_egrul && entries_egrul.length
                        ? entries_egrul.map((item, idx) => {
                            const {date, event} = item;

                            return (
                                <div key={idx} className="analyticUnit__egrul_item">
                                    {
                                        idx > 0
                                            ? <div className="analyticUnit__egrul_control">
                                                <p>{`Запись ${idx + 1}`}</p>
                                                <IconBtn
                                                    remove={true}
                                                    onClick={() => removeEgrul(idx)}
                                                    className="projectGoverning__removeIcon"
                                                />
                                            </div>
                                            : ''
                                    }
                                    <InputText
                                        name="date"
                                        label="Дата"
                                        formError={analyticEgrulValid[idx]}
                                        setFormError={error => setEgrulFormError(error, idx)}
                                        onChange={e => handleOnChangeEgrul(e, idx)}
                                        type="date"
                                        value={date || ''}
                                        resetValidate={!analyticValidStatus || egrulRunValidate}
                                        validator={[{required: true}]}
                                        inputWithDesc
                                        labelClass="requiredMark"
                                        inputWithDescValue={<CalendarIcon/>}
                                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                                    />
                                    <InputText
                                        rows={2}
                                        type="textarea"
                                        onChange={e => handleOnChangeEgrul(e, idx)}
                                        formError={analyticEgrulValid[idx]}
                                        setFormError={error => setEgrulFormError(error, idx)}
                                        name="event"
                                        value={event || ''}
                                        resetValidate={!analyticValidStatus || egrulRunValidate}
                                        validator={[{maxLength: 700}, {required: true}]}
                                        label="Событие"
                                        labelClass="requiredMark"
                                        containerClassName="addProject__fullInput"
                                    />
                                </div>
                            )
                        })
                        : null
                }
                <IconBtn
                    title="Добавить запись"
                    onClick={addEgrul}
                    className={classnames({projectGoverning__addBtn: true, disabled: !addEgrulBtnStatus})}
                />
                <div className="analyticUnit__arbitration">
                    <p className="dataCard__title">Арбитражные дела</p>
                    <div className="analyticUnit__arbitration_inner governingPerson__wrap">
                        <Table className="governingPerson__table" borderless>
                            <thead>
                            <tr>
                                <th style={{width: '14%'}}>Роль</th>
                                <th style={{width: '43%'}}>За 12 месяцев</th>
                                <th style={{width: '43%'}}>За все время</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">Истец</th>
                                <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                    <div className="analyticUnit__arbitration_group">
                                        <InputText
                                            noLabel
                                            noErrorMessage
                                            name="money"
                                            formError={arbitrationCasesValid.plaintiffYear}
                                            setFormError={error => arbitrationCasesSetFormError({
                                                error,
                                                type: 'plaintiffYear'
                                            })}
                                            onChange={e => handleOnChangeArbitration({
                                                name: e.target.name,
                                                value: e.target.value.replace(/\D/g, ''),
                                                type: 'plaintiffYear'
                                            })}
                                            value={arbitration_cases?.plaintiff?.year?.money || ''}
                                            resetValidate={!analyticValidStatus}
                                            validator={[{maxLength: 15}, {required: true}]}
                                            inputWithDesc
                                            inputWithDescValue="₽"
                                            containerClassName="addProject__middleInput"
                                            rank={true}
                                        />
                                        <InputText
                                            noLabel
                                            noErrorMessage
                                            name="time"
                                            formError={arbitrationCasesValid.plaintiffYear}
                                            setFormError={error => arbitrationCasesSetFormError({
                                                error,
                                                type: 'plaintiffYear'
                                            })}
                                            onChange={e => handleOnChangeArbitration({
                                                name: e.target.name,
                                                value: e.target.value.replace(/\D/g, ''),
                                                type: 'plaintiffYear'
                                            })}
                                            value={arbitration_cases?.plaintiff?.year?.time || ''}
                                            resetValidate={!analyticValidStatus}
                                            validator={[{maxLength: 15}, {required: true}]}
                                            inputWithDesc
                                            inputWithDescValue="раз"
                                            containerClassName="addProject__middleInput"
                                            rank={true}
                                        />
                                    </div>
                                </td>
                                <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                    <div className="analyticUnit__arbitration_group">
                                        <InputText
                                            noLabel
                                            noErrorMessage
                                            name="money"
                                            formError={arbitrationCasesValid.plaintiffAll}
                                            setFormError={error => arbitrationCasesSetFormError({
                                                error,
                                                type: 'plaintiffAll'
                                            })}
                                            onChange={e => handleOnChangeArbitration({
                                                name: e.target.name,
                                                value: e.target.value.replace(/\D/g, ''),
                                                type: 'plaintiffAll'
                                            })}
                                            value={arbitration_cases?.plaintiff?.all?.money || ''}
                                            resetValidate={!analyticValidStatus}
                                            validator={[{maxLength: 15}, {required: true}]}
                                            inputWithDesc
                                            inputWithDescValue="₽"
                                            containerClassName="addProject__middleInput"
                                            rank={true}
                                        />
                                        <InputText
                                            noLabel
                                            noErrorMessage
                                            name="time"
                                            formError={arbitrationCasesValid.plaintiffAll}
                                            setFormError={error => arbitrationCasesSetFormError({
                                                error,
                                                type: 'plaintiffAll'
                                            })}
                                            onChange={e => handleOnChangeArbitration({
                                                name: e.target.name,
                                                value: e.target.value.replace(/\D/g, ''),
                                                type: 'plaintiffAll'
                                            })}
                                            value={arbitration_cases?.plaintiff?.all?.time || ''}
                                            resetValidate={!analyticValidStatus}
                                            validator={[{maxLength: 15}, {required: true}]}
                                            inputWithDesc
                                            inputWithDescValue="раз"
                                            containerClassName="addProject__middleInput"
                                            rank={true}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Ответчик</th>
                                <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                    <div className="analyticUnit__arbitration_group">
                                        <InputText
                                            noLabel
                                            noErrorMessage
                                            name="money"
                                            formError={arbitrationCasesValid.respondentYear}
                                            setFormError={error => arbitrationCasesSetFormError({
                                                error,
                                                type: 'respondentYear'
                                            })}
                                            onChange={e => handleOnChangeArbitration({
                                                name: e.target.name,
                                                value: e.target.value.replace(/\D/g, ''),
                                                type: 'respondentYear'
                                            })}
                                            value={arbitration_cases?.respondent?.year?.money || ''}
                                            resetValidate={!analyticValidStatus}
                                            validator={[{maxLength: 15}, {required: true}]}
                                            inputWithDesc
                                            inputWithDescValue="₽"
                                            containerClassName="addProject__middleInput"
                                            rank={true}
                                        />
                                        <InputText
                                            noLabel
                                            noErrorMessage
                                            name="time"
                                            formError={arbitrationCasesValid.respondentYear}
                                            setFormError={error => arbitrationCasesSetFormError({
                                                error,
                                                type: 'respondentYear'
                                            })}
                                            onChange={e => handleOnChangeArbitration({
                                                name: e.target.name,
                                                value: e.target.value.replace(/\D/g, ''),
                                                type: 'respondentYear'
                                            })}
                                            value={arbitration_cases?.respondent?.year?.time || ''}
                                            resetValidate={!analyticValidStatus}
                                            validator={[{maxLength: 15}, {required: true}]}
                                            inputWithDesc
                                            inputWithDescValue="раз"
                                            containerClassName="addProject__middleInput"
                                            rank={true}
                                        />
                                    </div>
                                </td>
                                <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                    <div className="analyticUnit__arbitration_group">
                                        <InputText
                                            noLabel
                                            noErrorMessage
                                            name="money"
                                            formError={arbitrationCasesValid.respondentAll}
                                            setFormError={error => arbitrationCasesSetFormError({
                                                error,
                                                type: 'respondentAll'
                                            })}
                                            onChange={e => handleOnChangeArbitration({
                                                name: e.target.name,
                                                value: e.target.value.replace(/\D/g, ''),
                                                type: 'respondentAll'
                                            })}
                                            value={arbitration_cases?.respondent?.all?.money || ''}
                                            resetValidate={!analyticValidStatus}
                                            validator={[{maxLength: 15}, {required: true}]}
                                            inputWithDesc
                                            inputWithDescValue="₽"
                                            containerClassName="addProject__middleInput"
                                            rank={true}
                                        />
                                        <InputText
                                            noLabel
                                            noErrorMessage
                                            name="time"
                                            formError={arbitrationCasesValid.respondentAll}
                                            setFormError={error => arbitrationCasesSetFormError({
                                                error,
                                                type: 'respondentAll'
                                            })}
                                            onChange={e => handleOnChangeArbitration({
                                                name: e.target.name,
                                                value: e.target.value.replace(/\D/g, ''),
                                                type: 'respondentAll'
                                            })}
                                            value={arbitration_cases?.respondent?.all?.time || ''}
                                            resetValidate={!analyticValidStatus}
                                            validator={[{maxLength: 15}, {required: true}]}
                                            inputWithDesc
                                            inputWithDescValue="раз"
                                            containerClassName="addProject__middleInput"
                                            rank={true}
                                        />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                    <InputText
                        rows={2}
                        type="textarea"
                        onChange={handleOnChange}
                        formError={analyticValid}
                        setFormError={setFormError}
                        name="execution_proceedings"
                        value={execution_proceedings || ''}
                        resetValidate={!analyticValidStatus}
                        validator={[{maxLength: 200}, {required: true}]}
                        label="Исполнительные производства"
                        labelClass="requiredMark"
                        containerClassName="addProject__fullInput"
                    />
                    <InputText
                        rows={2}
                        type="textarea"
                        onChange={handleOnChange}
                        formError={analyticValid}
                        setFormError={setFormError}
                        name="availability_lists"
                        value={availability_lists || ''}
                        resetValidate={!analyticValidStatus}
                        validator={[{maxLength: 200}, {required: true}]}
                        label="Наличие в публичных списках"
                        labelClass="requiredMark"
                        containerClassName="addProject__fullInput"
                    />
                </div>
                <div className="analyticUnit__arbitration">
                    <p className="dataCard__title">Госзакупки</p>
                    <div className="analyticUnit__arbitration_inner governingPerson__wrap">
                        <Table className="governingPerson__table" borderless>
                            <thead>
                            <tr>
                                <th style={{width: '40%'}}>Госзакупки</th>
                                <th style={{width: '30%'}}>Сумма</th>
                                <th style={{width: '30%'}}>Количество</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">Участник</th>
                                <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                    <InputText
                                        noLabel
                                        noErrorMessage
                                        name="sum"
                                        formError={governmentPurchasesValid.participant}
                                        setFormError={error => governmentPurchasesSetFormError({
                                            error,
                                            type: 'participant'
                                        })}
                                        onChange={e => handleOnChangeGovernmentPurchases({
                                            name: e.target.name,
                                            value: e.target.value.replace(/\D/g, ''),
                                            type: 'participant'
                                        })}
                                        value={government_purchases?.participant?.sum || ''}
                                        resetValidate={!analyticValidStatus}
                                        validator={[{maxLength: 15}, {required: true}]}
                                        inputWithDesc
                                        inputWithDescValue="₽"
                                        containerClassName="addProject__middleInput"
                                        rank={true}
                                    />
                                </td>
                                <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                    <InputText
                                        noLabel
                                        noErrorMessage
                                        name="number"
                                        formError={governmentPurchasesValid.participant}
                                        setFormError={error => governmentPurchasesSetFormError({
                                            error,
                                            type: 'participant'
                                        })}
                                        onChange={e => handleOnChangeGovernmentPurchases({
                                            name: e.target.name,
                                            value: e.target.value.replace(/\D/g, ''),
                                            type: 'participant'
                                        })}
                                        value={government_purchases?.participant?.number || ''}
                                        resetValidate={!analyticValidStatus}
                                        validator={[{maxLength: 15}, {required: true}]}
                                        containerClassName="addProject__middleInput"
                                        rank={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Заключено контрактов</th>
                                <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                    <InputText
                                        noLabel
                                        noErrorMessage
                                        name="sum"
                                        formError={governmentPurchasesValid.contracts}
                                        setFormError={error => governmentPurchasesSetFormError({
                                            error,
                                            type: 'contracts'
                                        })}
                                        onChange={e => handleOnChangeGovernmentPurchases({
                                            name: e.target.name,
                                            value: e.target.value.replace(/\D/g, ''),
                                            type: 'contracts'
                                        })}
                                        value={government_purchases?.contracts?.sum || ''}
                                        resetValidate={!analyticValidStatus}
                                        validator={[{maxLength: 15}, {required: true}]}
                                        inputWithDesc
                                        inputWithDescValue="₽"
                                        containerClassName="addProject__middleInput"
                                        rank={true}
                                    />
                                </td>
                                <td className="verticalAlignInherit pl-0 aboutTariffs__mediumItem">
                                    <InputText
                                        noLabel
                                        noErrorMessage
                                        name="number"
                                        formError={governmentPurchasesValid.contracts}
                                        setFormError={error => governmentPurchasesSetFormError({
                                            error,
                                            type: 'contracts'
                                        })}
                                        onChange={e => handleOnChangeGovernmentPurchases({
                                            name: e.target.name,
                                            value: e.target.value.replace(/\D/g, ''),
                                            type: 'contracts'
                                        })}
                                        value={government_purchases?.contracts?.number || ''}
                                        resetValidate={!analyticValidStatus}
                                        validator={[{maxLength: 15}, {required: true}]}
                                        containerClassName="addProject__middleInput"
                                        rank={true}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnalyticUnit;

export const initState = {
    search: {
        'user.email': '',
        'banned_at': [],
        'bannedBy.user.fio': '',
    },
    searchFields: {
        'user.email': 'like',
        'banned_at': 'between',
        'bannedBy.user.fio': 'like',
    },
    tableHeaderVisible: ['number', 'user.email', 'banned_at', 'bannedBy.user.fio', 'reason'],
    sortedBy: ['asc'],
    orderBy: ['banned_at'],
    currentPage: 1
}

export const tableHeader = [
    {id: 0, name: 'number', title: '№ п/п', cellSize: 'md'},
    {id: 1, name: 'user.email', title: 'Email', filterType: 'searchField', cellSize: 'md'},
    {id: 2, name: 'banned_at', title: 'Дата', filterType: 'date', cellSize: 'md'},
    {id: 3, name: 'bannedBy.user.fio', title: 'Ответственное лицо', filterType: 'searchField', cellSize: 'md'},
    {id: 4, name: 'reason', title: 'Основание', cellSize: 'sm'},
]

import {
    ADD_DEFAULT_PLEDGE,
    EDIT_PLEDGE_CONTRACT_VALUE,
    EDIT_PROJECT_BUSINESSES,
    EDIT_PROJECT_BUSINESSES_OPEN,
    EDIT_PROJECT_COMPANY_ONE_VALUE,
    EDIT_PROJECT_DOCUMENTS,
    EDIT_PROJECT_VALUE,
    PLEDGE_FORM_IS_COMPLETED,
    REMOVE_PLEDGE,
    SET_PLEDGE_TYPE,
    SET_PLEDGE_VALUE,
    SET_PROJECT_EDIT_DATA,
    SET_PROJECT_FORM_ERROR,
    SET_PROJECT_FORM_IS_COMPLETED,
    SET_PROJECT_LIST,
    SET_PROJECT_PAGINATION_DATA,
    SET_PROPERTY_TYPE,
    SET_PROPERTY_VALUE
} from '@redux/actions/projectsActions';
import {getPledgeData, getPropertyData} from "@containers/Projects/AddProject/forms/Pledge/helpers";

const initialState = {
    projectEditData: {},
    projectFormError: [],
    formIsCompleted: {
        attractingInvestments: true,
        addProjectAboutCompany: false,
        relatedCompanies: true,
        aboutProject: false,
        businessModel: false,
        documentsForm: false,
        pledgeForm: {}
    },
    projectList: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PROJECT_LIST:
            return {
                ...state,
                projectList: action.payload
            }
        case SET_PROJECT_PAGINATION_DATA:
            return {
                ...state,
                projectList: {
                    data: [...state.projectList.data, ...action.payload.data],
                    meta: action.payload.meta
                }
            }
        case PLEDGE_FORM_IS_COMPLETED:
            return {
                ...state,
                formIsCompleted: {
                    ...state.formIsCompleted,
                    pledgeForm: {
                        ...state.formIsCompleted.pledgeForm,
                        [action.payload.pledgeId]: action.payload.status
                    }
                }
            }
        case ADD_DEFAULT_PLEDGE:
            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    pledges: [
                        ...state.projectEditData.pledges,
                        action.payload
                    ]
                }
            }
        case REMOVE_PLEDGE:
            const newRemovePledges = state.projectEditData.pledges.filter(item => item.id !== action.payload);
            const newPledgeFormValidate = {...state.formIsCompleted.pledgeForm};
            delete newPledgeFormValidate[action.payload];

            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    pledges: [...newRemovePledges]
                },
                formIsCompleted: {
                    ...state.formIsCompleted,
                    pledgeForm: {
                        ...newPledgeFormValidate
                    }
                }
            }
        case SET_PROPERTY_TYPE:
            const propertyTypeData = getPropertyData(action.payload.value.key);
            const newPropertyData = state.projectEditData.pledges.map(item => {
                if (item.id === action.payload.pledgeId) {
                    return {
                        ...item,
                        property: {...propertyTypeData}
                    }
                }
                return item;
            })

            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    pledges: [...newPropertyData]
                }
            }

        case SET_PROPERTY_VALUE:
            const newPropertyVal = state.projectEditData.pledges.map(item => {
                if (item.id === action.payload.pledgeId) {
                    return {
                        ...item,
                        property: {
                            ...item.property,
                            [action.payload.typeKey]: {
                                ...item.property[action.payload.typeKey],
                                [action.payload.name]: action.payload.value,
                            }
                        }
                    }
                }
                return item;
            })

            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    pledges: [...newPropertyVal]
                }
            }

        case EDIT_PLEDGE_CONTRACT_VALUE:
            const newContractValue = state.projectEditData.pledges.map(item => {
                if (item.id === action.payload.pledgeId) {
                    return {
                        ...item,
                        property: {
                            ...item.property,
                            [action.payload.typeKey]: {
                                ...item.property[action.payload.typeKey],
                                contract: {
                                    ...item.property[action.payload.typeKey].contract,
                                    [action.payload.name]: action.payload.value,
                                }
                            }
                        }
                    }
                }
                return item;
            })

            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    pledges: [...newContractValue]
                }
            }

        case SET_PLEDGE_TYPE:
            const newPledgeFormData = getPledgeData(action.payload.type);
            const newPledgesData = state.projectEditData.pledges.map(item => {
                if (item.id === action.payload.pledgeId) {
                    return {
                        ...item,
                        property_type: action.payload.type,
                        property: {...newPledgeFormData}
                    }
                }
                return item;
            })

            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    pledges: [...newPledgesData]
                }
            }

        case SET_PLEDGE_VALUE:
            const newPledgeValue = state.projectEditData.pledges.map(item => {
                if (item.id === action.payload.pledgeId) {
                    return {
                        ...item,
                        property: {
                            ...item.property,
                            [action.payload.name]: action.payload.value,
                        }
                    }
                }
                return item;
            })

            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    pledges: [...newPledgeValue]
                }
            }

        case SET_PROJECT_EDIT_DATA:
            return {
                ...state,
                projectEditData: action.payload
            }
        case EDIT_PROJECT_VALUE:
            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    [action.payload.dataKey]: {
                        ...state.projectEditData[action.payload.dataKey],
                        [action.payload.name]: action.payload.value
                    }
                }
            }
        case EDIT_PROJECT_BUSINESSES:
            const newBusinesses = [...state.projectEditData.businesses];

            newBusinesses[action.payload.name] = {
                ...newBusinesses[action.payload.name],
                value: action.payload.value
            }

            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    businesses: [...newBusinesses]
                }
            }
        case EDIT_PROJECT_BUSINESSES_OPEN:
            const newBusinessesOpen = [...state.projectEditData.businesses];

            newBusinessesOpen[action.payload] = {
                ...newBusinessesOpen[action.payload],
                open: true
            }

            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    businesses: [...newBusinessesOpen]
                }
            }
        case EDIT_PROJECT_DOCUMENTS:
            const newDocData = state.projectEditData.documents.map(item => {
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        files: action.payload.value,
                    }
                }
                return item;
            })

            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    documents: [...newDocData]
                }
            }
        case EDIT_PROJECT_COMPANY_ONE_VALUE:
            return {
                ...state,
                projectEditData: {
                    ...state.projectEditData,
                    [action.payload.name]: action.payload.value
                }
            }
        case SET_PROJECT_FORM_ERROR:
            return {
                ...state,
                projectFormError: [...action.payload]
            }
        case SET_PROJECT_FORM_IS_COMPLETED:
            return {
                ...state,
                formIsCompleted: {
                    ...state.formIsCompleted,
                    [action.payload.name]: action.payload.value
                }
            }
        default:
            return {...state}
    }
}
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Spinner} from "reactstrap";
import classnames from "classnames";
import {hideModal, showModal} from "@actions/modalActions";
import {ConfirmationsProfilePhoneService} from "@services/confirmations.service";
import RegFormCode from "@shared/components/form/registerForm/RegFormCode";
import {QualificationsSendService} from "@services/qualifications.service";
import {getProfileData} from '@redux/actions/profilesActions';

const QualifiedFormConfirmPhone = ({data}) => {
    const dispatch = useDispatch();
    const {phone} = useSelector(state => state.profiles.profileData);
    const [spinnerStatus, setSpinnerStatus] = useState(false);
    const [phoneConfirmCode, setPhoneConfirmCode] = useState('');
    const [resetValidate, setResetValidate] = useState(false)
    const [phoneConfirmCodeRequired, setPhoneConfirmCodeRequired] = useState(false);
    const [confirmedCode, setConfirmedCode] = useState(false) // Статус подтверждения кода

    const confirmBtnClass = classnames({
        account__btn_primary: true,
        lightDisabledBtn: !phoneConfirmCode || !confirmedCode
    })

    const confirmQualified = () => {
        // Выделить обязательные поля
        !phoneConfirmCode && setResetValidate(!resetValidate);

        if (confirmedCode) {
            setSpinnerStatus(true);

            // Получаем id файлов
            if (data?.value?.conditions?.length) {
                data.value.conditions.forEach(item => {
                    if (item?.items?.length) {
                        item.items.forEach(elem => {
                            if (elem?.files?.length) elem.files = elem.files.map(file => file.id)
                        })
                    }
                })
            }

            const newData = {code: phoneConfirmCode, ...data.value};

            QualificationsSendService.create(newData)
                .then(() => {
                    getProfileData()(dispatch)
                    hideModal()(dispatch)
                })
                .catch(error => console.log(error))
                .finally(() => setSpinnerStatus(false))
        }
    }

    useEffect(() => {
        ConfirmationsProfilePhoneService.create({phone, type: "qualification_request"})
            .catch(error => console.error(error))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div className="qualifiedInvestor__form">
        <p className="modalFullScreen__title">Подача заявления о признании физического лица квалифицированным
            инвестором</p>
        <div className="qualifiedInvestor__confirmPhone">
            <p className="qualifiedInvestor__form_info">
                {`Для подтверждения введите одноразовый пароль, отправленный на номер ${phone}`}
            </p>
            <div className="profileTabs__edit_phone">
                <RegFormCode
                    showIcon
                    label="Код из смс"
                    codeValue={phoneConfirmCode}
                    setCodeValue={setPhoneConfirmCode}
                    parentInput="profile"
                    parentValue={phone}
                    codeRequired={phoneConfirmCodeRequired}
                    setCodeRequired={setPhoneConfirmCodeRequired}
                    handleVisibleValidate={false}
                    hideClearIcon={true}
                    className="profileTabs__phone_code"
                    handleConfirmedCode={() => setConfirmedCode(!confirmedCode)}
                    resetValidate={resetValidate}
                    setResetValidate={setResetValidate}
                />
            </div>
            <div className="qualifiedInvestor__form_btn withdrawFunds__btnGroup">
                <Button
                    onClick={() => showModal()(dispatch)}
                    color="primary"
                    className="account__btn_cancel"
                    outline
                >
                    Отменить
                </Button>
                <Button
                    color="primary"
                    className={confirmBtnClass}
                    onClick={confirmQualified}
                >
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                        Подтвердить
                    </div>
                </Button>
            </div>
        </div>
    </div>
}

export default QualifiedFormConfirmPhone;
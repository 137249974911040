import {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {checkForAccreditation, setProfileDataEditingStatus} from "@actions/profilesActions";
import {getRole} from "@utils/auth";
import {accStatusKey} from "@utils/statusKey";

export const usePresenter = (profileData = {}) => {
    const dispatch = useDispatch();
    const role_key = getRole();
    const setFormEditStatus = setProfileDataEditingStatus(dispatch);
    const {legal_form_type = '', ban = {}, accreditation_status_key, isBanned} = profileData;

    // Проверить заполнены ли все формы для активации кнопки отправки профиля на аккредитацию
    useEffect(() => {
        if ([accStatusKey.returned, accStatusKey.notAccredited].includes(accreditation_status_key)) {
            checkForAccreditation(dispatch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accreditation_status_key, profileData])

    return {
        setFormEditStatus,
        role_key,
        legal_form_type,
        accreditation_status_key,
        isBanned,
        ban_comment: ban?.ban_comment
    }
}
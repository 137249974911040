import React from "react";
import Ownership from "./forms/Property/Contracts/Ownership";
import Lease from "./forms/Property/Contracts/Lease";
import Uses from "./forms/Property/Contracts/Uses";
import {
    ddu,
    movable,
    other,
    propertyApartment,
    propertyBuilding,
    propertyHouse,
    propertyLand,
    propertyPremise
} from './data';

export const getPledgeData = type => {
    switch (type) {
        case "ddu":
            return ddu;
        case "movable":
            return movable;
        case "other":
            return other;
        case "property":
            return propertyApartment;
        default: return ddu
    }
}

export const getPropertyData = type => {
    switch (type) {
        case "apartment":
            return propertyApartment;
        case "house":
            return propertyHouse;
        case "land":
            return propertyLand;
        case "premise":
            return propertyPremise;
        case "building":
            return propertyBuilding;
        default: return propertyApartment
    }
}

export const getPropertyContract = (type, data, formError, setFormError, pledgeId, typeKey, projectId) => {
    switch (type) {
        case "ownership":
            return <Ownership
                data={data}
                formError={formError}
                setFormError={setFormError}
                pledgeId={pledgeId}
                typeKey={typeKey}
                projectId={projectId}
            />;
        case "lease":
            return <Lease
                data={data}
                formError={formError}
                setFormError={setFormError}
                pledgeId={pledgeId}
                typeKey={typeKey}
                projectId={projectId}
            />;
        case "uses":
            return <Uses
                data={data}
                formError={formError}
                setFormError={setFormError}
                pledgeId={pledgeId}
                typeKey={typeKey}
                projectId={projectId}
            />;
        default:
            return null
    }
}
import React, {useState} from 'react';
import cn from 'classnames';
import classnames from 'classnames';
import DataCard from "@shared/components/data/DataCard";
import IconBtn from "@shared/components/form/IconBtn";
import InputText from "@shared/components/form/profileForm/InputText";
import {useSelector} from "react-redux";
import {dateOptions} from "@shared/components/account/helpers";
import CommentHistory from "@shared/components/data/CommentHistory";

// TODO переделать под Иная информация для зявления квал

const OtherInformation = ({remarkList, setRemarkList, moderationHistory = [], noFormatting, className}) => {
    const userName = useSelector(state => state.user.user.fio);
    const [inputVisible, setInputVisible] = useState(false);
    const [addInfoMessage, setAddInfoMessage] = useState('');
    const moderationHistoryOtherInfo = moderationHistory
        .map(item => ({...item, remarks: item.remarks.filter(elem => elem.type_key === null)}))

    const dataFormat = remarkList.filter(remark => remark.type_key === null).map(item => {
        const {created_at, profile, message} = item;

        return {name: `${created_at}, ${profile.user.fio}`, desc: message}
    })

    const addInfo = () => {
        if (!inputVisible) setInputVisible(true)
    }

    const saveMessage = () => {
        if (addInfoMessage.length) {
            const copyRemarks = JSON.parse(JSON.stringify(remarkList));
            copyRemarks.push({
                type_key: null,
                created_at: new Date().toLocaleString("ru", dateOptions),
                profile: {user: {fio: userName}},
                message: addInfoMessage
            })

            setRemarkList(copyRemarks)
            setAddInfoMessage('')
            setInputVisible(false)
        }
    }

    const handleOnClickCancel = () => {
        setAddInfoMessage('')
        setInputVisible(false)
    }

    return (
        <div className={cn({moderationProject__otherInfo: !noFormatting, [className]: className})}>
            {
                moderationHistoryOtherInfo?.length && moderationHistoryOtherInfo.some(item => item.remarks.length)
                    ? <>
                        <CommentHistory
                            withoutFormatting
                            data={moderationHistoryOtherInfo}
                            title="Иные сведения"
                        />
                        <DataCard data={dataFormat} className="moderationProject__otherInfo_dataCard"/>
                    </>
                    : <DataCard
                        data={dataFormat}
                        title="Иные сведения"
                        className="moderationProject__otherInfo_dataCard"
                    />
            }
            <IconBtn
                title="Добавить сведения"
                onClick={addInfo}
                className={classnames({projectGoverning__addBtn: true, disabled: inputVisible})}
            />
            {
                inputVisible
                    ? <div className="moderationProject__otherInfo_form">
                        <div className="moderationProject__otherInfo_formDesc">
                            <span>Иные сведения</span>
                            <div>
                                <button onClick={handleOnClickCancel}>
                                    Отменить
                                </button>
                                <button
                                    onClick={saveMessage}
                                    disabled={!addInfoMessage.length}
                                >
                                    Сохранить
                                </button>
                            </div>
                        </div>
                        <InputText
                            noLabel
                            rows={2}
                            type="textarea"
                            onChange={e => setAddInfoMessage(e.target.value)}
                            value={addInfoMessage || ''}
                            containerClassName="addProject__fullInput"
                        />
                    </div>
                    : null
            }
        </div>
    )
}

export default OtherInformation;

export const initState = {
    search: {
        'id': '',
        'fio': '',
        'email': '',
        'role.name': [],
        'created_at': [],
    },
    searchFields: {
        'id': 'like',
        'fio': 'like',
        'email': 'like',
        'role.name': 'in',
        'created_at': 'between',
    },
    tableHeaderVisible: ['number', 'id', 'fio', 'email', 'role.name', 'created_at', 'moreDetails'],
    sortedBy: ['desc'],
    orderBy: ['created_at'],
    currentPage: 1
}

export const tableHeader = [
    {id: 0, name: 'number', title: '№ п/п', cellSize: 'sm'},
    {id: 1, name: 'id', title: 'ID профиля', filterType: 'searchField', cellSize: 'sm'},
    {id: 2, name: 'fio', title: 'ФИО', filterType: 'searchField', cellSize: 'sm'},
    {id: 3, name: 'email', title: 'Email', filterType: 'searchField', cellSize: 'sm'},
    {
        id: 4, name: 'role.name', title: 'Роль', cellSize: 'sm', filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'admin', label: 'Администратор'},
            {id: 2, name: 'moderator', label: 'Модератор'},
        ],
    },
    {id: 5, name: 'created_at', title: 'Дата регистрации', filterType: 'date', cellSize: 'sm'},
    {id: 6, name: 'moreDetails', title: '', cellSize: 'sm', noExport: true},
]

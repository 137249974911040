import React from 'react';
import UploadField from "@shared/components/uploadField";

const PepSignatureFile = () => {
    return (
        <div className='cepSignatureFile'>
            <span className='fontSizeSubtitle cepSignatureFile__title'>Сведения элетронной подписи</span>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Тип подписи: </span>
                <span className='cepSignatureFile__desc_info'>Простая электронная подпись</span>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Номер телефона: </span>
                <span className='cepSignatureFile__desc_info'>+7 (999) 222-22-22</span>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>СМС-код:</span>
                <span className='cepSignatureFile__desc_info'> 1234567</span>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Дата и время: </span>
                <span className='cepSignatureFile__desc_info'>22.12.21, 08:40</span>
            </div>
            <div className='cepSignatureFile__document'>
                <span className='fontSizeSubtitle cepSignatureFile__title'>Подписанный документ</span>
                <div className='detailedCalculations__document'>
                    {/*files={[data.company.logo]}*/}
                    <UploadField viewDetail edit={false}/>
                </div>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Публичный ключ:</span>
                <span className='cepSignatureFile__desc_info'> 124567</span>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Подпись: </span>
                <span className='cepSignatureFile__desc_info'>1247959499494949567</span>
            </div>
        </div>
    )
};

export default PepSignatureFile;
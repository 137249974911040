import MuseoSansCyrl300 from "@fonts/MuseoSansCyrl-300.otf";
import MuseoSansCyrl500 from "@fonts/MuseoSansCyrl-500.otf";
import MuseoSansCyrl700 from "@fonts/MuseoSansCyrl-700.otf";

export const fonts = {
    family: 'MuseoSansCyrl', fonts: [
        {src: MuseoSansCyrl300, fontWeight: 'normal'},
        {src: MuseoSansCyrl500, fontWeight: 500},
        {src: MuseoSansCyrl700, fontWeight: 700},
    ]
}

export const requisitesStyles = {
    page: {
        padding: "45px 44px",
        color: '#000000',
        fontFamily: 'MuseoSansCyrl',
    },
    image: {
        width: '100%',
        maxWidth: 194
    },
    QRCodeImage: {
        width: '100%',
        maxWidth: 95,
    },
    QRCode: {
        width: 95,
        height: 95,
        marginTop: 25
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        marginTop: 52
    },
    subtitle: {
        fontWeight: 500,
        fontSize: 16,
        marginTop: 27
    },
    list: {
        marginTop: 11,
    },
    listGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 9,
        maxWidth: 420,
        width: '100%',
    },
    listTitle: {
        fontSize: 9,
        fontWeight: 700,
        width: '100%',
        maxWidth: 175
    },
    listDesc: {
        fontSize: 9,
        textAlign: 'left',
    },
    blueColor: {
        textAlign: 'left',
        fontSize: 9,
        color: '#4772CF',
        maxWidth: 242,
        width: '100%',
    },
}
import React from 'react';
import AnnuitySchedule from './AnnuitySchedule';
import DeferredAnnuitySchedule from './DeferredAnnuitySchedule';
import EndTermSchedule from "./EndTermSchedule";
import FixedAmountSchedule from "./FixedAmountSchedule";

const PaymentScheduleModal = () => {
    return (
        <div className="paymentScheduleModal__wrap">
            <p className="modalFullScreen__title">
                Подробнее о типах выплат
            </p>
            <div className="paymentScheduleModal__content">
                <AnnuitySchedule/>
                <DeferredAnnuitySchedule/>
                <EndTermSchedule/>
                <FixedAmountSchedule/>
            </div>
        </div>
    )
}

export default PaymentScheduleModal;
export const initState = {
    search: {
        'created_at': [],
        'type_key': [],
    },
    searchFields: {
        'created_at': 'between',
        'type_key': 'in',
    },
    tableHeaderVisible: ['created_at', 'type_key'],
    sortedBy: ['desc'],
    orderBy: ['created_at'],
    currentPage: 1
}

export const logType = {
    accreditation: {key: 'accreditation', desc: 'Аккредитация'},
    qualification: {key: 'qualification', desc: 'Квалификация'},
    moderation: {key: 'moderation', desc: 'Модерация проектов'},
    confirmation: {key: 'confirmation', desc: 'Подтверждение'},
    profile: {key: 'profile,ban_profile', desc: 'Действия с профилем'},
    checking_profiles: {key: 'profile', desc: 'Проверка анкет ПОД\\ФТ\\ФРОМУ'},
    bankDetail: {key: 'bankDetail', desc: 'Банковские реквизиты'},
    user: {key: 'user,ban_user', desc: 'Действия с аккаунтом'},
    document: {key: 'document', desc: 'Файл'},
    project: {key: 'project', desc: 'Проект'},
}

const optionFilter = {
    moderation: [
        {id: 0, name: 'all', label: 'Все'},
        {id: 1, name: logType["accreditation"].key, label: logType["accreditation"].desc},
        {id: 2, name: logType["qualification"].key, label: logType["qualification"].desc},
        {id: 3, name: logType["moderation"].key, label: logType["moderation"].desc},
    ],
    admin: [
        {id: 0, name: 'all', label: 'Все'},
        {id: 1, name: logType["accreditation"].key, label: logType["accreditation"].desc},
        {id: 2, name: logType["qualification"].key, label: logType["qualification"].desc},
        {id: 3, name: logType["moderation"].key, label: logType["moderation"].desc},
        {id: 4, name: logType["checking_profiles"].key, label: logType["checking_profiles"].desc},
        {id: 5, name: logType["user"].key, label: logType["user"].desc},
        {id: 6, name: logType["profile"].key, label: logType["profile"].desc},
    ],
    sdl: [
        {id: 0, name: 'all', label: 'Все'},
        {id: 1, name: logType["checking_profiles"].key, label: logType["checking_profiles"].desc},
        {id: 5, name: logType["user"].key, label: logType["user"].desc},
    ],
}

export const getTableHeader = role => {
    if (!role) return []

    return [
        {id: 0, name: 'created_at', title: 'Дата', filterType: 'date', cellSize: 'sm'},
        {
            id: 1,
            name: 'type_key',
            title: 'Операция',
            cellSize: 'lg',
            filterType: 'checkbox',
            filterData: optionFilter[role],
        },
    ]
}
import React from 'react';
import {Table} from "reactstrap";
import classnames from "classnames";
import CheckDataToDisplay from "@shared/components/presentation/CheckDataToDisplay";
import BaseTableHeader from "./BaseTableHeader";
import BaseTableBody from "@shared/components/table/BaseTable/BaseTableBody";
import Pagination from "@shared/components/pagination";

const BaseTable = ({
                       title, tableHeader = [], tableBody = [], loading, setPage = 1, meta = {}
                   }) => {
    const classes = classnames({
        baseTable: true,
        baseTable__load: loading,
    })

    return (
        <div className={classes}>
            <CheckDataToDisplay data={title}>
                <p className="adminSection__confirmCodes_title">{title}</p>
            </CheckDataToDisplay>
            <Table className="table--bordered" responsive>
                <BaseTableHeader data={tableHeader}/>
                <BaseTableBody data={tableBody}/>
            </Table>
            {
                !tableBody.length
                    ? <div className="baseTable__messageNoData">
                        <p>Файлы отсутствуют</p>
                    </div>
                    : null
            }
            <Pagination
                className="pagination-bar"
                meta={meta}
                onPageChange={page => setPage(page)}
            />
            <CheckDataToDisplay data={loading}>
                <div className="baseTable__loader">
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                        </svg>
                    </div>
                </div>
            </CheckDataToDisplay>
        </div>
    )
};

export default BaseTable;
import React, {useReducer} from 'react';
import {Table} from "reactstrap";
import {initState, platformRatesReducer} from "./helpers";
import Editing from "./Editing";
import PlatformRatesTariff from "./PlatformRatesTariff";

const PlatformRatesForm = ({data}) => {
    const [state, dispatch] = useReducer(platformRatesReducer, data || {}, initState);

    const CheckEditStatus = ({children, fieldKey, fieldKeyTo}) => {
        return !state.edit.includes(fieldKey) && !state.edit.includes(fieldKeyTo)
            ? children
            : null
    }

    return (
        <div className="aboutTariffs__table platformRates">
            <Table className="table--bordered" responsive>
                <thead>
                <tr className="aboutTariffs__table_header">
                    <th style={{width: '30%'}} className="pl-0">
                        <span className="aboutTariffs__table_title">Наименование услуги</span>
                    </th>
                    <th style={{width: '70%'}} className="aboutTariffs__largeItem pl-0">
                        <span className="aboutTariffs__table_title">Размер вознаграждения</span>
                        <span
                            className="aboutTariffs__table_subtitle greyColor">% от суммы привлеченных инвестиций</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="pl-0">
                    <span className="aboutTariffs__item_title purpleColor aboutTariffs__services_title">
                      Услуги Оператора по предоставлению доступа к сервисам инвестиционной платформы INCROWD и
                      привлечению инвестиций (размещение инвестиционного предложения, привлечение инвестиций,
                      заключение и обслуживание договора инвестирования)
                    </span>
                    </td>
                    <td className="aboutTariffs__item_content aboutTariffs__vnTable aboutTariffs__largeItem pl-0">
                        <Table className="aboutTariffs__vnTable" responsive>
                            <thead>
                            <tr>
                                <th style={{width: '37%'}} className="pt-0 pl-0">
                                        <span className="aboutTariffs__vnTable_title">
                                          Заключение договора инвестирования в рамках размещенного:
                                        </span>
                                </th>
                                <th style={{width: '63%'}} className="pt-0 pl-0">
                                        <span className="aboutTariffs__vnTable_title">
                                          Срок действия инвестиционного предложения, указанный инициатором (календарные дни):
                                        </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="aboutTariffs__vnTable_content purpleColor">
                            <tr>
                                <td/>
                                <td>
                                    <div className="aboutTariffs__vnTable_wrap">
                                            <span className="aboutTariffs__smTitle">
                                                <CheckEditStatus fieldKey="accessFirstColumnFrom"
                                                                 fieldKeyTo="accessFirstColumnTo">
                                                    {`от ${state.data.accessFirstColumnFrom} до ${state.data.accessFirstColumnTo}`}
                                                </CheckEditStatus>
                                                <Editing
                                                    fieldKey="accessFirstColumnFrom"
                                                    fieldKeyTo="accessFirstColumnTo"
                                                    dispatch={dispatch}
                                                    state={state}
                                                    multiple
                                                />
                                            </span>
                                        <span className="aboutTariffs__smTitle">
                                                <CheckEditStatus fieldKey="accessSecondColumnFrom"
                                                                 filedKeyTo="accessSecondColumnTo">
                                                    {`от ${state.data.accessSecondColumnFrom} до ${state.data.accessSecondColumnTo}`}
                                                </CheckEditStatus>
                                                <Editing
                                                    fieldKey="accessSecondColumnFrom"
                                                    fieldKeyTo="accessSecondColumnTo"
                                                    dispatch={dispatch}
                                                    state={state}
                                                    multiple
                                                />
                                            </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="pl-0">1-ого инвестиционного предложения</td>
                                <td className="pl-0">
                                    <div className="aboutTariffs__vnTable_wrap">
                                        <span>
                                            <CheckEditStatus fieldKey="accessFirstColumnPercentOne">
                                                {`${state.data.accessFirstColumnPercentOne}%`}
                                            </CheckEditStatus>
                                            <Editing
                                                fieldKey="accessFirstColumnPercentOne"
                                                dispatch={dispatch}
                                                state={state}
                                            />
                                        </span>
                                        <span>
                                            <CheckEditStatus fieldKey="accessSecondColumnPercentOne">
                                                {`${state.data.accessSecondColumnPercentOne}%`}
                                            </CheckEditStatus>
                                            <Editing
                                                fieldKey="accessSecondColumnPercentOne"
                                                dispatch={dispatch}
                                                state={state}
                                            />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="pl-0">2-ого инвестиционного предложения</td>
                                <td className="pl-0">
                                    <div className="aboutTariffs__vnTable_wrap">
                                        <span>
                                            <CheckEditStatus fieldKey="accessFirstColumnPercentTwo">
                                                {`${state.data.accessFirstColumnPercentTwo}%`}
                                            </CheckEditStatus>
                                            <Editing
                                                fieldKey="accessFirstColumnPercentTwo"
                                                dispatch={dispatch}
                                                state={state}
                                            />
                                        </span>
                                        <span>
                                            <CheckEditStatus fieldKey="accessSecondColumnPercentTwo">
                                                {`${state.data.accessSecondColumnPercentTwo}%`}
                                            </CheckEditStatus>
                                            <Editing
                                                fieldKey="accessSecondColumnPercentTwo"
                                                dispatch={dispatch}
                                                state={state}
                                            />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="pl-0">3-его инвестиционного предложения</td>
                                <td className="pl-0">
                                    <div className="aboutTariffs__vnTable_wrap">
                                        <span>
                                            <CheckEditStatus fieldKey="accessFirstColumnPercentThree">
                                                {`${state.data.accessFirstColumnPercentThree}%`}
                                            </CheckEditStatus>
                                            <Editing
                                                fieldKey="accessFirstColumnPercentThree"
                                                dispatch={dispatch}
                                                state={state}
                                            />
                                        </span>
                                        <span>
                                            <CheckEditStatus fieldKey="accessSecondColumnPercentThree">
                                                {`${state.data.accessSecondColumnPercentThree}%`}
                                            </CheckEditStatus>
                                            <Editing
                                                fieldKey="accessSecondColumnPercentThree"
                                                dispatch={dispatch}
                                                state={state}
                                            />
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
                <tr>
                    <td className="pl-0 pt-4">
                    <span className="aboutTariffs__item_title purpleColor">
                        Услуги Оператора по сопровождению
                        государственной регистрации залога
                        движимого/недвижимого имущества
                    </span>
                    </td>
                    <td className="aboutTariffs__largeItem pt-4">
                    <span className="aboutTariffs__vnTable_title purpleColor">
                        Единовременная оплата без учета государственной пошлины, услуги нотариуса)
                        <span className="platformRates__supportField">
                            <CheckEditStatus fieldKey="support">
                                {` ${state.data.support.toLocaleString()} ₽ / 1 объект залога`}
                            </CheckEditStatus>
                            <Editing
                                fieldKey="support"
                                dispatch={dispatch}
                                state={state}
                            />
                        </span>
                    </span>
                    </td>
                </tr>
                </tbody>
            </Table>
            <PlatformRatesTariff/>
        </div>
    )
}

export default PlatformRatesForm;
export const accBorrowerAndInvestorTableHeader = [
    {id: 1, title: 'ФИО/Наименование'},
    {id: 2, title: 'Правовая форма'},
    {id: 3, title: 'Id профиля'},
    {id: 4, title: 'Email'},
    {id: 5, sorted: true, title: 'Дата отправки на аккредитацию'},
    {id: 6, title: ''},
];

export const confirmInvestorTableHeader = [
    {id: 1, title: 'ФИО'},
    {id: 3, title: 'Id профиля'},
    {id: 4, title: 'Email'},
    {id: 5, sorted: true, title: 'Дата подачи заявления'},
    {id: 6, title: ''},
];

export const controlTabsData = [
    {key: 'investor', desc: 'Аккредитация инвесторов'},
    {key: 'borrower', desc: 'Аккредитация заемщиков'},
    {key: 'qualificationsInvestor', desc: 'Подтверждение квалификации инвестора'}
]
import React, {useState} from "react";
import {getFullProjectList} from "@actions/projectsActions";
import {projectListScroll} from "@containers/Projects/ProjectsFeed/helpers";
import {Button, Spinner} from "reactstrap";
import useWindowSize from "@hooks/useWindowSize";
import {useDispatch} from "react-redux";

const BtnShowMore = ({meta}) => {
    const dispatch = useDispatch();
    const [width = 1800] = useWindowSize();
    const [showMoreStatus, setShowMoreStatus] = useState(false);

    if (meta?.pagination) {
        const {current_page, total_pages} = meta.pagination;


        const handleOnChange = () => {
            setShowMoreStatus(true)
            getFullProjectList(current_page + 1)(dispatch)
                .then(res => {
                    // После получения данных прокрутить вниз
                    if (res?.data?.data && res.data.data.length) window.scrollTo({
                        top: projectListScroll(width, window.scrollY),
                        behavior: "smooth"
                    })
                })
                .finally(() => setShowMoreStatus(false))
        }

        if (current_page < total_pages) {
            return (
                <div className="projectsFeed__showMore">
                    <Button
                        color="success"
                        className="account__btn_success"
                        onClick={handleOnChange}
                        outline
                    >
                        <div className="btn-spinner-group">
                <span>
                  <Spinner className={`btn-spinner ${showMoreStatus ? 'btn-spinner-visible' : ''}`}/>
                  Показать еще
                </span>
                        </div>
                    </Button>
                </div>
            )
        }
    }

    return null;
}

export default BtnShowMore;
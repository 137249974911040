import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {leastOneNumber, lowercaseLetter, uppercaseLetter} from "@shared/components/form/validate";

const RegisterPasswordValidateInfo = ({value, visibleStatus, fieldFocused, dispatch}) => {
    const [oneNumberValid, setOneNumberValid] = useState(false) // Одна цифра
    const [lowercaseSymbol, setLowercaseSymbol] = useState(false) // Одна строчная буква
    const [uppercaseSymbol, setUppercaseSymbol] = useState(false) // Одна прописная буква
    const [lengthValid, setLengthValid] = useState(false) // Не менее 8 символов

    const validateInfoClass = cn({
        'form__pass-validate': true,
        'form__confirm-code': true,
        'fShow': visibleStatus || fieldFocused || value.length
    })

    const validate = value => {
        setLengthValid(value.length >= 8)
        setOneNumberValid(leastOneNumber.test(value))
        setLowercaseSymbol(lowercaseLetter.test(value))
        setUppercaseSymbol(uppercaseLetter.test(value))
    }

    useEffect(() => {
        if (fieldFocused || value.length) validate(value)
    }, [fieldFocused, value])

    useEffect(() => {
        dispatch({
            name: 'successfulPassword',
            value: oneNumberValid && lowercaseSymbol && uppercaseSymbol && lengthValid
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneNumberValid, lowercaseSymbol, uppercaseSymbol, lengthValid])

    return (
        <div className={validateInfoClass}>
            <span className={cn({'error': !uppercaseSymbol})}>одна прописная буква</span>
            <span className={cn({'error': !lowercaseSymbol})}>одна строчная буква</span>
            <span className={cn({'error': !lengthValid})}>не менее 8 символов</span>
            <span className={cn({'error': !oneNumberValid})}>одна цифра</span>
        </div>
    )
};

export default RegisterPasswordValidateInfo;

import React from 'react';
import {Button} from "reactstrap";
import {useDispatch} from "react-redux";
import {showModal} from "@actions/modalActions";
import {PAYMENT_DETAILS} from "@shared/components/modal/constants";

const LoanOptionsButtons = () => {
    const dispatch = useDispatch();

    const handleOnClick = type => {
        showModal(PAYMENT_DETAILS, type)(dispatch)
    }

    return (
        <div className='loanOptionsEditButton'>
            <Button
                className='loanOptionsEditButton__button'
                color="primary"
                size="lg"
                onClick={() => handleOnClick('calculator')}
            >
                Калькулятор досрочного погашения
            </Button>
            <Button
                className='loanOptionsEditButton__button'
                color="success"
                size="lg"
                outline
                onClick={() => handleOnClick('requisites')}
            >
                Реквизиты для платежей
            </Button>
        </div>
    )
};

export default LoanOptionsButtons;
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Button, Collapse} from 'reactstrap';
import {AuthService} from "@services/auth.service";
import {RightArrowIcon, SettingsIcon, UserIcon} from "@shared/img/iconSvg";
import {addEllipsis} from '@utils/text';
import classnames from "classnames";
import {useHistory} from "react-router-dom";
import {showModal} from "@actions/modalActions";
import {MODAL_WITH_SETTING} from "@shared/components/modal/constants";

const TopbarProfile = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [collapse, setCollapse] = useState(false);
    const userEmail = useSelector(state => state.user.user.email);

    const toggle = () => setCollapse(!collapse);

    const openModalWithSetting = () => {
        setTimeout(toggle, 500)
        showModal(MODAL_WITH_SETTING)(dispatch)
    }

    return (
        <div className="topbar__profile">
            <div className={`topbar__profile_inner ${collapse ? 'active' : ''}`} onClick={toggle}>
                <div className="topbar__profile_mobile">
                    <UserIcon/>
                </div>
                <span className="topbar__profile_name">{addEllipsis(userEmail) || ''}</span>
                <div className="topbar__icon">
                    <RightArrowIcon className={classnames({active: collapse})}/>
                </div>
            </div>
            {
                collapse
                    ? <button type="button" className="topbar__back" onClick={toggle}/>
                    : null
            }
            <Collapse isOpen={collapse} className="topbar__menu-wrap">
                <div className="topbar__menu">
                    <div className="topbar__menu_info">
                        <span>{addEllipsis(userEmail) || ''}</span>
                        <SettingsIcon onClick={openModalWithSetting}/>
                    </div>
                    <div className="topbar__menu_controls">
                        <Button color="secondary" size="sm" onClick={AuthService.redirectToLogin}>
                            Выйти
                        </Button>
                        <Button color="success" size="sm" onClick={() => history.push('/account')}>
                            Профиль
                        </Button>
                    </div>
                </div>
            </Collapse>
        </div>
    );
}

export default TopbarProfile;

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Spinner} from "reactstrap";
import {updateUserAvatar} from '@redux/actions/userActions';
import {b64toBlob} from '@utils/files';

const CropImage = ({image, hideAllModals, hideModal}) => {
    const dispatch = useDispatch();
    const editProfileId = useSelector(state => state.profiles.editProfileId);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({unit: '%', width: 50, aspect: 1,});
    const [spinnerStatus, setSpinnerStatus] = useState(false);
    const [completedCrop, setCompletedCrop] = useState(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    const saveImage = () => {
        setSpinnerStatus(true)
        const ImageURL = previewCanvasRef.current.toDataURL("image/jpeg");
        const block = ImageURL.split(";");
        const contentType = block[0].split(":")[1];
        const realData = block[1].split(",")[1];
        const blob = b64toBlob(realData, contentType);
        const form = new FormData();
        form.append("avatar", blob, 'avatar.jpg');

        updateUserAvatar(form, editProfileId)(dispatch).finally(() => {
            setSpinnerStatus(false)
            hideAllModals()
        })

    }

    const cancelCropping = () => {
        hideModal()
    }

    const imageLoadError = error => {
        console.log(error)
    }

    return (
        <div className="selfie">
            <p className="modalRoot__title">Выберите область фото для сохранения</p>
            <div className="reactCrop__container">
                <ReactCrop
                    src={image}
                    crop={crop}
                    minWidth={150}
                    maxWidth={500}
                    keepSelection={true}
                    onChange={setCrop}
                    onImageLoaded={onLoad}
                    onComplete={setCompletedCrop}
                    onImageError={imageLoadError}
                    className="cropImage__content"
                    imageAlt="avatar"
                />
            </div>
            <div>
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0),
                        display: 'none'
                    }}
                />
            </div>
            <div className="webCam__btn_group">
                <Button
                    color="primary"
                    className={`account__btn account__btn_primary webCam__btn ${!completedCrop?.width || !completedCrop?.height ? 'disabled' : ''}`}
                    onClick={saveImage}
                    disabled={!completedCrop?.width || !completedCrop?.height}
                >
                    <div className="webCam__btn_spinner">
                        <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                        Сохранить
                    </div>
                </Button>
                <Button
                    color="primary"
                    className='account__btn account__btn_cancel webCam__btn'
                    onClick={cancelCropping}
                    outline
                >
                    Отмена
                </Button>
            </div>
        </div>
    );
}
export default CropImage;
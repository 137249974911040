import React from 'react';
import cn from "classnames";
import UploadField from "@shared/components/uploadField";
import PrimaryTooltip from "@shared/components/tooltip";
import {NavLink} from "react-router-dom";

const DataCard = ({data = [], title, fRef = null, className}) => {

    const renderName = (item, idx) => {
        if (item.tooltip) {
            return (
                <span className="dataCard__name">
                    <span className="position-relative">
                        {item.name}
                        <PrimaryTooltip name={`dataCard${idx}`} title="?" className="profileTabs__tabItem_tooltip">
                          <p>{item.tooltip}</p>
                        </PrimaryTooltip>
                    </span>
                </span>
            )
        }

        return <span className="dataCard__name">{item.name}</span>
    }

    const CardList = () => {
        return data && data.length
            ? data.map((item, idx) => {
                const {type, name, desc, className, link} = item;

                switch (type) {
                    case 'subtitle':
                        return <p key={idx} className="dataCard__subtitle">{name}</p>
                    case 'file':
                        return (
                            <div key={idx} className="dataCard__item">
                                {renderName(item, idx)}
                                <span className={cn('dataCard__desc', {error: !desc.length, [className]: className})}>
                                    {desc.length ? <UploadField viewDetail files={desc} edit={false}/> : 'Не заполнено'}
                                </span>
                            </div>
                        )
                    case 'link':
                        return (
                            <div key={idx} className="dataCard__item">
                                {renderName(item, idx)}
                                <span className={cn('dataCard__desc', {error: !desc, [className]: className})}>
                                    {
                                        desc
                                            ? <NavLink to={link} className="green-color">{desc}</NavLink>
                                            : 'Не заполнено'
                                    }
                                </span>
                            </div>
                        )
                    default:
                        return (
                            <div key={idx} className="dataCard__item">
                                {renderName(item, idx)}
                                <span className={cn('dataCard__desc', {error: !desc, [className]: className})}>
                                    {desc || 'Не заполнено'}
                                </span>
                            </div>
                        )
                }
            })
            : null
    }

    return <div className={cn({dataCard: true, [className]: className})} ref={fRef}>
        {
            title
                ? <p className="dataCard__title">{title}</p>
                : null
        }
        <div className="dataCard__inner">
            <CardList/>
        </div>
    </div>
}

export default DataCard;

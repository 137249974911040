import React, {memo, useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {Col, Row} from "reactstrap";
import {CalendarIcon} from "@shared/img/iconSvg";
import {eighteenYearsAgo, innMask, innMaskEntity, ogrnipMask, snilsMask} from "@shared/components/account/helpers";
import RefFormPhone from "@shared/components/form/registerForm/phoneConfirmForm/RegFormPhone";
import RegFormCode from "@shared/components/form/registerForm/RegFormCode";
import {stCount} from "@utils/form";
import {SET_ERROR, SET_PERSONAL} from "@shared/components/account/EditProfile/helpers/constants";

const PersonalData = ({data, resetValidate, formError, dispatch, errorDispatch, user, legal_form_type}) => {
    const [confirmCodeVisible, setConfirmCodeVisible] = useState(false);
    const [fioResetValidate, setFioResetValidate] = useState(false);
    const [confirmedCode, setConfirmedCode] = useState(false);
    const [codeValue, setCodeValue] = useState('');
    const [residenceAddressResetValidate, setResidenceAddressResetValidate] = useState(false);
    const {
        fio, without_patronymic, birth_date, phone, phone_code, inn, birth_place,
        registration_address, position, ogrnip, snils, residence_address, address_matches
    } = data;

    const handleOnChange = ({target}) => {
        dispatch({
            type: SET_PERSONAL,
            payload: {
                name: target.name,
                value: target.value,
            }
        })
    }

    const setFormError = (error) => {
        errorDispatch({
            type: SET_ERROR,
            payload: {
                name: 'personal',
                value: error,
            }
        })
    }

    const noMiddleNameOnChange = (event) => {
        handleOnChange(event)
        // Обрезать fio до двух слов
        const fioTrim = fio.replace(/\s+/g, ' ').trim();
        if (stCount(fioTrim) > 2 && !without_patronymic) {
            handleOnChange({
                target: {
                    name: 'fio',
                    value: fioTrim.split(" ").slice(0, -(stCount(fioTrim) - 2)).join(" "),
                }
            })
        }

        setFioResetValidate(!fioResetValidate)
    }

    const addressMatchesOnChange = (event) => {
        handleOnChange(event);
        handleOnChange({
            target: {
                name: 'residence_address',
                value: registration_address,
            }
        });

        setResidenceAddressResetValidate(!residenceAddressResetValidate)
    }

    // Показать || скрыть поле кода
    const handleVisibleCode = status => {
        setConfirmCodeVisible(status)
    }

    return (
        <div className="profileTabs__tabItem accountPersonalData">
            <div className="profileTabs__tabItem_title">
                <span>Персональные данные</span>
            </div>
            <div className="profileTabs__tabItem_list">
                <div className="profileTabs__edit">
                    <InputText
                        id="fio"
                        name="fio"
                        label="ФИО"
                        type="text"
                        value={fio}
                        onChange={handleOnChange}
                        validator={[
                            {required: true},
                            {maxWords: without_patronymic ? 2 : 4},
                            {minWords: without_patronymic ? 2 : 3},
                            {onlyCyrillic: true}
                        ]}
                        containerClassName="fio__container"
                        resetValidate={fioResetValidate}
                        setResetValidate={setFioResetValidate}
                        formError={formError}
                        setFormError={setFormError}
                        checkAfterFocus={false}
                    />
                    <InputText
                        type="checkbox"
                        label="Нет отчества"
                        id="without_patronymic"
                        className='noMiddleName'
                        name="without_patronymic"
                        onChange={noMiddleNameOnChange}
                        resetValidate={resetValidate}
                        value={without_patronymic}
                        containerClassName="noMiddleName__container"
                        checkAfterFocus={false}
                    />
                    <Row>
                        <Col md={6}>
                            <InputText
                                type="date"
                                name="birth_date"
                                icon={CalendarIcon}
                                maxDate={eighteenYearsAgo}
                                label="Дата рождения"
                                onChange={handleOnChange}
                                value={birth_date}
                                validator={[{required: true}]}
                                containerClassName="date-picker profileTabs__edit_item"
                                formError={formError}
                                setFormError={setFormError}
                                resetValidate={resetValidate}
                                checkAfterFocus={false}
                            />
                        </Col>
                    </Row>
                    <div className="profileTabs__edit_phone profileTabs__edit_item">
                        <RefFormPhone
                            name="phone"
                            legalForm={true}
                            btnTitle='Изменить'
                            value={phone}
                            phoneInputDisabled={false}
                            className="profileTabs__phone"
                            confirmedCode={phone_code}
                            handleVisibleCode={handleVisibleCode}
                            onChange={phone => handleOnChange({target: {name: 'phone', value: phone}})}
                            formError={formError}
                            setFormError={setFormError}
                            resetValidate={resetValidate}
                            checkAfterFocus={false}
                        />
                        <div className={`form__form-group form__confirm-code ${confirmCodeVisible ? 'fShow' : ''}`}>
                            <RegFormCode
                                showIcon
                                codeValue={codeValue}
                                setCodeValue={setCodeValue}
                                parentInput="phone"
                                parentValue={phone}
                                handleVisibleValidate={false}
                                className="profileTabs__phone_code"
                                handleVisibleCode={handleVisibleCode}
                                handleConfirmedCode={() => setConfirmedCode(!confirmedCode)}
                                onChange={code => handleOnChange({target: {name: 'phone_code', value: code}})}
                                checkAfterFocus={false}
                            />
                        </div>
                    </div>
                    {
                        legal_form_type === 'entity'
                            ? <InputText
                                name="position"
                                label="Должность"
                                onChange={handleOnChange}
                                value={position}
                                validator={[{required: true}, {maxLength: 100}]}
                                containerClassName="profileTabs__edit_item"
                                formError={formError}
                                setFormError={setFormError}
                                resetValidate={resetValidate}
                                checkAfterFocus={false}
                            />
                            : null
                    }
                    {
                        legal_form_type === 'ie'
                            ? <InputText
                                type="mask"
                                name="ogrnip"
                                label="ОГРНИП"
                                onChange={handleOnChange}
                                value={ogrnip}
                                validator={[{required: true}, {numbersCount: 15}]}
                                mask={ogrnipMask}
                                containerClassName="profileTabs__edit_item"
                                formError={formError}
                                setFormError={setFormError}
                                resetValidate={resetValidate}
                                checkAfterFocus={false}
                            />
                            : null
                    }
                    <Row>
                        <Col md={6}>
                            <InputText
                                type="mask"
                                name="inn"
                                label="ИНН"
                                onChange={handleOnChange}
                                value={inn}
                                validator={[{required: true}, {numbersCount: legal_form_type === 'entity' ? 10 : 12}]}
                                mask={legal_form_type === 'entity' ? innMaskEntity : innMask}
                                containerClassName="profileTabs__edit_item"
                                formError={formError}
                                setFormError={setFormError}
                                resetValidate={resetValidate}
                                checkAfterFocus={false}
                            />
                        </Col>
                    </Row>
                    {
                        user && user.roles[0].name === 'borrower'
                            ? <Row>
                                <Col md={6}>
                                    <InputText
                                        type="mask"
                                        name="snils"
                                        label="СНИЛС"
                                        placeholder="999-999-999 99"
                                        onChange={handleOnChange}
                                        value={snils}
                                        validator={[{required: true}, {numbersCount: 11}]}
                                        mask={snilsMask}
                                        containerClassName="profileTabs__edit_item"
                                        formError={formError}
                                        setFormError={setFormError}
                                        resetValidate={resetValidate}
                                        checkAfterFocus={false}
                                    />
                                </Col>
                            </Row>
                            : null
                    }
                    <InputText
                        type="text"
                        name="birth_place"
                        label="Место рождения"
                        onChange={handleOnChange}
                        value={birth_place}
                        validator={[{required: true}, {maxLength: 200}]}
                        containerClassName="profileTabs__edit_item"
                        formError={formError}
                        setFormError={setFormError}
                        resetValidate={resetValidate}
                        checkAfterFocus={false}
                    />
                    <InputText
                        type="text"
                        name="registration_address"
                        label="Адрес регистрации по месту жительства"
                        onChange={handleOnChange}
                        value={registration_address}
                        validator={[{required: true}, {maxLength: 500}]}
                        containerClassName="profileTabs__edit_item"
                        formError={formError}
                        setFormError={setFormError}
                        resetValidate={resetValidate}
                        checkAfterFocus={false}
                    />
                    {
                        user && user.roles[0].name === 'borrower'
                            ? <>
                                <InputText
                                    type="text"
                                    name="residence_address"
                                    label="Адрес фактического пребывания"
                                    onChange={handleOnChange}
                                    value={address_matches ? registration_address : residence_address}
                                    validator={address_matches ? [] : [{required: true}, {maxLength: 500}]}
                                    disabled={address_matches}
                                    containerClassName="profileTabs__edit_item"
                                    resetValidate={residenceAddressResetValidate}
                                    setResetValidate={setResidenceAddressResetValidate}
                                    formError={formError}
                                    setFormError={setFormError}
                                    checkAfterFocus={false}
                                />
                                <InputText
                                    type="checkbox"
                                    name="address_matches"
                                    id="address_matches"
                                    label="Совпадает с адресом регистрации"
                                    onChange={addressMatchesOnChange}
                                    value={address_matches}
                                    containerClassName="profileTabs__edit_item profileTabs__edit_check"
                                    checkAfterFocus={false}
                                />
                            </>
                            : null
                    }
                </div>
            </div>
        </div>
    );
};

export default memo(PersonalData);
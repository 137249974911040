import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import CheckDataToDisplay from "@shared/components/presentation/CheckDataToDisplay";
import {Button, TabContent, TabPane} from "reactstrap";
import TabLinks from "@shared/components/tabs/TabLinks";
import {innerUserPageTabs} from "@containers/InnerUsers/InnerUserPage/helpers";
import InnerUserLogs from "@containers/InnerUsers/InnerUserPage/InnerUserLogs";
import {showModal} from "@actions/modalActions";
import {DELETE_INNER_USER_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";
import {UsersService} from "@services/users.service";
import InnerUserEditData from "@containers/InnerUsers/InnerUserPage/InnerUserEditData";
import InnerUserViewData from "@containers/InnerUsers/InnerUserPage/InnerUserViewData";

const InnerUserPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [userData, setUserData] = useState(null);
    const [activeTab, setActiveTab] = useState(innerUserPageTabs[0].key);
    const [updateUserData, setUpdateUserData] = useState(false);
    const [editStatus, setEditStatus] = useState(false);

    const deleteUser = () => {
        showModal(DELETE_INNER_USER_MODAL, userData)(dispatch)
    }

    useEffect(() => {
        UsersService.getById(id, {include: 'roles'})
            .then(res => setUserData(res?.data?.data || {}))
            .catch(error => console.log(error))
    }, [id, updateUserData])

    return (
        <div className="innerUserPage">
            <CheckDataToDisplay data={id}>
                <h1 className="page-title">
                    {`Внутренний пользователь № ${id}`}
                </h1>
                <div className="page-content innerUserPage__content">
                    <Button color="danger" size="lg" onClick={deleteUser}>
                        Удалить пользователя
                    </Button>
                    <div className="innerUserPage__tabs">
                        <TabLinks data={innerUserPageTabs} activeTab={activeTab} setActiveTab={setActiveTab}/>
                    </div>
                    <TabContent activeTab={activeTab} className="innerUserPage__content">
                        <TabPane tabId={innerUserPageTabs[0].key} className="innerUserPage__tab">
                            <CheckDataToDisplay data={userData}>
                                {
                                    editStatus
                                        ? <InnerUserEditData
                                            data={userData}
                                            setEditStatus={setEditStatus}
                                            setUpdateUserData={setUpdateUserData}
                                        />
                                        : <InnerUserViewData
                                            data={userData}
                                            setEditStatus={setEditStatus}
                                        />
                                }
                            </CheckDataToDisplay>
                        </TabPane>
                        <TabPane tabId={innerUserPageTabs[1].key} className="innerUserPage__tab">
                            <InnerUserLogs userId={id}/>
                        </TabPane>
                    </TabContent>
                </div>
            </CheckDataToDisplay>
        </div>
    )
};

export default InnerUserPage;
import React from 'react';
import {Button, Col} from "reactstrap";
import {monthsRuRP} from "@utils/form";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const ProjectCard = ({data, profileType, collectionCompleted}) => {
    const history = useHistory();
    const reserved = data?.condition?.target ? Math.floor(data.condition.target / 3.4) : 0;
    const progress = data?.condition?.target ? reserved / data.condition.target * 100 : 0;
    const period = data?.condition?.period || 0;
    const date = new Date();
    date.setDate(date.getDate() + period);
    const dateFormat = `${date.getDate()} ${monthsRuRP[date.getMonth()]} ${date.getHours()}:${date.getMinutes()}`;
    const profiles = useSelector(state => state.profiles.profiles);
    let companyName = '';

    if (profiles.length) {
        if (profiles[0]?.legal_form_type === "ie") {
            companyName = profiles[0]?.legal_form?.personal?.fio || ''
        } else {
            companyName = profiles[0]?.legal_form?.company?.name || ''
        }
    }

    const btnOnChange = () => {
        history.push(`/project-detail/${data.id}`);
    }

    return (
        <Col lg={6} xl={4}>
            <div className="projectCard">
                <div className="projectCard__inner">
                    <div className="projectCard__header">
                        <span className="projectCard__subtitle projectCard__name textEllipsis">{companyName}</span>
                        <span className="projectCard__subtitle">{data.id ? `№${data.id}` : ''}</span>
                    </div>
                    <p className="projectCard__title projectCard__header_title">{data.title || ''}</p>
                    <div className="projectCard__terms">
                        <div className="projectCard__terms_item">
                            <span className="projectCard__subtitle">Ставка</span>
                            <p className="projectCard__title">
                                <span
                                    className="notWrap">{data?.condition?.percent ? `${data.condition.percent} %` : ''}</span>
                                <span className="projectCard__smallText"> годовых</span>
                            </p>
                        </div>
                        <div className="projectCard__terms_item">
                            <span className="projectCard__subtitle">Срок займа</span>
                            <p className="projectCard__title">{data?.condition?.loan ? `${data.condition.loan} мес.` : ''}</p>
                        </div>
                        <div className="projectCard__terms_item">
                            <span className="projectCard__subtitle">Рейтинг проекта</span>
                            <p className="projectCard__title">{data.rating ? data.rating.value : ''}</p>
                        </div>
                    </div>
                    <div className="projectCard__progressBar">
              <span className="projectCard__subtitle">
                {`${collectionCompleted ? 'Собрано ' : 'Зарезервировано '}`}
                  <span className="notWrap">{`${reserved} ₽`}</span> из
                <span className="notWrap"> {data?.condition?.target ? `${data.condition.target} ₽` : ''}</span>
              </span>
                        <div className="projectCard__progressBar_inner">
                            <div className="projectCard__progressBar_fill" style={{width: `${progress}%`}}/>
                            <span/><span/><span/>
                        </div>
                    </div>
                    <div className="projectCard__desc">
              <span className="projectCard__subtitle">
                Мин. сумма сбора:
                <span> {data?.condition?.min ? `${data.condition.min} ₽` : ''}</span>
              </span>
                        <span className="projectCard__subtitle">
                {
                    collectionCompleted
                        ? <>Сбор завершен: <span>{dateFormat || ''}</span></>
                        : <>Сбор до: <span>{dateFormat || ''}</span></>
                }
              </span>
                    </div>
                </div>
                <div className="projectCard__btnWrap">
                    <Button
                        color="primary"
                        className="projectCard__btn"
                        onClick={btnOnChange}
                    >
                        {profileType === 'borrower' || collectionCompleted ? 'Подробнее' : 'Инвестировать'}
                    </Button>
                </div>
            </div>
        </Col>
    )
}

export default ProjectCard;
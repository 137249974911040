import {
    CHANGE_MOBILE_SIDEBAR_VISIBILITY,
    CHANGE_SIDEBAR_VISIBILITY,
    HIDE_MENU_COLLAPSE,
    MENU_COLLAPSE
} from '@redux/actions/sidebarActions';

const initialState = {
    show: false,
    collapse: false,
    personalAreaMenuCollapse: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_SIDEBAR_VISIBILITY:
            return {
                ...state,
                collapse: !state.collapse
            };
        case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
            return {
                ...state,
                show: !state.show
            };
        case MENU_COLLAPSE:
            return {
                ...state,
                [action.payload.menu]: action.payload.status
            };
        case HIDE_MENU_COLLAPSE:
            return {
                ...state,
                personalAreaMenuCollapse: false
            };
        default:
            return state;
    }
}

import React from 'react';
import {accountDataItem} from "@containers/Account/Profile/helpers";
import EditPersonalData from "@shared/components/account/accountPersonalData/EditPersonalData";
import {getPersonalDataToDisplay} from "./helpers";

const AccountPersonalData = ({
                                 profileData, editForm, title = 'Персональные данные',
                                 qualifiedApplication, forInnerUser
                             }) => {
    return (
        <div className="profileTabs__tabItem accountPersonalData">
            <div className="profileTabs__tabItem_title">
                <span>{title}</span>
            </div>
            <div className="profileTabs__tabItem_list">
                {
                    editForm
                        ? <EditPersonalData profileData={profileData}/>
                        : accountDataItem(getPersonalDataToDisplay(profileData, qualifiedApplication, forInnerUser))
                }
            </div>
        </div>
    )
}

export default AccountPersonalData
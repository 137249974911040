export const initState = {
    search: {
        'created_at': [],
        'profile.legal_form.personal.fio': '',
        'profile.user.role.name': [],
        'profile.id': '',
        'type_key': [],
    },
    searchFields: {
        'created_at': 'between',
        'profile.legal_form.personal.fio': 'like',
        'profile.user.role.name': 'in',
        'profile.id': 'like',
        'type_key': 'in',
    },
    tableHeaderVisible: ['number', 'created_at', 'profile.legal_form.personal.fio', 'profile.user.role.name', 'profile.id', 'type_key'],
    sortedBy: ['desc'],
    orderBy: ['created_at'],
    currentPage: 1
}

export const tableHeader = [
    {id: 0, name: 'number', title: '№ п/п', cellSize: 'sm'},
    {id: 1, name: 'created_at', title: 'Дата', filterType: 'date', cellSize: 'sm'},
    {
        id: 3,
        name: 'profile.legal_form.personal.fio',
        title: 'Наименование/ФИО',
        filterType: 'searchField',
        cellSize: 'sm'
    },
    {
        id: 4, name: 'profile.user.role.name', title: 'Роль', cellSize: 'sm', filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'investor', label: 'Инвестор'},
            {id: 2, name: 'borrower', label: 'Заемщик'},
        ],
    },
    {id: 5, name: 'profile.id', title: 'ID профиля', filterType: 'searchField', cellSize: 'sm'},
    {
        id: 6, name: 'type_key', title: 'Операция', cellSize: 'lg', filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'accreditation', label: 'Аккредитация'},
            {id: 2, name: 'moderation', label: 'Модерация'},
            {id: 3, name: 'qualification', label: 'Квалификация'},
            {id: 4, name: 'confirmation', label: 'Подтверждение'},
            {id: 5, name: 'ban_profile', label: 'Бан профиля'},
            {id: 6, name: 'ban_user', label: 'Бан пользователя'},
            {id: 7, name: 'profile', label: 'Профиль'},
            {id: 8, name: 'bankDetail', label: 'Банковские реквизиты'},
            {id: 9, name: 'user', label: 'Пользователь'},
            {id: 10, name: 'document', label: 'Файл'},
            {id: 11, name: 'project', label: 'Проект'},
        ],
    },
]

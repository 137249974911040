import React, {Component} from 'react';
import Image404 from "@shared/img/404/404.png";
import {Button} from "reactstrap";
import browserHistory from "@utils/history";

class ErrorBoundary extends Component {
    state = {hasError: false};

    constructor(props) {
        super(props);
        this.redirectToHome = this.redirectToHome.bind(this)
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log("ErrorBoundary \n", error, errorInfo);
    }

    redirectToHome() {
        this.setState({hasError: false})
        browserHistory.push('/');
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <div className="not-found">
                        <div className="not-found__content">
                            <img className="not-found__image" src={Image404} alt="404"/>
                            <h3 className="not-found__info">Упс! Неизвестная ошибка :(</h3>
                            <Button
                                color="primary"
                                size="lg"
                                onClick={this.redirectToHome}
                            >
                                Домой
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
import React from 'react';
import {checkDependencies} from "./helpers";
import EditAdditionalInfoGroup from "./EditAdditionalInfoGroup";
import UploadField from "@shared/components/uploadField";

const AdditionalInfo = ({data, editForm}) => {
    const {question} = data?.form || {};
    const values = data?.values || [];

    const renderFormValues = () => {
        let personNumber = 0;

        const renderItem = (item) => {
            const files = item.files ? item.files : [];
            const {type_key, label, key, options} = item.field || {};
            const itemKey = `${key}${item.person}`;

            switch (type_key) {
                case 'text':
                case 'string':
                case 'number':
                case 'date':
                case 'address':
                case 'fio':
                case 'serial_number':
                case 'inn':
                    return (
                        <div key={itemKey} className="profileTabs__tabItem_elem profileTabs__addInfo_item">
                            <span className="profileTabs__tabItem_name">{label}</span>
                            <span className={`profileTabs__tabItem_desc ${!item.value[0] ? 'error' : ''}`}>
                  {item.value[0] || 'Заполните поле'}
                </span>
                        </div>
                    )
                case 'files':
                case 'file':
                    return (
                        <div key={itemKey} className="profileTabs__tabItem_elem profileTabs__addInfo_item">
                            <span className="profileTabs__tabItem_name">{label}</span>
                            {
                                files.length
                                    ? <UploadField files={files} edit={false} title={label}/>
                                    : <span className="profileTabs__tabItem_desc error">Заполните поле</span>
                            }
                        </div>
                    )
                case 'select':
                    return key === 'main'
                        ? item.value[0] === 0 &&
                        <span key={itemKey} className="profileTabs__tabItem_name profileTabs__tabItem_main">
                {options.find(elem => elem.value === item.value[0]).label}
              </span>
                        : <div key={itemKey} className="profileTabs__tabItem_elem profileTabs__addInfo_item">
                            <span className="profileTabs__tabItem_name">{label}</span>
                            <span className="profileTabs__tabItem_desc">
                  {options.find(elem => elem.value === item.value[0]).label}
                </span>
                        </div>
                default:
                    break;
            }
        }

        return values.map(item => {
            const {depends_key, depends_value} = item?.field || {};

            if (depends_key.length) {
                if (checkDependencies(values, depends_key, depends_value, item)) {
                    if (item.person !== personNumber) {
                        personNumber = item.person;

                        return <div key={item.id}>
                            <p className="profileTabs__addInfo_itemNumber">{`Лицо ${+personNumber + 1}`}</p>
                            {renderItem(item)}
                        </div>
                    }

                    return renderItem(item)
                } else {
                    return null
                }
            }

            return renderItem(item)

        })
    }

    return (
        <div className="profileTabs__tabItem">
            <div className="profileTabs__tabItem_title">
                <span>{question}</span>
            </div>
            <div className="profileTabs__tabItem_list">
                {
                    editForm
                        ? <EditAdditionalInfoGroup data={data}/>
                        : renderFormValues()
                }
            </div>
        </div>
    )
}

export default AdditionalInfo
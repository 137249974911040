import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import IconBtn from "@shared/components/form/IconBtn";
import {EDIT_PROJECT_VALUE, setProjectFormError} from '@redux/actions/projectsActions';
import InputText from "@shared/components/form/profileForm/InputText";

const AttractingInvestments = ({fRef, data = {}, formError}) => {
    const dispatch = useDispatch();
    const {type_key, own_version, min, target, percent, period, loan} = data;
    const [ownVersionVisible, setOwnVersionVisible] = useState(false);
    const [minAmountResetValidate, setMinAmountResetValidate] = useState(false);

    const setFormError = data => {
        setProjectFormError(data, dispatch)
    }

    const setData = (name, value) => {
        dispatch({
            type: EDIT_PROJECT_VALUE,
            payload: {
                name,
                value: value || null,
                dataKey: 'condition',
            }
        })
    }

    const handleRadioBtn = (name, value) => {
        const index = formError.indexOf('own_version');

        setData(name, value)
        if (own_version) setData('own_version', null)
        if (index !== -1) {
            const newFormError = [...formError];
            newFormError.splice(index, 1)
            setFormError(newFormError)
        }
    }

    const handleOnClickOwnVersion = () => {
        setData('type_key', '');
        setOwnVersionVisible(true);
    }

    return (
        <div className="addProject__form addProject__form-investments" ref={fRef}>
            <p className="addProject__form_title">Цель привлечения инвестиций</p>
            <div className="addProject__investments">
                <div className="addProject__formGroup attractingInvest__mainBtn">
                    <button
                        className={`mainBtn__white from__custom-radio-btn ${type_key === 'purchase' && 'active'}`}
                        onClick={() => handleRadioBtn('type_key', 'purchase')}
                    >
                        <span>Приобретение имущества для ведения деятельности</span>
                    </button>
                    <button
                        className={`mainBtn__white from__custom-radio-btn ${type_key === 'replenishment' && 'active'}`}
                        onClick={() => handleRadioBtn('type_key', 'replenishment')}
                    >
                        <span>Пополнение оборотных средств</span>
                    </button>
                    <button
                        className={`mainBtn__white from__custom-radio-btn ${type_key === 'refinancing' && 'active'}`}
                        onClick={() => handleRadioBtn('type_key', 'refinancing')}
                    >
                        <span>Приобретение имущества для последующей реализации</span>
                    </button>
                </div>

                <div className="yourOption__wrap">
                    {
                        (ownVersionVisible || own_version) && !type_key
                            ? <InputText
                                key="own_version"
                                name="own_version"
                                type='textarea'
                                formError={formError}
                                onChange={e => setData('own_version', e.target.value)}
                                setFormError={setFormError}
                                validator={data.type_key === null ? [{maxWords: 50}, {required: true}] : []}
                                label="Свой вариант"
                                value={own_version || ''}
                                containerClassName="addProject__yourOption"
                                labelClass="requiredMark"
                            />
                            : <IconBtn
                                title="Свой вариант"
                                onClick={handleOnClickOwnVersion}
                                className="yourOption__addBtn"
                            />
                    }
                </div>

                <InputText
                    id="minInput"
                    name="min"
                    formError={formError}
                    setFormError={setFormError}
                    onChange={e => setData('min', +(e.target.value.replace(/\D/g, '')))}
                    label="Минимальная сумма"
                    value={min || ''}
                    labelClass="requiredMark"
                    validator={[{digitLimit: 10}, {required: true}]}
                    validatorMessages={{maxNumber: 'Минимальная сумма не должна превышать целевую сумму!',}}
                    inputWithDesc
                    inputWithDescValue="₽"
                    containerClassName="addProject__middleInput"
                    resetValidate={minAmountResetValidate}
                    setResetValidate={setMinAmountResetValidate}
                    rank={true}
                />

                <InputText
                    id="targetInput"
                    name="target"
                    formError={formError}
                    setFormError={setFormError}
                    onChange={e => {
                        setData('target', +(e.target.value.replace(/\D/g, '')));
                        if (min && !minAmountResetValidate) setMinAmountResetValidate(true)
                    }}
                    label="Целевая сумма"
                    value={target || ''}
                    labelClass="requiredMark"
                    validator={[{digitLimit: 10}, {minNumber: min + 1 || null}, {required: true}]}
                    validatorMessages={{minNumber: 'Целевая сумма должна быть больше минимальной суммы!'}}
                    inputWithDesc
                    inputWithDescValue="₽"
                    containerClassName="addProject__middleInput"
                    rank={true}
                />
                <InputText
                    id="percentInput"
                    name="percent"
                    formError={formError}
                    setFormError={setFormError}
                    onChange={e => setData('percent', e.target.value)}
                    label="Ставка"
                    value={percent || ''}
                    labelClass="requiredMark"
                    validator={[{digitLimit: 3}, {maxNumber: 200}, {onlyNumbers: true}, {required: true}]}
                    validatorMessages={{
                        digitLimit: 'Максимальная длина 3 цифры!',
                        maxNumber: 'Максимальный процент по займу 200%'
                    }}
                    inputWithDesc
                    inputWithDescValue="%"
                    containerClassName="addProject__middleInput"
                />

                <InputText
                    id="periodInput"
                    name="period"
                    formError={formError}
                    setFormError={setFormError}
                    onChange={e => setData('period', e.target.value)}
                    label="Период сбора инвестиций"
                    value={period || ''}
                    labelClass="requiredMark"
                    validator={[{digitLimit: 2}, {maxNumber: 30}, {onlyNumbers: true}, {required: true}]}
                    validatorMessages={{
                        digitLimit: 'Максимальная длина 2 цифры!',
                        maxNumber: 'Период сбора инвестиций не может превышать 30 дней'
                    }}
                    inputWithDesc
                    inputWithDescValue="дня(ей)"
                    containerClassName="addProject__smallInput"
                    inputStyle={{paddingRight: '75px'}}
                    tooltip="Не более 30 календарных дней"
                    tooltipClass="smallTooltip"
                />

                <InputText
                    id="loanInput"
                    name="loan"
                    formError={formError}
                    setFormError={setFormError}
                    onChange={e => setData('loan', e.target.value)}
                    label="Срок займа"
                    value={loan || ''}
                    labelClass="requiredMark"
                    validator={[{digitLimit: 2}, {maxNumber: 36}, {onlyNumbers: true}, {required: true}]}
                    validatorMessages={{
                        digitLimit: 'Максимальная длина 2 цифры!',
                        maxNumber: 'Срок займа не может превышать 36 мес.'
                    }}
                    inputWithDesc
                    inputWithDescValue="мес."
                    containerClassName="addProject__smallInput"
                    tooltip="Предельный срок займа 36 мес."
                    tooltipClass="smallTooltip"
                />

            </div>
        </div>
    )
}

export default AttractingInvestments;

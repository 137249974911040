export const TURN_OFF_LOADING = 'TURN_OFF_LOADING';
export const TURN_ON_LOADING = 'TURN_ON_LOADING';

export const turnOnLoading = () => dispatch => dispatch({
    type: TURN_ON_LOADING,
})

export const turnOffLoading = () => dispatch => dispatch({
    type: TURN_OFF_LOADING,
})

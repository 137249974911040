import React from 'react';

const LoanNotificationCollectionCompleted = ({NotificationColl}) => {
    return (
        <div className='loanNotificationCollectionCompleted'>
            <span className='loanNotificationCollectionCompleted__desc'>
                {NotificationColl}
            </span>
        </div>
    )
};

export default LoanNotificationCollectionCompleted;
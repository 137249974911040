import React from 'react';
import DataCard from "@shared/components/data/DataCard";

const BankDetail = ({data, fRef}) => {
    const masterAccount = data.filter(item => item.master_account);

    const dataFormat = [
        {name: 'Наименование банка', desc: masterAccount[0].name || ''},
        {name: 'БИК', desc: masterAccount[0].bik || ''},
        {name: 'ИНН', desc: masterAccount[0].inn || ''},
        {name: 'Корр. счет', desc: masterAccount[0].correspondent_account || ''},
        {name: 'Расчетный счет', desc: masterAccount[0].checking_account || ''},
    ]

    return <DataCard data={dataFormat} title="Банковские реквизиты" fRef={fRef}/>
}

export default BankDetail;

import React, {useEffect, useState} from "react";
import {EditIcon} from "@shared/img/iconSvg";
import {isBorrower} from "@utils/profile";
import EditBankDetails from "../EditBankDetails";
import {useDispatch, useSelector} from "react-redux";
import {SET_BANK_TEMPLATE_EDIT} from "@actions/profilesActions";

export const BankDetailsRender = ({
                                      data,
                                      notEditable,
                                      className,
                                      onlyBankName,
                                      fRef = null,
                                      hideMasterAccountNotice = false
                                  }) => {
    const dispatch = useDispatch()
    const [edit, setEdit] = useState(false);
    const editProfileId = useSelector(state => state.profiles.editProfileId);

    const handleEditOnClick = () => {
        setEdit(true);
        dispatch({
            type: SET_BANK_TEMPLATE_EDIT,
            payload: data.id
        })
    }

    useEffect(() => {
        setEdit(false)
    }, [editProfileId])

    return (
        <div
            ref={fRef}
            className={`profileTabs__col ${isBorrower() ? 'bankDetails__item profile__col50' : 'profile__col'} ${className ? className : ''}`}
        >
            <div className="profileTabs__tabItem">
                <div className="profileTabs__tabItem_title">
                    <span>Счет</span>
                </div>
                {
                    edit || data.newTemplate
                        ? <EditBankDetails data={data} setEdit={setEdit}/>
                        : <>
                            {
                                !notEditable
                                    ? <button className="profileTabs__tabItem_edit" onClick={handleEditOnClick}>
                                        <EditIcon/>
                                    </button>
                                    : ""
                            }
                            <div className="profileTabs__tabItem_list">
                                <div className="profileTabs__tabItem_elem">
                        <span className="profileTabs__tabItem_name">
                          {
                              onlyBankName
                                  ? 'Наименование банка'
                                  : 'Наименование банка или БИК'
                          }
                        </span>
                                    <span
                                        className={`profileTabs__tabItem_desc ${data.name || data.bik ? '' : 'error'}`}>{data.name || data.bik || 'Заполните поле'}</span>
                                </div>
                                <div className="profileTabs__tabItem_elem">
                                    <span className="profileTabs__tabItem_name">БИК</span>
                                    <span
                                        className={`profileTabs__tabItem_desc ${data.bik ? '' : 'error'}`}>{data.bik || 'Заполните поле'}</span>
                                </div>
                                <div className="profileTabs__tabItem_elem">
                                    <span className="profileTabs__tabItem_name">ИНН банка</span>
                                    <span
                                        className={`profileTabs__tabItem_desc ${data.inn ? '' : 'error'}`}>{data.inn || 'Заполните поле'}</span>
                                </div>
                                <div className="profileTabs__tabItem_elem">
                                    <span className="profileTabs__tabItem_name">Корр. счет</span>
                                    <span
                                        className={`profileTabs__tabItem_desc ${data.correspondent_account ? '' : 'error'}`}>{data.correspondent_account || 'Заполните поле'}</span>
                                </div>
                                <div className="profileTabs__tabItem_elem">
                                    <span className="profileTabs__tabItem_name">Расчетный счет</span>
                                    <span
                                        className={`profileTabs__tabItem_desc ${data.checking_account ? '' : 'error'}`}>{data.checking_account || 'Заполните поле'}</span>
                                </div>
                            </div>
                        </>
                }
                {
                    !hideMasterAccountNotice && data.master_account && isBorrower()
                        ? <span className="bankDetails__masterAccount">ОСНОВНОЙ СЧЕТ</span>
                        : ''
                }
            </div>
        </div>
    )
}
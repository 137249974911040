import React, {useState} from 'react';
import ControlTabs from "@shared/components/tabs/ControlTabs";
import {controlTabsData} from './helpers';
import ModerationProjectsList from './ModerationProjectsList';

const ModerationProjects = () => {
    const [activeTab, setActiveTab] = useState('pending');

    return (
        <div className="moderationProjects">
            <h1 className="page-title">Модерация проектов</h1>
            <div className="accreditation__row accreditation__content">
                <ControlTabs data={controlTabsData} activeTab={activeTab} setActiveTab={setActiveTab}/>
                <ModerationProjectsList activeTab={activeTab}/>
            </div>
        </div>
    )
}

export default ModerationProjects;
import React, {useEffect, useRef, useState} from 'react';
import SelectedProfile from "@shared/components/sidebar/profileSelection/SelectedProfile";
import ProfileSelectionList from "@shared/components/sidebar/profileSelection/ProfileSelectionList";
import {useSelector} from "react-redux";


const ProfileSelection = () => {
    const node = useRef();
    const {profiles} = useSelector(state => state.profiles)
    const id = useSelector(state => state.user.profile.id || null);
    const [listVisible, setListVisible] = useState(false);

    // При клике вне "div.profileSelection__list" закрыть список
    const handleClickOutside = ({target}) => {
        if (node.current.contains(target)) return;
        setTimeout(() => setListVisible(false), 150)
    };

    useEffect(() => {
        listVisible
            ? document.addEventListener("mousedown", handleClickOutside)
            : document.removeEventListener("mousedown", handleClickOutside)

        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [listVisible]);


    return (
        <div className="profileSelection" ref={node}>
            <SelectedProfile
                listVisible={listVisible}
                setListVisible={setListVisible}
                profiles={profiles}
                selectedProfileId={id}
            />
            <ProfileSelectionList
                listVisible={listVisible}
                setListVisible={setListVisible}
                profiles={profiles}
                selectedProfileId={id}
            />
        </div>
    )
}

export default React.memo(ProfileSelection);
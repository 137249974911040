import React from 'react';
import QualifiedConditionItem from './QualifiedConditionItem';


const QualifiedFormConditions = ({
                                     data,
                                     setData,
                                     applicationData = null,
                                     viewMode = false,
                                     formValidationCheck,
                                     formError = []
                                 }) => {
    const conditions = viewMode ? applicationData : data?.value?.conditions;

    const inputOnChange = (e, idx) => {
        const newData = {...data};
        newData.value.conditions[idx].selected = e.target.value;

        // Если галочку убрали очистить список файлов
        if (!e.target.value && newData.value.conditions[idx].items) {
            newData.value.conditions[idx].items = newData.value.conditions[idx].items.map(item => ({
                ...item,
                files: []
            }))
        }

        setData(newData);
    }

    const handleOnChangeFile = (e, idx, fileIdx) => {
        const newData = {...data};
        newData.value.conditions[idx].items[fileIdx].files = e.target.value;

        setData(newData);
    }

    return <div className="qualifiedInvestor__conditions">
        {
            conditions.map((item, idx) => (
                <QualifiedConditionItem
                    viewMode={viewMode}
                    key={idx}
                    id={idx}
                    inputOnChange={inputOnChange}
                    item={item}
                    handleOnChangeFile={handleOnChangeFile}
                    formValidationCheck={formValidationCheck}
                    formError={formError}
                />
            ))
        }
    </div>
}

export default QualifiedFormConditions;


import {TURN_OFF_LOADING, TURN_ON_LOADING} from '../actions/loadingActions';

const initialState = {
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TURN_ON_LOADING:
            return {loading: true};
        case TURN_OFF_LOADING:
            return {loading: false};
        default:
            return state;
    }
}

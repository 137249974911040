import React from 'react';
import {Table} from 'reactstrap';
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";
import InvestInputSum from "@shared/components/projects/projectDetail/ProjectInvest/modal/investInputSum";
import {PreliminaryPaymentScheduleData} from "@shared/components/projects/projectDetail/ProjectInvest/modal/helpers";

const PreliminaryPaymentSchedule = () => {
    return (
        <div className='preliminaryPaymentSchedule'>
            <BackButton/>
            <span className='textTitle'>Предварительный график платежей</span>
            <span className='preliminaryPaymentSchedule__subtitle fontSizeText colorGreyBlue'>
                Сумма вашей инвестиции
            </span>
            <div className='preliminaryPaymentSchedule__input'>
                <InvestInputSum/>
                <span className=' preliminaryPaymentSchedule__input_name fontSizeText colorGreyBlue'>
                    Ожидаемый доход:
                </span>
                <span className='preliminaryPaymentSchedule__input_desc fontSizeText'>12 578,09 ₽</span>
            </div>
            <div className='preliminaryPaymentSchedule__table'>
                <Table striped>
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Операция</th>
                        <th>Платеж</th>
                        <th>Погашение <br/> основного долга</th>
                        <th>Проценты</th>
                        <th>Остаток <br/> основного долга</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        PreliminaryPaymentScheduleData.map((elem) => (
                            <tr key={elem.id}>
                                <td>{elem.data}</td>
                                <td>{elem.operation}</td>
                                <td>{`${elem.payment} ₽`}</td>
                                <td>{`${elem.amortization} ₽`}</td>
                                <td>{`${elem.interest} ₽`} </td>
                                {
                                    elem.balanceOwed === ''
                                        ? ''
                                        : <td>{`${elem.balanceOwed} ₽`}</td>
                                }
                            </tr>
                        ))
                    }
                    </tbody>
                </Table>
            </div>
        </div>
    )
};

export default PreliminaryPaymentSchedule;
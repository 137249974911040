import React, {useEffect, useRef, useState} from 'react';
import classNames from "classnames";
import useWindowSize from "@hooks/useWindowSize";

const STEP_WIDTH = 128;

const StepsSidebar = ({step, setStep, stepList = []}) => {
    const [scale, setScale] = useState(0);
    const [progressBarSize, setProgressBarSize] = useState(0);
    const stepsSidebarRef = useRef();
    const [width = null] = useWindowSize();
    const xTransform = {transform: `translate3d(0px, 0px, 0px) scaleX(${scale}) scaleX(1)`};
    const yTransform = {transform: `translate3d(0px, 0px, 0px) scaleY(${scale}) scaleY(1)`};
    const mobXTransform = {transform: `translate3d(${STEP_WIDTH * step + 20}px, 0px, 0px)`};

    useEffect(() => {
        if (stepsSidebarRef?.current?.clientHeight && stepsSidebarRef?.current?.clientWidth) {
            setProgressBarSize(
                width > 1280 ?
                    stepsSidebarRef.current.clientHeight :
                    (stepsSidebarRef.current.clientWidth - stepsSidebarRef.current.clientWidth / stepList.length) + 30
            )

            const scaleValue = width > 1280 ?
                (progressBarSize / stepList.length + 15) / progressBarSize * step :
                ((progressBarSize / (stepList.length - 1))) / progressBarSize * step;

            setScale(scaleValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, width, progressBarSize])

    useEffect(() => {
        step > stepList.length - 1 && setStep(stepList.length - 1)
        step < 0 && setStep(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step])

    return (
        <div
            className="addProject__steps"
            style={width <= 480 ? mobXTransform : {transform: 'translate3d(0, 0, 0)'}}
            ref={stepsSidebarRef}
        >
            {
                stepList.map(item => {
                    const stepClassItem = classNames({
                        addProject__step: true,
                        addProject__step_end: item.id === stepList.length - 1,
                        'process': step === item.id && step !== stepList.length - 1,
                        'active': item.id <= step
                    })

                    return <div key={item.id} className={stepClassItem}>
                        <div className="addProject__step_icon"/>
                        <span className="addProject__step_name">{item.name}</span>
                    </div>
                })
            }
            <div className="addProject__steps_progressbar"
                 style={{width: width <= 1280 ? `${progressBarSize}px` : '4px'}}>
          <span
              className="addProject__steps_progressbar-fill"
              style={width && width <= 1280 ? xTransform : yTransform}
          />
            </div>
        </div>
    )
}

export default StepsSidebar;
import React from "react";
import {Route} from "react-router-dom";
import {isAuth, RoleHasPermissions} from "@utils/auth";

export const WrapperComponent = component => {
    return <div className="container__wrap">
        {React.createElement(component)}
    </div>
}

export const Router = (props) => {
    const {auth, noAuth, permissions, component, layout} = props
    //Авторизован, но адрес доступен неавторизованным
    if (auth && !isAuth()) return null
    //Не авторизован, но адрес доступен авторизованным
    if (noAuth && isAuth()) return null

    //Если указаны права
    if (permissions && Array.isArray(permissions)) {
        //Не авторизован
        if (!isAuth()) return null
        //Роль пользователя не присутствует в массиве
        if (permissions.length && !RoleHasPermissions(permissions)) return null
    }

    //Если требуется Layout
    if (layout) return <Route {...props} component={() => WrapperComponent(component)}/>

    return <Route {...props}/>
}

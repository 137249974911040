import React from 'react';
import {Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis,} from 'recharts';
import SchedulePanel from './SchedulePanel';

const data = [
    {month: '01.01.2019', c: 30000, d: 10000},
    {month: '01.03.2019', c: 30000, d: 10000},
    {month: '01.05.2019', c: 30000, d: 10000},
    {month: '01.07.2019', a: 21000, b: 30000, c: 30000, d: 10000},
    {month: '01.09.2019', a: 22000, b: 30000,},
    {month: '01.11.2019', a: 23000, b: 30000,},
    {month: '01.01.2020', a: 24000, b: 30000,},
    {month: '01.03.2020', a: 25000, b: 30000,},
    {month: '01.05.2020', a: 26000, b: 30000,},
    {month: '01.07.2020', a: 27000, b: 30000,},
    {month: '01.09.2020', a: 28000, b: 30000,},
    {month: '01.11.2020', a: 29000, b: 30000,},
    {month: '01.01.2021', a: 30000, b: 30000,},
];

const CustomizedAxisTick = props => {
    const {x, y, payload} = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={15} textAnchor="end" fill="#666" transform="rotate(-49.08)">
                {payload.value}
            </text>
        </g>
    );
}


const DeferredAnnuitySchedule = () => (
    <SchedulePanel
        title="2. Аннуитет с отсрочкой"
        desc="Отсроченный аннуитет предусматривает отсрочку на выплату основного долга.
        До этой даты заемщик выплачивает только проценты по займу. По окончании отсрочки заемщик выплачивает основной
        долг и проценты по классической схеме аннуитета"
    >
        <ResponsiveContainer height={320} className="dashboard__area">
            <AreaChart
                data={data}
                margin={{top: 10, right: 30, left: 0, bottom: 0}}
                className="addProject__chart"
            >
                <Legend
                    verticalAlign="top"
                    height={36}
                    align="right"
                    iconType="circle"
                    iconSize={11}
                    payload={[
                        {value: 'Тело', type: 'circle', id: 'a', color: 'url(#colorA)'},
                        {value: '%', type: 'circle', id: 'b', color: '#DEE9FF'},
                        {value: 'Время отсрочки', type: 'circle', id: 'c', color: '#66D7A2'},
                    ]}
                />
                <defs>
                    <linearGradient id="colorA">
                        <stop offset="0%" stopColor="#55C983"/>
                        <stop offset="100%" stopColor="#97A4F0"/>
                    </linearGradient>
                    <pattern
                        id="colorC"
                        width="5"
                        height="10"
                        patternUnits="userSpaceOnUse"
                        patternTransform="rotate(48)"
                        className="fff"
                    >
                        <line stroke="#66D7A2" strokeWidth="3px" y2="10"/>
                    </pattern>
                </defs>
                <CartesianGrid/>
                <XAxis
                    interval={0}
                    tickCount={13}
                    dataKey="month"
                    tickLine={false}
                    axisLine={false}
                    tick={<CustomizedAxisTick/>}
                    height={70}
                />
                <YAxis
                    tickCount={7}
                    tickLine={false}
                    axisLine={false}
                    interval={0}
                    tickMargin={12}
                    tickFormatter={item => item.toLocaleString()}
                />
                {/*<Tooltip content={renderTooltipContent} />*/}
                <Area type="monotone" dataKey="a" name="Тело" stackId="1" fillOpacity="0.75" stroke="url(#colorA)"
                      fill="url(#colorA)"/>
                <Area type="monotone" dataKey="b" name="%" stackId="2" fillOpacity="0.75" stroke="#DEE9FF"
                      fill="#DEE9FF"/>
                <Area type="monotone" dataKey="c" name="Время отсрочки" fillOpacity="0.75" stackId="3"
                      stroke="url(#colorC)" fill="url(#colorC)"/>
                <Area type="monotone" dataKey="d" name="dd" stackId="4" stroke="#DEE9FF" fillOpacity="0.75"
                      fill="#DEE9FF"/>
            </AreaChart>
        </ResponsiveContainer>
    </SchedulePanel>
);


export default DeferredAnnuitySchedule;

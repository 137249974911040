import React from 'react';

const SecurityForm = ({data}) => {
    return (
        <div className='security'>
            <span className='theProperty__title'>Обеспечение</span>
            <div className='theProperty'>
                {
                    data.pledges.map((elem, idx) => {
                        switch (elem.property_type) {
                            case 'property': {
                                return (
                                    <div key={idx}>
                                        <div className='theProperty__block'>
                                            <span
                                                className='theProperty__block_subtitle'>Недвижимость - {elem.property.type.value}</span>
                                            <span className='theProperty__block_name'>Кадастровый номер</span>
                                            <span className='theProperty__block_desc'>{elem.property.number} </span>
                                        </div>
                                        <div className='theProperty__block theProperty__blockItem'>
                                            <span className='theProperty__block_name'>Описание</span>
                                            <span className='theProperty__block_desc'>{elem.description}</span>
                                        </div>
                                    </div>
                                );
                            }
                            case 'movable': {
                                return (
                                    <div key={idx}>
                                        <div className='theProperty__block'>
                                            <span className='theProperty__block_subtitle'>Движимое имущество</span>
                                            <span className='theProperty__block_name'>Наименование (тип ТС)</span>
                                            <span className='theProperty__block_desc'>{elem.property.name}</span>
                                        </div>
                                        <div className='theProperty__block theProperty__blockItem'>
                                            <span className='theProperty__block_name'>Марка, модель ТС</span>
                                            <span className='theProperty__block_desc'>{elem.property.brand_model}</span>
                                        </div>
                                        <div className='theProperty__block theProperty__blockItem'>
                                            <span
                                                className='theProperty__block_name'>Идентификационный номер (VIN)</span>
                                            <span className='theProperty__block_desc'>{elem.property.vin}</span>
                                        </div>
                                        <div className='theProperty__block theProperty__blockItem'>
                                            <span className='theProperty__block_name'>Описание</span>
                                            <span className='theProperty__block_desc'>{elem.description}</span>
                                        </div>
                                    </div>
                                );
                            }
                            /*case 'property': {
                                return (
                                    <div key={idx}>
                                        <div className='theProperty__block'>
                                            <span className='theProperty__block_subtitle'>Недвижимость - жилой дом и земельный участок</span>
                                            <span className='theProperty__block_name'>Жилой дом (с хозяйственными постройками): кадастровый номер</span>
                                            <span
                                                className='theProperty__block_desc'>{elem.property.house.number} </span>
                                        </div>
                                        <div className='theProperty__block theProperty__blockItem'>
                                            <span className='theProperty__block_name'>Земельный участок: кадастровый номер</span>
                                            <span
                                                className='theProperty__block_desc'>{elem.property.land.number} </span>
                                        </div>
                                        <div className='theProperty__block theProperty__blockItem'>
                                            <span className='theProperty__block_name'>Описание</span>
                                            <span className='theProperty__block_desc'>{elem.description}</span>
                                        </div>
                                    </div>
                                );
                            }*/
                            case 'other': {
                                return (
                                    <div key={idx}>
                                        <div className='theProperty__block'>
                                            <span className='theProperty__block_subtitle'>Иное имущество</span>
                                            <span className='theProperty__block_name'>Наименование объекта</span>
                                            <span className='theProperty__block_desc'>{elem.property.name} </span>
                                        </div>
                                        <div className='theProperty__block theProperty__blockItem'>
                                            <span className='theProperty__block_name'>Качественные и количественные характеристики</span>
                                            <span
                                                className='theProperty__block_desc'>{elem.property.characteristics} </span>
                                        </div>
                                        <div className='theProperty__block theProperty__blockItem'>
                                            <span className='theProperty__block_name'>Описание</span>
                                            <span className='theProperty__block_desc'>{elem.description}</span>
                                        </div>
                                    </div>
                                );
                            }
                            case 'ddu': {
                                return (
                                    <div key={idx}>
                                        <div className='theProperty__block'>
                                            <span className='theProperty__block_subtitle'>Права требования по ДДУ</span>
                                            <span
                                                className='theProperty__block_name'>Объект долевого строительства</span>
                                            <span className='theProperty__block_desc'>Наименование </span>
                                        </div>
                                        <div className='theProperty__block theProperty__blockItem'>
                                            <span className='theProperty__block_name'>Адрес жилого помещения</span>
                                            <span className='theProperty__block_desc'>{elem.property.address}</span>
                                        </div>
                                    </div>
                                );
                            }
                            default: {
                                return null;
                            }
                        }
                    })
                }
            </div>
        </div>
    )
};

export default SecurityForm;
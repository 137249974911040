export const calculationsData = [
    {
        plannedDate: "12.08.2021",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'paidInFull'
    },
    {
        plannedDate: "12.08.2021",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'paidInFull'
    },
    {
        plannedDate: "12.08.2021",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'overdue'
    },
    {
        plannedDate: "12.08.2021",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'paidInPart'
    },
    {
        plannedDate: "Совершено частично досрочное погашение с перерасчетом графика платежей",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'partialEarlyRepayment'
    },
    {
        plannedDate: "12.08.2021",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'overdue'
    },
    {
        plannedDate: "12.08.2021",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'awaitingPayment'
    },
    {
        plannedDate: "12.08.2021",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'paidInFull'
    },
    {
        plannedDate: "Совершено частично досрочное погашение с перерасчетом графика платежей",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'fullEarlyRepayment'
    },
    {
        plannedDate: "ИТОГО:",
        plannedPayment: "100 000 ₽",
        mainDebt: '20 000 ₽',
        interest: '20 000 ₽',
        income: '20 000 ₽',
        actualAmount: '20 000 ₽',
        daysOfDelay: '12',
        status: 'overdue'
    },
]

export const statusData = [
    {id: 0, key: 'paidInFull', desc: 'Оплачен полностью', color: '#58BF91'},
    {id: 1, key: 'paidInFull', desc: 'Оплачен полностью', color: '#58BF91'},
    {id: 2, key: 'overdue', desc: 'Просрочен', color: '#FC8473'},
    {id: 3, key: 'paidInPart', desc: 'Оплачен частично', color: '#F0C83B'},
    {id: 4, key: 'partialEarlyRepayment', desc: 'Частично досрочное погашение', color: '#58BF91'},
    {id: 5, key: 'overdue', desc: 'Просрочен', color: '#FC8473'},
    {id: 6, key: 'awaitingPayment', desc: 'Ожидает оплаты', color: '#8797B6'},
    {id: 7, key: 'paidInFull', desc: 'Оплачен полностью', color: '#58BF91'},
    {id: 8, key: 'fullEarlyRepayment', desc: 'Полное досрочное погашение', color: '#58BF91'},
    {id: 9, key: 'overdue', desc: 'Просрочен', color: '#FC8473'},
]
import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import MediaPresentation from "@shared/components/presentation/MediaPresentation";

const Land = ({data, idx, fRef}) => {
    const {
        address, certificate_date, certificate_number, certificates, documents, extract_egrn_from, extracts,
        images, land_category, number, other_information, permitted_use, total_area,
    } = data.land;

    const dataFormat = [
        {name: 'Адрес', desc: address || ''},
        {name: 'Кадастровый номер', desc: number || ''},
        {name: 'Площадь', desc: total_area || ''},
        {name: 'Категория земель', desc: land_category || ''},
        {name: 'Разрешенное использование', desc: permitted_use || ''},
        {name: 'Свидетельство о праве собственности', desc: certificate_number || ''},
        {name: 'Свидетельство о праве собственности от', desc: certificate_date || ''},
        {name: 'Скан свидетельства о праве собственности', desc: certificates || '', type: 'file'},
        {name: 'Выписка из Единого государственного реестра недвижимости, дата', desc: extract_egrn_from || ''},
        {name: 'Скан выписки из ЕГРН', desc: extracts || '', type: 'file'},
        {name: 'Иные сведения', desc: other_information || ''},
        {name: 'Иные документы', desc: documents || '', type: 'file'},
        {
            name: 'Фото залога',
            desc: images ? <MediaPresentation files={images} className="moderationProject__medias"/> : ''
        },
    ]

    return <DataCard data={dataFormat} title={`Залог ${idx + 1}`} fRef={fRef}/>
}

export default Land;

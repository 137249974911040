import React from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import classNames from 'classnames';
import PrimaryTooltip from "@shared/components/tooltip";

const RadioButtonField = ({
                              onChange,
                              radioValue,
                              className,
                              disabled,
                              label,
                              name,
                              value,
                              tooltip = null,
                              tooltipClass = ''
                          }) => {

    const RadioButtonClass = classNames({'radio-btn': true, disabled});

    const handleChange = () => onChange(radioValue);

    return (
        <label
            className={`${RadioButtonClass}${className ? ` radio-btn--${className}` : ''}`}
        >
            <input
                className="radio-btn__radio"
                name={name}
                type="radio"
                onChange={handleChange}
                checked={value === radioValue}
                disabled={disabled}
            />
            <span className="radio-btn__radio-custom"/>
            {
                className === 'button'
                    ? <span className="radio-btn__label-svg">
            <CheckIcon className="radio-btn__label-check"/>
            <CloseIcon className="radio-btn__label-uncheck"/>
          </span>
                    : ''
            }
            <span className="radio-btn__label">
        {label}
                {
                    tooltip
                        ? <PrimaryTooltip
                            title="?"
                            name={name}
                            className={`${tooltipClass ? tooltipClass : ''} profileTabs__tabItem_tooltip`}
                        >
                            {tooltip}
                        </PrimaryTooltip>
                        : ""
                }
      </span>
        </label>
    );
};


export default RadioButtonField;

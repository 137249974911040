import React, {useEffect, useState} from 'react';
import cn from "classnames";
import LockIcon from "mdi-react/LockIcon";
import InputText from "@shared/components/form/profileForm/InputText";
import RegisterConfirmCodeService from "./RegisterConfirmCodeService";
import {setDataValue} from "@shared/components/form/registerForm/action";
import {AuthService} from "@services/auth.service";
import constants from '../../action';

const RegisterConfirmCodeField = ({state, dispatch, setFormError, parentValue, parentKey}) => {
    const [confirmCodeFieldDisabled, setConfirmCodeFieldDisabled] = useState(false);
    const [customErrorMessage, setCustomErrorMessage] = useState(null);
    const [resetValidate, setResetValidate] = useState(null);
    const [spinner, setSpinner] = useState(false);
    const {formError, data, visibleConfirmCodeField, formValidationCheck, verificationDataConfirmed} = state;
    const requestType = parentKey === 'email_code' ? 'checkRegisterEmailCode' : 'checkRegisterPhoneCode';

    const confirmCodeFieldClass = cn({
        'form__form-group': true,
        'form__confirm-code': true,
        'fShow': visibleConfirmCodeField
    })

    const registerFieldWrapClass = cn({
        'form__form-group-field': true,
        errorInput: formError.includes(parentKey)
    })

    const clearInputValue = () => {
        dispatch(setDataValue(parentKey, ''))
        setResetValidate(true)
    }

    const handleOnChangeResetValidate = value => {
        formValidationCheck
            ? dispatch({name: 'formValidationCheck', value})
            : setResetValidate(value)
    }

    const handleOnChangeConfirmCode = e => {
        //const {name, value} = e?.target || {};
        const onlyNums = e.target.value.replace(/[^\d]/g, '') // Только цифры

        if (onlyNums.length < 5) {
            dispatch(setDataValue(parentKey, onlyNums))

            if (onlyNums.length === 4) {
                setConfirmCodeFieldDisabled(true)
                setSpinner(true)

                AuthService[requestType](parentValue, onlyNums)
                    .then(() => {
                        dispatch({name: 'visibleConfirmCodeField', value: false})
                        dispatch({
                            name: 'confirmationFieldServiceType',
                            value: constants.CONFIRMATION_FIELD_VISIBLE_CHECK
                        })
                        dispatch({name: 'verificationDataConfirmed', value: true})
                    })
                    .catch(() => setCustomErrorMessage('Неверный код подтверждения!'))
                    .finally(() => {
                        setConfirmCodeFieldDisabled(false)
                        setSpinner(false)
                    })
            }
        }
    }

    useEffect(() => {
        if (data[parentKey] && data[parentKey].length === 4 && !verificationDataConfirmed) {
            setCustomErrorMessage('Неверный код подтверждения!')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValidationCheck])

    return (
        <div className={confirmCodeFieldClass}>
            <div className="register__field">
                <span className="form-label">Код подтверждения</span>
                <div className={registerFieldWrapClass}>
                    <div className="form__form-group-icon">
                        <LockIcon/>
                    </div>
                    <InputText
                        noLabel
                        checkAfterFocus
                        name={parentKey}
                        type="text"
                        placeholder="Код подтверждения"
                        className="account-input"
                        disabled={confirmCodeFieldDisabled}
                        value={data[parentKey] || ''}
                        onChange={handleOnChangeConfirmCode}
                        formError={formError}
                        setFormError={setFormError}
                        validator={[{numbersCount: 4}, {required: true}]}
                        validatorMessages={{numbersCount: 'Минимальная длина 4 цифры!'}}
                        resetValidate={(resetValidate || formValidationCheck) && visibleConfirmCodeField}
                        customErrorMessage={customErrorMessage}
                        setCustomErrorMessage={value => setCustomErrorMessage(value)}
                        setResetValidate={handleOnChangeResetValidate}
                    />
                    <RegisterConfirmCodeService
                        formError={formError}
                        spinner={spinner}
                        parentKey={parentKey}
                        clearInputValue={clearInputValue}
                    />
                </div>
            </div>
        </div>
    )
};

export default RegisterConfirmCodeField;
import {BaseService} from "@services/base.service";
import {ErrorWrapper} from "@utils/wrapper";

export class FilesService extends BaseService {
    static get entity() {
        return '/files'
    }
}

export class FilesDownloadService extends BaseService {
    static get entity() {
        return '/files/download'
    }

    static async download(id) {
        try {
            return await this.request({auth: true}).get(`${this.entity}/${id}`, {})
        } catch (error) {
            throw new ErrorWrapper(error, "error to get file")
        }
    }
}
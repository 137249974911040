import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Button, Collapse, Spinner} from "reactstrap";
import {FilesDownloadService, FilesService} from "@services/file.service";
import {createAttachedSignature, getUserCertificates} from "crypto-pro";
import {
    getSubjPartDolzh,
    getSubjPartFIO,
    getSubjPartInn,
    getSubjPartLoc,
    getSubjPartOrg
} from "@shared/components/modal/signature/signature";
import {turnOffLoading, turnOnLoading} from "@actions/loadingActions";
import {ArrowDownIcon, ArrowUpIcon} from "@shared/img/iconSvg";
import {SignatureService} from "@services/signature.service";

const SignatureSignModal = ({modalData, hideModal}) => {
    const dispatch = useDispatch();
    const fileId = modalData;
    const [file, setFile] = useState(null);
    const [selected, setSelected] = useState(0);
    const [certificates, setCertificates] = useState([]);
    const [continueStatus, setContinueStatus] = useState(false);

    useEffect(() => {
        turnOnLoading()(dispatch)
        if (fileId) {
            FilesService.getById(fileId, {include: 'profile,profile.legal_form'})
                .then(res => setFile(res.data.data))
                .then(() => {
                    getUserCertificates().then(certs => {
                        certs.forEach(cert => {
                            const certif = {}
                            certif.certDate = new Date(cert.validTo);
                            certif.certFromDate = new Date(cert.validFrom);
                            certif.SN = cert.subjectName
                            certif.issuer = cert.issuerName;
                            certif.thumb = cert.thumbprint;

                            certif.fio = getSubjPartFIO(cert.subjectName);
                            certif.loc = getSubjPartLoc(cert.subjectName);
                            certif.org = getSubjPartOrg(cert.subjectName);
                            certif.dolzh = getSubjPartDolzh(cert.subjectName);
                            certif.inn = getSubjPartInn(cert.subjectName);
                            setCertificates(c => [...c, certif])
                        })
                        turnOffLoading()(dispatch)
                    }).catch(e => {
                        turnOffLoading()(dispatch)
                        hideModal()
                    })
                }).catch(e => {
                turnOffLoading()(dispatch)
                hideModal()
            })
        } else {
            turnOffLoading()(dispatch)
            hideModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileId])

    const handleOnClickCancel = () => {
        setContinueStatus(false)
        hideModal()
    }

    const handleOnClickContinue = () => {
        setContinueStatus(true)
        FilesDownloadService.download(file.id).then(response => {
            createAttachedSignature(certificates[selected].thumb, response.data).then(sign => {
                SignatureService.create({
                    sign: sign,
                    file_id: file.id
                }).then(handleOnClickCancel).catch(handleOnClickCancel)
            }).catch(handleOnClickCancel)
        }).catch(handleOnClickCancel)
    }

    return (
        <div>
            <p className="modalFullScreen__title">
                Выберите сертификат
            </p>

            {certificates.map((cert, id) => <div key={id} onClick={() => setSelected(id)}
                                                 className="signatureSignModal__collapse">
                <div className="signatureSignModal__collapse-title">
                    <div className="signatureSignModal__collapse-title__h3">
                        <input
                            type={"radio"}
                            checked={selected === id}
                            onChange={() => {
                            }}
                        />
                        <h3>{cert["fio"]}</h3>
                    </div>
                    {
                        selected === id ? <ArrowUpIcon/> : <ArrowDownIcon/>
                    }
                </div>
                <Collapse
                    isOpen={selected === id}
                >
                    <div className="signatureSignModal__collapse__info">
                        {cert["inn"] && <div className="signatureSignModal__collapse__info-el">
                            <span>ИНН: </span><span>{cert["inn"]}</span></div>}
                        {cert["fio"] && <div className="signatureSignModal__collapse__info-el">
                            <span>Издатель: </span><span>{cert["fio"]}</span></div>}
                        {cert["certDate"] && <div className="signatureSignModal__collapse__info-el">
                            <span>Действителен до: </span><span>{(new Date(cert["certDate"])).toLocaleDateString()}</span>
                        </div>}
                        {cert["org"] && <div className="signatureSignModal__collapse__info-el">
                            <span>Организация: </span><span>{cert["org"]}</span></div>}
                        {cert["dolzh"] && <div className="signatureSignModal__collapse__info-el">
                            <span>Должность: </span><span>{cert["dolzh"]}</span></div>}
                        {cert["loc"] && <div className="signatureSignModal__collapse__info-el">
                            <span>Адрес: </span><span>{cert["loc"]}</span></div>}
                    </div>
                </Collapse>
            </div>)}
            <div className="signatureSignModal__controls">
                <Button color="danger" outline size="sm" onClick={handleOnClickCancel}>
                    Отменить
                </Button>
                <Button color="primary" size="lg" onClick={handleOnClickContinue}>
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${continueStatus ? 'btn-spinner-visible' : ''}`}/>
                        Продолжить
                    </div>
                </Button>
            </div>

        </div>
    )
};

export default SignatureSignModal;
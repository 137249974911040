import React from 'react';
import {Button} from "reactstrap";
import classNames from "classnames";
import {showModal} from "@actions/modalActions";
import {useDispatch} from "react-redux";
import {
    QUALIFICATION_REPORT_NON_COMPLIANCE,
    QUALIFIED_APPLICATION,
    QUALIFIED_INVESTOR_DETAIL
} from "@shared/components/modal/constants";

const InvestorSuccessQualifiedNotice = () => {
    const dispatch = useDispatch();

    const btnClass = classNames({
        btn_primary: true,
        confirmQualifiedInvestor__btn: true,
    });

    return (
        <div className="accStatusNotice accStatusNotice__successBlock">
            <div className="accStatusNotice__header">
                <p>Статус квалифицированного инвестора подтвержден</p>
            </div>
            <div className="accStatusNotice__content">
                <p>
                    Если вы перестали соответствовать условиям, указанным в заявлении,
                    то в течение 5 рабочих дней необходимо нам об этом сообщить.
                </p>
            </div>
            <div className="accStatusNotice__footer">
                <div className="confirmQualifiedInvestor__btnGroup">
                    <Button
                        color="success"
                        className="account__btn_success confirmQualifiedInvestor__btn"
                        onClick={() => showModal(QUALIFICATION_REPORT_NON_COMPLIANCE)(dispatch)}
                        outline
                    >
                        Сообщить о несоответствии
                    </Button>
                    <Button
                        color="primary"
                        className={btnClass}
                        onClick={() => showModal(QUALIFIED_APPLICATION)(dispatch)}
                    >
                        Открыть заявление
                    </Button>
                </div>
                <div className="accStatusNotice__footer_desc">
                    <button
                        className="accStatusNotice__moreDetails btn-reset"
                        onClick={() => showModal(QUALIFIED_INVESTOR_DETAIL)(dispatch)}
                    >
                        Подробнее о квалификации
                    </button>
                </div>
            </div>
        </div>
    )
}

export default InvestorSuccessQualifiedNotice;
import React from 'react';
import House from "@shared/components/presentation/PledgesProject/property/House";
import Apartment from "@shared/components/presentation/PledgesProject/property/Apartment";
import Premise from "@shared/components/presentation/PledgesProject/property/Premise";
import Land from "@shared/components/presentation/PledgesProject/property/Land";
import Building from "@shared/components/presentation/PledgesProject/property/Building";

const PledgesProjectProperty = ({data, idx, fRef}) => {
    const type = data.property.type_key;

    switch (type) {
        case 'house':
            return <House idx={idx} data={data.property} fRef={fRef}/>
        case 'apartment':
            return <Apartment idx={idx} data={data.property} fRef={fRef}/>
        case 'premise':
            return <Premise idx={idx} data={data.property} fRef={fRef}/>
        case 'land':
            return <Land idx={idx} data={data.property} fRef={fRef}/>
        case 'building':
            return <Building idx={idx} data={data.property} fRef={fRef}/>
        default:
            return null
    }
}

export default PledgesProjectProperty;
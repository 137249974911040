import React from 'react';
import DataCard from "@shared/components/data/DataCard";

const PassportData = ({data, fRef}) => {

    const dataFormat = [
        {name: 'Серия и номер паспорта', desc: data?.serial_number || ''},
        {name: 'Дата выдачи', desc: data?.issue_date || ''},
        {name: 'Кем выдан', desc: data?.issued_by || ''},
        {name: 'Код подразделения', desc: data?.department_code || ''},
        {
            name: 'Страница с фотографией',
            desc: data?.first_photo ? [data?.first_photo] : '',
            type: 'file',
            tooltip: 'Первая страница паспорта'
        },
        {
            name: 'Страница с регистрацией',
            desc: data?.second_photo ? [data?.second_photo] : '',
            type: 'file',
            tooltip: 'Страница с регистрацией'
        },
    ]

    return <DataCard data={dataFormat} title="Паспортные данные заявителя" fRef={fRef}/>
}

export default PassportData;
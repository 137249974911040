import * as constants from "./constants";
import Refill from './balance/Refill';
import WebCamModal from "./WebCamModal";
import AddNewProfile from "./AddNewProfile";
import AddProject from "./projects/AddProject";
import EditAvatarModal from "./avatar/EditAvatarModal";
import SelectImageModal from "./SelectImageModal";
import WithdrawFunds from './balance/WithdrawFunds';
import DesignateAccountMain from "./DesignateAccountMain";
import AboutTariffs from "./projects/AddProject/AboutTariffs";
import PaymentTypesModal from "./projects/AddProject/PaymentTypesModal";
import PaymentScheduleModal from "./projects/AddProject/PaymentScheduleModal";
import AccreditationUnfilled from "./accreditationProfile/AccreditationUnfilled";
import QualifiedInvestorDetail from "./qualifiedInvestor/QualifiedInvestorDetail";
import ModeratorAccProfileReject from './moderatorAccProfile/ModeratorAccProfileReject';
import AccreditationProfileModal from "./accreditationProfile/AccreditationProfileModal";
import AccreditationPhoneModal from "./accreditationProfile/AccreditationPhoneModal";
import ApplicationQualifiedInvestor from "./accreditationProfile/ApplicationQualifiedInvestor";
import QualifiedForm from "./qualifiedInvestor/QualifiedForm";
import QualifiedApplication from "./qualifiedInvestor/QualifiedForm/QualifiedApplication";
import ModeratorAccNewCheck from "./moderatorAccProfile/ModeratorAccNewCheck";
import QualifiedNewCheck from "./qualifiedInvestor/QualifiedNewCheck";
import ModerationProjectReject from "./moderationProject/ModerationProjectReject";
import DetailAvatarModal from "./avatar/DetailAvatarModal";
import ModalWithSettings from "./settings/ModalWithSettings";
import BanCommentModal from "./innerUsers/clientAccount/BanCommentModal";
import QualificationApplicationReject from "./qualifiedInvestor/QualificationApplicationReject";
import ApplicationArchiveModal from "./qualifiedInvestor/ApplicationArchiveModal";
import NoticeModal from "./notice/NoticeModal";
import FileDetailModal from "./files/FileDetailModal";
import SignatureModal from "@shared/components/modal/signature/SignatureModal";
import QualificationApplication from "@shared/components/modal/qualifiedInvestor/QualificationApplication";
import AddInnerUserModal from "@shared/components/modal/innerUsers/AddInerUserModal";
import DeleteInnerUserModal from "@shared/components/modal/innerUsers/DeleteInnerUserModal";
import InnerUsersQualifiedApplication from "./qualifiedInvestor/InnerUsers/InnerUsersQualifiedApplication";
import QualificationReportNonCompliance
    from "@shared/components/modal/qualifiedInvestor/QualificationReportNonCompliance";
import ModalNotification from "@shared/components/modal/ModalNotification";
import CancelProject from "@shared/components/projects/projectDetail/LoanOptions/modal/CancelProject";
import PlatformServices from "@shared/components/projects/projectDetail/LoanOptions/modal/PlatformServices";
import PaymentDetails from "@shared/components/projects/projectDetail/LoanOptions/modal/PaymentDetails";
import EarlyRepayment from "@shared/components/projects/projectDetail/ProjectCalculations/modal/EarlyRepayment";
import PlannedPayment from "@shared/components/projects/projectDetail/ProjectCalculations/modal/PlannedPayment";
import ProfileIsNotAccreditedModal
    from "@shared/components/projects/projectDetail/ProjectInvest/modal/profileIsNotAccreditedModal";
import InvestmentModal from "@shared/components/projects/projectDetail/ProjectInvest/modal/investmentModal";
import AmountExceededModal from "@shared/components/projects/projectDetail/ProjectInvest/modal/amountExceededModal";
import PreliminaryPaymentSchedule
    from "@shared/components/projects/projectDetail/ProjectInvest/modal/preliminaryPaymentSchedule";
import InvestmentsPlatformsModal
    from "@shared/components/projects/projectDetail/ProjectInvest/modal/InvestmentsPlatformsModal";
import InvestmentsPlatformsEditingModal
    from "@shared/components/projects/projectDetail/ProjectInvest/modal/investmentsPlatformsEditingModal";
import InvestmentsPlatformsEditingTwoModal
    from "@shared/components/projects/projectDetail/ProjectInvest/modal/investmentsPlatformsEditingTwoModal";
import CapNotRequiredModal from "@shared/components/projects/projectDetail/ProjectInvest/modal/capNotRequiredModal";
import ConfirmInvestmentSmsModal
    from "@shared/components/projects/projectDetail/ProjectInvest/modal/confirmInvestmentSmsModal";
import InvestmentCodeSmsModal
    from "@shared/components/projects/projectDetail/ProjectInvest/modal/investmentCodeSmsModal";
import InvestmentConfirmQepModal
    from "@shared/components/projects/projectDetail/ProjectInvest/modal/investmentConfirmQepModal";
import CepSignatureFile from "@shared/components/projects/projectDetail/LoanOptions/modal/CepSignatureFile";
import PepSignatureFile from "@shared/components/projects/projectDetail/LoanOptions/modal/PepSignatureFile";
import SignatureSignModal from "@shared/components/modal/signature/SignatureSignModal";
import SignatureShowModal from "@shared/components/modal/signature/SignatureShowModal";

export const MODAL_COMPONENTS = {
    [constants.REFILL_MODAL]: Refill,
    [constants.ADD_PROJECT]: AddProject,
    [constants.WEBCAM_MODAL]: WebCamModal,
    [constants.NOTICE_MODAL]: NoticeModal,
    [constants.ABOUT_TARIFFS]: AboutTariffs,
    [constants.QUALIFIED_FORM]: QualifiedForm,
    [constants.ADD_NEW_PROFILE]: AddNewProfile,
    [constants.CANCEL_PROJECT]: CancelProject,
    [constants.SIGNATURE_MODAL]: SignatureModal,
    [constants.SIGNATURE_SIGN_MODAL]: SignatureSignModal,
    [constants.SIGNATURE_SHOW_MODAL]: SignatureShowModal,
    [constants.PAYMENT_DETAILS]: PaymentDetails,
    [constants.EARLY_REPAYMENT]: EarlyRepayment,
    [constants.PLANNED_PAYMENT]: PlannedPayment,
    [constants.INVESTMENT_MODAL]: InvestmentModal,
    [constants.EDIT_AVATAR_MODAL]: EditAvatarModal,
    [constants.WITHDRAW_FUNDS_MODAL]: WithdrawFunds,
    [constants.SELECT_IMAGE_MODAL]: SelectImageModal,
    [constants.VIEW_FILE_DETAIL]: FileDetailModal,
    [constants.BAN_COMMENT_MODAL]: BanCommentModal,
    [constants.PLATFORM_SERVICES]: PlatformServices,
    [constants.CEP_SIGNATURE_FILE]: CepSignatureFile,
    [constants.PEP_SIGNATURE_FILE]: PepSignatureFile,
    [constants.MODAL_WITH_SETTING]: ModalWithSettings,
    [constants.MODAL_NOTIFICATION]: ModalNotification,
    [constants.PAYMENT_TYPES_MODAL]: PaymentTypesModal,
    [constants.DETAIL_AVATAR_MODAL]: DetailAvatarModal,
    [constants.QUALIFIED_NEW_CHECK]: QualifiedNewCheck,
    [constants.ADD_INNER_USER_MODAL]: AddInnerUserModal,
    [constants.AMOUNT_EXCEEDED_MODAL]: AmountExceededModal,
    [constants.QUALIFIED_APPLICATION]: QualifiedApplication,
    [constants.CAP_NOT_REQUIRED_MODAL]: CapNotRequiredModal,
    [constants.DESIGNATE_ACCOUNT_MAIN]: DesignateAccountMain,
    [constants.PAYMENT_SCHEDULE_MODAL]: PaymentScheduleModal,
    [constants.MODERATOR_ACC_NEW_CHECK]: ModeratorAccNewCheck,
    [constants.ACCREDITATION_UNFILLED]: AccreditationUnfilled,
    [constants.DELETE_INNER_USER_MODAL]: DeleteInnerUserModal,
    [constants.ACCREDITATION_PROFILE]: AccreditationProfileModal,
    [constants.ACCREDITATION_PHONE]: AccreditationPhoneModal,
    [constants.QUALIFIED_INVESTOR_DETAIL]: QualifiedInvestorDetail,
    [constants.INVESTMENTS_CODE_SMS_MODAL]: InvestmentCodeSmsModal,
    [constants.MODERATOR_PROJECT_REJECT]: ModerationProjectReject,
    [constants.VIEW_QUALIFIED_APPLICATION]: QualificationApplication,
    [constants.APPLICATION_QUALIFIED_ARCHIVE]: ApplicationArchiveModal,
    [constants.INVESTMENTS_PLATFORMS_MODAL]: InvestmentsPlatformsModal,
    [constants.CONFIRM_INVESTMENT_SMS_MODAL]: ConfirmInvestmentSmsModal,
    [constants.INVESTMENT_CONFIRM_QEP_MODAL]: InvestmentConfirmQepModal,
    [constants.MODERATOR_ACC_PROFILE_REJECT]: ModeratorAccProfileReject,
    [constants.PROFILE_IS_NOT_ACCREDITED_MODAL]: ProfileIsNotAccreditedModal,
    [constants.APPLICATION_QUALIFIED_INVESTOR]: ApplicationQualifiedInvestor,
    [constants.PRELIMINARY_PAYMENT_SCHEDULE_MODAL]: PreliminaryPaymentSchedule,
    [constants.QUALIFICATION_APPLICATION_REJECT]: QualificationApplicationReject,
    [constants.INNER_USERS_QUALIFIED_APPLICATION]: InnerUsersQualifiedApplication,
    [constants.INVESTMENTS_PLATFORMS_EDITING_MODAL]: InvestmentsPlatformsEditingModal,
    [constants.QUALIFICATION_REPORT_NON_COMPLIANCE]: QualificationReportNonCompliance,
    [constants.INVESTMENTS_PLATFORMS_EDITING_TWO_MODAL]: InvestmentsPlatformsEditingTwoModal
}

export const MODAL_CLASS_NAME = {
    [constants.WEBCAM_MODAL]: 'rootWebCamModal',
    [constants.REFILL_MODAL]: 'modalFullScreen',
    [constants.NOTICE_MODAL]: 'modalFullScreen',
    [constants.CANCEL_PROJECT]: 'modalFullScreen',
    [constants.PLANNED_PAYMENT]: 'modalFullScreen',
    [constants.ADD_NEW_PROFILE]: 'modalFullScreen',
    [constants.SIGNATURE_MODAL]: 'modalFullScreen',
    [constants.PAYMENT_DETAILS]: 'modalFullScreen',
    [constants.INVESTMENT_MODAL]: 'modalFullScreen',
    [constants.EARLY_REPAYMENT]: 'modalFullScreen',
    [constants.PLATFORM_SERVICES]: 'modalFullScreen',
    [constants.BAN_COMMENT_MODAL]: 'modalFullScreen',
    [constants.EDIT_AVATAR_MODAL]: 'editAvatarModal',
    [constants.SELECT_IMAGE_MODAL]: 'rootWebCamModal',
    [constants.MODAL_NOTIFICATION]: 'modalFullScreen',
    [constants.DETAIL_AVATAR_MODAL]: 'editAvatarModal',
    [constants.WITHDRAW_FUNDS_MODAL]: 'modalFullScreen',
    [constants.ACCREDITATION_PROFILE]: 'modalFullScreen',
    [constants.AMOUNT_EXCEEDED_MODAL]: 'modalFullScreen',
    [constants.ADD_INNER_USER_MODAL]: 'modalFullScreen',
    [constants.DESIGNATE_ACCOUNT_MAIN]: 'modalFullScreen',
    [constants.CAP_NOT_REQUIRED_MODAL]: 'modalFullScreen',
    [constants.ACCREDITATION_UNFILLED]: 'modalFullScreen',
    [constants.MODERATOR_ACC_NEW_CHECK]: 'modalFullScreen',
    [constants.SIGNATURE_FILE_MODAL]: 'signatureFileModal',
    [constants.CEP_SIGNATURE_FILE]: 'signatureFileModal',
    [constants.PEP_SIGNATURE_FILE]: 'signatureFileModal',
    [constants.MODERATOR_PROJECT_REJECT]: 'modalFullScreen',
    [constants.QUALIFIED_INVESTOR_DETAIL]: 'modalFullScreen',
    [constants.ADD_PROJECT]: 'modalFullScreen modalAddProject',
    [constants.INVESTMENTS_PLATFORMS_MODAL]: 'modalFullScreen',
    [constants.INVESTMENT_CONFIRM_QEP_MODAL]: 'modalFullScreen',
    [constants.CONFIRM_INVESTMENT_SMS_MODAL]: 'modalFullScreen',
    [constants.QUALIFIED_FORM]: 'modalFullScreen qualifiedForm',
    [constants.MODERATOR_ACC_PROFILE_REJECT]: 'modalFullScreen',
    [constants.INVESTMENTS_CODE_SMS_MODAL]: 'signatureFileModal',
    [constants.APPLICATION_QUALIFIED_INVESTOR]: 'modalFullScreen',
    [constants.PROFILE_IS_NOT_ACCREDITED_MODAL]: 'modalFullScreen',
    [constants.ABOUT_TARIFFS]: 'modalFullScreen aboutTariffsModal',
    [constants.QUALIFICATION_APPLICATION_REJECT]: 'modalFullScreen',
    [constants.QUALIFIED_APPLICATION]: 'modalFullScreen qualifiedForm',
    [constants.PRELIMINARY_PAYMENT_SCHEDULE_MODAL]: 'modalFullScreen',
    [constants.QUALIFICATION_REPORT_NON_COMPLIANCE]: 'modalFullScreen',
    [constants.INVESTMENTS_PLATFORMS_EDITING_MODAL]: 'modalFullScreen',
    [constants.MODAL_WITH_SETTING]: 'modalFullScreen modalWithSettings',
    [constants.PAYMENT_TYPES_MODAL]: 'modalFullScreen paymentTypesModal',
    [constants.VIEW_FILE_DETAIL]: 'modalFullScreen fileDetailModalWrap',
    [constants.INVESTMENTS_PLATFORMS_EDITING_TWO_MODAL]: 'modalFullScreen',
    [constants.PAYMENT_SCHEDULE_MODAL]: 'modalFullScreen paymentScheduleModal',
    [constants.DELETE_INNER_USER_MODAL]: 'modalFullScreen innerUserDelete__modal',
    [constants.VIEW_QUALIFIED_APPLICATION]: 'modalFullScreen qualifiedApplicationView',
    [constants.QUALIFIED_NEW_CHECK]: 'modalFullScreen qualifiedInvestor__modal_newCheck',
    [constants.APPLICATION_QUALIFIED_ARCHIVE]: 'modalFullScreen modalInnerUsersQualified',
    [constants.INNER_USERS_QUALIFIED_APPLICATION]: 'modalFullScreen modalInnerUsersQualified',
    [constants.SIGNATURE_SIGN_MODAL]: 'signatureSignModal',
    [constants.SIGNATURE_SHOW_MODAL]: 'signatureShowModal',
    [constants.ACCREDITATION_PHONE]: 'accreditationPhoneModal',
}

import React, {useEffect, useState} from 'react';
import Masonry from 'react-masonry-css'
import {useParams} from "react-router-dom";
import {QualificationsProfileService, QualificationsService} from "@services/qualifications.service";
import AdditionalInfo from "@shared/components/account/additionalInfo";
import QualificationsSelectedConditions
    from "@containers/Moderator/VerificationQualification/QualificationsSelectedConditions";
import QualificationControls from "@containers/Moderator/VerificationQualification/QualificationControls";
import SkeletonDataCard from "@shared/components/skeletons/SkeletonDataCard";
import PassportData from "@shared/components/presentation/PassportData";
import AccountPersonalData from "@shared/components/account/accountPersonalData";
import ApplicationArchive from "@shared/components/questionnaireProfile/view/ApplicationArchive";
import ApplicationData from "@shared/components/presentation/Qualification/ApplicationData";
import {getQualificationsRequestInclude} from "@containers/Moderator/VerificationQualification/helpers";

const VerificationQualification = () => {
    const {id} = useParams();
    const [qualificationData, setQualificationData] = useState(null);
    const [qualifications, setQualifications] = useState([]);

    const renderAdditionalInfo = () => {
        const addInfoForms = qualificationData?.profile?.legal_form?.questionnaire?.responses || [];

        return addInfoForms.map(item => {
            return <div key={item.id} className="profileTabs__addInfo_item accreditation__addInfo_item">
                <AdditionalInfo data={item}/>
            </div>
        })
    }

    const getQualificationData = () => (
        QualificationsService.getById(id, {include: getQualificationsRequestInclude.join(',')})
            .then(res => {
                setQualificationData(res?.data?.data || {});
                return res
            })
    )

    useEffect(() => {
        if (id) {
            getQualificationData()
                .then(res => {
                    QualificationsProfileService.getById(res?.data?.data?.profile?.id, {include: 'profile'})
                        .then(res => setQualifications(res?.data?.data || []))
                        .catch(error => console.log(error))
                })
                .catch(error => console.log(error))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return <div className="accreditation">
        <h1 className="page-title">Проверка заявлений о признании квал. инвесторами платформы</h1>
        <div className="accreditationProfile">
            {
                qualificationData
                    ? <div className="qualification__wrapper">
                        <ApplicationArchive data={qualifications} title="Архив заявлений"/>
                        <Masonry
                            breakpointCols={{default: 2, 1400: 1}}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            <ApplicationData
                                data={qualificationData}
                                getQualificationData={getQualificationData}
                                primaryCheck={true}
                            />
                            <AccountPersonalData
                                qualifiedApplication
                                forInnerUser
                                editForm={false}
                                profileData={qualificationData?.profile}
                                title='Персональные данные заявителя'
                            />
                            <QualificationsSelectedConditions data={qualificationData?.conditions?.conditions || []}/>
                            <PassportData data={qualificationData?.profile?.legal_form?.passport || {}}/>
                            {renderAdditionalInfo()}
                        </Masonry>
                    </div>
                    : <Masonry
                        breakpointCols={{default: 2, 1400: 1}}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        <SkeletonDataCard/>
                        <SkeletonDataCard/>
                        <SkeletonDataCard/>
                        <SkeletonDataCard/>
                    </Masonry>
            }
            <QualificationControls data={qualificationData}/>
        </div>
    </div>
}

export default VerificationQualification;
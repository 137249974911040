import {
    ADD_NEW_BANK_TEMPLATE,
    CHECK_ACCREDITATION,
    PROFILE_DATA_EDITING_STATUS,
    SET_BANK_DETAILS,
    SET_BANK_TEMPLATE_EDIT,
    SET_EDIT_PROFILE_ID,
    SET_PROFILE_DATA,
    SET_PROFILES
} from "@redux/actions/profilesActions";

const initialState = {
    profiles: [],
    profileData: {},
    editProfileId: '',
    editBankTemplate: '',
    addNewBankTemplate: false,
    checkAccreditation: false,
    profileDataEditingStatus: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CHECK_ACCREDITATION:
            return {
                ...state,
                checkAccreditation: action.payload
            }
        case SET_PROFILES:
            return {
                ...state,
                profiles: [...action.payload]
            }
        case SET_EDIT_PROFILE_ID:
            return {
                ...state,
                editProfileId: action.payload
            }
        case SET_PROFILE_DATA:
            return {
                ...state,
                profileData: {...action.payload}
            }
        case SET_BANK_DETAILS:
            return {
                ...state,
                profileData: {
                    ...state.profileData,
                    bank_details: action.payload
                }
            }
        case SET_BANK_TEMPLATE_EDIT:
            return {
                ...state,
                editBankTemplate: action.payload
            }
        case ADD_NEW_BANK_TEMPLATE:
            return {
                ...state,
                addNewBankTemplate: action.payload
            }
        case PROFILE_DATA_EDITING_STATUS:
            return {
                ...state,
                profileDataEditingStatus: !state.profileDataEditingStatus
            }
        default:
            return state;
    }
}

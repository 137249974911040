import React from 'react';
import formCheckIcon from "@shared/img/account/check.svg";

const RegisterPasswordFieldService = ({state, dispatch}) => {
    const {showPassword, successfulPassword} = state;

    return (
        <div className="register__fieldService">
            <button
                type="button"
                className={`input-blue-dark-forgot form__form-group-button${showPassword ? ' active' : ''}`}
                onClick={() => dispatch({name: 'showPassword', value: !showPassword})}
            >
                <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M23.5241 6.12422C23.4799 6.06389 22.418 4.62847 20.4886 3.19249C17.9245 1.2839 15.0217 0.275146 12.0939 0.275146C9.16608 0.275146 6.26319 1.2839 3.69907 3.19241C1.76959 4.62847 0.707496 6.06389 0.663308 6.12422C0.445564 6.42137 0.445564 6.82982 0.663308 7.12698C0.702109 7.17995 1.52359 8.28993 3.02593 9.53071L1.93339 11.7668C1.73173 12.1795 1.89521 12.6814 2.29861 12.8878C2.41579 12.9478 2.54032 12.9761 2.66305 12.9761C2.96253 12.9761 3.25086 12.8069 3.39388 12.5141L4.36424 10.5281C5.19532 11.0844 6.05755 11.5495 6.94133 11.9194L5.97487 13.8975C5.77321 14.3102 5.9367 14.8122 6.3401 15.0186C6.45728 15.0785 6.58188 15.1068 6.70454 15.1068C7.00402 15.1068 7.29242 14.9376 7.43545 14.6448L8.49965 12.4665C9.4131 12.7275 10.3425 12.89 11.2773 12.9498V14.8978C11.2773 15.3593 11.6429 15.7334 12.0938 15.7334C12.5447 15.7334 12.9103 15.3593 12.9103 14.8978V12.9498C13.8453 12.89 14.7745 12.7275 15.6879 12.4665L16.7521 14.6448C16.8952 14.9376 17.1835 15.1068 17.483 15.1068C17.6057 15.1068 17.7304 15.0785 17.8475 15.0186C18.2509 14.8122 18.4144 14.3102 18.2127 13.8975L17.2462 11.9194C18.1301 11.5495 18.9922 11.0844 19.8234 10.528L20.7937 12.5141C20.9367 12.8069 21.225 12.9761 21.5245 12.9761C21.6472 12.9761 21.7719 12.9478 21.889 12.8878C22.2924 12.6814 22.4558 12.1795 22.2542 11.7668L21.1617 9.53063C22.6641 8.28993 23.4854 7.17995 23.5242 7.12698C23.7418 6.82982 23.7418 6.42137 23.5241 6.12422ZM19.4846 8.73946C17.1803 10.4417 14.6938 11.3048 12.0939 11.3048C9.49382 11.3048 7.0073 10.4417 4.70292 8.73946C3.61787 7.93797 2.82934 7.12729 2.38566 6.6256C2.82958 6.12358 3.61803 5.31298 4.70292 4.51166C7.0073 2.80937 9.49382 1.94636 12.0939 1.94636C14.6938 1.94636 17.1803 2.80945 19.4846 4.51166C20.5696 5.31314 21.3581 6.12382 21.8018 6.6256C21.3579 7.12753 20.5695 7.93813 19.4846 8.73946Z"
                        fill={showPassword ? '#58bf91' : 'black'}
                    />
                </svg>
            </button>
            {
                successfulPassword
                    ? <div className="form__input-icon-wrap form__input-icon-check form__input-success-check">
                        <img src={formCheckIcon} alt="success"/>
                    </div>
                    : null
            }
        </div>
    )
};

export default RegisterPasswordFieldService;
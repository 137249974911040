export const SET_PLEDGE_ERROR = 'SET_PLEDGE_ERROR';

export const initPledgesState = (data = []) => {
    const validateFormat = {};
    const dataFormat = data.length ? data.map(item => ({
        id: item.id,
        collateral_value: item.collateral_value,
        description: item.description
    })) : [];

    data.length && data.forEach(item => validateFormat[item.id] = [])

    return {
        pledges: [...dataFormat],
        validate: {...validateFormat},
    }
}

export const pledgesReducer = (state, action) => {
    switch (action.type) {
        case SET_PLEDGE_ERROR:
            const validateCopy = {...state.validate};
            validateCopy[action.payload.id] = [...action.payload.error]

            return {
                ...state,
                validate: validateCopy
            }
        default:
            const newPledgesData = state.pledges.map(item => {
                if (item.id === action.id) {
                    return {...item, [action.name]: action.value}
                }

                return item;
            })

            return {
                ...state,
                pledges: [...newPledgesData]
            }
    }


}

import React, {useEffect, useState} from 'react';
import {SettingsProjectRateService} from "@services/settings.service";
import PlatformRatesForm from "./PlatformRatesForm";

const PlatformRates = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        SettingsProjectRateService.getList()
            .then(res => setData(res?.data?.data?.value))
            .catch(error => console.log(error))
    }, [])

    return data
        ? <PlatformRatesForm data={data}/>
        : null
}

export default PlatformRates;
import React from 'react';
import Select from 'react-select'
import {useDispatch} from "react-redux";
import {SET_PROPERTY_TYPE, SET_PROPERTY_VALUE} from "@actions/projectsActions";
import {propertySelectOptions} from '../../data';
import Apartment from './Apartment';
import House from './House';
import Land from './Land';
import Premise from './Premise';
import Building from './Building';

const Property = ({data, formError, setFormError, projectId}) => {
    const {id: pledgeId, property} = data || {};
    const {type_key} = property || {};
    const {key: value, value: label} = property?.type || {};
    const selectValue = {label, value};
    const dispatch = useDispatch();

    const handleOnChangeSelect = data => {
        const value = {key: data.value, value: data.label};

        dispatch({
            type: SET_PROPERTY_TYPE,
            payload: {pledgeId, value}
        })
    }

    const setData = event => {
        dispatch({
            type: SET_PROPERTY_VALUE,
            payload: {
                pledgeId,
                typeKey: type_key,
                name: event.target.name,
                value: event.target.value || null,
            }
        })
    }

    const renderPropertyForm = () => {
        switch (type_key) {
            case "apartment":
                return <Apartment
                    setData={setData}
                    pledgeId={pledgeId}
                    formError={formError}
                    data={property[type_key]}
                    setFormError={setFormError}
                    projectId={projectId}
                />
            case "house":
                return <House
                    setData={setData}
                    pledgeId={pledgeId}
                    formError={formError}
                    data={property}
                    setFormError={setFormError}
                    projectId={projectId}
                />
            case "land":
                return <Land
                    setData={setData}
                    pledgeId={pledgeId}
                    formError={formError}
                    data={property[type_key]}
                    setFormError={setFormError}
                    projectId={projectId}
                />
            case "premise":
                return <Premise
                    setData={setData}
                    pledgeId={pledgeId}
                    formError={formError}
                    data={property[type_key]}
                    setFormError={setFormError}
                    projectId={projectId}
                />
            case "building":
                return <Building
                    setData={setData}
                    pledgeId={pledgeId}
                    formError={formError}
                    data={property[type_key]}
                    setFormError={setFormError}
                    projectId={projectId}
                />
            default :
                return null
        }
    }

    return (
        <div className="pledge__form pledge__property">
            <div className="inputText__wrapper addProject__fullInput">
                <label>Наименование</label>
                <div className="pledge__formSelect">
                    <div className="pledge__formSelect_wrap">
                        <Select
                            options={propertySelectOptions}
                            value={selectValue}
                            isSearchable={false}
                            className="pledge__select"
                            classNamePrefix="pledge__select"
                            onChange={handleOnChangeSelect}
                        />
                    </div>
                </div>
            </div>

            {renderPropertyForm()}

        </div>
    )
}

export default Property;
import React from "react";

const RegisterFormBackBtn = ({dispatch, stateKey, parentKey}) => {
    const handleOnClick = e => {
        dispatch({name: stateKey, value: true})
        setTimeout(() => dispatch({name: stateKey, value: false}), 1000)
    }

    return !parentKey
        ? <button type="button" className="backBtn" onClick={handleOnClick}/>
        : null
}

export default RegisterFormBackBtn;
import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import MediaPresentation from "@shared/components/presentation/MediaPresentation";

const PledgesProjectMovable = ({data, idx, fRef}) => {
    const {
        name, brand_model, year, body, engine_volume, engine_type, chassis, body_color, engine_model_number,
        engine_power, vin, ts_mfr, pts_series_number, pts_date, pts_issued_by, pts_issued_address, pts_scans,
        sts_date, sts_series_number, sts_issued_by, sts_scans, gos_number, address, other_information, documents, images
    } = data.property;

    const dataFormat = [
        {name: 'ПТС:', type: 'subtitle'},
        {name: 'Наименование (тип ТС)', desc: name || ''},
        {name: 'Марка, модель ТС', desc: brand_model || ''},
        {name: 'Год изготовления ТС', desc: year || ''},
        {name: 'Кузов (кабина, прицеп)', desc: body || ''},
        {name: 'Рабочий объем двигателя, куб. см', desc: engine_volume || ''},
        {name: 'Тип двигателя', desc: engine_type || ''},
        {name: 'Шасси (рама)', desc: chassis || ''},
        {name: 'Цвет кузова (кабина, прицеп)', desc: body_color || ''},
        {name: 'Модель, номер двигателя', desc: engine_model_number || ''},
        {name: 'Мощность двигателя, л.с. (кВт)', desc: engine_power || ''},
        {name: 'Идентификационный номер (VIN)', desc: vin || ''},
        {name: 'Изготовитель ТС', desc: ts_mfr || ''},
        {name: 'Серия, номер ПТС', desc: pts_series_number || ''},
        {name: 'Дата выдачи ПТС', desc: pts_date || ''},
        {name: 'Наименование организации, выдавшей ПТС', desc: pts_issued_by || ''},
        {name: 'Адрес организации, выдавшей ПТС', desc: pts_issued_address || ''},
        {name: 'Скан ПТС', desc: pts_scans || '', type: 'file'},
        {name: 'СТС:', type: 'subtitle'},
        {name: 'Дата выдачи', desc: sts_date || ''},
        {name: 'Серия и номер', desc: sts_series_number || ''},
        {name: 'Кем выдан', desc: sts_issued_by || ''},
        {name: 'Скан СТС', desc: sts_scans || '', type: 'file'},
        {name: 'Госномер', desc: gos_number || ''},
        {name: 'Адрес расположения движимого имущества', desc: address || ''},
        {name: 'Иные сведения', desc: other_information || ''},
        {name: 'Иные документы', desc: documents || '', type: 'file'},
        {
            name: 'Фото залога',
            desc: images ? <MediaPresentation files={images} className="moderationProject__medias"/> : ''
        }
    ]

    return <DataCard data={dataFormat} title={`Залог ${idx + 1}`} fRef={fRef}/>
}

export default PledgesProjectMovable;

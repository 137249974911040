import {BanProfileService, UnbanProfileService, UsersBanService, UsersUnbanService} from "@services/ban.service";

export const BanService = {
    Profile: {
        true: UnbanProfileService,
        false: BanProfileService
    },
    User: {
        true: UsersUnbanService,
        false: UsersBanService
    }
}

export const modalDesc = {
    Profile: {
        title: {
            true: 'Разблокировка профиля',
            false: 'Блокировка профиля'
        },
        inputDesc: {
            true: 'Вы уверены,что хотите разблокировать профиль?',
            false: 'Основания блокировки профиля'
        },
        buttonDesc: {
            true: 'Разблокировать профиль',
            false: 'Заблокировать профиль'
        }
    },
    User: {
        title: {
            true: 'Разблокировка аккаунта',
            false: 'Блокировка аккаунта'
        },
        inputDesc: {
            true: 'Вы уверены,что хотите разблокировать аккаунт?',
            false: 'Основания блокировки аккаунта'
        },
        buttonDesc: {
            true: 'Разблокировать аккаунт',
            false: 'Заблокировать аккаунт'
        }
    }
}
import React, {useEffect} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import IconBtn from "@shared/components/form/IconBtn";
import {useDispatch, useSelector} from "react-redux";
import {
    EDIT_PROJECT_BUSINESSES,
    EDIT_PROJECT_BUSINESSES_OPEN,
    setProjectFormError,
    setProjectFormIsCompleted
} from "@actions/projectsActions";

const BusinessModel = ({fRef, data}) => {
    const dispatch = useDispatch();
    const {projectFormError: formError, formIsCompleted} = useSelector(state => state.projects);

    const setFormError = data => {
        setProjectFormError(data, dispatch)
    }

    const setData = event => {
        dispatch({
            type: EDIT_PROJECT_BUSINESSES,
            payload: {
                name: event.target.name,
                value: event.target.value || null,
            }
        })
    }

    const handleOnClickCollapseBtn = name => {
        dispatch({
            type: EDIT_PROJECT_BUSINESSES_OPEN,
            payload: name
        })
    }

    // Получить количество раскрытых элементов
    const getOpenInputCount = () => {
        let count = 0;
        data.forEach(item => item.value && ++count)

        return count;
    }

    // Проверяем заполнены ли все поля, если да, то активируем кнопку далее
    useEffect(() => {
        let status = true;

        for (let i = 0; i < data.length; ++i) {
            if ((data[i].open && !data[i].value) || getOpenInputCount() < 3) {
                status = false;
                break;
            }
        }

        if (status !== formIsCompleted.businessModel) setProjectFormIsCompleted("businessModel", status, dispatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    // Раскрыть поля у которых есть значение
    useEffect(() => {
        data.forEach((item, idx) => {
            if (item.value) handleOnClickCollapseBtn(idx);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderForm = () => {
        const items = [];

        if (data.length) {
            data.forEach((item, idx) => {
                if (item.open) items.push(
                    <InputText
                        formError={formError}
                        key={idx}
                        name={idx}
                        rows={2}
                        onChange={setData}
                        label={item.label}
                        setFormError={setFormError}
                        type="textarea"
                        value={item.value || ''}
                        validator={[{maxLength: 500}, {required: true}]}
                        labelClass="requiredMark"
                        containerClassName="addProject__fullInput"
                    />
                )
            })

            data.forEach((item, idx) => {
                if (!item.open) items.push(
                    <IconBtn
                        key={idx}
                        title={item.label}
                        onClick={() => handleOnClickCollapseBtn(idx)}
                        className={`yourOption__addBtn relatedCompanies__addBtn ${idx === 0 ? 'iconBtn__first' : ''}`}
                    />
                )
            })

            return items;

        }
    }

    return (
        <div className="addProject__form addProject__businesses addProject__form-investments" ref={fRef}>
            <p className="addProject__form_title">
                Бизнес-модель проекта
                <span> (заполните не менее 3-х пунктов)</span>
            </p>
            <div className="addProject__aboutProject">
                {renderForm()}
            </div>
        </div>
    )
}

export default BusinessModel;
import React from 'react';
import {Link} from "react-router-dom";
import {showModal} from "@actions/modalActions";
import {SIGNATURE_MODAL, VIEW_FILE_DETAIL} from "@shared/components/modal/constants";

export const yourDocumentsTabs = [
    {id: 0, key: 'documentsForSignature', title: "Документы на подпись", type: 'signature'},
    {id: 1, key: 'projectDocuments', title: "Документы по проектам", type: 'project'},
    {id: 2, key: 'profileDocuments', title: "Документы профиля", type: 'profile'}
];

export const yourDocumentsTableHeader = {
    documentsForSignature: [
        {id: 0, title: 'Проект'},
        {id: 1, title: 'Документ'},
        {id: 2, title: 'Дата документа'},
        {id: 3, title: 'Срок подписания'},
        {id: 4, title: ''},
    ],
    projectDocuments: [
        {id: 0, title: 'Проект'},
        {id: 1, title: 'Документ'},
        {id: 2, title: 'Дата документа'},
        {id: 3, title: 'Дата подписания/акцепта'},
        {id: 4, title: ''},
    ],
    profileDocuments: [
        {id: 0, title: 'Профиль'},
        {id: 1, title: 'Документ'},
        {id: 2, title: 'Дата подписания/акцепта'},
        {id: 4, title: ''},
    ],
}

export const documentsDataFormat = (data, activeTab, dispatch) => {
    const goToSigning = fileID => {
        showModal(SIGNATURE_MODAL, fileID)(dispatch)
    }

    const viewDetail = fileData => {
        showModal(VIEW_FILE_DETAIL, fileData)(dispatch)
    }

    const openSignatureFile = fileID => {
        showModal(SIGNATURE_MODAL, fileID)(dispatch)
    }

    switch (activeTab) {
        case yourDocumentsTabs[0].key:
            return data.map(item => {
                return [
                    <>
                        <span className="green-color">
                            №<Link className="green-color" to={"project-detail/" + item.id}>{item.id}</Link>&nbsp;
                        </span>
                        {item.project.title}
                    </>,
                    <button
                        className="green-color btn-reset yourDocuments__fileBtn"
                        onClick={() => viewDetail(item)}
                    >
                        {`${item.name}.${item.extension}`}
                    </button>,
                    item.created_at,
                    '-',
                    <button
                        className="green-color btn-reset yourDocuments__signingBtn"
                        onClick={() => goToSigning(item.id)}
                    >
                        Перейти к подписанию
                    </button>
                ]
            })
        case yourDocumentsTabs[1].key:
            return data.map(item => {
                return [
                    <>
                        <span className="green-color">
                            №<Link className="green-color" to="/documents">{item.id}</Link>&nbsp;
                        </span>
                        {item.project.title}
                    </>,
                    <button
                        className="green-color btn-reset yourDocuments__fileBtn"
                        onClick={() => viewDetail(item)}
                    >
                        {`${item.name}.${item.extension}`}
                    </button>,
                    item.created_at,
                    '-',
                    <button
                        className="green-color btn-reset yourDocuments__signingBtn"
                        onClick={() => openSignatureFile(item.id)}
                    >
                        Файл подписи
                    </button>
                ]
            })
        case yourDocumentsTabs[2].key:
            return data.map(item => {
                return [
                    `№${item.id} ${item.profile.legal_form_type === 'entity'
                        ? item.profile.legal_form?.company?.name
                        : item.profile.legal_form.personal.fio}`,
                    <button className="green-color btn-reset yourDocuments__fileBtn" onClick={() => viewDetail(item)}>
                        {`${item.name}.${item.extension}`}
                    </button>,
                    '-',
                    <button
                        className="green-color btn-reset yourDocuments__signingBtn"
                        onClick={() => openSignatureFile(item.id)}
                    >
                        Файл подписи
                    </button>
                ]
            })
        default:
            return []
    }
}
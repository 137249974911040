import React from 'react';
import {Button} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {QUALIFIED_FORM} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const ProjectNotification = ({error, title, desc, qualification}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const ProjectNotificationControls = () => {
        return qualification
            ? <div className="projectNotification__controls_qualification">
                <Button color="primary" size="lg" onClick={() => showModal(QUALIFIED_FORM)(dispatch)}>
                    Подтвердить статус
                </Button>
                <Button color="success" size="lg" outline onClick={() => history.push("/account")}>
                    Перейти к профилю
                </Button>

            </div>
            : <div className="projectNotification__controls">
                <Button color="primary" size="lg" onClick={() => history.push("/account")}>
                    Перейти к профилю
                </Button>
            </div>
    }
    return (
        <div className="projectNotification">
            {
                error
                    ? <div className="projectNotification__error">
                        К сожалению, ваш профиль отклонен. Доступ к сервисам платформы ограничен.
                    </div>
                    : <>
                        <div className="projectNotification__desc">
                            <div className="projectNotification__title">{title}</div>
                            <div className="projectNotification__text">{desc}</div>
                        </div>
                        <ProjectNotificationControls/>
                    </>
            }
        </div>
    )
};

export default ProjectNotification;
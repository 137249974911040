import React from 'react';
import {Link} from 'react-router-dom';
import {legalFormTypeDesc} from "@utils/auth";
import {RightLongArrowIcon} from '@shared/img/iconSvg';
import {useDispatch} from "react-redux";
import {SET_ACCREDITATION_PROFILE_ID} from "@actions/accreditationAction";

const AccreditationUserListTableItem = ({item, accType}) => {
    const dispatch = useDispatch();
    const {id, profile, created_at} = item;
    const link = accType === 'qualificationsInvestor'
        ? `/qualification/${id}`
        : `/accreditation/${id}`

    const setAccreditationProfileId = () => dispatch({
        type: SET_ACCREDITATION_PROFILE_ID,
        payload: id
    })

    return <tr>
        <td>{profile?.fio || ''}</td>
        {accType !== 'qualificationsInvestor' && <td>{legalFormTypeDesc[profile?.legal_form_type]}</td>}
        <td>{profile?.id || ''}</td>
        <td>{profile?.user?.email || ''}</td>
        <td>{created_at || ''}</td>
        <td>
            <div className="accreditationTable__btn">
                <Link onClick={setAccreditationProfileId} to={link}>В работу</Link>
                <div className="accreditationTable__btnIcon">
                    <RightLongArrowIcon/>
                </div>
            </div>
        </td>
    </tr>
}

export default AccreditationUserListTableItem;
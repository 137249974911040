import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';


const SkeletonElement = ({type, className}) => {
    const classes = classnames({
        skeleton: true,
        [type]: type,
        [className]: className
    })

    return (
        <div className={classes}/>
    )
}

SkeletonElement.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
};

SkeletonElement.defaultProps = {
    type: 'text', // text, title, avatar, thumbnail
    className: '',
};

export default SkeletonElement;
import React from 'react';

const BaseTableBody = ({data = []}) => {
    return data?.length
        ? <tbody>
        {
            data.map((item, idx) => (
                <tr key={idx}>
                    {
                        item.map((elem, idx) => <td key={idx}>{elem}</td>)
                    }
                </tr>
            ))
        }
        </tbody>
        : null
};

export default BaseTableBody;
import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import MediaPresentation from "@shared/components/presentation/MediaPresentation";
import VideoPresentation from "@shared/components/presentation/VideoPresentation";

const AboutProject = React.memo(({data, fRef}) => {
    const {
        description = '',
        stage = {},
        foundation_year = '',
        primary_occupation = '',
        //site = '',
        //logo = [],
        medias = [],
        videos
    } = data || {};

    const dataFormat = [
        {name: 'Описание проекта', desc: description},
        {name: 'Перечень приобретаемого имущества', desc: primary_occupation},
        {name: 'Медиа', desc: <MediaPresentation files={medias} className="moderationProject__medias"/>},
        {name: 'Видео', desc: <VideoPresentation videos={videos} className="moderationProject__video"/>},
        {name: 'Стадия проекта', desc: stage.value || ''},
        {name: 'Дополнительные данные', type: 'subtitle'},
        {
            name: 'Информация о целях привлечения инвестиций и об основных рисках, связанных с лицом, привлекающим инвестиции',
            desc: foundation_year
        },
        {
            name: 'Сведения о фактах, которые могут оказать существенное влияние на исполнение обязательств перед Инвесторами',
            desc: foundation_year
        },
        {
            name: 'Имеется ли у клиента дополнительный источник доходов ',
            desc: foundation_year
        },
        {
            name: 'Имеется ли у клиента движимое или недвижимое имущество в собственности',
            desc: foundation_year
        },
    ]

    return <DataCard data={dataFormat} title="О проекте" fRef={fRef}/>
})

export default AboutProject;

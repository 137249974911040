import React from 'react';

const AccreditationUnfilled = () => {
    return (
        <div>
            <p className="modalFullScreen__title">
                Для отправки профиля на аккредитацию необходимо полностью заполнить раздел «Данные профиля»
            </p>
        </div>
    )
}

export default AccreditationUnfilled;
export const constants = {
    EDIT_FORM_VALUE: 'EDIT_FORM_VALUE',
    SET_SELECT_VALUE: 'SET_SELECT_VALUE',
    SET_FORM_ERROR: 'SET_FORM_ERROR',
    CLEAR_STATE: 'CLEAR_STATE',
    RESET_VALIDATE: 'RESET_VALIDATE',
    EDIT: 'EDIT'
}

export const roleSelectData = [
    {value: 'moderator', label: 'Модератор'},
    {value: 'admin', label: 'Администратор'},
]

export const roleKeySelectData = {
    moderator: {value: 'moderator', label: 'Модератор'},
    admin: {value: 'admin', label: 'Администратор'},
}

export const initState = initData => {
    const {email = "", phone = "", fio = "", other_information = "", roles = [], password = "", formType} = initData;
    const data = {
        email,
        phone,
        fio,
        other_information,
        roles: roles.length ? roles.map(item => item.name) : [roleSelectData[0].value]
    };

    if (formType !== 'update') data.password = password

    return {
        data,
        oldData: {...data},
        formError: [],
        requestErrors: null,
        resetValidate: false,
        roles: roles.length ? roles.map(item => roleKeySelectData[item.name]) : [roleSelectData[0]],
        visiblePassValidInfo: false, // Показать || скрыть требования к паролю
        passValidStatus: false, // Вадидность пароля,
        showPassError: false,
        userAddStatus: false,
    }
}

export const innerUserFormReducer = (state, action) => {
    const {type, payload} = action || {};

    switch (type) {
        case constants.EDIT:
            return {
                ...state,
                [payload.key]: payload.value
            }
        case constants.SET_FORM_ERROR:
            return {
                ...state,
                formError: [...action.payload]
            }
        case constants.EDIT_FORM_VALUE:
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.name]: payload.value
                }
            }
        case constants.SET_SELECT_VALUE:
            return {
                ...state,
                roles: payload,
                data: {
                    ...state.data,
                    roles: payload.map(item => item.value)
                }
            }
        case constants.RESET_VALIDATE:
            return {
                ...state,
                resetValidate: !state.resetValidate
            }
        case constants.CLEAR_STATE:
            return {...initState}
        default: return state
    }
}

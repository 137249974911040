import React from 'react';
import Notification from "@shared/components/projects/projectDetail/ProjectInvest/modal/notification";
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";
import Reminder from "@shared/components/projects/projectDetail/ProjectInvest/modal/reminder";
import {showModal} from "@actions/modalActions";
import {INVESTMENTS_PLATFORMS_MODAL, PRELIMINARY_PAYMENT_SCHEDULE_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";

const AmountExceededModal = () => {
    const dispatch = useDispatch();

    return (
        <div className='amountExceededModal investmentModal'>
            <Notification desc={
                'Для того, чтобы инвестировать от имени другого профиля, выберете другой профиль в боковом меню'
            }/>
            <BackButton/>
            <span className='investmentModal__title'>Инвестирование в проект №***</span>
            <div className='investmentModal__subtitle'>
                <span>Наличие КЭП для инвестирования: </span>
                <span className='investmentModal__descGreen'>Не требуется</span>
                <Reminder/>
            </div>
            <div className='investmentModal__list'>
                <div className='investmentModal__list_desc'>
                    <span className='investmentModal__listDesc_name investmentModal__greyBlue'>На вашем счете</span>
                    <span>12 578,09 ₽</span>
                </div>
                <div className='investmentModal__list_desc'>
                    <div className='investmentModal__listDesc_reminder'>
                        <span className='investmentModal__listDesc_name investmentModal__greyBlue'>
                            Вы можете инвестировать до
                            <Reminder/>
                        </span>
                    </div>
                    <span className='investmentModal__listDesc_desc'>12 578,09 ₽</span>
                </div>
                <div className='investmentModal__list_desc'>
                    <span className='investmentModal__listDesc_name investmentModal__descGreen'>
                        Вы инвестировали в этот проект
                    </span>
                    <span className='investmentModal__descGreen'>5 000 ₽</span>
                </div>
            </div>
            <div className='amountExceededModal__blockInfo'>
                <span className='amountExceededModal__blockInfo_desc'>
                    Сумма ваших инвестиций за ГГГГ календарный год совокупно на всех инвестиционных платформах
                    достигла 600 тыс. руб. <br/>Возможность инвестировать ограничена до 01.01.****г. <br/>
                    Для снятия данного ограничения Вам необходимо подтвердить статус квалифицированного инвестора в
                </span>
                <span className='investmentModal__descGreen textLine'> Профиле</span>
            </div>
            <div className='investmentModal__platforms'>
                <span
                    className='investmentModal__descGreen textLine'
                    onClick={() => showModal(INVESTMENTS_PLATFORMS_MODAL)(dispatch)}
                >
                    Инвестиции на других платформах
                </span>
                <span
                    className='investmentModal__descGreen textLine'
                    onClick={() => showModal(PRELIMINARY_PAYMENT_SCHEDULE_MODAL)(dispatch)}
                >
                    Предварительный график
                </span>
            </div>
        </div>
    )
};

export default AmountExceededModal;
import React, {useEffect} from 'react';
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import {emailRegex} from '@shared/components/form/validate';
import formCheckIcon from "@shared/img/account/check.svg";

const FormEmail = ({
                       setDisabledBtn = () => {
                       }, value = '', setValue, emailValidate = false, setEmailValidate, onKeyPress
                   }) => {
    // Обработка ввода в поле "Email"
    const handleChangeEmail = e => {
        if (e.target.value.length < 255) setValue(e.target.value.trim()) // Ограничить длину до 255 символов
    }

    useEffect(() => {
        // Валидация почты
        if (emailRegex.test(value)) {
            setEmailValidate && setEmailValidate(true)
            setDisabledBtn && setDisabledBtn(false)
        } else {
            setEmailValidate && setEmailValidate(false)
            setDisabledBtn && setDisabledBtn(true)
        }
    }, [value, setEmailValidate, setDisabledBtn])

    return (
        <>
            <span className="form-label">Email</span>
            <div className="form__form-group-field">
                <div className="form__form-group-icon">
                    <AlternateEmailIcon/>
                </div>
                <input
                    type="text"
                    name="reset_email"
                    value={value}
                    className="account-input"
                    onChange={handleChangeEmail}
                    placeholder="exaple@gmail.com"
                    onKeyPress={onKeyPress ? onKeyPress : null}
                />
                {
                    // Показать галочку
                    emailValidate
                        ? <div className="form__input-icon-wrap form__input-icon-check">
                            <img src={formCheckIcon} alt="valid"/>
                        </div>
                        : null
                }
            </div>
        </>
    )
}

export default FormEmail
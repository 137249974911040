import React from 'react';
import {Table} from 'reactstrap';
import PrimaryTooltip from "@shared/components/tooltip";
import {calculationsData, statusData} from "@shared/components/projects/projectDetail/ProjectCalculations/helpers";
import DetailedCalculations from "@shared/components/projects/projectDetail/ProjectCalculations/DetailedCalculations";
import CalculationsStatus from "@shared/components/projects/projectDetail/ProjectCalculations/CalculationsStatus";
import {showModal} from "@actions/modalActions";
import {EARLY_REPAYMENT, PLANNED_PAYMENT} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";

const ProjectCalculations = ({data}) => {
    const dispatch = useDispatch();

    return (
        <div className='projectCalculations'>
            <Table>
                <thead>
                <tr className='projectCalculations__header'>
                    <th>Плановая дата</th>
                    <th className='projectCalculations__header_plannedPayment'>
                        Плановый платеж
                        <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                            <p>
                                1. Проект перешел к стадии подписания документов.
                                2. Проект перешел к стадии выплат
                                3. Проект перешел в просрочку
                                4. Проект завершен
                                5. Проект отменен
                                6. Проект не состоялся
                            </p>
                        </PrimaryTooltip>
                    </th>
                    <th>Основной долг</th>
                    <th>Проценты</th>
                    <th className='projectCalculations__header_income'>
                        НДФЛ
                        <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                            <p>
                                1. Проект перешел к стадии подписания документов.
                                2. Проект перешел к стадии выплат
                                3. Проект перешел в просрочку
                                4. Проект завершен
                                5. Проект отменен
                                6. Проект не состоялся
                            </p>
                        </PrimaryTooltip>
                    </th>
                    <th>Фактическая сумма <br/>платежа</th>
                    <th>Количество дней просрочки</th>
                    <th>Статус</th>
                </tr>
                </thead>
                <tbody className='projectCalculations__table'>
                {
                    calculationsData.map((elem, idx) => {
                        const status = statusData.filter(item => item.key === elem.status)[0];

                        return (
                            <tr key={idx} className='projectCalculations__desc'>
                                <td
                                    colSpan={
                                        status.key === 'partialEarlyRepayment' || status.key === 'fullEarlyRepayment'
                                            ? 5
                                            : null
                                    }
                                >
                                    {elem.plannedDate}
                                </td>
                                {
                                    status.key === 'partialEarlyRepayment' || status.key === 'fullEarlyRepayment'
                                        ? null
                                        : <>
                                            <td>{elem.plannedPayment}</td>
                                            <td>{elem.mainDebt}</td>
                                            <td>{elem.interest}</td>
                                            <td>{elem.income}</td>
                                        </>
                                }
                                <td>{elem.actualAmount}</td>
                                <td>{elem.daysOfDelay}</td>
                                <td
                                    onClick={() =>
                                        status.key === 'partialEarlyRepayment' || status.key === 'fullEarlyRepayment'
                                            ? showModal(EARLY_REPAYMENT)(dispatch)
                                            : showModal(PLANNED_PAYMENT)(dispatch)
                                    }
                                    className={
                                        status.key === 'partialEarlyRepayment' || status.key === 'fullEarlyRepayment'
                                            ? 'projectCalculations__desc_status' : null
                                    }>
                                    <CalculationsStatus status={status}/>
                                    {status.desc}
                                </td>
                            </tr
                            >
                        )
                    })
                }
                </tbody>
            </Table>
            {data.profile.user.roles[0].name === 'borrower' ? <DetailedCalculations data={data}/> : null}
        </div>
    )
};

export default ProjectCalculations;
import React from 'react';
import {BankDetailsRender} from "@shared/components/account/bankDetails/helpers";
import {addProjectMenu} from "@containers/Projects/AddProject/helpers";
import {Link} from "react-router-dom";
import {WarningIcon} from "@shared/img/iconSvg";
import CreditHistoryCheck from "@shared/components/account/creditHistoryCheck";
import {useSelector} from "react-redux";
import PlatformServices from "@shared/components/presentation/PlatformServices/PlatformServices";

const BankDetails = ({step}) => {
    const {bank_detail, pledges} = useSelector(state => state.projects.projectEditData);

    return (
        <>
            {
                bank_detail
                    ? <BankDetailsRender
                        data={bank_detail}
                        notEditable={true}
                        className="addProject__score"
                        onlyBankName={true}
                        fRef={addProjectMenu[step].links[0].ref}
                        hideMasterAccountNotice={true}
                    />
                    : ''
            }
            <div className="addProject__bankDetails_group">
                <div className="addProject__infoText addProject__infoText_success">
                    <p>Внести изменения в счет или выбрать другой счет в качестве основного можно в разделе <Link
                        to="/account">Аккаунт</Link></p>
                </div>
                <div className="addProject__infoText addProject__infoText_error">
                    <div className="addProject__infoText_icon">
                        <WarningIcon/>
                    </div>
                    <p>Важно! Не допускается использование банковских счетов третьих лиц</p>
                </div>
            </div>
            <CreditHistoryCheck fRef={addProjectMenu[step].links[1].ref}/>
            <PlatformServices pledges={pledges} fRef={addProjectMenu[step].links[2].ref}/>
        </>
    )
}

export default BankDetails;
import React from 'react';
import {useDispatch} from "react-redux";
import useWindowSize from "@hooks/useWindowSize";
import SidebarLink from '@layout/sidebar/SidebarLink';
import {changeMobileSidebarVisibility} from "@redux/actions/sidebarActions";
import {
    accountIcon,
    AccreditationIcon,
    AnalyticsIcon,
    documentsIcon,
    homeIcon,
    ModerationIcon,
    operationsHistoryIcon,
    portfolioIcon,
    projectsIcon,
    RegistriesIcon,
    SDLIcon
} from '@shared/img/iconSvg';

const SidebarContent = () => {
    const dispatch = useDispatch();
    const [width = 769] = useWindowSize();
    const hideSidebar = () => width <= 768 ? dispatch(changeMobileSidebarVisibility()) : null;

    return (
        <div className="sidebar__content">
            <ul className="sidebar__block">
                <SidebarLink
                    auth
                    exact
                    route="/"
                    title="Аккредитация и квалификация"
                    icon={AccreditationIcon}
                    onClick={hideSidebar}
                    permissions={['moderator']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/"
                    title="Раздел администратора"
                    icon={homeIcon}
                    onClick={hideSidebar}
                    permissions={['admin']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/"
                    title="Лента проектов"
                    icon={projectsIcon}
                    onClick={hideSidebar}
                    permissions={['borrower']}
                />
                <SidebarLink
                    auth
                    route="/registry"
                    title="Реестры"
                    icon={RegistriesIcon}
                    onClick={hideSidebar}
                    permissions={['moderator', 'admin']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/accreditation_qualifications"
                    title="Аккредитация и квалификация"
                    icon={AccreditationIcon}
                    onClick={hideSidebar}
                    permissions={['admin']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/moderation_projects"
                    title="Модерация проектов"
                    icon={ModerationIcon}
                    onClick={hideSidebar}
                    permissions={['moderator', 'admin']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/analytics_section"
                    title="Раздел аналитика"
                    icon={AnalyticsIcon}
                    onClick={hideSidebar}
                    permissions={['admin']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/sdl_section"
                    title="Раздел СДЛ"
                    icon={SDLIcon}
                    onClick={hideSidebar}
                    permissions={['admin']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/projects_feed"
                    title="Лента проектов"
                    icon={projectsIcon}
                    onClick={hideSidebar}
                    permissions={['moderator', 'admin']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/"
                    title="Инвестировать"
                    icon={projectsIcon}
                    onClick={hideSidebar}
                    permissions={['investor']}
                />
                <SidebarLink
                    auth
                    route="/portfolio"
                    title="Ваш портфель"
                    icon={portfolioIcon}
                    onClick={hideSidebar}
                    permissions={['borrower', 'investor']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/operations_history"
                    title="История операций"
                    icon={operationsHistoryIcon}
                    onClick={hideSidebar}
                    permissions={['borrower', 'investor']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/analytics_investor"
                    title="Аналитика"
                    icon={AnalyticsIcon}
                    onClick={hideSidebar}
                    permissions={['investor']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/account"
                    title="Профиль"
                    icon={accountIcon}
                    onClick={hideSidebar}
                    permissions={['borrower', 'investor']}
                />
                <SidebarLink
                    auth
                    exact
                    route="/documents"
                    title="Ваши документы"
                    icon={documentsIcon}
                    onClick={hideSidebar}
                    permissions={['borrower', 'investor']}
                />
            </ul>
        </div>
    );
}

export default SidebarContent;

import React from 'react';
import {SET_PLEDGE_TYPE} from '@redux/actions/projectsActions';
import {useDispatch} from "react-redux";

const PledgeSelect = ({selectValue, pledgeId}) => {
    const dispatch = useDispatch();

    const options = [
        {label: "Недвижимость", value: "property"},
        {label: "Движимое имущество", value: "movable"},
        {label: "Права требования по ДДУ", value: "ddu"},
        {label: "Иное имущество", value: "other"},
    ]

    const setPropertyType = type => {
        dispatch({
            type: SET_PLEDGE_TYPE,
            payload: {pledgeId, type}
        })
    }

    return (
        <div className="pledge__form pledge__form_mainSelect">
            <div className="securityForm__mainSelect">
                <div className="formEdit__selectGroup">
                    {
                        options.map(elem => <button
                            key={`${elem.value}`}
                            className={`from__custom-radio-btn ${selectValue === elem.value ? 'active' : ''}`}
                            onClick={() => setPropertyType(elem.value)}
                        >
                            <span>{elem.label}</span>
                        </button>)
                    }
                </div>
            </div>
        </div>
    )
}

export default PledgeSelect;
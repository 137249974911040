import React, {useEffect} from 'react';
import AccStatusNotice from '@shared/components/account/accStatusNotice';
import ProfileTabs from './components/ProfileTabs';
import {getProfileData} from "@actions/profilesActions";
import {useDispatch, useSelector} from "react-redux";

const Profile = () => {
    const dispatch = useDispatch();
    const {profileData = {}, checkAccreditation, profileDataEditingStatus} = useSelector(({profiles}) => profiles);
    const profileId = useSelector(({user}) => user.profile.id);

    useEffect(() => {
        if (profileId){
            getProfileData()(dispatch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileId])


    return (
        <div className="profile">
            <h1 className="page-title">Аккаунт</h1>
            <div className="profile__content">
                <div className="profile__group accountContent">
                    <div className="accountContent__wrap">
                        <AccStatusNotice
                            profileData={profileData}
                            checkAccreditation={checkAccreditation}
                            profileDataEditingStatus={profileDataEditingStatus}
                        />
                    </div>
                    <ProfileTabs
                        profileData={profileData}
                        checkAccreditation={checkAccreditation}
                        profileDataEditingStatus={profileDataEditingStatus}
                    />
                </div>
            </div>
        </div>
    )
}

export default Profile;
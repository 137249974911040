import React from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {CalendarIcon} from "@shared/img/iconSvg";
import UploadField from "@shared/components/uploadField";

const Movable = ({data, setData, formError, setFormError, projectId}) => {
    const {
        name,
        brand_model,
        year,
        body,
        engine_volume,
        engine_type,
        chassis,
        body_color,
        engine_model_number,
        engine_power,
        vin,
        ts_mfr,
        pts_date,
        pts_series_number,
        pts_issued_by,
        pts_issued_address,
        sts_date,
        documents,
        pts_scans,
        sts_scans,
        images,
        sts_series_number,
        sts_issued_by,
        gos_number,
        address,
        other_information
    } = data;


    return (
        <div className="pledge__forms">
            <p className="addProject__form_title">ПТС:</p>
            <InputText
                name="name"
                label="Наименование (тип ТС)"
                formError={formError}
                id="nameInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={name || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                name="brand_model"
                label="Марка, модель ТС"
                formError={formError}
                id="brandModelInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={brand_model || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                name="year"
                label="Год изготовления ТС"
                formError={formError}
                id="yearInput"
                onChange={setData}
                setFormError={setFormError}
                type="mask"
                value={year || ''}
                guide={false}
                validator={[{minNumber: 1700}, {maxLength: 4}, {onlyNumbers: true}, {required: true}]}
                mask={[/\d/, /\d/, /\d/, /\d/]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__smallInput"
            />
            <InputText
                name="body"
                label="Кузов (кабина, прицеп)"
                formError={formError}
                id="bodyInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={body || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="engine_volume"
                label="Рабочий объем двигателя, куб. см"
                formError={formError}
                id="engineVolumeInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={engine_volume || ''}
                validator={[{maxNumber: 10}, {onlyNumbers: true}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__smallInput"
            />
            <InputText
                name="engine_type"
                label="Тип двигателя"
                formError={formError}
                id="engineTypeInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={engine_type || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="chassis"
                label="Шасси (рама)"
                formError={formError}
                id="chassisInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={chassis || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="body_color"
                label="Цвет кузова (кабина, прицеп)"
                formError={formError}
                id="bodyColorInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={body_color || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="engine_model_number"
                label="Модель, номер двигателя"
                formError={formError}
                id="engineModelNumberInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={engine_model_number || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="engine_power"
                label="Мощность двигателя, л.с. (кВт)"
                formError={formError}
                id="enginePowerInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={engine_power || ''}
                validator={[{maxLength: 10}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__smallInput"
            />
            <InputText
                name="vin"
                label="Идентификационный номер (VIN)"
                formError={formError}
                id="vinInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={vin || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="ts_mfr"
                label="Изготовитель ТС"
                formError={formError}
                id="tsMfrInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={ts_mfr || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                name="pts_series_number"
                label="Серия, номер ПТС"
                formError={formError}
                id="ptsSeriesNumberInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={pts_series_number || ''}
                validator={[{maxLength: 10}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__smallInput"
            />
            <InputText
                name="pts_date"
                label="Дата выдачи ПТС"
                id="ptsDateInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                type="date"
                value={pts_date || ''}
                validator={[{required: true}]}
                inputWithDesc
                inputWithDescValue={<CalendarIcon/>}
                containerClassName="addProject__middleInput addProject__dateInput date-picker"
            />
            <InputText
                name="pts_issued_by"
                label="Наименование организации, выдавшей ПТС"
                formError={formError}
                id="ptsIssuedByInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={pts_issued_by || ''}
                validator={[{maxLength: 200}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                name="pts_issued_address"
                label="Адрес организации, выдавшей ПТС"
                formError={formError}
                id="ptsIssuedAddressInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={pts_issued_address || ''}
                validator={[{maxLength: 200}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Скан ПТС</label>
                <UploadField
                    files={pts_scans}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан ПТС"
                    onChange={setData}
                    fileKey="pts_scans"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                    required={true}
                />
            </div>
            <p className="addProject__form_title">СТС (при наличии):</p>
            <InputText
                name="sts_date"
                label="Дата выдачи"
                id="stsDateInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                type="date"
                value={sts_date || ''}
                inputWithDesc
                inputWithDescValue={<CalendarIcon/>}
                containerClassName="addProject__middleInput addProject__dateInput date-picker"
            />
            <InputText
                name="sts_series_number"
                label="Серия и номер"
                formError={formError}
                id="stsSeriesNumberInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={sts_series_number || ''}
                validator={[{maxLength: 10}]}
                inputWrap={true}
                containerClassName="addProject__smallInput"
            />
            <InputText
                name="sts_issued_by"
                label="Кем выдан"
                formError={formError}
                id="stsIssuedByInput"
                onChange={setData}
                setFormError={setFormError}
                type="text"
                value={sts_issued_by || ''}
                validator={[{maxLength: 100}]}
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Скан СТС</label>
                <UploadField
                    files={sts_scans}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан СТС"
                    onChange={setData}
                    fileKey="sts_scans"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <div className="pledge__forms_group">
                <InputText
                    name="gos_number"
                    label="Госномер"
                    formError={formError}
                    id="gosNumberInput"
                    onChange={setData}
                    setFormError={setFormError}
                    type="text"
                    value={gos_number || ''}
                    validator={[{maxLength: 10}, {required: true}]}
                    labelClass="requiredMark"
                    inputWrap={true}
                    containerClassName="addProject__smallInput"
                />
                <InputText
                    name="address"
                    label="Адрес расположения движимого имущества"
                    formError={formError}
                    id="addressInput"
                    onChange={setData}
                    setFormError={setFormError}
                    type="text"
                    value={address || ''}
                    validator={[{maxLength: 200}, {required: true}]}
                    labelClass="requiredMark"
                    containerClassName="addProject__fullInput"
                />
                <InputText
                    name="other_information"
                    label="Иные сведения"
                    rows={2}
                    type="textarea"
                    onChange={setData}
                    formError={formError}
                    value={other_information || ''}
                    id="otherInformationInput"
                    setFormError={setFormError}
                    validator={[{maxLength: 500}]}
                    containerClassName="addProject__fullInput"
                />
                <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                    <label>Иные документы</label>
                    <UploadField
                        files={documents}
                        edit={true}
                        parentType="project_id"
                        parentId={projectId}
                        title="Иные документы"
                        onChange={setData}
                        fileKey="documents"
                        multiple={true}
                        maxFiles={5}
                        formError={formError}
                        setFormError={setFormError}
                        fileType="project_property"
                        accept=".jpeg, .png, .pdf"
                    />
                </div>
                <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                    <label className="requiredMark">Фото залога</label>
                    <UploadField
                        files={images}
                        edit={true}
                        parentType="project_id"
                        parentId={projectId}
                        title="Фото залога"
                        onChange={setData}
                        fileKey="images"
                        multiple={true}
                        maxFiles={5}
                        formError={formError}
                        setFormError={setFormError}
                        fileType="project_property"
                        accept=".jpeg, .png"
                        required={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Movable;
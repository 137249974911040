const paymentTypesHeaders = [
    {label: "Дата", key: "date"},
    {label: "Операция", key: "transaction"},
    {label: "Платеж", key: "payment"},
    {label: "Погашение основного долга", key: "amortization"},
    {label: "Проценты", key: "percent"},
    {label: "Остаток основного долга", key: "balanceOwed"},
];

const paymentTypesData = [
    {date: "20.20.2020", transaction: "Заем", payment: "-", amortization: "-", percent: '-', balanceOwed: '-'},
    {
        date: "20.20.2020",
        transaction: "1 платеж",
        payment: "9 410",
        amortization: "9 000",
        percent: "410",
        balanceOwed: "85 240"
    },
    {
        date: "20.20.2020",
        transaction: "2 платеж",
        payment: "9 410",
        amortization: "9 000",
        percent: "410",
        balanceOwed: "75 240"
    },
    {
        date: "20.20.2020",
        transaction: "3 платеж",
        payment: "9 410",
        amortization: "9 000",
        percent: "410",
        balanceOwed: "65 240"
    },
    {
        date: "20.20.2020",
        transaction: "4 платеж",
        payment: "9 410",
        amortization: "9 000",
        percent: "410",
        balanceOwed: "55 240"
    },
    {
        date: "20.20.2020",
        transaction: "5 платеж",
        payment: "9 410",
        amortization: "9 000",
        percent: "410",
        balanceOwed: "45 240"
    },
    {
        date: "20.20.2020",
        transaction: "6 платеж",
        payment: "9 410",
        amortization: "9 000",
        percent: "410",
        balanceOwed: "35 240"
    },
    {
        date: "20.20.2020",
        transaction: "7 платеж",
        payment: "9 410",
        amortization: "9 000",
        percent: "410",
        balanceOwed: "25 240"
    },
    {
        date: "20.20.2020",
        transaction: "8 платеж",
        payment: "9 410",
        amortization: "9 000",
        percent: "410",
        balanceOwed: "15 240"
    },
    {
        date: "20.20.2020",
        transaction: "9 платеж",
        payment: "9 410",
        amortization: "9 000",
        percent: "410",
        balanceOwed: "0"
    },
    {date: "Итого", transaction: "", payment: "85 240", amortization: "81 240", percent: "21 240", balanceOwed: ""},
];

export const paymentTypesCsvReport = {
    data: paymentTypesData,
    headers: paymentTypesHeaders,
    filename: 'Типы выплат.csv'
};
import React, {useEffect} from 'react';
import UploadField from "@shared/components/uploadField";
import {useDispatch, useSelector} from "react-redux";
import {EDIT_PROJECT_DOCUMENTS, setProjectFormError, setProjectFormIsCompleted} from "@actions/projectsActions";

const DocumentsForm = ({fRef, data, projectId}) => {
    const dispatch = useDispatch();
    const {projectFormError: formError, formIsCompleted} = useSelector(state => state.projects);

    const setFormError = data => {
        setProjectFormError(data, dispatch)
    }

    const setData = event => {
        dispatch({
            type: EDIT_PROJECT_DOCUMENTS,
            payload: {
                id: event.target.name,
                value: event.target.value || [],
            }
        })
    }

    // Проверяем заполнены ли все поля, если да, то активируем кнопку далее
    useEffect(() => {
        let status = true;

        data.forEach(item => {
            if (item.required && !item.files.length) status = false;
        })

        if (status !== formIsCompleted.documentsForm) {
            setProjectFormIsCompleted("documentsForm", status, dispatch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const renderForm = () => {
        if (data.length) {
            return data.map(item => {
                const {id, label, required, files} = item;

                return (
                    <div className="inputText__wrapper addProject__fullInput" key={id}>
                        <label className={required ? 'requiredMark' : ''}>{label}</label>
                        <UploadField
                            fileType="project_documents"
                            files={files}
                            parentType="project_id"
                            parentId={projectId}
                            edit={true}
                            onChange={setData}
                            fileKey={id}
                            multiple={true}
                            maxFiles={5}
                            formError={formError}
                            setFormError={setFormError}
                            required={required}
                            accept=".pdf, .doc, .docx, .jpeg, .png"
                        />
                    </div>
                )
            })
        }
    }

    return (
        <div className="addProject__form addProject__form-investments" ref={fRef}>
            <p className="addProject__form_title">Документы</p>
            <div className="addProject__aboutProject addProject__documentsForm">
                {renderForm()}
            </div>
        </div>
    )
}

export default DocumentsForm;
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {showModal} from "@actions/modalActions";
import {documentIcon} from "@shared/img/iconSvg";
import {SIGNATURE_MODAL, VIEW_FILE_DETAIL} from "@shared/components/modal/constants";
import FileItemControls from "@shared/components/uploadField/FileItemControls";
import {FilesService} from "@services/file.service";

const SignatureFile = props => {
    const {id, viewDetail, update} = props;
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();

    const openFileDetail = () => {
        if (viewDetail) {
            showModal(VIEW_FILE_DETAIL, file)(dispatch)
        }
    }

    const showSignModal = () => {
        showModal(SIGNATURE_MODAL, id)(dispatch)
    }

    useEffect(() => {
        if (id) {
            FilesService.getById(id, {include: 'signature'})
                .then(res => setFile(res.data.data))
                .catch(error => console.log(error))
        }
    }, [id, update])

    const styles = {
        "border": "1px solid rgba(135, 151, 182, 0.29)",
        "border-radius": "5px",
        "padding": "10px",
    }


    return (
        file && <div className="uploadField__item" style={styles}>
            <div className="uploadField__item_group" onClick={openFileDetail}>
                <div className="uploadField__fileIcon">{documentIcon()}</div>
                <div className="uploadField__name">{`${file.name}.${file.extension}`}</div>
            </div>
            <div className="uploadField__download_group">
                <FileItemControls data={file} edit={false} openDropzone={false} spinner={false} update={false}/>
                <div className="creditCheck__services creditCheck__flex">
                    {
                        file && !!file.signature ? <div className="creditCheck__mess creditCheck__mess_success">
                            <span>Подписано {file?.signature?.signing_time}</span>
                        </div> : <div>
                            <div className="creditCheck__mess creditCheck__mess_error">
                                <span>Ожидается ваша подпись </span>
                            </div>
                            <div className="creditCheck__services_link">
                                <span onClick={showSignModal}>Перейти к подписанию</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SignatureFile;
import React, {useEffect, useState} from 'react';
import {FilesService} from "@services/file.service";
import UploadField from "@shared/components/uploadField";
import {Button} from "reactstrap";
import {SIGNATURE_SHOW_MODAL, SIGNATURE_SIGN_MODAL} from "@shared/components/modal/constants";

const SignatureModal = ({modalUpdate, showModal, modalData}) => {
    const fileId = modalData;
    const [file, setFile] = useState(null);

    useEffect(() => {
        if (fileId) {
            FilesService.getById(fileId, {include: 'profile,profile.user,profile.legal_form,profile.user.roles,signature'})
                .then(res => setFile(res.data.data))
                .catch(error => console.log(error))
        }
    }, [fileId, modalUpdate])

    const signFile = () => {
        showModal(SIGNATURE_SIGN_MODAL, file.id)
    }
    const showFile = () => {
        showModal(SIGNATURE_SHOW_MODAL, file.id)
    }

    return (<div className="signature">
            <p className="modalFullScreen__title">
                Детали
            </p>
            <div className="dataCard signature__tabItem">
                <div className="signature__tabItem_files">
                    <div className="signature__tabItem_elem">
                      <span className="signature__tabItem_name">
                        <span className="position-relative">
                          Документ
                        </span>
                      </span>
                        {file && <UploadField viewDetail files={[file]} edit={false} title="паспорт"/>}
                    </div>
                </div>
                <div className="signature__tabItem_elem">
                    <span className="signature__tabItem_name">Ваша роль</span>
                    <span className="signature__tabItem_desc">{file && file.profile.user.roles[0].display_name}</span>
                </div>
                <div className="signature__tabItem_elem">
                    <span className="signature__tabItem_name">Ваш профиль</span>
                    <span className="signature__tabItem_desc">{file && file.profile.legal_form.personal.fio}</span>
                </div>
                <div className="signature__tabItem_elem">
                    <span className="signature__tabItem_name">Статус вашей подписи</span>
                    <span className="signature__tabItem_desc">
                        {
                            file && !!file.signature ?
                                <span
                                    className="signature__tabItem_desc_status success">Подписано {file.signature.signing_time}</span> :
                                <span className="signature__tabItem_desc_status error">Ожидается ваша подпись</span>
                        }
                    </span>
                </div>
                <div className="signature__tabItem_elem">
                    <span className="signature__tabItem_name">Сведения КЭП</span>
                    {
                        file && !!file.signature ? <span className="signature__tabItem_desc"><button
                            className="green-color btn-reset d-flex justify-content-start"
                            onClick={showFile}
                        >
                            Файл подписи
                        </button></span> : <Button color="primary" size="md" onClick={signFile}>
                            <div className="btn-spinner-group">
                                Подписать КЭП
                            </div>
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
};

export default SignatureModal;
import React from 'react';

const ProgressBarLine = ({data}) => {
    const reserved = data?.condition?.target ? Math.floor(data.condition.target / 3.4) : 0;
    const progress = data?.condition?.target ? reserved / data.condition.target * 100 : 0;

    return (
        <div className="projectCard__progressBar_inner">
            <div className="projectCard__progressBar_fill" style={{width: `${progress}%`}}/>
            <span/><span/><span/>
        </div>
    )
};

export default ProgressBarLine;
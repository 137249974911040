import React from 'react';
import Notification from "@shared/components/projects/projectDetail/ProjectInvest/modal/notification";
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";
import Reminder from "@shared/components/projects/projectDetail/ProjectInvest/modal/reminder";
import {Button} from "reactstrap";
import InvestInputSum from "@shared/components/projects/projectDetail/ProjectInvest/modal/investInputSum";
import {showModal} from "@actions/modalActions";
import {INVESTMENTS_PLATFORMS_MODAL, PRELIMINARY_PAYMENT_SCHEDULE_MODAL} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";

const InvestmentModal = () => {
    const dispatch = useDispatch();

    return (
        <div className='investmentModal'>
            <Notification desc={
                'Для того, чтобы инвестировать от имени другого профиля, выберете другой профиль в боковом меню'
            }/>
            <BackButton/>
            <span className='investmentModal__title'>Инвестирование в проект №***</span>
            <div className='investmentModal__subtitle'>
                <span>Наличие КЭП для инвестирования: </span>
                <span className='investmentModal__descGreen'>Не требуется</span>
                <Reminder/>
            </div>
            <div className='investmentModal__list'>
                <div className='investmentModal__list_desc'>
                    <span className='investmentModal__listDesc_name investmentModal__greyBlue'>На вашем счете</span>
                    <span>12 578,09 ₽</span>
                </div>
                <div className='investmentModal__list_desc'>
                    <div className='investmentModal__listDesc_reminder'>
                        <span className='investmentModal__listDesc_name investmentModal__greyBlue'>
                            Вы можете инвестировать до
                            <Reminder/>
                        </span>
                    </div>
                    <span className='investmentModal__listDesc_desc'>12 578,09 ₽</span>
                </div>
                <div className='investmentModal__list_desc'>
                    <span className='investmentModal__listDesc_name investmentModal__descGreen'>
                        Вы инвестировали в этот проект
                    </span>
                    <span className='investmentModal__descGreen'>5 000 ₽</span>
                </div>
            </div>
            <div className='investmentModal__sum'>
                <span className='investmentModal__greyBlue'>Сумма вашей инвестиции (минимально 5 000 ₽)</span>
                <InvestInputSum/>
            </div>
            <Button
                color="primary"
                size="lg"
            >
                Инвестировать
            </Button>
            <div className='investmentModal__platforms'>
                <span
                    className='investmentModal__descGreen textLine'
                    onClick={() => showModal(INVESTMENTS_PLATFORMS_MODAL)(dispatch)}
                >
                    Инвестиции на других платформах
                </span>
                <span
                    className='investmentModal__descGreen textLine'
                    onClick={() => showModal(PRELIMINARY_PAYMENT_SCHEDULE_MODAL)(dispatch)}
                >
                    Предварительный график
                </span>
            </div>
            <div className='investmentModal__info'>
                <span className='investmentModal__greyBlue'>
                    Нажимая на кнопку «Инвестировать», вы соглашаетесь заключить договор на условиях инвестиционного
                    предложения и подтверждаете, что ознакомились и согласны с условиями заключаемого договора
                </span>
                <span className='investmentModal__greyBlue'>
                    Отказаться от инвестиции можно в течение пяти рабочих дней со дня акцепта, но не позднее
                    дня прекращения действия инвестиционного предложения
                </span>
            </div>
        </div>
    )
};

export default InvestmentModal;
import React, {useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {Button, Spinner} from "reactstrap";
import {useHistory} from "react-router-dom";
import {QualificationsCheckService} from "@services/qualifications.service";


const QualificationApplicationReject = ({hideModal, modalData}) => {
    const history = useHistory();
    const qualificationId = modalData;
    const [rejectionText, setRejectionText] = useState('');
    const [formError, setFormError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resetValidate, setResetValidate] = useState(false);

    const handleOnChange = e => setRejectionText(e.target.value);

    const handleOnClickReject = () => {
        if (rejectionText && qualificationId) {
            setLoading(true);
            const data = {type: "qualification_rejected", remark: rejectionText}

            QualificationsCheckService.create(data, qualificationId)
                .then(() => {
                    setLoading(false);
                    hideModal();
                    history.push('/');
                })
                .catch(() => setLoading(false))
        } else {
            setFormError(["rejectionText"])
            setResetValidate(true)
        }
    }

    return <div className="moderatorAccModal__reject">
        <p className="modalFullScreen__title">Причина отклонения</p>
        <InputText
            name="rejectionText"
            id="rejectionText"
            type='textarea'
            label="Укажите причину отклонения заявления"
            rows={2}
            formError={formError}
            onChange={handleOnChange}
            setFormError={setFormError}
            resetValidate={resetValidate}
            value={rejectionText}
            containerClassName="profileTabs__edit_item moderatorAccModal__reject_inputGroup"
            validator={[{required: true}]}
        />
        <div className="modalFullScreen__btnGroup moderatorAccModal__reject_btnGroup">
            <Button
                onClick={() => hideModal()}
                color="primary"
                className="account__btn_cancel moderatorAccModal__reject_btnDanger"
                outline
            >
                Отмена
            </Button>
            <Button
                color="primary"
                className="account__btn_primary moderatorAccModal__reject_btnPrimary"
                onClick={handleOnClickReject}
            >
                <div className="btn-spinner-group">
                    <Spinner className={`btn-spinner ${loading ? 'btn-spinner-visible' : ''}`}/>
                    Отклонить
                </div>
            </Button>
        </div>
    </div>
}

export default QualificationApplicationReject;

import React from 'react';
import {useDispatch} from "react-redux";
import {Button} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {BAN_COMMENT_MODAL} from "@shared/components/modal/constants";

const AccountBanPage = ({data = {}, profileId}) => {
    const dispatch = useDispatch();

    const openBanAccountModal = () => {
        showModal(BAN_COMMENT_MODAL, {...data, profileId})(dispatch)
    }

    return (
        <Button color="danger" size="lg" onClick={openBanAccountModal}>
            {data?.isBanned ? 'Разблокировать аккаунт' : 'Заблокировать аккаунт'}
        </Button>
    )
};

export default AccountBanPage;
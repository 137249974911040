import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import MediaPresentation from "@shared/components/presentation/MediaPresentation";

const House = ({data, idx, fRef}) => {
    const {house, land} = data;

    const dataFormat = [
        {name: 'Жилой дом (с хозяйственными постройками)', type: 'subtitle'},
        {name: 'Адрес', desc: house.address || ''},
        {name: 'Кадастровый номер', desc: house.number || ''},
        {name: 'Общая площадь', desc: house.total_area || ''},
        {name: 'Жилая площадь', desc: house.living_area || ''},
        {name: 'Технический план (скан)', desc: house.plans || '', type: 'file'},
        {name: 'Свидетельство о праве собственности', desc: house.certificate_number || ''},
        {name: 'Свидетельство о праве собственности от', desc: house.certificate_date || ''},
        {name: 'Скан свидетельства о праве собственности', desc: house.certificates || '', type: 'file'},
        {name: 'Выписка из Единого государственного реестра недвижимости, дата', desc: house.extract_egrn_from || ''},
        {name: 'Скан выписки', desc: house.extracts || '', type: 'file'},
        {name: 'Иные сведения', desc: house.other_information || ''},
        {name: 'Иные документы', desc: house.documents || '', type: 'file'},
        {
            name: 'Фото залога',
            desc: house.images ? <MediaPresentation files={house.images} className="moderationProject__medias"/> : ''
        },
        {name: 'Земельный участок', type: 'subtitle'},
        {name: 'Адрес', desc: land.address || ''},
        {name: 'Кадастровый номер', desc: land.number || ''},
        {name: 'Площадь', desc: land.total_area || ''},
        {name: 'Категория земель', desc: land.land_category || ''},
        {name: 'Разрешенное использование', desc: land.permitted_use || ''},
        {name: 'Свидетельство о праве собственности', desc: land.certificate_number || ''},
        {name: 'Свидетельство о праве собственности от', desc: land.certificate_date || ''},
        {name: 'Скан свидетельства о праве собственности', desc: land.certificates || '', type: 'file'},
        {name: 'Выписка из Единого государственного реестра недвижимости, дата', desc: land.extract_egrn_from || ''},
        {name: 'Скан выписки', desc: land.extracts || '', type: 'file'},
        {name: 'Иные сведения', desc: land.other_information || ''},
        {name: 'Иные документы', desc: land.documents || '', type: 'file'},
        {
            name: 'Фото залога',
            desc: land.images ? <MediaPresentation files={land.images} className="moderationProject__medias"/> : ''
        },
    ]

    return <DataCard data={dataFormat} title={`Залог ${idx + 1}`} fRef={fRef}/>
}

export default House;

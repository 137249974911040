import React, {useState} from 'react';
import QRCode from "qrcode.react";
import {DownloadIcon, LeftArrowIcon} from "@shared/img/iconSvg";
import {requisitesDataInitial} from "@shared/components/modal/balance/helpers";
import AccountReplenishmentDetails from "@shared/components/pdf/AccountReplenishmentDetails";
import {useHistory} from "react-router-dom";


const PaymentDetails = ({modalData}) => {
    const type = modalData;
    const [requisites] = useState(requisitesDataInitial);
    const history = useHistory();

    const requisitesQRData = 'ST00011|NAME=ООО «ИНКРАУД»|BANK_NAME=Точка ПАО Банка «Финансовая Корпорация Открытие»|PERSONAL_ACC=40702810501500068008|PAYEE_INN=9704007489|PURPOSE=Пополнение счета|BIC=044525999|KPP=770401001';

    return (
        <div className="balance__modal paymentDetailsModal platformServicesModal">
            <div
                className='platformServicesModal__feed'
                onClick={() => history.push("/project-detail/101010")}
            >
                <div className='platformServicesModal__feed__button'><LeftArrowIcon/></div>
                <span>Назад</span>
            </div>
            <p className="modalFullScreen__title">
                {
                    type === 'requisites'
                        ? 'Внесение платежа по договору займа №1234'
                        : 'Калькулятор полного досрочного погашения по договору займа №1234'
                }
            </p>
            {
                type === 'calculator'
                    ? <div className='paymentDetailsModal__repayment'>
                        <div className='paymentDetailsModal__repayment_date'>
                            <span className='paymentDetailsModal__repayment_title'>Дата досрочного погашения</span>
                            <div className='paymentDetailsModal__date_desc'>
                                <span>15.01.2021</span>
                            </div>
                        </div>
                        <div>
                            <span
                                className='paymentDetailsModal__repayment_title'>Сумма полного погашения – 1 000 000 ₽</span>
                            <div className='paymentDetailsModal__repayment_sum'>
                                <div className='paymentDetailsModal__sum_desc'>
                                    <span>Основной долг</span>
                                    <span>100 000, 00 ₽ </span>
                                </div>
                                <div className='paymentDetailsModal__sum_desc'>
                                    <span>Проценты</span>
                                    <span>2 000, 00 ₽</span>
                                </div>
                                <div className='paymentDetailsModal__sum_desc'>
                                    <span>Пени</span>
                                    <span>12 000, 00 ₽</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            <p className="balance__modal_subtitle balance__modal_subtitle-top">
                {
                    type === 'requisites'
                        ? 'Для погашения займа осуществите банковской перевод по указанным реквизитам'
                        : 'Для полного досрочного погашения осуществите банковской перевод по указанным реквизитам '
                }
            </p>
            <ul className="circle_checklist">
                {
                    type === 'requisites'
                        ? <>
                            <li>Перевод осуществляется только в рублях РФ</li>
                            <li>
                                Внесение платежа возможно только с вашего счета ИП в банке.
                                Использование счетов третьих лиц <br/>недопустимо
                            </li>
                        </>
                        : <>
                            <li>
                                Внесение платежа возможно только с вашего счета ИП в банке.
                                Использование счетов третьих лиц недопустимо
                            </li>
                            <li>
                                Платеж считается исполненным с даты его зачисления на номинальный счет платформы.<br/>
                                При внесении платежа учитывайте срок его исполнения вашим банком
                            </li>
                        </>

                }

            </ul>
            <div className="balance__modal_requisites">
                <p className="balance__modal_title">Реквизиты номинального счета</p>
                <ul>
                    <li>Получатель платежа: <span>{requisites.payment_receiver}</span></li>
                    <li>Расчетный счет: <span>************</span></li>
                    <li>Корр. счет: <span>************</span></li>
                    <li>ИНН получателя: <span>************</span></li>
                    <li>КПП получателя: <span>************</span></li>
                    <li>Наименование банка: <span>************</span></li>
                    <li>БИК банка: <span>************</span></li>
                    <li>Назначение платежа:
                        <span className='paymentDetailsModal__loanPayment'>
                         Платёж по договору займа №******** от **.**.****г. Без НДС</span>
                    </li>
                </ul>
                <span className='paymentDetailsModal__purposeOfPayment'>
                    Указывайте именно такое назначение платежа. Это ускорит поступление денег.
                </span>
                <div className="balance__modal_btn">
                    <div className="uploadField__download">
                        <DownloadIcon/>
                    </div>
                    <AccountReplenishmentDetails/>
                </div>
            </div>
            <p className="balance__modal_subtitle balance__modal_subtitle-bottom">
                QR-код для оплаты из мобильного приложения вашего банка
            </p>
            <p className="balance__modal_text">
                Мобильное приложение вашего банка может не поддерживать
                функцию запыления реквизитов для оплаты с помощью QR-кода
            </p>
            <div className="balance__modal_qrcode">
                <QRCode
                    id='requisitesQRCode'
                    value={requisitesQRData}
                    size={95}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={false}
                    renderAs={"canvas"}
                />
            </div>
        </div>
    )
};

export default PaymentDetails;
import React from 'react';
import InfoBlock from "@shared/components/projects/projectDetail/LoanOptions/InfoBlock";
import ProgressBar from "@shared/components/projects/projectDetail/LoanOptions/ProgressBar";
import LoanPlatformServices from "@shared/components/projects/projectDetail/LoanOptions/LoanPlatformServices";
import ProjectEvents from "@shared/components/projects/projectDetail/LoanOptions/ProjectEvents";
import LoanOptionsEditButton from "@shared/components/projects/projectDetail/LoanOptions/LoanOptionsEditButton";
import LoanCollectedFunds from "@shared/components/projects/projectDetail/LoanOptions/LoanCollectedFunds";
import LoanOptionsRemarks from "@shared/components/projects/projectDetail/LoanOptions/LoanOptionsRemarks";
import LoanOptionsCancelButton from "@shared/components/projects/projectDetail/LoanOptions/LoanOptionsCancelButton";
import LoanNotificationCollectionCompleted
    from "@shared/components/projects/projectDetail/LoanOptions/LoanNotificationCollectionCompleted";
import ProgressBarLine from "@shared/components/projects/projectDetail/LoanOptions/ProgressBarLine";
import InfoBlockTitle from "@shared/components/projects/projectDetail/LoanOptions/InfoBlockTitle";
import LoanOptionsButtons from "@shared/components/projects/projectDetail/LoanOptions/LoanOptionsButtons";

const LoanOptions = ({data}) => {
    switch (data.moderation_status_key) {
        case 'projects_not_carried_out': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <InfoBlock data={data}/>
                    <ProgressBarLine data={data}/>
                    <ProgressBar data={data}/>
                    <LoanPlatformServices/>
                    <ProjectEvents/>
                </div>
            );
        }
        case 'projects_checking':
        case 'projects_draft': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <InfoBlock data={data}/>
                    <ProgressBarLine data={data}/>
                    <ProgressBar data={data}/>
                    <LoanOptionsEditButton/>
                    <LoanPlatformServices/>
                </div>
            );
        }
        case 'projects_collecting': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <InfoBlock data={data}/>
                    <ProgressBarLine data={data}/>
                    <ProgressBar data={data}/>
                    <LoanOptionsCancelButton data={data}/>
                    <LoanCollectedFunds data={data}/>
                    <LoanPlatformServices/>
                    <ProjectEvents/>
                </div>
            );
        }
        case 'projects_returned': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <InfoBlock data={data}/>
                    <ProgressBarLine data={data}/>
                    <LoanOptionsRemarks/>
                    <ProgressBar data={data}/>
                    <LoanOptionsEditButton/>
                    <LoanPlatformServices/>
                </div>
            );
        }
        case 'projects_collecting_completed': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <InfoBlock data={data}/>
                    <ProgressBarLine data={data}/>
                    <ProgressBar data={data}/>
                    <LoanCollectedFunds data={data}/>
                    <LoanPlatformServices/>
                    <ProjectEvents/>
                </div>
            );
        }
        case 'projects_signing_stage': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <InfoBlock data={data}/>
                    <ProgressBarLine data={data}/>
                    <LoanNotificationCollectionCompleted NotificationColl={
                        'Сбор инвестиций завершен. Вам необходимо подписать ' +
                        'документы в разделе “Документы” до 22.12.2021.'
                    }/>
                    <ProgressBar data={data}/>
                    <LoanCollectedFunds data={data}/>
                    <LoanPlatformServices/>
                    <ProjectEvents/>
                </div>
            );
        }
        case 'projects_payment_stage': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <InfoBlock data={data}/>
                    <ProgressBarLine data={data}/>
                    <ProgressBar data={data}/>
                    <LoanOptionsButtons/>
                    <LoanPlatformServices/>
                    <ProjectEvents/>
                </div>
            );
        }
        case 'projects_delay': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <InfoBlock data={data}/>
                    <ProgressBarLine data={data}/>
                    <ProgressBar data={data}/>
                    <LoanOptionsButtons/>
                    <LoanPlatformServices/>
                    <ProjectEvents/>
                </div>
            );
        }
        default: {
            return null;
        }
    }

};

export default LoanOptions;
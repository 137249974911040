import React from 'react';
import {initState, qualificationsRegistryStatusKey} from './data';
import {showModal} from "@actions/modalActions";
import {INNER_USERS_QUALIFIED_APPLICATION} from "@shared/components/modal/constants";

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_CHECKBOX_VALUE = 'SET_CHECKBOX_VALUE';
export const SET_DATE_VALUE = 'SET_DATE_VALUE';
export const SET_SEARCH_FIELDS_VALUE = 'SET_SEARCH_FIELDS_VALUE';
export const FILTER_RESET = 'FILTER_RESET';
export const SET_VISIBLE = 'SET_VISIBLE';
export const SET_ALL_VISIBLE = 'SET_ALL_VISIBLE';
export const SET_SORTED = 'SET_SORTED';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const reducer = (state, action) => {
    const {name, value, key, sort} = action.payload || {};

    switch (action.type) {
        case SET_SEARCH_VALUE:
            return {...state, search: {...state.search, [name]: value}}
        case SET_SEARCH_FIELDS_VALUE:
            return {...state, searchFields: {...state.searchFields, [name]: value}}
        case SET_VISIBLE:
            const newTableHeaderVisible = [...state.tableHeaderVisible];
            const index = newTableHeaderVisible.indexOf(name);
            index !== -1 ? newTableHeaderVisible.splice(index, 1) : newTableHeaderVisible.push(name)

            return {...state, tableHeaderVisible: newTableHeaderVisible}
        case SET_CHECKBOX_VALUE:
            const searchCheckboxCopy = [...state.search[name]];
            const valueIndex = searchCheckboxCopy.indexOf(value);
            valueIndex !== -1 ? searchCheckboxCopy.splice(valueIndex, 1) : searchCheckboxCopy.push(value)

            return {...state, search: {...state.search, [name]: searchCheckboxCopy}}
        case SET_ALL_VISIBLE:
            return {...state, tableHeaderVisible: initState.tableHeaderVisible}
        case SET_DATE_VALUE:
            const newSearchDate = [...state.search[key]];
            let newFieldsDate = '';
            name === 'from' ? newSearchDate[0] = value : newSearchDate[1] = value

            if (newSearchDate[0] && newSearchDate[1]) newFieldsDate = 'between'
            if (!newSearchDate[0] && newSearchDate[1]) newFieldsDate = '<'
            if (newSearchDate[0] && !newSearchDate[1]) newFieldsDate = '>'

            return {
                ...state,
                search: {...state.search, [key]: newSearchDate},
                searchFields: {...state.searchFields, [key]: newFieldsDate}
            }
        case SET_SORTED:
            const sortedByCopy = [...state.sortedBy];
            const orderByCopy = [...state.orderBy];
            const orderIndex = orderByCopy.indexOf(key);

            if (orderIndex !== -1) {
                sortedByCopy[orderIndex] = sort;
            } else {
                orderByCopy.push(key)
                sortedByCopy.push(sort)
            }

            return {...state, sortedBy: sortedByCopy, orderBy: orderByCopy}
        case FILTER_RESET:
            return {...initState}
        case SET_CURRENT_PAGE:
            return {...state, currentPage: action.payload}

        default:
            return {...state}
    }
}

export const dataFormat = (data, reduxDispatch) => {
    const openApplication = id => {
        showModal(INNER_USERS_QUALIFIED_APPLICATION, id)(reduxDispatch)
    }

    if (data?.length) {
        return data.map((item, idx) => {
            const {id, legal_form, qualification_status_key, qualified_at, unqualified_at} = item.profile;

            return {
                'link': ['profile.id'],
                'linkUrl': [`/client_account/${id}`],
                'number': `№${idx + 1}`,
                'id': `№${item.id}`,
                'profile.qualification_status_key': qualificationsRegistryStatusKey[qualification_status_key] || '-',
                'profile.id': id || '-',
                'profile.legal_form.personal.fio': legal_form?.personal?.fio || '-',
                'profile.legal_form.personal.inn': legal_form?.personal?.inn || '-',
                'created_at': item.created_at || '-',
                'profile.qualified_at': qualified_at || '-',
                'profile.unqualified_at': unqualified_at || '-',
                'moreDetails': <button
                    className="green-color btn-reset"
                    onClick={() => openApplication(item.id)}
                >
                    Подробнее
                </button>
            }
        })
    }

    return []
}



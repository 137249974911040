import React, {useEffect, useState} from 'react';
import Masonry from 'react-masonry-css'
import {useHistory, useParams} from "react-router-dom";
import AccProfileData from './AccProfileData';
import AccProfileControls from './AccProfileControls';
import {AccreditationService} from "@services/accreditation.service";
import AdditionalInfo from "@shared/components/account/additionalInfo";
import {useDispatch, useSelector} from "react-redux";
import {SET_ACCREDITATION_PROFILE_ID} from "@actions/accreditationAction";
import PassportData from "@shared/components/presentation/PassportData";
import PersonalData from "@shared/components/presentation/PersonalData";
import AddedList from "@shared/components/form/addedList";
import AccreditationProfileStatus from "@containers/Accreditation/AccreditationProfile/AccreditationProfileStatus";
import {accreditationRequestInclude} from "@containers/Accreditation/AccreditationProfile/helpers";
import CommentHistory from "@shared/components/data/CommentHistory";


const AccreditationProfile = ({title, showResult}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [profileData, setProfileData] = useState(null);
    const [remarkList, setRemarkList] = useState([]);
    const [remarkListErrorMessage, setRemarkListErrorMessage] = useState(null);
    const accreditationProfileId = useSelector(state => state.accreditation.accreditationProfileId);
    const accreditationsHistory = profileData?.profile?.accreditations || [];

    const renderAdditionalInfo = () => {
        const addInfoForms = profileData?.profile?.legal_form?.questionnaire?.responses || [];

        return addInfoForms.map(item => {
            return <div key={item.id} className="profileTabs__addInfo_item accreditation__addInfo_item">
                <AdditionalInfo data={item}/>
            </div>
        })
    }

    useEffect(() => {
        let unmounted = false;

        if (accreditationProfileId) {
            AccreditationService.getById(accreditationProfileId, {
                include: accreditationRequestInclude.join(',')
            })
                .then(res => !unmounted && setProfileData(res?.data?.data || {}))
                .catch(error => console.log(error))
        }

        return () => unmounted = true;
    }, [accreditationProfileId])

    useEffect(() => {
        if (!accreditationProfileId) {
            if (id) {
                dispatch({type: SET_ACCREDITATION_PROFILE_ID, payload: id})
            } else {
                history.push('/404')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (profileData) setRemarkList(profileData.remarks)
    }, [profileData])

    return <div className="accreditation">
        <h1 className="page-title">{title ? title : 'Аккредитация профиля'}</h1>
        <div className="accreditationProfile">
            {
                profileData
                    ? <>
                        <Masonry
                            breakpointCols={{default: 2, 1400: 1}}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {
                                accreditationsHistory?.length && accreditationsHistory.some(item => item.remarks.length)
                                    ? <CommentHistory data={accreditationsHistory}/>
                                    : null
                            }
                            {
                                showResult
                                    ? <AccreditationProfileStatus
                                        status={profileData?.profile?.accreditation_status_key}
                                        stage={profileData?.stage}
                                    />
                                    : null
                            }
                            <PersonalData data={profileData?.profile}/>
                            <AccProfileData data={profileData?.profile || {}}/>
                            <PassportData data={profileData?.profile?.legal_form?.passport || {}}/>
                            {renderAdditionalInfo()}
                        </Masonry>
                        <p className="moderationProject__title">Решения модератора</p>
                        <AddedList
                            data={remarkList}
                            onChange={setRemarkList}
                            disabled={showResult}
                            label="Замечания"
                            placeholder="Замечание"
                            className="moderationProject__remarks"
                            errorMessage={remarkListErrorMessage}
                            setErrorMessage={setRemarkListErrorMessage}
                        />
                        <AccProfileControls
                            profileIsBanned={profileData?.profile?.isBanned}
                            userIsBanned={profileData?.profile?.user?.isBanned}
                            remarkList={remarkList}
                            showResult={showResult}
                            accreditationId={id}
                            setRemarkListErrorMessage={setRemarkListErrorMessage}
                            accreditationStatus={profileData?.profile?.accreditation_status_key}
                        />
                    </>
                    : ''/*<Masonry
                breakpointCols={{default: 2, 1400: 1}}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
              <SkeletonDataCard />
              <SkeletonDataCard />
              <SkeletonDataCard />
              <SkeletonDataCard />
            </Masonry>*/
            }
        </div>
    </div>
}

export default AccreditationProfile;
import React from 'react';
import {navigationMenu} from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/helpers";
import AboutCompany from "@shared/components/presentation/AboutCompany";
import AboutProject from "@shared/components/presentation/AboutProject";
import DocumentsCompany from "@shared/components/presentation/DocumentsCompany";
import AddedList from "@shared/components/form/addedList";
import AnalyticUnit from "@shared/components/form/moderation/AnalyticUnit";
import classnames from "classnames";

const ModerationProjectDescription = ({
                                          data,
                                          step,
                                          remarkList,
                                          setRemarkList,
                                          analyticState,
                                          analyticDispatch,
                                          analyticValidStatus,
                                          showResult
                                      }) => {
    const classes = classnames({
        'dataCard__list': true,
        'moderationProject__showResult': showResult
    })

    return (
        <div className={classes}>
            <AboutCompany
                data={data.project?.company || {}}
                fRef={navigationMenu[step].links[0].ref}
            />
            <AnalyticUnit
                state={analyticState}
                dispatch={analyticDispatch}
                analyticValidStatus={analyticValidStatus}
                fRef={navigationMenu[step].links[1].ref}
            />
            <AboutProject
                data={data.project}
                fRef={navigationMenu[step].links[2].ref}
            />
            <DocumentsCompany
                data={data.project?.documents || {}}
                fRef={navigationMenu[step].links[3].ref}
            />
            <p className="moderationProject__title">Решения модератора</p>
            <AddedList
                disabled={showResult}
                data={remarkList}
                label="Замечания"
                placeholder="Замечание"
                onChange={setRemarkList}
                dataType="moderation_description"
                className="moderationProject__remarks"
                fRef={navigationMenu[step].links[4].ref}
            />
        </div>
    )
}

export default ModerationProjectDescription;

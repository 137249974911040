import React from 'react';
import classnames from 'classnames';
import {DOTS, usePagination} from './hooks/usePagination';
import {RightLongArrowIcon} from "@shared/img/iconSvg";

const Pagination = ({onPageChange, siblingCount = 1, className, meta}) => {
    // freecodecamp.org/news/build-a-custom-pagination-component-in-react/

    const {current_page, total_pages, total, count} = meta?.pagination || {};
    const paginationRange = usePagination({total_pages, siblingCount, current_page}) || [];

    if (current_page === 0 || paginationRange.length < 2) return null;

    const onNext = () => onPageChange(current_page + 1);
    const onPrevious = () => onPageChange(current_page - 1);

    const PaginationResultCount = () => {
        if (current_page === total_pages) return <p>Показано: <span>{`${total}/${total}`}</span></p>

        return <p>Показано: <span>{`${current_page * count}/${total}`}</span></p>
    }

    return count
        ? <div className="pagination-wrap">
            <div className="pagination-result-counts">
                <PaginationResultCount/>
            </div>
            <ul className={classnames('pagination-container', {[className]: className})}>
                <li className={classnames('pagination-item pagination-arrow pagination-arrow-left', {disabled: current_page === 1})}
                    onClick={onPrevious}>
                    <RightLongArrowIcon/>
                </li>
                {paginationRange.map((pageNumber, idx) => {
                    if (pageNumber === DOTS) return <li key={idx} className="pagination-item dots">&#8230;</li>;

                    return (
                        <li
                            key={idx}
                            onClick={() => onPageChange(pageNumber)}
                            className={classnames('pagination-item', {selected: pageNumber === current_page})}
                        >
                            {pageNumber}
                        </li>
                    );
                })}
                <li
                    className={classnames('pagination-item pagination-arrow', {disabled: current_page === total_pages})}
                    onClick={onNext}
                >
                    <RightLongArrowIcon/>
                </li>
            </ul>
        </div>
        : null
};

export default Pagination;

import React, {useEffect, useReducer, useState} from 'react';
import {getInnerUsersList} from "@actions/registryActions";
import RegistryTable from "@containers/Moderator/Registry/RegistryTable";
import Pagination from "@shared/components/pagination";
import {dataFormat, FILTER_RESET, reducer, SET_ALL_VISIBLE, SET_CURRENT_PAGE, SET_VISIBLE} from './helpers';
import {initState, tableHeader} from './data';
import {ColumnIcon} from "@shared/img/iconSvg";
import InputText from "@shared/components/form/profileForm/InputText";
import NotificationsBlock from "@shared/components/notifications/NotificationsBlock";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {ADD_INNER_USER_MODAL} from "@shared/components/modal/constants";

const InnerUsersRegistry = () => {
    const reduxDispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(null);
    const [data, setData] = useState(null);
    const [columnFilterIsOpen, setColumnFilterIsOpen] = useState(false);
    const [state, dispatch] = useReducer(reducer, initState);
    const updateParentData = useSelector(state => state.modal.updateParentData);

    const excelTableData = [];
    const tableDataFormat = dataFormat(data?.data || [], reduxDispatch);
    const tableHeaderVisible = tableHeader.filter(item => state.tableHeaderVisible.includes(item.name));
    const excelTableHeaders = tableHeaderVisible
        .filter(item => !item?.noExport)
        .map(item => ({label: item.title, key: item.name}));

    const filterReset = () => dispatch({type: FILTER_RESET})

    // Excel data format
    tableDataFormat.forEach(item => {
        let dataItem = {};
        excelTableHeaders.forEach(elem => dataItem[elem.key] = item[elem.key])
        excelTableData.push(dataItem);
    })

    const excelData = {
        data: excelTableData,
        headers: excelTableHeaders,
        filename: 'Реестр внутренних пользователей.csv'
    };

    const columnFilterOnChange = e => {
        dispatch({
            type: SET_VISIBLE,
            payload: {name: e.target.name}
        })
    }

    const setCurrentPage = page => {
        dispatch({
            type: SET_CURRENT_PAGE,
            payload: page
        })
    }

    const AddUser = () => {
        const openAddInnerUserModal = () => showModal(ADD_INNER_USER_MODAL)(reduxDispatch)

        return (
            <Button color="success" size="lg" onClick={openAddInnerUserModal}>
                Добавить пользователя
            </Button>
        )
    }

    useEffect(() => {
        let unmounted = false;
        setLoading(true);
        clearTimeout(timer);

        setTimer(setTimeout(() => {
            getInnerUsersList(state)
                .then(res => !unmounted && setData(res?.data || {}))
                .finally(() => !unmounted && setLoading(false))
        }, 500))

        return () => unmounted = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.search, state.sortedBy, state.orderBy, state.currentPage])

    useEffect(() => {
        if (updateParentData > 0) {
            setLoading(true);

            getInnerUsersList(state)
                .then(res => setData(res?.data || {}))
                .finally(() => {
                    setLoading(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateParentData])

    return (
        <div className="registry registry__qualifications registry__innerUsers">
            <h1 className="page-title">Реестр внутренних пользователей</h1>
            <div className="registry__content">
                <div className="registryTableWrap">
                    <RegistryTable
                        state={state}
                        initState={initState}
                        filterDispatch={dispatch}
                        tableHeader={tableHeaderVisible}
                        tableData={tableDataFormat}
                        loading={loading}
                        filterReset={filterReset}
                        excelData={excelData}
                        HeaderButton={AddUser}
                        className="registry__actionProfile"
                    />
                    <Pagination
                        className="pagination-bar"
                        meta={data?.meta || {}}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
                <div className="registry__columnFilter">
                    <button className="btn-reset" onClick={() => setColumnFilterIsOpen(true)}>
                        <ColumnIcon/>
                        <span>Колонки</span>
                    </button>
                </div>
                <NotificationsBlock isOpen={columnFilterIsOpen} setIsOpen={setColumnFilterIsOpen}>
                    <div className="registry__columnFilter_inner">
                        <div className="registry__columnFilter_header">
                            <button
                                className="btn-reset"
                                onClick={() => dispatch({type: SET_ALL_VISIBLE})}
                            >
                                Выбрать все
                            </button>
                        </div>
                        <div className="registry__columnFilter_content">
                            {
                                tableHeader
                                    .filter(item => !item?.noExport)
                                    .map((item, idx) => (
                                        <InputText
                                            key={idx}
                                            type="checkbox"
                                            name={item.name}
                                            label={item.title}
                                            onChange={columnFilterOnChange}
                                            value={!!state.tableHeaderVisible.includes(item.name)}
                                            containerClassName="profileTabs__edit_item profileTabs__edit_check"
                                        />
                                    ))
                            }
                        </div>
                    </div>
                </NotificationsBlock>
            </div>
        </div>
    )
};

export default InnerUsersRegistry;
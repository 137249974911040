import React from "react";
import {Link} from "react-router-dom";

const renderAcceptLink = () => <>
    Я согласен с <Link to="/">Политикой обработки персональных</Link> данных и даю <br/> <Link to="/">Согласие на
    обработку своих персональных данных</Link>
</>

const renderFioCheckBoxDesc = () => {
    return 'Нет отчества'
}

export {
    renderAcceptLink,
    renderFioCheckBoxDesc
}
import {BaseService} from "@services/base.service";
import {ErrorWrapper, ResponseWrapper} from "@utils/wrapper";

export class ProfilesService extends BaseService {
    static get entity() {
        return 'profiles'
    }
}

export class ExternalProfileService extends BaseService {
    static get entity() {
        return 'profiles/outer'
    }
}

export class ProfilesOuterService extends BaseService {
    static get entity() {
        return 'outer/profiles'
    }

    static async updateWithAccreditation( data = {}) {
        const url = this.entity

        try {
            const response = await this.request({auth: true}).put(url, data)
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }
}

export class ProfilesOwnService extends BaseService {
    static get entity() {
        return 'profiles/own'
    }
}

export class ProfilesCheckAccreditationService extends BaseService {
    static get entity() {
        return 'accreditations/check'
    }
}
import React, {useEffect, useState} from 'react';
import {array, bool, func} from 'prop-types';
import {EditIcon} from "@shared/img/iconSvg";
import ApplicationCardList from "@shared/components/presentation/Qualification/ApplicationCardList";
import OtherInfoForm from "@shared/components/presentation/Qualification/ApplicationOtherInformation/OtherInfoForm";

const ApplicationOtherInformation = ({otherInfoList, addNewOtherInfo, addStatus, showAddForm, setShowAddForm}) => {
    const [message, setMessage] = useState('');

    const EditBtn = () => (
        !showAddForm
            ? <div className="qualification__otherInfo_editBtn" onClick={() => setShowAddForm(true)}>
                <EditIcon/>
            </div>
            : null
    )

    const OtherInfoList = () => (
        otherInfoList.length
            ? <div className="qualification__otherInfo_list">
                <ApplicationCardList data={otherInfoList}/>
            </div>
            : null
    )

    useEffect(() => {
        if (!showAddForm && message) setMessage('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAddForm])

    return (
        <div className="qualification__otherInfo">
            <div className="qualification__otherInfo_header">
                <p className="dataCard__title">Иная информация</p>
                <EditBtn/>
            </div>
            <OtherInfoList/>
            <OtherInfoForm
                addStatus={addStatus}
                showAddForm={showAddForm}
                setShowAddForm={setShowAddForm}
                message={message}
                setMessage={setMessage}
                addNewOtherInfo={addNewOtherInfo}
            />
        </div>
    )
}

ApplicationOtherInformation.propTypes = {
    otherInfoList: array.isRequired,
    addNewOtherInfo: func.isRequired,
    addStatus: bool.isRequired,
    showAddForm: bool.isRequired,
    setShowAddForm: func.isRequired,
};

ApplicationOtherInformation.defaultProps = {
    otherInfoList: [],
    addNewOtherInfo: () => {
    },
    addStatus: false,
    showAddForm: false,
    setShowAddForm: () => {
    }
};

export default ApplicationOtherInformation;

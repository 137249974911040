import React from 'react';
import RemarksNotice from "@shared/components/account/accStatusNotice/general/RemarksNotice";
import {loanOptionsRemarks} from "./helpers";

const LoanOptionsRemarks = () => {
    return (
        <div className='loanOptionsRemarks'>
            <p className='loanOptionsRemarks__desc'>Ваш проект не прошел проверку. Внесите изменения в соответствии с
                замечаниями:</p>
            <RemarksNotice data={loanOptionsRemarks}/>
        </div>
    )
};

export default LoanOptionsRemarks;
import {useMemo} from 'react';

export const DOTS = '...';

const range = (start, end) => {
    const length = end - start + 1;

    return Array.from({length}, (_, idx) => idx + start);
};

export const usePagination = ({total_pages, siblingCount = 1, current_page}) => {
    return useMemo(() => {
        // Количество страниц определяется как siblingCount + firstPage + lastPage + current_page + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
          Если количество страниц меньше, чем количество страниц, которые мы хотим показать
          в нашем компоненте разбивки на страницы, мы возвращаем диапазон [1..total_pages]
        */
        if (totalPageNumbers >= total_pages) return range(1, total_pages);

        const leftSiblingIndex = Math.max(current_page - siblingCount, 1);
        const rightSiblingIndex = Math.min(current_page + siblingCount, total_pages);

        /*
          Мы не хотим показывать точки, если осталась только одна позиция
          после / до подсчета левой / правой страницы, так как это приведет к изменению, если наша разбивка на страницы
           размер компонента, который нам не нужен
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < total_pages - 2;

        const firstPageIndex = 1;
        const lastPageIndex = total_pages;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, total_pages];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                total_pages - rightItemCount + 1,
                total_pages
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [total_pages, siblingCount, current_page]);
};
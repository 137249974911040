import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {UsersService} from "@services/users.service";
import {useHistory} from "react-router-dom";

const DeleteInnerUserModal = ({hideModal, modalData}) => {
    const history = useHistory();
    const [userDeleteStatus, setUserDeleteStatus] = useState(null);
    const {id, fio, roles} = modalData || {};

    const handleOnClickCancel = () => {
        hideModal()
    }

    const handleOnClickSave = () => {
        setUserDeleteStatus(true)

        UsersService.remove(id)
            .then(() => {
                setUserDeleteStatus(false)
                history.push('/registry/internal_users')
                hideModal()
            })
            .catch(() => setUserDeleteStatus(false))
    }

    return (
        <div className="innerUserDelete__modal">
            <p className="modalFullScreen__title">
                {`Удаление внутреннего пользователя №${id}`}
            </p>
            <div className="dataCard__inner">
                <div className="dataCard__item">
                    <span className="dataCard__name">ФИО</span>
                    <span className="dataCard__desc">{fio || ''}</span>
                </div>
                <div className="dataCard__item">
                    <span className="dataCard__name">Роль</span>
                    <span className="dataCard__desc">{roles?.length ? roles[0].display_name : ''}</span>
                </div>
            </div>
            <div className="innerUserPage__btnGroup">
                <Button outline color="danger" size="sm" onClick={handleOnClickCancel}>
                    Отменить
                </Button>
                <Button color="primary" size="lg" onClick={handleOnClickSave}>
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${userDeleteStatus ? 'btn-spinner-visible' : ''}`}/>
                        Удалить
                    </div>
                </Button>
            </div>
        </div>
    )
};

export default DeleteInnerUserModal;
import React, {useEffect, useState} from 'react';
import {getFileBlob} from "@services/blob.service";
import {AVATAR_STATUS} from "@actions/userActions";
import {useDispatch} from "react-redux";

const defaultAva = `${process.env.PUBLIC_URL}/img/userDefaultAvatar.png`;

const UserAvatar = ({id, update, detailAvatar, hasAvatar}) => {
    const dispatch = useDispatch();
    //const {avatarStatus} = useDispatch();
    const [blob, setBlob] = useState(null);

    useEffect(() => {
        if (hasAvatar) {
            getFileBlob(id, '/profiles/avatar/').then(blob => setBlob(blob)).catch(() => setBlob(null))
        } else {
            setBlob(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (update) {
            getFileBlob(id, '/profiles/avatar/').then(blob => setBlob(blob)).catch(() => setBlob(null))
            dispatch({type: AVATAR_STATUS})
        }else{
            setBlob(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    return (
        <div className="avatar__wrapper" style={!detailAvatar ? {backgroundImage: `url(${defaultAva})`} : {}}>
            <img
                src={blob || defaultAva}
                onError={e => {
                    e.target.onerror = null;
                    e.target.src = defaultAva
                }}
                alt="avatar"
            />
        </div>
    )

}


export default UserAvatar;
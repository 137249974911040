import React from 'react';
import {Table} from "reactstrap";
import classNames from "classnames";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {ModerationProjectsTableHeader} from "./helpers";
import {RightLongArrowIcon, TriangleIcon} from "@shared/img/iconSvg";
import {SET_MODERATION_PROFILE_ID} from "@actions/moderationAction";
import {useDispatch} from "react-redux";

const ModerationProjectsTable = ({moderationList, loading, filterData, setFilterData, activeTab}) => {
    const dispatch = useDispatch();
    const {sortedBy, orderBy} = JSON.parse(JSON.stringify(filterData));

    const tableClass = classNames({
        accreditationTable: true,
        moderationProjectsTable: true,
        accreditationTable__load: loading,
    });

    const handleOnChangeSorted = key => {
        const orderIndex = orderBy.indexOf(key);

        if (orderIndex !== -1) {
            sortedBy[orderIndex] = sortedBy[orderIndex] === 'desc' ? 'asc' : 'desc';
        } else {
            orderBy.push(key)
            sortedBy.push('asc')
        }

        setFilterData({name: 'orderBy', value: orderBy});
        setFilterData({name: 'sortedBy', value: sortedBy});
    }

    const tableHeaderRender = () => {
        return ModerationProjectsTableHeader.map(item => (
            item.sorted
                ? <th key={item.id}>
                    <div
                        onClick={() => handleOnChangeSorted(item.orderKey)}
                        className="accreditationTable__headerItem accreditationTable__sortedItem"
                    >
                        <div className={classnames(
                            'accreditationTable__sortedIcon',
                            {active: orderBy.includes(item.orderKey) && sortedBy[orderBy.indexOf(item.orderKey)] === 'asc'}
                        )}>
                            <TriangleIcon/>
                        </div>
                        <span>{item.title}</span>
                    </div>
                </th>
                : <th key={item.id} className="accreditationTable__headerItem">
                    <span>{item.title}</span>
                </th>
        ))
    }

    const getBorrowerName = (item) => {
        if (item.project.profile.legal_form_type === 'entity')
            return item?.project?.profile?.legal_form?.company?.name
                || item?.project?.profile?.legal_form?.personal?.fio
                || '';

        return item?.project?.profile?.legal_form?.personal?.fio || ''
    }

    const setModerationProfileId = id => dispatch({
        type: SET_MODERATION_PROFILE_ID,
        payload: id
    })

    const renderTableBody = () => moderationList.map((item, idx) => (
        <tr key={idx}>
            <td>{item.project.id}</td>
            <td>{getBorrowerName(item)}</td>
            <td>{item.project.profile.id}</td>
            <td>{item.project.condition.min}</td>
            <td>{item.project.condition.target}</td>
            <td>{item.project.pledges.length ? 'Да' : 'Нет'}</td>
            <td>{item.project.moderations.length === 1 ? 'Первичная' : 'Повторная'}</td>
            <td>
                {
                    activeTab === 'completion'
                        ? <Link
                            className="moderationProjectsList__link green-color"
                            onClick={() => setModerationProfileId(item.id)}
                            to={`/moderation_projects_results/${item.id}`}
                        >
                            Подробнее
                        </Link>
                        : <div className="accreditationTable__btn">
                            <Link onClick={() => setModerationProfileId(item.id)} to={`/moderation_projects/${item.id}`}>
                                В работу
                            </Link>
                            <div className="accreditationTable__btnIcon">
                                <RightLongArrowIcon/>
                            </div>
                        </div>
                }
            </td>
        </tr>
    ))

    return moderationList.length
        ? <div className={tableClass}>
            <Table className="table--bordered" responsive>
                <colgroup>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                    <col/>
                </colgroup>
                <thead>
                <tr>
                    {tableHeaderRender()}
                </tr>
                </thead>
                <tbody>
                {renderTableBody()}
                </tbody>
            </Table>
            {
                loading
                    ? <div className="accreditationTable__loader">
                        <div className="load__icon-wrap">
                            <svg className="load__icon">
                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                            </svg>
                        </div>
                    </div>
                    : ''
            }
        </div>
        : null
}

export default ModerationProjectsTable;
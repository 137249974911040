import React from 'react';
import Reminder from "@shared/components/projects/projectDetail/ProjectInvest/modal/reminder";

const YourInvestment = () => {
    return (
        <div className='yourInvestment'>
            <span className='yourInvestment__name fontSizeText colorGreyBlue'>Ваша инвестиция </span>
            <span className='yourInvestment__desc greenColor fontSizeSubtitle'>245 500 ₽</span>
            <Reminder/>
        </div>
    )
};

export default YourInvestment;
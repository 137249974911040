import React from 'react';
import ProjectCardRating from "@shared/components/projects/projectDetail/ProjectDetailRating";
import ProjectCardStatus from "@shared/components/projects/projectDetail/ProjectDetailStatus";

const ProjectDetailHeader = ({data}) => {
    let dataFormat = data.created_at.split('.').map(item => Number(item));

    const dataDetail = () => {
        let D = new Date(dataFormat[2], dataFormat[1], dataFormat[0]);
        D.setDate(D.getDate() + data.condition.period);
        return D.toLocaleDateString()
    }

    return (
        <div className='projectDetail__header'>
            <div className='projectDetail__header_roomProject'>
                <p>Проект №{data.id}</p>
                <ProjectCardRating rating={data.project_rating.key}/>
                <span><ProjectCardStatus status={data.moderation_status_key}/></span>
            </div>
            <p className='projectDetail__header_ip'>ИП {data.profile.fio}</p>
            <div className='projectDetail__header_purpose'>
                <div className='projectDetail__header_purposeDesc'>
                    <span>Цель займа: </span>
                    <span>{data.condition.type.value}</span>
                </div>

                {
                    data.moderation_status_key === "projects_collecting"
                        ? <div className='projectDetail__header_collectionUpTo'>
                            <span>Сбор инвестиций до: </span>
                            <span>{dataDetail()}</span>
                        </div>
                        : null
                }

                {
                    data.moderation_status_key === "projects_payment_stage"
                        ? <div className='projectDetail__header_collectionUpTo'>
                            <span>Возврат займа до: </span>
                            <span>{dataDetail()}</span>
                        </div>
                        : null
                }

            </div>
        </div>
    );
};

export default ProjectDetailHeader;
import React from 'react';

const ProgressBar = ({data}) => {
    return (
        <div className='progressBar'>
            {data.moderation_status_key === 'projects_delay'
                ? <div className='progressBar__desc'>
                    <span className='progressBar__desc__title'>Сумма займа</span>
                    <span className='progressBar__desc__data'>{data.condition.target} ₽</span>
                </div>
                : null
            }
            <div className='progressBar__desc'>
                <span className='progressBar__desc__title'>Срок займа</span>
                <span className='progressBar__desc__data'>{data.condition.loan} месяцев</span>
            </div>
            <div className='progressBar__desc'>
                <span className='progressBar__desc__title'>Ставка</span>
                <span className='progressBar__desc__data'>{data.condition.percent}% годовых</span>
            </div>
            <div className='progressBar__desc'>
                <span className='progressBar__desc__title'>Способ погашения</span>
                <span className='progressBar__desc__dataGreen'>{data.condition.repayment.value}</span>
            </div>
            {data.moderation_status_key === 'projects_delay'
                ? null
                : <div className='progressBar__desc'>
                    <span className='progressBar__desc__title'>Минимальная сумма инвестирования </span>
                    <span className='progressBar__desc__data'>{data.condition.min} Р</span>
                </div>
            }
            <div className='progressBar__desc'>
                <span className='progressBar__desc__title'>Инвесторов</span>
                <span className='progressBar__desc__data'>52</span>
            </div>
        </div>
    )
};


export default ProgressBar;
import React from 'react';
import {Button} from "reactstrap";
import classNames from "classnames";
import {showModal} from "@actions/modalActions";
import {useDispatch, useSelector} from "react-redux";
import {QUALIFIED_APPLICATION, QUALIFIED_FORM, QUALIFIED_INVESTOR_DETAIL} from "@shared/components/modal/constants";
import RemarksNotice from "@shared/components/account/accStatusNotice/general/RemarksNotice";

const InvestorRejectedQualifiedNotice = () => {
    const dispatch = useDispatch();
    const remarks = useSelector(state => state.profiles.profileData?.qualification?.remarks || []);

    const btnClass = classNames({
        btn_primary: true,
        confirmQualifiedInvestor__btn: true,
    });

    return (
        <div className="accStatusNotice">
            <div className="accStatusNotice__header">
                <p>Подтверждение статуса квалифицированного инвестора</p>
            </div>
            <div className="accStatusNotice__content">
                <p>
                    К сожалению, ваше заявление не прошло проверку. Повторно загрузите документы,
                    подтверждающие соблюдение условия для получения статуса квалифицированного инвестора.
                </p>
            </div>
            <RemarksNotice data={remarks}/>
            <div className="accStatusNotice__footer">
                <div className="confirmQualifiedInvestor__btnGroup">
                    <Button
                        color="success"
                        className="account__btn_success confirmQualifiedInvestor__btn"
                        onClick={() => showModal(QUALIFIED_APPLICATION)(dispatch)}
                        outline
                    >
                        Открыть заявление
                    </Button>
                    <Button
                        color="primary"
                        className={btnClass}
                        onClick={() => showModal(QUALIFIED_FORM)(dispatch)}
                    >
                        Подтвердить статус
                    </Button>
                </div>
                <div className="accStatusNotice__footer_desc">
                    <button
                        className="accStatusNotice__moreDetails btn-reset"
                        onClick={() => showModal(QUALIFIED_INVESTOR_DETAIL)(dispatch)}
                    >
                        Подробнее о квалификации
                    </button>
                </div>
            </div>
        </div>
    )
}

export default InvestorRejectedQualifiedNotice;
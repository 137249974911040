import InputText from "@shared/components/form/profileForm/InputText";
import React from "react";
import classnames from "classnames";
import {Spinner} from "reactstrap";
import {
    formatData,
    RESET_VALUE,
    SET_DATA,
    SET_EDITABLE_FIELD_KEY,
    SET_ERRORS,
    SET_SAVE_STATUS,
    SET_UPDATED_DATA
} from "./helpers";
import {SettingsProjectRateService} from "@services/settings.service";

const EditingFields = ({fieldKey, fieldKeyTo, multiple, state, switchEdit, dispatch}) => {
    const errorStatus = state.formError.includes(fieldKey) || state.formError.includes(fieldKeyTo);
    const fieldGroupClasses = classnames({
        'platformRates__field_group': true,
        'inputWithDesc': true,
        'platformRates__field_group-error': errorStatus
    })

    const setFormError = errors => dispatch({
        type: SET_ERRORS,
        payload: errors
    })

    const setData = (name, value) => {
        dispatch({
            type: SET_DATA,
            payload: {name, value}
        })
    }

    const resetValue = (fieldFrom, fieldTo) => dispatch({
        type: RESET_VALUE,
        payload: {fieldFrom, fieldTo}
    })

    const saveData = () => {
        dispatch({type: SET_SAVE_STATUS})

        SettingsProjectRateService.update(null, formatData(state.data))
            .then(res => {
                dispatch({type: SET_EDITABLE_FIELD_KEY})
                dispatch({
                    type: SET_UPDATED_DATA,
                    payload: res.data.value
                })
            })
            .catch(() => dispatch({type: SET_SAVE_STATUS}))
    }

    const FieldControls = () => (
        <div className="platformRates__controls">
            {
                state.saveStatus
                    ? <div className="platformRates__controls_loading">
                        <Spinner className={`btn-spinner green-color ${state.saveStatus ? 'btn-spinner-visible' : ''}`}/>
                    </div>
                    : <button
                        className="green-color btn-reset"
                        onClick={saveData}
                        disabled={errorStatus}
                    >
                        Сохранить
                    </button>
            }
            <button
                className="error-color btn-reset"
                onClick={() => resetValue(fieldKey, fieldKeyTo)}
            >
                Отменить
            </button>
        </div>
    )

    if (multiple) {
        return (
            <div className="platformRates__editGroup">
                <div className={fieldGroupClasses}>
                    <InputText
                        noLabel
                        noErrorMessage
                        name={fieldKey}
                        onChange={e => setData(fieldKey, +(e.target.value.replace(/\D/g, '')))}
                        value={state.data[fieldKey] || ''}
                        inputWithDesc
                        inputWithDescLeft
                        inputWithDescValue="от"
                        validator={[{maxNumber: 100}, {required: true}]}
                        formError={state.formError}
                        setFormError={setFormError}
                        containerClassName="platformRates__field"
                        rank={true}
                    />
                    <InputText
                        noLabel
                        noErrorMessage
                        name={fieldKeyTo}
                        onChange={e => setData(fieldKeyTo, +(e.target.value.replace(/\D/g, '')))}
                        value={state.data[fieldKeyTo] || ''}
                        inputWithDesc
                        inputWithDescLeft
                        inputWithDescValue="до"
                        validator={[{maxNumber: 100}, {required: true}]}
                        formError={state.formError}
                        setFormError={setFormError}
                        containerClassName="platformRates__field"
                        rank={true}
                    />
                </div>
                <FieldControls/>
            </div>
        )
    } else {
        return (
            <div className="platformRates__editField">
                <InputText
                    noLabel
                    noErrorMessage
                    name={fieldKey}
                    onChange={e => setData(fieldKey, e.target.value)}
                    value={state.data[fieldKey] || ''}
                    inputWithDesc
                    inputWithDescValue={fieldKey === 'support' ? '₽' : "%"}
                    validator={[{maxNumber: fieldKey === 'support' ? null : 100}, {decimalOrNumeric: true}, {required: true}]}
                    formError={state.formError}
                    setFormError={setFormError}
                />
                <FieldControls/>
            </div>
        )
    }
}

export default EditingFields;
import React, {useEffect, useMemo, useReducer, useState} from 'react';
import Masonry from "react-masonry-css";
import {errorInitialData, initialData} from './helpers/initialData';
import {errorReducer, reducer} from './helpers/reducer';
import {Button, Spinner} from "reactstrap";
import {usePresenter} from "@account/Profile/components/ProfileData/hooks/usePresenter";
import CompanyData from "@shared/components/account/EditProfile/CompanyData";
import {SET_RESET_VALIDATE} from "@shared/components/account/EditProfile/helpers/constants";
import PersonalData from "@shared/components/account/EditProfile/PersonalData";
import PassportData from "@shared/components/account/EditProfile/PassportData";
import AdditionalInfo from "@shared/components/account/EditProfile/AdditionalInfo";
import {profileDataFormatter} from "@shared/components/account/EditProfile/utils";
import {OuterProfilesService} from "@services/outer.service";
import {getProfileData, getProfiles} from "@actions/profilesActions";
import {useDispatch, useSelector} from "react-redux";
import {ACCREDITATION_PROFILE} from "@shared/components/modal/constants";
import {showModal, unsetUpdateParentData} from "@actions/modalActions";

const EditProfile = ({profileData}) => {
    const [state, dispatch] = useReducer(reducer, profileData, initialData);
    const dispatch2 = useDispatch()
    const [errorState, errorDispatch] = useReducer(errorReducer, profileData, errorInitialData);
    const {setFormEditStatus} = usePresenter();
    const [saveStatus, setSaveStatus] = useState(false);
    const [saveAsStatusStatus, setSaveAsStatusStatus] = useState(false);
    const {user, legal_form_type} = profileData;
    const saveBtnDisabled = useMemo(() => (
        errorState.company.length
        || errorState.personal.length
        || errorState.passport.length
        || errorState.additionalInfo.filter(item => item.length).length
    ), [errorState]);
    const updateParentData = useSelector(state => state.modal.updateParentData);


    const setResetValidate = () => dispatch({type: SET_RESET_VALIDATE});

    useEffect(() => {
        setResetValidate()
    }, [])

    const saveData = () => {
        setResetValidate();
        if (!saveBtnDisabled) {
            const dataFormat = profileDataFormatter(state, legal_form_type);
            showModal(ACCREDITATION_PROFILE, dataFormat)(dispatch2)
        }
    }

    const saveAsDraft = () => {
        setSaveAsStatusStatus(true);
        const dataFormat = profileDataFormatter(state, legal_form_type);

        OuterProfilesService.update(null, {...dataFormat, is_draft: true})
            .then(() => {
                setFormEditStatus()
                getProfileData()(dispatch2)
                getProfiles()(dispatch2)
            })
            .catch(error => console.log(error))
            .finally(() => setSaveAsStatusStatus(false));
    }

    useEffect(()=>{
        if (updateParentData > 0){
            setFormEditStatus()
            getProfileData()(dispatch2)
            getProfiles()(dispatch2)
            unsetUpdateParentData()(dispatch2)
        }
    }, [updateParentData])

    return (
        <>
            <Masonry
                breakpointCols={{default: 2, 1400: 1}}
                className="my-masonry-grid custom-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {
                    legal_form_type === 'entity'
                        ? <CompanyData
                            data={state.company}
                            formError={errorState.company}
                            dispatch={dispatch}
                            errorDispatch={errorDispatch}
                            resetValidate={state.resetValidate}
                        /> : null
                }
                <PersonalData
                    data={state.personal}
                    formError={errorState.personal}
                    dispatch={dispatch}
                    errorDispatch={errorDispatch}
                    resetValidate={state.resetValidate}
                    user={user}
                    legal_form_type={legal_form_type}
                />
                <PassportData
                    data={state.passport}
                    formError={errorState.passport}
                    dispatch={dispatch}
                    errorDispatch={errorDispatch}
                    resetValidate={state.resetValidate}
                    birth_date={state.personal.birth_date}
                />
                {
                    state.additionalInfo.map((item, idx) => (
                        <div key={idx} className="profileTabs__addInfo_item">
                            <AdditionalInfo
                                data={item}
                                additionalInfo={state.additionalInfoResponses}
                                formError={errorState.additionalInfo[idx]}
                                dispatch={dispatch}
                                errorDispatch={errorDispatch}
                                number={idx}
                                resetValidate={state.resetValidate}
                            />
                        </div>
                    ))
                }
            </Masonry>
            <div className="accountProfiles__controls">
                <Button color="danger" size="sm" outline onClick={setFormEditStatus}>
                    Отменить
                </Button>
                <Button color="success" size="lg" outline onClick={saveAsDraft}>
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${saveAsStatusStatus ? 'btn-spinner-visible' : ''}`}/>
                        Сохранить как черновик
                    </div>
                </Button>
                <Button color="primary" size="lg" onClick={saveData}>
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${saveStatus ? 'btn-spinner-visible' : ''}`}/>
                        Продолжить
                    </div>
                </Button>
            </div>
        </>
    );
};

export default EditProfile;
import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {BankDetailService} from '@services/bankDetail.service';
import {getProfileData} from "@actions/profilesActions";

const DesignateAccountMain = ({hideModal}) => {
    const dispatch = useDispatch();
    const {editBankTemplate} = useSelector(state => state.profiles);
    const [spinnerStatus, setSpinnerStatus] = useState(false);

    const handleSendForm = () => {
        if (editBankTemplate) {
            setSpinnerStatus(true)
            BankDetailService.update(`${editBankTemplate}/master_account`, {})
                .then(() => {
                    getProfileData()(dispatch).then(() => hideModal()(dispatch))
                })
                .catch(() => {
                })
                .finally(() => setSpinnerStatus(false))
        } else {
            hideModal()(dispatch)
        }
    }

    return (
        <div className="designateAccountMain">
            <p className="modalFullScreen__title">
                Вы уверены, что хотите назначить этот счет основным?
            </p>
            <p className="modalFullScreen__desc">
                После этого профиль будет отправлен на проверку. На этот период доступ к платформе будет частично
                ограничен
            </p>
            <div className="modalFullScreen__btnGroup">
                <Button
                    color="primary"
                    className="account__btn account__btn_primary"
                    onClick={handleSendForm}
                >
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                        Продолжить
                    </div>
                </Button>
                <Button
                    onClick={() => hideModal()(dispatch)}
                    color="primary"
                    className="account__btn account__btn_cancel"
                    outline
                >
                    Отмена
                </Button>
            </div>
        </div>
    )
}

export default DesignateAccountMain;
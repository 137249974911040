import Select from "react-select";
import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {CalendarIcon} from "@shared/img/iconSvg";
import UploadField from "@shared/components/uploadField";
import InputText from "@shared/components/form/profileForm/InputText";
import {SET_PROPERTY_VALUE} from "@actions/projectsActions";
import {propertyContracts, propertyPremiseContractSelectOptions, propertyPremiseSelectOptions} from '../../data';
import {getPropertyContract} from "../../helpers";

const Premise = ({data, setData, formError, setFormError, pledgeId, projectId}) => {
    const dispatch = useDispatch();
    const [selectValue, setSelectValue] = useState({value: 'residential', label: 'Жилое'});
    const {
        address, certificate_date, certificate_number, certificates, documents, extract_egrn_from,
        extracts, floors, floors_number, images, number, other_information, plans, residential_total_area,
        rooms, total_area, contract, contract_type
    } = data;
    const contractTypeInit = propertyPremiseContractSelectOptions.filter(item => item.value === contract_type);
    const [contractType, setContractType] = useState(contractTypeInit[0]);

    const handleOnChangeSelect = (key, data, setSelect) => {
        setSelect(data);
        dispatch({
            type: SET_PROPERTY_VALUE,
            payload: {
                pledgeId,
                typeKey: 'premise',
                name: key,
                value: data.value
            }
        })
    }

    const handleOnChangeContract = data => {
        setContractType(data);
        dispatch({
            type: SET_PROPERTY_VALUE,
            payload: {
                pledgeId,
                typeKey: 'premise',
                name: 'contract_type',
                value: data.value
            }
        })

        setData({
            target: {
                name: 'contract',
                value: propertyContracts[data.value] || null
            }
        })
    }

    return (
        <>
            <p className="addProject__form_title font-weight-normal">Нежилое помещения</p>
            <InputText
                formError={formError}
                name="address"
                id="addressInput"
                onChange={setData}
                label="Адрес"
                setFormError={setFormError}
                type="text"
                value={address || ''}
                validator={[{required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                formError={formError}
                name="number"
                id="numberInput"
                onChange={setData}
                label="Условный / кадастровый номер"
                setFormError={setFormError}
                type="text"
                value={number || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
                tooltip="указать как в Свидетельстве о гос. регистрации права или выписке из ЕГРН"
                tooltipClass="smallTooltip"
            />
            <InputText
                name="total_area"
                id="totalAreaInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Общая площадь помещений"
                value={total_area || ''}
                labelClass="requiredMark"
                validator={[{maxLength: 10}, {onlyNumbers: true}, {required: true}]}
                inputWithDesc
                inputWithDescValue={<>м<sup>2</sup></>}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="residential_total_area"
                id="residentialTotalAreaInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Общая площадь здания"
                value={residential_total_area || ''}
                labelClass="requiredMark"
                validator={[{maxLength: 10}, {onlyNumbers: true}, {required: true}]}
                inputWithDesc
                inputWithDescValue={<>м<sup>2</sup></>}
                containerClassName="addProject__middleInput"
            />
            <InputText
                name="rooms"
                id="roomsInput"
                onChange={setData}
                label="Номер(а) комнат(ы)"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={rooms || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
            />
            <InputText
                name="floors"
                id="floorsInput"
                onChange={setData}
                label="Этажи"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={floors || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
            />
            <InputText
                name="floors_number"
                id="floorsNumberInput"
                onChange={setData}
                label="Общая этажность здания"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={floors_number || ''}
                validator={[{maxLength: 10}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
            />
            <div className="inputText__wrapper addProject__middleInput">
                <label>Тип здания</label>
                <div className="pledge__formSelect">
                    <div className="pledge__formSelect_wrap">
                        <Select
                            options={propertyPremiseSelectOptions}
                            value={selectValue}
                            isSearchable={false}
                            className="pledge__select"
                            classNamePrefix="pledge__select"
                            onChange={data => handleOnChangeSelect('premise_type', data, setSelectValue)}
                        />
                    </div>
                </div>
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Технический план (скан)</label>
                <UploadField
                    files={plans}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Технический план"
                    onChange={setData}
                    fileKey="plans"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <div className="pledge__inputGroup inputText__wrapper">
                <label className="requiredMark">Свидетельство о праве собственности</label>
                <div className="pledge__inputGroup_inner">
                    <InputText
                        name="certificate_number"
                        id="certificateNumberInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setData}
                        noLabel={true}
                        value={certificate_number || ''}
                        validator={[{maxLength: 50}, {required: true}]}
                        inputWithDesc
                        inputWithDescValue="№"
                        inputLabelLeft={true}
                        containerClassName="addProject__middleInput"
                    />
                    <InputText
                        name="certificate_date"
                        id="certificateDateInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setData}
                        noLabel={true}
                        type="date"
                        value={certificate_date || ''}
                        validator={[{required: true}]}
                        inputWithDesc
                        inputWithDescValue="от"
                        inputLabelLeft={true}
                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                    />
                </div>
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Скан свидетельства о праве собственности</label>
                <UploadField
                    files={certificates}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан свидетельства о праве собственности"
                    onChange={setData}
                    fileKey="certificates"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <InputText
                name="extract_egrn_from"
                id="extractEgrnFromInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Выписка из Единого государственного реестра недвижимости, дата"
                type="date"
                value={extract_egrn_from || ''}
                validator={[{required: true}]}
                labelClass="requiredMark"
                inputWithDesc
                inputWithDescValue={<CalendarIcon/>}
                containerClassName="addProject__middleInput addProject__dateInput date-picker"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Скан выписки</label>
                <UploadField
                    files={extracts}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Скан выписки из ЕГРН"
                    onChange={setData}
                    fileKey="extracts"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                    required={true}
                />
            </div>
            <InputText
                rows={2}
                type="textarea"
                onChange={setData}
                formError={formError}
                name="other_information"
                value={other_information || ''}
                id="otherInformationInput"
                setFormError={setFormError}
                validator={[{maxLength: 500}]}
                label="Иные сведения"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Иные документы</label>
                <UploadField
                    files={documents}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Иные документы"
                    onChange={setData}
                    fileKey="documents"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Фото залога</label>
                <UploadField
                    files={images}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Фото залога"
                    onChange={setData}
                    fileKey="images"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png"
                    required={true}
                />
            </div>
            <div className="inputText__wrapper addProject__fullInput">
                <label className="requiredMark">Имущественные права на земельный участок</label>
                <div className="pledge__formSelect">
                    <div className="pledge__formSelect_wrap">
                        <Select
                            options={propertyPremiseContractSelectOptions}
                            value={contractType}
                            isSearchable={false}
                            className="pledge__select"
                            classNamePrefix="pledge__select"
                            onChange={handleOnChangeContract}
                        />
                    </div>
                </div>
            </div>
            {
                getPropertyContract(
                    contractType.value, contract, formError, setFormError, pledgeId, 'premise'
                )
            }
        </>
    )

}

export default Premise;
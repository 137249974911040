import {BaseService} from "@services/base.service";

export class ProjectsBorrowerService extends BaseService {
    static get entity() {
        return '/borrower/projects'
    }
}

export class ProjectsService extends BaseService {
    static get entity() {
        return 'projects'
    }
}


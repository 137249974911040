import React, {useEffect, useReducer, useState} from 'react';
import {reducer} from "@shared/components/account/helpers";
import InputText from "@shared/components/form/profileForm/InputText";
import {Button, Col, Row, Spinner} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {DESIGNATE_ACCOUNT_MAIN} from "@shared/components/modal/constants";
import {
    ADD_NEW_BANK_TEMPLATE,
    checkForAccreditation,
    getProfileData,
    //getProfiles,
    SET_BANK_DETAILS
} from "@actions/profilesActions";
import {useDispatch, useSelector} from "react-redux";
import {BankDetailService} from '@services/bankDetail.service';
import {isBorrower} from "@utils/profile";

const EditBankDetails = ({data, setEdit}) => {
    const {name, bik, inn, correspondent_account, checking_account, id, newTemplate, master_account} = data || {};
    const dispatch = useDispatch();
    const {editProfileId, profileData} = useSelector(state => state.profiles);
    const [spinnerStatus, setSpinnerStatus] = useState(false)
    const [formError, setFormError] = useState([]) // Ошибочные поля
    const [formValidate, setFormValidate] = useState(false) // Валидность всех полей формы
    const visibleBtnMakeMain = () => isBorrower() && !newTemplate && !master_account;

    const initialState = {
        name: name || '',
        bik: bik || '',
        inn: inn || '',
        correspondent_account: correspondent_account || '',
        checking_account: checking_account || '',
    }

    const [formData, setFormData] = useReducer(reducer, initialState);

    const handleOnChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        })
    }

    const handleCancelOnClick = () => {
        if (newTemplate) {
            const newBankData = profileData.bank_details ? [...profileData.bank_details] : [];

            if (newBankData.length) {
                newBankData.shift()

                dispatch({
                    type: SET_BANK_DETAILS,
                    payload: newBankData
                })
                dispatch({type: ADD_NEW_BANK_TEMPLATE, payload: false})
            }
        } else {
            setEdit(false)
        }
    }

    const handleSendForm = () => {
        const formDataTrim = {};

        if (formValidate && formError.length === 0) {
            setSpinnerStatus(true)

            // У строк удаляем лишние пробелы
            for (let key in formData) {
                if (typeof formData[key] === 'string') {
                    formDataTrim[key] = formData[key].replace(/\s+/g, ' ').trim()
                } else {
                    formDataTrim[key] = formData[key]
                }
            }

            if (newTemplate) {
                BankDetailService.create(formDataTrim, editProfileId)
                    .then(() => {
                        setEdit(false)
                        getProfileData()(dispatch)
                        dispatch({type: ADD_NEW_BANK_TEMPLATE, payload: false})
                    })
                    .catch(() => {
                    })
                    .finally(() => setSpinnerStatus(false))
            } else {
                BankDetailService.update(id, formDataTrim)
                    .then(() => {
                        checkForAccreditation(dispatch)
                        getProfileData()(dispatch)
                        setSpinnerStatus(false)
                        setEdit(false)
                    })
                    .catch(() => setSpinnerStatus(false))

                // Проверить заполнены ли все поля для перехода к аккредитации

            }
        }
    }

    useEffect(() => {
        let validateStatus = true;
        let identical = true;

        for (let key in formData) {
            if (('' + formData[key]).length === 0) validateStatus = false
        }

        if (validateStatus) {
            for (let key in formData) {
                if ((initialState[key] !== formData[key])) identical = false
            }

            if (identical) {
                setFormValidate(false)
            } else {
                setFormValidate(true)
            }

        } else {
            setFormValidate(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const handleClickBtnMakeMain = () => {
        showModal(DESIGNATE_ACCOUNT_MAIN)(dispatch)
        setEdit(false)
    }

    return (
        <div className="profileTabs__edit">
            <InputText
                type="text"
                name="name"
                id="name"
                label="Наименование банка или БИК"
                onChange={handleOnChange}
                value={formData.name}
                validator={[{required: true}, {maxLength: 300}]}
                containerClassName="profileTabs__edit_item"
                formError={formError}
                setFormError={setFormError}
            />
            <Row>
                <Col md={6}>
                    <InputText
                        type="mask"
                        name="bik"
                        id="bik"
                        label="БИК"
                        onChange={handleOnChange}
                        value={formData.bik}
                        validator={[{required: true}, {numbersCount: 9}]}
                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        formError={formError}
                        setFormError={setFormError}
                        containerClassName="profileTabs__edit_item"
                    />
                </Col>
                <Col md={6}>
                    <InputText
                        type="mask"
                        name="inn"
                        id="inn"
                        label="ИНН банка"
                        onChange={handleOnChange}
                        value={formData.inn}
                        validator={[{required: true}, {numbersCount: 10}]}
                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        formError={formError}
                        setFormError={setFormError}
                        containerClassName="profileTabs__edit_item"
                    />
                </Col>
            </Row>
            <InputText
                type="mask"
                guide={false}
                name="correspondent_account"
                id="correspondent_account"
                label="Корр. счет"
                onChange={handleOnChange}
                value={formData.correspondent_account}
                validator={[{required: true}, {numbersCountBig: 20}]}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                formError={formError}
                setFormError={setFormError}
                containerClassName="profileTabs__edit_item"
            />
            <InputText
                type="mask"
                guide={false}
                name="checking_account"
                id="checking_account"
                label="Расчетный счет"
                onChange={handleOnChange}
                value={formData.checking_account}
                validator={[{required: true}, {numbersCountBig: 20}]}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                formError={formError}
                setFormError={setFormError}
                containerClassName="profileTabs__edit_item"
            />
            <div className={`profileTabs__edit_btn-group ${visibleBtnMakeMain() ? 'profileTabs__edit_threeBtn' : ''}`}>
                <Button
                    color="primary"
                    className="account__btn account__btn_primary"
                    disabled={!formValidate || !!formError.length}
                    onClick={handleSendForm}
                >
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                        Сохранить
                    </div>
                </Button>
                <Button
                    onClick={handleCancelOnClick}
                    color="primary"
                    className="account__btn account__btn_cancel"
                    outline
                >
                    Отмена
                </Button>
                {
                    visibleBtnMakeMain()
                        ? <Button
                            onClick={handleClickBtnMakeMain}
                            color="success"
                            className="account__btn account__btn_success"
                            outline
                        >
                            Сделать основным
                        </Button>
                        : ''
                }
            </div>
        </div>
    )
}

export default EditBankDetails;
import constants from './action';

const initState = {
    data: {
        fio: '',
        role: '',
        email: '',
        phone: '',
        company: '',
        password: '',
        email_code: '',
        phone_code: '',
        legal_form_type: '',
        without_patronymic: false,
        confidentiality_acceptance: false
    },
    formError: [],
    visibleSecondsStage: false,
    noteRoleBtn: false,
    confirmationFieldServiceType: constants.CONFIRMATION_FIELD_VISIBLE_CONFIRM_BTN,
    confirmationFieldResetValidate: false,
    visibleConfirmCodeField: false,
    verificationDataConfirmed: false,
    showPassword: false,
    successfulPassword: false,
    formValidationCheck: false,
}

const reducer = (state, action) => {
    const {type, payload} = action || {};

    switch (type) {
        case constants.SET_DATA_VALUE:
            const {name, value} = payload || {};

            return {
                ...state,
                data: {
                    ...state.data,
                    [name]: value
                }
            }
        case constants.SET_LEGAL_FORM_TYPE:
            return {
                ...state,
                data: {
                    ...state.data,
                    legal_form_type: action.payload,
                    fio: '',
                    company: '',
                    without_patronymic: false
                },
                formError: state.formError.filter(item => !['fio', 'company'].includes(item))
            }
        case constants.SET_VISIBLE_SECOND_STAGE:
            return {
                ...state,
                visibleSecondsStage: true,
                noteRoleBtn: false,
                confirmationFieldServiceType: constants.CONFIRMATION_FIELD_VISIBLE_CONFIRM_BTN,
                confirmationFieldResetValidate: false,
                visibleConfirmCodeField: false,
                verificationDataConfirmed: false,
                formValidationCheck: false,
            }
        default:
            return {
                ...state,
                [action.name]: action.value
            }
    }
}

export {initState, reducer}
import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {sendForAccreditation} from "@actions/accreditationAction";

const ApplicationQualifiedInvestor = ({hideModal}) => {
    const dispatch = useDispatch();
    const [spinnerStatus, setSpinnerStatus] = useState(false);
    const editProfileId = useSelector(state => state.profiles.editProfileId);

    const confirmAccreditation = () => {
        setSpinnerStatus(true)
        sendForAccreditation(editProfileId, dispatch)
            .finally(() => {
                setSpinnerStatus(false);
                hideModal();
            })
    }

    return (
        <div className="designateAccountMain addNewProfile accreditationModal">
            <p className="modalFullScreen__title">
                Заявление о признании физического лица квалифицированным инвестором
            </p>
            <div className="modalFullScreen__btnGroup">
                <Button
                    color="primary"
                    className="account__btn account__btn_primary"
                    onClick={confirmAccreditation}
                >
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                        Подтвердить и подписать КЭП
                    </div>
                </Button>
                <Button
                    onClick={hideModal}
                    color="primary"
                    className="account__btn account__btn_cancel"
                    outline
                >
                    Отмена
                </Button>
            </div>
        </div>
    )
}

export default ApplicationQualifiedInvestor;
//https://ourcodeworld.com/articles/read/322/how-to-convert-a-base64-image-into-a-image-file-and-upload-it-with-an-asynchronous-form-using-jquery
//Преобразование строки base64 в Blob в соответствии с data и contentType.
import {get} from "@utils/storage";

const accessToken = get("accessToken");

const myHeaders = new Headers();
myHeaders.append("Authorization", accessToken ? `Bearer ${accessToken}` : '');
myHeaders.append("Access-Control-Allow-Origin", "*")

const requestOptions = {
    method: 'GET',
    headers: myHeaders,
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

// Получить изображение в blob
export const imageToBlob = (url = '') => {
    return fetch(url, {method: 'GET'})
        .then(response => {
            if (!response.ok) return null;
            return response.blob()
        })
        .then(blob => {
            return URL.createObjectURL(blob) || null
        })
        .catch(() => {
        });
}

// Скачать файл
export const downloadBlobFile = (id, fileName) => {
    fetch(`${process.env.REACT_APP_API_URL}/files/download/${id}`, requestOptions)
        .then(response => {
            return response.blob()
        })
        .then(blob => {
            const link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName || 'file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(() => {
        });
}


export const getBlobUrl = id => {
    return fetch(`${process.env.REACT_APP_API_URL}/files/download/${id}`, requestOptions)
        .then(response => {
            return response.blob()
        })
        .then(blob => {
            return window.URL.createObjectURL(blob)
        })
        .catch(() => {
        });
}






import React, {useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import Select from "react-select";
import {
    propertyLandSelectOptions,
    propertyRentConfirmedSelectOptions,
    propertyUsesTypeSelectOptions
} from "@containers/Projects/AddProject/forms/Pledge/data";
import {EDIT_PLEDGE_CONTRACT_VALUE} from "@actions/projectsActions";
import {useDispatch} from "react-redux";
import UploadField from "@shared/components/uploadField";

const Uses = ({data, formError, setFormError, pledgeId, typeKey, projectId}) => {
    const dispatch = useDispatch();
    const [usesText, setUsesText] = useState('');
    const [usesDate, setUsesDate] = useState('');
    const [selectValue, setSelectValue] = useState({value: 'ga', label: 'га'});
    const [rentTypeValue, setRentTypeValue] = useState({value: 'year', label: 'лет'});
    const [rentConfirmedSelectValue, setRentConfirmedSelectValue] = useState({
        value: 'contract',
        label: 'Договор'
    });
    const {
        address, number, total_area, land_category, permitted_use, other_information,
        uses, rent_confirmed, contract_name, contract_date, contract_number, contract_tenant, contract_registered_date,
        contract_registered_company, contract_registered_number, order_company, order_number, order_date, documents
    } = data

    const setContractData = event => {
        dispatch({
            type: EDIT_PLEDGE_CONTRACT_VALUE,
            payload: {
                pledgeId,
                typeKey,
                name: event.target.name,
                value: event.target.value || null,
            }
        })
    }

    const setContractRentData = (event, type) => {
        setContractData(event);
        if (type === 'usesText') {
            if (usesDate) setUsesDate('');
            setUsesText(event.target.value);
        }
        if (type === 'usesDate') {
            if (usesText) setUsesText('')
            setUsesDate(event.target.value)
        }
    }

    const handleOnChangeSelect = (data, type, setSelect) => {
        setSelect(data);

        if (type === 'uses_type' && data.value === 'infinity') {
            setContractData({target: {name: 'uses', value: null}})
        }

        dispatch({
            type: EDIT_PLEDGE_CONTRACT_VALUE,
            payload: {
                pledgeId,
                typeKey,
                name: type,
                value: data.value,
            }
        })
    }

    return (
        <>
            <p className="addProject__form_title font-weight-normal">Право пользования земельным участком</p>
            <InputText
                formError={formError}
                name="address"
                id="addressInput"
                onChange={setContractData}
                label="Адрес"
                setFormError={setFormError}
                type="text"
                value={address || ''}
                validator={[{maxLength: 200}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                formError={formError}
                name="number"
                id="numberInput"
                onChange={setContractData}
                label="Кадастровый номер"
                setFormError={setFormError}
                type="text"
                value={number || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
            />
            <div className="addProject__landSelectGroup">
                <InputText
                    name="total_area"
                    id="totalAreaInput"
                    formError={formError}
                    setFormError={setFormError}
                    onChange={setContractData}
                    label="Площадь"
                    value={total_area || ''}
                    labelClass="requiredMark"
                    validator={[{maxLength: 10}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue={
                        <Select
                            options={propertyLandSelectOptions}
                            value={selectValue}
                            isSearchable={false}
                            className="pledge__select pledge__landSelect_wrap"
                            classNamePrefix="pledge__landSelect"
                            onChange={data => handleOnChangeSelect(data, 'total_area_type', setSelectValue)}
                        />
                    }
                    containerClassName="addProject__middleInput"
                />
            </div>
            <InputText
                name="land_category"
                id="landCategoryInput"
                onChange={setContractData}
                label="Категория земель"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={land_category || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                rows={2}
                type="textarea"
                onChange={setContractData}
                formError={formError}
                name="permitted_use"
                value={permitted_use || ''}
                id="permittedUseInput"
                setFormError={setFormError}
                validator={[{maxLength: 200}, {required: true}]}
                labelClass="requiredMark"
                label="Разрешенное использование"
                containerClassName="addProject__fullInput"
            />
            <div className="pledge__inputGroup inputText__wrapper">
                <label className="requiredMark">Срок пользования</label>
                <div className="pledge__inputGroup_inner">
                    <div className="addProject__landSelectGroup lease_rentText mt-0">
                        <InputText
                            name="uses"
                            id="usesTextInput"
                            formError={formError}
                            setFormError={setFormError}
                            onChange={event => setContractRentData(event, 'usesText')}
                            noLabel={true}
                            value={usesDate ? '' : uses || ''}
                            disabled={rentTypeValue.value === 'infinity'}
                            validator={[{maxLength: 5}]}
                            inputWithDesc
                            inputWithDescValue={
                                <Select
                                    options={propertyUsesTypeSelectOptions}
                                    value={rentTypeValue}
                                    isSearchable={false}
                                    className="pledge__select pledge__landSelect_wrap usesSelect"
                                    classNamePrefix="pledge__landSelect"
                                    onChange={data => handleOnChangeSelect(data, 'uses_type', setRentTypeValue)}
                                />
                            }
                            containerClassName="addProject__middleInput"
                        />
                    </div>
                    <div className="pledge__inputGroup_desc">
                        <span>или</span>
                    </div>
                    <InputText
                        name="uses"
                        id="usesDateInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={event => setContractRentData(event, 'usesDate')}
                        noLabel={true}
                        type="date"
                        disabled={rentTypeValue.value === 'infinity'}
                        value={usesText ? '' : uses || ''}
                        inputWithDesc
                        inputWithDescValue="до"
                        inputLabelLeft={true}
                        containerClassName="addProject__middleInput addProject__dateInput date-picker"
                    />
                </div>
            </div>
            <div className="inputText__wrapper addProject__fullInput">
                <label className="requiredMark">Подтверждающий документ</label>
                <div className="pledge__formSelect">
                    <div className="pledge__formSelect_wrap">
                        <Select
                            options={propertyRentConfirmedSelectOptions}
                            value={rentConfirmedSelectValue}
                            isSearchable={false}
                            className="pledge__select"
                            classNamePrefix="pledge__select"
                            onChange={data => handleOnChangeSelect(data, 'rent_confirmed', setRentConfirmedSelectValue)}
                        />
                    </div>
                </div>
            </div>

            {
                rent_confirmed === 'contract'
                    ? <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                        <label className="requiredMark">Договор</label>
                        <div className="contractBlock inputErrorGroup">
                            <span>Договор</span>
                            <InputText
                                formError={formError}
                                name="contract_name"
                                id="contractNameInput"
                                onChange={setContractData}
                                noLabel={true}
                                setFormError={setFormError}
                                type="text"
                                value={contract_name || ''}
                                validator={[{maxLength: 50}, {required: true}]}
                                containerClassName="addProject__contractInput addProject__contractInput_name"
                            />
                            <span>(наименование договора) от</span>
                            <InputText
                                name="contract_date"
                                id="contractDateInput"
                                formError={formError}
                                setFormError={setFormError}
                                onChange={setContractData}
                                noLabel={true}
                                type="date"
                                value={contract_date || ''}
                                validator={[{required: true}]}
                                containerClassName="addProject__contractInput addProject__dateInput date-picker addProject__contractInput_date"
                            />
                            <span>№</span>
                            <InputText
                                formError={formError}
                                name="contract_number"
                                id="contractNumberInput"
                                onChange={setContractData}
                                noLabel={true}
                                setFormError={setFormError}
                                type="text"
                                value={contract_number || ''}
                                validator={[{maxLength: 50}, {required: true}]}
                                containerClassName="addProject__contractInput addProject__contractInput_name addProject__contractInput_number"
                            />
                            <span>, заключенный между</span><br/>
                            <span>Залогодателем и</span>
                            <InputText
                                formError={formError}
                                name="contract_tenant"
                                id="contractTenantInput"
                                onChange={setContractData}
                                noLabel={true}
                                setFormError={setFormError}
                                type="text"
                                value={contract_tenant || ''}
                                validator={[{maxLength: 100}, {required: true}]}
                                containerClassName="addProject__contractInput addProject__contractInput_tenant"
                            />
                            <span>, и зарегистрированным</span>
                            <InputText
                                name="contract_registered_date"
                                id="contractRegisteredDateInput"
                                formError={formError}
                                setFormError={setFormError}
                                onChange={setContractData}
                                noLabel={true}
                                type="date"
                                value={contract_registered_date || ''}
                                validator={[{required: true}]}
                                containerClassName="addProject__contractInput addProject__dateInput date-picker addProject__contractInput_date"
                            />
                            <span>года</span><br/>
                            <InputText
                                formError={formError}
                                name="contract_registered_company"
                                id="contractRegisteredCompanyInput"
                                onChange={setContractData}
                                noLabel={true}
                                setFormError={setFormError}
                                type="text"
                                value={contract_registered_company || ''}
                                validator={[{maxLength: 100}, {required: true}]}
                                containerClassName="addProject__contractInput addProject__contractInput_name"
                            />
                            <span>
                    (указать наименование органа, зарегистрировавшего договор аренды – из
                    штампа о регистрации) за номером регистрации
                  </span>
                            <InputText
                                formError={formError}
                                name="contract_registered_number"
                                id="contractRegisteredNumberInput"
                                onChange={setContractData}
                                noLabel={true}
                                setFormError={setFormError}
                                type="text"
                                value={contract_registered_number || ''}
                                validator={[{maxLength: 50}, {required: true}]}
                                containerClassName="addProject__contractInput addProject__contractInput_name"
                            />
                        </div>
                    </div>
                    : ''
            }

            {
                rent_confirmed === 'order'
                    ? <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                        <label className="requiredMark">Распоряжение</label>
                        <div className="contractBlock inputErrorGroup">
                            <span>Распоряжение</span>
                            <InputText
                                formError={formError}
                                name="order_company"
                                id="orderCompanyInput"
                                onChange={setContractData}
                                noLabel={true}
                                setFormError={setFormError}
                                type="text"
                                value={order_company || ''}
                                validator={[{maxLength: 100}, {required: true}]}
                                containerClassName="addProject__contractInput addProject__contractInput_name"
                            />
                            <span>(указать наименование органа, принявшего решение о предоставлении участка в аренду) от</span>
                            <InputText
                                name="order_date"
                                id="orderDateInput"
                                formError={formError}
                                setFormError={setFormError}
                                onChange={setContractData}
                                noLabel={true}
                                type="date"
                                value={order_date || ''}
                                validator={[{required: true}]}
                                containerClassName="addProject__contractInput addProject__dateInput date-picker addProject__contractInput_date"
                            />
                            <span>года №</span>
                            <InputText
                                formError={formError}
                                name="order_number"
                                id="orderNumberInput"
                                onChange={setContractData}
                                noLabel={true}
                                setFormError={setFormError}
                                type="text"
                                value={order_number || ''}
                                validator={[{maxLength: 50}, {required: true}]}
                                containerClassName="addProject__contractInput addProject__contractInput_name addProject__contractInput_number"
                            />
                            <span>.</span>
                        </div>
                    </div>
                    : ''
            }
            <InputText
                rows={2}
                type="textarea"
                onChange={setContractData}
                formError={formError}
                name="other_information"
                value={other_information || ''}
                id="otherInformationInput"
                setFormError={setFormError}
                validator={[{maxLength: 500}]}
                label="Иные сведения"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Иные документы</label>
                <UploadField
                    files={documents}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Иные документы"
                    onChange={setContractData}
                    fileKey="documents"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
        </>
    )
}

export default Uses;
import React from 'react';
import {projectDetailMenuDesc, projectDetailMenuDescInvestor} from "./helper";
import classnames from "classnames";
import {useSelector} from "react-redux";

const ProjectDetailMenu = ({data, activeTab, setActiveTab}) => {
    const role = useSelector(state => state.user.user.roles[0].name);

    return (
        <div className="projectDetail__tabs_header">
            {role === 'borrower' ?
                projectDetailMenuDesc.map((elem) => (
                    <span
                        className={classnames({
                            'projectDetailTabs__link projectDetail__tabs_link': true,
                            active: activeTab === elem.id
                        })
                        }
                        onClick={() => setActiveTab(elem.id)}
                        key={elem.id}
                    >
                   {elem.name}
                </span>
                ))
                : projectDetailMenuDescInvestor.map((elem) => (
                    <span
                        className={classnames({
                            'projectDetailTabs__link projectDetail__tabs_link': true,
                            active: activeTab === elem.id
                        })
                        }
                        onClick={() => setActiveTab(elem.id)}
                        key={elem.id}
                    >
                   {elem.name}
                </span>
                ))
            }
        </div>
    );
}


export default ProjectDetailMenu;
import React from 'react';

const InvestInputSum = ({id}) => {
    return (
        <input
            id={id}
            className='investmentModal__sum_input investmentModal__greyBlue'
            type="text"
            placeholder='5 000 ₽'
        />
    )
};

export default InvestInputSum;
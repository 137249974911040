import React from 'react';

const InfoBlockTitle = ({data}) => {
    switch (data.moderation_status_key) {
        case 'projects_payment_stage': {
            return <div className='projectCard__progressBar__title'>Ход проекта</div>
        }
        default: {
            return <div className='projectCard__progressBar__title'>Параметры займа</div>
        }
    }
};

export default InfoBlockTitle;

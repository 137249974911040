import React from "react";
import {CloseIcon} from "@shared/img/iconSvg";

export const stepList = [
    {id: 0, name: '01. Условия проекта'},
    {id: 1, name: '02. Описание проекта'},
    {id: 2, name: '03. Документы'},
    {id: 3, name: '04. Обеспечение'},
    {id: 4, name: '05. Банковские реквизиты'},
]

export const navigationMenu = [

    {
        links: [
            {id: 0, ref: React.createRef(), title: 'Данные профиля'},
            {id: 1, ref: React.createRef(), title: 'Персональные данные заявителя'},
            {id: 2, ref: React.createRef(), title: 'Проверка электронной подписи'},
            {id: 3, ref: React.createRef(), title: 'Решение модератора'},
        ]
    },
]

export const addProjectMenu = [
    {
        links: [
            {id: 0, ref: React.createRef(), title: 'Цель привлечения инвестиций'},
            {id: 1, ref: React.createRef(), title: 'Тип выплат'},
        ]
    },
    {
        links: [
            {id: 0, ref: React.createRef(), title: 'О компании'},
            {id: 1, ref: React.createRef(), title: 'Взаимосвязанные (аффилированные) компании'},
            {id: 2, ref: React.createRef(), title: 'О проекте'},
            {id: 3, ref: React.createRef(), title: 'Бизнес-модель проекта'}
        ]
    },
    {
        links: [
            {id: 0, ref: React.createRef(), title: 'Документы'}
        ]
    },
    {
        links: [
            {id: 0, ref: React.createRef(), title: 'Залог'}
        ]
    },
    {
        links: [
            {id: 0, ref: React.createRef(), title: 'Счет'},
            {id: 1, ref: React.createRef(), title: 'Проверка кредитной истории'},
            {id: 2, ref: React.createRef(), title: 'Услуги платформы'},
        ]
    },
]

export const checkFormIsCompleted = (step, formIsCompleted) => {
    switch (step) {
        case 0:
            return formIsCompleted.attractingInvestments
        case 1:
            return formIsCompleted.addProjectAboutCompany
                && formIsCompleted.relatedCompanies
                && formIsCompleted.aboutProject
                && formIsCompleted.businessModel
        case 2:
            return formIsCompleted.documentsForm
        case 3:
            let status = true;

            Object.keys(formIsCompleted.pledgeForm).forEach(key => {
                if (!formIsCompleted.pledgeForm[key]) status = false
            })

            return status
        default:
            return true
    }
}


export const addProjectReducer = (state, action) => {
    switch (action.type) {
        case 'edit':
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    [action.payload.name]: {
                        ...state[action.form][action.payload.name],
                        value: [action.payload.value]
                    }
                }
            }
        case 'changeType':
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    [action.payload.name]: {
                        ...state[action.form][action.payload.name],
                        type_key: action.payload.type_key
                    }
                }
            }
        case 'editItem':
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    [action.itemKey]: {
                        ...state[action.form][action.itemKey],
                        [action.payload.name]: {
                            ...state[action.form][action.itemKey][action.payload.name],
                            value: [action.payload.value]
                        }
                    }
                }
            }
        case 'addItem':
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    [action.itemKey]: {
                        ...action.payload
                    }
                }
            }
        case 'deleteItem':
            const stateCopy = {...state};
            delete stateCopy[action.form][action.itemKey];

            return {
                ...stateCopy
            }
        default:
            return {
                ...state
            }
    }
}

export const renderMainSelect = (data, deleteItem, itemKey, setFormData, elemKey, formKey) => {

    const renderSelectOptions = () => {
        const {options, value} = data;

        return options.map(item => <button
                key={`${itemKey}${item.key}`}
                className={`from__custom-radio-btn ${value[0] === item.value && 'active'}`}
                onClick={() => setFormData({
                    type: 'editItem',
                    form: 'relatedCompanies',
                    itemKey: itemKey,
                    payload: {name: elemKey, value: item.value}
                })}
            >
                <span>{item.label}</span>
            </button>
        )
    }


    return (
        <div key={itemKey} className="formEdit__mainSelect">
            <div className="formEdit__selectGroup">
                {renderSelectOptions()}
            </div>
            {
                itemKey !== 'item0'
                    ? <div className="formEdit__mainSelect_delete" onClick={() => deleteItem(formKey, itemKey)}>
                        <CloseIcon/>
                    </div>
                    : ''
            }
        </div>
    )
}

export const getFileFormat = data => {
    // Перед сохранением "Проекта" в объекте данных меняем "объекты файлов" на "id файлов"
    const newData = JSON.parse(JSON.stringify(data)); // Глубокое копирование

    // id логотипа компании
    if (Array.isArray(newData.company.logo) && newData.company.logo.length) {
        newData.company.logo_id = newData.company.logo[0].id;
    }

    // Видео о проекте
    if (Array.isArray(newData.videos) && newData.videos.length) {
        newData.videos = newData.videos.map(item => item.message);
    } else {
        newData.videos = []
    }

    // id по документам
    if (Array.isArray(newData.documents) && newData.documents.length) {
        newData.documents = newData.documents.map(item => ({id: item.id, files: item.files.map(file => file.id)}));
    }

    // id файлов для медиа проекта
    if (Array.isArray(newData.medias) && newData.medias.length) {
        newData.medias = newData.medias.map(item => item.id)
    }

    // Перебираем массив "Залог" для файлов вместо объектов задаем id
    if (Array.isArray(newData.pledges) && newData.pledges.length) {
        newData.pledges.forEach((item, idx) => {
            const itemData = item.property;

            for (let elem in itemData) {

                if (Array.isArray(itemData[elem]) && itemData[elem].length) {
                    newData.pledges[idx].property[elem] = itemData[elem].map(elem => elem.id)
                } else {
                    for (let key in itemData[elem]) {

                        if (key === 'contract') {
                            const contractData = itemData[elem].contract;

                            for (let cKey in contractData) {
                                if (Array.isArray(contractData[cKey]) && contractData[cKey].length) {
                                    const data = contractData[cKey];
                                    newData.pledges[idx].property[elem].contract[cKey] = data.map(elem => elem.id)
                                }
                            }

                        } else if (Array.isArray(itemData[elem][key]) && itemData[elem][key].length) {
                            const data = itemData[elem][key];

                            newData.pledges[idx].property[elem][key] = data.map(elem => elem.id)
                        }
                    }
                }
            }
        })

    }

    return newData;
}

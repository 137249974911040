import React from "react";
import {Modal} from 'reactstrap';
import {CloseIcon} from '@shared/img/iconSvg';
import {useDispatch, useSelector} from 'react-redux';
import {hideAllModals, hideModal, setModalData, showModal} from "@actions/modalActions";
import {MODAL_CLASS_NAME, MODAL_COMPONENTS} from "./helpers";


const ModalRoot = () => {
    const zIndex = 1050;
    const {modals} = useSelector(state => state.modal);
    const theme = useSelector(state => state.theme.className)
    const dispatch = useDispatch();

    const handleModalClose = (type) => {
        hideModal(type)(dispatch)
    }

    const handleShowModal = (type, data) => {
        showModal(type, data || null)(dispatch)
    }

    const handleHideModal = (type, success, data) => {
        hideModal(type, success, data)(dispatch)
    }

    if (modals.length === 0) return <span/>

    return (
        modals.map((m, id) => {
            const SpecificModal = MODAL_COMPONENTS[m.type]
            return <Modal
                key={id}
                isOpen={!!SpecificModal}
                    toggle={() => handleModalClose(m.type)}
                    zIndex={zIndex + id}
                    className={`modalRoot ${theme || ''} ${MODAL_CLASS_NAME[m.type] || ''}`}
                    contentClassName="modalRoot__content"
                    backdropClassName="modalRoot__bg"
                    wrapClassName={`modalRoot__wrap ${theme || ''}`}
                >
                    <div className="modalRoot__close" onClick={() => handleModalClose(m.type)}>
                        <CloseIcon/>
                    </div>
                    <div className="modalRoot__body">
                        <SpecificModal
                            modalData={m.data}
                            modalType={m.type}
                            modalUpdate={m.update}
                            modalParentData={m.parentData}
                            showModal={handleShowModal}
                            hideModal={(success = true, data = null, type) => handleHideModal(type || m.type, success, data)}
                            hideAllModals={(success = true) => hideAllModals(success)(dispatch)}
                            setModalData={(data, type) => setModalData(type || m.type, data)}
                        />
                    </div>
                </Modal>
            }
        )
    )
}

export default ModalRoot
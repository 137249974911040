import React, {useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {Button} from "reactstrap";

const CancelProject = () => {
    const [rejectionText, setRejectionText] = useState('');
    const [formError, setFormError] = useState([]);
    const [resetValidate] = useState(false);
    const handleOnChange = e => setRejectionText(e.target.value);

    return (
        <div className="moderatorAccModal__reject">
            <p className="modalFullScreen__title">Вы уверены, что хотите отменить проект?</p>
            <InputText
                name="rejectionText"
                id="rejectionText"
                type='textarea'
                label="Причины отмены проекта"
                rows={2}
                formError={formError}
                onChange={handleOnChange}
                setFormError={setFormError}
                resetValidate={resetValidate}
                value={rejectionText}
                containerClassName="profileTabs__edit_item moderatorAccModal__reject_inputGroup"
                validator={[{required: true}]}
            />
            <div className="modalFullScreen__btnGroup moderatorAccModal__reject_btnGroup">
                <Button
                    color="primary"
                    className="account__btn_cancel moderatorAccModal__reject_btnDanger"
                    outline
                >
                    Назад
                </Button>
                <Button
                    color="primary"
                    className="account__btn_primary moderatorAccModal__reject_btnPrimary"
                >
                    <div className="btn-spinner-group">
                        Отменить проект
                    </div>
                </Button>
            </div>
        </div>
    )
};

export default CancelProject;
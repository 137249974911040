import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {showModal} from "@actions/modalActions";
import {QUALIFICATION_APPLICATION_REJECT} from "@shared/components/modal/constants";
import {QualificationsCheckService} from "@services/qualifications.service";

const QualificationControls = ({data}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [qualificationLoading, setQualificationLoading] = useState(false);

    const handleOnClickApplicationReject = () => {
        showModal(QUALIFICATION_APPLICATION_REJECT, data.id)(dispatch)
    }

    const confirmQualifications = () => {
        setQualificationLoading(true);

        QualificationsCheckService.create({type: "qualification_qualified"}, data.id)
            .then(() => history.push('/'))
            .catch(error => console.log(error))
            .finally(() => setQualificationLoading(false))
    }

    return <div className="accreditationProfile__btnGroup">
        <Button
            color="danger"
            className="accreditationProfile__btn"
            onClick={handleOnClickApplicationReject}
        >
            Отклонить заявления
        </Button>
        <Button
            color="success"
            className="accreditationProfile__btn"
            onClick={confirmQualifications}
        >
            <div className="btn-spinner-group">
                <Spinner className={`btn-spinner ${qualificationLoading ? 'btn-spinner-visible' : ''}`}/>
                Подтвердить квалификацию
            </div>
        </Button>
    </div>
}

export default QualificationControls;
import React from 'react';
import {ProjectLogo} from "@shared/components/modal/projects/AddProject/helpers";
import {Link} from "react-router-dom";
import CommentHistory from "@shared/components/data/CommentHistory";

const ProjectSummary = ({data, showResult}) => {
    const {project} = data;
    const moderationHistory = project?.moderations || [];

    const moderationAuthor = () => {
        const moderations = project.moderations;
        if (!moderations?.length) return;

        if (['projects_checking', 'projects_returned'].includes(project.moderation_status_key)) {
            if (moderations[moderations.length - 1].stage === 'moderator') return 'Модератор'
            if (moderations[moderations.length - 1].stage === 'analytic') return 'Аналитик'
        }
    }

    return <div className="projectSummary">
        <div className="projectSummary__wrap">
            <div className="projectSummary__logo">
                <ProjectLogo id={project.id}/>
            </div>
            <div className="projectSummary__desc">
                <span className="projectSummary__desc_subtitle">Цель привлечения инвестиций</span>
                <p className="projectSummary__desc_title">{project?.condition?.type?.value || ''}</p>
                <div className="projectSummary__group">
                    <span className="projectSummary__desc_number">{project ? `№${project.id}` : ''}</span>
                    {
                        showResult && project?.moderation_status?.value
                            ? <span className="draftProjects__notification">
                  {`${project.moderation_status.value} ${moderationAuthor() ? `(${moderationAuthor()})` : ''}`}
              </span>
                            : null
                    }
                </div>
            </div>
            <div className="projectSummary__controls">
                <span>Заем</span>
                <Link to={`/registry/project/${project?.profile?.id || null}`}>Реестр проектов профиля</Link>
            </div>
        </div>
        {
            moderationHistory?.length && moderationHistory.some(item => item.remarks.length)
                ? <CommentHistory data={moderationHistory}/>
                : null
        }
    </div>
}

export default ProjectSummary;

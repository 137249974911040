import React from 'react';
import cn from 'classnames';
import {getCommentHistoryData} from "./helpers";

const CommentHistory = ({data, withoutFormatting, title = 'История замечаний'}) => {
    const dataFormat = getCommentHistoryData(data)

    const CommentHistoryList = () => {
        return dataFormat.map((item, idx) => {
            const {desc, messages} = item;

            return (
                <div key={idx} className="dataCard__item">
                    <span className="dataCard__name">{desc}</span>
                    <span className="dataCard__desc">
                        {
                            messages.map((elem, index) => (
                                <div key={index} className="dataCard__commentHistoryItem">{elem}</div>
                            ))
                        }
                    </span>
                </div>
            )
        })
    }

    return (
        <div className={cn({dataCard: !withoutFormatting, dataCard__withoutFormatting: withoutFormatting})}>
            <p className="dataCard__title">{title}</p>
            <div className="dataCard__inner">
                <CommentHistoryList/>
            </div>
        </div>
    )
};

export default CommentHistory;
import React from 'react';
import {Table} from 'reactstrap';
import {dataBorrowerExperience} from "./helper";
import BorrowerExperienceTableItem
    from "@shared/components/projects/projectDetail/BorrowerExperience/BorrowerExperienceTableItem";

const BorrowerExperience = () => {

    return (
        <div>
            <div className='borrowerExperience'>
                <div className='borrowerExperience__title'>
                    <span>Остаток по текущим займам:</span>
                    <span>250 000,00 ₽</span>
                </div>
                <div className='borrowerExperience__table'>
                    <Table responsive>
                        <thead>
                        <tr className='borrowerExperience__table_title'>
                            <th>ID <br/>проекта</th>
                            <th className='borrowerExperience__table_item'>Сумма займа</th>
                            <th>Ставка</th>
                            <th>Срок</th>
                            <th>Дата выдачи</th>
                            <th>Дата возврата</th>
                            <th>Просрочка,<br/> дней</th>
                            <th>Заем<br/> возвращен</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            dataBorrowerExperience.map((elem, idx) => (
                                <BorrowerExperienceTableItem key={idx} data={elem}/>
                            ))
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
};

export default BorrowerExperience;
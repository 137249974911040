import React, {useEffect, useState} from 'react';
import RegFormPassword from "@shared/components/form/registerForm/mailConfirmForm/RegFormPassword";
import {Button, Spinner} from "reactstrap";
import classnames from "classnames";
import {PasswordChangeService} from "@services/password.service";
import formCheckIcon from "@shared/img/account/check.svg";

const ChangePassword = () => {
    const [validateInfo, setValidateInfo] = useState(false); // Показать || скрыть требования к паролю
    const [old_password, setOldPassword] = useState('') // Старый пароль
    const [new_password, setNewPass] = useState('') // Новый пароль
    const [repeatNewPass, setRepeatNewPass] = useState('') // Повтор пароля
    const [requestErrorMessage, setRequestErrorMessage] = useState('')
    const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false)

    const [oldPassValid, setOldPassValid] = useState(false);
    const [newPassValid, setNewPassValid] = useState(false);
    const [repeatNewPassValid, setRepeatNewPassValid] = useState(false);

    const [newPassValidStatus, setNewPassValidStatus] = useState(false) // Вадидность пароля
    const [changePassLoading, setChangePassLoading] = useState(false);
    const [formValidateStatus, setFormValidateStatus] = useState(false);

    const handleOnBlurNewPass = () => {
        setValidateInfo(true)
    }

    const handleChangePassword = () => {
        if (old_password.length < 6) setOldPassValid(true)
        if (!newPassValidStatus) setNewPassValid(true)
        if (repeatNewPass !== new_password || !repeatNewPass) setRepeatNewPassValid(true)

        if (old_password.length >= 6 && newPassValidStatus && repeatNewPass === new_password) {
            setChangePassLoading(true)
            PasswordChangeService.create({old_password, new_password})
                .then(() => {
                    setPasswordChangeSuccess(true);
                    setTimeout(() => setPasswordChangeSuccess(false), 2000)
                })
                .catch(error => {
                    setRequestErrorMessage(error.message)
                    setTimeout(() => setRequestErrorMessage(''), 2000)
                })
                .finally(() => setChangePassLoading(false))
        }
    }

    useEffect(() => {
        if (old_password.length >= 6 && new_password && repeatNewPass && new_password === repeatNewPass && newPassValidStatus) {
            setFormValidateStatus(true)
        } else if (formValidateStatus) {
            setFormValidateStatus(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [old_password, new_password, repeatNewPass])

    return (
        <div className="register-form">
            <div className="modalWithSettings__item">
                <RegFormPassword
                    noDisabled
                    noValidate
                    label="Старый пароль"
                    handleOnChangePassword={setOldPassword}
                    showError={oldPassValid}
                    setShowError={setOldPassValid}
                />
            </div>
            <div className="modalWithSettings__item modalWithSettings__item_validate">
                <RegFormPassword
                    noDisabled
                    label="Новый пароль"
                    onFocus={handleOnBlurNewPass}
                    setValidStatus={setNewPassValidStatus}
                    validateInfo={validateInfo && !newPassValidStatus}
                    handleOnChangePassword={setNewPass}
                    showError={newPassValid}
                    setShowError={setNewPassValid}
                />
            </div>
            <div className="modalWithSettings__item">
                <RegFormPassword
                    noDisabled
                    noValidate
                    repeatPass={new_password}
                    label="Повторите пароль"
                    handleOnChangePassword={setRepeatNewPass}
                    showError={repeatNewPassValid}
                    setShowError={setRepeatNewPassValid}
                />
            </div>
            <div className="modalWithSettings__button">
                <Button
                    color="primary"
                    size="sm"
                    className={classnames({lightDisabledBtn: !formValidateStatus})}
                    onClick={handleChangePassword}
                >
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${changePassLoading ? 'btn-spinner-visible' : ''}`}/>
                        Изменить
                    </div>
                </Button>
                {
                    passwordChangeSuccess
                        ? <div className="modalWithSettings__successIcon">
                            <img src={formCheckIcon} alt="confirmed"/>
                        </div>
                        : null
                }
            </div>
            {
                requestErrorMessage
                    ? <span className="form__form-error modalWithSettings__error">{requestErrorMessage}</span>
                    : null
            }
        </div>
    )
}

export default ChangePassword;
import React, {useState} from "react";
import {bool, func, string} from 'prop-types';
import InputText from "@shared/components/form/profileForm/InputText";
import {Spinner} from "reactstrap";

const OtherInfoForm = ({showAddForm, setShowAddForm, message, setMessage, addNewOtherInfo, addStatus}) => {
    const [resetValidate, setResetValidate] = useState(false);

    const handleOnClickCancel = () => {
        setMessage('');
        setShowAddForm(false);
    }

    const saveMessage = () => {
        setResetValidate(true);

        if (message.length && message.length <= 500) {
            addNewOtherInfo(message)
        }
    }

    return showAddForm
        ? <div className="moderationProject__otherInfo_form qualification__otherInfo_form">
            <InputText
                label="Информация"
                rows={2}
                type="textarea"
                onChange={e => setMessage(e.target.value)}
                value={message}
                validator={[{maxLength: 500}, {required: true}]}
                resetValidate={resetValidate}
                setResetValidate={setResetValidate}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <div className="moderationProject__otherInfo_formDesc">
                <div>
                    <button onClick={handleOnClickCancel}>
                        Отменить
                    </button>
                    <button onClick={saveMessage}>
                        <div className="btn-spinner-group">
                            <Spinner className={`btn-spinner ${addStatus ? 'btn-spinner-visible' : ''}`}/>
                            Сохранить изменения
                        </div>
                    </button>
                </div>
            </div>
        </div>
        : null
}

OtherInfoForm.propTypes = {
    showAddForm: bool.isRequired,
    setShowAddForm: func.isRequired,
    message: string.isRequired,
    setMessage: func.isRequired,
    addNewOtherInfo: func.isRequired,
    addStatus: bool.isRequired
}

OtherInfoForm.defaultProps = {
    showAddForm: false,
    setShowAddForm: () => {
    },
    message: '',
    setMessage: () => {
    },
    addNewOtherInfo: () => {
    },
    addStatus: false
}

export default OtherInfoForm;
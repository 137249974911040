import React, {useState} from 'react';
import RegFormEmail from "@shared/components/form/registerForm/mailConfirmForm/RegFormEmail";
import classnames from "classnames";
import RegFormCode from "@shared/components/form/registerForm/RegFormCode";
import {EmailChangeService} from "@services/email.service";
import formCheckIcon from "@shared/img/account/check.svg";

const ChangeEmail = () => {
    const [email, setEmail] = useState('')
    const [confirmCodeVisible, setConfirmCodeVisible] = useState(false)
    const [confirmedCode, setConfirmedCode] = useState(false)
    const [emailRequired, setEmailRequired] = useState(false)
    const [emailErrorMessage] = useState(null)
    const [confirmBtnRequired, setConfirmBtnRequired] = useState(false)
    const [email_code, setEmailCode] = useState('')
    const [validateInfo, setValidateInfo] = useState(false)
    const [emailCodeRequired, setEmailCodeRequired] = useState(false)
    const [emailChangeStatus, setEmailChangeStatus] = useState(false)
    const [emailChangeSuccess, setEmailChangeSuccess] = useState(false)
    const [requestErrorMessage, setRequestErrorMessage] = useState("")

    const handleVisibleCode = status => {
        setConfirmCodeVisible(status)
    }

    const handleConfirmedCode = value => {
        setConfirmedCode(value)
    }

    const handleVisibleValidate = () => {
        setValidateInfo(!validateInfo)
    }

    const handleOnChangeEmail = () => {
        setEmailChangeStatus(true)
        EmailChangeService.create({email, email_code})
            .then(() => {
                setConfirmedCode(false)
                setEmailCode('')
                setEmailChangeSuccess(true);
                setTimeout(() => setEmailChangeSuccess(false), 5000)
            })
            .catch(error => {
                setRequestErrorMessage(error.message)
                setTimeout(() => setRequestErrorMessage(''), 3000)
            })
            .finally(() => setEmailChangeStatus(false))
    }

    return (
        <div className="register-form changeEmail">
            <div className="form__form-group changeEmail__email">
                <div>
                    <RegFormEmail
                        userType
                        changeEmail
                        requestType="change_request"
                        emailValue={email}
                        setEmailValue={setEmail}
                        handleVisibleCode={handleVisibleCode}
                        confirmedCode={confirmedCode}
                        className={classnames({errorInput: emailRequired})}
                        errorMessage={emailRequired ? emailErrorMessage : null}
                        emailRequired={emailRequired}
                        setEmailRequired={setEmailRequired}
                        confirmBtnRequired={confirmBtnRequired}
                        setConfirmBtnRequired={setConfirmBtnRequired}
                        handleOnChangeEmail={handleOnChangeEmail}
                        emailChangeStatus={emailChangeStatus}
                    />
                </div>
                {
                    emailChangeSuccess
                        ? <div className="modalWithSettings__successIcon">
                            <img src={formCheckIcon} alt="confirmed"/>
                        </div>
                        : null
                }
            </div>
            <div className={`form__form-group form__confirm-code ${confirmCodeVisible ? 'fShow' : ''}`}>
                <RegFormCode
                    requestCustomType="change_request"
                    codeValue={email_code}
                    setCodeValue={setEmailCode}
                    handleVisibleCode={handleVisibleCode}
                    handleConfirmedCode={handleConfirmedCode}
                    handleVisibleValidate={handleVisibleValidate}
                    codeRequired={emailCodeRequired}
                    setCodeRequired={setEmailCodeRequired}
                    parentInput="email"
                />
            </div>
            {
                requestErrorMessage
                    ? <span className="form__form-error modalWithSettings__error">{requestErrorMessage}</span>
                    : null
            }
        </div>
    )
}

export default ChangeEmail;
import React from 'react';
import PropTypes from 'prop-types';
import {Badge} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {isAuth, RoleHasPermissions} from "@utils/auth";

const SidebarLink = ({title, icon, newLink, route, onClick, permissions, auth, noAuth, exact}) => {
    if (auth && !isAuth()) return null
    //Не авторизован, но адрес доступен авторизованным
    if (noAuth && isAuth()) return null

    //Если указаны права
    if (permissions && Array.isArray(permissions)) {
        //Не авторизован
        if (!isAuth()) return null
        //Роль пользователя не присутствует в массиве
        if (permissions.length && !RoleHasPermissions(permissions))
            return null
    }

    return (
        <NavLink
            to={route}
            onClick={onClick}
            activeClassName="sidebar__link-active"
            exact={!!exact}
        >
            <li className="sidebar__link">
                {icon ?
                    <div className="sidebar__icon">
                        {icon()}
                    </div>
                    : ''
                }
                <p className="sidebar__link-title">
                    {title}
                    {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
                </p>
            </li>
        </NavLink>
    )

};

SidebarLink.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.func,
    newLink: PropTypes.bool,
    route: PropTypes.string,
    onClick: PropTypes.func,
    permissions: PropTypes.arrayOf(PropTypes.string),
    auth: PropTypes.bool,
    noAuth: PropTypes.bool,
};

SidebarLink.defaultProps = {
    icon: () => {
    },
    newLink: false,
    route: '/',
    onClick: () => {
    },
};

export default SidebarLink;

import React from 'react';
import {EditIcon} from "@shared/img/iconSvg";
import {SET_INPUT_EDIT_VISIBLE, SET_RATE_ERROR} from "@shared/components/form/moderation/PlatformServiceForm/helpers";
import InputText from "@shared/components/form/profileForm/InputText";

const PlatformServiceForm = ({data, fRef = null, state, dispatch, rateValidStatus}) => {
    const {rate, validate, supportEdit, tariffEdit} = state;
    const {condition, pledges = []} = data?.project || {}
    const amountRemuneration = condition.target && rate.tariff
        ? `${((+condition.target * +rate.tariff) / 100).toLocaleString()} ₽`
        : 0


    const setEdit = name => {
        dispatch({
            type: SET_INPUT_EDIT_VISIBLE,
            payload: name
        })
    }

    const handleOnChange = data => {
        dispatch({
            payload: {
                ...data
            }
        })
    }

    const setFormError = error => {
        dispatch({
            type: SET_RATE_ERROR,
            payload: error
        })
    }

    return (
        <div className="platformServices" ref={fRef}>
            <div className="platformServices__titleGroup">
                <div className="platformServices__title">
                    <span>Услуги платформы</span>
                </div>
            </div>
            <div className="platformServices__content">
                <div className="platformServices__item">
            <span className="platformServices__item_title">
              Услуги по предоставлению доступа к сервисам инвестиционной платформы INCROWD и привлечению инвестиций
            </span>
                    <div className="platformServices__item_elems">
                        <div className="platformServices__item_elem">
                            <div className="platformServices__item_edit">
                                <span>Тариф:&nbsp;</span>
                                {
                                    !tariffEdit
                                        ? <>
                                            {
                                                rate.tariff
                                                    ? `${rate.tariff.toLocaleString()} % от суммы займа`
                                                    : <span className="error-color">Не заполнено</span>
                                            }
                                            <span
                                                className="platformServices__editBtn"
                                                onClick={() => setEdit('tariffEdit')}
                                            >
                                                <EditIcon/>
                                            </span>
                                        </>
                                        : <InputText
                                            noLabel
                                            noErrorMessage
                                            name="tariff"
                                            onChange={e => handleOnChange({
                                                name: "tariff",
                                                value: +(e.target.value.replace(/\D/g, ''))
                                            })}
                                            value={rate.tariff || ''}
                                            inputWithDesc
                                            inputWithDescValue="%"
                                            resetValidate={!rateValidStatus}
                                            validator={[{maxNumber: 100}, {required: true}]}
                                            formError={validate}
                                            setFormError={setFormError}
                                            containerClassName="addProject__smallInput"
                                            rank={true}
                                        />
                                }
                            </div>
                        </div>
                        <div className="platformServices__item_elem">
                            <span>Сумма вознаграждения: </span>{amountRemuneration}
                        </div>
                        <div className="platformServices__item_list">
                            <div className="platformServices__item_elem">
                                <span>При наборе минимальной суммы: </span>
                                {
                                    condition.min
                                        ? `${condition.min.toLocaleString()} ₽`
                                        : <span className="error-color">Не заполнено</span>
                                }
                            </div>
                            <div className="platformServices__item_elem">
                                <span>При наборе целевой суммы: </span>
                                {
                                    condition.target
                                        ? `${condition.target.toLocaleString()} ₽`
                                        : <span className="error-color">Не заполнено</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    pledges.length
                        ? <div className="platformServices__item">
                            <span className="platformServices__item_title">
                                Услуги платформы INCROWD по сопровождению государственной регистрации
                                залога движимого/недвижимого имущества
                            </span>
                            <div className="platformServices__item_elems">
                                <div className="platformServices__item_elem">
                                    <div className="platformServices__item_edit">
                                        <span>Тариф:&nbsp;</span>
                                        {
                                            !supportEdit
                                                ? <>
                                                    {
                                                        rate.support
                                                            ? ` ${rate.support.toLocaleString()} ₽ / 1 объект залога`
                                                            : <span className="error-color">Не заполнено</span>
                                                    }
                                                    <span
                                                        className="platformServices__editBtn"
                                                        onClick={() => setEdit('supportEdit')}
                                                    >
                                                    <EditIcon/>
                                                </span>
                                                </>
                                                : <InputText
                                                    noLabel
                                                    noErrorMessage
                                                    name="support"
                                                    onChange={e => handleOnChange({
                                                        name: "support",
                                                        value: +(e.target.value.replace(/\D/g, ''))
                                                    })}
                                                    value={rate.support || ''}
                                                    inputWithDesc
                                                    inputWithDescValue="₽"
                                                    resetValidate={!rateValidStatus}
                                                    validator={[{maxLength: 100}, {required: true}]}
                                                    formError={validate}
                                                    setFormError={setFormError}
                                                    containerClassName="addProject__smallInput"
                                                    rank={true}
                                                />
                                        }
                                    </div>
                                </div>
                                <div className="platformServices__item_elem">
                                    <span>Объектов залога:</span> {pledges ? pledges.length : 0}
                                </div>
                                <div className="platformServices__item_elem">
                                    <span>Сумма вознаграждения: </span>
                                    {(pledges.length * +(rate.support || 0)).toLocaleString()} ₽
                                </div>
                            </div>
                        </div>
                        : ''
                }
            </div>
            <div className="platformServices__amount_pay">
                <p>Сумма вознаграждения: <span>62 000 ₽</span></p>
            </div>
        </div>
    )
}

export default PlatformServiceForm;


export const controlTabsData = [
    {key: 'pending', desc: 'Ожидают проверки'},
    {key: 'completion', desc: 'На доработке'}
]

export const filterInitialState = {
    page: '',
    limit: '',
    from_min: '',
    to_min: '',
    from_target: '',
    to_target: '',
    from_project: '',
    to_project: '',
    from_returned: '',
    to_returned: '',
    pledge: null,
    type: null,
    search: '',
    from: '',
    to: '',
    sortedBy: [],
    orderBy: [],
    include: 'project.profile,project.profile.legal_form,project.condition,project.pledges,project.moderations',
    status: 'projects_checking'
}

export const filterReducer = (state, event) => {
    if (event.reset) return {...filterInitialState}
    return {
        ...state,
        [event.name]: event.value
    }
}
export const ModerationProjectsTableHeader = [
    {id: 0, title: 'Номер проекта'},
    {id: 1, title: 'Наименование заемщика'},
    {id: 2, title: 'ID профиля'},
    {id: 3, title: 'Минимальная сумма', sorted: true, orderKey: 'project.condition.min'},
    {id: 4, title: 'Целевая сумма', sorted: true, orderKey: 'project.condition.target'},
    {id: 5, title: 'Наличие обеспечения'},
    {id: 6, title: 'Тип проверки'},
    {id: 7, title: ''},
];

export const ModerationProjectsReturnedTableHeader = [
    {id: 0, title: 'Номер проекта'},
    {id: 1, title: 'Наименование заемщика'},
    {id: 2, title: 'ID профиля'},
    {id: 3, title: 'Минимальная сумма', sorted: true,},
    {id: 4, title: 'Целевая сумма', sorted: true},
    {id: 5, title: 'Наличие обеспечения'},
    {id: 6, title: 'Дата создания проекта', sorted: true},
    {id: 6, title: 'На доработке ', subtitle: '(дней)'},
    {id: 7, title: ''},
];

export const moderationProjectStepList = [
    {id: 0, name: '01. Данные заявителя'},
    {id: 1, name: '02. Условия проекта'},
    {id: 2, name: '03. Описание проекта и документы'},
    {id: 3, name: '04. Обеспечение'},
    {id: 4, name: '05. Банковские реквизиты'},
    {id: 5, name: '06. Решение по проекту'},
]
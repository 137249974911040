import React, {useEffect, useState} from 'react';
import ApplicationArchive from "@shared/components/questionnaireProfile/view/ApplicationArchive";
import {QualificationsProfileService} from "@services/qualifications.service";
import {APPLICATION_QUALIFIED_ARCHIVE} from "@shared/components/modal/constants";

const ApplicationArchiveModal = ({modalData}) => {
    const [qualifications, setQualifications] = useState([]);
    const profileId = modalData;

    useEffect(() => {
        if (profileId) {
            QualificationsProfileService.getById(profileId, {include: 'profile'})
                .then(res => setQualifications(res?.data?.data || []))
                .catch(error => console.log(error))
        }
    }, [profileId])


    return (
        <div className="moderatorAccModal__newCheck">
            <p className="modalFullScreen__title">
                {qualifications.length ? 'Архив заявлений' : 'Заявления отсутствуют'}
            </p>
            <ApplicationArchive
                data={qualifications}
                className="qualification__archive_modal"
                previousModal={APPLICATION_QUALIFIED_ARCHIVE}
                previousModalData={profileId}
            />
        </div>
    )
}

export default ApplicationArchiveModal;
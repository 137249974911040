export const tabs = [
    {id: 0, key: 'profileData', title: 'Данные профиля'},
    {id: 1, key: 'bankDetails', title: 'Банковские реквизиты'},
    {id: 2, key: 'documents', title: 'Документы профиля'},
    {id: 3, key: 'accreditationResults', title: 'Результаты проведения аккредитации'},
    {id: 4, key: 'operationsHistory', title: 'История операций'},
    {id: 5, key: 'profileActionsRegistry', title: 'Реестр действий профиля'},
    {id: 6, key: 'projectsRegistry', title: 'Реестр проектов профиля'},
    {id: 7, key: 'accountBlockingPage', title: 'Страница блокировки аккаунта'},
]

export const pageTitleRoleDesc = {
    investor: 'инвестора',
    borrower: 'заемщика'
}
import React from 'react';
import DataCard from "@shared/components/data/DataCard";

const PledgesProjectDDU = ({data, idx, fRef}) => {
    const {
        number, date, developer, ogrn_developer, registration_region, registration_number, sum, address, total_area,
        floor, floors_number, conditional_number, plans, transfer_date, other_information, documents
    } = data.property;

    const dataFormat = [
        {
            name: 'Договор',
            desc: `Договор участия в долевом строительстве (ДДУ) №${number} от ${date}, заключенного между Залогодателем (Участник долевого строительства) и ${developer} (Застройщик)`
        },
        {name: 'ОГРН (Застройщик)', desc: ogrn_developer || ''},
        {name: 'ОГРН (Застройщик)', desc: ogrn_developer || ''},
        {
            name: 'Регистрация',
            desc: `Государственная регистрация права участника долевого строительства на объект долевого строительства осуществлена Управлением Федеральной службы государственной регистрации кадастра и картографии по ${registration_region} (регион), за номером ${registration_number}`
        },
        {name: 'Уплаченная сумма застройщику (в соответствии с ДДУ)', desc: sum || ''},
        {name: 'Адрес жилого помещения', desc: address || ''},
        {name: 'Общая проектная площадь (без учета летних помещений)', desc: total_area || ''},
        {name: 'Этаж', desc: floor || ''},
        {name: 'Общая этажность здания', desc: floors_number || ''},
        {name: 'Условный номер жилого помещения', desc: conditional_number || ''},
        {name: 'Технический план (скан)', desc: plans || '', type: 'file'},
        {name: 'Срок передачи жилого помещения Застройщиком до', desc: transfer_date || ''},
        {name: 'Иные сведения', desc: other_information || ''},
        {name: 'Иные документы', desc: documents || '', type: 'file'},

    ]

    return <DataCard data={dataFormat} title={`Залог ${idx + 1}`} fRef={fRef}/>
}

export default PledgesProjectDDU;
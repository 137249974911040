import React, {useEffect} from 'react';
import {checkDependencies, renderFormElem} from "./helpers";

const EditAdditionalInfo = ({
                                setFormValidate, formError, setFormError, formDataItem,
                                handleOnChange, setFormData, personNumber, resetValidate,
                            }) => {
    const formItemsId = [];

    const renderEditForm = () => {
        const formItems = [];

        for (let key in formDataItem) {
            const {depends_key = [], depends_value = [], default: defaultValue} = formDataItem[key]?.field || {};

            if (depends_key.length) {
                if (checkDependencies(formDataItem, depends_key, depends_value)) {
                    formItems.push(renderFormElem(
                        formDataItem[key], formError, handleOnChange, setFormError, formDataItem, setFormData, resetValidate
                    ));
                    formItemsId.push(formDataItem[key].field_id)
                } else if (defaultValue[0] !== formDataItem[key].value[0]) {
                    setFormData({
                        type: 'edit',
                        payload: {name: key, value: defaultValue[0], person: formDataItem[key].person}
                    })
                }
            } else {
                formItems.push(renderFormElem(
                    formDataItem[key], formError, handleOnChange, setFormError, formDataItem, setFormData, resetValidate
                ));
                formItemsId.push(formDataItem[key].field_id)
            }
        }

        return formItems;
    }

    useEffect(() => {
        let validateStatus = true;

        for (let key in formDataItem) {
            if (key === 'formError') continue;
            if (formItemsId.includes(formDataItem[key].field_id)) {
                if (formDataItem[key].value[0] === undefined || ('' + formDataItem[key].value[0]).length === 0) validateStatus = false
            }
        }

        setFormValidate(validateStatus)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDataItem])

    return (
        <div className="profileTabs__edit">
            {
                personNumber > 1
                    ? <p className="profileTabs__edit_person">{`Лицо ${personNumber}`}</p>
                    : ''
            }
            {renderEditForm()}
        </div>
    )
}

export default EditAdditionalInfo;
import React from 'react';

const DashboardCard = ({desc, value}) => {
    return desc && value
        ? <div className="dashboardCard">
            <p className="dashboardCard__desc">
                {desc}
            </p>
            <p className="dashboardCard__value">
                {value}
            </p>
        </div>
        : null
}

export default DashboardCard;
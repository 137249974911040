const checkFormValidity = (conditions = []) => {
    if (!conditions.length) return false

    return conditions.some(item => item.selected)
}

const checkSelectedOneFile = fields => {
    return fields.some(item => item.files.length)
}

const checkCompletedDocument = (conditions = [], formError, setFormError) => {
    if (!conditions.length) return null

    conditions.forEach((item, idx) => {
        const index = formError.indexOf(idx);

        if (item.selected) {
            if (!checkSelectedOneFile(item.items)) {
                if (index === -1) {
                    const newFormError = [...formError];
                    newFormError.push(idx)
                    setFormError(newFormError)
                }
            } else {
                if (index !== -1) {
                    const newFormError = [...formError];
                    newFormError.splice(index, 1)
                    setFormError(newFormError)
                }
            }
        } else {
            if (index !== -1) {
                const newFormError = [...formError];
                newFormError.splice(index, 1)
                setFormError(newFormError)
            }
        }
    })
}

export {
    checkFormValidity,
    checkCompletedDocument
}


/*[
    {
        "text": "Общая стоимость ценных бумаг, которыми я владею, и (или) общий размер обязательств из договоров, являющихся производными финансовыми инструментами и заключенных за мой счет, рассчитанные в порядке, предусмотренном действующими нормативно-правовыми актами Банка России,  составляют не менее 6 000 000 (шести миллионов) рублей. При определении общей стоимости указанных ценных бумаг (общего размера обязательств) учитываются также соответствующие финансовые инструменты, переданные мною в доверительное управление;",
        "type": "files",
        "items": [
            {
                "files": [],
                "label": "Выписка со счета депо"
            },
            {
                "files": [],
                "label": "Выписка из реестра акционеров"
            },
            {
                "files": [],
                "label": "Акты передачи денежных средств и / или ценных бумаг в доверительное управление, отчеты о деятельности доверительного управляющего"
            },
            {
                "files": [],
                "label": "Отчеты /документы, подтверждающие заключение на организованных торгах договоров, являющихся производными финансовыми инструментами "
            }
        ],
        "selected": true
    },
    {
        "text": "Имею опыт работы** в российской и (или) иностранной организации, которая осуществляла сделки с ценными бумагами и (или) иными финансовыми инструментами:\n\n                    •\tне менее 2 лет, если такая организация (организации) является квалифицированным инвестором в силу пункта 2 статьи 51.2 Федерального закона «О рынке ценных бумаг»;\n                    или\n                    •\tне менее 3 лет в иных случаях;",
        "type": "files",
        "items": [
            {
                "files": [],
                "label": "Копия трудовой книжки"
            },
            {
                "files": [],
                "label": "Копия должностной инструкции"
            },
            {
                "files": [],
                "label": "Письмо компании–работодателя с указанием должностных обязанностей"
            }
        ],
        "selected": false
    },
    {
        "text": "Совершал сделки с ценными бумагами и (или) заключал договоры, являющиеся производными финансовыми инструментами, за последние четыре квартала в среднем не реже 10 раз в квартал, но не реже одного раза в месяц. При этом совокупная цена таких сделок (договоров) составляет не менее 6 000 000 (шести миллионов) рублей;",
        "type": "files",
        "items": [
            {
                "files": [],
                "label": "Выписки с брокерских счетов/отчеты брокеров"
            },
            {
                "files": [],
                "label": "Выписка со счета депо / выписки об операциях из реестра акционеров, датированные не позднее 1 (Одного) месяца до даты предоставления"
            },
            {
                "files": [],
                "label": "Отчеты /документы, подтверждающие заключение на организованных торгах договоров, являющихся производными финансовыми инструментами"
            }
        ],
        "selected": false
    },
    {
        "text": "Размер принадлежащего мне имущества составляет не менее 6 000 000 (Шести миллионов) рублей**",
        "type": "files",
        "items": [
            {
                "files": [],
                "label": "Выписки с текущих счетов и/или депозитов, подтверждающие остаток денежных средств на сумму более 6 (Шести) миллионов рублей с учетом начисленных процентов."
            },
            {
                "files": [],
                "label": "Выписки с металлических счетов, либо иные документы, подтверждающие требования к кредитной организации выплатить денежный эквивалент драгоценного металла по учетной цене соответствующего драгоценного металла."
            },
            {
                "files": [],
                "label": "Выписка со счета депо"
            },
            {
                "files": [],
                "label": "Выписка из реестра акционеров"
            },
            {
                "files": [],
                "label": "Акты передачи денежных средств и / или ценных бумаг в доверительное управление, отчеты о деятельности доверительного управляющего"
            },
            {
                "files": [],
                "label": "Отчеты /документы, подтверждающие заключение на организованных торгах договоров, являющихся производными финансовыми инструментами."
            }
        ],
        "selected": false
    },
    {
        "text": "Имею высшее экономическое образование, подтвержденное документом государственного образца Российской Федерации о высшем образовании, выданным образовательной организацией высшего профессионального образования, которое на момент выдачи указанного документа осуществляло аттестацию граждан в сфере профессиональной деятельности на рынке ценных бумаг, или любой из следующих аттестатов и сертификатов: квалификационный аттестат специалиста финансового рынка, квалификационный аттестат аудитора, квалификационный аттестат страхового актуария, сертификат \"Chartered Financial Analyst (CFA)\", сертификат \"Certified International Investment Analyst (CHA)\", сертификат \"Financial Risk Manager (FRM)\". ",
        "type": "files",
        "items": [
            {
                "files": [],
                "label": "Диплом государственного образца о высшем экономическом образовании, выданный организацией, которая на момент выдачи указанного документа осуществляла аттестацию граждан в сфере профессиональной деятельности на рынке ценных бумаг;"
            },
            {
                "files": [],
                "label": "Квалификационный аттестат специалиста финансового рынка;"
            },
            {
                "files": [],
                "label": "Квалификационный аттестат аудитора;"
            },
            {
                "files": [],
                "label": "Квалификационный аттестат страхового актуария;"
            },
            {
                "files": [],
                "label": "Сертификат “Carted Financial Analyst (CFA)”;"
            },
            {
                "files": [],
                "label": "Сертификат “Certified International Investment Analyst (CIIA)”;"
            },
            {
                "files": [],
                "label": "Сертификат “Financial Risk Manager (FRM)”."
            }
        ],
        "selected": false
    }
]*/
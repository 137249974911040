import React, {useState} from 'react';

const MailingSettings = () => {
    const [notifyProjects, setNotifyProjects] = useState(false);
    const [notifyNews, setNotifyNews] = useState(false);

    const handleOnChangeCheckbox = type => {
        if (type === 'notifyProjects') setNotifyProjects(prev => !prev)
        if (type === 'notifyNews') setNotifyNews(prev => !prev)
    }

    return (
        <div className="mailingSettings">
            <label className="toggle-btn customizer__toggle" htmlFor="notifyProjects">
                <input
                    className="toggle-btn__input"
                    type="checkbox"
                    name="notifyProjects"
                    id="notifyProjects"
                    checked={notifyProjects}
                    onChange={() => handleOnChangeCheckbox('notifyProjects')}
                />
                <span className="toggle-btn__input-label"/>
                <span>Уведомлять о новых проектах на платформе</span>
            </label>
            <label className="toggle-btn customizer__toggle" htmlFor="notifyNews">
                <input
                    className="toggle-btn__input"
                    type="checkbox"
                    name="notifyNews"
                    id="notifyNews"
                    checked={notifyNews}
                    onChange={() => handleOnChangeCheckbox('notifyNews')}
                />
                <span className="toggle-btn__input-label"/>
                <span>Уведомлять о последних новостях платформы </span>
            </label>
        </div>
    )
}

export default MailingSettings;
import React from 'react';
import {Button} from "reactstrap";
import classNames from "classnames";
import {showModal} from "@actions/modalActions";
import {useDispatch} from "react-redux";
import {QUALIFIED_APPLICATION, QUALIFIED_INVESTOR_DETAIL} from "@shared/components/modal/constants";

const InvestorCheckingQualifiedNotice = () => {
    const dispatch = useDispatch();

    const btnClass = classNames({
        btn_primary: true,
        confirmQualifiedInvestor__btn: true,
    });

    return (
        <div className="accStatusNotice">
            <div className="accStatusNotice__header">
                <p>Подтверждение статуса квалифицированного инвестора</p>
            </div>
            <div className="accStatusNotice__content">
                <p>Ваше заявление находится на проверке.</p>
            </div>
            <div className="accStatusNotice__footer">
                <div className="confirmQualifiedInvestor__btnGroup">
                    <Button
                        color="success"
                        className="account__btn_success confirmQualifiedInvestor__btn"
                        onClick={() => showModal(QUALIFIED_INVESTOR_DETAIL)(dispatch)}
                        outline
                    >
                        Подробнее о квалификации
                    </Button>
                    <Button
                        color="primary"
                        className={btnClass}
                        onClick={() => showModal(QUALIFIED_APPLICATION)(dispatch)}
                    >
                        Открыть заявление
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default InvestorCheckingQualifiedNotice;
import {AuthService} from "@services/auth.service";
import {UsersAvatarService} from "@services/users.service";
import {SET_ATOKEN_EXP_DATE, SET_IS_AUTH} from "@actions/authActions";
import {SET_EDIT_PROFILE_ID} from "@actions/profilesActions";
import {get} from "@utils/storage";

// Constants
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const AVATAR_STATUS = 'AVATAR_STATUS';


export const userInfo = (dispatch) => {
    const params = {include: 'roles,profiles,status,avatar'}

    return AuthService.userInfo(params)
        .then(res => {
            dispatch({type: SET_USER_INFO, payload: res.data})
            dispatch({type: SET_ATOKEN_EXP_DATE, payload: get('exp')})
            dispatch({type: SET_IS_AUTH, payload: true})
            dispatch({type: SET_EDIT_PROFILE_ID, payload: res?.data?.profile?.id || null})
        }).catch(error => {
            console.log(error)
        })
}

export const updateUserAvatar = (file, id) => {
    return dispatch => {
        return UsersAvatarService.create(file, id).then(() => {
            dispatch({type: AVATAR_STATUS})
        }).catch(error => {
            console.log(error)
        })
    }
}

export const removeUserAvatar = () => {
    return dispatch => {
        return UsersAvatarService.remove().then(() => {
            dispatch({type: AVATAR_STATUS})
        }).catch(error => {
            console.log(error)
        })
    }
}
import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useDispatch} from "react-redux";
import Masonry from "react-masonry-css";
import AdditionalInfo from "@shared/components/account/additionalInfo";
import AccountPersonalData from "@shared/components/account/accountPersonalData";
import AccountPassportData from "@shared/components/account/accountPassportData";
import AccountCompanyData from "@shared/components/account/accountCompanyData";
import {showModal} from "@actions/modalActions";
import {BAN_COMMENT_MODAL} from "@shared/components/modal/constants";

const ClientAccountDataTab = ({data, profileId}) => {
    const dispatch = useDispatch();
    const {isBanned, user} = data;
    const addInfoForms = data?.legal_form?.questionnaire?.responses || [];
    const [lockProfileStatus] = useState(false);

    const EntityAccountCompanyData = () => (
        data.legal_form_type === 'entity'
            ? <AccountCompanyData editForm={false} profileData={data}/>
            : null
    )

    const banProfile = () => {
        showModal(BAN_COMMENT_MODAL, {...data, profileId})(dispatch)
    }

    return (
        <div className="clientAccount__tab">
            <Button color={user.isBanned ? 'secondary' : 'danger'} size="lg" disabled={user.isBanned}
                    onClick={banProfile}>
                <div className="btn-spinner-group">
                    <Spinner className={`btn-spinner ${lockProfileStatus ? 'btn-spinner-visible' : ''}`}/>
                    {isBanned ? 'Разблокировать профиль' : 'Заблокировать профиль'}
                </div>
            </Button>
            <p className="clientAccount__tab_subtitle">Анкетные данные</p>
            <Masonry
                breakpointCols={{default: 2, 1400: 1}}
                className="my-masonry-grid clientAccount__masonryGrid custom-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                <EntityAccountCompanyData/>
                <AccountPersonalData editForm={false} profileData={data}/>
                <AccountPassportData editForm={false} profileData={data}/>
                {
                    addInfoForms.map(item => (
                        <div key={item.id} className="profileTabs__addInfo_item">
                            <AdditionalInfo data={item}/>
                        </div>
                    ))
                }
            </Masonry>
        </div>
    )
}

export default ClientAccountDataTab;
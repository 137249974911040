const projectCardStatusDesc = {
    projects_draft: 'Черновик',
    projects_checking: 'На проверке',
    projects_returned: 'Возвращен на доработку',
    projects_collecting: 'Сбор инвестиций',
    projects_collecting_completed: 'Сбор инвестиций завершен',
    projects_signing_stage: 'Стадия подписания',
    projects_payment_stage: 'Стадия выплат',
    projects_delay: 'Просрочка',
    projects_completed: 'Завершен',
    projects_not_carried_out: 'Не состоялся',
    projects_canceled: 'Отменен',
    projects_rejected: 'Отклонен',
}

const projectCardStatusColor = {
    projects_checking: '#6C8ED8',
    projects_returned: '#F0C83B',
    projects_draft: '#8797B6',
    projects_collecting: '#58BF91',
    projects_collecting_completed: '#58BF91',
    projects_signing_stage: '#6C8ED8',
    projects_delay: '#FC8473',
    projects_payment_stage: '#6C8ED8',
    projects_completed: '#58BF91',
    projects_not_carried_out: '#8797B6',
    projects_canceled: '#8797B6',
    projects_rejected: '#8797B6',
}


export {
    projectCardStatusDesc,
    projectCardStatusColor
}
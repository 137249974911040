import React from 'react';
import classnames from "classnames";
import {EditIcon} from "@shared/img/iconSvg";
import {
    SET_TERMS_ERROR,
    SET_TERMS_VALUE,
    TERMS_INPUT_EDIT_VISIBLE
} from "@shared/components/presentation/TermsProject/helpers";
import InputText from "@shared/components/form/profileForm/InputText";
import RadioButtonField from "@shared/components/form/RadioButton";

const TermsProject = ({data, fRef, state, dispatch, validStatus}) => {
    const {
        condition, validate, percentEdit, loanEdit, defferPeriodEdit,
        penaltyPerDayEdit, chargePenaltyFirstDayEdit, percentsCalculationEdit, outputKeyEdit
    } = state;
    const {min, target, type, period, repayment, repayment_key} = data || {};

    const dataFormat = [
        {name: 'Цель привлечения инвестиций', desc: type?.value || '',},
        {name: 'Минимальная сумма', desc: min ? `${min} ₽` : ''},
        {name: 'Целевая сумма', desc: target ? `${target} ₽` : ''},
        {
            name: 'Ставка',
            key: 'percent',
            desc: condition.percent || '',
            type: 'edit',
            editStatus: percentEdit,
            editKey: 'percentEdit',
            dataKey: 'condition',
            inputWithDescValue: '%',
            validator: [{maxNumber: 100}, {required: true}]
        },
        {name: 'Период сбора инвестиций', desc: period ? `${period} дней` : ''},
        {
            name: 'Срок займа',
            key: 'loan',
            desc: condition.loan || '',
            type: 'edit',
            editStatus: loanEdit,
            editKey: 'loanEdit',
            dataKey: 'condition',
            inputWithDescValue: 'мес.',
            validator: [{maxLength: 100}, {required: true}]
        },
        {name: 'Способ погашения', desc: repayment.value || ''},
        {
            name: 'Срок отсрочки',
            key: 'deffer_period',
            type: 'edit',
            editStatus: defferPeriodEdit,
            editKey: 'defferPeriodEdit',
            dataKey: 'condition',
            inputWithDescValue: 'мес.',
            desc: condition.deffer_period || '',
            validator: [{digitLimit: 2}, {maxNumber: condition.loan ? +condition.loan - 1 : 0}, {onlyNumbers: true}, {required: true}],
            hide: repayment_key !== "deferred_annuity"
        },
        {
            name: 'Размер пени в день',
            key: 'penalty_per_day',
            type: 'edit',
            editStatus: penaltyPerDayEdit,
            editKey: 'penaltyPerDayEdit',
            dataKey: 'condition',
            inputWithDescValue: '%',
            desc: condition.penalty_per_day || '',
            textBtnEdit: 'Указать иные проценты',
            validator: [{maxNumber: 3}, {onlyNumbers: true}, {required: true}],
        },
        {
            name: 'Начало начисления пени',
            key: 'charge_penalty_first_day',
            type: 'edit',
            editStatus: chargePenaltyFirstDayEdit,
            editKey: 'chargePenaltyFirstDayEdit',
            dataKey: 'condition',
            desc: condition.charge_penalty_first_day === 'yes' || condition.charge_penalty_first_day === true
                ? 'С первого дня просрочки'
                : 'Не начислять пени',
            radioButton: [
                {id: 0, value: 'yes', label: 'С первого дня просрочки'},
                {id: 1, value: 'no', label: 'Не начислять пени'},
            ]
        },
        {
            name: 'Расчет % за пользование займом при просрочке',
            key: 'percents_calculation',
            type: 'edit',
            editStatus: percentsCalculationEdit,
            editKey: 'percentsCalculationEdit',
            dataKey: 'condition',
            inputWithDescValue: '%',
            desc: condition.percents_calculation || '',
            textBtnEdit: 'Указать иные проценты',
            validator: [{minNumber: 5}, {maxNumber: 200}, {onlyNumbers: true}, {required: true}],
        },
        {
            name: 'Тип вывода д/с на р/с заемщика',
            key: 'output_key',
            type: 'edit',
            editStatus: outputKeyEdit,
            editKey: 'outputKeyEdit',
            dataKey: 'condition',
            desc: condition.output_key === 'manually' ? 'Вручную' : 'Автоматически',
            radioButton: [
                {id: 0, value: 'automatically', label: 'Автоматически'},
                {id: 1, value: 'manually', label: 'Вручную'},
            ]
        },
    ]

    const setEdit = name => {
        dispatch({
            type: TERMS_INPUT_EDIT_VISIBLE,
            payload: name
        })
    }

    const handleOnChange = (e, dataKey) => {
        dispatch({
            type: SET_TERMS_VALUE,
            payload: {
                dataKey,
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const setFormError = error => {
        dispatch({
            type: SET_TERMS_ERROR,
            payload: error
        })
    }

    return (
        <div className="dataCard" ref={fRef}>
            <div className="dataCard__inner">
                {
                    dataFormat.map((item, idx) => {
                        if (item.hide) return null
                        if (item.type === 'edit') {
                            return (
                                <div key={idx} className={classnames({
                                    dataCard__item: true,
                                    dataCard__item_edit: item.editStatus
                                })}>
                                    <span className="dataCard__name">{item.name}</span>
                                    {
                                        !item.editStatus
                                            ? <span className={classnames('dataCard__desc', {
                                                error: !item.desc,
                                                [item.className]: item?.className
                                            })}>
                                {
                                    item.desc
                                        ? `${item.desc} ${item.inputWithDescValue ? item.inputWithDescValue : ''}`
                                        : 'Не заполнено'
                                }
                                                <span
                                                    className={item.textBtnEdit ? 'platformServices__editBtnText' : 'platformServices__editBtn'}
                                                    onClick={() => setEdit(item.editKey)}
                                                >
                                  {
                                      item.textBtnEdit
                                          ? <span>Указать иные проценты</span>
                                          : <EditIcon/>
                                  }
                                </span>
                            </span>
                                            : <div className="dataCard__editGroup">
                                                {
                                                    item.radioButton
                                                        ? <div
                                                            className="platformServices__radioGroup inputText__wrapper dataCard__desc">
                                                            {
                                                                item.radioButton.map(elem => {
                                                                    return (
                                                                        <RadioButtonField
                                                                            key={elem.id}
                                                                            name={item.key}
                                                                            label={elem.label}
                                                                            radioValue={elem.value}
                                                                            value={
                                                                                item.key === 'charge_penalty_first_day'
                                                                                    ? condition.charge_penalty_first_day === true ? 'yes' : 'no'
                                                                                    : state[item.dataKey][item.key]}
                                                                            onChange={value => handleOnChange(
                                                                                {target: {name: item.key, value: value}},
                                                                                item.dataKey
                                                                            )}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        : <InputText
                                                            noLabel
                                                            noErrorMessage
                                                            name={item.key}
                                                            onChange={e => handleOnChange(
                                                                {
                                                                    target: {
                                                                        name: item.key,
                                                                        value: +(e.target.value.replace(/\D/g, ''))
                                                                    }
                                                                },
                                                                item.dataKey
                                                            )}
                                                            value={state[item.dataKey][item.key]}
                                                            inputWithDesc
                                                            inputWithDescValue={item.inputWithDescValue}
                                                            resetValidate={!validStatus}
                                                            validator={item.validator}
                                                            formError={validate}
                                                            setFormError={setFormError}
                                                            containerClassName="addProject__smallInput dataCard__desc"
                                                            rank={true}
                                                        />
                                                }
                                                <button
                                                    onClick={() => setEdit(item.editKey)}
                                                    disabled={!state[item.dataKey][item.key] || !validate.indexOf(item.key)}
                                                >
                                                    Сохранить
                                                </button>
                                            </div>
                                    }
                                </div>
                            )
                        } else {
                            return (
                                <div key={idx} className="dataCard__item">
                                    <span className="dataCard__name">{item.name}</span>
                                    <span className={classnames('dataCard__desc', {
                                        error: !item.desc,
                                        [item.className]: item?.className
                                    })}>
                        {item.desc || 'Не заполнено'}
                      </span>
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}

export default TermsProject;

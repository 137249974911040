import React, {useReducer} from 'react';
import {Button, Spinner} from "reactstrap";
import {RegisterInnerService} from "@services/register.service";
import InnerUserForm from "@containers/InnerUsers/InnerUserPage/InnerUserForm";
import {initState, innerUserFormReducer} from "@containers/InnerUsers/InnerUserPage/InnerUserForm/helpers";
import innerUserFormActions from "@containers/InnerUsers/InnerUserPage/InnerUserForm/action";

const AddInnerUserModal = ({hideModal}) => {
    const [state, dispatch] = useReducer(innerUserFormReducer, {}, initState);
    const {data, formError, passValidStatus, userAddStatus, roles} = state || {};
    const {editState} = innerUserFormActions;

    const onChangeState = (key, value) => editState(key, value)(dispatch)

    const handleOnClickAdd = () => {
        onChangeState('resetValidate', true)

        if (!formError.length && passValidStatus && roles.length) {
            const dataWithoutSpaces = {};

            onChangeState('userAddStatus', true)
            Object.keys(data).forEach(item => dataWithoutSpaces[item] = item === 'roles' ? data[item] : data[item].trim())

            RegisterInnerService.create(dataWithoutSpaces)
                .then(res => {
                    onChangeState('userAddStatus', false)
                    hideModal()
                })
                .catch(error => {
                    if (error?.errors) onChangeState('requestErrors', error.errors)
                    onChangeState('userAddStatus', false)
                })
        }
    }

    return (
        <div className="addInnerUser register-form">
            <p className="modalFullScreen__title">Добавить пользователя</p>
            <div className="addInnerUser__content">
                <InnerUserForm state={state} dispatch={dispatch}/>
                <div className="addInnerUser__btnGroup">
                    <Button color="danger" outline size="sm" onClick={() => hideModal()}>
                        Отменить
                    </Button>
                    <Button color="primary" size="lg" onClick={handleOnClickAdd}>
                        <div className="btn-spinner-group">
                            <Spinner className={`btn-spinner ${userAddStatus ? 'btn-spinner-visible' : ''}`}/>
                            Добавить
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default AddInnerUserModal;
import React from 'react';
import {Table} from 'reactstrap';
import CalculationsStatus from "../CalculationsStatus";
import PrimaryTooltip from "@shared/components/tooltip";
import {
    plannedInfoBlock,
    plannedTransactions
} from "@shared/components/projects/projectDetail/ProjectCalculations/modal/helpers";
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";

const PlannedPayment = () => {
    return (
        <div className='plannedPayment'>
            <div className='platformServicesModal__feed'>
                <BackButton/>
            </div>
            <span
                className='earlyRepayment__title'>Информация по погашению планового платежа по договору займа №****"</span>
            <div className='plannedPayment__table'>
                <Table borderless>
                    <thead>
                    <tr className='plannedPayment__table_title'>
                        <th>Плановая дата</th>
                        <th className='projectCalculations__header_plannedPayment'>
                            Плановый <br/>платеж
                            <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                                <p>
                                    1. Проект перешел к стадии подписания документов.
                                    2. Проект перешел к стадии выплат
                                    3. Проект перешел в просрочку
                                    4. Проект завершен
                                    5. Проект отменен
                                    6. Проект не состоялся
                                </p>
                            </PrimaryTooltip>
                        </th>
                        <th>Проценты</th>
                        <th className='projectCalculations__header_income'>
                            НДФЛ
                            <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                                <p>
                                    1. Проект перешел к стадии подписания документов.
                                    2. Проект перешел к стадии выплат
                                    3. Проект перешел в просрочку
                                    4. Проект завершен
                                    5. Проект отменен
                                    6. Проект не состоялся
                                </p>
                            </PrimaryTooltip>
                        </th>
                        <th>Фактическая <br/>сумма платежа</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className='plannedPayment__table_desc'>
                        <td>20.20.2020</td>
                        <td>9 410 ₽</td>
                        <td>410 ₽</td>
                        <td>410 ₽</td>
                        <td>410 ₽</td>
                        <td>
                            <CalculationsStatus status={{color: '#58BF91'}}/>
                            Оплачен полностью
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className='plannedPayment__blocks'>
                {
                    plannedInfoBlock.map((elem) => (
                        <div key={elem.id} className='plannedPayment__infoBlock'>
                            <span className='plannedPayment__infoBlock_title'>{elem.title}</span>
                            <div className='plannedPayment__infoBlock_data'>
                                <div className='plannedPayment__infoBlockData'>
                                    <span>{elem.dutyName}</span>
                                    <span>{elem.dutyDesc}</span>
                                </div>
                                <div className='plannedPayment__infoBlockData'>
                                    <span>{elem.interestName}</span>
                                    <span>{elem.interestDesc}</span>
                                </div>
                                <div className='plannedPayment__infoBlockData'>
                                    <span>{elem.penaltiesName}</span>
                                    <span>{elem.penaltiesDesc}</span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className='plannedPayment__transactions'>
                <span className='plannedPayment__transactions_title'>Транзакции</span>
                {
                    plannedTransactions.map((elem, idx) => (
                        <div key={idx} className='plannedPayment__transactions_list'>
                            {
                                elem.map((item) => (
                                        <div key={item.id} className='plannedPayment__transactionsList_desc'>
                                            <span>{item.name}</span>
                                            <span>{item.desc}</span>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

export default PlannedPayment;
import React, {useEffect, useReducer, useState} from 'react';
import {Col, Row} from 'reactstrap';
import {stCount} from "@utils/form";
import {CalendarIcon} from '@shared/img/iconSvg';
import InputText from '@shared/components/form/profileForm/InputText';
import RegFormCode from "@shared/components/form/registerForm/RegFormCode";
import {
    eighteenYearsAgo,
    innMask,
    innMaskEntity,
    ogrnipMask,
    reducer,
    snilsMask
} from "@shared/components/account/helpers";
import RefFormPhone from "@shared/components/form/registerForm/phoneConfirmForm/RegFormPhone";
import {initialReducerData} from './helpers';

const EditPersonalData = ({profileData}) => {
    const [confirmCodeVisible, setConfirmCodeVisible] = useState(false) // Вмдимость поля ввода кода
    const [confirmedCode, setConfirmedCode] = useState(false) // Статус подтверждения кода
    // eslint-disable-next-line no-unused-vars
    const [_, setFormValidate] = useState(false) // Валидность всех полей формы
    const [formError, setFormError] = useState([]) // Ошибочные поля
    const [fioResetValidate, setFioResetValidate] = useState(false)
    const [residenceAddressResetValidate, setResidenceAddressResetValidate] = useState(false)
    const [codeValue, setCodeValue] = useState('') // Сохраняем ввод в input
    const {user, legal_form_type} = profileData || {};

    const initialData = initialReducerData(profileData);
    const [formData, setFormData] = useReducer(reducer, profileData, initialReducerData);

    const handleOnChange = ({target}) => {
        setFormData({
            name: target.name,
            value: target.value,
        })
    }

    const noMiddleNameOnChange = (e) => {
        handleOnChange(e)
        // Обрезать fio до двух слов
        const fioTrim = formData.fio.replace(/\s+/g, ' ').trim();
        if (stCount(fioTrim) > 2 && !formData.without_patronymic) {
            setFormData({
                name: 'fio',
                value: fioTrim.split(" ").slice(0, -(stCount(fioTrim) - 2)).join(" "),
            })
        }

        setFioResetValidate(!fioResetValidate)
    }

    const addressMatchesOnChange = e => {
        handleOnChange(e)
        setFormData({
            name: 'residence_address',
            value: formData.registration_address,
        })
        setResidenceAddressResetValidate(!residenceAddressResetValidate)
    }

    // Показать || скрыть поле кода
    const handleVisibleCode = status => {
        setConfirmCodeVisible(status)
    }

    useEffect(() => {
        let validateStatus = true;
        let identical = true;
        for (let key in formData) {
            if ((formData.phone === initialData.phone) && key === 'phone_code') continue;
            if (('' + formData[key]).length === 0) validateStatus = false
        }

        if (validateStatus) {
            for (let key in formData) {
                if ((initialData[key] !== formData[key])) identical = false
            }
            if (identical) {
                setFormValidate(false)
            } else {
                setFormValidate(true)
            }
        } else {
            setFormValidate(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    return <div className="profileTabs__edit">
        <InputText
            id="fio"
            name="fio"
            label="ФИО"
            type="text"
            value={formData.fio}
            onChange={handleOnChange}
            validator={[
                {required: true},
                {maxWords: formData.without_patronymic ? 2 : 4},
                {minWords: formData.without_patronymic ? 2 : 3},
                {onlyCyrillic: true}
            ]}
            containerClassName="fio__container"
            resetValidate={fioResetValidate}
            setResetValidate={setFioResetValidate}
            formError={formError}
            setFormError={setFormError}
        />
        <InputText
            type="checkbox"
            label="Нет отчества"
            id="without_patronymic"
            className='noMiddleName'
            name="without_patronymic"
            onChange={noMiddleNameOnChange}
            value={formData.without_patronymic}
            containerClassName="noMiddleName__container"
        />
        <Row>
            <Col md={6}>
                <InputText
                    type="date"
                    name="birth_date"
                    icon={CalendarIcon}
                    maxDate={eighteenYearsAgo}
                    label="Дата рождения"
                    onChange={handleOnChange}
                    value={formData.birth_date}
                    validator={[{required: true}]}
                    containerClassName="date-picker profileTabs__edit_item"
                    formError={formError}
                    setFormError={setFormError}
                />
            </Col>
        </Row>
        <div className="profileTabs__edit_phone profileTabs__edit_item">
            <RefFormPhone
                name="phone"
                legalForm={true}
                btnTitle='Изменить'
                value={formData.phone}
                phoneInputDisabled={false}
                className="profileTabs__phone"
                confirmedCode={formData.phone_code}
                handleVisibleCode={handleVisibleCode}
                onChange={phone => setFormData({name: 'phone', value: phone})}
                formError={formError}
                setFormError={setFormError}
            />
            <div className={`form__form-group form__confirm-code ${confirmCodeVisible ? 'fShow' : ''}`}>
                <RegFormCode
                    showIcon
                    codeValue={codeValue}
                    setCodeValue={setCodeValue}
                    parentInput="phone"
                    parentValue={formData.phone}
                    handleVisibleValidate={false}
                    className="profileTabs__phone_code"
                    handleVisibleCode={handleVisibleCode}
                    handleConfirmedCode={() => setConfirmedCode(!confirmedCode)}
                    onChange={code => setFormData({name: 'phone_code', value: code})}
                />
            </div>
        </div>
        {
            legal_form_type === 'entity'
                ? <InputText
                    name="position"
                    label="Должность"
                    onChange={handleOnChange}
                    value={formData.position}
                    validator={[{required: true}, {maxLength: 100}]}
                    containerClassName="profileTabs__edit_item"
                    formError={formError}
                    setFormError={setFormError}
                />
                : null
        }
        {
            legal_form_type === 'ie'
                ? <InputText
                    type="mask"
                    name="ogrnip"
                    label="ОГРНИП"
                    onChange={handleOnChange}
                    value={formData.ogrnip}
                    validator={[{required: true}, {numbersCount: 15}]}
                    mask={ogrnipMask}
                    containerClassName="profileTabs__edit_item"
                    formError={formError}
                    setFormError={setFormError}
                />
                : null
        }
        <Row>
            <Col md={6}>
                <InputText
                    type="mask"
                    name="inn"
                    label="ИНН"
                    onChange={handleOnChange}
                    value={formData.inn}
                    validator={[{required: true}, {numbersCount: legal_form_type === 'entity' ? 10 : 12}]}
                    mask={legal_form_type === 'entity' ? innMaskEntity : innMask}
                    containerClassName="profileTabs__edit_item"
                    formError={formError}
                    setFormError={setFormError}
                />
            </Col>
        </Row>
        {
            user && user.roles[0].name === 'borrower'
                ? <Row>
                    <Col md={6}>
                        <InputText
                            type="mask"
                            name="snils"
                            label="СНИЛС"
                            placeholder="999-999-999 99"
                            onChange={handleOnChange}
                            value={formData.snils}
                            validator={[{required: true}, {numbersCount: 11}]}
                            mask={snilsMask}
                            containerClassName="profileTabs__edit_item"
                            formError={formError}
                            setFormError={setFormError}
                        />
                    </Col>
                </Row>
                : null
        }
        <InputText
            type="text"
            name="birth_place"
            label="Место рождения"
            onChange={handleOnChange}
            value={formData.birth_place}
            validator={[{required: true}, {maxLength: 200}]}
            containerClassName="profileTabs__edit_item"
            formError={formError}
            setFormError={setFormError}
        />
        <InputText
            type="text"
            name="registration_address"
            label="Адрес регистрации по месту жительства"
            onChange={handleOnChange}
            value={formData.registration_address}
            validator={[{required: true}, {maxLength: 500}]}
            containerClassName="profileTabs__edit_item"
            formError={formError}
            setFormError={setFormError}
        />
        {
            user && user.roles[0].name === 'borrower'
                ? <>
                    <InputText
                        type="text"
                        name="residence_address"
                        label="Адрес фактического пребывания"
                        onChange={handleOnChange}
                        value={formData.address_matches ? formData.registration_address : formData.residence_address}
                        validator={formData.address_matches ? [] : [{required: true}, {maxLength: 500}]}
                        disabled={formData.address_matches}
                        containerClassName="profileTabs__edit_item"
                        resetValidate={residenceAddressResetValidate}
                        setResetValidate={setResidenceAddressResetValidate}
                        formError={formError}
                        setFormError={setFormError}
                    />
                    <InputText
                        type="checkbox"
                        name="address_matches"
                        id="address_matches"
                        label="Совпадает с адресом регистрации"
                        onChange={addressMatchesOnChange}
                        value={formData.address_matches}
                        containerClassName="profileTabs__edit_item profileTabs__edit_check"
                    />
                </>
                : null
        }
    </div>

}

export default EditPersonalData;
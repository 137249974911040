import {shortDate} from "@utils/date";

export const profileDataFormatter = (data, legal_form_type) => {
    const {personal, passport, company, additionalInfo} = data;

    const companyTrim = {};
    const personalTrim = {};
    const passportTrim = {};

    // company
    if (legal_form_type === "entity") {
        for (let key in company) {
            if (typeof company[key] === 'string') {
                companyTrim[key] = company[key].replace(/\s+/g, ' ').trim()
            } else {
                companyTrim[key] = company[key]
            }
        }
        if (companyTrim.charters.length) {
            companyTrim.charters = companyTrim.charters.map(item => item.id);
        }
    }


    //  personal
    for (let key in personal) {
        if (typeof personal[key] === 'string') {
            personalTrim[key] = personal[key].replace(/\s+/g, ' ').trim()
        } else {
            personalTrim[key] = personal[key]
        }
    }

    // passport
    for (let key in passport) {
        if (typeof passport[key] === 'string') {
            passportTrim[key] = passport[key].replace(/\s+/g, ' ').trim()
        } else {
            passportTrim[key] = passport[key]
        }
    }

    if (passportTrim.first_photo.length) {
        passportTrim.first_photo_id = passportTrim.first_photo[0].id;
    }

    if (passportTrim.second_photo.length) {
        passportTrim.second_photo_id = passportTrim.second_photo[0].id;
    }

    // addInfo
    const result = [];

    additionalInfo.forEach((item) => {
        let personNumber = 0;
        const values = [];

        for (let key in item) {

            if ((item[key].main && item[key].main.value[0] !== 0) || personNumber === 0) {
                personNumber++;

                for (let idx in item[key]) {

                    switch (item[key][idx].field.type_key) {
                        case 'date':
                            values.push({
                                field_id: item[key][idx].field_id,
                                person: personNumber - 1,
                                value: [shortDate(item[key][idx].value[0] || '')]
                            })
                            break;
                        case 'files':
                        case 'file':
                            values.push({
                                field_id: item[key][idx].field_id,
                                person: personNumber - 1,
                                value: item[key][idx].value.filter(item => item !== null).map(item => item.id)
                            })
                            break;
                        default:
                            values.push({
                                field_id: item[key][idx].field_id,
                                person: personNumber - 1,
                                value: item[key][idx].value
                            })
                            break;

                    }
                }

            }

        }

        result.push({
            id: item.id,
            values
        })
    })

    return {
        personal: personalTrim,
        passport: passportTrim,
        company: companyTrim,
        responses: result
    }
}
import {accreditedStatusDesc, legalFormTypeDesc, qualifiedStatusDesc, roleDesc} from "@utils/auth";

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_CHECKBOX_VALUE = 'SET_CHECKBOX_VALUE';
export const SET_DATE_VALUE = 'SET_DATE_VALUE';
export const SET_SEARCH_FIELDS_VALUE = 'SET_SEARCH_FIELDS_VALUE';
export const FILTER_RESET = 'FILTER_RESET';
export const SET_VISIBLE = 'SET_VISIBLE';
export const SET_ALL_VISIBLE = 'SET_ALL_VISIBLE';
export const SET_SORTED = 'SET_SORTED';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const initState = {
    search: {
        'id': '',
        'legal_form.personal.registration_address': '',
        'legal_form_type': [],
        'legal_form.personal.fio': '',
        'legal_form.personal.inn': '',
        'legal_form.company.inn': '',
        'legal_form.personal.ogrnip': '',
        'legal_form.company.kpp': '',
        'legal_form.personal.birth_date': [],
        'accreditation_status_key': '',
        'qualification_status_key': '',
        'created_at': [],
        'accreditation.updated_at': [],
        'phone': '',
        'user.email': '',
    },
    searchFields: {
        'id': 'like',
        'legal_form.personal.registration_address': 'like',
        'legal_form_type': 'in',
        'legal_form.personal.fio': 'like',
        'legal_form.personal.inn': 'like',
        'legal_form.company.inn': 'like',
        'legal_form.personal.ogrnip': 'like',
        'legal_form.company.ogrn': 'like',
        'legal_form.company.kpp': 'like',
        'legal_form.personal.birth_date': 'between',
        'accreditation_status_key': 'in',
        'qualification_status_key': 'in',
        'created_at': 'between',
        'accreditation.updated_at': 'between',
        'phone': 'like',
        'user.email': 'like',
    },
    tableHeaderVisible: [
        'number', 'id', 'legal_form.personal.registration_address', 'legal_form_type', 'legal_form.personal.fio', 'fullName',
        'legal_form.company.inn', 'legal_form.company.ogrn', 'legal_form.company.kpp', 'legal_form.personal.inn', 'legal_form.personal.ogrnip', 'snils',
        'legal_form.personal.birth_date', 'role', 'accreditation_status_key', 'qualification_status_key',
        'created_at', 'accreditation.updated_at', 'phone', 'user.email', 'completedTransactions',
        'amountInvestmentsTransactions', 'amountReservedInvestment', 'amountAttractedInvestments'
    ],
    sortedBy: ['asc', 'asc', 'asc'],
    orderBy: ['legal_form.personal.birth_date', 'created_at', 'accreditation.updated_at'],
    currentPage: 1
}

export const reducer = (state, action) => {
    const {name, value, key, sort} = action.payload || {};

    switch (action.type) {
        case SET_SEARCH_VALUE:
            return {...state, search: {...state.search, [name]: value}}
        case SET_SEARCH_FIELDS_VALUE:
            return {...state, searchFields: {...state.searchFields, [name]: value}}
        case SET_VISIBLE:
            const newTableHeaderVisible = [...state.tableHeaderVisible];
            const index = newTableHeaderVisible.indexOf(name);
            index !== -1 ? newTableHeaderVisible.splice(index, 1) : newTableHeaderVisible.push(name)

            return {...state, tableHeaderVisible: newTableHeaderVisible}
        case SET_CHECKBOX_VALUE:
            const searchCheckboxCopy = [...state.search[name]];
            const valueIndex = searchCheckboxCopy.indexOf(value);
            valueIndex !== -1 ? searchCheckboxCopy.splice(valueIndex, 1) : searchCheckboxCopy.push(value)

            return {...state, search: {...state.search, [name]: searchCheckboxCopy}}
        case SET_ALL_VISIBLE:
            return {...state, tableHeaderVisible: initState.tableHeaderVisible}
        case SET_DATE_VALUE:
            const newSearchDate = [...state.search[key]];
            let newFieldsDate = '';
            name === 'from' ? newSearchDate[0] = value : newSearchDate[1] = value

            if (newSearchDate[0] && newSearchDate[1]) newFieldsDate = 'between'
            if (!newSearchDate[0] && newSearchDate[1]) newFieldsDate = '<'
            if (newSearchDate[0] && !newSearchDate[1]) newFieldsDate = '>'

            return {
                ...state,
                search: {...state.search, [key]: newSearchDate},
                searchFields: {...state.searchFields, [key]: newFieldsDate}
            }
        case SET_SORTED:
            const sortedByCopy = [...state.sortedBy];
            const orderByCopy = [...state.orderBy];
            const orderIndex = orderByCopy.indexOf(key);

            if (orderIndex !== -1) {
                sortedByCopy[orderIndex] = sort;
            } else {
                orderByCopy.push(key)
                sortedByCopy.push(sort)
            }

            return {...state, sortedBy: sortedByCopy, orderBy: orderByCopy}
        case FILTER_RESET:
            return {...initState}
        case SET_CURRENT_PAGE:
            return {...state, currentPage: action.payload}

        default:
            return {...state}
    }
}

export const registryProfileDataFormat = data => {
    if (data?.length) {
        return data.map((item, idx) => {
            const {
                id, legal_form, legal_form_type, accreditation_status_key, user, created_at,
                accreditation, phone, qualification_status_key
            } = item;

            return {
                'number': `№${idx + 1}`,
                'id': id,
                'link': ['id'],
                'linkUrl': [`/client_account/${id}`],
                'legal_form.personal.registration_address': legal_form?.personal?.registration_address || '-',
                'legal_form_type': legalFormTypeDesc[legal_form_type] || '-',
                'legal_form.personal.fio': legal_form_type === 'entity' ? legal_form?.company?.name : legal_form.personal.fio,
                'fullName': '-',
                'legal_form.company.inn': legal_form_type === 'entity' ? legal_form?.company?.inn : '-',
                'legal_form.company.ogrn': legal_form_type === 'entity' ? legal_form?.company?.ogrn : '-',
                'legal_form.company.kpp': legal_form_type === 'entity' ? legal_form?.company?.kpp || '-' : '-',
                'legal_form.personal.inn': legal_form?.personal?.inn || '-',
                'legal_form.personal.ogrnip': legal_form_type === 'ie' ? legal_form.personal.ogrnip : '-',
                'snils': '-',
                'legal_form.personal.birth_date': legal_form.personal.birth_date || '-',
                'role': roleDesc[user.roles[0].name] || '-',
                'accreditation_status_key': accreditedStatusDesc[accreditation_status_key] || '-',
                'qualification_status_key': user?.roles[0]?.name === 'investor' && legal_form_type === 'indiv'
                    ? qualifiedStatusDesc[qualification_status_key] || '-'
                    : '-',
                'created_at': created_at || '-',
                'accreditation.updated_at': accreditation?.updated_at || '-',
                'phone': phone || '-',
                'user.email': user.email || '-',
                'completedTransactions': '-',
                'amountInvestmentsTransactions': '-',
                'amountReservedInvestment': '-',
                'amountAttractedInvestments': '-'
            }
        })
    }

    return []
}



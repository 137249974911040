import React from "react";
import {Spinner} from "reactstrap";
import {uploadIcon} from "@shared/img/iconSvg";

const UploadMoreFiles = ({uploadMore, maxFiles, spinner, openDropzone, files}) => {
    if (uploadMore && files.length !== maxFiles) return (
        <div className="uploadField__more">
            {
                spinner
                    ? <Spinner className={`btn-spinner ${spinner ? 'btn-spinner-visible' : ''}`}/>
                    : <div className="uploadField__download" onClick={openDropzone}>
                        {uploadIcon()}
                    </div>
            }
        </div>
    )

    return null
}

export default UploadMoreFiles;
import React, {useEffect, useState} from 'react';
import DocumentTabs from "@containers/YourDocuments/DocumentTabs";
import {documentsDataFormat, yourDocumentsTableHeader, yourDocumentsTabs} from './helpers';
import BaseTable from "@shared/components/table/BaseTable";
import {FilesService} from "@services/file.service";
import {useDispatch, useSelector} from "react-redux";

const YourDocuments = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(yourDocumentsTabs[0].key);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [documents, setDocuments] = useState(null);
    const updateParentData = useSelector(state => state.modal.updateParentData);

    //const [inputValue, setInputValue] = useState(null);

    useEffect(() => {
        let unmounted = false;
        const type = yourDocumentsTabs.filter(item => item.key === activeTab);
        const params = {
            page,
            limit: 15,
            type: type[0].type,
            include: 'profile,profile.legal_form,project,signature'
        }

        setLoading(true);

        FilesService.getList(params)
            .then(res => !unmounted && setDocuments(res?.data || []))
            .catch(error => console.log(error))
            .finally(() => !unmounted && setLoading(false))

        return () => unmounted = true;
    }, [page, activeTab, updateParentData])

    return (
        <div className="yourDocuments">
            <h1 className="page-title">Ваши документы</h1>
            <div className="yourDocuments__tabs">
                <DocumentTabs documentsCount={documents?.data.length} activeTab={activeTab} setActiveTab={setActiveTab}
                              setDocuments={setDocuments}/>
            </div>
            <div className="yourDocuments__content">
                {
                    documents && <BaseTable
                        tableHeader={yourDocumentsTableHeader[activeTab]}
                        tableBody={documentsDataFormat(documents?.data || [], activeTab, dispatch)}
                        loading={loading}
                        setPage={setPage}
                        meta={documents?.meta}
                    />
                }
                {
                    loading && <div className="baseTable__loader">
                        <div className="load__icon-wrap">
                            <svg className="load__icon">
                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                            </svg>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default YourDocuments;
import React, {useEffect, useState} from 'react';
import UploadField from "@shared/components/uploadField/index";
import {FilesService} from "@services/file.service";

const GetFile = ({onChange, fileId, fileKey, title, fileType}) => {
    const [file, setFile] = useState([]);

    useEffect(() => {
        if (fileId) {
            FilesService.getById(fileId)
                .then(res => setFile([res.data.data]))
                .catch(error => console.log(error))
        }
    }, [fileId])

    return (
        <UploadField
            edit
            update
            viewDetail
            titleShow
            fileKey={fileKey}
            fileType={fileType}
            title={title}
            accept=".pdf, .doc, .docx"
            onChange={onChange}
            files={file}
        />
    )
}

export default GetFile;
import React from 'react';
import {accreditedStatusDesc, roleDesc} from "@utils/auth";

const AccreditationProfileStatus = ({status, stage}) => {
    if (!status || !stage) return null

    const AccreditationAuthor = () => {
        return status === 'accreditation_checking'
            ? <span>({roleDesc[stage]})</span>
            : null
    }

    return (
        <div className="dataCard accreditation__statusCard">
            <span className="accreditation__statusCard_title">Статус профиля:</span>
            <span className="accreditation__statusCard_desc">
                <span>{accreditedStatusDesc[status]} </span>
                <AccreditationAuthor/>
            </span>
        </div>
    )
};

export default AccreditationProfileStatus;
import React from 'react';
import SkeletonElement from "@shared/components/skeletons/SkeletonElement";

const SkeletonList = ({count = 1}) => {
    const list = [];

    for (let i = 0; i < count; i++) {
        list.push(
            <div key={i} className="skeletonList__item">
                <SkeletonElement type="text"/>
                <SkeletonElement type="text"/>
            </div>
        )
    }

    return list;
}

export default SkeletonList;
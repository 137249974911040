import React from 'react';
import EditPassportData from "./EditPassportData";
import PrimaryTooltip from '@shared/components/tooltip';
import UploadField from '@shared/components/uploadField';
import {accountDataItem} from "@containers/Account/Profile/helpers";

const AccountPassportData = ({profileData, editForm, title = 'Паспортные данные'}) => {
    const {
        issue_date, issued_by, serial_number, department_code, first_photo, second_photo
    } = profileData?.legal_form?.passport || {};

    const data = [
        {name: 'Серия и номер паспорта', desc: serial_number || ''},
        {name: 'Дата выдачи', desc: issue_date || ''},
        {name: 'Кем выдан', desc: issued_by || ''},
        {name: 'Код подразделения', desc: department_code || ''},
    ]

    return (
        <div className="profileTabs__tabItem accountPassportData">
            <div className="profileTabs__tabItem_title">
          <span>
            {title}
              <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
              <p>
                Паспортные данные необходимо заполнять в полном соответствии
                с документов подтверждающим личность (с сохранением всех сокращений)
              </p>
            </PrimaryTooltip>
          </span>
            </div>
            <div className="profileTabs__tabItem_list">
                {
                    editForm
                        ? <EditPassportData profileData={profileData}/>
                        : <>
                            {accountDataItem(data)}
                            <div className="profileTabs__tabItem_files">
                                <div className="profileTabs__tabItem_elem">
                      <span className="profileTabs__tabItem_name">
                        <span className="position-relative">
                          Страница с фотографией
                          <PrimaryTooltip name="firstPhotoScanTooltip" title="?" className="profileTabs__tabItem_tooltip">
                            <p>Первая страница паспорта</p>
                          </PrimaryTooltip>
                        </span>
                      </span>
                                    {
                                        first_photo
                                            ? <UploadField viewDetail files={[first_photo]} edit={false} title="паспорт"/>
                                            : <span className="profileTabs__tabItem_desc error">Заполните поле</span>
                                    }
                                </div>
                            </div>
                            <div className="profileTabs__tabItem_files">
                                <div className="profileTabs__tabItem_elem">
                      <span className="profileTabs__tabItem_name">
                        <span className="position-relative">
                          Страница с регистрацией
                          <PrimaryTooltip name="secondPhotoScanTooltip" title="?" className="profileTabs__tabItem_tooltip">
                            <p>Страница с регистрацией</p>
                          </PrimaryTooltip>
                        </span>
                      </span>
                                    {
                                        second_photo
                                            ? <UploadField viewDetail files={[second_photo]} edit={false} title="паспорт"/>
                                            : <span className="profileTabs__tabItem_desc error">Заполните поле</span>
                                    }
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default AccountPassportData
import React, {useState} from 'react';
import {RightArrowIcon} from "@shared/img/iconSvg";
import {Collapse, Table} from "reactstrap";
import classNames from "classnames";

const BorrowerExperienceTableItem = ({data}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const icon = classNames({
        'projectEvents__icon': true,
        'iconRotate': isOpen
    })

    return (
        <>
            <tr onClick={toggle} className='borrowerExperience__tableDesc'>
                <td
                    className='borrowerExperience__tableDesc_button'
                >
                    <RightArrowIcon className={icon}/>
                    {data.id}
                </td>
                <td>{`${data.sum} ₽`}</td>
                <td>{`${data.bid} %`}</td>
                <td>{`${data.term} мес.`}</td>
                <td>{data.dateOfIssue}</td>
                <td>{data.returnDate}</td>
                <td>{data.daysOverdue}</td>
                <td>
                    <span className={
                        data.loanRepaid === true
                            ? 'borrowerExperience__mark'
                            : 'borrowerExperience__mark borrowerExperience__mark_color'}>
                    </span>
                    {data.loanRepaid === true ? 'Да' : 'Нет'}
                </td>
            </tr>
            <tr aria-colspan="5">
                <td colSpan={8} className='borrowerExperience__labelPayment'>
                    <Collapse isOpen={isOpen}>
                        <Table striped>
                            <thead>
                            <tr className='borrowerExperience__payment'>
                                <th>Платеж</th>
                                <th>Сумма основного <br/>долга</th>
                                <th>Плановая дата <br/>погашения</th>
                                <th>Фактическая дата <br/>погашения</th>
                                <th>Период закрыт</th>
                                <th>Просрочка</th>
                            </tr>
                            </thead>
                            <tbody className='borrowerExperience__payment_desc'>
                            {
                                data.history.map(item => (
                                    <tr key={item.id} className='borrowerExperience__payment_list'>
                                        <td>{item.Payment}</td>
                                        <td>{item.principalAmount}</td>
                                        <td>{item.plannedMaturityDate}</td>
                                        <td>{item.actualMaturityDate}</td>
                                        <td>{item.periodClosed}</td>
                                        <td>{item.delay}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Collapse>
                </td>
            </tr>
        </>
    )
};

export default BorrowerExperienceTableItem;
import React from 'react';
import DataCard from "@shared/components/data/DataCard";

const ProfileData = ({data, fRef}) => {
    const dataFormat = [
        {
            name: 'ID профиля',
            desc: data?.project?.profile?.id || '',
            className: 'green-color',
            type: 'link',
            link: `/client_account/${data?.project?.profile?.id}`
        },
        {name: 'Email', desc: data?.project?.profile?.user?.email || ''},
    ]

    return <DataCard data={dataFormat} title="Данные профиля" fRef={fRef}/>
}

export default ProfileData;

import React from 'react';
import {Table} from "reactstrap";
import RegistryTableHeader from "@containers/Moderator/Registry/RegistryTable/RegistryTableHeader";
import {Link} from "react-router-dom";
import {DownloadIcon} from "@shared/img/iconSvg";
import classNames from "classnames";
import {CSVLink} from "react-csv";

const RegistryTable = ({
                           filterDispatch, tableHeader, tableData, loading, state, filterReset = () => {
    },
                           initState, excelData, tableTitle, className, HeaderButton, noControls
                       }) => {
    const tableClass = classNames({
        accreditationTable__load: loading,
        [className]: className
    });
    const tableHeaderServicesClass = classNames({registryTable__header: tableTitle || HeaderButton});
    const {search: filterState, orderBy, sortedBy, tableHeaderVisible: headerVisible} = state;

    const renderTableBody = () => {
        const getTableRow = data => {
            const dataFormat = [];

            for (let key in data) {
                const linkIdx = data.link.indexOf(key);

                if (headerVisible.includes(key)) {
                    dataFormat.push(
                        <td key={key}>
                            {linkIdx !== -1 ? <Link to={data.linkUrl[linkIdx]}>{data[key]}</Link> : data[key]}
                        </td>
                    )
                }
            }

            return dataFormat;
        }

        return tableData?.length
            ? tableData.map((item, idx) => (
                <tr key={idx}>
                    {getTableRow(item)}
                </tr>
            ))
            : null
    }

    return (
        <div className={tableClass}>
            {
                !noControls
                    ? <div className={tableHeaderServicesClass}>
                        {tableTitle ? <div className="registryTable__title"><p>{tableTitle}</p></div> : null}
                        {HeaderButton ? <HeaderButton/> : null}
                        <div className="registryTable__services">
                            <button
                                onClick={filterReset}
                                disabled={JSON.stringify(state) === JSON.stringify(initState)}
                                className="btn-reset registryTable__services_reset"
                            >
                                Сбросить фильтры
                            </button>
                            <CSVLink{...excelData}>
                                <button className="btn-reset registryTable__services_download">
                                    <div className="uploadField__download">
                                        <DownloadIcon/>
                                    </div>
                                    <span>Скачать в Excel</span>
                                </button>
                            </CSVLink>
                        </div>
                    </div>
                    : null
            }
            <Table className="table--bordered registryTable" responsive>
                <RegistryTableHeader
                    orderBy={orderBy}
                    sortedBy={sortedBy}
                    filterState={filterState}
                    filterDispatch={filterDispatch}
                    data={tableHeader}
                />
                <tbody>
                {renderTableBody()}
                </tbody>
            </Table>
            {
                loading
                    ? <div className="accreditationTable__loader">
                        <div className="load__icon-wrap">
                            <svg className="load__icon">
                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                            </svg>
                        </div>
                    </div>
                    : ''
            }
        </div>
    )
}

export default RegistryTable;

import React, {useEffect, useState} from 'react';
import {AuthService} from "@services/auth.service";
import AccountWrapper from "@account/AccountWrapper";
import FormPass from '@shared/components/form/FormPass';
import {leastOneNumber, lowercaseLetter, uppercaseLetter} from "@shared/components/form/validate";
import {Link, NavLink} from "react-router-dom";

const ResetPassNewPassword = ({token}) => {
    const [pass, setPass] = useState(''); // Пароль
    const [repeatPass, setRepeatPass] = useState(''); // Повтор пароля
    const [passSuccess, setPassSuccess] = useState(false); // Пароль соответствует требованиям
    const [repeatPassSuccess, setRepeatPassSuccess] = useState(false); // "Повтор пароля" соответствует требованиям
    const [disabledRepeatPass, setRepeatDisabledPass] = useState(true); // Активность поля "Повторить пароль"
    const [showPassword, setShowPassword] = useState(false); // Показать - скрыть пароль
    const [passIdentical, setPassIdentical] = useState(false); // Пароли совпадают
    const [successResetPass, setSuccessResetPass] = useState(false); // Статус изменения пароля
    const [errorMessage, setErrorMessage] = useState(null); // Сообщение об ошибке

    // Показать - скрыть пароль
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // Валидация пароля
    const [lengthValid, setLengthValid] = useState(false) // Не менее 8 символов
    const [oneNumberValid, setOneNumberValid] = useState(false) // Одна цифра
    const [lowercaseSymbol, setLowercaseSymbol] = useState(false) // Одна строчная буква
    const [uppercaseSymbol, setUppercaseSymbol] = useState(false) // Одна прописная буква

    // Обработка ввода
    const handleChangePass = value => {
        if (!/[а-яА-ЯїЇєЄіІёЁ ]/g.test(value) && value.length < 255) setPass(value) // Кроме кириллицы и пробелов. Длина не больше 255

        // Скрыть сообщение об ошибке
        if (errorMessage) setErrorMessage(null)

        // Валидация пароля
        value.length >= 8 ? setLengthValid(false) : setLengthValid(true)
        leastOneNumber.test(value) ? setOneNumberValid(false) : setOneNumberValid(true)
        lowercaseLetter.test(value) ? setLowercaseSymbol(false) : setLowercaseSymbol(true)
        uppercaseLetter.test(value) ? setUppercaseSymbol(false) : setUppercaseSymbol(true)
    }

    // Обработка повторного ввода пароля
    const handleChangeRepeatPass = value => {
        setRepeatPass(value)

        // Скрыть сообщение об ошибке
        if (errorMessage) setErrorMessage(null)
    }

    // Сохранить новый пароль
    const saveNewPass = () => {
        if (token && passIdentical && pass && repeatPass) {
            AuthService.changePassword(token, pass, repeatPass).then(res => {
                setSuccessResetPass(true)
            }).catch(error => {
                if (successResetPass) setSuccessResetPass(false)
                if (error.message) setErrorMessage(error.message)
            })
        }
    }

    // Показать - скрыть галочку
    useEffect(() => {
        if (!lengthValid && !oneNumberValid && !lowercaseSymbol && !uppercaseSymbol && pass.length) {
            setPassSuccess(true)
            setRepeatDisabledPass(false)
        } else {
            if (passSuccess) setPassSuccess(false)
            if (!disabledRepeatPass) {
                setRepeatDisabledPass(true)
            }
        }
    }, [lengthValid, oneNumberValid, lowercaseSymbol, uppercaseSymbol, disabledRepeatPass, pass.length, passSuccess])

    // Сравнение паролей
    useEffect(() => {
        if (pass === repeatPass && repeatPass.length) {
            setRepeatPassSuccess(true)
            setPassIdentical(true)
        } else {
            if (repeatPassSuccess) setRepeatPassSuccess(false)
            if (passIdentical) setPassIdentical(false)
        }
    }, [passIdentical, pass, repeatPass, repeatPassSuccess])

    return (
        <AccountWrapper className="send-email-success">
            <div className="accountFormWrap__mobile_header">
                <h1>Восстановление пароля</h1>
                <NavLink exact to="login" className="accountFormWrap__mobile_auth">Вход</NavLink>
            </div>
            <div className="accountFormWrap">
                <div className="accountFormWrap__bg"/>
                <div className="accountFormWrap__wrap">
                    <div className="accountFormWrap__content">
                        <div className="login-form-container">
                            <h1>Восстановление пароля</h1>
                            {
                                successResetPass
                                    ? <>
                                        <div className="reset-password-desc">
                                            <div>
                                                <p>Ваш пароль успешно изменен!</p>
                                            </div>
                                        </div>
                                        <Link className="btn btn-primary register-form-btn" to="/login">Войти</Link>
                                    </>
                                    : <>
                                        <div className="register-form form new-pass-group">
                                            <div className="form__form-group">
                                                <FormPass
                                                    title="новый пароль"
                                                    placeholder="пароль не менне 8 символов"
                                                    success={passSuccess}
                                                    value={pass}
                                                    setValue={handleChangePass}
                                                    showPassword={showPassword}
                                                />
                                                {errorMessage && <span className="form__form-error">{errorMessage}</span>}
                                            </div>
                                            <div className="form__form-group">
                                                <FormPass
                                                    title="повторите пароль"
                                                    placeholder="повторите пароль"
                                                    success={repeatPassSuccess}
                                                    value={repeatPass}
                                                    showPassBtn={true}
                                                    toggleShowPassword={toggleShowPassword}
                                                    disabled={disabledRepeatPass}
                                                    showPassword={showPassword}
                                                    setValue={handleChangeRepeatPass}
                                                />
                                            </div>
                                            <div className="form__pass-validate form__confirm-code fShow">
                                                <span
                                                    className={`${uppercaseSymbol ? "error" : ''}`}>одна прописная буква</span>
                                                <span
                                                    className={`${lowercaseSymbol ? "error" : ''}`}>одна строчная буква</span>
                                                <span className={`${lengthValid ? "error" : ''}`}>не менее 8 символов</span>
                                                <span className={`${oneNumberValid ? "error" : ''}`}>одна цифра</span>
                                            </div>
                                        </div>
                                        <button
                                            className="btn btn-primary register-form-btn"
                                            disabled={!passIdentical}
                                            onClick={saveNewPass}
                                        >
                                            Сохранить
                                        </button>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </AccountWrapper>
    )
}

export default ResetPassNewPassword;
import React, {useReducer, useState} from 'react';
import {Button} from "reactstrap";
import ApplicantsDetails from "./ApplicantsDetails";
import ModerationProjectTerms from "./ModerationProjectTerms";
import ModerationProjectDescription from "./ModerationProjectDescription";
import ModerationProjectPledge from "./ProjectPledges/ModerationProjectPledge";
import ModerationProjectBankDetails from "./ModerationProjectBankDetails";
import ModerationProjectSolution from "./ModerationProjectSolution";
import ModerationProjectFinishBtn from "./ModerationProjectFinishBtn";
import {governingStructureReducer, initState} from "@shared/components/form/moderation/helpers";
import {analyticReducer, initAnalyticState} from "@shared/components/form/moderation/AnalyticUnit/helpers";
import {initPledgesState, pledgesReducer} from "@shared/components/form/moderation/EstimatedСollateral/helpers";
import {
    checkAnalyticValidate,
    checkGoverningValidate,
    checkPledgeValidate,
    checkRateValidate,
    checkTermsValidate,
    navigationMenu
} from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/helpers";
import {
    HIDE_INPUT_EDIT,
    initRateState,
    rateReducer
} from "@shared/components/form/moderation/PlatformServiceForm/helpers";
import {HIDE_ALL_INPUT_EDIT, initTermsState, termsReducer} from "@shared/components/presentation/TermsProject/helpers";

const ModerationProjectDetailSteps = ({step, setStep, projectData, showResult}) => {
    const [remarkList, setRemarkList] = useState(showResult ? projectData.remarks : []);
    const [analyticValidStatus, setAnalyticValidStatus] = useState(true);
    const [pledgesValidStatus, setPledgesValidStatus] = useState(true);
    const [governingValidStatus, setGoverningValidStatus] = useState(true);
    const [rateValidStatus, setRateValidStatus] = useState(true);
    const [termsValidStatus, setTermsValidStatus] = useState(true);
    const role = projectData?.project?.profile?.legal_form_type || null;

    const [governmentState, governmentDispatch] = useReducer(
        governingStructureReducer,
        projectData?.project?.government || {},
        initState
    );

    const [analyticState, analyticDispatch] = useReducer(
        analyticReducer,
        projectData?.project?.analytic || {},
        initAnalyticState
    );

    const [pledgesState, pledgesDispatch] = useReducer(
        pledgesReducer,
        projectData?.project?.pledges || {},
        initPledgesState
    );

    const [rateState, rateDispatch] = useReducer(
        rateReducer,
        projectData?.project?.rate || {},
        initRateState
    );

    const [termsState, termsDispatch] = useReducer(
        termsReducer,
        projectData?.project?.condition || {},
        initTermsState
    );

    const renderContent = () => {
        if (projectData) {
            switch (step) {
                case 0:
                    return <ApplicantsDetails
                        step={step}
                        role={role}
                        data={projectData}
                        showResult={showResult}
                        remarkList={remarkList}
                        setRemarkList={setRemarkList}
                        governmentState={governmentState}
                        governmentDispatch={governmentDispatch}
                        governingValidStatus={governingValidStatus}
                    />
                case 1:
                    return <ModerationProjectTerms
                        step={step}
                        data={projectData}
                        showResult={showResult}
                        remarkList={remarkList}
                        setRemarkList={setRemarkList}
                        rateState={rateState}
                        rateDispatch={rateDispatch}
                        rateValidStatus={rateValidStatus}
                        termsState={termsState}
                        termsDispatch={termsDispatch}
                        termsValidStatus={termsValidStatus}
                    />
                case 2:
                    return <ModerationProjectDescription
                        step={step}
                        data={projectData}
                        showResult={showResult}
                        remarkList={remarkList}
                        setRemarkList={setRemarkList}
                        analyticState={analyticState}
                        analyticDispatch={analyticDispatch}
                        analyticValidStatus={analyticValidStatus}
                    />
                case 3:
                    return <ModerationProjectPledge
                        step={step}
                        data={projectData}
                        showResult={showResult}
                        remarkList={remarkList}
                        setRemarkList={setRemarkList}
                        pledgesState={pledgesState}
                        pledgesDispatch={pledgesDispatch}
                        pledgesValidStatus={pledgesValidStatus}
                    />
                case 4:
                    return <ModerationProjectBankDetails
                        data={projectData}
                        step={step}
                    />
                case 5:
                    return <ModerationProjectSolution
                        moderationHistory={projectData.project?.moderations || []}
                        step={step}
                        remarkList={remarkList}
                        setRemarkList={setRemarkList}
                        pledgesState={pledgesState}
                    />
                default:
                    return null
            }
        }

        return null
    }

    const renderControls = () => {

        const handleControlsOnClick = back => {
            if (back) {
                setStep(prevStep => prevStep - 1)
                return;
            }

            switch (step) {
                case 0:
                    if (role === 'entity' && !checkGoverningValidate(governmentState, setGoverningValidStatus)) {
                        navigationMenu[0].links.entity[4].ref.current.scrollIntoView();
                    } else {
                        window.scrollTo(0, 0)
                        setStep(prevStep => prevStep + 1)
                    }
                    break;
                case 1:
                    if (!checkRateValidate(rateState, rateDispatch, setRateValidStatus)) {
                        navigationMenu[step].links[1].ref.current.scrollIntoView();
                    } else if (!checkTermsValidate(termsState, termsDispatch, setTermsValidStatus)) {
                        navigationMenu[step].links[0].ref.current.scrollIntoView();
                    } else {
                        termsDispatch({type: HIDE_ALL_INPUT_EDIT})
                        rateDispatch({type: HIDE_INPUT_EDIT})
                        window.scrollTo(0, 0)
                        setStep(prevStep => prevStep + 1)
                    }
                    break;
                case 2:
                    if (!checkAnalyticValidate(analyticState, setAnalyticValidStatus)) {
                        navigationMenu[step].links[1].ref.current.scrollIntoView();
                    } else {
                        window.scrollTo(0, 0)
                        setStep(prevStep => prevStep + 1)
                    }
                    break;
                case 3:
                    if (!checkPledgeValidate(pledgesState, setPledgesValidStatus)) {
                        navigationMenu[step].links[0].ref.current.scrollIntoView();
                    } else {
                        window.scrollTo(0, 0)
                        setStep(prevStep => prevStep + 1)
                    }
                    break;
                default:
                    window.scrollTo(0, 0)
                    setStep(prevStep => prevStep + 1)
            }

        }

        return step === 5
            ? <ModerationProjectFinishBtn
                moderationId={projectData.id}
                showResult={showResult}
                moderationStatus={projectData?.project?.moderation_status_key}
                projectId={projectData.id}
                remarkList={remarkList}
                government={governmentState}
                analytic={analyticState}
                pledges={pledgesState.pledges}
                rate={rateState.rate}
                condition={termsState.condition}
                role={role}
            />
            : <div className="moderationProjectContent__controls">
                {!!step && <Button color="success" size="sm" onClick={() => handleControlsOnClick(true)}
                                   outline>Назад</Button>}
                <Button
                    color="primary"
                    size="sm"
                    onClick={() => handleControlsOnClick()}
                >
                    Далее
                </Button>
            </div>
    }


    return (
        <div className="moderationProjectContent">
            {renderContent()}
            {renderControls()}
        </div>
    )
}

export default ModerationProjectDetailSteps;

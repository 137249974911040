import React, {useEffect, useState} from 'react';
import StepsSidebar from "@shared/components/layout/StepsSidebar";
import {moderationProjectStepList} from "@containers/Moderator/ModerationProjects/helpers";
import ModerationProjectDetailSteps from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/steps";
import AddProjectMenu from "@containers/Projects/AddProject/AddProjectMenu";
import {navigationMenu} from "./helpers";
import {getProjectModeration, SET_MODERATION_PROFILE_ID} from "@actions/moderationAction";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ProjectSummary from "@shared/components/presentation/ProjectSummary";


const ModerationProjectDetail = ({title, showResult}) => {
    const [step, setStep] = useState(0);
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [projectData, setProjectData] = useState(null);
    const moderationProfileId = useSelector(state => state.moderation.moderationProfileId);

    useEffect(() => {
        let unmounted = false;

        if (moderationProfileId) {
            getProjectModeration(moderationProfileId, dispatch)
                .then(res => !unmounted && setProjectData(res?.data?.data || {}))
                .catch(error => console.log(error))
        }

        return () => unmounted = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moderationProfileId])

    useEffect(() => {
        if (!moderationProfileId) {
            if (id) {
                dispatch({type: SET_MODERATION_PROFILE_ID, payload: id})
            } else {
                history.push('/404')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, moderationProfileId])

    useEffect(() => {
        if (showResult && ['projects_checking', 'is_draft'].includes(projectData?.project?.moderation_status_key)) {
            history.push('/404')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectData])

    return (
        <div className="moderationProject">
            <h1 className="page-title">{title ? title : 'Проведение модерации проекта'}</h1>
            <div className="addProject__row">
                <div className="profile__col addProject__sidebar">
                    <div className="addProject__sidebar_inner">
                        <div className="addProject__steps_wrap addProject__wrap">
                            <StepsSidebar step={step} setStep={setStep} stepList={moderationProjectStepList}/>
                        </div>
                    </div>
                </div>
                <div className="profile__col">
                    {
                        projectData &&
                        <>
                            <ProjectSummary data={projectData} showResult={showResult}/>
                            <div className="accreditationProfile moderationProjectDetail">
                                <ModerationProjectDetailSteps
                                    step={step}
                                    setStep={setStep}
                                    projectData={projectData}
                                    showResult={showResult}
                                />
                                <AddProjectMenu
                                    data={navigationMenu[step]}
                                    role={projectData?.project?.profile?.legal_form_type || null}
                                    className="moderationProjectMenu"
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default ModerationProjectDetail;

import React from 'react';

const AboutTheBorrowerAnalytic = () => {
    const dataAboutTheBorrowerAnalytic = [
        {
            title: 'Аналитический блок',
            id: 0,
            data: [
                {
                    id: 0,
                    name: 'Кредитная история',
                    value: 'Прежде всего, существующая теория обеспечивает широкому кругу теорию круга'
                },
                {
                    id: 1,
                    name: 'Приостановки по банковским счетам',
                    value: 'Прежде всего, существующая теория обеспечивает широкому кругу теорию круга'
                },
            ]
        },
        {
            title: 'Финансы на конец года',
            id: 1,
            data: [
                {
                    id: 0,
                    name: 'Баланс',
                    value: 'Прежде всего, существующая теория обеспечивает широкому кругу теорию круга'
                },
                {
                    id: 1,
                    name: 'Выручка',
                    value: 'Прежде всего, существующая теория обеспечивает широкому кругу теорию круга'
                },
                {
                    id: 2,
                    name: 'Чистая прибыль',
                    value: 'Прежде всего, существующая теория обеспечивает широкому кругу теорию круга'
                },
            ]
        },
        {
            title: 'Налоги и сборы',
            id: 2,
            data: [
                {
                    id: 0,
                    name: 'НДС',
                    value: 'Прежде всего, существующая теория обеспечивает широкому кругу теорию круга'
                },
                {
                    id: 1,
                    name: 'Взносы в ПФР',
                    value: 'Прежде всего, существующая теория обеспечивает широкому кругу теорию круга'
                },
                {
                    id: 2,
                    name: 'Всего уплачено',
                    value: 'Прежде всего, существующая теория обеспечивает широкому кругу теорию круга'
                },
                {
                    id: 3,
                    name: 'Задолженность на конец года',
                    value: 'Прежде всего, существующая теория обеспечивает широкому кругу теорию круга'
                },
            ]
        },
    ]
    return (
        <div className='aboutTheBorrower__analytic aboutTheBorrower__info'>
            {dataAboutTheBorrowerAnalytic.map((elem) =>
                <div key={elem.id}>
                    <span className='aboutTheBorrower__infoList_title'>{elem.title}</span>
                    {elem.data.map((item) =>
                        <div key={item.id} className='aboutTheBorrower__infoList_block'>
                            <span>{item.name}</span>
                            <span>{item.value}</span>
                        </div>
                    )}
                </div>
            )}
            <span className='aboutTheBorrower__intelligence_subtitle'>Записи в ЕГРЮЛ</span>
            <div className='aboutTheBorrower__intelligenceDesc aboutTheBorrower__infoList_block'>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>Дата</span>
                    <span>22.12.2021</span>
                </div>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>Событие</span>
                    <span>Прежде всего, существующая теория обеспечивает широкому кругу теорию круга</span>
                </div>
            </div>
            <span className='aboutTheBorrower__intelligence_subtitle'>Арбитражные дела</span>
            <div className='aboutTheBorrower__intelligenceDesc aboutTheBorrower__infoList_block'>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>Роль</span>
                    <span>Истец</span>
                </div>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>Роль</span>
                    <span>Ответчик</span>
                </div>
            </div>
            <div className='aboutTheBorrower__intelligenceDesc aboutTheBorrower__infoList_block'>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>За 12 месяцев</span>
                    <span className='aboutTheBorrower__intelligenceDesc_sum'>
                        <p>1 раз</p>
                        <p>10 000 000 ₽</p>
                    </span>
                </div>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>За 12 месяцев</span>
                    <span className='aboutTheBorrower__intelligenceDesc_sum'>
                        <p>1 раз</p>
                        <p>10 000 000 ₽</p>
                    </span>
                </div>
            </div>
            <div className='aboutTheBorrower__intelligenceDesc aboutTheBorrower__infoList_block'>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>За все время</span>
                    <span className='aboutTheBorrower__intelligenceDesc_sum'>
                        <p>5 раз</p>
                        <p>30 000 000 ₽</p>
                    </span>
                </div>
                <div className='aboutTheBorrower__intelligenceDesc_block '>
                    <span>За все время</span>
                    <span className='aboutTheBorrower__intelligenceDesc_sum'>
                        <p>5 раз</p>
                        <p>30 000 000 ₽</p>
                    </span>
                </div>
            </div>
            <div className='aboutTheBorrower__infoList_block'>
                <span>Исполнительные производства</span>
                <span>Прежде всего, существующая теория обеспечивает широкому кругу теорию круга</span>
            </div>
            <div className='aboutTheBorrower__infoList_block'>
                <span>Наличие в публичных списках</span>
                <span>Прежде всего, существующая теория обеспечивает широкому кругу теорию круга</span>
            </div>
            <span className='aboutTheBorrower__intelligence_subtitle'>Госзакупки</span>
            <div className='aboutTheBorrower__intelligenceDesc aboutTheBorrower__infoList_block'>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>Количество участий</span>
                    <span>1</span>
                </div>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>Сумма контрактов</span>
                    <span>10 000 000 ₽</span>
                </div>
            </div>
            <div className='aboutTheBorrower__intelligenceDesc aboutTheBorrower__infoList_block'>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>Заключено контрактов</span>
                    <span>1</span>
                </div>
                <div className='aboutTheBorrower__intelligenceDesc_block'>
                    <span>Сумма контрактов</span>
                    <span>10 000 000 ₽</span>
                </div>
            </div>
        </div>
    )
};

export default AboutTheBorrowerAnalytic;
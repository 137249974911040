import React, {useEffect, useReducer, useState} from 'react';
import {getTableHeader, initState} from "./data";
import {dataFormat, reducer, SET_CURRENT_PAGE} from "./helpers";
import {getInnerUserLogs} from "@actions/registryActions";
import RegistryTable from "@containers/Moderator/Registry/RegistryTable";
import Pagination from "@shared/components/pagination";
import {getRole} from "@utils/auth";

const InnerUserLogs = ({userId}) => {
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(null);
    const [data, setData] = useState(null);
    //const [columnFilterIsOpen, setColumnFilterIsOpen] = useState(false);
    const [tableHeader, setTableHeader] = useState([]);
    const role = getRole()
    const [state, dispatch] = useReducer(reducer, initState);

    const tableDataFormat = dataFormat(data?.data || []);
    const tableHeaderVisible = tableHeader.filter(item => state.tableHeaderVisible.includes(item.name));

    /*const columnFilterOnChange = e => {
        dispatch({
            type: SET_VISIBLE,
            payload: {name: e.target.name}
        })
    }*/

    const setCurrentPage = page => {
        dispatch({
            type: SET_CURRENT_PAGE,
            payload: page
        })
    }

    useEffect(() => {
        if (role) setTableHeader(getTableHeader(role))
    }, [role])

    useEffect(() => {
        let unmounted = false;
        setLoading(true);
        clearTimeout(timer);

        setTimer(setTimeout(() => {
            getInnerUserLogs(userId, state)
                .then(res => !unmounted && setData(res?.data || {}))
                .finally(() => !unmounted && setLoading(false))
        }, 500))

        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => unmounted = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.search, state.sortedBy, state.orderBy, state.currentPage])

    return (
        <div className="innerUser__logs">
            <div className="registry__content">
                <div className="registryTableWrap">
                    <RegistryTable
                        noControls
                        state={state}
                        initState={initState}
                        filterDispatch={dispatch}
                        tableHeader={tableHeaderVisible}
                        tableData={tableDataFormat}
                        loading={loading}
                        className="registry__actionProfile"
                    />
                    <Pagination
                        className="pagination-bar"
                        meta={data?.meta || {}}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
            </div>
        </div>
    )
};

export default InnerUserLogs;
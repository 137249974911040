import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import {Button} from "reactstrap";
import {dataCardDataFormat} from "./helpers";

const InnerUserViewData = ({data, setEditStatus}) => {
    return (
        <div className="">
            <DataCard data={dataCardDataFormat(data)} title="Данные пользователя" className="innerUserPage__dataCard"/>
            <Button
                color="primary"
                size="lg"
                onClick={() => setEditStatus(true)}
                className="innerUserPage__editBtn"
            >
                Редактировать
            </Button>
        </div>
    )
};

export default InnerUserViewData;
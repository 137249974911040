import React, {useState} from 'react';
import {ImageIcon, LoadIcon, RemoveAvatarIcon} from "@shared/img/iconSvg";
import {showModal} from "@actions/modalActions";
import {DETAIL_AVATAR_MODAL, EDIT_AVATAR_MODAL} from "@shared/components/modal/constants";
import {removeUserAvatar} from "@actions/userActions";
import {useDispatch, useSelector} from "react-redux";
import UserAvatar from "@shared/components/account/avatar";

const EditAvatar = () => {
    const dispatch = useDispatch();
    const {profile, avatarStatus} = useSelector(state => state.user);
    const [avatarServicesVisible, setAvatarServicesVisible] = useState(false);

    const changeAvatarServicesVisible = () => {
        setAvatarServicesVisible(!avatarServicesVisible)
    }

    const handleEditAvatar = () => {
        showModal(EDIT_AVATAR_MODAL)(dispatch)
        changeAvatarServicesVisible()
    }

    const handleRemoveAvatar = () => {
        removeUserAvatar()(dispatch)
        changeAvatarServicesVisible()
    }

    const handleOpenDetailAvatar = () => {
        showModal(DETAIL_AVATAR_MODAL)(dispatch)
        changeAvatarServicesVisible()
    }

    const avatarServicesRender = () => {
        return (
            <>
                <div className="userInfo__avatar_services">
                    <div className="userInfo__avatar_services_item userInfo__avatar_services_green"
                         onClick={handleEditAvatar}>
                        <div><LoadIcon/></div>
                        <span>Загрузить</span>
                    </div>
                    <div className="userInfo__avatar_services_item" onClick={handleOpenDetailAvatar}>
                        <div><ImageIcon/></div>
                        <span>Открыть</span>
                    </div>
                    <div className="userInfo__avatar_services_item" onClick={handleRemoveAvatar}>
                        <div><RemoveAvatarIcon/></div>
                        <span>Удалить</span>
                    </div>
                </div>
                <button type="button" className="topbar__back" onClick={changeAvatarServicesVisible}/>
            </>
        )
    }

    return (
        <div className="editAvatar">
            <div className={`userInfo__avatar ${avatarServicesVisible ? 'userInfo__avatar_active' : ''}`}>
                <div className="userInfo__avatar_image" onClick={changeAvatarServicesVisible}>
                    <UserAvatar id={profile.id} update={avatarStatus} hasAvatar={profile.avatar || null}/>
                </div>
                {avatarServicesVisible ? avatarServicesRender() : null}
            </div>
        </div>
    )
}

export default EditAvatar;
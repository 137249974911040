import React from 'react';
import DataCard from "@shared/components/data/DataCard";

const CompanyData = ({data, fRef}) => {

    const dataFormat = [
        {name: 'Наименование компании', desc: data?.name || ''},
        {name: 'ИНН', desc: data?.inn || ''},
        {name: 'ОГРН', desc: data?.ogrn || ''},
        {name: 'Юридический адрес', desc: data?.legal_address || ''},
        {name: 'Фактический адрес', desc: data?.actual_address || ''},
        {
            name: 'Устав',
            desc: data?.charters?.length ? data.charters : '',
            type: 'file'
        },
    ]

    return <DataCard data={dataFormat} title="Данные компании" fRef={fRef}/>
}

export default CompanyData;

import React, {useEffect, useReducer, useState} from 'react';
import EditAdditionalInfo from "./EditAdditionalInfo";
import {AddFaceBtn, reducer} from "./helpers";
import {QuestionnaireService} from "@services/questionnaire.service";
import {checkForAccreditation, getProfileData} from "@actions/profilesActions";
import {useDispatch} from "react-redux";
import {shortDate} from "@utils/date";

const EditAdditionalInfoGroup = ({
                                     data, className, saveFormData = false, saveFormError = () => {
    },
                                     onChangeData = () => {
                                     }, formTitle = false, resetValidate = false
                                 }) => {
    const dispatch = useDispatch();
    const [formError, setFormError] = useState([]) // Ошибочные поля
    const [personCount, setPersonCount] = useState(data.persons ? data.persons + 1 : 1) // Кол-во лиц
    const [formValidate, setFormValidate] = useState(false) // Валидность всех полей формы
    const values = data?.values || [];
    const {some} = data?.form || {};
    const initialState = {};
    let person = {};
    let personIdx = 0;

    values.forEach(item => {
        if (personIdx === item.person) {
            person[item.field.key] = {
                field_id: item.field_id,
                person: item.person,
                value: item.value,
                field: item.field,
                files: item.files ? item.files : null
            }
        } else {
            initialState[`person${personIdx}`] = {...person};
            person = {};
            person[item.field.key] = {
                field_id: item.field_id,
                person: item.person,
                value: item.value,
                field: item.field,
                files: item.files ? item.files : null
            }
            personIdx = item.person
        }
    });

    initialState[`person${personIdx}`] = {...person};

    const [formData, setFormData] = useReducer(reducer, initialState);

    const handleOnChange = (e, person) => {
        setFormData({
            type: 'edit',
            payload: {
                name: e.target.name,
                value: e.target.value,
                person: person
            }
        })
    }

    const handleAddFace = () => {
        const addFaceData = {};
        setPersonCount(personCount + 1)

        values.forEach(item => {
            addFaceData[item.field.key] = {
                field_id: item.field_id,
                person: personCount,
                value: item.field.default,
                field: item.field,
            }
        });

        setFormData({
            type: 'addFace',
            payload: {
                data: {...addFaceData},
                person: personCount
            }
        })
    }

    const handleSendForm = () => {
        let personNumber = 0;

        if (formValidate && formError.length === 0) {
            const values = [];

            // Подготовка данных для отправки на сервер
            for (let key in formData) {

                if ((formData[key].main && formData[key].main.value[0] !== 0) || personNumber === 0) {
                    personNumber++;

                    for (let idx in formData[key]) {
                        switch (formData[key][idx].field.type_key) {
                            case 'date':
                                if (formData[key][idx].value[0] !== '') {
                                    values.push({
                                        field_id: formData[key][idx].field_id,
                                        person: personNumber - 1,
                                        value: [shortDate(formData[key][idx].value[0])]
                                    })
                                }
                                break;
                            // Вместо объектов, для файлов отправляем id
                            case 'files':
                            case 'file':
                                values.push({
                                    field_id: formData[key][idx].field_id,
                                    person: personNumber - 1,
                                    value: formData[key][idx].value.map(item => item.id)
                                })
                                break;
                            default:
                                values.push({
                                    field_id: formData[key][idx].field_id,
                                    person: personNumber - 1,
                                    value: formData[key][idx].value
                                })
                                break;

                        }
                    }

                }

            }

            if (!saveFormData) {
                QuestionnaireService.update(data.id, {values})
                    .then(() => {
                        checkForAccreditation(dispatch)
                        getProfileData()(dispatch)
                    })
            } else {
                onChangeData({[data.id]: {values}})
            }
        }
    }

    const renderEditForm = () => {
        const formItems = [];
        let personNumber = 0;

        for (let key in formData) {
            ++personNumber
            formItems.push(<EditAdditionalInfo
                key={key}
                setFormValidate={setFormValidate}
                formError={formError}
                setFormError={setFormError}
                formDataItem={formData[key]}
                handleOnChange={handleOnChange}
                setFormData={setFormData}
                personNumber={personNumber}
                resetValidate={resetValidate}
            />);
        }

        return formItems;
    }

    useEffect(() => {
        if (saveFormData) {
            handleSendForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        if (saveFormData) {
            saveFormError({[data.id]: formError})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formError])

    return (
        <div className={className}>
            {
                formTitle
                    ? <div className="profileTabs__tabItem_title">
                        <span>{data.form.question}</span>
                    </div>
                    : null
            }
            {renderEditForm()}
            <AddFaceBtn
                some={some}
                formData={formData}
                handleAddFace={handleAddFace}
                disabled={!formValidate || !!formError.length}
            />
        </div>
    )
}

export default EditAdditionalInfoGroup;
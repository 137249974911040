import React from 'react';
import classnames from "classnames";
import {CloseIcon} from "@shared/img/iconSvg";

const NotificationsBlock = ({children, isOpen, setIsOpen}) => {
    const notificationsBlockClass = classnames({
        notificationsBlock: true,
        show: isOpen
    })

    return (
        <div className={notificationsBlockClass}>
            <div className="notificationsBlock__inner">
                <div className="notificationsBlock__close" onClick={() => setIsOpen(false)}>
                    <CloseIcon/>
                </div>
                {children}
            </div>
            {/*<button type="button" className="notificationsBlock__back" onClick={() => setIsOpen(false)} />*/}
        </div>
    )
}

export default NotificationsBlock;

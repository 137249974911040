import {AVATAR_STATUS, SET_USER_INFO} from "@actions/userActions";

const initialState = {
    profile: {},
    user: {
        roles: []
    },
    avatarStatus: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USER_INFO:
            return {
                ...state,
                profile: action.payload.profile.data,
                user: action.payload.user.data,
            };
        case AVATAR_STATUS:
            return {
                ...state,
                avatarStatus: !state.avatarStatus
            };
        default:
            return state;
    }
}

import {SET_ATOKEN_EXP_DATE, SET_IS_AUTH} from "@actions/authActions";
import {get} from "@utils/storage";

const initialState = {
    accessTokenExpDate: get("exp"),
    isAuth: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ATOKEN_EXP_DATE:
            return {...state, accessTokenExpDate: action.payload};
        case SET_IS_AUTH:
            return {...state, isAuth: action.payload};
        default:
            return state;
    }
}

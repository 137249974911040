export const mockEventsLog = [
    {id: 0, date: '12.08.2021', log: 'Вы зарезервировали инвестицию ***,** руб. '},
    {id: 1, date: '12.08.2021', log: 'Вы отменили резерв инвестиции ***,** руб.'},
    {id: 2, date: '12.08.2021', log: 'Проект перешел к стадии подписания документов.'},
    {id: 3, date: '12.08.2021', log: 'Поступление платежа *** руб'},
    {id: 4, date: '12.08.2021', log: 'Заемщик произвел частичное досрочное погашение займа'},
    {id: 5, date: '12.08.2021', log: 'Заемщик произвел полное досрочное погашение займа'},
    {id: 6, date: '12.08.2021', log: 'Заемщик произвел полное досрочное погашение займа'},
    {id: 7, date: '12.08.2021', log: 'Вы зарезервировали инвестицию ***,** руб. '}
]

export const loanOptionsRemarks = [
    {id: 0, message: 'В данных проекта указан неверный инн'},
    {id: 1, message: 'В данных проекта указан неверный БИК'},
    {id: 2, message: 'В анкете загружен некорректный док. с лицензией на осуществление деятельности'},
]

import React, {useState} from 'react';
import {Button} from "reactstrap";
import classNames from "classnames";
import {useHistory} from "react-router-dom";

const InvestmentCodeSmsModal = () => {
    const [code, setCode] = useState(false)
    const history = useHistory();

    const descButton = classNames({
        investmentCodeSmsModal__descButton: true,
        fontSizeText: true,
        textLine: !code,
    });

    const inputSmsDesc = classNames({
        investmentCodeSmsModal__inputSmsDesc: true,
        investmentCodeSmsModal__inputSmsDescNone: code,
    });

    const inputSms = classNames({
        investmentCodeSmsModal__inputSmsError: true,
        investmentCodeSmsModal__inputSms: code,
    });

    return (
        <div className='investmentCodeSmsModal'>
            <span className='investmentCodeSmsModal__title'>
                Для подтверждения введите одноразовый пароль,<br/>
                отправленный на номер <br/>
                +7 (***) ***-**-**
            </span>
            <input className={inputSms} type="text"/>
            <span className={inputSmsDesc}>Введите код из СМС</span>
            <Button
                className='investmentCodeSmsModal__button'
                color="primary"
                size="lg"
            >
                Подтвердить
            </Button>
            <Button
                className='loanOptionsEditButton__button'
                color="danger"
                size="sm"
                outline
                onClick={() => history.push("/project-detail/101010")}
            >
                Отменить
            </Button>
            <span className='investmentCodeSmsModal__desc fontSizeText'>Не получили код? Через *:**  вы можете</span>
            <span
                className={descButton}
                onClick={() => setCode(true)}
            >
                направить код повторно
            </span>
        </div>
    )
};

export default InvestmentCodeSmsModal;
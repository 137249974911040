export const SET_COMPANY = 'SET_COMPANY';
export const SET_ERROR = 'SET_ERROR';
export const SET_ADDITIONAL_INFO_ERROR = 'SET_ADDITIONAL_INFO_ERROR';
export const SET_RESET_VALIDATE = 'SET_RESET_VALIDATE';
export const SET_PERSONAL = 'SET_PERSONAL';
export const SET_PASSPORT = 'SET_PASSPORT';
export const ADDITIONAL_INFO_EDIT = 'ADDITIONAL_INFO_EDIT';
export const ADDITIONAL_INFO_EDIT_ADD_FILES = 'ADDITIONAL_INFO_EDIT_ADD_FILES';
export const ADDITIONAL_INFO_EDIT_DELETE_FILES = 'ADDITIONAL_INFO_EDIT_DELETE_FILES';
export const ADDITIONAL_INFO_EDIT_ADD_FACE = 'ADDITIONAL_INFO_EDIT_ADD_FACE';
export const ADDITIONAL_INFO_EDIT_DELETE_FACE = 'ADDITIONAL_INFO_EDIT_DELETE_FACE';
import React from 'react';
import {LeftArrowIcon} from "@shared/img/iconSvg";
import {useHistory} from "react-router-dom";

const BackButton = () => {
    const history = useHistory();
    return (
        <div
            className='platformServicesModal__feed'
            onClick={() => history.push("/project-detail/101010")}
        >
            <div className='platformServicesModal__feed__button'><LeftArrowIcon/></div>
            <span>Назад</span>
        </div>
    )
};

export default BackButton;
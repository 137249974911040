export const dataBorrowerExperience = [
    {
        id: '1234560',
        sum: '2 000 000',
        bid: '24',
        term: '12',
        dateOfIssue: '12.08.2021',
        returnDate: '12.08.2021',
        daysOverdue: '19',
        loanRepaid: false,
        history: [
            {
                id: 0,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 1,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 2,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
        ]
    },
    {
        id: '1234561',
        sum: '2 000 000',
        bid: '24',
        term: '12',
        dateOfIssue: '12.08.2021',
        returnDate: '12.08.2021',
        daysOverdue: '-',
        loanRepaid: true,
        history: [
            {
                id: 0,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 1,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 2,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
        ]
    },
    {
        id: '1234562',
        sum: '2 000 000',
        bid: '24',
        term: '12',
        dateOfIssue: '12.08.2021',
        returnDate: '12.08.2021',
        daysOverdue: '19',
        loanRepaid: false,
        history: [
            {
                id: 0,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 1,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 2,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
        ]
    },
    {
        id: '1234563',
        sum: '2 000 000',
        bid: '24',
        term: '12',
        dateOfIssue: '12.08.2021',
        returnDate: '12.08.2021',
        daysOverdue: '19',
        loanRepaid: false,
        history: [
            {
                id: 0,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 1,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 2,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
        ]
    }, {
        id: '1234565',
        sum: '2 000 000',
        bid: '24',
        term: '12',
        dateOfIssue: '12.08.2021',
        returnDate: '12.08.2021',
        daysOverdue: '19',
        loanRepaid: false,
        history: [
            {
                id: 0,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 1,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 2,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
        ]
    },
    {
        id: '1234566',
        sum: '2 000 000',
        bid: '24',
        term: '12',
        dateOfIssue: '12.08.2021',
        returnDate: '12.08.2021',
        daysOverdue: '19',
        loanRepaid: false,
        history: [
            {
                id: 0,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 1,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 2,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
        ]
    },
    {
        id: '1234567',
        sum: '2 000 000',
        bid: '24',
        term: '12',
        dateOfIssue: '12.08.2021',
        returnDate: '12.08.2021',
        daysOverdue: '19',
        loanRepaid: false,
        history: [
            {
                id: 0,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 1,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 2,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
        ]
    },
    {
        id: '1234568',
        sum: '2 000 000',
        bid: '24',
        term: '12',
        dateOfIssue: '12.08.2021',
        returnDate: '12.08.2021',
        daysOverdue: '19',
        loanRepaid: false,
        history: [
            {
                id: 0,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 1,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 2,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
        ]
    },
    {
        id: '1234569',
        sum: '2 000 000',
        bid: '24',
        term: '12',
        dateOfIssue: '12.08.2021',
        returnDate: '12.08.2021',
        daysOverdue: '19',
        loanRepaid: false,
        history: [
            {
                id: 0,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 1,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
            {
                id: 2,
                Payment: 1,
                principalAmount: '20 000',
                plannedMaturityDate: '17.08.21',
                actualMaturityDate: '17.08.21',
                periodClosed: 'Да',
                delay: '12'
            },
        ]
    },
]

import React from 'react';
import SearchIcon from "mdi-react/SearchIcon";
import InputText from "@shared/components/form/profileForm/InputText";
import {
    SET_CHECKBOX_VALUE,
    SET_DATE_VALUE,
    SET_SEARCH_VALUE
} from "@containers/Moderator/Registry/RegistryProfile/helpers";
import RegistryTableHeaderItem from "./RegistryTableHeaderItem";
import {CalendarIcon} from "@shared/img/iconSvg";
import RegistryTableFilterSorted from "./RegistryTableFilterSorted";

const RegistryTableHeader = ({filterState, filterDispatch, data = [], orderBy, sortedBy}) => {
    const setSearchValue = e => {
        filterDispatch({
            type: SET_SEARCH_VALUE,
            payload: {
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const setCheckboxValue = (name, value, filterData = []) => {
        if (value === 'all') {
            filterDispatch({
                type: SET_SEARCH_VALUE,
                payload: {
                    name,
                    value: filterState[name].length === filterData.length - 1
                        ? []
                        : filterData.filter(item => item.name !== 'all').map(item => item.name)
                }
            })
        } else {
            filterDispatch({
                type: SET_CHECKBOX_VALUE,
                payload: {name, value}
            })
        }
    }

    const setFilterDate = (e, key) => {
        filterDispatch({
            type: SET_DATE_VALUE,
            payload: {
                key,
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const registryTableFilter = (item) => {
        const {filterType, filterData = [], filterPlaceholder, title, name, notSort} = item;

        switch (filterType) {
            case 'searchField':
                return <InputText
                    type="text"
                    name={name}
                    value={filterState[name]}
                    onChange={setSearchValue}
                    placeholder={title}
                    inputWithDesc
                    inputWithDescValue={<SearchIcon/>}
                    containerClassName="addProject__fullInput searchInputField"
                />
            case 'checkbox':
                return filterData.map((elem, idx) => (
                    <InputText
                        key={idx}
                        type="checkbox"
                        name={elem.name}
                        label={elem.label}
                        onChange={() => setCheckboxValue(name, elem.name, filterData)}
                        value={
                            elem.name === 'all'
                                ? (filterState[name] && filterState[name].length === filterData.length - 1) || false
                                : (filterState[name] && filterState[name].includes(elem.name)) || false}
                        containerClassName="profileTabs__edit_item profileTabs__edit_check"
                    />
                ))
            case 'date':
                return <div className="registryTable__filterDate_group">
                    <div className="registryTable__filterDate">
                        <InputText
                            type="date"
                            name="from"
                            onChange={e => setFilterDate(e, name)}
                            inputWithDesc
                            noLabel={true}
                            maxDate={new Date()}
                            value={filterState[name] ? filterState[name][0] : ''}
                            inputWithDescValue={<CalendarIcon/>}
                            containerClassName="addProject__middleInput addProject__dateInput date-picker"
                        />
                        <span className="registryTable__filterDate_separator"/>
                        <InputText
                            type="date"
                            name="to"
                            onChange={e => setFilterDate(e, name)}
                            inputWithDesc
                            noLabel={true}
                            maxDate={new Date()}
                            value={filterState[name] ? filterState[name][1] : ''}
                            inputWithDescValue={<CalendarIcon/>}
                            containerClassName="addProject__middleInput addProject__dateInput date-picker"
                        />
                    </div>
                    <RegistryTableFilterSorted
                        name={name}
                        orderBy={orderBy}
                        sortedBy={sortedBy}
                        filterDispatch={filterDispatch}
                    />
                </div>
            case 'interval':
                return <div className="registryTable__filterDate_group">
                    <div className="registryTable__filterDate">
                        <InputText
                            type="text"
                            name="from"
                            onChange={e => setFilterDate({
                                    target: {name: e.target.name, value: +(e.target.value.replace(/\D/g, ''))}
                                },
                                name
                            )}
                            inputWithDesc
                            noLabel={true}
                            value={filterState[name] ? filterState[name][0] : ''}
                            inputWithDescValue={filterPlaceholder || ''}
                            placeholder="от"
                            containerClassName="addProject__fullInput registryIntervalField"
                            rank={true}
                        />
                        <span className="registryTable__filterDate_separator"/>
                        <InputText
                            type="text"
                            name="to"
                            onChange={e => setFilterDate({
                                    target: {name: e.target.name, value: +(e.target.value.replace(/\D/g, ''))}
                                },
                                name
                            )}
                            inputWithDesc
                            noLabel={true}
                            value={filterState[name] ? filterState[name][1] : ''}
                            inputWithDescValue={filterPlaceholder || ''}
                            placeholder="до"
                            containerClassName="addProject__fullInput registryIntervalField"
                            rank={true}
                        />
                    </div>
                    {
                        !notSort
                            ? <RegistryTableFilterSorted
                                name={name}
                                orderBy={orderBy}
                                sortedBy={sortedBy}
                                filterDispatch={filterDispatch}
                            />
                            : null
                    }
                </div>
            default:
                return null
        }
    }

    return (
        <thead>
        <tr>
            {data.map(item => <RegistryTableHeaderItem
                key={item.name}
                item={item}
                registryTableFilter={registryTableFilter}
            />)}
        </tr>
        </thead>
    )
}

export default RegistryTableHeader;

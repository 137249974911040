import React, {useEffect, useState} from 'react';
import {getRank, handleValidate} from '@utils/form';
import MaskedInput from "react-text-mask";
import CustomCheckBox from '@shared/components/form/profileForm/CustomCheckBox';
import CustomDatePicker from "@shared/components/form/profileForm/CustomDatePicker"
import CustomDropzone from "@shared/components/form/profileForm/CustomDropzone";
import {dateNewDate} from '@utils/date';
import PrimaryTooltip from "@shared/components/tooltip";
import classnames from "classnames";

const InputText = ({
                       label = '',
                       type = 'text',
                       name = '',
                       placeholder = '',
                       id = null,
                       resetValidate = false,
                       onChange,
                       value,
                       validator = [],
                       placeholderText = '',
                       disabled = false,
                       icon = null,
                       setResetValidate = () => {
                       },
                       mask = [],
                       className = '',
                       containerClassName = '',
                       dateFormat = "dd.MM.yyyy",
                       maxDate = null,
                       minDate = null,
                       formError = [],
                       setFormError,
                       guide = true,
                       person = null,
                       labelClass = '',
                       rows = 5,
                       inputWrap = false,
                       inputWithDesc,
                       inputWithDescLeft,
                       inputWithDescValue = '',
                       validatorMessages = {},
                       tooltip,
                       tooltipClass,
                       noLabel = false,
                       inputLabelLeft = false,
                       inputStyle = {},
                       rank = false,
                       noErrorMessage,
                       customErrorMessage = null,
                       setCustomErrorMessage = () => {
                       },
                       checkAfterFocus = true,
                       onBlur = () => {
                       }
                   }) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const [errorType, setErrorType] = useState(null)

    const inputWithDescClasses = classnames({
        'inputWithDesc': true,
        'fieldDesc__error': errorMessage,
        'inputWithDescLeft': inputWithDescLeft
    })

    const wrapperClass = classnames({
        'inputText__wrapper': true,
        'inputText__error': errorMessage,
        'fieldDesc__inputLabelLeft': inputLabelLeft,
        [containerClassName]: containerClassName,
    })

    // Валидация поля
    const validate = inputValue => {
        if (validator.length) {
            validator.forEach(rule => {
                handleValidate(inputValue, rule, errorMessage, setErrorMessage, errorType, setErrorType, validatorMessages, rank)
            })
        } else if (errorMessage) {
            setErrorMessage(null)
        }
    }

    const handleOnChange = e => {
        person !== null ? onChange(e, person) : onChange(e)
        if (!checkAfterFocus || errorMessage) {
            validate(e.target.value)
        }
    }

    const handleBlur = e => {
        validate(value)
        onBlur(e)
    }

    useEffect(() => {
        validate(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetValidate])


    useEffect(() => {
        if (!checkAfterFocus) {
            setTimeout(() => {
                validate(value)
            }, 100)
        }
    }, [])

    useEffect(() => {
        if (setFormError) {
            const index = formError.indexOf(name);
            if (errorMessage) {
                if (index === -1) {
                    const newFormError = [...formError];
                    newFormError.push(name)
                    setFormError(newFormError)
                }
            } else {
                if (index !== -1) {
                    const newFormError = [...formError];
                    newFormError.splice(index, 1)
                    setFormError(newFormError)
                }
            }
        }

    }, [errorMessage, customErrorMessage, formError])

    useEffect(() => {
        if (customErrorMessage) {
            setErrorMessage(customErrorMessage)
            setTimeout(() => {
                setCustomErrorMessage(null)
                setErrorMessage(null)
            }, 5000)
        } else {
            setErrorMessage(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customErrorMessage])

    const renderInput = () => {
        switch (type) {
            case 'text':
            case 'email':
            case 'password':
                return <input
                    id={id}
                    type={type}
                    name={name}
                    value={rank && value ? getRank(value) : value}
                    autoComplete={type === 'password' ? 'new-password' : 'none'}
                    disabled={disabled}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    onChange={onChange ? handleOnChange : null}
                    className={`inputText ${className}`}
                    style={inputStyle}
                />
            case 'textarea':
                return <textarea
                    id={id}
                    rows={rows}
                    name={name}
                    value={value}
                    autoComplete="off"
                    disabled={disabled}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    onChange={handleOnChange}
                    className={`inputText inputText__textarea ${className}`}
                />
            case 'mask':
                return <MaskedInput
                    id={id}
                    mask={mask}
                    type="text"
                    name={name}
                    value={value}
                    autoComplete="off"
                    disabled={disabled}
                    onBlur={handleBlur}
                    onChange={handleOnChange}
                    placeholder={placeholder}
                    className={`inputText ${className}`}
                    guide={guide}
                />
            case 'date':
                return <CustomDatePicker
                    id={id}
                    name={name}
                    value={value ? dateNewDate(value) : null}
                    maxDate={maxDate}
                    minDate={minDate}
                    disabled={disabled}
                    onBlur={handleBlur}
                    dateFormat={dateFormat}
                    onChange={onChange ? handleOnChange : null}
                    placeholderText={placeholderText}
                    className={`inputText ${className}`}
                />
            case 'checkbox':
                return <CustomCheckBox
                    id={id}
                    type={type}
                    name={name}
                    label={label}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    className={className}
                />
            case 'dropzone':
                return <CustomDropzone
                    name={name}
                    value={value}
                    onChange={handleOnChange}
                />
            default:
                break;
        }
    }

    const renderInputWrapper = () => {
        if (inputWrap) return <div className="inputText__wrap">{renderInput()}</div>

        if (icon) return (
            <div className="inputText__icon_group">
                {renderInput()}
                {icon(errorMessage)}
            </div>
        )

        if (inputWithDesc) {
            return (
                <div className={inputWithDescClasses}>
                    {
                        inputWithDescLeft
                            ? <>
                                <span>{inputWithDescValue}</span>
                                {renderInput()}
                            </>
                            : <>
                                {renderInput()}
                                <span>{inputWithDescValue}</span>
                            </>
                    }
                </div>
            )
        }

        return renderInput()
    }

    return (
        <div className={wrapperClass}>
            {type === 'checkbox' || noLabel
                ? ''
                : <label htmlFor={id} className={labelClass}>
                <span className={tooltip ? 'inputText__tooltipGroup' : ''}>
                  {label}
                    {
                        tooltip
                            ? <PrimaryTooltip
                                title="?"
                                name={name}
                                className={`${tooltipClass ? tooltipClass : ''} profileTabs__tabItem_tooltip`}
                            >
                                {tooltip}
                            </PrimaryTooltip>
                            : ""
                    }
                </span>
                </label>
            }
            <div className="inputErrorGroup">
                {renderInputWrapper()}
                {!noErrorMessage && errorMessage && <span className="form__form-error">{errorMessage}</span>}
            </div>
        </div>
    )
}

export default InputText;

import React from 'react';
import Dropzone from 'react-dropzone';
import uploadIcon from "@shared/img/icons/upload.svg";

const CustomDropzone = ({value, name, onChange, inputKey}) => {
    const files = value;

    const handleOnChange = value => {
        onChange({target: {name: name, value: value}})
    }
    const onDrop = (file) => {
        handleOnChange(file.map(fl => Object.assign(fl, {preview: URL.createObjectURL(fl),})));
    };
    const removeFile = (index, e) => {
        e.preventDefault();
        handleOnChange(value.filter((val, i) => i !== index));
    };

    return (
        <div className="dropzone dropzone--single" key={inputKey}>
            <Dropzone
                accept="image/jpeg, image/png"
                name={name}
                multiple={false}
                onDrop={(fileToUpload) => {
                    onDrop(fileToUpload);
                }}
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} className="dropzone__input">
                        {(!files || files.length === 0)
                            && (
                                <div className="dropzone__drop-here">
                                    <img src={uploadIcon} alt="upload"/>
                                    <span>Перетащите файл для загрузки сюда</span>
                                </div>
                            )}
                        <input {...getInputProps()} />
                    </div>
                )}
            </Dropzone>
            {files && Array.isArray(files) && files.length > 0
                && (
                    <aside className="dropzone__img">
                        <img src={files[0].preview} alt="drop-img"/>
                        <p className="dropzone__img-name">{files[0].name}</p>
                        <button className="dropzone__img-delete" type="button" onClick={e => removeFile(0, e)}>
                            Удалить
                        </button>
                    </aside>
                )}
        </div>
    );
}

export default CustomDropzone;
import React, {useState} from 'react';
import PrimaryTooltip from "@shared/components/tooltip";
import RadioButtonField from "@shared/components/form/RadioButton";
import {showModal} from "@actions/modalActions";
import {ABOUT_TARIFFS} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";

const PlatformServices = ({data, fRef = null, pledges = []}) => {
    const rate = data?.project?.rate || {};
    const projectPledges = data?.project?.pledges || {};
    //const pledgeTariff = 3000;
    const [paymentType, setPaymentType] = useState(0);
    const dispatch = useDispatch();

    const handleOnChangeRadio = value => {
        setPaymentType(value)
    }

    return (
        <div className="platformServices" ref={fRef}>
            <div className="platformServices__titleGroup">
                <div className="platformServices__title">
            <span>
              Услуги платформы
              <PrimaryTooltip
                  title="?"
                  name="platformServicesTitleTooltip"
                  className="smallTooltip profileTabs__tabItem_tooltip"
              >
                Оплачиваются только если проект соберет минимальную сумму инвестиций.
              </PrimaryTooltip>
            </span>
                </div>
                <button
                    className="simpleGreenBtn"
                    onClick={() => showModal(ABOUT_TARIFFS)(dispatch)}
                >
                    Подробнее о тарифах
                </button>
            </div>
            <div className="platformServices__content">
                <div className="platformServices__item">
            <span className="platformServices__item_title">
              Услуги по предоставлению доступа к сервисам инвестиционной платформы INCROWD и привлечению инвестиций
            </span>
                    <div className="platformServices__item_elems">
                        <div className="platformServices__item_elem">
                            <span>Тариф:</span> {rate?.tariff ? rate.tariff : '20'} % от суммы займа
                        </div>
                        <div className="platformServices__item_elem">
                            <span>Сумма вознаграждения:</span> 50 000 ₽
                        </div>
                    </div>
                </div>
                {
                    pledges.length
                        ? <div className="platformServices__item">
                            <span className="platformServices__item_title">
                                Услуги платформы INCROWD по сопровождению государственной регистрации залога движимого/недвижимого имущества
                            </span>
                            <div className="platformServices__item_elems">
                                <div className="platformServices__item_elem">
                                    <span>Тариф:</span> {rate?.support ? rate.support : '3000'} ₽ / 1 объект залога
                                </div>
                                <div className="platformServices__item_elem">
                                    <span>Объектов залога:</span> {projectPledges ? projectPledges.length : pledges.length}
                                </div>
                                <div className="platformServices__item_elem">
                                    <span>Сумма вознаграждения: </span>
                                    {(projectPledges ? projectPledges.length : pledges.length) * +(rate?.support ? rate.support : 3000)} ₽
                                </div>
                            </div>
                        </div>
                        : ''
                }
            </div>
            <div className="platformServices__paymentType">
                <span className="platformServices__paymentType_title">Тип оплаты вознаграждения оператора:</span>
                <div className="platformServices__paymentType_content">
                    <div className="paymentType__radioGroup">
                        <RadioButtonField
                            key="withholdingAmount"
                            name="withholdingAmount"
                            label="Удержание из суммы займа"
                            radioValue={0}
                            value={paymentType}
                            onChange={handleOnChangeRadio}
                        />
                        <RadioButtonField
                            key="paymentInvoice"
                            name="paymentInvoice"
                            label="Оплата счета"
                            radioValue={1}
                            value={paymentType}
                            onChange={handleOnChangeRadio}
                            tooltip="Вам будет необходимо оплатить счет в течение 2 раб. дней с даты прекращения инвестиционногопредложения"
                            tooltipClass="smallTooltip"
                        />
                    </div>
                </div>
            </div>
            <div className="platformServices__amount_pay">
                <p>Сумма к оплате: <span>62 000 ₽</span></p>
            </div>
        </div>
    )
}

export default PlatformServices;

import React, {useEffect, useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {CalendarIcon, SearchIcon} from "@shared/img/iconSvg";
import {Button} from "reactstrap";
import {getListForAccreditation} from "@actions/accreditationAction";
import AccreditationUserListTable from "./AccreditationUserListTable";
import Pagination from "@shared/components/pagination";
import classnames from "classnames";

const AccreditationUserList = React.memo(({accType}) => {
    const [accFilterDateStart, setAccFilterDateStart] = useState('');
    const [accFilterDateEnd, setAccFilterDateEnd] = useState('');
    const [searchText, setSearchText] = useState('');
    const [sortedBy, setSortedBy] = useState(false);
    const [userListData, setUserListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const resetFilterData = () => {
        setAccFilterDateStart('');
        setAccFilterDateEnd('');
        setSearchText('');
        setCurrentPage(1);
        setSortedBy(false);
    }

    const getUserList = (clearFilter = false) => {
        if (clearFilter) resetFilterData();

        setLoading(true);
        getListForAccreditation(accType, accFilterDateStart, accFilterDateEnd, sortedBy, searchText, currentPage, clearFilter)
            .then(res => setUserListData(res?.data || []))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        let unmounted = false;

        setLoading(true);
        getListForAccreditation(accType, accFilterDateStart, accFilterDateEnd, sortedBy, searchText, currentPage)
            .then(res => !unmounted && setUserListData(res?.data || []))
            .finally(() => !unmounted && setLoading(false))

        return () => unmounted = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accType, sortedBy, currentPage])

    useEffect(() => {
        resetFilterData()
    }, [accType])

    return <div className="accreditationList">
        <div className="accreditationList__inner">
            <p className="accreditationList__filter_title">
                {accType === 'qualificationsInvestor' ? 'Дата подачи заявления' : 'Дата отправки на аккредитацию'}
            </p>
            <div className="accreditationList__filter">
                <div className="accreditationList__filter_inputGroup">
                    <div className="accreditationList__filter_dateGroup">
                        <InputText
                            type="date"
                            name="accreditationFilterDateStart"
                            id="accreditationFilterDateStart"
                            onChange={({target}) => setAccFilterDateStart(target.value)}
                            inputWithDesc
                            noLabel={true}
                            maxDate={new Date()}
                            value={accFilterDateStart || ''}
                            inputWithDescValue={<CalendarIcon/>}
                            containerClassName="addProject__middleInput addProject__dateInput date-picker"
                        />
                        <span className="accreditationList__filter_separator"/>
                        <InputText
                            type="date"
                            name="accreditationFilterDateEnd"
                            id="accreditationFilterDateEnd"
                            onChange={({target}) => setAccFilterDateEnd(target.value)}
                            inputWithDesc
                            noLabel={true}
                            maxDate={new Date()}
                            value={accFilterDateEnd || ''}
                            inputWithDescValue={<CalendarIcon/>}
                            containerClassName="addProject__middleInput addProject__dateInput date-picker"
                        />
                    </div>
                    <div className="accreditationList__filter_searchInput">
                        <InputText
                            type="text"
                            name="accreditationFilterSearchText"
                            id="accreditationFilterSearchText"
                            value={searchText}
                            onChange={({target}) => setSearchText(target.value)}
                            placeholder="ФИО/Наименование, Правовая форма, ID профиля, E-mail"
                            inputWithDesc
                            inputWithDescValue={<SearchIcon/>}
                            containerClassName="addProject__fullInput"
                        />
                    </div>
                </div>
                <div className="accreditationList__filter_controls">
                    <Button
                        color="primary"
                        className={classnames('account__btn_primary addProject__btn', {disabled: loading})}
                        onClick={getUserList}
                    >
                        Применить
                    </Button>
                    <Button
                        color="success"
                        className={classnames('addProject__btn account__btn account__btn_cancel', {disabled: loading})}
                        onClick={() => getUserList(true)}
                        outline
                    >
                        Сбросить
                    </Button>
                </div>
            </div>
            <AccreditationUserListTable
                sortedBy={sortedBy}
                setSortedBy={setSortedBy}
                userList={userListData.data}
                loading={loading}
                accType={accType}
            />
            <Pagination
                className="pagination-bar"
                meta={userListData.meta}
                onPageChange={page => setCurrentPage(page)}
            />
        </div>
    </div>
})

export default AccreditationUserList;

import {AuthService} from "@services/auth.service";
import store from '@redux/storeConfig/store';
import {accStatusKey} from "@utils/statusKey";

export const isAuth = () => {
    return AuthService.checkTokenAndExp()
}

export const getRoles = () => {
    const state = store.getState();

    return state?.user?.user?.roles
        ? state.user.user.roles.map(role => role.name)
        : []
}

export const getRolesWithInfo = () => {
    const state = store.getState();

    return state?.user?.user?.roles
        ? state.user.user.roles
        : []
}

export const getRole = () => {
    const roles = getRoles()
    return roles.length ? roles[0] : null
}

export const getRoleWithInfo = () => {
    const roles = getRolesWithInfo()
    return roles.length ? roles[0] : {}
}

export const RoleHasPermissions = (permissions) => {
    const intersect = (arr1, arr2) => {
        return arr1.filter(n => arr2.indexOf(n) !== -1);
    };

    return intersect(getRoles(), permissions).length > 0
}

export const getLegalFormType = () => {
    const state = store.getState();

    return state.profiles?.profileData?.legal_form_type;
}

export const roleDesc = {
    'investor': 'Инвестор',
    'borrower': 'Заемщик',
    'admin': 'Администратор',
    'moderator': 'Модератор',
    'analytic': 'Аналитик',
    'sdl': 'СДЛ',
    'indiv': 'Физическое лицо',
    'entity': 'Юридическое лицо',
    'ie': 'ИП',
}

export const legalFormTypeDesc = {
    'indiv': 'Физ. лицо',
    'entity': 'Юр. лицо',
    'ie': 'ИП',
}

export const accreditedStatusDesc = {
    [accStatusKey.accredited]: 'Аккредитован',
    [accStatusKey.notAccredited]: 'Не аккредитован',
    [accStatusKey.checking]: 'На проверке',
    [accStatusKey.returned]: 'На доработке',
    [accStatusKey.rejected]: 'Отклонен'
}

export const qualifiedStatusDesc = {
    qualification_qualified: 'Квалификация подтверждена',
    qualification_checking: 'На проверке',
    qualification_not_qualified: 'Квалификация не подтверждена',
    qualification_rejected: 'Отклонен'
}

export const qualifiedStatusKey = {
    qualified: 'qualification_qualified',
    checking: 'qualification_checking',
    notQualified: 'qualification_not_qualified',
    rejected: 'qualification_rejected',
}

import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import AppRouter from '@router/AppRouter';
import ScrollToTop from '@app/ScrollToTop';
import AppLoading from "@containers/App/AppLoading";
import 'bootstrap/dist/css/bootstrap.css';
import '@scss/app.scss';

const App = () => (
    <BrowserRouter>
        <ScrollToTop>
            <>
                <AppLoading/>
                <AppRouter/>
            </>
        </ScrollToTop>
    </BrowserRouter>
)

export default App;

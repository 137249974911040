import React from "react";
import {AddIcon, CalendarIcon, CloseIcon} from "@shared/img/iconSvg";
import UploadField from "@shared/components/uploadField";
import InputText from "@shared/components/form/profileForm/InputText";
import {eighteenYearsAgo, innMask, serialNumber} from "@shared/components/account/helpers";

// Проверка зависимостей
export const checkDependencies = (formData, depends_key, depends_value, item = null) => {
    let dependsStatus = true;
    let dependsParent = null;

    for (let i = 0; i < depends_key.length; i++) {
        for (let key in formData) {
            if (!!item) {
                if ((formData[key].person === item.person) && (formData[key].field.key === depends_key[i])) {
                    dependsParent = formData[key];
                    break;
                }
            } else {
                if (formData[key].field.key === depends_key[i]) {
                    dependsParent = formData[key];
                    break;
                }
            }
        }

        if (!(dependsParent && depends_value[i].includes('' + dependsParent.value[0]))) dependsStatus = false
    }

    return dependsStatus
}

export const reducer = (state, action) => {
    const person = `person${action.payload.person}`;

    switch (action.type) {
        case 'edit':
            return {
                ...state,
                [person]: {
                    ...state[person],
                    [action.payload.name]: {
                        ...state[person][action.payload.name],
                        value: [action.payload.value]
                    }
                }
            }
        case 'addFiles':
            return {
                ...state,
                [person]: {
                    ...state[person],
                    [action.payload.name]: {
                        ...state[person][action.payload.name],
                        value: [...action.payload.value]
                    }
                }
            }
        case 'deleteFiles':
            return {
                ...state,
                [person]: {
                    ...state[person],
                    [action.payload.name]: {
                        ...state[person][action.payload.name],
                        value: [...action.payload.value.filesId],
                        files: [...action.payload.value.filesNew]
                    }
                }
            }
        case 'addFace':
            return {
                ...state,
                [person]: {
                    ...action.payload.data
                }
            }
        case 'deleteFace':
            const stateCopy = {...state};
            delete stateCopy[`person${action.payload}`];

            return {
                ...stateCopy
            }
        default:
            return {
                ...state
            }
    }
}

export const renderFormElem = (
    elem, formError, handleOnChange, setFormError, formDataItem, setFormData, resetValidate
) => {
    const {field, person, files} = elem;
    const {type_key, key, label, options, placeholder} = field || {};

    const renderSelectOptions = () => {
        return options.map(item => <button
                key={`${key}${item.value}`}
                className={`from__custom-radio-btn ${formDataItem[key].value[0] === item.value && 'active'}`}
                onClick={() => setFormData({type: 'edit', payload: {name: key, value: item.value, person: person}})}
            >
                <span>{item.label}</span>
            </button>
        )
    }

    const renderMainSelect = () => {
        return (
            <div key={key} className="formEdit__mainSelect">
                <div className="formEdit__selectGroup">
                    {renderSelectOptions()}
                </div>
                {
                    person > 0
                        ? <div className="formEdit__mainSelect_delete" onClick={() => deletePerson(person)}>
                            <CloseIcon/>
                        </div>
                        : ''
                }
            </div>
        )
    }

    const deletePerson = person => {
        setFormData({type: 'deleteFace', payload: person})
    }

    const fileOnChange = (data, person) => {
        setFormData({
            type: 'addFiles',
            payload: {
                person,
                ...data.target
            }
        })
    }

    switch (type_key) {
        case 'string':
        case 'number':
        case 'fio':
        case 'address':
            return (
                <InputText
                    key={key}
                    name={key}
                    type='text'
                    label={label}
                    person={person}
                    formError={formError}
                    onChange={handleOnChange}
                    setFormError={setFormError}
                    value={formDataItem[key].value[0] || ""}
                    placeholder={placeholder || ''}
                    containerClassName="profileTabs__edit_item"
                    validator={[{required: true}, {maxLength: 200}]}
                    resetValidate={resetValidate}
                    checkAfterFocus={false}
                />
            )
        case 'inn':
            return (
                <InputText
                    key={key}
                    label={label}
                    name={key}
                    person={person}
                    formError={formError}
                    onChange={handleOnChange}
                    setFormError={setFormError}
                    placeholder={placeholder || ''}
                    value={formDataItem[key].value[0] || ""}
                    containerClassName="profileTabs__edit_item"
                    validator={[{required: true}, {numbersCount: 12}]}
                    type='mask'
                    mask={innMask}
                    resetValidate={resetValidate}
                    checkAfterFocus={false}
                />
            )
        case 'serial_number':
            return (
                <InputText
                    key={key}
                    label={label}
                    name={key}
                    person={person}
                    formError={formError}
                    onChange={handleOnChange}
                    setFormError={setFormError}
                    placeholder={placeholder || ''}
                    value={formDataItem[key].value[0] || ""}
                    containerClassName="profileTabs__edit_item"
                    validator={[{required: true}, {numbersCount: 10}]}
                    type='mask'
                    mask={serialNumber}
                    resetValidate={resetValidate}
                    checkAfterFocus={false}
                />
            )
        case 'date':
            return (
                <InputText
                    key={key}
                    type="date"
                    name={key}
                    person={person}
                    icon={CalendarIcon}
                    maxDate={eighteenYearsAgo}
                    label={label}
                    onChange={handleOnChange}
                    value={formDataItem[key].value[0] || ""}
                    validator={[{required: true}]}
                    containerClassName="date-picker profileTabs__edit_item"
                    formError={formError}
                    setFormError={setFormError}
                    resetValidate={resetValidate}
                    checkAfterFocus={false}
                />
            )
        case 'file':
        case 'files':
            return (
                <div className="inputText__wrapper profileTabs__edit_item" key={key}>
                    <label>{label}</label>
                    <UploadField
                        multiple={type_key === 'files'}
                        files={files || []}
                        edit={true}
                        title={label}
                        onChange={data => fileOnChange(data, person)}
                        fileKey={key}
                        fileType="questionnaires"
                        required
                        formError={formError}
                        setFormError={setFormError}
                        resetValidate={resetValidate}
                        checkAfterFocus={false}
                    />
                </div>
            )
        case 'select':
            return key === 'main'
                ? options.length
                    ? renderMainSelect()
                    : ''
                : options.length
                    ? <div key={key} className="inputText__wrapper profileTabs__edit_item">
                        <label>{label}</label>
                        <div className="formEdit__selectGroup">
                            {renderSelectOptions()}
                        </div>
                    </div>
                    : ''
        case 'text':
            return (
                <InputText
                    key={key}
                    name={key}
                    type='textarea'
                    label={label}
                    person={person}
                    onChange={handleOnChange}
                    value={formDataItem[key].value[0] || ""}
                    placeholder={placeholder || ''}
                    containerClassName="profileTabs__edit_item"
                    validator={[{required: true}, {maxLength: 200}]}
                    formError={formError}
                    setFormError={setFormError}
                    resetValidate={resetValidate}
                    checkAfterFocus={false}
                />
            )
        default:
            break;
    }
}

export const AddFaceBtn = ({some, handleAddFace, formData, disabled}) => {
    let mainValue = null, mainDefaultValue = null;
    const formDataArr = Object.values(formData) || [];

    if (formDataArr.length && formDataArr[formDataArr.length - 1].main) {
        mainValue = formDataArr[formDataArr.length - 1].main.value[0];
        mainDefaultValue = formDataArr[formDataArr.length - 1].main.field.default[0];
    }

    if (some) {
        if (mainValue !== null && mainDefaultValue !== null) {
            if (mainValue !== mainDefaultValue) {
                return (
                    <button className="profileTabs__edit_add" onClick={handleAddFace} disabled={disabled}>
                        <div className="uploadField__download">
                            <AddIcon/>
                        </div>
                        <span>Добавить лицо</span>
                    </button>
                )
            }
        } else {
            return (
                <div className="profileTabs__edit_add" onClick={handleAddFace}>
                    <div className="uploadField__download">
                        <AddIcon/>
                    </div>
                    <span>Добавить лицо</span>
                </div>
            )
        }
    }
    return null
}

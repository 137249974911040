import React from 'react';
import {renderSwiperSlide, renderTabLink} from "@account/Profile/helpers";
import useWindowSize from "@hooks/useWindowSize";
import {useSelector} from "react-redux";
import {accStatusKey} from "@utils/statusKey";

const ProfileTabsLinks = ({activeTab, setActiveTab}) => {
    const [width = 541] = useWindowSize();
    const {role_key} = useSelector(state => state.user.profile)
    const {checkAccreditation} = useSelector(state => state.profiles);
    const {accreditation_status_key} = useSelector(state => state.profiles?.profileData || {});

    const accreditationNewStatus = [accStatusKey.returned, accStatusKey.notAccredited].includes(accreditation_status_key)
        ? checkAccreditation
        : true

    return <div className="profileTabs__tabs">
        {
            width <= 540
                ? renderSwiperSlide(role_key, activeTab, setActiveTab, accreditationNewStatus)
                : renderTabLink(role_key, activeTab, setActiveTab, accreditationNewStatus)
        }
    </div>
}

export default ProfileTabsLinks;
import React from 'react';
import {Button} from "reactstrap";

const LoanOptionsEditButton = () => {
    const LoanEditBtn = () => {
        console.log("LoanOptionsEditButton")
    }

    return (
        <div className='loanOptionsEditButton'>
            <Button
                color="primary"
                size="lg"
                onClick={LoanEditBtn}
            >
                Редактировать
            </Button>
        </div>
    )
};

export default LoanOptionsEditButton;
import {onlyCyrillic} from "@shared/components/form/validate";
import {stCount} from "@utils/form";

const checkSendData = state => {
    const data = {...state.data};

    data.legal_form_type === 'entity'
        ? delete data.fio
        : delete data.company

    const allElementsValid = Object.keys(data).filter(item => item !== 'without_patronymic').every(item => data[item])

    return allElementsValid ? data : null
}


const checkAccountNameValidate = data => {
    const {legal_form_type, fio, company, without_patronymic} = data;
    const fioWordCount = without_patronymic ? 2 : 3;

    if (legal_form_type === 'entity') return !onlyCyrillic.test(company)

    return !onlyCyrillic.test(fio) && stCount(fio) === fioWordCount
}

export {
    checkSendData,
    checkAccountNameValidate
}



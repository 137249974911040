export const innMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const innMaskEntity = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const ogrnipMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const ogrnMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const serialNumber = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const phoneMask = ['+', '7', '(', /[489]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
export const phoneCode = [/\d/, /\d/, /\d/, /\d/];
export const snilsMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
export const eighteenYearsAgo = new Date().setFullYear(new Date().getFullYear() - 19);
export const dateOptions = {year: 'numeric', month: 'numeric', day: 'numeric', timezone: 'UTC'};

export const reducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value
    }
}
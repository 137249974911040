import classnames from "classnames";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ProjectsFeedHeader from './ProjectsFeedHeader';
import BtnShowMore from "@containers/Projects/ProjectsFeed/BtnShowMore";
import {Row, Spinner, TabContent, TabPane} from "reactstrap";
import {getFullProjectList, getProjectsList, SET_PROJECT_LIST} from "@redux/actions/projectsActions";
import ProjectsFeedList from './ProjectsFeedList';
import {getRole} from "@utils/auth";

const ProjectsFeed = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [profileProjectList, setProfileProjectList] = useState([]);
    const role_key = getRole();
    const profileId = useSelector(state => state?.user?.profile?.id ? state.user.profile.id : null);
    const {projectList} = useSelector(state => state.projects);
    const meta = projectList.meta;

    const tabsToggle = id => {
        if (activeTab !== id) {
            setActiveTab(id);

            //Получить список проектов
            setLoading(true)
            getFullProjectList(id === 0 ? 'collecting' : 'completed')(dispatch)
                .finally(() => setLoading(false))
        }
    }

    useEffect(() => {
        let unmounted = false;

        //Получить список проектов
        setLoading(true)
        getFullProjectList('collecting')(dispatch)
            .finally(() => !unmounted && setLoading(false));

        getProjectsList().then(res => setProfileProjectList(res?.data?.data || []))
        // После размонтирования компонента удалить список проектов
        return () => {
            unmounted = true;
            dispatch({type: SET_PROJECT_LIST, payload: {}});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileId])

    return (
        <div className="home-page">
            <div className="projectsFeed">
                <ProjectsFeedHeader profileProjectList={profileProjectList}/>
                <div className="projectsFeed__tabs">
                    <div className="projectsFeed__tabs_header">
              <span
                  className={classnames({'profileTabs__link projectsFeed__tabs_link': true, active: activeTab === 0})}
                  onClick={() => tabsToggle(0)}
              >
                Сбор инвестиций
              </span>
                        <span
                            className={classnames({
                                'profileTabs__link projectsFeed__tabs_link': true,
                                active: activeTab === 1
                            })}
                            onClick={() => tabsToggle(1)}
                        >
                Сбор инвестиций завершен
              </span>
                    </div>
                    <TabContent activeTab={activeTab}>
                        {
                            loading
                                ? <div className="projectsFeed__load">
                                    <Spinner style={{width: '3rem', height: '3rem'}}/>
                                </div>
                                : <>
                                    <TabPane tabId={0} className="projectsFeed__tabs_item">
                                        <Row>
                                            <ProjectsFeedList data={projectList} roleKey={role_key}/>
                                        </Row>
                                        <BtnShowMore meta={meta} roleKey={role_key}/>
                                    </TabPane>
                                    <TabPane tabId={1} className="projectsFeed__tabs_item">
                                        <Row>
                                            <ProjectsFeedList data={projectList} collectionCompleted={true}
                                                              roleKey={role_key}/>
                                        </Row>
                                    </TabPane>
                                </>
                        }
                    </TabContent>
                </div>
            </div>
        </div>
    )
}

export default ProjectsFeed;
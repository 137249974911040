export const dataCardDataFormat = (data = {}) => {
    const {email, fio, roles, phone, other_information} = data;

    return [
        {name: 'ФИО', desc: fio || ''},
        {name: 'Телефон', desc: phone || ''},
        {name: 'Email', desc: email || ''},
        {name: 'Роль', desc: roles?.length ? roles.map(item => item.display_name).join(', ') : ''},
        {name: 'Иная информация', desc: other_information || ''},
    ]
}
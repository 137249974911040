import React, {useEffect, useState} from 'react';
import {Spinner} from "reactstrap";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import {emailRegex} from '@shared/components/form/validate';
import formCheckIcon from "@shared/img/account/check.svg";
import {AuthService} from "@services/auth.service";
import {set} from "@utils/storage";
import classNames from 'classnames';

const RegFormEmail = ({
                          userType,
                          handleVisibleCode,
                          confirmedCode,
                          emailInputDisabled,
                          setEmailValue,
                          emailValue,
                          storageErrorMessage = '',
                          className = '',
                          errorMessage = null,
                          setEmailRequired,
                          emailRequired,
                          confirmBtnRequired,
                          setProfileTypeRequired = () => {
                          },
                          emailIcon,
                          changeEmail,
                          handleOnChangeEmail,
                          emailChangeStatus,
                          requestType
                      }) => {
    const [emailSpinner, setEmailSpinner] = useState(false) // Спинер в поле "Email"
    const [emailErrorMessage, setEmailErrorMessage] = useState(null) // Сообщение об ошибке при отправке Email
    const [confirmBtn, setConfirmBtn] = useState(true) // Паказать скрыть кнопку "Подтвердить"
    const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false) // Активность кнопки "Подтвердить"
    const [emailValidate, setEmailValidate] = useState(false) // Проверяем валидность email

    const inputGroupClass = classNames({
        'form__form-group-field': true,
        errorInput: emailErrorMessage,
        [className]: className
    })

    const confirmBtnClass = classNames({
        btn: true,
        disabled: !emailValidate || confirmBtnDisabled || emailErrorMessage,
        requiredFillConfirmBtn: confirmBtnRequired,
        'btn-outline-green': true,
    })

    // При клике по кнопке "Подтвердить", отправить запрос на подтверждение почты
    const handleConfirmEmail = () => {
        setConfirmBtnDisabled(true)
        setEmailSpinner(true)

        // Запрос на подтверждение почты
        AuthService.signup(emailValue, requestType)
            .then(res => {
                set('email', emailValue)
                handleVisibleCode(true)
            })
            .catch(error => {
                const emailErrorMessage = error.errors && error.errors.email && error.errors.email[0];
                // Показать кнопку "подтвердить"
                setConfirmBtn(true)
                // Обработка ошибки при отправке Email
                switch (error.status) {
                    case 422:
                        setEmailErrorMessage(emailErrorMessage ? emailErrorMessage : 'Ошибка валидации!');
                        break;
                    case 500:
                        setEmailErrorMessage('Не удалось отправить письмо!');
                        break;
                    default:
                        setEmailErrorMessage('Неизвестная ошибка!')
                }
                handleVisibleCode(false)
            }).finally(() => {
            setEmailSpinner(false)
            setConfirmBtnDisabled(false)
        })
    }

    // Обработка ввода в поле "Email"
    const handleChangeEmail = e => {
        if (e.target.value.length < 255) setEmailValue(e.target.value) // Ограничить длину до 255 символов
        // Валидация почты
        emailRegex.test(e.target.value) ? setEmailValidate(true) : setEmailValidate(false)
        // Скрыть текст ошибки если он есть
        if (emailErrorMessage) setEmailErrorMessage(null);
        // Уведомление об ошибке
        if (emailRequired) setEmailRequired(false)

    }

    const ChangeEmailService = () => {
        if (changeEmail) {
            if (!emailChangeStatus) {
                return <>
                    <img src={formCheckIcon} alt="confirmed"/>
                    <button className="btn btn-outline-green" onClick={handleOnChangeEmail}>Изменить</button>
                </>
            } else {
                return <div className="form__input-icon-wrap changeEmail__spinner"><Spinner style={{color: '#58BF8F'}}/>
                </div>
            }
        }

        return <img src={formCheckIcon} alt="confirmed"/>
    }

    // Вывести спинер || кнопку "Подтвердить" || галочку
    const renderInputService = () => {
        if (emailSpinner) return <div className="form__input-icon-wrap">
            <Spinner style={{color: '#58BF8F'}}/>
        </div>

        if (confirmedCode) return <div className="form__input-icon-wrap form__input-icon-check">
            <ChangeEmailService/>
        </div>

        if (confirmBtn) return <div className="confirm-mail-btn">
            <button
                className={confirmBtnClass}
                disabled={!emailValidate || confirmBtnDisabled || emailErrorMessage}
                onClick={handleConfirmEmail}
            >
                Подтвердить
            </button>
        </div>
    }

    const handleBlur = () => {
        if (!emailValue.length) setEmailErrorMessage('Обязательно для заполнения!')
    }

    const handleOnClickInputGroup = () => {
        if (!userType) {
            setProfileTypeRequired(true)
            setTimeout(() => setProfileTypeRequired(false), 1000)
        }
    }

    // Вывести сообщение об ошибке localStorage
    useEffect(() => {
        if (storageErrorMessage.length) setEmailErrorMessage(storageErrorMessage)
    }, [storageErrorMessage])

    // Вывести сообщение валидации
    useEffect(() => {
        if (errorMessage !== emailErrorMessage) setEmailErrorMessage(errorMessage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage])

    return (
        <>
            <span className="form-label">Email</span>
            <div className={inputGroupClass} onClick={handleOnClickInputGroup}>
                {
                    emailIcon
                        ? <div className="form__form-group-icon">
                            <AlternateEmailIcon/>
                        </div>
                        : null
                }
                <input
                    type="text"
                    name="email"
                    autoComplete="off"
                    value={emailValue}
                    disabled={!userType || emailInputDisabled}
                    className="account-input"
                    onChange={handleChangeEmail}
                    placeholder="exaple@gmail.com"
                    onBlur={handleBlur}
                />
                {renderInputService()}
            </div>
            {emailErrorMessage && <span className="form__form-error">{emailErrorMessage}</span>}
        </>
    )
}

export default RegFormEmail
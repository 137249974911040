import React, {useState} from 'react';
import {tabKeys} from "./helpers";
import ControlTabs from "@shared/components/tabs/ControlTabs";
import AdminSectionContent from "@containers/InnerUsers/AdminSection/AdminSectionContent";

const AdminSection = () => {
    const [activeTab, setActiveTab] = useState(tabKeys[0].key);

    return (
        <div className="accreditation adminSection">
            <h1 className="page-title">Раздел администратора</h1>
            <div className="accreditation__row accreditation__content">
                <ControlTabs
                    data={tabKeys}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    className="adminSection__tabs"
                />
                <AdminSectionContent activeTab={activeTab}/>
            </div>
        </div>
    )
}

export default AdminSection;
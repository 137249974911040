import React from 'react';
import {transactions} from "@shared/components/projects/projectDetail/ProjectCalculations/modal/helpers";
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";

const EarlyRepayment = () => {
    return (
        <div className='earlyRepayment'>
            <div className='platformServicesModal__feed'>
                <BackButton/>
            </div>
            <span className='earlyRepayment__title'>Информация о досрочном погашении по договору займа №****</span>
            <span className='earlyRepayment__subtitle'>Транзакции</span>
            <div className='earlyRepayment__info'>
                {
                    transactions.map((elem) => (
                        <div key={elem.id} className='earlyRepayment__infoBlock'>
                            <span className='earlyRepayment__infoBlock_name'>{elem.name}</span>
                            <span className='earlyRepayment__infoBlock_desc'>{elem.desc}</span>
                        </div>
                    ))
                }
            </div>
            <div className='earlyRepayment__desc'>
                <span>
                    • В случае если Займодавцем является физическое лицо, Заемщик обязан осуществлять функции
                    налогового агента в соответствии с требованиями действующего законодательства РФ. В этом случае
                    Заемщик обязан уплачивать Займодавцу суммы процентов за вычетом налога на доходы физических лиц
                    в порядке, установленном Договором инвестирования.
                </span>
                <span>
                    • Размер суммы НДФЛ рассчитан от фактически оплаченной суммы процентов и пени (при наличии)
                </span>
            </div>
        </div>
    )
};

export default EarlyRepayment;
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Masonry from "react-masonry-css";
import {useParams} from "react-router-dom";
import AccountPersonalData from "@shared/components/account/accountPersonalData";
import ApplicationData from "@shared/components/presentation/Qualification/ApplicationData";
import AccountPassportData from "@shared/components/account/accountPassportData";
import QualificationsSelectedConditions
    from "@containers/Moderator/VerificationQualification/QualificationsSelectedConditions";
import {getClientAccountData} from "@actions/innerUsersActions";
import {QualificationsProfileService} from "@services/qualifications.service";
import ApplicationArchive from "@shared/components/questionnaireProfile/view/ApplicationArchive";
import {Button} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {NOTICE_MODAL, QUALIFIED_NEW_CHECK} from "@shared/components/modal/constants";

const QualificationResults = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [qualifications, setQualifications] = useState([]);
    const {data} = useSelector(state => state.innerUsers.clientAccount);
    const {qualification, qualification_status_key, qualification_status, qualified_at, user, isBanned} = data || {};

    const startNewCheck = () => {
        if (user?.isBanned) {
            showModal(NOTICE_MODAL, 'Аккаунт профиля заблокирован')(dispatch)
            return;
        }

        if (isBanned) {
            showModal(NOTICE_MODAL, 'Профиль заблокирован')(dispatch)
            return;
        }

        showModal(QUALIFIED_NEW_CHECK, {
            qualificationId: qualification.id,
            qualificationStatus: qualification_status_key
        })(dispatch)
    }

    useEffect(() => {
        if (id) {
            getClientAccountData(id)(dispatch)
            QualificationsProfileService.getById(id, {include: 'profile'})
                .then(res => setQualifications(res?.data?.data || []))
                .catch(error => console.log(error))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <div className="qualification__results">
            <h1 className="page-title">Результаты проверки заявлений о признании квал. инвесторами платформы</h1>
            <div className="content__wrapper">
                <ApplicationArchive data={qualifications} title="Архив заявлений"/>
                <div className="qualifiedInvestor__info profileTabs">
                    <Masonry
                        breakpointCols={{default: 2, 1400: 1}}
                        className="my-masonry-grid custom-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        <ApplicationData
                            data={{
                                ...qualification,
                                profile: {qualification_status_key, qualification_status, qualified_at}
                            }}
                        />
                        <AccountPersonalData
                            editForm={false}
                            profileData={data}
                            title={'Персональные данные заявителя'}
                        />
                        <QualificationsSelectedConditions data={qualification?.conditions?.conditions || []}/>
                        <AccountPassportData
                            editForm={false}
                            profileData={data}
                            title={'Паспортные данные заявителя'}
                        />
                    </Masonry>
                </div>
                <div className="qualification__results_control">
                    <Button color="primary" size="lg" onClick={startNewCheck}>
                        Начать новую проверку
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default QualificationResults;
import {BaseService} from "@services/base.service";
import {ErrorWrapper} from "@utils/wrapper";

export class DocumentsService extends BaseService {
    static get entity() {
        return '/documents'
    }

    static async make(type) {
        try {
            return await this.request({auth: true}).post(`${this.entity}/${type}`, {})
        } catch (error) {
            throw new ErrorWrapper(error, "error to make file")
        }
    }
}
import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import {SidebarProps} from '@shared/prop-types/ReducerProps';
import ProfileSelection from '@shared/components/sidebar/profileSelection';
import {Link} from "react-router-dom";

const Sidebar = ({changeMobileSidebarVisibility, sidebar}) => {
    const sidebarClass = classNames({
        sidebar: true,
        'sidebar--show': sidebar.show,
        'sidebar--collapse': sidebar.collapse,
    });

    return (
        <div className={sidebarClass}>
            <div className="sidebar-header sidebar-hr">
                <div className="sidebar-logo">
                    <Link className="topbar__logo" to="/"/>
                </div>
            </div>
            <button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility}/>
            <Scrollbar className="sidebar__scroll scroll">
                <ProfileSelection/>
                <div className="sidebar__wrapper">
                    <SidebarContent/>
                </div>
            </Scrollbar>
        </div>
    );
};

Sidebar.propTypes = {
    sidebar: SidebarProps.isRequired,
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default React.memo(Sidebar);

export const accreditationRequestInclude = [
    'profile.legal_form',
    'profile.user',
    'profile.accreditation',
    'profile.accreditations',
    'profile.accreditations.remarks.profile',
    'profile.accreditations.remarks.profile.user',
    'profile.user.roles',
    'profile.legal_form.questionnaire',
    'profile.legal_form.questionnaire.responses',
    'profile.legal_form.questionnaire.responses.values',
    'profile.legal_form.questionnaire.responses.form',
    'profile.legal_form.questionnaire.responses.values.field'
]
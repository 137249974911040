import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Spinner} from "reactstrap";
import {AuthService} from '@services/auth.service';
import AccountWrapper from '@account/AccountWrapper';
import FormEmail from '@shared/components/form/FormEmail';
import {emailRegex} from '@shared/components/form/validate';

const ResetPassSendToken = () => {
    const RESET_LINK = 'https://lk.investonline.su/reset-password'
    const [disabledBtn, setDisabledBtn] = useState(true) // Активность кнопки "Продолжить"
    const [resetEmail, setResetEmail] = useState('') // Email
    const [spinnerStatus, setSpinnerStatus] = useState(false) // Статус отправки письма
    const [successSendEmail, setSuccessSendEmail] = useState(false) // Статус отправки письма
    const [emailValidate, setEmailValidate] = useState(false) // Проверяем валидность email

    const handleOnClickBtn = () => {
        if (resetEmail && emailRegex.test(resetEmail)) {
            setSpinnerStatus(true) // Запускаем спиннер
            AuthService.resetPassGetToken(resetEmail, RESET_LINK)
                .then()
                .catch(error => {
                })
                .finally(() => {
                    setSpinnerStatus(false)
                    setSuccessSendEmail(true)
                })
        }
    }

    return (
        <AccountWrapper>
            <div className="accountFormWrap__mobile_header">
                <h1>Восстановление пароля</h1>
                <NavLink exact to="login" className="accountFormWrap__mobile_auth">Вход</NavLink>
            </div>
            <div className="accountFormWrap">
                <div className="accountFormWrap__bg"/>
                <div className="accountFormWrap__wrap">
                    <div className={`accountFormWrap__content ${successSendEmail ? 'send-email-success' : ''}`}>
                        <h1>Восстановление пароля</h1>
                        <div className="reset-password-desc">
                            {successSendEmail
                                ? <div>
                                    <p>Спасибо, вам отправлена инструкция</p>
                                </div>
                                : <div>
                                    <p>Введите ваш Email и мы отправим ссылку на восстановление пароля</p>
                                </div>
                            }
                        </div>
                        {
                            !successSendEmail
                                ? <div className="register-form form reset-password-form">
                                    <div className="form__group-wrap">
                                        <div className="form__form-group reset-password-group">
                                            <FormEmail
                                                setDisabledBtn={setDisabledBtn}
                                                setValue={setResetEmail}
                                                value={resetEmail}
                                                emailValidate={emailValidate}
                                                setEmailValidate={setEmailValidate}
                                            />
                                        </div>
                                    </div>
                                    <div className="form__group-wrap">
                                        <button
                                            className={`btn btn-purple register-form-btn  ${disabledBtn ? 'disabled' : ''}`}
                                            disabled={disabledBtn}
                                            onClick={handleOnClickBtn}
                                        >
                                            <div className="btn-spinner-group">
                                                <Spinner
                                                    className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                                                Восстановить пароль
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                : <Link className="btn btn-purple register-form-btn reset-password-btn"
                                        to="/login">Войти</Link>
                        }
                    </div>
                </div>
            </div>
        </AccountWrapper>
    )
}

export default ResetPassSendToken;
import React from 'react';
import classNames from "classnames";
import {Table} from "reactstrap";

const ConfirmationCodesTable = ({tableHeader = [], tableContent = [], loading, title = ''}) => {
    const tableClass = classNames({
        accreditationTable: true,
        moderationProjectsTable: true,
        accreditationTable__load: loading,
    });

    const TableHeader = () => {
        return tableHeader.map(item => {
            const {id, title} = item;

            return (
                <th key={id} className="accreditationTable__headerItem">
                    <span>{title}</span>
                </th>
            )
        })
    }

    const TableBody = () => {
        return tableContent.map(item => {
            const {id, value, code, created_at} = item;

            return (
                <tr key={id}>
                    <td>{value}</td>
                    <td>{code}</td>
                    <td>{created_at}</td>
                </tr>
            )
        })
    }

    return (
        <div className={tableClass}>
            {
                title
                    ? <p className="adminSection__confirmCodes_title">{title}</p>
                    : null
            }
            <Table className="table--bordered" responsive>
                <thead>
                <tr>
                    <TableHeader/>
                </tr>
                </thead>
                <tbody>
                <TableBody/>
                </tbody>
            </Table>
            {
                loading
                    ? <div className="accreditationTable__loader">
                        <div className="load__icon-wrap">
                            <svg className="load__icon">
                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                            </svg>
                        </div>
                    </div>
                    : ''
            }
        </div>
    )
}

export default ConfirmationCodesTable;
import React from 'react';
import ProfileData from "@shared/components/presentation/ProfileData";
import PersonalData from "@shared/components/presentation/PersonalData";
import PassportData from "@shared/components/presentation/PassportData";
import CompanyData from "@shared/components/presentation/CompanyData";
import {navigationMenu} from '../helpers';
import AddedList from "@shared/components/form/addedList";
import GoverningStructure from "@shared/components/form/moderation/GoverningStructure";
import GoverningPerson from "@shared/components/form/moderation/GoverningPerson";

const ApplicantsDetails = ({
                               data,
                               step,
                               remarkList,
                               setRemarkList,
                               governmentState,
                               governmentDispatch,
                               role,
                               governingValidStatus,
                               showResult
                           }) => {
    const remarksRef = data.project.profile.legal_form_type === 'entity' ? 5 : 3;

    return (
        <div className="dataCard__list">
            <ProfileData
                data={data}
                fRef={navigationMenu[step].links[role][0].ref}
            />
            {
                role === 'entity'
                    ? <CompanyData
                        data={data.project.profile.legal_form.company}
                        fRef={navigationMenu[step].links[role][1].ref}
                    />
                    : ''
            }
            <PersonalData
                data={data.project.profile}
                fRef={navigationMenu[step].links[role][2].ref}
            />
            <PassportData
                data={data.project.profile.legal_form.passport}
                fRef={navigationMenu[step].links[role][3].ref}
            />
            {
                role === 'entity'
                    ? <>
                        <p className="moderationProject__title">Сведения о структуре органов управления</p>
                        <GoverningStructure
                            showResult={showResult}
                            state={governmentState}
                            dispatch={governmentDispatch}
                            fRef={navigationMenu[step].links[role][4].ref}
                            governingValidStatus={governingValidStatus}
                        />
                        <GoverningPerson
                            showResult={showResult}
                            state={governmentState}
                            dispatch={governmentDispatch}
                            governingValidStatus={governingValidStatus}
                        />
                    </>
                    : null
            }
            <p className="moderationProject__title">Решения модератора</p>
            <AddedList
                label="Замечания"
                data={remarkList}
                disabled={showResult}
                placeholder="Замечание"
                onChange={setRemarkList}
                dataType="moderation_profile"
                className="moderationProject__remarks"
                fRef={navigationMenu[step].links[role][remarksRef].ref}
            />
        </div>
    )
}

export default ApplicantsDetails;

import React, {useEffect, useState} from 'react';
import {SignatureService} from "@services/signature.service";
import {FilesService} from "@services/file.service";
import UploadField from "@shared/components/uploadField";
import {DownloadIcon} from "@shared/img/iconSvg";

const SignatureShowModal = ({modalData, hideModal}) => {
    const fileId = modalData;
    const [file, setFile] = useState(null);

    const downloadSignature = (id) => {
        SignatureService.download(id)
            .then(response => {
                const blob = new Blob([response.data], {type: "application/sig"})
                const link = window.document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = file.name + '.sig';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((e) => {
                console.log(e)
            });
    }

    useEffect(() => {
        if (fileId) {
            FilesService.getById(fileId, {include: 'signature,profile.legal_form'})
                .then(res => setFile(res.data.data))
                .then(() => {
                    if (!!file.signature) {
                        hideModal()
                    }
                })
                .catch(error => console.log(error))
        }
    }, [fileId])

    return (<div>
        <p className="modalFullScreen__title">
            Сведения элетронной подписи
        </p>
        {
            file && <div className="signatureShowModal__tabItem">
                <div className="signatureShowModal__tabItem_elem">
                    <span className="signatureShowModal__tabItem_name">Тип подписи:</span>
                    <span className="signatureShowModal__tabItem_desc">Квалифицированная электронная подпись</span>
                </div>
                <div className="signatureShowModal__tabItem_elem">
                    <span className="signatureShowModal__tabItem_name">Владелец:</span>
                    <span
                        className="signatureShowModal__tabItem_desc">{file.profile.legal_form.personal.fio}</span>
                </div>
                <div className="signatureShowModal__tabItem_elem">
                    <span className="signatureShowModal__tabItem_name">ИНН владельца:</span>
                    <span className="signatureShowModal__tabItem_desc">1234567</span>
                </div>
                <div className="signatureShowModal__tabItem_elem">
                    <span className="signatureShowModal__tabItem_name">Удостоверяющий центр:</span>
                    <span className="signatureShowModal__tabItem_desc">ООО “Екей УЦ”</span>
                </div>
                <div className="signatureShowModal__tabItem_elem">
                    <span className="signatureShowModal__tabItem_name">Срок действия:</span>
                    <span
                        className="signatureShowModal__tabItem_desc">с {file.signature.valid_from} по {file.signature.valid_to}</span>
                </div>
                <div className="signatureShowModal__tabItem_elem">
                    <span className="signatureShowModal__tabItem_name">Дата и время проверки ЭП:</span>
                    <span className="signatureShowModal__tabItem_desc">12.02.2020 13:00</span>
                </div>
                <div className="signatureShowModal__tabItem_elem signatureShowModal__tabItem_file flex-column">
                      <span className="signatureShowModal__tabItem_name">
                        Подписанный документ
                      </span>
                    {<UploadField viewDetail files={[file]} edit={false} title="паспорт"/>}
                </div>
                <div className="signatureShowModal__tabItem_elem">
                    <span className="signatureShowModal__tabItem_name">Файл подписи:</span>
                    {!!file.signature && <span className="signatureShowModal__tabItem_desc">
                        <div className="uploadField__download">{DownloadIcon()}</div>
                        <button
                            className="green-color btn-reset d-flex justify-content-start"
                            onClick={() => downloadSignature(file.signature.id)}
                        >
                             Скачать файл подписи
                        </button>
                        </span>}
                </div>
            </div>
        }
    </div>)
};

export default SignatureShowModal;
import React from 'react';
import AddProjectAboutCompany from './AddProjectAboutCompany';
import AboutProject from './AboutProject';
import BusinessModel from './BusinessModel';
import {addProjectMenu} from "@containers/Projects/AddProject/helpers";
import {useSelector} from "react-redux";

const ProjectDesc = ({step}) => {
    const {
        company = {}, title, description, stage_key, medias, businesses = [], videos, id
    } = useSelector(state => state.projects.projectEditData);

    return (
        <>
            <AddProjectAboutCompany
                fRef={addProjectMenu[step].links[0].ref}
                data={company}
                projectId={id}
            />
            <AboutProject
                data={{title, description, stage_key, medias, videos: videos || []}}
                fRef={addProjectMenu[step].links[2].ref}
                projectId={id}
            />
            <BusinessModel
                data={businesses}
                fRef={addProjectMenu[step].links[3].ref}
            />
        </>
    )
}

export default ProjectDesc;
import React from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {SET_PLEDGE_ERROR} from "@shared/components/form/moderation/EstimatedСollateral/helpers";

const EstimatedCollateral = ({dispatch, data, validate, pledgesValidStatus}) => {
    const {id, collateral_value, description} = data.length ? data[0] : {};
    //const [collateral, setCollateral] = useState('');
    //const [pledgeDesc, setPledgeDesc] = useState('');

    const handleOnChange = e => {
        dispatch({
            id,
            name: e.target.name,
            value: e.target.value
        })
    }

    const setFormError = (error, id) => {
        dispatch({
            type: SET_PLEDGE_ERROR,
            payload: {error, id}
        })
    }

    return <div className="addProject__page dataCard">
        <InputText
            name="collateral_value"
            label="Оценочная стоимость залога"
            onChange={e => handleOnChange({
                target: {
                    name: "collateral_value",
                    value: +(e.target.value.replace(/\D/g, ''))
                }
            })}
            value={collateral_value || ''}
            inputWithDesc
            inputWithDescValue="₽"
            resetValidate={!pledgesValidStatus}
            validator={[{required: true}]}
            formError={validate[id]}
            setFormError={error => setFormError(error, id)}
            containerClassName="addProject__middleInput"
            rank={true}
        />
        <InputText
            rows={2}
            type="textarea"
            name="description"
            label="Описание залога"
            onChange={handleOnChange}
            value={description || ''}
            resetValidate={!pledgesValidStatus}
            validator={[{maxLength: 100}, {required: true}]}
            formError={validate[id]}
            setFormError={error => setFormError(error, id)}
            containerClassName="addProject__fullInput"
        />
    </div>
}

export default EstimatedCollateral;

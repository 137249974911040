import {
    ModeratorAccreditationsService,
    ModeratorQualificationsService,
    OtherAccreditationService
} from '@services/accreditation.service';
import {getProfileData, getProfiles} from "@actions/profilesActions";

export const SET_ACCREDITATION_PROFILE_ID = 'SET_ACCREDITATION_PROFILE_ID';

export const sendForAccreditation = (id, dispatch) => {
    return OtherAccreditationService.create({}, id)
        .then(() => {
            getProfiles()(dispatch)
            getProfileData()(dispatch)
        })
        .catch(error => console.log(error))
}

export const getListForAccreditation = (
    role, datFrom, dateTo, sortedBy, searchText, page = 1, clearFilter = false
) => {

    const include = role === 'qualificationsInvestor'
        ? 'profile,profile.user,profile.qualification'
        : 'profile.legal_form,profile.user'

    const search = role === 'qualificationsInvestor'
        ? `profile.id:${searchText};profile.user.email:${searchText}` //profile.legal_form.personal.fio:${searchText};profile.legal_form.company.name:${searchText}
        : `profile.id:${searchText};profile.user.email:${searchText}` //profile.legal_form.personal.fio:${searchText};profile.legal_form.company.name:${searchText}

    const params = {
        page,
        include,
        limit: 20,
        to: clearFilter ? '' : dateTo,
        from: clearFilter ? '' : datFrom,
        orderBy: 'created_at',
        sortedBy: sortedBy ? 'asc' : 'desc',
        search: searchText && !clearFilter ? search : ''
    }

    if (role !== 'qualificationsInvestor') params.role = role

    return role !== 'qualificationsInvestor'
        ? ModeratorAccreditationsService.getList(params).catch(error => console.log(error))
        : ModeratorQualificationsService.getList(params).catch(error => console.log(error))
}
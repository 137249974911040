import React, {useEffect, useState} from 'react';
import {Button} from "reactstrap";
import {SettingsService} from "@services/settings.service";
import QualifiedFormConditions from './QualifiedFormConditions';
import QualifiedFormGuarantee from './QualifiedFormGuarantee';
import QualifiedFormConfirmPhone from "./QualifiedFormConfirmPhone";
import {checkCompletedDocument, checkFormValidity} from "./helpers";

const QualifiedForm = ({showModal}) => {
    const [data, setData] = useState(null);
    const [checkRequired, setCheckRequired] = useState(null);
    const [showConfirmPhone, setShowConfirmPhone] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState(null);
    const [formError, setFormError] = useState([]);
    const [formValidationCheck, setFormValidationCheck] = useState(false);

    const confirmQualified = () => {
        const checkSelectedOneCondition = checkFormValidity(data?.value?.conditions)

        setFormValidationCheck(true)

        if (!checkSelectedOneCondition) setFormErrorMessage('Необходимо выбрать не менее одного условия!')
        if (!data?.value?.guarantee?.selected) setCheckRequired(true)

        if (!formError.length && checkSelectedOneCondition && data?.value?.guarantee?.selected) {
            setShowConfirmPhone(true)
        }
    }

    useEffect(() => {
        SettingsService.getById('qualification_conditions')
            .then(res => setData(res?.data?.data))
            .catch(error => console.error(error))
    }, [])

    useEffect(() => {
        if (checkFormValidity(data?.value?.conditions) && formErrorMessage) setFormErrorMessage(null)
        checkCompletedDocument(data?.value?.conditions, formError, setFormError)
        if (formValidationCheck) setFormValidationCheck(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return <div className="qualifiedInvestor__modal">
        {
            showConfirmPhone
                ? <QualifiedFormConfirmPhone data={data}/>
                : <>
                    <div className="qualifiedInvestor__form qualifiedInvestor__form_line">
                        <p className="modalFullScreen__title">Подача заявления о признании физического лица
                            квалифицированным инвестором</p>
                        <div className="qualifiedInvestor__form_desc">
                            <p className="qualifiedInvestor__form_info">
                                1. Настоящим прошу признать меня квалифицированным инвестором для целей осуществления
                                инвестирования
                                на Платформе в сумме более 600 тысяч рублей.
                            </p>
                            <p className="qualifiedInvestor__form_info">
                                2. Настоящим подтверждаю выполнение мной следующего(-их) условия(-ий), необходимых для
                                признания меня
                                квалифицированным инвестором (отметить любое из ниже приведенных)* :
                            </p>
                        </div>
                        {
                            data?.value?.conditions?.length && data?.value?.guarantee
                                ? <>
                                    <QualifiedFormConditions
                                        data={data}
                                        setData={setData}
                                        formValidationCheck={formValidationCheck}
                                        formError={formError}
                                    />
                                    <QualifiedFormGuarantee
                                        data={data}
                                        setData={setData}
                                        checkRequired={checkRequired}
                                        setCheckRequired={setCheckRequired}
                                    />
                                    {
                                        formErrorMessage
                                            ? <div className="qualifiedInvestor__form_error">
                                                <span className="error-color">{formErrorMessage}</span>
                                            </div>
                                            : null
                                    }
                                    <div className="qualifiedInvestor__form_btn withdrawFunds__btnGroup">
                                        <Button
                                            onClick={() => showModal()}
                                            color="primary"
                                            className="account__btn_cancel"
                                            outline
                                        >
                                            Отменить
                                        </Button>
                                        <Button color="primary" className="account__btn_primary" onClick={confirmQualified}>
                                            Продолжить
                                        </Button>
                                    </div>
                                </>
                                : null
                        }
                    </div>
                    <div className="qualifiedInvestor__form_note">
                        <p>
                            <span>*</span>Для целей, предусмотренных Условиями 1, 3, 4 учитываются следующие ценные бумаги и
                            / или
                            финансовые инструменты: государственные ценные бумаги Российской Федерации, государственные
                            ценные бумаги
                            субъектов Российской Федерации и муниципальные ценные бумаги; акции и облигации российских
                            эмитентов;
                            государственные ценные бумаги иностранных государств; акции и облигации иностранных эмитентов;
                            российские
                            депозитарные расписки; иностранные депозитарные расписки, инвестиционные паи паевых
                            инвестиционных фондов,
                            паи (акции) иностранных инвестиционных фондов; ипотечные сертификаты участия; заключаемые на
                            организованных
                            торгах договоры, являющиеся производными финансовыми инструментами.
                        </p>
                        <p>
                            <span>**</span>При определении необходимого опыта работы учитывается работа в течение 5 лет,
                            предшествующих
                            дате подачи заявления о признании квалифицированным инвестором, и непосредственно связанная с
                            совершением
                            операций с ценными бумагами и (или) иными финансовыми инструментами, в том числе по принятию
                            решений о
                            совершении сделок, подготовке соответствующих рекомендаций, контролю за совершением операций,
                            анализом финансового рынка, управлением рисками.
                        </p>
                        <p>
                            <span>***</span>При расчете размера имущества учитывается только следующее имущество: денежные
                            средства,
                            находящиеся на счетах и (или) во вкладах (депозитах), открытых в кредитных организациях в
                            соответствии
                            с нормативными актами Банка России, и (или) в иностранных банках, с местом учреждения в
                            государствах,
                            указанных в подпунктах 1 и 2 пункта 2 статьи 51.1 Федерального закона "О рынке ценных бумаг", и
                            суммы
                            начисленных процентов; требования к кредитной организации выплатить денежный эквивалент
                            драгоценного металла
                            по учетной цене соответствующего драгоценного металла; ценные бумаги, предусмотренные пунктом
                            2.3
                            Указания Банка России от 29.04.2015 N 3629-У"О признании лиц квалифицированными инвесторами и
                            порядке
                            ведения реестра лиц, признанных квалифицированными инвесторами",
                            в том числе переданные физическим лицом в доверительное управление
                        </p>
                    </div>
                </>
        }
    </div>
}

export default QualifiedForm;
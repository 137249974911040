import React from 'react';
import {Link} from "react-router-dom";
import {initState} from './data';

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_CHECKBOX_VALUE = 'SET_CHECKBOX_VALUE';
export const SET_DATE_VALUE = 'SET_DATE_VALUE';
export const SET_SEARCH_FIELDS_VALUE = 'SET_SEARCH_FIELDS_VALUE';
export const FILTER_RESET = 'FILTER_RESET';
export const SET_VISIBLE = 'SET_VISIBLE';
export const SET_ALL_VISIBLE = 'SET_ALL_VISIBLE';
export const SET_SORTED = 'SET_SORTED';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const reducer = (state, action) => {
    const {name, value, key, sort} = action.payload || {};

    switch (action.type) {
        case SET_SEARCH_VALUE:
            return {...state, search: {...state.search, [name]: value}}
        case SET_SEARCH_FIELDS_VALUE:
            return {...state, searchFields: {...state.searchFields, [name]: value}}
        case SET_VISIBLE:
            const newTableHeaderVisible = [...state.tableHeaderVisible];
            const index = newTableHeaderVisible.indexOf(name);
            index !== -1 ? newTableHeaderVisible.splice(index, 1) : newTableHeaderVisible.push(name)

            return {...state, tableHeaderVisible: newTableHeaderVisible}
        case SET_CHECKBOX_VALUE:
            const searchCheckboxCopy = [...state.search[name]];
            const valueIndex = searchCheckboxCopy.indexOf(value);
            valueIndex !== -1 ? searchCheckboxCopy.splice(valueIndex, 1) : searchCheckboxCopy.push(value)

            return {...state, search: {...state.search, [name]: searchCheckboxCopy}}
        case SET_ALL_VISIBLE:
            return {...state, tableHeaderVisible: initState.tableHeaderVisible}
        case SET_DATE_VALUE:
            const newSearchDate = [...state.search[key]];
            let newFieldsDate = '';
            name === 'from' ? newSearchDate[0] = value : newSearchDate[1] = value

            if (newSearchDate[0] && newSearchDate[1]) newFieldsDate = 'between'
            if (!newSearchDate[0] && newSearchDate[1]) newFieldsDate = '<'
            if (newSearchDate[0] && !newSearchDate[1]) newFieldsDate = '>'

            return {
                ...state,
                search: {...state.search, [key]: newSearchDate},
                searchFields: {...state.searchFields, [key]: newFieldsDate}
            }
        case SET_SORTED:
            const sortedByCopy = [...state.sortedBy];
            const orderByCopy = [...state.orderBy];
            const orderIndex = orderByCopy.indexOf(key);

            if (orderIndex !== -1) {
                sortedByCopy[orderIndex] = sort;
            } else {
                orderByCopy.push(key)
                sortedByCopy.push(sort)
            }

            return {...state, sortedBy: sortedByCopy, orderBy: orderByCopy}
        case FILTER_RESET:
            return {...initState}
        case SET_CURRENT_PAGE:
            return {...state, currentPage: action.payload}

        default:
            return {...state}
    }
}

const getLogOperationUrl = (id, type, relationId) => {
    if (!id || !type) return null

    const url = {
        accreditation: `/accreditation_profile_results/${relationId}`,
        moderation: `/moderation_projects_results/${relationId}`, // FIXME если статус "на проверке" или "чрновик" прернаправить на карточку проекта
        qualification: `/qualification_results/${id}`,
        confirmation: '/',
        ban_profile: `/client_account/${id}`,
        ban_user: `/client_account/${id}`,
        profile: `/client_account/${id}`,
        bankDetail: '/',
        user: `/client_account/${id}`,
        document: '/',
        project: `/moderation_projects_results/${relationId}`,
    }

    return url[type]

}

const addLinkInLogDesc = (log, type, relation_id) => {
    if (!log) return '-'
    if (!type) return log

    const id = log.match(/№\w+/g);

    if (id?.length) {
        const idOnlyNumbers = id[0].substr(1);
        const logWithoutId = log.split(id[0]);

        if (!logWithoutId?.length) return '-'

        return (
            <span>
                <span>{logWithoutId[0]}</span>
                <Link to={getLogOperationUrl(idOnlyNumbers, type, relation_id)}>{id[0]}</Link>
                <span>{logWithoutId[1]}</span>
            </span>
        )
    }

    return log
}

export const dataFormat = data => {
    if (data?.length) {
        return data.map(item => {
            const {created_at, log, type_key, relation_id} = item;

            return {
                'link': [],
                'linkUrl': [],
                'created_at': created_at || '-',
                'type_key': addLinkInLogDesc(log, type_key, relation_id)
            }
        })
    }

    return []
}



import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useHistory} from "react-router-dom";
import {showModal} from "@actions/modalActions";
import {MODERATOR_ACC_NEW_CHECK, MODERATOR_ACC_PROFILE_REJECT, NOTICE_MODAL} from "@shared/components/modal/constants";
import {useDispatch, useSelector} from "react-redux";
import {ModeratorAccreditationsCheckService} from "@services/accreditation.service";

const AccProfileControls = ({
                                remarkList = [], showResult, accreditationId, accreditationStatus,
                                profileIsBanned, userIsBanned, setRemarkListErrorMessage
                            }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [returnedLoading, setReturnedLoading] = useState(false);
    const [accreditedLoading, setAccreditedLoading] = useState(false);
    const accreditationProfileId = useSelector(state => state.accreditation.accreditationProfileId);
    const [remarkListErrorTimer, setRemarkListErrorTimer] = useState(null);

    const returnedProfile = () => {
        setReturnedLoading(true);

        ModeratorAccreditationsCheckService.create(
            {
                type: "accreditation_returned",
                remarks: remarkList.map(item => item.message)
            },
            accreditationProfileId
        )
            .then(() => {
                history.push('/');
                setReturnedLoading(false)
            })
            .catch(() => setReturnedLoading(false))
    }

    const accreditedProfile = () => {
        if (remarkList.length) {
            setRemarkListErrorMessage('Удалите замечания чтобы продолжить!')
            clearTimeout(remarkListErrorTimer);
            setRemarkListErrorTimer(setTimeout(() => setRemarkListErrorMessage(null), 5000))
        } else {
            setAccreditedLoading(true);

            ModeratorAccreditationsCheckService.create(
                {type: "accreditation_accredited", remarks: []},
                accreditationProfileId
            )
                .then(() => history.push('/'))
                .catch(error => console.log(error))
                .finally(() => setAccreditedLoading(false))
        }
    }

    if (showResult) {
        const recheck = () => {
            if (userIsBanned) {
                showModal(NOTICE_MODAL, 'Аккаунт профиля заблокирован')(dispatch)

                return;
            }

            if (profileIsBanned) {
                showModal(NOTICE_MODAL, 'Профиль заблокирован')(dispatch)

                return;
            }

            showModal(MODERATOR_ACC_NEW_CHECK, {accreditationId, accreditationStatus})(dispatch)
        }

        return <div className="accreditationProfile__btnGroup">
            <Button color="primary" className="accreditationProfile__btn" onClick={recheck}>
                Начать новую проверку
            </Button>
        </div>
    }

    return <div className="accreditationProfile__btnGroup">
        <Button
            color="danger"
            className="accreditationProfile__btn"
            onClick={() => showModal(MODERATOR_ACC_PROFILE_REJECT)(dispatch)}
        >
            Отклонить профиль
        </Button>
        <Button
            color="primary"
            className="accreditationProfile__btn"
            onClick={returnedProfile}
        >
            <div className="btn-spinner-group">
                <Spinner className={`btn-spinner ${returnedLoading ? 'btn-spinner-visible' : ''}`}/>
                Отправить на доработку
            </div>
        </Button>
        <Button
            color="success"
            className="accreditationProfile__btn"
            onClick={accreditedProfile}
        >
            <div className="btn-spinner-group">
                <Spinner className={`btn-spinner ${accreditedLoading ? 'btn-spinner-visible' : ''}`}/>
                Утвердить
            </div>
        </Button>
    </div>
}

export default AccProfileControls;
import {ExternalProfileService} from "@services/profiles.service";

// Constants
const SET_CLIENT_ACCOUNT_DATA = 'SET_CLIENT_ACCOUNT_DATA';
const CLIENT_ACCOUNT_DATA_LOADING = 'CLIENT_ACCOUNT_DATA_LOADING';

export const getClientAccountData = profileId => {
    const include = [
        'legal_form',
        'legal_form.questionnaire',
        'bank_details',
        'accreditation',
        'user',
        'user.roles',
        'qualification',
        'qualifications',
        'ban',
        'bans',
    ]
    const params = {
        include: include.join(',')
    }

    return dispatch => {
        dispatch({type: CLIENT_ACCOUNT_DATA_LOADING})
        ExternalProfileService.getById(profileId, params)
            .then(res => {
                if (res?.data?.data) {
                    dispatch({
                        type: SET_CLIENT_ACCOUNT_DATA,
                        payload: res.data.data
                    })
                }
            })
            .catch(error => console.log(error))
            .finally(() => dispatch({type: CLIENT_ACCOUNT_DATA_LOADING}))
    }
}

export const constants = {SET_CLIENT_ACCOUNT_DATA, CLIENT_ACCOUNT_DATA_LOADING}


import {legalFormTypeDesc} from "@utils/auth";

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_CHECKBOX_VALUE = 'SET_CHECKBOX_VALUE';
export const SET_DATE_VALUE = 'SET_DATE_VALUE';
export const SET_SEARCH_FIELDS_VALUE = 'SET_SEARCH_FIELDS_VALUE';
export const FILTER_RESET = 'FILTER_RESET';
export const SET_VISIBLE = 'SET_VISIBLE';
export const SET_ALL_VISIBLE = 'SET_ALL_VISIBLE';
export const SET_SORTED = 'SET_SORTED';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const repayment_key = {
    annuity: 'Аннуитет',
    deferred_annuity: 'Аннуитет с отсрочкой',
    principal_and_annual_interest: 'ОД и проценты (в годовых) в конце срока',
    principal_and_fixed_interest: 'ОД и проценты (фиксированная сумма вознаграждения) в конце срока'
}

export const projectStatus = {
    projects_collecting: 'Сбор инвестиций',
    projects_signing_stage: 'Стадия подписания',
    projects_payment_stage: 'Стадия выплат',
    projects_delay: 'Просрочка',
    projects_completed: 'Завершен',
    projects_not_carried_out: 'Не состоялся',
    projects_canceled: 'Отменен',
    projects_checking: 'На проверке',
    projects_returned: 'Возвращен на доработку',
    projects_rejected: 'Отклонен',
    is_draft: 'Черновик'
}

export const initState = {
    search: {
        'id': '',
        'condition.type_key': '',
        'moderation_status_key': '',
        'profile.id': '',
        'profile.legal_form_type': '',
        'profile.legal_form.personal.fio': '',
        'condition.target': [],
        'condition.min': [],
        'condition.repayment_key': '',
        'condition.percent': [],
        'pledges': '',
        'condition.loan': [],
    },
    searchFields: {
        'id': 'in',
        'condition.type_key': 'in',
        'moderation_status_key': 'in',
        'profile.id': 'like',
        'profile.legal_form_type': 'in',
        'profile.legal_form.personal.fio': 'like ',
        'condition.target': 'between',
        'condition.min': 'between',
        'condition.repayment_key': 'in',
        'condition.percent': 'between',
        'condition.pledges': 'in',
        'condition.loan': 'between'
    },
    tableHeaderVisible: [
        'number', 'id', 'condition.type_key', 'moderation_status_key', 'profile.id', 'profile.legal_form_type', 'profile.legal_form.personal.fio',
        'condition.target', 'condition.min', 'date_publication', 'placement_period', 'funds_raised', 'condition.repayment_key', 'condition.percent',
        'condition.loan', 'pledges', 'number_investors', 'project_investor_id', 'investor_legal_form_type', 'fio_investor',
        'acceptance_date', 'amount_invested', 'agreement_date', 'commission_size', 'checkout',
    ],
    sortedBy: [],
    orderBy: [],
    currentPage: 1
}

export const reducer = (state, action) => {
    const {name, value, key, sort} = action.payload || {};

    switch (action.type) {
        case SET_SEARCH_VALUE:
            return {...state, search: {...state.search, [name]: value}}
        case SET_SEARCH_FIELDS_VALUE:
            return {...state, searchFields: {...state.searchFields, [name]: value}}
        case SET_VISIBLE:
            const newTableHeaderVisible = [...state.tableHeaderVisible];
            const index = newTableHeaderVisible.indexOf(name);
            index !== -1 ? newTableHeaderVisible.splice(index, 1) : newTableHeaderVisible.push(name)

            return {...state, tableHeaderVisible: newTableHeaderVisible}
        case SET_CHECKBOX_VALUE:
            const searchCheckboxCopy = [...state.search[name]];
            const valueIndex = searchCheckboxCopy.indexOf(value);
            valueIndex !== -1 ? searchCheckboxCopy.splice(valueIndex, 1) : searchCheckboxCopy.push(value)

            return {...state, search: {...state.search, [name]: searchCheckboxCopy}}
        case SET_ALL_VISIBLE:
            return {...state, tableHeaderVisible: initState.tableHeaderVisible}
        case SET_DATE_VALUE:
            const newSearchDate = [...state.search[key]];
            let newFieldsDate = '';
            name === 'from' ? newSearchDate[0] = value : newSearchDate[1] = value

            if (newSearchDate[0] && newSearchDate[1]) newFieldsDate = 'between'
            if (!newSearchDate[0] && newSearchDate[1]) newFieldsDate = '<'
            if (newSearchDate[0] && !newSearchDate[1]) newFieldsDate = '>'

            return {
                ...state,
                search: {...state.search, [key]: newSearchDate},
                searchFields: {...state.searchFields, [key]: newFieldsDate}
            }
        case SET_SORTED:
            const sortedByCopy = [...state.sortedBy];
            const orderByCopy = [...state.orderBy];
            const orderIndex = orderByCopy.indexOf(key);

            if (orderIndex !== -1) {
                sortedByCopy[orderIndex] = sort;
            } else {
                orderByCopy.push(key)
                sortedByCopy.push(sort)
            }

            return {...state, sortedBy: sortedByCopy, orderBy: orderByCopy}
        case FILTER_RESET:
            return {...initState}
        case SET_CURRENT_PAGE:
            return {...state, currentPage: action.payload}

        default:
            return {...state}
    }
}

const investmentPurposeDesc = {
    purchase: 'Приобретение основных средств',
    refinancing: 'Рефинансирование',
    replenishment: 'Пополнение оборотных средств'
}

export const registryProfileDataFormat = data => {
    if (data?.length) {
        return data.map((item, idx) => {
            const {id, condition, profile, moderation_status_key, pledges} = item;

            return {
                'link': ['id', 'profile.id'],
                'linkUrl': ['/registry/project', `/client_account/${profile.id}`], // FIXME для id указать ссылку на карточку проекта
                'number': `№${idx + 1}`,
                'id': id,
                'condition.type_key': investmentPurposeDesc[condition?.type_key] || '-',
                'moderation_status_key': moderation_status_key ? projectStatus[moderation_status_key] : '-',
                'profile.id': profile?.id || '-',
                'profile.legal_form_type': legalFormTypeDesc[profile?.legal_form_type] || '-',
                /*'profile.legal_form.personal.fio': profile?.legal_form_type === 'entity'
                    ? profile?.legal_form?.company?.name || '-'
                    : profile?.legal_form?.personal?.fio || '-',*/
                'profile.legal_form.personal.fio': profile?.legal_form?.personal?.fio || '-',
                'condition.target': condition?.target ? `${condition.target.toLocaleString()} ₽` : '-',
                'condition.min': condition?.min ? `${condition?.min.toLocaleString()} ₽` : '-',
                'date_publication': '-',
                'placement_period': '-',
                'funds_raised': '-',
                'condition.repayment_key': condition?.repayment_key ? repayment_key[condition.repayment_key] : '-',
                'condition.percent': condition?.percent ? `${condition?.percent.toLocaleString()} %` : '-',
                'condition.loan': condition?.loan ? `${condition.loan} мес.` : '-',
                'pledges': pledges && pledges.length ? 'Есть' : 'Нет',
                'number_investors': '-',
                'project_investor_id': '-',
                'investor_legal_form_type': '-',
                'fio_investor': '-',
                'acceptance_date': '-',
                'amount_invested': '-',
                'agreement_date': '-',
                'commission_size': '-',
                'checkout': '-',
            }
        })
    }

    return []
}

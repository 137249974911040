import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import {AuthService} from "@services/auth.service";
import UserAvatar from "@shared/components/account/avatar";
import {roleDesc} from "@utils/auth";
import {useDispatch} from "react-redux";
import {userInfo} from "@actions/userActions";
import {accStatusKey} from "@utils/statusKey";
import {useHistory} from "react-router-dom";
import {getProfileData} from "@actions/profilesActions";

const ProfileSelectionItem = ({data, setListVisible}) => {
    const [fio, setFio] = useState('')
    const dispatch = useDispatch();
    const legal_form_type = data.legal_form_type || '';
    const {key, value} = data.accreditation_status || {};
    const history = useHistory();

    const accreditedClass = classNames({
        "profileSelection__profile_status": true,
        "accountProfiles__item_error": key === accStatusKey.notAccredited
    })

    useEffect(()=>{
        data.legal_form_type === 'entity'
            ? setFio(data.legal_form?.company?.name || data.legal_form?.personal?.fio || '')
            : setFio(data.legal_form?.personal?.fio || '')
    }, [data])



    const handleChangeProfile = id => {
        AuthService.refreshTokens(id).then(() => {
            history.push('/');
            userInfo(dispatch)
            getProfileData()(dispatch)
        })
        setListVisible(false)
    }


    return (
        <div className="profileSelection__profile sidebar-hr" onClick={() => handleChangeProfile(data.id)}>
            <div className="profileSelection__profile_avatar">
                <UserAvatar id={data.id} hasAvatar={data.avatar || null}/>
            </div>
            <div className="profileSelection__profile_desc">
                <div className="profileSelection__profile_type">
                    {roleDesc[legal_form_type] || ''}
                </div>
                <div className="profileSelection__profile_name">{fio}</div>
                <div className={accreditedClass}>
                    {value}
                </div>
            </div>
        </div>
    )
}

export default ProfileSelectionItem;
import React, {useEffect, useReducer, useState} from 'react';
import {Col, Row} from "reactstrap";
import {reducer} from "@shared/components/account/helpers";
import InputText from "@shared/components/form/profileForm/InputText";
import UploadField from "@shared/components/uploadField";

const EditAccountCompanyData = ({profileData}) => {
    const {
        name, inn, ogrn, legal_address, actual_address, address_matches, charters
    } = profileData?.legal_form?.company || {};
    const [formError, setFormError] = useState([]) // Ошибочные поля
    // eslint-disable-next-line no-unused-vars
    const [_, setFormValidate] = useState(false) // Валидность всех полей формы
    const [actualAddressResetValidate, setActualAddressResetValidate] = useState(false)

    const initialState = {
        name: name || '',
        inn: inn || '',
        ogrn: ogrn || '',
        charters: charters || [],
        legal_address: legal_address || '',
        actual_address: address_matches ? legal_address : actual_address || '',
        address_matches: address_matches || false,
    }

    const [formData, setFormData] = useReducer(reducer, initialState);

    const handleOnChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        })
    }

    const addressMatchesOnChange = e => {
        handleOnChange(e)
        setFormData({
            name: 'actual_address',
            value: formData.legal_address,
        })
        setActualAddressResetValidate(!actualAddressResetValidate)
    }

    useEffect(() => {
        let validateStatus = true;
        let identical = true;

        for (let key in formData) {
            if (key === 'address_matches') continue;
            if (('' + formData[key]).length === 0) validateStatus = false
        }

        if (validateStatus) {
            for (let key in formData) {
                if ((initialState[key] !== formData[key])) identical = false
            }

            if (identical) {
                setFormValidate(false)
            } else {
                setFormValidate(true)
            }

        } else {
            setFormValidate(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    return (
        <div className="profileTabs__edit">
            <InputText
                type="text"
                name="name"
                id="nameInput"
                label="Наименования компании"
                onChange={handleOnChange}
                value={formData.name}
                validator={[{required: true}, {maxLength: 300}]}
                containerClassName="profileTabs__edit_item"
                formError={formError}
                setFormError={setFormError}
                tooltip="Правовая форма и наименование указываются без сокращений"
                tooltipClass="profileTabs__companyNameTooltip"
            />
            <Row>
                <Col md={6}>
                    <InputText
                        type="mask"
                        name="inn"
                        id="inn"
                        label="ИНН"
                        onChange={handleOnChange}
                        value={formData.inn}
                        validator={[{required: true}, {numbersCount: 10}]}
                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        formError={formError}
                        setFormError={setFormError}
                        containerClassName="profileTabs__edit_item"
                    />
                </Col>
                <Col md={6}>
                    <InputText
                        type="mask"
                        name="ogrn"
                        id="ogrn"
                        label="ОГРН"
                        onChange={handleOnChange}
                        value={formData.ogrn}
                        validator={[{required: true}, {numbersCount: 13}]}
                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        formError={formError}
                        setFormError={setFormError}
                        containerClassName="profileTabs__edit_item"
                    />
                </Col>
            </Row>
            <div className="inputText__wrapper profileTabs__edit_item">
                <label>Устав</label>
                <UploadField
                    files={formData.charters}
                    edit={true}
                    multiple={true}
                    maxFiles={5}
                    title="Устав"
                    onChange={handleOnChange}
                    fileKey="charters"
                    fileType="company"
                    accept=".jpeg, .jpg, .png, .pdf"
                />
            </div>
            <InputText
                type="text"
                name="legal_address"
                id="legal_address"
                label="Юридический адрес"
                onChange={handleOnChange}
                value={formData.legal_address}
                validator={[{required: true}, {maxLength: 500}]}
                containerClassName="profileTabs__edit_item"
                formError={formError}
                setFormError={setFormError}
            />
            <InputText
                type="text"
                name="actual_address"
                id="actual_address"
                label="Фактический адрес"
                onChange={handleOnChange}
                value={formData.address_matches ? formData.legal_address : formData.actual_address}
                validator={formData.address_matches ? [] : [{required: true}, {maxLength: 500}]}
                disabled={formData.address_matches}
                containerClassName="profileTabs__edit_item"
                formError={formError}
                setFormError={setFormError}
                resetValidate={actualAddressResetValidate}
                setResetValidate={setActualAddressResetValidate}
            />
            <InputText
                type="checkbox"
                name="address_matches"
                id="company_address_matches"
                label="Совпадает с юридическим адресом"
                onChange={addressMatchesOnChange}
                value={formData.address_matches}
                containerClassName="profileTabs__edit_item profileTabs__edit_check"
            />
        </div>
    )
}

export default EditAccountCompanyData;

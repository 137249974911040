import React from 'react';
import {navigationMenu} from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/helpers";
import BankDetail from "@shared/components/presentation/BankDetail";

const ModerationProjectBankDetails = ({data, step}) => {

    return (
        <div className="dataCard__list">
            <BankDetail
                data={data.project.profile.bank_details}
                fRef={navigationMenu[step].links[0].ref}
            />
        </div>
    )
}

export default ModerationProjectBankDetails;
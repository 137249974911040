import React, {useEffect, useState} from 'react';
import {getBlobUrl} from "@utils/files";


const MediaPreview = ({file, onClick}) => {
    const {id} = file;
    const [url, setUrl] = useState(null);

    useEffect(() => {
        getBlobUrl(id).then(res => setUrl(res))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return url
        ? <div
            className="mediaPresentation__bg mediaPresentation__preview"
            style={{background: `url(${url})`}}
            onClick={() => onClick({file, url})}
        />
        : null
}

export default MediaPreview;

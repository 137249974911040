import React from 'react';
import DataCard from "@shared/components/data/DataCard";

const DocumentsCompany = ({data = [], fRef}) => {
    const dataFormat = data && data.length
        ? data.map(item => ({name: item.label, desc: item.files.length ? item.files : '', type: 'file'}))
        : null

    return <DataCard data={dataFormat} title="Документы компании" fRef={fRef}/>
}

export default DocumentsCompany;

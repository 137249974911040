import React, {useEffect, useState} from 'react';
import cn from "classnames";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import {getInputSetting} from "./helpers";
import InputText from "@shared/components/form/profileForm/InputText";

const RegisterAccountNameField = ({state, dispatch, setData, setFormError, type}) => {
    const [resetValidate, setResetValidate] = useState(null);
    const [touched, setTouched] = useState(false);
    const {formError, data, formValidationCheck} = state;
    const {without_patronymic} = data;
    const {fieldLabel, fieldPlaceholder} = getInputSetting(type);
    const companyFieldValidate = [{onlyCyrillic: true}, {maxLength: 300}, {required: true}];
    const fioFieldValidate = [
        {wordCount: without_patronymic ? 2 : 3},
        {onlyCyrillic: true},
        {maxLength: 100},
        {required: true}
    ];

    const registerFieldWrapClass = cn({
        'form__form-group-field': true,
        errorInput: formError.includes(type)
    })

    const handleOnChangeResetValidate = value => {
        formValidationCheck
            ? dispatch({name: 'formValidationCheck', value})
            : setResetValidate(value)
    }

    const handleOnChange = e => {
        setData(e)
    }

    useEffect(() => {
        if (touched) setResetValidate(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [without_patronymic])

    return (
        <div className="register__emailField register__field">
            <span className="form-label">{fieldLabel}</span>
            <div className={registerFieldWrapClass}>
                <div className="form__form-group-icon">
                    <AccountOutlineIcon/>
                </div>
                <InputText
                    noLabel
                    checkAfterFocus
                    name={type}
                    placeholder={fieldPlaceholder}
                    className="account-input"
                    value={data[type] || ''}
                    onChange={handleOnChange}
                    onBlur={() => setTouched(true)}
                    formError={formError}
                    setFormError={setFormError}
                    validator={type === 'fio' ? fioFieldValidate : companyFieldValidate}
                    resetValidate={formValidationCheck || resetValidate}
                    setResetValidate={handleOnChangeResetValidate}
                />
            </div>
        </div>
    )
};

export default RegisterAccountNameField;
export const SET_TERMS_ERROR = 'SET_TERMS_ERROR';
export const TERMS_INPUT_EDIT_VISIBLE = 'TERMS_INPUT_EDIT_VISIBLE';
export const HIDE_ALL_INPUT_EDIT = 'HIDE_ALL_INPUT_EDIT';
export const SET_TERMS_VALUE = 'SET_TERMS_VALUE';

export const initTermsState = (data = []) => {
    return {
        condition: {
            percent: data?.percent || '',
            loan: data?.loan || '',
            deffer_period: data?.deffer_period || '',
            penalty_per_day: data?.penalty_per_day || '',
            charge_penalty_first_day: data?.charge_penalty_first_day || '',
            percents_calculation: data?.percents_calculation || '',
            output_key: data?.output_key || '',
        },
        validate: [],
        percentEdit: false,
        loanEdit: false,
        defferPeriodEdit: false,
        penaltyPerDayEdit: false,
        chargePenaltyFirstDayEdit: false,
        percentsCalculationEdit: false,
        outputKeyEditEdit: false,
    }
}

export const termsReducer = (state, action) => {
    switch (action.type) {
        case TERMS_INPUT_EDIT_VISIBLE:
            return {
                ...state,
                [action.payload]: !state[action.payload]
            }
        case HIDE_ALL_INPUT_EDIT:
            return {
                ...state,
                percentEdit: false,
                loanEdit: false
            }
        case SET_TERMS_ERROR:
            return {
                ...state,
                validate: action.payload
            }
        case SET_TERMS_VALUE:
            return {
                ...state,
                [action.payload.dataKey]: {
                    ...state[action.payload.dataKey],
                    [action.payload.name]: action.payload.value
                }
            }
        default:
            return {
                ...state,
                rate: {
                    ...state.rate,
                    [action.payload.name]: action.payload.value
                }
            }
    }
}

import {Http} from '@services/http.init'
import {ErrorWrapper, ResponseWrapper} from "@utils/wrapper";

export class BaseService {
    static get entity() {
        throw new Error('entity getter not defined')
    }

    static request(status = {auth: false}) {
        return new Http(status)
    }

    static responseWrapper(...rest) {
        return new ResponseWrapper(...rest)
    }

    static errorWrapper(...rest) {
        return new ErrorWrapper(...rest)
    }

    /**
     * Получить по id
     * @param data{object}
     * @returns {Promise<ResponseWrapper>}
     */
    static async getList(data = {}) {
        const params = {...data}

        try {
            const response = await this.request({auth: true}).get(this.entity, {params})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    /**
     * Получить по id
     * @param id{string}
     * @param data{object}
     * @returns {Promise<ResponseWrapper>}
     */
    static async getById(id, data = {}) {
        const params = {...data}

        try {
            const response = await this.request({auth: true}).get(`${this.entity}/${id}`, {params})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    /**
     * Создать новый экземпляр
     * @param data{object}
     * @param id{number, null}
     * @param params{object}
     * @returns {Promise<ResponseWrapper>}
     */
    static async create(data = {}, id = null, params = {}) {
        const url = id ? `${this.entity}/${id}` : `${this.entity}`;

        try {
            const response = await this.request({auth: true}).post(url, data, {params});
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    /**
     * Обновить экземпляр по id
     * @param id{string}
     * @param data{object}
     * @returns {Promise<ResponseWrapper>}
     */
    static async update(id, data = {}) {
        const url = id ? `${this.entity}/${id}` : `${this.entity}`;

        try {
            const response = await this.request({auth: true}).put(url, data)
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    /**
     * Удалить экземпляр по id
     * @param id{number}
     * @returns {Promise<ResponseWrapper>}
     */
    static async remove(id) {
        const url = id ? `${this.entity}/${id}` : `${this.entity}`;
        try {
            const response = await this.request({auth: true}).delete(url)
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }
}
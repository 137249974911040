import React, {useEffect, useState} from 'react';
import FileViewer from 'react-file-viewer';
import {getFileBlob} from "@services/blob.service";
import {DownloadIcon} from "@shared/img/iconSvg";
import {downloadBlobFile} from "@utils/files";

const FileDetailModal = ({modalData}) => {
    const [blobData, setBlobData] = useState(null);
    const {id, extension, name} = modalData;

    const handleOnClickDownload = () => downloadBlobFile(id, `${name}.${extension}`)

    useEffect(() => {
        if (id) getFileBlob(id).then(blob => setBlobData(blob))
    }, [id])

    return blobData
        ? <div className="fileDetailModal">
            <div className="fileDetailModal__header">
                <p className="modalFullScreen__title">
                    {name}
                </p>
                <div className="uploadField__download" onClick={handleOnClickDownload}>
                    <DownloadIcon/>
                </div>
            </div>
            <div className="fileDetailModal__content">
                <FileViewer
                    fileType={extension}
                    filePath={blobData}
                />
            </div>
        </div>
        : null
}

export default FileDetailModal;
export const initState = {
    search: {
        'created_at': [],
        'profile.qualified_at': [],
        'profile.unqualified_at': [],
        'profile.legal_form.personal.fio': '',
        'profile.legal_form.personal.inn': '',
        'profile.qualification_status_key': [],
        'id': '',
        'profile.id': '',
    },
    searchFields: {
        'created_at': 'between',
        'profile.qualified_at': 'between',
        'profile.unqualified_at': 'between',
        'profile.legal_form.personal.fio': 'like',
        'profile.legal_form.personal.inn': 'like',
        'profile.qualification_status_key': 'in',
        'id': 'like',
        'profile.id': 'like',
    },
    tableHeaderVisible: [
        'number', 'id', 'profile.qualification_status_key', 'profile.id', 'profile.legal_form.personal.fio', 'profile.legal_form.personal.inn',
        'created_at', 'profile.qualified_at', 'profile.unqualified_at', 'moreDetails'
    ],
    sortedBy: ['desc', 'desc', 'desc'],
    orderBy: ['created_at', 'profile.qualified_at', 'profile.unqualified_at'],
    currentPage: 1
}

export const qualificationsRegistryStatusKey = {
    qualification_qualified: 'Одобрено',
    qualification_checking: 'На проверке',
    qualification_not_qualified: 'Аннулировано',
    qualification_rejected: 'Аннулировано',
}

export const tableHeader = [
    {id: 0, name: 'number', title: '№ п/п', cellSize: 'sm'},
    {id: 1, name: 'id', title: 'Номер заявления', filterType: 'searchField', cellSize: 'sm'},
    {
        id: 2,
        name: 'profile.qualification_status_key',
        title: 'Статус заявления',
        cellSize: 'sm',
        filterType: 'checkbox',
        filterData: [
            {id: 0, name: 'all', label: 'Все'},
            {id: 1, name: 'qualification_qualified', label: qualificationsRegistryStatusKey.qualification_qualified},
            {id: 2, name: 'qualification_checking', label: qualificationsRegistryStatusKey.qualification_checking},
            {
                id: 3,
                name: 'qualification_not_qualified,qualification_rejected',
                label: qualificationsRegistryStatusKey.qualification_not_qualified
            },
        ],
    },
    {id: 3, name: 'profile.id', title: 'ID профиля', filterType: 'searchField', cellSize: 'sm'},
    {id: 4, name: 'profile.legal_form.personal.fio', title: 'ФИО', filterType: 'searchField', cellSize: 'sm'},
    {id: 5, name: 'profile.legal_form.personal.inn', title: 'ИНН', filterType: 'searchField', cellSize: 'sm'},
    {id: 6, name: 'created_at', title: 'Дата подачи заявления', filterType: 'date', cellSize: 'sm'},
    {id: 7, name: 'profile.qualified_at', title: 'Дата одобрения заявления', filterType: 'date', cellSize: 'sm'},
    {id: 8, name: 'profile.unqualified_at', title: 'Дата аннулирования заявления', filterType: 'date', cellSize: 'sm'},
    {id: 9, name: 'moreDetails', title: '', cellSize: 'sm', noExport: true},
]

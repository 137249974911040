import {BaseService} from "@services/base.service";

export class ModerationsListService extends BaseService {
    static get entity() {
        return '/moderator/moderations'
    }
}

export class ModerationsCheckService extends BaseService {
    static get entity() {
        return '/moderator/moderations/check'
    }
}

export class ModerationsService extends BaseService {
    static get entity() {
        return '/moderations'
    }
}

export class ModeratorProfilesService extends BaseService {
    static get entity() {
        return '/moderator/profiles'
    }
}

export class ModeratorProjectsService extends BaseService {
    static get entity() {
        return '/moderator/projects'
    }
}

import React from 'react';

const BaseTableHeader = ({data = []}) => {
    return data.length
        ? <thead>
        <tr>
            {
                data.map(item => (
                    <th key={item.id} className="accreditationTable__headerItem">
                        <span>{item.title}</span>
                    </th>
                ))
            }
        </tr>
        </thead>
        : null
};

export default BaseTableHeader;
import React from 'react';
import DataCard from "@shared/components/data/DataCard";

const PersonalData = ({data, fRef}) => {
    const {legal_form, legal_form_type, user, phone} = data;
    const {
        birth_date, birth_place, fio, inn, registration_address, residence_address, position, ogrnip, snils
    } = legal_form.personal || {};

    const dataFormat = [
        {name: 'ФИО', desc: fio || ''},
        {name: 'Дата рождения', desc: birth_date || ''},
        {name: 'Телефон', desc: phone || ''},
        ...(legal_form_type === 'entity' ? [{name: 'Должность', desc: position || ''}] : []),
        ...(legal_form_type === 'ie' ? [{name: 'ОГРНИП', desc: ogrnip || ''}] : []),
        ...(user.roles[0].name === 'borrower' && legal_form_type !== 'ie' ? [{name: 'СНИЛС', desc: snils || ''}] : []),
        {name: 'ИНН', desc: inn || ''},
        {name: 'Место рождения', desc: birth_place || ''},
        {name: 'Адрес регистрации по месту жительства', desc: registration_address || ''},
        ...(user.roles[0].name === 'borrower' ? [{name: 'Адрес фактического пребывания', desc: residence_address || ''}] : []),
    ]

    return <DataCard data={dataFormat} title="Персональные данные заявителя" fRef={fRef}/>
}

export default PersonalData;
import React from "react";
import {SET_INPUT_EDIT_VISIBLE} from "@shared/components/form/moderation/PlatformServiceForm/helpers";
import {TERMS_INPUT_EDIT_VISIBLE} from "@shared/components/presentation/TermsProject/helpers";

export const navigationMenu = [
    {
        role: true,
        links: {
            'ie': [
                {id: 0, ref: React.createRef(), title: 'Данные профиля'},
                {id: 1, ref: React.createRef(), title: 'Персональные данные заявителя'},
                {id: 2, ref: React.createRef(), title: 'Проверка электронной подписи'},
                {id: 3, ref: React.createRef(), title: 'Решение модератора'},
            ],
            'entity': [
                {id: 0, ref: React.createRef(), title: 'Данные профиля'},
                {id: 1, ref: React.createRef(), title: 'Данные компании'},
                {id: 2, ref: React.createRef(), title: 'Персональные данные заявителя'},
                {id: 3, ref: React.createRef(), title: 'Паспортные данные заявителя'},
                {id: 4, ref: React.createRef(), title: 'Сведения о структуре органов управления'},
                {id: 5, ref: React.createRef(), title: 'Проверка электронной подписи'},
                {id: 6, ref: React.createRef(), title: 'Решение модератора'},
            ],
        }
    },
    {
        links: [
            {id: 0, ref: React.createRef(), title: 'Условия проекта'},
            {id: 1, ref: React.createRef(), title: 'Услуги платформы'},
            {id: 2, ref: React.createRef(), title: 'Решение модератора'},
        ]
    },
    {
        links: [
            {id: 0, ref: React.createRef(), title: 'О компании'},
            {id: 1, ref: React.createRef(), title: 'Аналитический блок'},
            {id: 2, ref: React.createRef(), title: 'О проекте'},
            {id: 3, ref: React.createRef(), title: 'Документы компании'},
            {id: 4, ref: React.createRef(), title: 'Решение модератора'},
        ]
    },
    {
        links: []
    },
    {
        links: [
            {id: 0, ref: React.createRef(), title: 'Банковские реквизиты'},
        ]
    },
    {
        links: [
            {id: 0, ref: React.createRef(), title: 'Данные заявителя'},
            {id: 1, ref: React.createRef(), title: 'Условия проекта'},
            {id: 2, ref: React.createRef(), title: 'Описание проекта и документы'},
            {id: 3, ref: React.createRef(), title: 'Обеспечение'},
            {id: 5, ref: React.createRef(), title: 'Иные сведенья'},
        ]
    },
]

// Валидация "Аналитический блок"
export const checkAnalyticValidate = (analyticState, setAnalyticValidStatus) => {
    let status = true;
    const {
        credit_history, suspension, balance, revenue, net_profit, nds, contributions, total_paid, debt,
        entries_egrul, arbitration_cases, execution_proceedings, availability_lists, government_purchases,
        analyticValid, analyticEgrulValid, arbitrationCasesValid, governmentPurchasesValid
    } = analyticState;

    if (
        !(credit_history && suspension && balance && revenue && net_profit && nds && contributions &&
            total_paid && debt && execution_proceedings && availability_lists)
    ) {
        status = false
    }

    if (status) {
        entries_egrul.forEach(item => {
            for (let key in item) {
                if (!item[key]) {
                    status = false
                    break;
                }
            }
        })
    }

    if (status) {
        for (let key in arbitration_cases) {
            for (let keyVn in arbitration_cases[key]) {
                for (let i in arbitration_cases[key][keyVn]) {
                    if (!arbitration_cases[key][keyVn][i]) {
                        status = false
                        break;
                    }
                }
            }
        }
    }

    if (status) {
        for (let key in government_purchases) {
            for (let keyVn in government_purchases[key]) {
                if (!government_purchases[key][keyVn]) {
                    status = false
                    break;
                }
            }
        }
    }

    if (status &&
        (analyticValid.length ||
        arbitrationCasesValid.plaintiffAll.length ||
        arbitrationCasesValid.plaintiffYear.length ||
        arbitrationCasesValid.respondentAll.length ||
        arbitrationCasesValid.respondentYear.length ||
        governmentPurchasesValid.participant.length ||
        governmentPurchasesValid.contracts.length)) status = false

    if (status && analyticEgrulValid.length) {
        analyticEgrulValid.forEach(item => {
            if (item.length) status = false
        })
    }

    setAnalyticValidStatus(status);

    return status
}


// Валидация "Сведения о структуре органов управления"
export const checkGoverningValidate = (governmentState, setGoverningValidStatus) => {
    let status = true;

    const {
        participants, body_name, participants_name, persons_information,
        participantsValid, governingPersonValid, governingValid,
    } = governmentState;

    if (!(body_name && participants_name)) status = false

    if (status && participants.length) {
        participants.forEach(item => {
            for (let key in item) {
                if (!item[key]) {
                    status = false
                    break;
                }
            }
        })
    }

    if (status && persons_information.length) {
        persons_information.forEach(item => {
            for (let key in item) {
                if (!item[key]) {
                    status = false
                    break;
                }
            }
        })
    }

    if (status && governingValid.length) status = false

    if (status && participantsValid.length) {
        participantsValid.forEach(item => {
            if (item.length) status = false
        })
    }

    if (status && governingPersonValid.length) {
        governingPersonValid.forEach(item => {
            if (item.length) status = false
        })
    }

    setGoverningValidStatus(status);

    return status
}

// Валидация "Залог"
export const checkPledgeValidate = (pledgesState, setPledgesValidStatus) => {
    let status = true;
    const {pledges, validate} = pledgesState;

    if (pledges.length) {
        pledges.forEach(item => {
            for (let key in item) {
                if (!item[key]) {
                    status = false;
                    break;
                }
            }
        })
    }

    if (status && validate.length) {
        validate.forEach(item => {
            if (item.length) status = false
        })
    }

    setPledgesValidStatus(status);

    return status
}

// Валидация "Услуги платформы"
export const checkRateValidate = (pledgesState, rateDispatch, setRateValidStatus) => {
    let status = true;
    const {rate, validate, supportEdit, tariffEdit} = pledgesState;

    if (!rate.tariff) {
        status = false;

        if (!tariffEdit) {
            rateDispatch({
                type: SET_INPUT_EDIT_VISIBLE,
                payload: 'tariffEdit'
            })
        }
    }

    if (status && !rate.support) {
        status = false;

        if (!supportEdit) {
            rateDispatch({
                type: SET_INPUT_EDIT_VISIBLE,
                payload: 'supportEdit'
            })
        }
    }

    if (status && validate.length) status = false

    setRateValidStatus(status);

    return status
}

// Валидация "Условия"
export const checkTermsValidate = (termsState, termsDispatch, setTermsValidStatus) => {
    let status = true;
    const {condition, validate, percentEdit, loanEdit} = termsState;

    if (!condition.percent) {
        status = false;

        if (!percentEdit) {
            termsDispatch({
                type: TERMS_INPUT_EDIT_VISIBLE,
                payload: 'percentEdit'
            })
        }
    }

    if (status && !condition.loan) {
        status = false;

        if (!loanEdit) {
            termsDispatch({
                type: TERMS_INPUT_EDIT_VISIBLE,
                payload: 'loanEdit'
            })
        }
    }

    if (status && validate.length) status = false

    setTermsValidStatus(status);

    return status
}

import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {showModal} from "@actions/modalActions";
import ProjectsFeedInfo from './ProjectsFeedInfo';
import {useDispatch, useSelector} from "react-redux";
import ProjectsFeedBalance from './ProjectsFeedBalance';
import {ADD_PROJECT} from "@shared/components/modal/constants";
import {AddRectIcon} from "@shared/img/iconSvg";
import {accStatusKey} from "@utils/statusKey";
import {addProject} from "@actions/projectsActions";
import ProjectNotification from "@containers/Projects/ProjectsFeed/ProjectsFeedHeader/ProjectNotification";
import {qualifiedStatusKey} from "@utils/auth";

const ProjectsFeedHeader = ({profileProjectList}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [addProjectSpinnerStatus, setAddProjectSpinnerStatus] = useState(false);
    const user = useSelector(state => state.user);
    const profileId = user?.profile?.id || null;
    const role_key = user?.user?.roles[0]?.name || null;
    const {accreditation_status_key, qualification_status_key, legal_form_type} = user?.profile || {};
    const title = role_key === 'investor' ? 'Инвестиционные предложения' : 'Лента проектов';

    const handleOnClickAddProject = () => {
        if (profileProjectList.length) {
            showModal(ADD_PROJECT)(dispatch)
        } else if (profileId) {
            setAddProjectSpinnerStatus(true)

            addProject(profileId)(dispatch)
                .then(() => {
                    setAddProjectSpinnerStatus(false)
                    history.push("/projects/add");
                })
                .catch(() => setAddProjectSpinnerStatus(false))
        }

    }

    const HeaderNotification = () => {
        switch (accreditation_status_key) {
            case accStatusKey.notAccredited:
                return (
                    <ProjectNotification
                        title="Ваш профиль не аккредитован"
                        desc="Для доступа к сервисам платформы вам необходимо пройти аккредитацию."
                    />
                )
            case accStatusKey.returned:
                return (
                    <ProjectNotification
                        title="Ваш профиль не аккредитован"
                        desc="Ваш профиль не прошел проверку. Доступ к сервисам платформы ограничен. Внесите изменения в соответствии с замечаниями."
                    />
                )
            case accStatusKey.checking:
                return (
                    <ProjectNotification
                        title="Аккредитация профиля"
                        desc="Ваш профиль находится на проверке. Доступ к сервисам платформы будет доступен после прохождения аккредитации."
                    />
                )
            case accStatusKey.rejected:
                return <ProjectNotification error={true}/>
            case accStatusKey.accredited:
                return legal_form_type === 'indiv' && qualification_status_key === qualifiedStatusKey.notQualified
                    ? <ProjectNotification
                        qualification
                        title="Статус квалифицированного инвестора не подтвержден"
                        desc="К сожалению, ваше заявление не прошло проверку. Повторно загрузите документы, подтверждающие соблюдение условия для получения статуса квалифицированного инвестора."
                    />
                    : null
            default:
                return null
        }
    }

    const HeaderContent = () => {
        if (accreditation_status_key !== accStatusKey.accredited) return null;

        switch (role_key) {
            case 'investor':
                return <ProjectsFeedBalance/>
            case 'borrower':
                return <ProjectsFeedInfo
                    btnSpinnerStatus={addProjectSpinnerStatus}
                    Icon={AddRectIcon}
                    BtnIcon={AddRectIcon}
                    btnTitle="Добавить проект"
                    disabledBtn={!(accreditation_status_key === accStatusKey.accredited)}
                    btnClassName="projectsFeed__info_btnGradient"
                    btnOnClick={handleOnClickAddProject}
                    desc="Создайте свой инвестиционный проект и привлеките средства для вашего бизнеса"
                />
            default:
                break;
        }
    }

    return (
        <div className="projectsFeed__header">
            <HeaderNotification/>
            <HeaderContent/>
            <h1 className="page-title">{title}</h1>
        </div>
    )
}

export default ProjectsFeedHeader;
import React, {useState} from 'react';
import {Collapse} from 'reactstrap';
import {NotificationIcon} from '@shared/img/iconSvg'

let notificationsInit = [
    {id: 0, ava: NotificationIcon, name: '', message: '', date: ''},
    {id: 1, ava: NotificationIcon, name: '', message: '', date: ''},
    {id: 2, ava: NotificationIcon, name: '', message: '', date: ''},
    {id: 3, ava: NotificationIcon, name: '', message: '', date: ''},
    {id: 4, ava: NotificationIcon, name: '', message: '', date: ''},
    {id: 5, ava: NotificationIcon, name: '', message: '', date: ''},
    {id: 6, ava: NotificationIcon, name: '', message: '', date: ''},
    {id: 7, ava: NotificationIcon, name: '', message: '', date: ''},
    {id: 8, ava: NotificationIcon, name: '', message: '', date: ''},
    {id: 9, ava: NotificationIcon, name: '', message: '', date: ''},
];

const TopbarNotification = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [notifications, setNotifications] = useState(notificationsInit)

    const toggleNotification = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleClearMessage = () => {
        setNotifications([])
    }

    return (
        <div className="topbar__collapse_wrap topbar__notification">
            <div className="topbar__collapse_icon">
                <span className="icon__notification_count">{notifications.length || 0}</span>
                <NotificationIcon/>
            </div>
            {isCollapsed && <button className="topbar__back" type="button" onClick={toggleNotification}/>}
            <Collapse isOpen={isCollapsed} className="topbar__collapse-content">
                <div className="topbar__collapse-title-wrap">
                    <p className="topbar__collapse-title">Уведомления</p>
                    <button
                        className="topbar__collapse-button"
                        type="button"
                        onClick={handleClearMessage}
                        disabled={!!!notifications.length}
                    >
                        Очистить
                    </button>
                </div>
                {!notifications.length
                    ? notifications.map(notification => (
                        <div className="topbar__collapse-item" key={notification.id}>
                            <div className="topbar__collapse-img-wrap">
                                <img className="topbar__collapse-img" src={notification.ava} alt=""/>
                            </div>
                            <p className="topbar__collapse-name">{notification.name}</p>
                            <p className="topbar__collapse-message topbar__collapse-message--mail">{notification.message}</p>
                            <p className="topbar__collapse-date">{notification.date}</p>
                        </div>
                    ))
                    : null
                }
            </Collapse>
        </div>
    );
};

export default TopbarNotification;

import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {ModerationsCheckService, ModerationsService} from "@services/moderator.service";
import {useHistory} from "react-router-dom";
import {showModal} from "@actions/modalActions";
import {MODAL_NOTIFICATION, MODERATOR_PROJECT_REJECT} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";
import {SET_MODERATION_PROFILE_ID} from "@actions/moderationAction";

const ModerationProjectFinishBtn = ({
                                        projectId, remarkList = [], government, analytic, moderationId,
                                        pledges, rate, role, condition, showResult, moderationStatus
                                    }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [returnedLoading, setReturnedLoading] = useState(false);
    const [accreditedLoading, setAccreditedLoading] = useState(false);
    const conditionFormat = {...condition};
    const onlyRemarks = remarkList.filter(item => item.type_key !== null);
    conditionFormat.charge_penalty_first_day = condition.charge_penalty_first_day !== 'no';

    const moderationProjectData = {
        remarks: remarkList.filter(item => !item.id),
        government: role === 'entity' ? government : null,
        condition: conditionFormat,
        analytic,
        pledges,
        rate
    }

    const RejectProjectBtn = () => {
        const handleOnClickRejectProject = e => {
            moderationProjectData.type = "projects_rejected";
            showModal(MODERATOR_PROJECT_REJECT, {
                moderationProjectData,
                moderationId,
                showResult
            })(dispatch)
        }

        return <Button color="danger" size="lg" onClick={handleOnClickRejectProject}>
            Отклонить проект
        </Button>
    }

    const returnedProject = () => {
        if (!onlyRemarks.length) {
            showModal(MODAL_NOTIFICATION, 'Укажите замечания')(dispatch)
        } else {
            setReturnedLoading(true);
            moderationProjectData.type = "projects_returned";

            ModerationsCheckService.create(moderationProjectData, projectId)
                .then(() => {
                    setReturnedLoading(false)
                    history.push('/moderation_projects');
                })
                .catch(() => setReturnedLoading(false))
        }
    }

    const transferAnalytics = () => {
        if (onlyRemarks.length) {
            showModal(MODAL_NOTIFICATION, 'Передача аналитику недоступна, если указаны замечания')(dispatch)
        } else {
            setAccreditedLoading(true);
            moderationProjectData.type = "projects_collecting";

            ModerationsCheckService.create(moderationProjectData, projectId)
                .then(() => {
                    setAccreditedLoading(false)
                    history.push('/moderation_projects');
                })
                .catch(() => setAccreditedLoading(false))
        }
    }

    // Результаты модерации проекта
    if (showResult) {
        if ([
            'projects_payment_stage', 'projects_delay', 'projects_completed', 'is_draft',
            'projects_not_carried_out', 'projects_canceled', 'projects_checking'
        ].includes(moderationStatus) || !moderationStatus) return null

        const RecheckBtn = () => {
            const handleOnClickRecheck = e => {
                const params = {
                    type: "projects_checking",
                    remarks: remarkList.filter(item => item.type_key === null && !item.id).map(item => ({message: item.message}))
                }

                ModerationsService.create(params, moderationId)
                    .then(res => {
                        dispatch({type: SET_MODERATION_PROFILE_ID, payload: projectId})
                        history.push(`/moderation_projects/${projectId}`);
                    })
                    .catch(error => console.log(error))
            }

            return <Button color="primary" size="lg" onClick={handleOnClickRecheck}>
                Повторная проверка
            </Button>
        }

        if (['projects_rejected'].includes(moderationStatus)) {
            return <div className="moderationProjectContent__controls">
                <RecheckBtn/>
            </div>
        }

        if (['projects_returned', 'projects_collecting', 'projects_signing_stage'].includes(moderationStatus)) {
            return <div className="moderationProjectContent__controls">
                <RejectProjectBtn/>
                <RecheckBtn/>
            </div>
        }

    }

    // Модерация проекта
    return <div className="moderationProjectContent__controls">
        <RejectProjectBtn/>
        <Button color="primary" size="lg" onClick={returnedProject}>
            <div className="btn-spinner-group">
                <Spinner className={`btn-spinner ${returnedLoading ? 'btn-spinner-visible' : ''}`}/>
                Отправить на доработку
            </div>
        </Button>
        <Button color="success" size="lg" onClick={transferAnalytics}>
            <div className="btn-spinner-group">
                <Spinner className={`btn-spinner ${accreditedLoading ? 'btn-spinner-visible' : ''}`}/>
                Передать аналитику
            </div>
        </Button>
    </div>
}

export default ModerationProjectFinishBtn;

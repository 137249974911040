import React, {useEffect, useState} from 'react';
import UploadField from "@shared/components/uploadField";
import {SettingsDocumentsService} from "@services/settings.service";
import {FilesService} from "@services/file.service";

const PlatformRatesTariff = () => {
    const [data, setData] = useState(null);
    const [tariffFile, setTariffFile] = useState([]);
    const {platform_rates} = data?.value || {};

    const savePlatformRatesTariff = ({target}) => {
        const dataFormat = {...data, value: {...data.value, platform_rates: target.value[0].id}};

        SettingsDocumentsService.update(null, dataFormat)
            .then(res => setData(res.data))
            .catch(error => console.log(error))
    }

    useEffect(() => {
        SettingsDocumentsService.getList()
            .then(res => setData(res.data.data))
            .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        if (platform_rates) {
            FilesService.getById(platform_rates)
                .then(res => setTariffFile([res.data.data]))
                .catch(error => console.log(error))
        }
    }, [platform_rates, data])

    return data
        ? <div className="platformRates__tariff">
            <UploadField
                edit
                update
                viewDetail
                titleShow
                fileKey="tariff"
                fileType="settings"
                title="Тарифы платформы"
                accept=".pdf, .doc, .docx"
                onChange={savePlatformRatesTariff}
                files={tariffFile}
            />
        </div>
        : null
}

export default PlatformRatesTariff;
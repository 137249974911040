import React from 'react';
import {Button} from "reactstrap";

const ProfileNotAccreditedNotice = ({profileDataEditingStatus, setFormEditStatus, role}) => (
    <div className="accStatusNotice">
        <div className="accStatusNotice__header">
            <p>Ваш профиль не аккредитован</p>
        </div>
        <div className="accStatusNotice__content">
            {
                role === 'borrower'
                    ? <p>
                        Для доступа к сервисам платформы вам необходимо заполнить и подтвердить данные профиля.
                        Наличие КЭП* обязательно.
                    </p>
                    : <p>Для доступа к сервисам платформы вам необходимо заполнить и подтвердить данные профиля.</p>
            }
        </div>
        <div className="accStatusNotice__footer">
            {
                !profileDataEditingStatus
                    ? <Button color="primary" size="lg" onClick={setFormEditStatus}>
                        Заполнить и подтвердить
                    </Button>
                    : null
            }
            {
                role === 'borrower'
                    ? <div className="accStatusNotice__footer_desc">
                        <p>*КЭП-Квалифицированная электронная подпись</p>
                    </div>
                    : null
            }
        </div>
    </div>
)

export default ProfileNotAccreditedNotice;
import React from 'react';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import {changeThemeToDark, changeThemeToLight} from '@redux/actions/themeActions';
import {changeMobileSidebarVisibility, changeSidebarVisibility} from '@redux/actions/sidebarActions';
import {useDispatch, useSelector} from "react-redux";

const Layout = () => {
    const dispatch = useDispatch();
    const sidebar = useSelector(state => state.sidebar)

    const handleChangeSidebarVisibility = () => dispatch(changeSidebarVisibility());
    const handleChangeMobileSidebarVisibility = () => dispatch(changeMobileSidebarVisibility());
    const changeToDark = () => dispatch(changeThemeToDark());
    const changeToLight = () => dispatch(changeThemeToLight());

    const layoutClass = classNames({
        layout: true,
        'layout--collapse': sidebar.collapse,
    });
    return (
        <div className={layoutClass}>
            <Topbar changeMobileSidebarVisibility={handleChangeMobileSidebarVisibility}/>
            <Sidebar
                sidebar={sidebar}
                changeToDark={changeToDark}
                changeToLight={changeToLight}
                changeMobileSidebarVisibility={handleChangeMobileSidebarVisibility}
                changeSidebarVisibility={handleChangeSidebarVisibility}
            />
        </div>
    );
}

export default Layout;

import React from 'react';
import DataCard from "@shared/components/data/DataCard";

const TotalEstimatedCollateral = ({pledgesState}) => {
    const total = pledgesState.pledges.reduce((acc, item) => acc + item.collateral_value || 0, 0);

    const dataFormat = [
        {name: 'Суммарная оценочная стоимость залога:', desc: `${total.toLocaleString()} ₽` || ''},
    ]

    return <DataCard data={dataFormat}/>
}

export default TotalEstimatedCollateral;

import {paymentTypesCsvReport} from "@shared/components/modal/projects/AddProject/data";
import {Table} from "reactstrap";
import React from "react";

export default class PaymentTypesTable extends React.PureComponent {
    render() {
        return (
            <Table className="paymentTypesModal__table" responsive>
                <thead>
                <tr>
                    {
                        paymentTypesCsvReport.headers.map(item => (
                            <th key={item.key}>{item.label}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    paymentTypesCsvReport.data.map((item, idx) => (
                        <tr
                            key={idx}
                            className={idx === paymentTypesCsvReport.data.length - 1 ? 'paymentTypesModal__table_total' : ''}
                        >
                            <td>{item.date}</td>
                            <td>{item.transaction}</td>
                            <td>{item.payment}</td>
                            <td>{item.amortization}</td>
                            <td>{item.percent}</td>
                            <td>{item.balanceOwed}</td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        );
    }
}
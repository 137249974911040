import React from 'react';

const NoticeModal = ({modalData}) => {
    return (
        <div className="moderatorAccModal__newCheck">
            <p className="modalFullScreen__title">
                {modalData ? modalData : null}
            </p>
        </div>
    )
}

export default NoticeModal;
import React, {useEffect, useReducer, useState} from 'react';
import {reducer} from "@shared/components/account/helpers";
import InputText from "@shared/components/form/profileForm/InputText";
import {Col, Row} from "reactstrap";
import {CalendarIcon} from "@shared/img/iconSvg";
import UploadField from "@shared/components/uploadField";
import PrimaryTooltip from "@shared/components/tooltip";

const EditPassportData = ({profileData}) => {
    const {
        issue_date, issued_by, serial_number, department_code, files
    } = profileData?.legal_form?.passport || {};
    const {birth_date} = profileData?.legal_form?.personal || {};
    const [formError, setFormError] = useState([]) // Ошибочные поля
    // eslint-disable-next-line no-unused-vars
    const [_, setFormValidate] = useState(false) // Валидность всех полей формы

    const initialState = {
        issue_date: issue_date || '',
        issued_by: issued_by || '',
        serial_number: serial_number || '',
        department_code: department_code || '',
        files: files ? files : [],
    }

    const [formData, setFormData] = useReducer(reducer, initialState);

    const handleOnChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        })
    }

    useEffect(() => {
        let validateStatus = true;
        let identical = true;

        for (let key in formData) {
            if (('' + formData[key]).length === 0) validateStatus = false
        }

        if (validateStatus) {
            for (let key in formData) {
                if ((initialState[key] !== formData[key])) identical = false
            }

            if (identical) {
                setFormValidate(false)
            } else {
                setFormValidate(true)
            }

        } else {
            setFormValidate(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const issueMaxDate = new Date();
    const issueMinDate = birth_date ? new Date(birth_date) : new Date();

    issueMaxDate.setDate(issueMaxDate.getDate() - 1);

    if (!birth_date) {
        issueMinDate.setFullYear(1900)
    }

    return (
        <div className="profileTabs__edit">
            <InputText
                type="mask"
                name="serial_number"
                id="serial_number"
                label="Серия и номер паспорта"
                onChange={handleOnChange}
                value={formData.serial_number}
                validator={[{required: true}, {numbersCount: 10}]}
                mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                formError={formError}
                setFormError={setFormError}
            />
            <Row>
                <Col md={6}>
                    <InputText
                        type="date"
                        name="issue_date"
                        icon={CalendarIcon}
                        maxDate={issueMaxDate}
                        minDate={issueMinDate}
                        label="Дата выдачи"
                        onChange={handleOnChange}
                        value={formData.issue_date}
                        validator={[{required: true}]}
                        containerClassName="date-picker profileTabs__edit_item"
                        formError={formError}
                        setFormError={setFormError}
                    />
                </Col>
                <Col md={6}>
                    <InputText
                        type="mask"
                        name="department_code"
                        label="Код подразделения"
                        onChange={handleOnChange}
                        value={formData.department_code}
                        validator={[{required: true}, {numbersCount: 6}]}
                        mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                        containerClassName="profileTabs__edit_item"
                        formError={formError}
                        setFormError={setFormError}
                    />
                </Col>
            </Row>
            <InputText
                type="text"
                name="issued_by"
                label="Кем выдан"
                onChange={handleOnChange}
                value={formData.issued_by}
                validator={[{required: true}, {maxLength: 300}]}
                containerClassName="profileTabs__edit_item"
                formError={formError}
                setFormError={setFormError}
            />
            <div className="inputText__wrapper profileTabs__edit_item">
                <label>
            <span className="position-relative">
              Сканы паспорта
              <PrimaryTooltip name="passportScanEditTooltip" title="?" className="profileTabs__tabItem_tooltip">
                <p>Первая страница паспорта и страница с регистрацией</p>
              </PrimaryTooltip>
            </span>
                </label>
                <UploadField
                    viewDetail
                    files={formData.files}
                    edit={true}
                    multiple={true}
                    maxFiles={10}
                    title="паспорт"
                    onChange={handleOnChange}
                    fileKey="files"
                    fileType="passport"
                    accept=".jpeg, .jpg, .png, .pdf"
                />
            </div>
        </div>
    )
}

export default EditPassportData;
/*const emailData = [
    {id: 0, info: 'ivanov_ivan@gmail.com', code: '123456', date: '22.11.21', time: '18:00'},
    {id: 1, info: 'ivanov_ivan@gmail.com', code: '123456', date: '22.11.21', time: '18:00'},
    {id: 2, info: 'ivanov_ivan@gmail.com', code: '123456', date: '22.11.21', time: '18:00'},
    {id: 3, info: 'ivanov_ivan@gmail.com', code: '123456', date: '22.11.21', time: '18:00'},
    {id: 4, info: 'ivanov_ivan@gmail.com', code: '123456', date: '22.11.21', time: '18:00'},
]

const phoneData = [
    {id: 0, info: '+7 (925) 785-66-33', code: '123456', date: '22.11.21', time: '18:00'},
    {id: 1, info: '+7 (925) 785-66-33', code: '123456', date: '22.11.21', time: '18:00'},
    {id: 2, info: '+7 (925) 785-66-33', code: '123456', date: '22.11.21', time: '18:00'},
    {id: 3, info: '+7 (925) 785-66-33', code: '123456', date: '22.11.21', time: '18:00'},
    {id: 4, info: '+7 (925) 785-66-33', code: '123456', date: '22.11.21', time: '18:00'},
]*/

export const tableHeader = {
    email: [
        {id: 0, title: 'Email'},
        {id: 1, title: 'Код'},
        {id: 2, title: 'Дата и время'},
    ],
    phone: [
        {id: 0, title: 'Телефон'},
        {id: 1, title: 'Код'},
        {id: 2, title: 'Дата и время'},
    ]
}
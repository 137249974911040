import React, {useEffect, useState} from 'react';
import Masonry from "react-masonry-css";
import ApplicationData from "@shared/components/presentation/Qualification/ApplicationData";
import AccountPersonalData from "@shared/components/account/accountPersonalData";
import AccountPassportData from "@shared/components/account/accountPassportData";
import {useDispatch, useSelector} from "react-redux";
import {getClientAccountData} from "@actions/innerUsersActions";
import {QualificationsProfileService} from "@services/qualifications.service";
import {Button} from "reactstrap";
import {NOTICE_MODAL, QUALIFIED_NEW_CHECK} from "@shared/components/modal/constants";
import QualificationsSelectedConditions
    from "@containers/Moderator/VerificationQualification/QualificationsSelectedConditions";

const QualificationApplication = ({modalData, modalType, showModal, hideModal, setModalData}) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    const [_, setQualifications] = useState([]);
    const applicationId = modalData;
    const {data} = useSelector(state => state.innerUsers.clientAccount);
    const {
        qualification, qualification_status_key, qualification_status, qualified_at, user, isBanned
    } = data || {};

    const startNewCheck = () => {
        if (user?.isBanned) {
            showModal(NOTICE_MODAL, 'Аккаунт профиля заблокирован')

            return;
        }

        if (isBanned) {
            showModal(NOTICE_MODAL, 'Профиль заблокирован')(dispatch)

            return;
        }

        showModal(QUALIFIED_NEW_CHECK, {
            qualificationId: qualification.id,
            qualificationStatus: qualification_status_key
        })(dispatch)
    }

    useEffect(() => {
        if (applicationId) {
            getClientAccountData(applicationId)(dispatch)
            QualificationsProfileService.getById(applicationId, {include: 'profile'})
                .then(res => setQualifications(res?.data?.data || []))
                .catch(error => console.log(error))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId])

    return (
        <div className="qualificationApplication">
            <p className="modalFullScreen__title">
                Заявление о признании физ. лица квалифицированным инвестором
            </p>
            <div className="qualifiedInvestor__info profileTabs">
                <Masonry
                    breakpointCols={{default: 2, 1400: 1}}
                    className="my-masonry-grid custom-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    <AccountPersonalData
                        qualifiedApplication
                        editForm={false}
                        profileData={data}
                        title='Персональные данные заявителя'
                    />
                    <AccountPassportData
                        editForm={false}
                        profileData={data}
                        title='Паспортные данные заявителя'
                    />
                    <ApplicationData
                        data={{
                            ...qualification,
                            profile: {qualification_status_key, qualification_status, qualified_at}
                        }}
                    />
                    <QualificationsSelectedConditions data={qualification?.conditions?.conditions || []}/>
                </Masonry>
            </div>
            {
                qualification_status_key === 'qualification_qualified'
                    ? <div className="qualification__results_control">
                        <Button color="primary" size="lg" onClick={startNewCheck}>
                            Начать проверку
                        </Button>
                    </div>
                    : null
            }
        </div>
    )
};

export default QualificationApplication;
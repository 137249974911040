import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Button, Spinner} from "reactstrap";

const WithdrawFunds = ({showModal}) => {
    const balance = 1_000_000;
    let toNumber, toLocale, valueNumber, lastCharacter;
    const [sum, setSum] = useState('');
    const balanceLocale = balance.toLocaleString();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState(null);
    const [spinnerStatus, setSpinnerStatus] = useState(false);
    const profiles = useSelector(state => state.profiles.profiles);
    const bankName = profiles[0]?.bank_details[0]?.name || 'ПАО «Сбербанк»';
    const score = profiles[0]?.bank_details[0]?.checking_account || '************';

    const handleOnChangeSum = ({target}) => {
        lastCharacter = +target.value[target.value.length - 1];

        // Валидация поля ввода
        if (/^\d+$/.test(lastCharacter)) {
            valueNumber = +target.value.replace(/\s/g, '');

            if (valueNumber < balance) {
                if (errorMessage) setErrorMessage(null);
                toNumber = Number(target.value.replace(/\D/g, ''));
                toLocale = toNumber.toLocaleString();

                setSum(target.value ? toLocale : '');
            } else if (!errorMessage) {
                setErrorMessage(`Максимальная сумма вывода:  ${balanceLocale} ₽`)
            }
        } else if (target.value.length === 0) {
            if (errorMessage) setErrorMessage(null);
            setSum('')
        }
    }

    const goToAccountPage = () => {
        history.push('/account?bank_details');
        showModal()
    }

    const sendRequest = () => {
        setSpinnerStatus(true)
    }

    return (
        <div className="balance__modal withdrawFunds">
            <p className="modalFullScreen__title">
                Вывод средств
            </p>
            <div className="withdrawFunds__sumGroup">
                <div className="withdrawFunds__input">
                    <div className="withdrawFunds__input_inner">
                        <span>Укажите сумму вывода </span>
                        <input
                            type="text"
                            value={sum}
                            onChange={handleOnChangeSum}
                            placeholder="100 000 000 ₽"
                            autoComplete="off"
                        />
                    </div>
                    {errorMessage && <span className="form__form-error">{errorMessage}</span>}
                </div>
                <div className="withdrawFunds__maxSum">
                    <p>Максимальная сумма вывода: <span> {`${balanceLocale} ₽`}</span></p>
                </div>
            </div>
            <div className="balance__modal_requisites withdrawFunds__requisites">
                <p className="balance__modal_title">Банковский счет, на который будут выводиться средства</p>
                <ul>
                    <li>Наименование банка: <span>{bankName}</span></li>
                    <li>Счет №: <span>{score}</span></li>
                </ul>
            </div>
            <p className="withdrawFunds__changeAccount">
                Изменить счет можно в
                <span onClick={goToAccountPage}> настройках профиля</span>
            </p>
            <div className="withdrawFunds__btnGroup">
                <Button
                    color="primary"
                    className="account__btn_primary"
                    onClick={sendRequest}
                >
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                        Отправить заявку
                    </div>
                </Button>
                <Button
                    onClick={() => showModal()}
                    color="primary"
                    className="account__btn_cancel"
                    outline
                >
                    Отмена
                </Button>
            </div>
        </div>
    )
}

export default WithdrawFunds;
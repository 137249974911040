export const handleFilesValidate = (code, setErrorMessage) => {
    switch (code) {
        case "too-many-files":
            setErrorMessage('Максимум 5 файлов!')
            break;
        case "file-invalid-type":
            setErrorMessage('Формат не поддерживается. Загрузите изображения в JPEG, JPG, PNG!')
            break;
        case "file-too-large":
            setErrorMessage('Максимальный размер файла 5 MB!')
            break;
        default:
            break;
    }
}

// Цвет границы области загрузки файла
export const getColor = (props) => {
    if (props.isDragAccept) {
        return '#58BF8F';
    }
    if (props.isDragReject) {
        return '#FC8473';
    }
    if (props.isDragActive) {
        return '#58BF8F';
    }
    if (props.errorMessage) {
        return '#FC8473!important';
    }
    return '#DFDFDF';
}

// Получить название и тип файла
export const getFullFileName = (type, name) => {
    if (typeof type === 'string') {
        const fileType = type.split("/");
        if (fileType.length) return `${name}.${fileType[1]}`
    }

    return name
}

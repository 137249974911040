import React from 'react';
import CheckIcon from "mdi-react/CheckIcon";
import classNames from "classnames";


const CustomCheckBox = ({
                            type = 'checkbox', name = '', value = false, label = '', onChange, id = 'checkbox',
                            className = '', inputKey = '', disabled = false
                        }) => {

    const handleOnChange = e => {
        onChange({target: {name: name, value: e.target.checked}})
    }

    const labelClass = classNames({
        [className]: className,
        'checkbox-btn': true,
        'checkbox-btn--colored': true,
        'checkbox-btn-disabled': disabled,
    });

    return (
        <label className={labelClass} htmlFor={id} key={inputKey}>
            <input
                className="checkbox-btn__checkbox"
                type={type}
                name={name}
                id={id}
                onChange={handleOnChange}
                checked={value}
                disabled={disabled}
            />
            <span className="checkbox-btn__checkbox-custom" style={
                value
                    ? {background: '#58BF91', borderColor: '#58BF91'}
                    : {background: 'rgba(135, 151, 182, 0.19)', borderColor: 'rgba(135, 151, 182, 0.19)'}
            }>
          <CheckIcon/>
        </span>
            <span className="checkbox-btn__label checkbox__label-accept">{label}</span>
        </label>
    );
}

export default CustomCheckBox;
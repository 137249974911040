import React from 'react';
import {useHistory} from "react-router-dom";
import {Button} from "reactstrap";
import {accStatusKey} from "@utils/statusKey";
import RemarksNotice from "@shared/components/account/accStatusNotice/general/RemarksNotice";
import {showModal} from "@actions/modalActions";
import {APPLICATION_QUALIFIED_ARCHIVE, INNER_USERS_QUALIFIED_APPLICATION} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";
import {qualifiedStatusKey} from "@utils/auth";
import ProfileIsBanned from "@shared/components/account/accStatusNotice/general/ProfileIsBanned";

const ClientAccountHeaderNotice = ({data = {}, profileId}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        accreditation_status_key, legal_form_type, accreditation, isBanned, ban, qualification, qualification_status_key
    } = data;

    const handleOnClickShowApplication = () => {
        showModal(INNER_USERS_QUALIFIED_APPLICATION, data?.qualification?.id || null)(dispatch)
    }

    const showApplicationResults = () => {
        history.push(`/qualification_results/${profileId}`);
    }

    const showApplicationArchiveModal = () => {
        showModal(APPLICATION_QUALIFIED_ARCHIVE, profileId)(dispatch)
    }

    const QualificationStatus = () => {
        switch (qualification_status_key) {
            case qualifiedStatusKey.qualified:
                return <span className="statusInfo statusInfo__success">Квалификация подтверждена</span>
            case qualifiedStatusKey.checking:
                return <span className="statusInfo statusInfo__checking">На проверке</span>
            case qualifiedStatusKey.notQualified:
                return <span className="statusInfo statusInfo__not">Квалификация не подтверждена</span>
            case qualifiedStatusKey.rejected:
                return <span className="statusInfo statusInfo__rejected">Отклонен</span>
            default:
                return null
        }
    }

    const AccStatusNotice = () => {
        if (isBanned) return <ProfileIsBanned comment={ban?.ban_comment || ''}/>

        if (accreditation_status_key === accStatusKey.returned) {
            return (
                <div className="accStatusNotice clientAccount__notice">
                    <div className="accStatusNotice__header">
                        <p>Профиль возвращен на доработку</p>
                    </div>
                    <RemarksNotice data={accreditation.remarks}/>
                </div>
            )
        }

        if (accreditation_status_key === accStatusKey.rejected) {
            return (
                <div className="accStatusNotice accStatusNotice__error clientAccount__notice">
                    <div className="accStatusNotice__header">
                        <p>Профиль отклонен</p>
                    </div>
                    <div className="accStatusNotice__content">
                        <RemarksNotice data={accreditation.remarks}/>
                    </div>
                </div>
            )
        }

        return null
    }

    const QualificationNotice = () => {
        return accreditation_status_key === accStatusKey.accredited && legal_form_type === 'indiv' && qualification
            ? <div className="accStatusNotice clientAccount__notice">
                <div className="accStatusNotice__header">
                    <p>Подтверждение статуса квалифицированного инвестора</p>
                </div>
                <div className="clientAccount__notice_content d-flex align-items-center">
                    <span className="accStatusNotice__number">{`№${qualification.id}`}</span>
                    <QualificationStatus/>
                </div>
                <div className="accStatusNotice__footer">
                    <div className="accStatusNotice__btnGroup">
                        <Button color="primary" size="lg" onClick={handleOnClickShowApplication}>
                            Открыть заявление
                        </Button>
                        <Button outline color="success" size="auto" onClick={showApplicationResults}>
                            Результаты проверки заявления о признании квал. инвесторами платформы
                        </Button>
                        <Button outline color="success" size="md" onClick={showApplicationArchiveModal}>
                            Архив заявлений
                        </Button>
                    </div>
                </div>
            </div>
            : null
    }

    return <>
        <QualificationNotice/>
        <AccStatusNotice/>
    </>
}

export default ClientAccountHeaderNotice;
import React from 'react';
import CropImage from "@shared/components/camera/CropImage";

const SelectImageModal = ({modalData, hideAllModals, hideModal}) => {

    return (
        <CropImage image={modalData} hideAllModals={hideAllModals} hideModal={hideModal}/>
    )
}

export default SelectImageModal;
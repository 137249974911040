import React, {useState} from 'react';
import EditAdditionalInfo from "@shared/components/account/additionalInfo/EditAdditionalInfo";
import {
    ADDITIONAL_INFO_EDIT,
    ADDITIONAL_INFO_EDIT_ADD_FACE,
    ADDITIONAL_INFO_EDIT_ADD_FILES,
    ADDITIONAL_INFO_EDIT_DELETE_FACE,
    ADDITIONAL_INFO_EDIT_DELETE_FILES,
    SET_ADDITIONAL_INFO_ERROR,
} from "@shared/components/account/EditProfile/helpers/constants";
import {AddFaceBtn} from "@shared/components/account/additionalInfo/helpers";

const AdditionalInfo = ({data, formError, dispatch, errorDispatch, number, resetValidate, additionalInfo}) => {
    const personCount = Object.keys(data).length;
    const form = additionalInfo[number].form;
    const values = additionalInfo[number].values;
    const [formValidate, setFormValidate] = useState(false);
    const constants = {
        edit: ADDITIONAL_INFO_EDIT,
        addFiles: ADDITIONAL_INFO_EDIT_ADD_FILES,
        deleteFiles: ADDITIONAL_INFO_EDIT_DELETE_FILES,
        addFace: ADDITIONAL_INFO_EDIT_ADD_FACE,
        deleteFace: ADDITIONAL_INFO_EDIT_DELETE_FACE,
    };

    const handleOnChange = (e, person) => {
        dispatch({
            type: constants.edit,
            payload: {
                number,
                data: {
                    name: e.target.name,
                    value: e.target.value,
                    person
                },
            }
        })
    }

    const setFormError = (error) => {
        errorDispatch({
            type: SET_ADDITIONAL_INFO_ERROR,
            payload: {
                number,
                value: error,
            }
        })
    }

    const setFormData = (payload) => {
        dispatch({
            type: constants[payload.type],
            payload: {
                number,
                data: payload.payload
            }
        })
    }

    const handleAddFace = () => {
        const addFaceData = {};

        values.forEach(item => {
            addFaceData[item.field.key] = {
                field_id: item.field_id,
                person: personCount,
                value: item.field.default,
                field: item.field,
            }
        });

        dispatch({
            type: constants.addFace,
            payload: {
                number,
                data: {...addFaceData},
                personId: personCount,
            }
        })
    }


    const renderEditForm = () => {
        const formItems = [];
        let personNumber = -1;

        for (let key in data) {
            ++personNumber
            formItems.push(<EditAdditionalInfo
                key={key}
                setFormValidate={setFormValidate}
                formError={formError}
                setFormError={setFormError}
                formDataItem={data[key]}
                handleOnChange={handleOnChange}
                setFormData={setFormData}
                personNumber={personNumber}
                resetValidate={resetValidate}
            />);
        }

        return formItems;
    }

    return (
        <div className="profileTabs__tabItem">
            <div className="profileTabs__tabItem_title">
                <span>{form?.question}</span>
            </div>
            <div className="profileTabs__tabItem_list">


                {
                    Object.keys(data).map((key, personNumber) => {
                        if (key !== "id") {
                            return <EditAdditionalInfo
                                key={key}
                                setFormValidate={setFormValidate}
                                formError={formError}
                                setFormError={setFormError}
                                formDataItem={data[key]}
                                handleOnChange={handleOnChange}
                                setFormData={setFormData}
                                personNumber={personNumber}
                                resetValidate={resetValidate}
                            />
                        }
                    })
                }
                <AddFaceBtn
                    some={form.some}
                    formData={data}
                    handleAddFace={handleAddFace}
                    disabled={!formValidate || !!formError.length}
                />
            </div>
        </div>
    );
};

export default AdditionalInfo;
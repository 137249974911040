import {AuthService} from '@services/auth.service'

/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
function _getStatusMessage(status) {
    switch (status) {
        case 200:
            return 'All done. Request successfully executed'
        case 201:
            return 'Data successfully created'
        case 400:
            return 'Bad Request'
        case 401:
            return 'Need auth'
        case 404:
            return 'Not found'
        case 422:
            return 'Validation'
        case 503:
            return 'Service unavailable. Try again later'
        default:
            return 'Something wrong. Client default error message'
    }
}

/**
 * Create instant, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
 */
export class ResponseWrapper {
    constructor(response, data = {}, message) {
        this.data = data
        this.success = response.data.success
        this.headers = response.headers
        this.status = response.status
        this.statusMessage = _getStatusMessage(this.status)
        this.message = message || null
    }
}

/**
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
export class ErrorWrapper extends Error {
    constructor(error, message) {
        super()
        this.success = error.response ? error.response.data.success : false
        this.meta = error.response ? error.response.data.meta : false
        this.code = error.response ? error.response.data.code : false
        this.status = error.response ? error.response.status : false
        this.errors = error.response && error.response.data.errors ? error.response.data.errors : false
        this.statusMessage = _getStatusMessage(this.status)
        this.message = message || _getResponseErrorMessage(error)
        this.checkStatus(this.status)
    }

    checkStatus(status) {
        switch (status) {
            case 404:
            case 500:
                //Уведомлние об ошибке
                break;
            case 401:
                AuthService.redirectToLogin('Ошибка авторизации!')
                break;
            default:
                /*AuthService.redirectToLogin('Неизвестная ошибка!')*/
                break;
        }
    }
}

function _getResponseErrorMessage(error) {
    if (error.response && error.response.data) return error.response.data.message
    if (error.response && error.response.statusText) return error.response.statusText
    return error.message === 'Network Error' ? 'Oops! Network Error. Try again later' : error.message
}
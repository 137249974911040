import React from 'react';

const ModalNotification = ({modalData}) => {
    const message = modalData;

    if (!message) return null

    return (
        <div className="moderatorAccModal__newCheck">
            <p className="modalFullScreen__title">
                {message}
            </p>
        </div>
    )
};

export default ModalNotification;
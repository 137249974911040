import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Col, Container, Row} from "reactstrap";
import AddProjectControlButtons from './AddProjectControlButtons';
import ProjectStatusNotification from "@containers/Projects/AddProject/ProjectStatusNotification";
import {DocumentEditIcon, DocumentRightArrow} from "@shared/img/iconSvg";
import {ADD_PROJECT} from "@shared/components/modal/constants";
import {getProject} from "@actions/projectsActions";
import {showModal} from "@actions/modalActions";
import {get} from "@utils/storage";
import StepsSidebar from "@shared/components/layout/StepsSidebar";
import {stepList} from './helpers';
import {accStatusKey} from "@utils/statusKey";

const AddProject = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const {accreditation_status_key} = useSelector(state => state.user.profile);
    const [visibleProjectAddStatus, setVisibleProjectAddStatus] = useState(false);
    const [projectIsDraft, setProjectIsDraft] = useState(false);
    const projectEditData = useSelector(state => state.projects.projectEditData);

    useEffect(() => {
        // Если перешли на страницу добавления проекта,
        // но профиль не аккредитован, перенаправить на страницу "Лента проектов"
        if (accreditation_status_key && !(accreditation_status_key === accStatusKey.accredited)) {
            history.push('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // Если нет данных о проекте, то запросить
        if (!Object.keys(projectEditData).length) {
            const projectId = get('editProjectId');

            if (projectId) {
                getProject(projectId)(dispatch)
            } else {
                history.push('/');
                showModal(ADD_PROJECT)(dispatch);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container>
            <div className="addProject__page">
                {
                    !visibleProjectAddStatus
                        ? <>
                            <Row>
                                <Col md={12}>
                                    <h1 className="page-title">Добавление проекта</h1>
                                </Col>
                            </Row>
                            <div className="addProject__row">
                                <div className="profile__col addProject__sidebar">
                                    <div className="addProject__sidebar_inner">
                                        <div className="addProject__steps_wrap addProject__wrap">
                                            <StepsSidebar step={step} setStep={setStep} stepList={stepList}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile__col">
                                    <AddProjectControlButtons
                                        step={step}
                                        setStep={setStep}
                                        setProjectIsDraft={setProjectIsDraft}
                                        setVisibleProjectAddStatus={setVisibleProjectAddStatus}
                                    />
                                </div>
                            </div>
                        </>
                        : projectIsDraft
                            ? <ProjectStatusNotification
                                Icon={DocumentEditIcon}
                                title="Ваш проект сохранен как черновик"
                                desc="Подробнее о ваших проектах настранице Ваш портфель"
                                onClick={() => history.push("/portfolio")}
                                btnTitle="Перейти в портфель"
                            />
                            : <ProjectStatusNotification
                                Icon={DocumentRightArrow}
                                title="Ваш проект отправлен на проверку"
                                desc="Отслеживайте информацию на странице Ваш портфель"
                                onClick={() => history.push("/portfolio")}
                                btnTitle="Перейти в портфель"
                            />
                }

            </div>
        </Container>
    )
}

export default AddProject;

import React from 'react';
import classnames from "classnames";
import CheckDataToDisplay from "@shared/components/presentation/CheckDataToDisplay";

const TabLinks = ({data, activeTab, setActiveTab}) => {
    const toggle = ({target}) => {
        if (activeTab !== target.id) setActiveTab(target.id);
    };

    return (
        <div className="tabLinks">
            <CheckDataToDisplay data={data}>
                {
                    data.map(item => (
                        <span
                            key={item.id}
                            id={item.key}
                            className={classnames({'tabLinks__item': true, active: activeTab === item.key})}
                            onClick={toggle}
                        >
                            {item.title}
                        </span>
                    ))
                }
            </CheckDataToDisplay>
        </div>
    )
};

export default TabLinks;
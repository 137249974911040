import React, {useState} from 'react';
import {controlTabsData} from './helpers';
import AccreditationUserList from './AccreditationUserList';
import ControlTabs from "@shared/components/tabs/ControlTabs";

const AccreditationList = React.memo(() => {
    const [accType, setAccType] = useState('investor');

    return (
        <div className="accreditation">
            <h1 className="page-title">Аккредитация и квалификация</h1>
            <div className="accreditation__row accreditation__content">
                <ControlTabs data={controlTabsData} activeTab={accType} setActiveTab={setAccType}/>
                <AccreditationUserList accType={accType}/>
            </div>
        </div>
    )
});

export default AccreditationList;
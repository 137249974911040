import React from 'react';
import {string} from 'prop-types';
import {projectCardStatusColor, projectCardStatusDesc} from './helpers';

const ProjectCardStatus = ({status}) => {
    if (!status) return null

    return <span className="draftProjects__notification" style={{backgroundColor: projectCardStatusColor[status]}}>
        {projectCardStatusDesc[status]}
    </span>
};

ProjectCardStatus.propTypes = {
    status: string.isRequired
};

export default ProjectCardStatus;
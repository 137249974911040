import React, {useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import UploadField from "@shared/components/uploadField";
import {useDispatch} from "react-redux";
import {SET_PROPERTY_VALUE} from "@actions/projectsActions";
import Select from "react-select";
import {propertyBuildingNumberSelectOptions, propertyContracts, propertyPremiseContractSelectOptions} from "../../data";
import {getPropertyContract} from "@containers/Projects/AddProject/forms/Pledge/helpers";

const Building = ({data, setData, formError, setFormError, pledgeId, projectId}) => {
    const dispatch = useDispatch();
    const [numberType, setNumberType] = useState({value: 'cadastral', label: 'Кадастровый номер'});
    const [contractType, setContractType] = useState({value: null, label: 'Имущественные права отсутствуют'});
    const {
        address, documents, floors_number, images, number,
        object_composition, other_information, plans, total_area, contract
    } = data;

    const handleOnChangeSelect = (key, data, setSelect) => {
        setSelect(data);
        setData({target: {name: key, value: data.value}})
    }

    const handleOnChangeContract = data => {
        setContractType(data);
        dispatch({
            type: SET_PROPERTY_VALUE,
            payload: {
                pledgeId,
                typeKey: 'building',
                name: 'contract_type',
                value: data.value
            }
        })

        setData({
            target: {
                name: 'contract',
                value: propertyContracts[data.value] || null
            }
        })
    }

    return (
        <>
            <p className="addProject__form_title font-weight-normal">Здание</p>
            <InputText
                formError={formError}
                name="address"
                id="addressInput"
                onChange={setData}
                label="Адрес"
                setFormError={setFormError}
                type="text"
                value={address || ''}
                validator={[{required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <div className="pledge__inputGroup inputText__wrapper">
                <label className="requiredMark">Номер</label>
                <div className="pledge__inputGroup_inner pledge__numberType">
                    <div className="pledge__formSelect">
                        <div className="pledge__formSelect_wrap">
                            <Select
                                options={propertyBuildingNumberSelectOptions}
                                value={numberType}
                                isSearchable={false}
                                className="pledge__select"
                                classNamePrefix="pledge__select"
                                onChange={data => handleOnChangeSelect('number_type', data, setNumberType)}
                            />
                        </div>
                    </div>
                    <InputText
                        formError={formError}
                        name="number"
                        id="numberInput"
                        onChange={setData}
                        noLabel={true}
                        setFormError={setFormError}
                        type="text"
                        value={number || ''}
                        validator={[{maxLength: 50}, {required: true}]}
                        containerClassName="addProject__fullInput mt-0"
                    />
                </div>
            </div>
            <InputText
                name="total_area"
                id="totalAreaInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Общая площадь"
                value={total_area || ''}
                labelClass="requiredMark"
                validator={[{maxLength: 10}, {onlyNumbers: true}, {required: true}]}
                inputWithDesc
                inputWithDescValue={<>м<sup>2</sup></>}
                containerClassName="addProject__middleInput"
            />
            <InputText
                formError={formError}
                name="object_composition"
                id="objectCompositionInput"
                onChange={setData}
                label="Состав объекта (описание согласно правоустанавливающему документу)"
                setFormError={setFormError}
                type="text"
                value={object_composition || ''}
                validator={[{required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                name="floors_number"
                id="floorsNumberInput"
                onChange={setData}
                label="Этажность"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={floors_number || ''}
                validator={[{maxLength: 3}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Технический план (скан)</label>
                <UploadField
                    files={plans}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Технический план"
                    onChange={setData}
                    fileKey="plans"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <InputText
                rows={2}
                type="textarea"
                onChange={setData}
                formError={formError}
                name="other_information"
                value={other_information || ''}
                id="otherInformationInput"
                setFormError={setFormError}
                validator={[{maxLength: 500}]}
                label="Иные сведения"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Иные документы</label>
                <UploadField
                    files={documents}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Иные документы"
                    onChange={setData}
                    fileKey="documents"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Фото залога</label>
                <UploadField
                    files={images}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Фото залога"
                    onChange={setData}
                    fileKey="images"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png"
                    required={true}
                />
            </div>
            <div className="inputText__wrapper addProject__fullInput">
                <label className="requiredMark">Имущественные права на земельный участок</label>
                <div className="pledge__formSelect">
                    <div className="pledge__formSelect_wrap">
                        <Select
                            options={propertyPremiseContractSelectOptions}
                            value={contractType}
                            isSearchable={false}
                            className="pledge__select"
                            classNamePrefix="pledge__select"
                            onChange={handleOnChangeContract}
                        />
                    </div>
                </div>
            </div>
            {
                getPropertyContract(
                    contractType.value, contract, formError, setFormError, pledgeId, 'building', projectId
                )
            }
        </>
    )

}

export default Building;
import React, {useEffect, useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import IconBtn from "@shared/components/form/IconBtn";
import {
    ADD_MEETING,
    REMOVE_MEETING,
    SET_MEETING_ERROR,
    SET_MEETING_LIST_VALUE,
    SET_MEETING_PARTICIPANTS_ERROR
} from "../helpers";
import classnames from 'classnames';

const Meeting = ({state, dispatch, governingValidStatus}) => {
    const {participants, body_name, participants_name, participantsValid, governingValid} = state;
    const [addMeetingBtnStatus, setAddMeetingBtnStatus] = useState(false);
    const [runMeetingValidate, setRunMeetingValidate] = useState(false);

    const removeMeeting = idx => {
        dispatch({
            type: REMOVE_MEETING,
            payload: {idx}
        })
    }

    const addMeeting = () => {
        if (addMeetingBtnStatus) {
            dispatch({type: ADD_MEETING})
            setRunMeetingValidate(false)
        } else {
            setRunMeetingValidate(true)
        }
    }

    const handleOnChangeMeetingList = (e, idx) => {
        dispatch({
            type: SET_MEETING_LIST_VALUE,
            payload: {
                idx,
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const handleOnChange = e => {
        dispatch({
            payload: {
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const setParticipantsFormError = (error, idx) => {
        dispatch({
            type: SET_MEETING_PARTICIPANTS_ERROR,
            payload: {error, idx}
        })
    }

    const setFormError = error => {
        dispatch({
            type: SET_MEETING_ERROR,
            payload: error
        })
    }

    useEffect(() => {
        let status = true;

        for (let i = 0; i < participantsValid.length; i++) {
            if (participantsValid[i].length) {
                status = false
                break;
            }
        }

        participants.forEach((item, idx) => {
            for (let key in item) {
                if (!item[key]) {
                    status = false
                    break;
                }
            }
        })

        if (status !== addMeetingBtnStatus) setAddMeetingBtnStatus(status)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participants, participantsValid, runMeetingValidate])

    return (
        <div className="projectGoverning__meeting">
            <p className="dataCard__title">Общее собрание</p>
            <div className="projectGoverning__meeting_list">
                {participants.map((item, idx) => (
                    <div key={idx} className="projectGoverning__meeting_item">
                        <span className="projectGoverning__meeting_name">Наименование участника</span>
                        <div className="projectGoverning__meeting_group">
                            <InputText
                                noLabel
                                noErrorMessage
                                name="name"
                                type="text"
                                formError={participantsValid[idx]}
                                setFormError={error => setParticipantsFormError(error, idx)}
                                resetValidate={!governingValidStatus || runMeetingValidate}
                                validator={[{maxLength: 50}, {required: true}]}
                                onChange={e => handleOnChangeMeetingList(e, idx)}
                                value={item.name || ''}
                                containerClassName="projectGoverning__middleInput"
                            />
                            <div className="projectGoverning__meeting_groupInner">
                                <span className="projectGoverning__meeting_share">% доли в УК</span>
                                <InputText
                                    noLabel
                                    noErrorMessage
                                    name="share"
                                    type="text"
                                    formError={participantsValid[idx]}
                                    setFormError={error => setParticipantsFormError(error, idx)}
                                    resetValidate={!governingValidStatus || runMeetingValidate}
                                    validator={[{onlyNumbers: true}, {maxNumber: 100}, {maxLength: 50}, {required: true}]}
                                    onChange={e => handleOnChangeMeetingList(e, idx)}
                                    value={item.share || ''}
                                    containerClassName="projectGoverning__smallInput"
                                />
                                <IconBtn
                                    remove={true}
                                    onClick={() => removeMeeting(idx)}
                                    className="projectGoverning__removeIcon"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <IconBtn
                title="Добавить участника"
                onClick={addMeeting}
                className={classnames({projectGoverning__addBtn: true, disabled: !addMeetingBtnStatus})}
            />
            <div className="projectGoverning__meeting_collegial">
                <p className="dataCard__title">Коллегиальный орган управления</p>
                <InputText
                    type="text"
                    onChange={handleOnChange}
                    name="body_name"
                    value={body_name || ''}
                    formError={governingValid}
                    setFormError={setFormError}
                    validator={[{maxLength: 100}, {required: true}]}
                    resetValidate={!governingValidStatus}
                    label="Название коллегиального органа управления"
                    containerClassName="addProject__fullInput"
                />
                <InputText
                    rows={2}
                    type="textarea"
                    onChange={handleOnChange}
                    name="participants_name"
                    value={participants_name || ''}
                    formError={governingValid}
                    setFormError={setFormError}
                    validator={[{maxLength: 1000}, {required: true}]}
                    resetValidate={!governingValidStatus}
                    label="Наименование участников"
                    containerClassName="addProject__fullInput"
                />
            </div>
        </div>
    )
}

export default Meeting;

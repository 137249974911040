import {get} from "@utils/storage";

export const getFileBlob = (id, path) => {
    const url = `${process.env.REACT_APP_API_URL}${path ? path : '/files/download/'}${id ? id : ''}`
    const HEADERS = new Headers();

    HEADERS.set("Authorization", `Bearer ${get("accessToken")}`);
    HEADERS.set("Access-Control-Allow-Origin", "*")

    const requestOptions = {
        method: 'GET',
        headers: HEADERS,
    }

    return fetch(url, requestOptions)
        .then(response => response.blob())
        .then(blob => window.URL.createObjectURL(blob))
        .catch(error => console.log(error))
}
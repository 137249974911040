import React from 'react';
import UploadField from "@shared/components/uploadField";

const PledgeDocuments = ({data}) => {
    return (
        <div className='documentationInfo'>
            <span className='documentationInfo__title'>Документы</span>
            <div className='documentationInfo__desc documentationInfo__descCompany'>
                <span className='documentationInfo__descCompany_subtitle'>Документы по залогу</span>
                {
                    data.pledges.map(item => (
                        <UploadField key={item.id} viewDetail files={item.property.documents} edit={false} notControls/>
                    ))
                }
            </div>
        </div>
    )
};

export default PledgeDocuments;
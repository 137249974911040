import React, {useState} from 'react';
import {Tooltip} from 'reactstrap';
import useWindowSize from "@hooks/useWindowSize";

const PrimaryTooltip = ({children, title = null, className = '', name}) => {
    const [width = 441] = useWindowSize();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return title
        ? <div className={className}>
            <span id={name ? name : 'primaryTooltip'} className="tooltip_icon">{title}</span>
            <Tooltip
                placement={width >= 441 ? "right" : "bottom"}
                isOpen={tooltipOpen}
                autohide={false}
                target={name ? name : 'primaryTooltip'}
                toggle={toggle}
                className={`${className}_content`}
            >
                {children}
            </Tooltip>
        </div>
        : null
}

export default PrimaryTooltip;
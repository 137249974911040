import {LogsProfilesService} from "@services/logs.service";
import {QualificationsService} from "@services/qualifications.service";
import {BansUsersService} from "@services/ban.service";
import {UsersService} from "@services/users.service";

export const getLogsProfiles = filterState => {
    let searchFormat = '';
    let searchFieldsFormat = '';
    const include = [
        'profile',
        'profile.legal_form',
        'profile.user.roles',
    ];

    Object.keys(filterState.search).forEach(item => {
        if (filterState.search[item] && filterState.search[item].length) {
            if (Array.isArray(filterState.search[item])) {
                const sortingEmpty = filterState.search[item].filter(elem => elem);

                if (sortingEmpty.length) searchFormat += `${item}:${sortingEmpty};`
            } else {
                searchFormat += `${item}:${filterState.search[item].trim()};`
            }

            searchFieldsFormat += `${item}:${filterState.searchFields[item]};`
        }
    })

    const filterDataFormat = {
        include: include.join(','),
        sortedBy: filterState.sortedBy.join(';'),
        orderBy: filterState.orderBy.join(';'),
        search: searchFormat,
        searchFields: searchFieldsFormat,
        page: filterState.currentPage,
        limit: 20
    };

    return LogsProfilesService.getList(Object.fromEntries(Object.entries(filterDataFormat).filter(([_, v]) => !!v)))
        .catch(error => console.log(error))
}

export const getQualificationsList = filterState => {
    let searchFormat = '';
    let searchFieldsFormat = '';
    const include = [
        'profile',
        'profile.legal_form',
        'profile.user.roles',
    ];

    Object.keys(filterState.search).forEach(item => {
        if (filterState.search[item] && filterState.search[item].length) {
            if (Array.isArray(filterState.search[item])) {
                const sortingEmpty = filterState.search[item].filter(elem => elem);

                if (sortingEmpty.length) searchFormat += `${item}:${sortingEmpty};`
            } else {
                searchFormat += `${item}:${filterState.search[item].trim()};`
            }

            searchFieldsFormat += `${item}:${filterState.searchFields[item]};`
        }
    })

    const filterDataFormat = {
        include: include.join(','),
        sortedBy: filterState.sortedBy.join(';'),
        orderBy: filterState.orderBy.join(';'),
        search: searchFormat,
        searchFields: searchFieldsFormat,
        page: filterState.currentPage,
        limit: 20
    };

    return QualificationsService.getList(Object.fromEntries(Object.entries(filterDataFormat).filter(([_, v]) => !!v)))
        .catch(error => console.log(error))
}

export const getBlockedAccountsList = filterState => {
    let searchFormat = '';
    let searchFieldsFormat = '';
    const include = [
        'bannedBy',
        'bannedBy.user',
        'user',
    ];

    Object.keys(filterState.search).forEach(item => {
        if (filterState.search[item] && filterState.search[item].length) {
            if (Array.isArray(filterState.search[item])) {
                const sortingEmpty = filterState.search[item].filter(elem => elem);

                if (sortingEmpty.length) searchFormat += `${item}:${sortingEmpty};`
            } else {
                searchFormat += `${item}:${filterState.search[item].trim()};`
            }

            searchFieldsFormat += `${item}:${filterState.searchFields[item]};`
        }
    })

    const filterDataFormat = {
        include: include.join(','),
        sortedBy: filterState.sortedBy.join(';'),
        orderBy: filterState.orderBy.join(';'),
        search: searchFormat,
        searchFields: searchFieldsFormat,
        page: filterState.currentPage,
        limit: 20
    };

    return BansUsersService.getList(Object.fromEntries(Object.entries(filterDataFormat).filter(([_, v]) => !!v)))
        .catch(error => console.log(error))
}

export const getInnerUsersList = filterState => {
    let searchFormat = '';
    let searchFieldsFormat = '';
    const include = [
        'roles',
        'status',
    ];

    Object.keys(filterState.search).forEach(item => {
        if (filterState.search[item] && filterState.search[item].length) {
            if (Array.isArray(filterState.search[item])) {
                const sortingEmpty = filterState.search[item].filter(elem => elem);

                if (sortingEmpty.length) searchFormat += `${item}:${sortingEmpty};`
            } else {
                searchFormat += `${item}:${filterState.search[item].trim()};`
            }

            searchFieldsFormat += `${item}:${filterState.searchFields[item]};`
        }
    })

    const filterDataFormat = {
        include: include.join(','),
        sortedBy: filterState.sortedBy.join(';'),
        orderBy: filterState.orderBy.join(';'),
        search: searchFormat,
        searchFields: searchFieldsFormat,
        page: filterState.currentPage,
        role: 'inner',
        limit: 20
    };

    return UsersService.getList(Object.fromEntries(Object.entries(filterDataFormat).filter(([_, v]) => !!v)))
        .catch(error => console.log(error))
}

export const getInnerUserLogs = (userId, filterState) => {
    let searchFormat = '';
    let searchFieldsFormat = '';
    const include = [
        'profile',
        'profile.legal_form',
        'profile.user.roles',
    ];

    Object.keys(filterState.search).forEach(item => {
        if (filterState.search[item] && filterState.search[item].length) {
            if (Array.isArray(filterState.search[item])) {
                const sortingEmpty = filterState.search[item].filter(elem => elem);

                if (sortingEmpty.length) searchFormat += `${item}:${sortingEmpty};`
            } else {
                searchFormat += `${item}:${filterState.search[item].trim()};`
            }

            searchFieldsFormat += `${item}:${filterState.searchFields[item]};`
        }
    })

    const filterDataFormat = {
        include: include.join(','),
        sortedBy: filterState.sortedBy.join(';'),
        orderBy: filterState.orderBy.join(';'),
        search: searchFormat,
        searchFields: searchFieldsFormat,
        page: filterState.currentPage,
        limit: 20
    };

    return LogsProfilesService.getById(userId, Object.fromEntries(Object.entries(filterDataFormat).filter(([_, v]) => !!v)))
        .catch(error => console.log(error))
}

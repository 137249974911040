import React from "react"
import {projectCardRatingColor, projectCardRatingDesc} from "./helper";

const ProjectCardRating = ({rating}) => {
    if (!rating) return null

    return <span className="projectDetail__rating" style={{color: projectCardRatingColor[rating]}}>
        {projectCardRatingDesc[rating]}
    </span>
}

export default ProjectCardRating;
import React from 'react';
import {CSSTransition} from "react-transition-group";
import ProjectCard from "@containers/Projects/ProjectsFeed/ProjectCard";
import NoProjectsNotification from "@containers/Projects/ProjectsFeed/NoProjectsNotification";

const ProjectsFeedList = ({data, collectionCompleted, roleKey}) => {
    if (data?.data && data.data.length) {
        return data.data.map(item => (
            <CSSTransition classNames="fade" key={item.id} in={true} timeout={1000} appear={true} unmountOnExit>
                <ProjectCard
                    key={item.id}
                    data={item}
                    profileType={roleKey}
                    collectionCompleted={collectionCompleted}
                />
            </CSSTransition>
        ))
    } else if (roleKey === 'investor') {
        return <NoProjectsNotification/>
    }

    return null
}

export default ProjectsFeedList;
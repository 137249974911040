import React from 'react';
import cn from 'classnames';
import {Button} from "reactstrap";
import constants from "../action";
import RegisterFormBackBtn from '../RegisterFormBackBtn';
import RegisterConfirmCodeField from "./RegisterConfirmCodeField";
import RegisterPasswordField from "./RegisterPasswordField";
import RegisterCheckbox from "./RegisterCheckbox";
import RegisterConfirmationField from "./RegisterConfirmationField";
import {renderAcceptLink} from "./RegisterCheckbox/helpers";

const FirstStepRegister = props => {
    const {state, dispatch, setData} = props;
    const {data, noteRoleBtn, confirmationFieldServiceType, formError, successfulPassword} = state;
    const {
        role, email
    } = data;

    const handleOnClickContinue = () => {
        dispatch({name: 'formValidationCheck', value: true})

        if (
            !formError.length
            && successfulPassword
            && confirmationFieldServiceType === constants.CONFIRMATION_FIELD_VISIBLE_CHECK
        ) dispatch({type: constants.SET_VISIBLE_SECOND_STAGE})
    }

    return (
        <div className="register__step register-form register-form-between">
            <div className="form__group-wrap">
                <div className="register__radioBtnGroup">
                    <button
                        className={cn({
                            'from__custom-radio-btn': true,
                            'requiredFill': noteRoleBtn,
                            'active': role === 'investor'
                        })}
                        onClick={() => setData({target: {name: 'role', value: 'investor'}})}
                    >
                        <span>Инвестор</span>
                    </button>
                    <button
                        className={cn({
                            'from__custom-radio-btn': true,
                            'requiredFill': noteRoleBtn,
                            'active': role === 'borrower'
                        })}
                        onClick={() => setData({target: {name: 'role', value: 'borrower'}})}
                    >
                        <span>Заемщик</span>
                    </button>
                    <RegisterFormBackBtn dispatch={dispatch} stateKey="noteRoleBtn" parentKey={role}/>
                </div>
                <RegisterConfirmationField {...props} type="email"/>
                <RegisterConfirmCodeField
                    {...props}
                    parentKey="email_code"
                    parentValue={email}
                />
                <RegisterPasswordField
                    {...props}
                    validateInfoVisible={confirmationFieldServiceType === constants.CONFIRMATION_FIELD_VISIBLE_CHECK}
                />
            </div>
            <div className="form__group-wrap">
                <div className="form__form-group form__form-group-accept">
                    <RegisterCheckbox
                        dispatch={dispatch}
                        state={state}
                        type="confidentiality_acceptance"
                        renderDesc={renderAcceptLink}
                        isRequired={true}
                    />
                </div>
                <div className="register__mainBtn">
                    <Button color="primary" className="w-100" onClick={handleOnClickContinue}>
                        Продолжить
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default FirstStepRegister;
import React from 'react';
import Shimmer from "@shared/components/skeletons/Shimmer";
import SkeletonElement from "@shared/components/skeletons/SkeletonElement";
import SkeletonList from "@shared/components/skeletons/SkeletonList/SkeletonList";

const SkeletonDataCard = () => {
    return (
        <div className="skeleton-wrapper">
            <div className="skeleton-article">
                <SkeletonElement type="title"/>
                <SkeletonList count={5}/>
            </div>
            <Shimmer/>
        </div>
    )
}

export default SkeletonDataCard;
import React, {useEffect, useState} from 'react';
import {Link, NavLink, useHistory} from "react-router-dom";
import {AuthService} from "@services/auth.service";
import FormEmail from '@shared/components/form/FormEmail';
import FormPass from '@shared/components/form/FormPass';
import {Spinner} from "reactstrap";
import AccountWrapper from "@account/AccountWrapper";
import {userInfo} from "@actions/userActions";
import {useDispatch} from "react-redux";

const LoginForm = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [email, setEmail] = useState(''); // Почта
    const [pass, setPass] = useState(''); // Пароль
    const [emailValidate, setEmailValidate] = useState(false) // Проверяем валидность email
    const [showPassword, setShowPassword] = useState(false); // Видимость пароля
    const [disabledBtn, setDisabledBtn] = useState(true); // Активность кнопки "Вход"
    const [errorMessage, setErrorMessage] = useState(null); // Сообщение об ошибке при вводе пароля
    const [spinnerStatus, setSpinnerStatus] = useState(false) // Статус запроса на авторизацию

    // Обработать вход
    const handleLogin = () => {
        if (email && pass) {
            setSpinnerStatus(true)
            setDisabledBtn(true)

            // Запрос на авторизацию
            AuthService.login(email, pass)
                .then(() => {
                    userInfo(dispatch).then(() => history.push('/'))
                })
                .catch(() => {
                    setSpinnerStatus(false)
                    setDisabledBtn(false)
                    setErrorMessage('Ошибка авторизации!')
                })
        } else {
            setErrorMessage('Введите почту и пароль!')
        }
    }

    // Показать - скрыть пароль
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // Обработка ввода
    const handleChangePass = value => {
        if (value.length < 255) setPass(value) // Длина не больше 255
        if (errorMessage) setErrorMessage(null)
    }

    // Обработать потерю фокуса поля "Пароль"
    const handleOnBlurPass = () => {
        if (!pass.length) setErrorMessage('Обязательно для заполнения!')
    }

    // При нажатии Enter в поле "Пароль" или "Email", если данные валидны, то отправить запрос на вход
    const handleClickEnter = e => {
        if ((e.charCode === 13 || e.key === 'Enter') && !disabledBtn) handleLogin()
    }

    // Если все поля заполнены активировать кнопку "Войти"
    useEffect(() => {
        if (pass.length && emailValidate) {
            if (disabledBtn) setDisabledBtn(false)
        } else if (!disabledBtn) {
            setDisabledBtn(true)
        }
    }, [pass, emailValidate, disabledBtn])

    return (
        <AccountWrapper className="send-email-success login-form">
            <div className="accountFormWrap__mobile_header">
                <h1>Вход</h1>
                <NavLink exact to="register" className="accountFormWrap__mobile_auth">Зарегистрироваться</NavLink>
            </div>
            <div className="accountFormWrap">
                <div className="accountFormWrap__bg"/>
                <div className="accountFormWrap__wrap">
                    <div className="accountFormWrap__content">
                        <div className="login-form-container">
                            <h1>Вход</h1>
                            <div className="register-form form login-form-group">
                                <div className="form__form-group">
                                    <FormEmail
                                        setValue={setEmail}
                                        value={email}
                                        emailValidate={emailValidate}
                                        setEmailValidate={setEmailValidate}
                                        onKeyPress={handleClickEnter}
                                    />
                                </div>
                                <div className="form__form-group">
                                    <FormPass
                                        value={pass}
                                        showPassBtn={true}
                                        toggleShowPassword={toggleShowPassword}
                                        showPassword={showPassword}
                                        setValue={handleChangePass}
                                        handleOnBlur={handleOnBlurPass}
                                        onKeyPress={handleClickEnter}
                                    />
                                    {errorMessage && <span className="form__form-error">{errorMessage}</span>}
                                    <div className="forgot-pass">
                                        <Link className="forgot-pass-link" to="/reset-password">Забыли пароль?</Link>
                                    </div>
                                </div>
                            </div>
                            <button
                                className={`btn btn-purple register-form-btn ${disabledBtn ? 'disabled' : ''}`}
                                disabled={disabledBtn}
                                onClick={handleLogin}
                            >
                                <div className="btn-spinner-group">
                                    <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                                    Вход
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </AccountWrapper>
    )
}

export default LoginForm;
import React, {useEffect, useState} from 'react';
import {Spinner} from "reactstrap";
import MaskedInput from 'react-text-mask';
import PhoneInTalkOutlineIcon from "mdi-react/PhoneInTalkOutlineIcon";
import formCheckIcon from "@shared/img/account/check.svg";
import {AuthService} from "@services/auth.service";
import {set} from "@utils/storage";
import {elevenDigits} from "@shared/components/form/validate";
import classNames from "classnames";

const RegFormPhone = ({
                          legalForm,
                          phoneInputDisabled,
                          confirmedCode,
                          handleVisibleCode,
                          name = 'phone',
                          btnTitle = '',
                          className = '',
                          onChange,
                          value = '',
                          formError = [],
                          setFormError,
                          setProfileTypeRequired,
                          confirmBtnRequired
                      }) => {
    const [phone, setPhone] = useState(value) // Сохраняем ввод в input
    const [disabled, setDisabled] = useState(phoneInputDisabled || false) // Сохраняем ввод в input
    const [phoneErrorMessage, setPhoneErrorMessage] = useState(null) // Сообщение об ошибке при отправке телефона
    const [phoneSpinner, setPhoneSpinner] = useState(false) // Спинер в поле "Телефон"
    const [confirmBtn, setConfirmBtn] = useState(true) // Паказать скрыть кнопку "Подтвердить"
    const [phoneValidate, setPhoneValidate] = useState(false) // Проверяем валидность телефона
    const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false) // Активность кнопки "Подтвердить"

    const inputGroupClass = classNames({
        'form__form-group-field': true,
        errorInput: phoneErrorMessage
    })

    const confirmBtnClass = classNames({
        btn: true,
        disabled: !phoneValidate || confirmBtnDisabled || phoneErrorMessage,
        requiredFillConfirmBtn: confirmBtnRequired,
        'btn-outline-green': true,
    })

    // При клике по кнопке "Подтвердить", отправить запрос на подтверждение телефона
    const handleConfirmPhone = () => {
        setConfirmBtnDisabled(true)
        setPhoneSpinner(true)

        // Запрос на подтверждение телефона
        AuthService.confirmPhone(phone).then(res => {
            // Определяем время через которое можно отправить повторный запрос
            const xRatelimit = +res.headers['x-ratelimit-remaining'];
            const repeatTime = xRatelimit ? 60000 / xRatelimit : 60000

            setTimeout(() => {
                setConfirmBtnDisabled(false)
            }, repeatTime)

            set('phone', phone)
            handleVisibleCode(true)
            setDisabled(true)
        }).catch(error => {
            // Показать кнопку "подтвердить"
            setConfirmBtn(true)
            setConfirmBtnDisabled(false)
            // Обработка ошибки при отправке Phone
            switch (error.status) {
                case 422:
                    const errorMessage = error?.errors?.phone[0];
                    setPhoneErrorMessage(errorMessage ? errorMessage : 'Ошибка валидации!');
                    break;
                case 500:
                    setPhoneErrorMessage('Ошибка отправки смс!');
                    break;
                default:
                    setPhoneErrorMessage('Неизвестная ошибка!')
            }

            handleVisibleCode(false)
        }).finally(() => {
            setPhoneSpinner(false)
        })
    }

    // Обработка ввода в поле "Телефон"
    const handleChangePhone = e => {
        const noSpaces = e.target.value.replace(/\s/g, ''); // Удаляем все пробелы из строки
        setPhone(noSpaces)
        if (onChange) onChange(noSpaces)
        // Валидация телефона
        elevenDigits.test(e.target.value) ? setPhoneValidate(true) : setPhoneValidate(false)
        // Скрыть текст ошибки если он есть
        if (phoneErrorMessage) setPhoneErrorMessage(null)
    }

    // Вывести спинер || кнопку "Подтвердить" || галочку
    const renderInputService = () => {
        if (phoneSpinner) return <div className="form__input-icon-wrap">
            <Spinner style={{color: '#3F82E7'}}/>
        </div>

        if (confirmedCode) return <div className="form__input-icon-wrap form__input-icon-check">
            <img src={formCheckIcon} alt="confirmed"/>
        </div>

        if (confirmBtn) return <div className="confirm-mail-btn">
            <button
                className={confirmBtnClass}
                disabled={!phoneValidate || confirmBtnDisabled}
                onClick={handleConfirmPhone}
            >
                {btnTitle ? btnTitle : 'Подтвердить'}
            </button>
        </div>
    }

    const handleOnBlur = e => {
        if (!elevenDigits.test(e.target.value)) {
            setPhoneErrorMessage('Обязательно для заполнения!')
        }
    }

    const handleOnClickInputGroup = () => {
        if (!legalForm && setProfileTypeRequired) {
            setProfileTypeRequired(true)
            setTimeout(() => setProfileTypeRequired(false), 1000)
        }
    }

    useEffect(() => {
        if (setFormError) {
            if (phoneErrorMessage) {
                const index = formError.indexOf(name);
                if (index === -1) {
                    const newFormError = [...formError];
                    newFormError.push(name)
                    setFormError(newFormError)
                }
            } else {
                const index = formError.indexOf(name);
                if (index !== -1) {
                    const newFormError = [...formError];
                    newFormError.splice(index, 1)
                    setFormError(newFormError)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phoneErrorMessage])

    return (
        <div className={`inputText__wrapper ${phoneErrorMessage ? 'inputText__error' : ''}`}>
            <span className="form-label">Телефон</span>
            <div className={inputGroupClass} onClick={handleOnClickInputGroup}>
                <div className="form__form-group-icon">
                    <PhoneInTalkOutlineIcon/>
                </div>
                <MaskedInput
                    mask={['+', '7', ' ', '(', /[489]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                    type="text"
                    name={name}
                    autoComplete="off"
                    value={phone}
                    disabled={!legalForm || disabled}
                    className={`account-input ${className}`}
                    onChange={handleChangePhone}
                    placeholder="+7 (999) 999-99-99"
                    onBlur={handleOnBlur}
                />
                {renderInputService()}
            </div>
            {phoneErrorMessage && <span className="form__form-error">{phoneErrorMessage}</span>}
        </div>
    )
}

export default RegFormPhone
import React, {useEffect, useState} from 'react'
import {Spinner} from "reactstrap";
import LockIcon from "mdi-react/LockIcon";
import {AuthService} from "@services/auth.service";
import {get, set} from "@utils/storage";
import inputErrorIcon from "@shared/img/account/inputError.png";
import classNames from 'classnames';
import {ConfirmationsProfilePhoneCheckService} from "@services/confirmations.service";

const RegFormCode = ({
                         handleVisibleCode,
                         handleConfirmedCode,
                         handleVisibleValidate,
                         parentInput,
                         className = '',
                         onChange,
                         codeValue,
                         setCodeValue,
                         codeRequired,
                         setCodeRequired,
                         parentValue,
                         label,
                         hideClearIcon = false,
                         resetValidate = false,
                         setResetValidate,
                         showIcon,
                         requestCustomType
                     }) => {
    const requestType = parentInput === 'email' ? 'checkRegisterEmailCode' : 'checkRegisterPhoneCode';
    const [codeSpinner, setCodeSpinner] = useState(false) // Спинер в поле "Код"
    const [errorMessage, setErrorMessage] = useState(null) // Ошибка при подтверждении кода

    const inputClass = classNames({
        'account-input': true,
        [className]: className
    })

    const inputGroupClass = classNames({
        'form__form-group-field': true,
        errorInput: errorMessage
    })

    // Обработка ввода в поле "Код"
    const handleChangeCode = e => {
        if (codeRequired) setCodeRequired(false);

        if (e.target.value.length <= 4) {
            if (!!errorMessage) setErrorMessage('') // Скрываем информацию об ошибке

            const onlyNums = e.target.value.replace(/[^\d]/g, '') // Только цифры
            setCodeValue(onlyNums)

            if (e.target.value.length === 4) {
                // При вводе 4-х цифр - отправляем запрос на подтверждение кода
                const value = parentValue ? parentValue : get(`${parentInput}`);
                setCodeSpinner(true)

                const Service = parentInput === 'profile'
                    ? ConfirmationsProfilePhoneCheckService.create({
                        phone: value,
                        code: onlyNums,
                        type: "qualification_request"
                    })
                    : AuthService[requestType](value, onlyNums, requestCustomType)

                Service.then(() => {
                    onChange && onChange(onlyNums)
                    handleVisibleCode && handleVisibleCode(false)
                    parentInput && set(`${parentInput}_code`, onlyNums)
                    handleVisibleValidate && handleVisibleValidate(true)
                    handleConfirmedCode && handleConfirmedCode(true)
                }).catch(() => {
                    setErrorMessage('Неверный код подтверждения!')
                }).finally(() => {
                    setCodeSpinner(false)
                })
            }
        }
    }

    // Очистка поля при клике по крестику
    const handleClickErrorIcon = () => {
        setCodeValue('')
        setErrorMessage('')
    }

    // Показать спинер || крестик
    const renderCodeInputService = () => {
        if (codeSpinner) return <div className="form__input-icon-wrap">
            <Spinner style={{color: '#58BF8F'}}/>
        </div>

        if (!!errorMessage && !hideClearIcon) return <div
            className="form__input-icon-wrap form__input-icon-check from__input-error-icon">
            <img src={inputErrorIcon} alt="error" onClick={handleClickErrorIcon}/>
        </div>
    }

    // Валидация при потере фокуса
    const validate = () => {
        if (!codeValue.length) {
            setErrorMessage('Обязательно для заполнения!');
            return;
        }
        if (!codeValue.length < 4) setErrorMessage('Минимальная длина 4 символа!');
    }

    useEffect(() => {
        if (codeRequired) setErrorMessage('Обязательно для заполнения!')
    }, [codeRequired])

    useEffect(() => {
        if (resetValidate && setResetValidate) {
            validate()
            setResetValidate(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetValidate])

    return (
        <div className={`confirmPhone__wrapper ${!!errorMessage ? 'confirmPhone__error' : ''}`}>
            <span className="form-label">{label ? label : 'Код подтверждения'}</span>
            <div className={inputGroupClass}>
                {
                    showIcon
                        ? <div className="form__form-group-icon">
                            <LockIcon/>
                        </div>
                        : null
                }
                <input
                    type="text"
                    autoComplete="off"
                    name="confirm_code"
                    className={inputClass}
                    placeholder="Код подтверждения"
                    disabled={codeSpinner}
                    value={codeValue}
                    onChange={handleChangeCode}
                    onBlur={validate}
                />
                {renderCodeInputService()}
            </div>
            {errorMessage && <span className="form__form-error">{errorMessage}</span>}
        </div>
    )
}

export default RegFormCode
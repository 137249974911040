import {
    ExternalProfileService,
    ProfilesCheckAccreditationService,
    ProfilesOwnService,
    ProfilesService
} from '@services/profiles.service';


// Constants
export const SET_PROFILES = 'SET_PROFILES';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_BANK_DETAILS = 'SET_BANK_DETAILS';
export const CHECK_ACCREDITATION = 'CHECK_ACCREDITATION';
export const SET_EDIT_PROFILE_ID = 'SET_EDIT_PROFILE_ID';
export const ADD_NEW_BANK_TEMPLATE = 'ADD_NEW_BANK_TEMPLATE';
export const SET_BANK_TEMPLATE_EDIT = 'SET_BANK_TEMPLATE_EDIT';
export const PROFILE_DATA_EDITING_STATUS = 'PROFILE_DATA_EDITING_STATUS';


export const getProfiles = () => {
    return dispatch => {
        ProfilesService.getList({
            include: 'role,legal_form,bank_details,accreditation_status,avatar',
            limit: 50,
            orderBy: 'created_at'
        }).then(res => {
            dispatch({
                type: SET_PROFILES,
                payload: res.data.data
            })
        }).catch(error => {
            console.log(error)
        })
    }
}

export const setEditProfileId = id => {
    return dispatch => {
        dispatch({
            type: SET_EDIT_PROFILE_ID,
            payload: id
        })
    }
}

export const getProfileData = () => {
    return dispatch => {
        return ProfilesOwnService.getList({
            include: [
                'role',
                'legal_form',
                'bank_details',
                'legal_form.questionnaire',
                'legal_form.questionnaire.responses',
                'legal_form.questionnaire.responses.values',
                'legal_form.questionnaire.responses.form',
                'legal_form.questionnaire.responses.values.field',
                'qualification',
                'accreditation',
                'ban',
                'user.roles',
                'avatar'
            ].join(','),
            orderBy: 'created_at'
        }).then(res => {
            dispatch({
                type: SET_PROFILE_DATA,
                payload: res.data.data
            })
        }).catch(error => {
            console.log(error)
        })
    }
}

export const checkForAccreditation = (dispatch) => {
    ProfilesCheckAccreditationService.getList()
        .then(() => {
            getProfiles()(dispatch)
            dispatch({
                type: CHECK_ACCREDITATION,
                payload: true
            })
        })
        .catch(() => {
        })
}

export const getModerationProjects = profileId => {
    const include = [
        'legal_form',
        'legal_form.questionnaire',
        'bank_details',
        'accreditation',
        'user',
        'qualification'
    ]
    const params = {
        include: include.join(',')
    }

    return ExternalProfileService.getById(profileId, params)
        .catch(error => console.log(error))
}

export const setProfileDataEditingStatus = dispatch => () => dispatch({type: PROFILE_DATA_EDITING_STATUS})
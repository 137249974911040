import React from 'react';
import {useDispatch} from "react-redux";
import {showModal} from "@actions/modalActions";
import {ADD_NEW_PROFILE} from "@shared/components/modal/constants";
import ProfileSelectionItem from "@shared/components/sidebar/profileSelection/ProfileSelectionItem";
import IconBtn from "@shared/components/form/IconBtn";
import classNames from "classnames";

const ProfileSelectionList = ({listVisible, setListVisible, profiles, selectedProfileId}) => {
    const dispatch = useDispatch();
    const profileList = profiles.filter(item => item.id !== selectedProfileId);

    const handleAddProfile = () => showModal(ADD_NEW_PROFILE)(dispatch);

    const profileSelectionList = classNames({
        profileSelection__list: true,
        'profileSelection__list--open': listVisible,
    })

    return <div className={profileSelectionList}>
        {profileList.map((item) => <ProfileSelectionItem setListVisible={setListVisible} key={item.id} data={item}/>)}
        <div className="profileSelection__addBtn sidebar-hr">
            <IconBtn
                title="Добавить профиль"
                onClick={handleAddProfile}
                className="yourOption__addBtn relatedCompanies__addBtn"
            />
        </div>
    </div>

}

export default ProfileSelectionList;
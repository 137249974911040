import {BaseService} from "@services/base.service";

export class SettingsService extends BaseService {
    static get entity() {
        return '/settings'
    }
}

export class SettingsProjectRateService extends BaseService {
    static get entity() {
        return '/settings/project_rate'
    }
}

export class SettingsDocumentsService extends BaseService {
    static get entity() {
        return '/settings/documents'
    }
}
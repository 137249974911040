import React from 'react';
import {navigationMenu} from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/helpers";
import AddedList from "@shared/components/form/addedList";
import OtherInformation from "../forms/moderationSolution/OtherInformation";

const ModerationProjectSolution = ({step, remarkList, setRemarkList, pledgesState, moderationHistory}) => {
    const total = pledgesState.pledges.reduce((acc, item) => acc + item.collateral_value || 0, 0);
    const TotalPledgePrice = () => <p>Суммарная оценочная стоимость залога: <span>{`${total} ₽`}</span></p>

    const moderationProfile = remarkList.filter(item => item.type_key === 'moderation_profile');
    const moderationConditions = remarkList.filter(item => item.type_key === 'moderation_conditions');
    const moderationDescription = remarkList.filter(item => item.type_key === 'moderation_description');
    const moderationPledge = remarkList.filter(item => item.type_key === 'moderation_pledge');

    return <div>
        {
            moderationProfile.length
                ? <AddedList
                    disabled
                    data={moderationProfile}
                    label="Данные заявителя"
                    className="moderationProject__remarks"
                    fRef={navigationMenu[step].links[0].ref}
                />
                : null
        }

        {
            moderationConditions.length
                ? <AddedList
                    disabled
                    data={moderationConditions}
                    label="Условия проекта"
                    className="moderationProject__remarks"
                    fRef={navigationMenu[step].links[1].ref}
                />
                : null
        }

        {
            moderationDescription.length
                ? <AddedList
                    disabled
                    data={moderationDescription}
                    label="Описание проекта и документы"
                    className="moderationProject__remarks"
                    fRef={navigationMenu[step].links[2].ref}
                />
                : null
        }

        {
            moderationPledge.length
                ? <AddedList
                    disabled
                    Subtitle={TotalPledgePrice}
                    data={moderationPledge}
                    label="Обеспечение"
                    className="moderationProject__remarks"
                    fRef={navigationMenu[step].links[3].ref}
                />
                : null
        }
        <OtherInformation
            remarkList={remarkList}
            setRemarkList={setRemarkList}
            moderationHistory={moderationHistory}
        />
    </div>
}

export default ModerationProjectSolution;

export const ADD_EGRUL = 'ADD_EGRUL';
export const REMOVE_EGRUL = 'REMOVE_EGRUL';
export const SET_EGRUL_ERROR = 'SET_EGRUL_ERROR';
export const SET_ANALYTIC_ERROR = 'SET_ANALYTIC_ERROR';
export const SET_EGRUL_LIST_VALUE = 'SET_EGRUL_LIST_VALUE';
export const SET_ARBITRATION_ERROR = 'SET_ARBITRATION_ERROR';
export const SET_GOVERNMENT_PURCHASES_ERROR = 'SET_GOVERNMENT_PURCHASES_ERROR';
export const SET_ARBITRATION_VALUE = 'SET_ARBITRATION_VALUE';
export const SET_GOVERNMENT_PURCHASES_VALUE = 'SET_GOVERNMENT_PURCHASES_VALUE';

export const initAnalyticState = data => (
    {
        ...JSON.parse(JSON.stringify(data)),
        analyticValid: [],
        analyticEgrulValid: [],
        arbitrationCasesValid: {
            plaintiffAll: [],
            plaintiffYear: [],
            respondentAll: [],
            respondentYear: [],
        },
        governmentPurchasesValid: {
            participant: [],
            contracts: [],
        }
    }
)

export const analyticReducer = (state, action) => {
    const {name, value, idx = null, type: payloadType, error = []} = action.payload || {};

    switch (action.type) {
        case SET_EGRUL_LIST_VALUE:
            const dataEgrulCopyList = [...state.entries_egrul];
            dataEgrulCopyList[idx][name] = value

            return {
                ...state,
                entries_egrul: dataEgrulCopyList
            }
        case ADD_EGRUL:
            return {
                ...state,
                entries_egrul: [...state.entries_egrul, {date: '', event: ''}],
                analyticEgrulValid: [...state.analyticEgrulValid, []]
            }
        case REMOVE_EGRUL:
            const dataEgrulRemoveCopyList = [...state.entries_egrul];
            const dataEgrulValidRemoveCopyList = [...state.analyticEgrulValid];

            dataEgrulRemoveCopyList.splice(idx, 1)
            dataEgrulValidRemoveCopyList.splice(idx, 1)

            return {
                ...state,
                entries_egrul: dataEgrulRemoveCopyList,
                analyticEgrulValid: dataEgrulValidRemoveCopyList,
            }
        case SET_EGRUL_ERROR:
            const dataEgrulValidCopyList = [...state.analyticEgrulValid];
            dataEgrulValidCopyList[idx] = [...error]

            return {
                ...state,
                analyticEgrulValid: dataEgrulValidCopyList
            }
        case SET_ARBITRATION_VALUE:
            switch (payloadType) {
                case 'plaintiffYear':
                    return {
                        ...state,
                        arbitration_cases: {
                            ...state.arbitration_cases,
                            plaintiff: {
                                ...state.arbitration_cases.plaintiff,
                                year: {
                                    ...state.arbitration_cases.plaintiff.year,
                                    [name]: value
                                }
                            }
                        }
                    }
                case 'plaintiffAll':
                    return {
                        ...state,
                        arbitration_cases: {
                            ...state.arbitration_cases,
                            plaintiff: {
                                ...state.arbitration_cases.plaintiff,
                                all: {
                                    ...state.arbitration_cases.plaintiff.all,
                                    [name]: value
                                }
                            }
                        }
                    }
                case 'respondentYear':
                    return {
                        ...state,
                        arbitration_cases: {
                            ...state.arbitration_cases,
                            respondent: {
                                ...state.arbitration_cases.respondent,
                                year: {
                                    ...state.arbitration_cases.respondent.year,
                                    [name]: value
                                }
                            }
                        }
                    }
                case 'respondentAll':
                    return {
                        ...state,
                        arbitration_cases: {
                            ...state.arbitration_cases,
                            respondent: {
                                ...state.arbitration_cases.respondent,
                                all: {
                                    ...state.arbitration_cases.respondent.all,
                                    [name]: value
                                }
                            }
                        }
                    }
                default:
                    return {...state}
            }
        case SET_GOVERNMENT_PURCHASES_VALUE:
            switch (payloadType) {
                case 'participant':
                    return {
                        ...state,
                        government_purchases: {
                            ...state.government_purchases,
                            participant: {
                                ...state.government_purchases.participant,
                                [name]: value
                            }
                        }
                    }
                case 'contracts':
                    return {
                        ...state,
                        government_purchases: {
                            ...state.government_purchases,
                            contracts: {
                                ...state.government_purchases.contracts,
                                [name]: value
                            }
                        }
                    }
                default:
                    return {...state}
            }
        case SET_ANALYTIC_ERROR:
            return {
                ...state,
                analyticValid: action.payload
            }
        case SET_ARBITRATION_ERROR:
            return {
                ...state,
                arbitrationCasesValid: {
                    ...state.arbitrationCasesValid,
                    [payloadType]: error
                }
            }
        case SET_GOVERNMENT_PURCHASES_ERROR:
            return {
                ...state,
                governmentPurchasesValid: {
                    ...state.governmentPurchasesValid,
                    [payloadType]: error
                }
            }
        default:
            return {
                ...state,
                [name]: value
            }
    }

}

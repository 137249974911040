import React from 'react';
import YoutubeEmbed from "@shared/components/video/YoutubeEmbed";
import classnames from "classnames";

const VideoPresentation = React.memo(({videos, className, width = 240, height = 135}) => {
    const videoPresentationClass = classnames({
        videoPresentation: true,
        [className]: className
    })

    return videos.length
        ? <div className={videoPresentationClass}>
            {videos.map((item, idx) => <YoutubeEmbed key={idx} width={width} height={height} videoLink={item}/>)}
        </div>
        : null
})

export default VideoPresentation;

import React from 'react';
import AboutTheBorrowerInfo from "./AboutTheBorrowerInfo";
import AboutTheBorrowerAnalytic from "./AboutTheBorrowerAnalytic";

const AboutTheBorrower = ({data}) => {
    return (
        <div className='aboutTheBorrower'>
            <AboutTheBorrowerInfo data={data}/>
            <AboutTheBorrowerAnalytic data={data}/>
        </div>
    )
};

export default AboutTheBorrower;
import React from 'react';
import {Link} from "react-router-dom";
import {EmailIcon, PhoneIcon} from "@shared/img/iconSvg";
import {socialIconsLink} from './helpers';

const Footer = () => {

    return (
        <div className="footer">
            <div className="footer__menu">
                <Link to="/">О компании</Link>
                <Link to="/">Документы платфоры</Link>
                <Link to="/">FAQ</Link>
            </div>
            <div className="footer__contacts">
                <div className="footer__contacts_item">
                    <a href="tel:+79991235643">
                        <PhoneIcon/>
                        <span>8 (999) 123-56-43</span>
                    </a>
                </div>
                <div className="footer__contacts_item">
                    <a href="mailto:support@incrowd.ru">
                        <EmailIcon/>
                        <span>support@incrowd.ru</span>
                    </a>
                </div>
                <div className="footer__socialIcons">
                    {
                        socialIconsLink.map((item, idx) => {
                            const {Icon} = item;

                            return <a key={idx} href={item.link}>
                                <Icon/>
                            </a>
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(Footer);

import {BaseService} from "@services/base.service";

export class UsersService extends BaseService {
    static get entity() {
        return 'users'
    }
}

export class UsersAvatarService extends BaseService {
    static get entity() {
        return '/profiles/avatar'
    }
}
import React, {useEffect, useState} from 'react';
import cn from "classnames";
import InputText from "@shared/components/form/profileForm/InputText";
import ConfirmationFieldService from "./ConfirmationFieldService";
import {getInputSetting} from "./helpers";

const RegisterConfirmationField = ({state, dispatch, setData, setFormError, type = 'email'}) => {
    const {
        formError,
        data,
        confirmationFieldResetValidate,
        verificationDataConfirmed,
        formValidationCheck,
        visibleConfirmCodeField
    } = state;
    const [customErrorMessage, setCustomErrorMessage] = useState(null);
    const {
        fieldIcon: FieldIcon,
        fieldLabel,
        fieldType,
        fieldMask,
        fieldPlaceholder,
        fieldValidator
    } = getInputSetting(type);

    const registerFieldWrapClass = cn({
        'form__form-group-field': true,
        errorInput: formError.includes(type)
    })

    const handleOnChangeResetValidate = value => {
        dispatch({
            name: confirmationFieldResetValidate ? 'confirmationFieldResetValidate' : 'formValidationCheck',
            value
        })
    }

    useEffect(() => {
        if (
            formValidationCheck
            && data[type]
            && !formError.includes(type)
            && !visibleConfirmCodeField
            && !data[`${type}_code`]
        ) setCustomErrorMessage(`Необходимо подтвердить ${type === 'email' ? 'почту' : 'телефон'}!`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValidationCheck])

    return (
        <div className="register__emailField register__field">
            <span className="form-label">{fieldLabel}</span>
            <div className={registerFieldWrapClass}>
                <div className="form__form-group-icon">
                    <FieldIcon/>
                </div>
                <InputText
                    noLabel
                    checkAfterFocus
                    mask={fieldMask}
                    type={fieldType}
                    name={type}
                    placeholder={fieldPlaceholder}
                    className="account-input"
                    disabled={verificationDataConfirmed}
                    value={data[type] || ''}
                    onChange={setData}
                    formError={formError}
                    setFormError={setFormError}
                    validator={fieldValidator}
                    resetValidate={confirmationFieldResetValidate || formValidationCheck}
                    setResetValidate={handleOnChangeResetValidate}
                    customErrorMessage={customErrorMessage}
                    setCustomErrorMessage={setCustomErrorMessage}
                />
                <ConfirmationFieldService
                    customErrorMessage={customErrorMessage}
                    setCustomErrorMessage={setCustomErrorMessage}
                    state={state}
                    dispatch={dispatch}
                    parentType={type}
                />
            </div>
        </div>
    )
};

export default RegisterConfirmationField;
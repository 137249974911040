import React from 'react';
import LoanOptions from "@containers/Projects/ProjectDetail/ProjectDetailBorrower/ProjectDetailContent/LoanOptions";
import AboutTheProject from "@shared/components/projects/projectDetail/AboutTheProject";
import AboutTheBorrower from "@shared/components/projects/projectDetail/AboutTheBorrower";
import BorrowerExperience from "@shared/components/projects/projectDetail/BorrowerExperience";
import SecurityForm from "@shared/components/projects/projectDetail/Security";
import ExpertOpinion from "@shared/components/projects/projectDetail/ExpertOpinion";
import Documentation from "@shared/components/projects/projectDetail/Documentation";
import ReportOnNDFL from "@shared/components/projects/projectDetail/ReportOnNDFL";
import ProjectCalculations from "@shared/components/projects/projectDetail/ProjectCalculations";

const ProjectDetailContent = ({activeTab, data}) => {

    switch (activeTab) {
        case 0: {
            return <LoanOptions data={data}/>
        }
        case 1: {
            return <AboutTheProject data={data}/>
        }
        case 2: {
            return <AboutTheBorrower data={data}/>
        }
        case 3: {
            return <BorrowerExperience data={data}/>
        }
        case 4: {
            return <SecurityForm data={data}/>
        }
        case 5: {
            return <ExpertOpinion data={data}/>
        }
        case 6: {
            return <Documentation data={data}/>
        }
        case 7: {
            return <ProjectCalculations data={data}/>
        }
        case 8: {
            return <ReportOnNDFL data={data}/>
        }
        default: {
            return null;
        }
    }
};

export default ProjectDetailContent;
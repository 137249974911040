export const getContractData = (dataFormat, contract, contract_type) => {
    switch (contract_type) {
        case 'ownership':
            return [
                ...dataFormat,
                {name: 'Адрес', desc: contract.address || ''},
                {name: 'Кадастровый номер', desc: contract.number || ''},
                {name: 'Площадь', desc: contract.total_area || ''},
                {name: 'Категория земель', desc: contract.land_category || ''},
                {name: 'Разрешенное использование', desc: contract.permitted_use || ''},
                {name: 'Свидетельство о праве собственности', desc: contract.certificate_number || ''},
                {name: 'Свидетельство о праве собственности от', desc: contract.certificate_date || ''},
                {name: 'Скан свидетельства о праве собственности', desc: contract.certificates || '', type: 'file'},
                {
                    name: 'Выписка из Единого государственного реестра недвижимости, дата',
                    desc: contract.extract_egrn_from || ''
                },
                {name: 'Скан выписки из ЕГРН', desc: contract.extracts || '', type: 'file'},
                {name: 'Иные сведения', desc: contract.other_information || ''},
                {name: 'Иные документы', desc: contract.documents || '', type: 'file'}
            ]
        case 'lease':
            return [
                ...dataFormat,
                {name: 'Адрес', desc: contract.address || ''},
                {name: 'Кадастровый номер', desc: contract.number || ''},
                {name: 'Площадь', desc: contract.total_area || ''},
                {name: 'Категория земель', desc: contract.land_category || ''},
                {name: 'Разрешенное использование', desc: contract.permitted_use || ''},
                {name: 'Срок аренды', desc: contract.rent || ''},
                {
                    name: 'Договор',
                    desc: `Договор ${contract.contract_name} (наименование договора) от ${contract.contract_date} №${contract.contract_number}, заключенный между Залогодателем и ${contract.contract_tenant}, и зарегистрированным ${contract.contract_registered_date} года ${contract.contract_registered_company} (указать наименование органа, зарегистрировавшего договор аренды – из штампа о регистрации) за номером регистрации ${contract.contract_registered_number}`
                },
                {name: 'Иные сведения', desc: contract.other_information || ''},
                {name: 'Иные документы', desc: contract.documents || '', type: 'file'}
            ]
        case 'uses':
            return [
                ...dataFormat,
                {name: 'Адрес', desc: contract.address || ''},
                {name: 'Кадастровый номер', desc: contract.number || ''},
                {name: 'Площадь', desc: contract.total_area || ''},
                {name: 'Категория земель', desc: contract.land_category || ''},
                {name: 'Разрешенное использование', desc: contract.permitted_use || ''},
                {name: 'Срок пользования', desc: contract.uses || ''},
                {name: 'Подтверждающий документ', desc: contract.rent_confirmed || ''},
                {
                    name: contract.rent_confirmed === 'contract' ? 'Договор' : 'Распоряжение',
                    desc: contract.rent_confirmed === 'contract'
                        ? `Договор ${contract.contract_name} (наименование договора) от ${contract.contract_date} №${contract.contract_number}, заключенный между Залогодателем и ${contract.contract_tenant}, и зарегистрированным ${contract.contract_registered_date} года ${contract.contract_registered_company} (указать наименование органа, зарегистрировавшего договор аренды – из штампа о регистрации) за номером регистрации ${contract.contract_registered_number}`
                        : `Распоряжение ${contract.order_company} (указать наименование органа, принявшего решение о предоставлении участка в аренду) от ${contract.order_date} года №${contract.order_number}.`
                },
                {name: 'Иные сведения', desc: contract.other_information || ''},
                {name: 'Иные документы', desc: contract.documents || '', type: 'file'}
            ]
        default:
            return dataFormat
    }

}
import React from 'react';
import {registryPages} from "./helpers";
import {Link} from "react-router-dom";

const Registry = () => {
    const renderRegistryPagesLink = () => {
        return registryPages.map(item => (
            <div key={item.id} className="registry__pagesList_item">
                <Link to={item.link}>
                    {item.title}
                </Link>
            </div>
        ))
    }

    return (
        <div className="registry">
            <h1 className="page-title">Реестры</h1>
            <div className="registry__pagesList">
                {renderRegistryPagesLink()}
            </div>
        </div>
    )
}

export default Registry;

import {
    ADDITIONAL_INFO_EDIT,
    ADDITIONAL_INFO_EDIT_ADD_FACE,
    ADDITIONAL_INFO_EDIT_ADD_FILES,
    ADDITIONAL_INFO_EDIT_DELETE_FACE,
    ADDITIONAL_INFO_EDIT_DELETE_FILES,
    SET_ADDITIONAL_INFO_ERROR,
    SET_COMPANY,
    SET_ERROR,
    SET_PASSPORT,
    SET_PERSONAL,
    SET_RESET_VALIDATE
} from "@shared/components/account/EditProfile/helpers/constants";

export const reducer = (state, action) => {
    let dataCopy;
    let person = '';
    const {type, payload} = action;

    switch (type) {
        case SET_COMPANY: {
            return {
                ...state,
                company: {
                    ...state.company,
                    [payload.name]: payload.value
                }
            }
        }
        case SET_PERSONAL: {
            return {
                ...state,
                personal: {
                    ...state.personal,
                    [payload.name]: payload.value
                }
            }
        }
        case SET_PASSPORT: {
            return {
                ...state,
                passport: {
                    ...state.passport,
                    [payload.name]: payload.value
                }
            }
        }
        case SET_RESET_VALIDATE: {
            return {
                ...state,
                resetValidate: !state.resetValidate,
            }
        }
        case ADDITIONAL_INFO_EDIT:
            person = `person${payload.data.person}`;

            dataCopy = [...state.additionalInfo];
            dataCopy[payload.number][person] = {
                ...dataCopy[payload.number][person],
                [payload.data.name]: {
                    ...dataCopy[payload.number][person][payload.data.name],
                    value: [payload.data.value]
                }
            };

            return {
                ...state,
                additionalInfo: dataCopy,
            }
        case ADDITIONAL_INFO_EDIT_ADD_FILES:
            person = `person${payload.data.person}`

            dataCopy = [...state.additionalInfo];
            dataCopy[payload.number][person] = {
                ...dataCopy[payload.number][person],
                [payload.data.name]: {
                    ...dataCopy[payload.number][person][payload.data.name],
                    value: [...payload.data.value],
                    files: [...payload.data.value]
                }
            };

            console.log(dataCopy)

            return {
                ...state,
                additionalInfo: dataCopy,
            }
        case ADDITIONAL_INFO_EDIT_DELETE_FILES:
            person = `person${payload.data.person}`

            dataCopy = [...state.additionalInfo];
            dataCopy[payload.number][person] = {
                ...dataCopy[payload.number][person],
                [payload.data.name]: {
                    ...dataCopy[payload.number][person][payload.data.name],
                    value: [...payload.data.value.filesId],
                    files: [...payload.data.value.filesNew]
                }
            };

            return {
                ...state,
                additionalInfo: dataCopy,
            }
        case ADDITIONAL_INFO_EDIT_ADD_FACE:
            person = `person${payload.personId}`;
            console.log("payload", payload)

            dataCopy = [...state.additionalInfo];
            dataCopy[payload.number][`person${payload.personId}`] = payload.data;

            return {
                ...state,
                additionalInfo: dataCopy,
            }
        case ADDITIONAL_INFO_EDIT_DELETE_FACE:
            const {number, data} = payload;
            person = `person${data}`;


            dataCopy = [...state.additionalInfo];
            delete dataCopy[number][`person${data}`];

            return {
                ...state,
                additionalInfo: dataCopy,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}

export const errorReducer = (state, action) => {
    let dataCopy;
    const {type, payload} = action;

    switch (type) {
        case SET_ERROR: {
            return {
                ...state,
                [payload.name]: payload.value
            }
        }
        case SET_ADDITIONAL_INFO_ERROR: {
            dataCopy = [...state.additionalInfo];
            dataCopy[payload.number] = payload.value;

            return {
                ...state,
                additionalInfo: dataCopy
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}
import React, {useReducer} from 'react';
import {initState, reducer} from './helpers';
import {setDataValue} from './action';
import FirstStepRegister from "./FirstStepRegister";
import SecondStepRegister from "./SecondStepRegister";

const RegisterForm = () => {
    const [state, dispatch] = useReducer(reducer, initState);

    const setFormError = errors => dispatch({name: 'formError', value: errors})

    const setData = ({target}) => {
        const {name, value} = target;
        dispatch(setDataValue(name, value))
    }

    return (
        <div className="register">
            {
                state.visibleSecondsStage
                    ? <SecondStepRegister
                        state={state}
                        dispatch={dispatch}
                        setData={setData}
                        setFormError={setFormError}
                    />
                    : <FirstStepRegister
                        state={state}
                        dispatch={dispatch}
                        setData={setData}
                        setFormError={setFormError}
                    />
            }
        </div>
    )

};

export default RegisterForm;
import React, {useRef} from 'react';
import {CSVLink} from 'react-csv';
import {paymentTypesCsvReport} from "../data";
import {Button} from "reactstrap";
import {useReactToPrint} from 'react-to-print';
import PaymentTypesTable from './PaymentTypesTable';


const PaymentTypesModal = () => {
    const tableRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
    });

    return (
        <div className="paymentTypesModal__wrap">
            <p className="modalFullScreen__title">
                Подробнее о типах выплат
            </p>
            <div className="paymentTypesModal__btnGroup">
                <CSVLink
                    {...paymentTypesCsvReport}
                    className="btn btn-primary account__btn_primary paymentTypesModal__mediumBtn"
                >
                    Скачать в формате Excel
                </CSVLink>
                <Button
                    color="success"
                    className="account__btn_success addProject__btn paymentTypesModal__smallBtn"
                    onClick={handlePrint}
                    outline
                >
                    Распечатать
                </Button>
            </div>
            <div className="paymentTypesModal__table_wrap">
                <PaymentTypesTable ref={tableRef}/>
            </div>
        </div>
    )
}

export default PaymentTypesModal;
import React from 'react';
import Meeting from "./Meeting";
import classnames from "classnames";

const GoverningStructure = ({state, dispatch, fRef, governingValidStatus, showResult}) => {
    const {type_key} = state;

    const handleTypeSelection = value => {
        dispatch({
            payload: {name: 'type_key', value}
        })
    }

    const classes = classnames({
        'dataCard': true,
        'projectGoverning': true,
        'moderationProject__showResult': showResult
    })

    return (
        <div className={classes} ref={fRef}>
            <p className="dataCard__title">
                Сведения о структуре и персональном составе органов управления юридического лица
            </p>
            <div className="dataCard__inner">
                <div className="addProject__formGroup attractingInvest__mainBtn">
                    <button
                        className={`mainBtn__white from__custom-radio-btn ${type_key === 'collegial_governing_body' && 'active'}`}
                        onClick={() => handleTypeSelection('collegial_governing_body')}
                    >
                        <span className="">Коллегиальный орган управления</span>
                    </button>
                    <button
                        className={`mainBtn__white from__custom-radio-btn ${type_key === 'sole_executive_body' && 'active'}`}
                        onClick={() => handleTypeSelection('sole_executive_body')}
                    >
                        <span>Единоличный исполнительный орган</span>
                    </button>
                    <button
                        className={`mainBtn__white from__custom-radio-btn ${type_key === 'collegial_executive_body' && 'active'}`}
                        onClick={() => handleTypeSelection('collegial_executive_body')}
                    >
                        <span>Коллегиальный исполнительный орган</span>
                    </button>
                </div>
            </div>
            <Meeting state={state} dispatch={dispatch} governingValidStatus={governingValidStatus}/>
        </div>
    )
}

export default GoverningStructure;

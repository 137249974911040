import {withRouter} from "react-router";
import {useEffect} from "react";
import Routers from "@router/Routers";
import {isAuth, RoleHasPermissions} from "@utils/auth";
import {AuthService} from "@services/auth.service";
import {userInfo} from "@actions/userActions";
import {getProfiles} from "@actions/profilesActions";
import {useDispatch} from "react-redux";

const Check = (history, location) => {
    //Массив пути
    const location_path = location.pathname.split('/')

    //Фильтрация по совпадению адресов
    let routerFilter = Routers.filter(router => {
        //Массив пути
        const router_path = router.path.split('/')
        //Если длины массивов не равны
        if (location_path.length !== router_path.length) return false
        //Если все элементы массивов совпадают
        return location_path.every((path, index) => {
            //Если элементы равны
            if (path === router_path[index]) return true
            //Динамический и элемент пути не пустая строка
            return router_path[index][0] === ':' && path !== ""
        })
    })
    //Если не найден подходящий адрес
    if (routerFilter.length === 0) return history.replace('/404')

    //Фильтрация по доступу к адресу
    routerFilter = routerFilter.filter(router => {
        //Авторизован, но адрес доступен неавторизованным
        if (isAuth() && router.noAuth) return false
        //Не авторизован, но адрес доступен авторизованным
        if (!isAuth() && router.auth) return false
        //Если указаны права
        if (router.permissions && Array.isArray(router.permissions)) {
            //Не авторизован
            if (!isAuth()) return false
            //Роль пользователя не присутствует в массиве
            if (router.permissions.length && !RoleHasPermissions(router.permissions))
                return false
        }

        return true
    })
    //Если авторизован и адрес недоступен
    if (isAuth() && routerFilter.length === 0) return history.replace('/403')
    //Если не авторизован и адрес недоступен
    if (!isAuth() && routerFilter.length === 0) return AuthService.redirectToLogin()
}

const RouterContainer = ({children, history}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        let unListen;

        const accessCheck = () => {
            unListen = history.listen(location => Check(history, location)) //Прослушка изменения адресов
            Check(history, history.location) //Проверка доступов
        }

        if (isAuth()) {
            userInfo(dispatch).then(() => {
                getProfiles()(dispatch)
                accessCheck()
            })
        } else {
            accessCheck()
        }

        return () => {
            if (unListen) unListen() //Удаление прослушки
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, isAuth()])

    return children
}

export default withRouter(RouterContainer)
import React from 'react';
import UploadField from "@shared/components/uploadField";
import {DownloadIcon} from "@shared/img/iconSvg";

const CepSignatureFile = () => {
    return (
        <div className='cepSignatureFile'>
            <span className='fontSizeSubtitle cepSignatureFile__title'>Сведения элетронной подписи</span>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Тип подписи: </span>
                <span className='cepSignatureFile__desc_info'>Квалифицированная электронная подпись</span>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Владелец: </span>
                <span className='cepSignatureFile__desc_info'>Иванов Иван Иванович </span>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>ИНН владельца: </span>
                <span className='cepSignatureFile__desc_info'>1234567</span>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Удостоверяющий центр: </span>
                <span className='cepSignatureFile__desc_info'>ООО “Екей УЦ”</span>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Срок действия: </span>
                <span className='cepSignatureFile__desc_info'>с 23.10.2019 13:00 по 23.10.2020 13:50</span>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Дата и время проверки ЭП: </span>
                <span className='cepSignatureFile__desc_info'>12.02.2020 13:00</span>
            </div>
            <div className='cepSignatureFile__document'>
                <span className='fontSizeSubtitle cepSignatureFile__title'>Подписанный документ</span>
                <div className='detailedCalculations__document'>
                    {/*files={[data.company.logo]}*/}
                    <UploadField viewDetail edit={false}/>
                </div>
            </div>
            <div className='cepSignatureFile__desc'>
                <span className='cepSignatureFile__desc_name colorGreyBlue'>Файл подписи: </span>
                <div className="uploadField__download">{DownloadIcon()}</div>
                <span className='cepSignatureFile__desc_info greenColor'>Скачать файл подписи</span>
            </div>
        </div>
    )
};


export default CepSignatureFile;
import React, {useEffect} from 'react';
import UploadField from "@shared/components/uploadField";
import InputText from "@shared/components/form/profileForm/InputText";
import {useDispatch, useSelector} from "react-redux";
import {aboutProjectSelectOptions} from "@containers/Projects/AddProject/forms/ProjectDesc/data";
import {EDIT_PROJECT_COMPANY_ONE_VALUE, setProjectFormError, setProjectFormIsCompleted} from "@actions/projectsActions";
import AddedList from "@shared/components/form/addedList";

const AboutProject = ({fRef, data, projectId}) => {
    const dispatch = useDispatch();
    const {title, description, stage_key, medias, videos} = data;
    const {projectFormError: formError, formIsCompleted} = useSelector(state => state.projects);

    const setFormError = data => {
        setProjectFormError(data, dispatch)
    }

    const setData = event => {
        dispatch({
            type: EDIT_PROJECT_COMPANY_ONE_VALUE,
            payload: {
                name: event.target.name,
                value: event.target.value || null,
            }
        })
    }

    const setDataMedias = event => {
        dispatch({
            type: EDIT_PROJECT_COMPANY_ONE_VALUE,
            payload: {
                name: event.target.name,
                value: event.target.value || [],
            }
        })
    }

    const setDataVideos = (videos = []) => {
        dispatch({
            type: EDIT_PROJECT_COMPANY_ONE_VALUE,
            payload: {
                name: "videos",
                value: videos
            }
        })
    }

    // Проверяем заполнены ли все поля, если да, то активируем кнопку далее
    useEffect(() => {
        let status = true;

        for (let key in data) {
            if (key === 'medias') continue;
            if (!data[key]) status = false;
        }

        if (status !== formIsCompleted.aboutProject) {
            setProjectFormIsCompleted("aboutProject", status, dispatch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    return (
        <div className="addProject__form addProject__form-investments" ref={fRef}>
            <p className="addProject__form_title">О проекте</p>
            <div className="addProject__aboutProject">
                <InputText
                    type="text"
                    formError={formError}
                    name="title"
                    onChange={setData}
                    label="Наименование проекта (5-10 слов)"
                    setFormError={setFormError}
                    value={title || ''}
                    validator={[{maxLength: 100}, {required: true}]}
                    labelClass="requiredMark"
                    containerClassName="addProject__fullInput"
                />
                <InputText
                    type="textarea"
                    formError={formError}
                    name="description"
                    onChange={setData}
                    label="Описание проекта"
                    setFormError={setFormError}
                    value={description || ''}
                    validator={[{maxLength: 100}, {required: true}]}
                    labelClass="requiredMark"
                    containerClassName="addProject__fullInput"
                />
                <div className="inputText__wrapper addProject__fullInput">
                    <label>Медиа (презентации, инфографика, фото6 видео и пр.) (по желанию)</label>
                    <UploadField
                        files={medias}
                        edit={true}
                        parentType="project_id"
                        parentId={projectId}
                        title="Медиа по проекту"
                        onChange={setDataMedias}
                        fileKey="medias"
                        multiple={true}
                        maxFiles={5}
                        formError={formError}
                        setFormError={setFormError}
                        fileType="project_medias"
                        placeholder="макс 5 файлов"
                    />
                </div>
                <AddedList
                    formInput
                    data={videos}
                    onChange={setDataVideos}
                    label="Видео о проекте"
                    placeholder="Ссылки на видео на YouTube"
                />
                <div className="inputText__wrapper addProject__fullInput aboutProject__select">
                    <label className="requiredMark">Стадия проекта</label>
                    <div className="formEdit__selectGroup aboutProject__selectGroup">
                        <div className="aboutProject__selectWrap">
                            {
                                aboutProjectSelectOptions.map(elem => (
                                    <div className="aboutProject__selectInner" key={elem.key}>
                                        <button
                                            className={`from__custom-radio-btn aboutProject__mainSelect ${stage_key === elem.key ? 'active' : ''}`}
                                            onClick={() => setData({target: {name: "stage_key", value: elem.key}})}
                                        >
                                            <span className="aboutProject__selectInner-title">{elem.title}</span>
                                            <span className="aboutProject__selectInner-desc">{elem.label}</span>
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutProject;

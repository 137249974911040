import React from 'react';
import DataCard from "@shared/components/data/DataCard";

const AboutCompany = ({data, fRef}) => {
    const {foundation_year, primary_occupation, site, logo} = data;

    const dataFormat = [
        {name: 'Год основания', desc: foundation_year || ''},
        {name: 'Основной вид деятельности', desc: primary_occupation || ''},
        {name: 'Сайт', desc: site || ''},
        {name: 'Логотип компании', desc: logo ? [logo] : '', type: 'file'},
    ]

    return <DataCard data={dataFormat} title="О компании" fRef={fRef}/>
}

export default AboutCompany;

import React, {useEffect, useState} from 'react';
import {DownloadIcon} from "@shared/img/iconSvg";
import QRCode from 'qrcode.react';
import AccountReplenishmentDetails from '@shared/components/pdf/AccountReplenishmentDetails';
import {DadataFindByIdService} from "@services/dadata.service";
import {requisitesDataInitial, requisitesRequestParams} from './helpers';

const Refill = () => {
    const [qrCodeDataUri, setQrCodeDataUri] = useState(null);
    const [requisites] = useState(requisitesDataInitial);

    useEffect(() => {
        const qrCodeCanvas = document.getElementById('requisitesQRCode');
        if (qrCodeCanvas && !qrCodeDataUri) setQrCodeDataUri(qrCodeCanvas.toDataURL('image/jpg', 0.3))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Получить реквизиты
    useEffect(() => {
        DadataFindByIdService.create(requisitesRequestParams)
            .then(res => {
                if (res?.data?.length && res?.data[0]?.data) {
                }
            })
            .catch(error => console.log(error))
    }, [])

    const requisitesQRData = 'ST00011|NAME=ООО «ИНКРАУД»|BANK_NAME=Точка ПАО Банка «Финансовая Корпорация Открытие»|PERSONAL_ACC=40702810501500068008|PAYEE_INN=9704007489|PURPOSE=Пополнение счета|BIC=044525999|KPP=770401001';

    return (
        <div className="balance__modal">
            <p className="modalFullScreen__title">
                Пополнение счета
            </p>
            <p className="balance__modal_subtitle balance__modal_subtitle-top">
                Для пополнения виртуального счета совершите банковский перевод на номинальный счет платформы INCROWD
            </p>
            <ul className="circle_checklist">
                <li>Перевод осуществляется только в рублях РФ</li>
                <li>Пополнение возможно только со счета владельца профиля</li>
            </ul>
            <div className="balance__modal_requisites">
                <p className="balance__modal_title">Реквизиты платформы INCROWD</p>
                <ul>
                    <li>Получатель платежа: <span>{requisites.payment_receiver}</span></li>
                    <li>Номинальный счет: <span>************</span></li>
                    <li>ИНН получателя: <span>************</span></li>
                    <li>КПП получателя: <span>************</span></li>
                    <li>Наименование банка: <span>************</span></li>
                    <li>БИК банка: <span>************</span></li>
                    <li>Назначение платежа: <span>{requisites.purpose_payment}</span></li>
                </ul>
                <div className="balance__modal_btn">
                    <div className="uploadField__download">
                        <DownloadIcon/>
                    </div>
                    <AccountReplenishmentDetails QRCode={qrCodeDataUri}/>
                </div>
            </div>
            <p className="balance__modal_subtitle balance__modal_subtitle-bottom">
                Для удобства вы можете отсканировать QR в мобильном приложении банк
            </p>
            <p className="balance__modal_text">
                Мобильное приложение вашего банка может не поддерживать
                функцию запыления реквизитов для оплаты с помощью QR-кода
            </p>
            <div className="balance__modal_qrcode">
                <QRCode
                    id='requisitesQRCode'
                    value={requisitesQRData}
                    size={95}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={false}
                    renderAs={"canvas"}
                />
            </div>
        </div>
    )
}

export default Refill;
import React from 'react';
import {Table} from "reactstrap";
import {qualifiedStatusKey} from "@utils/auth";
import {showModal} from "@actions/modalActions";
import {INNER_USERS_QUALIFIED_APPLICATION} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";
import classnames from "classnames";

const ApplicationArchive = ({title, data, className}) => {
    const dispatch = useDispatch();
    const classes = classnames({
        'qualification__archive': true,
        [className]: className
    })

    const showApplication = (qualificationId) => {
        showModal(INNER_USERS_QUALIFIED_APPLICATION, qualificationId)(dispatch)
    }

    return data && data.length
        ? <div className={classes}>
            {
                title
                    ? <h3 className="page-subtitle">{title}</h3>
                    : null
            }
            <div className="qualification__archive_table">
                <Table className="table-align" borderless>
                    <thead>
                    <tr>
                        <th style={{width: '25%'}}>№ заявления</th>
                        <th style={{width: '25%'}}>Дата подачи <br/>заявления</th>
                        <th style={{width: '25%'}}>Дата подтверждения <br/>квалификации</th>
                        <th style={{width: '25%'}}>Дата аннулирования <br/>заявления</th>
                        <th style={{width: '25%'}}/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map(item => {
                            const {created_at, id, profile} = item;
                            const {qualification_status_key, qualified_at, unqualified_at} = profile || {};
                            return (
                                <tr key={id}>
                                    <td className="verticalAlignInherit">{id}</td>
                                    <td className="verticalAlignInherit">{created_at}</td>
                                    <td className="verticalAlignInherit">
                                        {
                                            qualification_status_key === qualifiedStatusKey.qualified
                                                ? qualified_at
                                                : '-'
                                        }
                                    </td>
                                    <td className="verticalAlignInherit">
                                        {
                                            ['qualification_not_qualified', 'qualification_rejected']
                                                .includes(qualification_status_key)
                                                ? unqualified_at
                                                : '-'
                                        }
                                    </td>
                                    <td className="verticalAlignInherit">
                                        <button
                                            className="accStatusNotice__moreDetails btn-reset"
                                            onClick={() => showApplication(id)}
                                        >
                                            Подробнее
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </div>
        </div>
        : null
}

export default ApplicationArchive;
import React, {useState} from 'react';
import {Collapse} from 'reactstrap';
import {RightArrowIcon} from "@shared/img/iconSvg";
import classNames from "classnames";
import {mockEventsLog} from "./helpers";
import PrimaryTooltip from "@shared/components/tooltip";

const ProjectEvents = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const icon = classNames({
        'projectEvents__icon': true,
        'iconRotate': isOpen
    })

    return (
        <div className='projectEvents'>
            <div onClick={toggle} className="cursor-pointer">
                <button className='projectEvents__button btn-reset'>События проекта</button>
                <RightArrowIcon className={icon}/>
            </div>
            <Collapse isOpen={isOpen}>
                <div className='projectEvents__block'>
                    <div className='projectEvents__header projectEvents__item'>
                        <div>Дата</div>
                        <div className="projectEvents__header_item">
                            <span>Событие</span>
                            <PrimaryTooltip title="?" className="profileTabs__tabItem_tooltip">
                                <p>
                                    1. Проект перешел к стадии подписания документов.
                                    2. Проект перешел к стадии выплат
                                    3. Проект перешел в просрочку
                                    4. Проект завершен
                                    5. Проект отменен
                                    6. Проект не состоялся
                                </p>
                            </PrimaryTooltip>
                        </div>
                    </div>

                    {mockEventsLog.map((elem) =>
                        <div key={elem.id} className='projectEvents__item'>
                            <div>{elem.date}</div>
                            <div>{elem.log}</div>
                        </div>
                    )}

                </div>
            </Collapse>
        </div>
    );
};

export default ProjectEvents;
import React, {useState} from 'react';
import cn from "classnames";
import {Button, Spinner} from "reactstrap";
import RegisterFormBackBtn from "../RegisterFormBackBtn";
import RegisterConfirmationField from "../FirstStepRegister/RegisterConfirmationField";
import RegisterConfirmCodeField from "../FirstStepRegister/RegisterConfirmCodeField";
import RegisterAccountNameField from "../FirstStepRegister/RegisterAccountNameField";
import RegisterCheckbox from "../FirstStepRegister/RegisterCheckbox";
import {renderFioCheckBoxDesc} from "../FirstStepRegister/RegisterCheckbox/helpers";
import {checkAccountNameValidate, checkSendData} from './helpers';
import {AuthService} from "@services/auth.service";
import {userInfo} from "@actions/userActions";
import {useHistory} from "react-router-dom";
import constants from '../action';
import {useDispatch} from "react-redux";

const SecondStepRegister = props => {
    const history = useHistory();
    const reduxDispatch = useDispatch();
    const [formSendError, setFormSendError] = useState('');
    const [createAccountStatus, setCreateAccountStatus] = useState(null);
    const {state, dispatch} = props;
    const {data, noteRoleBtn, formError} = state;
    const {legal_form_type, role, phone} = data;

    const IndivLegalFormTypeBtn = () => {
        return role === 'investor'
            ? <button
                className={cn({
                    'from__custom-radio-btn': true,
                    'requiredFill': noteRoleBtn,
                    'active': legal_form_type === 'indiv'
                })}
                onClick={() => setLegalFormType('indiv')}
            >
                <span>физ. лицо</span>
            </button>
            : null
    }

    const createAccount = () => {
        dispatch({name: 'formValidationCheck', value: true})
        const dataToSend = checkSendData(state);

        if (dataToSend && !formError.length && checkAccountNameValidate(data)) {
            setCreateAccountStatus(true)
            AuthService.createAccount(dataToSend)
                .then(() => {
                    setCreateAccountStatus(false)
                    userInfo(reduxDispatch).then(() => history.push('/')) // Перенаправляем на главную
                })
                .catch(() => {
                    setCreateAccountStatus(false)
                    setFormSendError('Неизвестная ошибка!')
                    setTimeout(() => setFormSendError(''), 5000)
                })
        }
    }

    const setLegalFormType = type => {
        dispatch({
            type: constants.SET_LEGAL_FORM_TYPE,
            payload: type
        })
    }

    return (
        <div className="register__step register-form register-form-between">
            <div className="form__group-wrap">
                <div className="register__radioBtnGroup">
                    <IndivLegalFormTypeBtn/>
                    <button
                        className={cn({
                            'from__custom-radio-btn': true,
                            'requiredFill': noteRoleBtn,
                            'active': legal_form_type === 'entity'
                        })}
                        onClick={() => setLegalFormType('entity')}
                    >
                        <span>юр. лицо</span>
                    </button>
                    <button
                        className={cn({
                            'from__custom-radio-btn': true,
                            'requiredFill': noteRoleBtn,
                            'active': legal_form_type === 'ie'
                        })}
                        onClick={() => setLegalFormType('ie')}
                    >
                        <span>ип</span>
                    </button>
                    <RegisterFormBackBtn dispatch={dispatch} stateKey="noteRoleBtn" parentKey={legal_form_type}/>
                </div>
                <RegisterConfirmationField {...props} type="phone"/>
                <RegisterConfirmCodeField
                    {...props}
                    parentKey="phone_code"
                    parentValue={phone}
                />
                {
                    legal_form_type === 'entity'
                        ? <RegisterAccountNameField {...props} type='company'/>
                        : <>
                            <RegisterAccountNameField {...props} type='fio'/>
                            <div className="middle-name-check">
                                <RegisterCheckbox
                                    dispatch={dispatch}
                                    state={state}
                                    type="without_patronymic"
                                    renderDesc={renderFioCheckBoxDesc}
                                />
                            </div>
                        </>
                }

            </div>
            <div className="form__group-wrap">
                <div className={`form__send_error ${formSendError ? 'active' : ''}`}>
                    <span>{formSendError}</span>
                </div>
                <div className="register__mainBtn">
                    <Button color="primary" className="w-100" onClick={createAccount}>
                        <div className="btn-spinner-group">
                            <Spinner className={`btn-spinner ${createAccountStatus ? 'btn-spinner-visible' : ''}`}/>
                            Создать аккаунт
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default SecondStepRegister;
import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import {
    propertyPremiseContractSelectOptions,
    propertyPremiseSelectOptions
} from "@containers/Projects/AddProject/forms/Pledge/data";
import {getContractData} from "@shared/components/presentation/PledgesProject/helpers";
import MediaPresentation from "@shared/components/presentation/MediaPresentation";

const Premise = ({data, idx, fRef}) => {
    const type = data.type.value;
    const contract = data.premise.contract;
    const {
        address, number, total_area, residential_total_area, rooms, floors, floors_number, premise_type, plans,
        certificate_number, certificate_date, certificates, extract_egrn_from, extracts, other_information, documents,
        images, contract_type
    } = data.premise;

    const dataFormat = [
        {name: type, type: 'subtitle'},
        {name: 'Адрес', desc: address || ''},
        {name: 'Условный / кадастровый номер', desc: number || ''},
        {name: 'Общая площадь помещений', desc: total_area || ''},
        {name: 'Общая площадь здания', desc: residential_total_area || ''},
        {name: 'Номер(а) комнат(ы)', desc: rooms || ''},
        {name: 'Этажи', desc: floors || ''},
        {name: 'Общая этажность здания', desc: floors_number || ''},
        {
            name: 'Тип здания',
            desc: propertyPremiseSelectOptions.filter(item => item.value === premise_type)[0].label || ''
        },
        {name: 'Технический план (скан)', desc: plans || '', type: 'file'},
        {name: 'Свидетельство о праве собственности', desc: certificate_number || ''},
        {name: 'Свидетельство о праве собственности от', desc: certificate_date || ''},
        {name: 'Скан свидетельства о праве собственности', desc: certificates || '', type: 'file'},
        {name: 'Выписка из Единого государственного реестра недвижимости, дата', desc: extract_egrn_from || ''},
        {name: 'Скан выписки', desc: extracts || '', type: 'file'},
        {name: 'Иные сведения', desc: other_information || ''},
        {name: 'Иные документы', desc: documents || '', type: 'file'},
        {
            name: 'Фото залога',
            desc: images ? <MediaPresentation files={images} className="moderationProject__medias"/> : ''
        },
        {name: 'Имущественные права на земельный участок', type: 'subtitle'},
        {
            name: propertyPremiseContractSelectOptions.filter(item => item.value === contract_type)[0].label || '',
            type: 'subtitle'
        },
    ];

    return <DataCard data={getContractData(dataFormat, contract, contract_type)} title={`Залог ${idx + 1}`}
                     fRef={fRef}/>
}

export default Premise;

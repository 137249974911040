import {BaseService} from "@services/base.service";

export class LogsOuterService extends BaseService {
    static get entity() {
        return '/logs/outer'
    }
}

export class LogsProfilesService extends BaseService {
    static get entity() {
        return '/logs/profiles'
    }
}
import React from 'react';
import classNames from "classnames";
import {Button, Spinner} from "reactstrap";
import PropTypes from "prop-types";

const ProjectsFeedInfo = ({
                              Icon,
                              desc,
                              btnTitle,
                              error,
                              btnOnClick,
                              smallBtn,
                              BtnIcon,
                              btnClassName,
                              disabledBtn,
                              btnSpinnerStatus
                          }) => {
    const projectsFeedInfoClass = classNames({
        projectsFeed__info: true,
        projectsFeed__header_item: true,
        'projectsFeed__info-error': error,
    });

    const projectsFeedInfoBtnClass = classNames({
        account__btn_success: true,
        projectsFeed__info_smallBtn: smallBtn,
        [btnClassName]: btnClassName
    });

    return (
        <div className={projectsFeedInfoClass}>
            <div className="projectsFeed__info_group">
                <div className="projectsFeed__info_icon">
                    <Icon/>
                </div>
                <div className="projectsFeed__info_desc">
                    <p>{desc}</p>
                </div>
            </div>
            <div className="projectsFeed__info_btn">
                {
                    btnTitle
                        ? <Button
                            color="success"
                            className={projectsFeedInfoBtnClass}
                            onClick={btnOnClick}
                            disabled={disabledBtn}
                            outline
                        >
                            <div className="btn-spinner-group">
                                {
                                    btnSpinnerStatus
                                        ? <>
                                            <Spinner
                                                className={`btn-spinner ${btnSpinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                                            <span>{btnTitle}</span>
                                        </>
                                        : <div>
                                            {BtnIcon ? <BtnIcon/> : null}
                                            <span>{btnTitle}</span>
                                        </div>
                                }
                            </div>
                        </Button>
                        : null
                }
            </div>
        </div>
    )
}

ProjectsFeedInfo.propTypes = {
    error: PropTypes.bool,
    BtnIcon: PropTypes.func,
    smallBtn: PropTypes.bool,
    btnOnClick: PropTypes.func,
    disabledBtn: PropTypes.bool,
    btnClassName: PropTypes.string,
    Icon: PropTypes.func.isRequired,
    desc: PropTypes.string.isRequired,
    btnTitle: PropTypes.string.isRequired,
    btnSpinnerStatus: PropTypes.bool,
};

ProjectsFeedInfo.defaultProps = {
    error: false,
    BtnIcon: null,
    smallBtn: false,
    btnClassName: null,
    disabledBtn: false,
    btnSpinnerStatus: false,
    btnOnClick: () => {
    },
};

export default ProjectsFeedInfo;
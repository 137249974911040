import React, {useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {ogrnMask} from "@shared/components/account/helpers";
import Select from "react-select";
import {propertyLandSelectOptions} from "@containers/Projects/AddProject/forms/Pledge/data";
import UploadField from "@shared/components/uploadField";
import {CalendarIcon} from "@shared/img/iconSvg";

const DDU = ({data, setData, formError, setFormError, projectId}) => {
    const [selectValue, setSelectValue] = useState({value: 'ga', label: 'га'});
    const {
        number, date, developer, ogrn_developer, registration_region, registration_number, sum, address, total_area,
        floor, floors_number, conditional_number, transfer_date, other_information, plans, documents
    } = data;

    const handleOnChangeSelect = data => {
        setSelectValue(data);
        setData({
            target: {
                name: 'total_area_type',
                value: data.value || null,
            }
        })
    }

    return (
        <div className="pledge__forms">
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Договор</label>
                <div className="contractBlock inputErrorGroup">
                    <span>Договор участия в долевом строительстве (ДДУ) №</span>
                    <InputText
                        formError={formError}
                        name="number"
                        id="numberInput"
                        onChange={setData}
                        noLabel={true}
                        setFormError={setFormError}
                        type="text"
                        value={number || ''}
                        validator={[{maxLength: 50}, {required: true}]}
                        containerClassName="addProject__contractInput addProject__contractInput_name addProject__contractInput_number"
                    />
                    <span>от</span>
                    <InputText
                        name="date"
                        id="dateInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setData}
                        noLabel={true}
                        type="date"
                        value={date || ''}
                        validator={[{required: true}]}
                        containerClassName="addProject__contractInput addProject__dateInput date-picker addProject__contractInput_date mr-0"
                    />
                    <span>, заключенного между Залогодателем (Участник долевого строительства) и</span>
                    <InputText
                        formError={formError}
                        name="developer"
                        id="developerInput"
                        onChange={setData}
                        noLabel={true}
                        setFormError={setFormError}
                        type="text"
                        value={developer || ''}
                        validator={[{maxLength: 100}, {required: true}]}
                        containerClassName="addProject__contractInput addProject__contractInput_tenant"
                    />
                    <span>(Застройщик)</span>
                </div>
            </div>
            <InputText
                name="ogrn_developer"
                id="ogrnDeveloperInput"
                onChange={setData}
                label="ОГРН (Застройщик)"
                formError={formError}
                setFormError={setFormError}
                type="mask"
                value={ogrn_developer || ''}
                validator={[{numbersCount: 13}, {required: true}]}
                mask={ogrnMask}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__smallInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Регистрация</label>
                <div className="contractBlock inputErrorGroup">
            <span>
              Государственная регистрация права участника долевого строительства на объект долевого
              строительства осуществлена Управлением Федеральной службы государственной
              регистрации кадастра и картографии по
            </span>
                    <InputText
                        formError={formError}
                        name="registration_region"
                        id="registrationRegionInput"
                        onChange={setData}
                        noLabel={true}
                        setFormError={setFormError}
                        type="text"
                        value={registration_region || ''}
                        validator={[{maxLength: 50}, {required: true}]}
                        containerClassName="addProject__contractInput addProject__contractInput_name addProject__contractInput_number"
                    />
                    <span>(регион), за номером</span>
                    <InputText
                        formError={formError}
                        name="registration_number"
                        id="registrationNumberInput"
                        onChange={setData}
                        noLabel={true}
                        setFormError={setFormError}
                        type="text"
                        value={registration_number || ''}
                        validator={[{maxLength: 50}, {required: true}]}
                        containerClassName="addProject__contractInput addProject__contractInput_name addProject__contractInput_tenant"
                    />
                </div>
            </div>
            <InputText
                name="sum"
                id="sumInput"
                onChange={setData}
                label="Уплаченная сумма застройщику (в соответствии с ДДУ)"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={sum || ''}
                validator={[{maxNumber: 20}, {onlyNumbers: true}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__smallInput"
            />
            <InputText
                formError={formError}
                name="address"
                id="addressInput"
                onChange={setData}
                label="Адрес жилого помещения"
                setFormError={setFormError}
                type="text"
                value={address || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <div className="addProject__landSelectGroup">
                <InputText
                    name="total_area"
                    id="totalAreaInput"
                    formError={formError}
                    setFormError={setFormError}
                    onChange={setData}
                    label="Общая проектная площадь (без учета летних помещений)"
                    value={total_area || ''}
                    labelClass="requiredMark"
                    validator={[{maxLength: 10}, {required: true}]}
                    inputWithDesc
                    inputWithDescValue={
                        <Select
                            options={propertyLandSelectOptions}
                            value={selectValue}
                            isSearchable={false}
                            className="pledge__select pledge__landSelect_wrap"
                            classNamePrefix="pledge__landSelect"
                            onChange={handleOnChangeSelect}
                        />
                    }
                    containerClassName="addProject__middleInput"
                />
            </div>
            <InputText
                name="floor"
                id="floorInput"
                onChange={setData}
                label="Этаж"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={floor || ''}
                validator={[{maxLength: 3}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__smallInput"
                inputWrap={true}
            />
            <InputText
                name="floors_number"
                id="floorsNumberInput"
                onChange={setData}
                label="Общая этажность здания"
                formError={formError}
                setFormError={setFormError}
                type="text"
                value={floors_number || ''}
                validator={[{maxLength: 10}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__middleInput"
                inputWrap={true}
            />
            <InputText
                formError={formError}
                name="conditional_number"
                id="conditionalNumberInput"
                onChange={setData}
                label="Условный номер жилого помещения"
                setFormError={setFormError}
                type="text"
                value={conditional_number || ''}
                validator={[{maxLength: 50}, {required: true}]}
                labelClass="requiredMark"
                inputWrap={true}
                containerClassName="addProject__middleInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Технический план (скан)</label>
                <UploadField
                    files={plans}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Технический план"
                    onChange={setData}
                    fileKey="plans"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <InputText
                name="transfer_date"
                id="transferDateInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Срок передачи жилого помещения Застройщиком до"
                type="date"
                value={transfer_date || ''}
                validator={[{required: true}]}
                labelClass="requiredMark"
                inputWithDesc
                inputWithDescValue={<CalendarIcon/>}
                containerClassName="addProject__middleInput addProject__dateInput date-picker"
            />
            <InputText
                rows={2}
                type="textarea"
                onChange={setData}
                formError={formError}
                name="other_information"
                value={other_information || ''}
                id="otherInformationInput"
                setFormError={setFormError}
                validator={[{maxLength: 500}]}
                label="Иные сведения"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Иные документы</label>
                <UploadField
                    files={documents}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Иные документы"
                    onChange={setData}
                    fileKey="documents"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
        </div>
    )
}

export default DDU;
import React from 'react';
import {Spinner} from "reactstrap";
import inputErrorIcon from "@shared/img/account/inputError.png";

const RegisterConfirmCodeService = ({formError, parentKey, spinner, clearInputValue}) => {
    const inputErrorStatus = formError.includes(parentKey);

    if (spinner) return (
        <div className="form__input-icon-wrap">
            <Spinner style={{color: '#58BF8F'}}/>
        </div>
    )

    if (inputErrorStatus) return (
        <div
            className="form__input-icon-wrap form__input-icon-check from__input-error-icon"
            onClick={clearInputValue}
        >
            <img src={inputErrorIcon} alt="error"/>
        </div>
    )

    return null
};

export default RegisterConfirmCodeService;
import {SET_MODERATION_PROFILE_ID} from "@actions/moderationAction";

const initialState = {
    moderationProfileId: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_MODERATION_PROFILE_ID:
            return {
                ...state,
                moderationProfileId: action.payload
            };
        default:
            return state;
    }
}

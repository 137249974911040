export const requisitesDataInitial = {
    payment_receiver: 'ООО «ИНКРАУД»',
    nominal_account: '',
    inn: '',
    kpp: '',
    name_bank: '',
    bik: '',
    purpose_payment: 'Пополнение счета'
}

export const requisitesRequestParams = {
    name: 'party',
    query: process.env.REACT_APP_INCROWD_INN,
    branch_type: "MAIN"
}
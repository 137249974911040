export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS';
export const UNSET_MODAL_DATA = 'UNSET_MODAL_DATA';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const UNSET_UPDATE_PARENT_DATA = 'UNSET_UPDATE_PARENT_DATA';

export const showModal = (type, data) => dispatch => dispatch({
    type: SHOW_MODAL,
    payload: {type, data}
})

export const hideModal = (type, success, data) => dispatch => dispatch({
    type: HIDE_MODAL,
    payload: {type, success, data}
})

export const hideAllModals = success => dispatch => dispatch({
    type: HIDE_ALL_MODALS,
    payload: {success}
})

export const setModalData = (type, data) => dispatch => dispatch({
    type: SET_MODAL_DATA,
    payload: {type, data}
})

export const unsetModalData = (type, data) => dispatch => dispatch({
    type: UNSET_MODAL_DATA,
    payload: {type, data}
})

export const unsetUpdateParentData = () => dispatch => dispatch({
    type: UNSET_MODAL_DATA
})
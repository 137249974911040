import React from 'react';
import classnames from "classnames";
import {SET_SORTED} from "@containers/Moderator/Registry/RegistryProfile/helpers";

const RegistryTableFilterSorted = ({name, orderBy, sortedBy, filterDispatch}) => {
    const getSortedAscClasses = () => {
        const keyIdx = orderBy.indexOf(name);

        if (keyIdx !== -1) return sortedBy[keyIdx]
    }

    const handleOnChangeSorted = (key, sort) => {
        filterDispatch({
            type: SET_SORTED,
            payload: {key, sort}
        })
    }

    return (
        <div className="registryTable__filterDate_controls">
            <button
                className={classnames({
                    'btn-reset': true,
                    'active': getSortedAscClasses() === 'asc'
                })}
                onClick={() => handleOnChangeSorted(name, 'asc')}
            >
                По возрастанию
            </button>
            <button
                className={classnames({
                    'btn-reset': true,
                    'active': getSortedAscClasses() === 'desc'
                })}
                onClick={() => handleOnChangeSorted(name, 'desc')}
            >
                По убыванию
            </button>
        </div>
    )
}

export default RegistryTableFilterSorted;
import {BaseService} from "@services/base.service";
import {ErrorWrapper, ResponseWrapper} from "@utils/wrapper";

export class ConfirmationsProfilePhoneService extends BaseService {
    static get entity() {
        return '/confirmations/send/profile-phone'
    }
}

export class ConfirmationsProfilePhoneCheckService extends BaseService {
    static get entity() {
        return '/confirmations/check/profile-phone'
    }
}

export class ConfirmationsAdminService extends BaseService {
    static get entity() {
        return '/confirmations/admin'
    }
}
import React, {useState} from 'react';
import {getApplicationData, getOtherInfoDataFormat} from './helpers';
import {QualificationsRemarksService} from "@services/qualifications.service";
import ApplicationCardList from "@shared/components/presentation/Qualification/ApplicationCardList";
import ApplicationOtherInformation from "@shared/components/presentation/Qualification/ApplicationOtherInformation";

const ApplicationData = ({data, getQualificationData, primaryCheck}) => {
    const dataFormat = getApplicationData(data, primaryCheck);
    const otherInfoDataFormat = getOtherInfoDataFormat(data?.remarks || []);
    //const [otherInfo, setOtherInfo] = useState([]);
    const [showAddOtherInfoForm, setShowAddOtherInfoForm] = useState(false);
    const [statusAddNewOtherInfo, setStatusAddNewOtherInfo] = useState(false);

    const addNewOtherInfo = message => {
        setStatusAddNewOtherInfo(true);

        QualificationsRemarksService.create({
            "qualification_id": data.id,
            "remark": message
        })
            .then(() => {
                //setOtherInfo([]);
                getQualificationData().then(() => {
                    setStatusAddNewOtherInfo(false)
                    setShowAddOtherInfoForm(false)
                })
            })
            .catch(() => {
                setStatusAddNewOtherInfo(false)
                setShowAddOtherInfoForm(false)
            })
    }

    return (
        <div className="dataCard">
            <p className="dataCard__title">Данные заявления</p>
            <div className="dataCard__inner">
                <ApplicationCardList data={dataFormat}/>
                <ApplicationOtherInformation
                    otherInfoList={otherInfoDataFormat}
                    addNewOtherInfo={addNewOtherInfo}
                    addStatus={statusAddNewOtherInfo}
                    showAddForm={showAddOtherInfoForm}
                    setShowAddForm={setShowAddOtherInfoForm}
                />
            </div>
        </div>
    )
}

export default ApplicationData;
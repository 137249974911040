import React, {memo, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {LeftArrowIcon} from "@shared/img/iconSvg";
import {Spinner} from "reactstrap";
import ProjectDetailHeader from "@shared/components/projects/projectDetail/ProjectDetailHeader";
import ProjectDetailMenu from "@shared/components/projects/projectDetail/ProjectDetailMenu";
import {getSingleProject} from "@actions/projectsActions";
import ProjectDetailInvestorContent
    from "@containers/Projects/ProjectDetail/ProjectDetailInvestor/ProjectDetailInvestorContent";

const ProjectDetailInvestor = memo(() => {
    const {id} = useParams();
    const history = useHistory();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setLoading(true);
        getSingleProject(id)
            .then(data => setData(data))
            .finally(() => setLoading(false))
    }, [id])

    const redirectToProjectList = () => history.push('/');

    return (
        <div className="projectDetail__container">
            <div className='projectDetail__feed' onClick={redirectToProjectList}>
                <div className='projectDetail__feed__button'><LeftArrowIcon/></div>
                <span>Лента проектов</span>
            </div>
            <div className='projectDetail__wrap'>
                {
                    loading && (
                        <div className="projectsFeed__load">
                            <Spinner style={{width: '3rem', height: '3rem'}}/>
                        </div>
                    )
                }
                {
                    data && (
                        <div className="projectDetail">
                            <ProjectDetailHeader data={data}/>
                            <ProjectDetailMenu data={data} activeTab={activeTab} setActiveTab={setActiveTab}/>
                            <ProjectDetailInvestorContent activeTab={activeTab} data={data}/>
                        </div>
                    )
                }
            </div>
        </div>
    );
});

export default ProjectDetailInvestor;
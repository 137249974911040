export const transactions = [
    {id: 0, name: 'Дата платежа', desc: '17.01.2021'},
    {id: 1, name: 'Погашение пени', desc: '400,00 ₽'},
    {id: 2, name: 'Сумма платежа', desc: '10 000,00 ₽'},
    {id: 3, name: 'Остаток основного долга после платежа', desc: '100 000,00 ₽ '},
    {id: 4, name: 'Погашение основного долга', desc: '9 000,00 ₽'},
    {id: 5, name: 'Способ пересчета графика платежей', desc: 'Изменение срока договора'},
    {id: 6, name: 'Погашение процентов', desc: '600,00 ₽'},
]

export const plannedInfoBlock = [
    {
        id: 0,
        title: 'Оплачено',
        dutyName: 'Основной долг',
        dutyDesc: '100 000, 00 ₽ ',
        interestName: 'Проценты',
        interestDesc: '2 000, 00 ₽',
        penaltiesName: 'Пени',
        penaltiesDesc: '12 000, 00 ₽',
    },
    {
        id: 2,
        title: 'Начисленные пени',
        dutyName: 'Сумма',
        dutyDesc: '100 000, 00 ₽ ',
        interestName: 'НДФЛ с пени',
        interestDesc: '12, 00 ₽',
        penaltiesName: 'Дней просрочки',
        penaltiesDesc: '12',
    },
    {
        id: 2,
        title: 'Остаток к оплате',
        dutyName: 'Основной долг',
        dutyDesc: '100 000, 00 ₽ ',
        interestName: 'Проценты',
        interestDesc: '2 000, 00 ₽',
        penaltiesName: 'Пени',
        penaltiesDesc: '12 000, 00 ₽',
    },
]

export const plannedTransactions = [
    [
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
    ],
    [
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
    ],
    [
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
    ],
    [
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
    ],
    [
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
    ],
    [
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
        {id: 0, name: 'Дата платежа', desc: '17.01.2021 '},
    ],
]
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {turnOffLoading, turnOnLoading} from "@redux/actions/loadingActions";

const AppLoading = () => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading)

    useEffect(() => {
        turnOnLoading()(dispatch)
        window.addEventListener('load', () => setTimeout(() => turnOffLoading()(dispatch), 500));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return loading.loading
        ? <div className="load">
            <div className="load__icon-wrap">
                <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                </svg>
            </div>
        </div>
        : null
}

export default AppLoading;
import React, {useEffect, useState} from 'react';
import CheckIcon from "mdi-react/CheckIcon";
import classNames from "classnames";
import {setDataValue} from "@shared/components/form/registerForm/action";

const RegisterCheckbox = ({state, dispatch, type, renderDesc, isRequired}) => {
    const [touched, setTouched] = useState(null);
    const {data, formValidationCheck, formError} = state;
    const checkBoxValue = data[type];

    const checkboxContainerClass = classNames({
        'checkbox-btn__checkbox-custom': true,
        errorInput: isRequired && formError.includes(type)
    })

    const handleOnChange = ({target}) => {
        setTouched(true)
        dispatch(setDataValue(target.name, target.checked))
    }

    useEffect(() => {
        if ((isRequired && formValidationCheck) || (isRequired && touched)) {
            const index = formError.indexOf(type);
            const newFormError = [...formError];

            if (checkBoxValue && index !== -1) newFormError.splice(index, 1)
            if (!checkBoxValue && index === -1) newFormError.push(type)

            dispatch({name: 'formError', value: newFormError})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkBoxValue, formValidationCheck])

    return (
        <label className="checkbox-btn checkbox-btn--colored" htmlFor={type}>
            <input
                className="checkbox-btn__checkbox"
                type="checkbox"
                name={type}
                id={type}
                onChange={handleOnChange}
                checked={checkBoxValue}
            />
            <span className={checkboxContainerClass} style={{background: checkBoxValue ? '#58BF91' : '#8797b630'}}>
                <CheckIcon/>
            </span>
            <span className="checkbox-btn__label checkbox__label-accept">
                {renderDesc ? renderDesc() : null}
            </span>
        </label>

    );
};

export default RegisterCheckbox;
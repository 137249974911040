import Select from "react-select";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import UploadField from "@shared/components/uploadField";
import InputText from "@shared/components/form/profileForm/InputText";
import {aboutCompanyRequiredKey, financeSelectOptions} from "@containers/Projects/AddProject/forms/Pledge/data";
import {EDIT_PROJECT_VALUE, setProjectFormError, setProjectFormIsCompleted} from "@actions/projectsActions";

const AddProjectAboutCompany = ({fRef, data, projectId}) => {
    const dispatch = useDispatch();
    const legalFormType = useSelector(state => state.user.profile.legal_form_type);
    const [selectValue, setSelectValue] = useState({value: 'profit', label: 'Прибыль'});
    const {projectFormError: formError, formIsCompleted} = useSelector(state => state.projects);
    const {
        primary_occupation, additional_facts, foundation_year, no_site, site,
        finance, structure_details, persons_information, revenue, logo
    } = data;

    const setFormError = data => {
        setProjectFormError(data, dispatch)
    }

    const setData = event => {
        dispatch({
            type: EDIT_PROJECT_VALUE,
            payload: {
                name: event.target.name,
                value: event.target.value || null,
                dataKey: 'company',
            }
        })
    }

    const handleNoSite = event => {
        setData(event);

        dispatch({
            type: EDIT_PROJECT_VALUE,
            payload: {
                name: 'site',
                value: null,
                dataKey: 'company',
            }
        })

        const index = formError.indexOf("site");
        if (index !== -1) {
            const newFormError = [...formError];
            newFormError.splice(index, 1)
            setFormError(newFormError)
        }
    }

    const handleOnChangeSelect = (data, type, setSelect) => {
        setSelect(data);
        setData({target: {name: 'finance_type', value: data.value}})
    }

    // Проверяем заполнены ли все поля, если да, то активируем кнопку далее
    useEffect(() => {
        let status = true;

        Object.keys(data).forEach(key => {
            if (key === 'site') {
                if (!data['no_site'] && !data[key]) status = false;
            } else {
                if (aboutCompanyRequiredKey.includes(key)) {
                    if ((Array.isArray(data[key]) && data[key].length === 0) || !data[key]) status = false;
                }
            }
        })

        if (status !== formIsCompleted.addProjectAboutCompany) {
            setProjectFormIsCompleted("addProjectAboutCompany", status, dispatch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])


    return (
        <div className="addProject__form addProject__form-investments" ref={fRef}>
            <p className="addProject__form_title">О компании</p>
            <div className="addProject__investments">
                {
                    legalFormType === "entity"
                        ? <>
                            <InputText
                                rows={2}
                                type="textarea"
                                onChange={setData}
                                formError={formError}
                                name="structure_details"
                                value={structure_details || ''}
                                id="structureDetailsInput"
                                setFormError={setFormError}
                                validator={[{maxLength: 500}]}
                                label="Сведения о структуре и персональном составе органов управления юридического лица"
                                containerClassName="addProject__fullInput"
                            />
                            <InputText
                                rows={2}
                                type="textarea"
                                onChange={setData}
                                formError={formError}
                                name="persons_information"
                                value={persons_information || ''}
                                id="personsInformationInput"
                                setFormError={setFormError}
                                validator={[{maxLength: 500}]}
                                label="Сведения о лицах, имеющих право распоряжаться не менее чем 10 процентами
                      голосов в высшем органе управления юридического лица"
                                containerClassName="addProject__fullInput"
                            />
                        </>
                        : ''
                }
                <InputText
                    rows={2}
                    type="textarea"
                    onChange={setData}
                    formError={formError}
                    name="primary_occupation"
                    labelClass="requiredMark"
                    value={primary_occupation || ''}
                    id="primaryOccupationInput"
                    setFormError={setFormError}
                    validator={[{maxLength: 500}, {required: true}]}
                    label="Основной вид деятельности"
                    containerClassName="addProject__fullInput"
                />

                <InputText
                    rows={2}
                    type="textarea"
                    onChange={setData}
                    formError={formError}
                    value={additional_facts || ''}
                    name="additional_facts"
                    id="additionalFactsInput"
                    setFormError={setFormError}
                    label="Дополнительные факты"
                    validator={[{maxLength: 500}]}
                    containerClassName="addProject__fullInput"
                />

                <InputText
                    type="text"
                    onChange={setData}
                    formError={formError}
                    label="Год основания"
                    name="foundation_year"
                    value={foundation_year || ''}
                    id="foundationYearInput"
                    labelClass="requiredMark"
                    setFormError={setFormError}
                    validator={[
                        {digitLimit: 4},
                        {minNumber: 1700},
                        {maxNumber: new Date().getFullYear()},
                        {onlyNumbers: true},
                        {required: true}
                    ]}
                    validatorMessages={{
                        digitLimit: 'Максимальная длина 4 цифры!',
                        maxNumber: 'Год основания не может превышать текущий год!',
                    }}
                    containerClassName="addProject__smallInput"
                />

                {
                    no_site
                        ? null
                        : <InputText
                            name="site"
                            type="text"
                            value={site || ''}
                            id="siteInput"
                            formError={formError}
                            setFormError={setFormError}
                            onChange={setData}
                            label="Сайт компании"
                            labelClass="requiredMark"
                            validator={[{maxLength: 50}, {required: true}]}
                            containerClassName="addProject__fullInput addProject__aboutCompany-site"
                        />
                }

                <InputText
                    name="no_site"
                    type="checkbox"
                    value={no_site || ''}
                    id="noSiteInput"
                    onChange={handleNoSite}
                    label="У компании нет сайта"
                    containerClassName={`addProject__checkbox ${!no_site ? 'mt-0' : ''}`}
                />

                <div className="inputText__wrapper profileTabs__edit_item addProject__fullInput">
                    <label>Логотип компании</label>
                    <UploadField
                        files={logo}
                        edit={true}
                        parentType="project_id"
                        parentId={projectId}
                        title="Логотип компании"
                        onChange={setData}
                        fileKey="logo"
                        formError={formError}
                        setFormError={setFormError}
                        fileType="project_company"
                        accept=".jpeg, .png, .gif"
                    />
                </div>

                <InputText
                    type="text"
                    name="revenue"
                    id="revenueInput"
                    onChange={setData}
                    value={revenue || ''}
                    label="Выручка за предыдущий отчётный год"
                    formError={formError}
                    setFormError={setFormError}
                    validator={[{maxLength: 100}, {required: true}]}
                    labelClass="requiredMark"
                    containerClassName="addProject__fullInput"
                    rank={true}
                />

                <div className="addProject__landSelectGroup">
                    <InputText
                        name="finance"
                        id="financeInput"
                        formError={formError}
                        setFormError={setFormError}
                        onChange={setData}
                        label="Прибыль/убыток"
                        value={finance || ''}
                        validator={[{maxLength: 100}]}
                        rank={true}
                        inputWithDesc
                        inputWithDescValue={
                            <Select
                                options={financeSelectOptions}
                                value={selectValue}
                                isSearchable={false}
                                className="pledge__select pledge__landSelect_wrap addProject__aboutCompany_finance"
                                classNamePrefix="pledge__landSelect"
                                onChange={data => handleOnChangeSelect(data, 'total_area_type', setSelectValue)}
                            />
                        }
                        containerClassName="addProject__fullInput"
                    />
                </div>
            </div>
        </div>
    )
}

export default AddProjectAboutCompany;

import React from "react";
import {arrayOf, shape, string} from 'prop-types';

const ApplicationCardList = ({data}) => data.map((item, idx) => (
    <div key={idx} className="dataCard__item">
        <span className="dataCard__name">{item.name}</span>
        <span className="dataCard__desc">{item.desc}</span>
    </div>
))

ApplicationCardList.propTypes = {
    data: arrayOf(shape({
        name: string,
        desc: string,
    })).isRequired,
};

ApplicationCardList.defaultProps = {
    data: [],
};

export default ApplicationCardList;
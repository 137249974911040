import React from 'react';
import PledgeForms from "./forms";
import IconBtn from "@shared/components/form/IconBtn";
import {useDispatch, useSelector} from "react-redux";
import {ADD_DEFAULT_PLEDGE, PLEDGE_FORM_IS_COMPLETED} from "@actions/projectsActions";
import {defaultPledge} from "@containers/Projects/AddProject/forms/Pledge/data";
import {checkFormIsCompleted} from "@containers/Projects/AddProject/helpers";

const Pledge = () => {
    const dispatch = useDispatch();
    const {pledges} = useSelector(state => state.projects.projectEditData);
    const {projectFormError, formIsCompleted} = useSelector(state => state.projects);

    const addPledge = () => {
        const defaultPledgeId = Math.round(Math.random() * 100);
        const defaultPledgeDate = defaultPledge(defaultPledgeId);

        dispatch({
            type: PLEDGE_FORM_IS_COMPLETED,
            payload: {
                pledgeId: defaultPledgeId,
                status: false
            }
        })

        dispatch({
            type: ADD_DEFAULT_PLEDGE,
            payload: {...defaultPledgeDate}
        })
    }

    const renderPledgeList = () => {
        if (pledges?.length) {
            return pledges.map((item, idx) => (
                <div key={item.id} className="addProject__form addProject__form-investments">
                    <PledgeForms data={item} title={`Залог ${idx > 0 ? idx + 1 : ''}`}/>
                </div>
            ))
        }
    }

    return <>
        {renderPledgeList()}
        <div className="pledge__addBtn">
            <IconBtn
                title="Добавить залог"
                onClick={addPledge}
                className="yourOption__addBtn relatedCompanies__addBtn"
                disabled={pledges?.length > 0 && (!!projectFormError.length || !checkFormIsCompleted(3, formIsCompleted))}
            />
        </div>
    </>
}

export default Pledge;
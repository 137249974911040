import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useHistory} from "react-router-dom";
import {QualificationsService} from "@services/qualifications.service";

const QualifiedNewCheck = ({hideModal, modalData}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const {qualificationId, qualificationStatus} = modalData;

    const newCheck = () => {
        if (qualificationStatus === 'qualification_checking') {
            hideModal()
            history.push(`/qualification/${qualificationId}`)
        } else {
            setLoading(true)
            QualificationsService.create({type: "qualification_checking"}, qualificationId)
                .then(res => {
                    setLoading(false)
                    hideModal()
                    history.push(`/qualification/${res?.data?.data?.id}`)
                })
                .catch(error => console.log(error))
        }

    }

    const handleOnClickCancel = e => {
        hideModal()
    }

    return <div className="moderatorAccModal__newCheck">
        <p className="modalFullScreen__title">Вы уверены, что хотите начать новую проверку заявления о квалификации?</p>
        <div className="btn-wrap moderatorAccModal__newCheck_bnt">
            <Button onClick={handleOnClickCancel} color="danger" size="sm" outline>
                Отмена
            </Button>
            <Button color="primary" size="lg" onClick={newCheck}>
                <div className="btn-spinner-group">
                    <Spinner className={`btn-spinner ${loading ? 'btn-spinner-visible' : ''}`}/>
                    Начать проверку
                </div>
            </Button>
        </div>
    </div>
}

export default QualifiedNewCheck;
export const getSubjPartFIO = (SN) => {
    const rv = SN.replace(/(.+,\s|^)CN=(.*?)(,\s.*|$)/, "$2");
    return rv === SN ? "" : rv;
}
export const getSubjPartEmail = (SN) => {
    const rv = SN.replace(/(.+,\s|^)E=(.*?)(,\s.*|$)/, "$2");
    return rv === SN ? "" : rv;
}
export const getSubjPartLoc = (SN) => {
    const rv = SN.replace(/(.+,\s|^)L=(.*?)(,\s.*|$)/, "$2");
    return rv === SN ? "" : rv;
}
export const getSubjPartOrg = (SN) => {
    const rv = SN.replace(/(.+,\s|^)O=(.*?)(,\s.*|$)/, "$2");
    return rv === SN ? "" : rv;
}
export const getSubjPartDolzh = (SN) => {
    const rv = SN.replace(/(.+,\s|^)T=(.*?)(,\s.*|$)/, "$2");
    return rv === SN ? "" : rv;
}
export const getSubjPartInn = (SN) => {
    const rv = SN.replace(/(.+,\s|^)(ИНН|INN)=(.*?)(,\s.*|$)/, "$3");
    return rv === SN ? "" : rv;
}

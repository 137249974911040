import React from 'react';
import classnames from "classnames";

const ControlTabs = ({
                         data = [], activeTab = null, setActiveTab = () => {
    }, horizontal, className
                     }) => {

    const controlTabsClass = classnames({
        controlTabs: !horizontal,
        controlTabs__horizontal: horizontal,
        [className]: className
    })

    const renderTabs = () => {
        return data.map(item => {
            const {key, icon: ControlTabIcon, desc} = item;

            return (
                <button
                    key={key}
                    className={`mainBtn__white from__custom-radio-btn ${activeTab === key ? 'active' : ''}`}
                    onClick={() => setActiveTab(key)}
                >
                    <span>
                        {
                            ControlTabIcon
                                ? <div className="controlTabs__icon">
                                    <ControlTabIcon/>
                                </div>
                                : null
                        }
                        <span>{desc}</span>
                    </span>
                </button>
            )
        })
    }

    return data.length
        ? <div className={controlTabsClass}>
            <div className="controlTabs__inner">
                {renderTabs()}
            </div>
        </div>
        : null
}

export default ControlTabs;

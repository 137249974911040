import React, {useEffect, useState} from 'react';
import classnames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";
import {useHistory, useParams} from "react-router-dom";
import {TabContent, TabPane} from 'reactstrap';
import ClientAccountHeaderNotice from "@containers/InnerUsers/ClientAccount/ClientAccountHeaderNotice";
import {pageTitleRoleDesc, tabs} from "./helpers";
import {accStatusKey} from "@utils/statusKey";
import {ProfilesCheckAccreditationService} from "@services/profiles.service";
import ClientAccountDataTab from "@containers/InnerUsers/ClientAccount/tabs/ClientAccountDataTab";
import {getClientAccountData} from "@actions/innerUsersActions";
import {useDispatch, useSelector} from "react-redux";
import AccountBanPage from "@containers/InnerUsers/ClientAccount/tabs/AccountBanPage";
import CheckDataToDisplay from "@shared/components/presentation/CheckDataToDisplay";

const ClientAccount = () => {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const {data} = useSelector(state => state.innerUsers.clientAccount);
    const [completedProfileData, setCompletedProfileData] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const accreditationStatus = [accStatusKey.returned, accStatusKey.notAccredited].includes(data?.accreditation_status_key)
        ? completedProfileData
        : true

    const getPageTitle = () => {
        return data?.user?.roles[0]?.name
            ? `Профиль ${pageTitleRoleDesc[data.user.roles[0].name]}`
            : null
    }

    useEffect(() => {
        if (id) {
            getClientAccountData(id)(dispatch)
            ProfilesCheckAccreditationService.getList()
                .then(() => setCompletedProfileData(true))
                .catch(() => {
                })
        } else {
            history.push('/404')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <div className="clientAccount">
            <CheckDataToDisplay data={data}>
                <>
                    <h1 className="page-title">{getPageTitle()}</h1>
                    <div className="content__wrapper profile__content">
                        <ClientAccountHeaderNotice data={data} profileId={id}/>
                        <div className="profileTabs">
                            <div className="profileTabs__tabs">
                                <Swiper
                                    spaceBetween={60}
                                    slidesPerView="auto"
                                    freeMode={true}
                                    onClick={(swiper, event) => swiper.slideTo(event.target.dataset.id, 300)}
                                >
                                    {
                                        tabs.map((item) => (
                                            <SwiperSlide key={item.id}>
                                                  <span
                                                      data-id={item.id}
                                                      className={classnames({
                                                          'profileTabs__link': true,
                                                          active: activeTab === item.id
                                                      })}
                                                      onClick={() => activeTab !== item.id && setActiveTab(item.id)}
                                                  >
                                                        {item.title}
                                                      {
                                                          !accreditationStatus && item.key === 'profileData'
                                                              ? <span className="profileTabs__link_notice">!</span>
                                                              : ''
                                                      }
                                                  </span>
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div>
                            <TabContent activeTab={activeTab} className="accountContent__wrap">
                                <TabPane tabId={0} className="profileTabs__tab">
                                    <ClientAccountDataTab data={data} profileId={id}/>
                                </TabPane>
                                <TabPane tabId={1} className="profileTabs__tab">
                                    Банковские реквизиты
                                </TabPane>
                                <TabPane tabId={2} className="profileTabs__tab">
                                    Документы профиля
                                </TabPane>
                                <TabPane tabId={3} className="profileTabs__tab">
                                    Результаты проведения аккредитации
                                </TabPane>
                                <TabPane tabId={4} className="profileTabs__tab">
                                    История операций
                                </TabPane>
                                <TabPane tabId={5} className="profileTabs__tab">
                                    Реестр действий профиля
                                </TabPane>
                                <TabPane tabId={6} className="profileTabs__tab">
                                    Реестр проектов профиля
                                </TabPane>
                                <TabPane tabId={7} className="profileTabs__tab">
                                    <AccountBanPage data={data?.user} profileId={id}/>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </>
            </CheckDataToDisplay>
        </div>
    )
}

export default ClientAccount;
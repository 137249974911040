import React from 'react';
import {Button} from "reactstrap";
import {useHistory} from "react-router-dom";
import Notification from "@shared/components/projects/projectDetail/ProjectInvest/modal/notification";
import BackButton from "@shared/components/projects/projectDetail/ProjectInvest/modal/backButton";

const ProfileIsNotAccreditedModal = () => {
    const history = useHistory();
    return (
        <div className='profileIsNotAccredited'>
            <Notification desc={
                'Для того, чтобы инвестировать от имени другого профиля, выберете другой профиль в боковом меню'
            }/>
            <BackButton/>
            <span className='profileIsNotAccredited__desc'>
                Для доступа к инвестированию вам необходимо пройти аккредитацию на платформе
            </span>
            <div className='profileIsNotAccredited__buttons'>
                <Button
                    color="primary"
                    className="account__btn_cancel"
                    outline
                    onClick={() => history.push("/project-detail/101010")}
                >
                    Отменить
                </Button>
                <Button
                    className='notAccreditedProject__button'
                    color="primary"
                    size="lg"
                    onClick={() => history.push("/account")}
                >
                    Перейти в профиль
                </Button>
            </div>
        </div>
    )
};

export default ProfileIsNotAccreditedModal;
export const aboutProjectSelectOptions = [
    {key: "seed", title: "SEED", label: 'Посевная стадия инвестирования Небольшие доходы, расход отсутствует'},
    {key: "startup", title: "Startup", label: 'Cтартап стадия Появляются первые доходы'},
    {
        key: "early_growth",
        title: "Early growth",
        label: 'Стадия раннего роста. Бизнес проходит точку безубыточности, появляется первая прибыль'
    },
    {key: "expansion", title: "EXPENCION", label: 'Стадия расширения Рост прибыли копании'},
]

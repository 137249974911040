import {
    HIDE_ALL_MODALS,
    HIDE_MODAL,
    SET_MODAL_DATA,
    SHOW_MODAL,
    UNSET_MODAL_DATA,
    UNSET_UPDATE_PARENT_DATA,
} from '@redux/actions/modalActions';

const initialState = {
    modals: [],
    updateParentData: 0,
    parentData: null,
}

const updateParent = (success, parent) => {
    let updateParent = 0
    if (!!success) {
        if (parent >= 0) {
            updateParent = parent + 1
        } else {
            updateParent = 1
        }
    } else {
        if (parent >= 0) {
            updateParent = -1
        } else {
            updateParent = parent - 1
        }
    }
    return updateParent
}

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                modals: [...state.modals.filter(m => m.type !== action.payload.type), {
                    type: action.payload.type,
                    data: action.payload.data || null,
                    update: 0
                }]
            }
        case HIDE_MODAL:
            const modals = state.modals
            const i = modals.findIndex(m => m.type === action.payload.type)
            let parentData = null

            if (i > 0) {
                modals[i - 1].update = updateParent(action.payload.success, modals[i - 1].update)
                modals[i - 1].parentData = action.payload.data || null
            } else {
                parentData = action.payload.data || null
            }

            return {
                ...state,
                modals: modals.filter(m => m.type !== action.payload.type),
                updateParentData: state.modals.length === 1 ? updateParent(action.payload.success, state.updateParentData) : state.updateParentData,
                parentData: parentData
            }
        case HIDE_ALL_MODALS:
            return {
                ...state,
                modals: [],
                updateParentData: updateParent(action.payload.success, state.updateParentData)
            }
        case SET_MODAL_DATA:
            return {
                ...state,
                modals: state.modals.map(m => m.type === action.payload.type ? {
                    ...m,
                    data: action.payload.data,
                    parentData: null
                } : m)
            }
        case UNSET_MODAL_DATA:
            return {
                ...state,
                modals: state.modals.map(m => m.type === action.payload.type ? null : m)
            }
        case UNSET_UPDATE_PARENT_DATA:
            return {
                ...state,
                updateParentData: 0
            }
        default:
            return state
    }
}
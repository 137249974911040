import React from 'react';
import {Col, Container, Row} from "reactstrap";

const AnalyticsInvestor = () => {
    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h1 className="page-title">Аналитика</h1>
                </Col>
            </Row>
        </Container>
    )
}

export default AnalyticsInvestor;
import React from 'react';
import {showModal} from "@actions/modalActions";
import {PLATFORM_SERVICES} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";

const LoanPlatformServices = () => {
    const dispatch = useDispatch();

    return (
        <div className='loanPlatformServices'>
            <span className='loanPlatformServices__desc'>Услуги платформы: 12 000,00 ₽</span>
            <span
                className='loanPlatformServices__button'
                onClick={() => showModal(PLATFORM_SERVICES)(dispatch)}
            >
                Подробнее
            </span>
        </div>
    )
};

export default LoanPlatformServices;
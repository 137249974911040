const projectCardRatingDesc = {
    aPlus: 'A+',
    a: 'A',
    b: 'B',
    c: 'C',
    d: 'D',
}

const projectCardRatingColor = {
    aPlus: '#58BF91',
    a: '#58BF91',
    b: '#F0C83B',
    c: '#FD9C65',
    d: '#FC8473',
}

export {
    projectCardRatingDesc,
    projectCardRatingColor
}
import React from "react";
import {SET_EDITABLE_FIELD_KEY} from "./helpers";
import {EditIcon} from "@shared/img/iconSvg";
import EditingFields from "./EditingFields";

const Editing = props => {
    const {fieldKey, fieldKeyTo, state, dispatch} = props;

    const switchEdit = key => dispatch({
        type: SET_EDITABLE_FIELD_KEY,
        payload: key
    })

    return !state.edit.includes(fieldKey) && !state.edit.includes(fieldKeyTo)
        ? <EditIcon
            className="platformRates__editIcon"
            onClick={() => switchEdit([fieldKey, fieldKeyTo].filter(item => item !== undefined))}
        />
        : <EditingFields {...props} />
}

export default Editing;
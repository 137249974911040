import React, {useState} from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import {Button, Spinner} from "reactstrap";
import {useHistory} from "react-router-dom";
import {ModerationsCheckService, ModerationsService} from "@services/moderator.service";


const ModerationProjectReject = ({hideModal, modalData}) => {
    const history = useHistory();
    const {moderationProjectData, showResult, moderationId} = modalData;
    const [rejectionText, setRejectionText] = useState('');
    const [formError, setFormError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resetValidate, setResetValidate] = useState(false);

    const handleOnChange = e => setRejectionText(e.target.value);
    const redirectToModerationProjects = () => {
        setLoading(false);
        history.push('/moderation_projects');
        hideModal();
    }

    const handleOnClickReject = () => {
        if (rejectionText && moderationId) {
            setLoading(true);
            moderationProjectData.remarks.push({type_key: null, message: rejectionText})

            if (showResult) {
                const params = {
                    type: "projects_rejected",
                    remarks: moderationProjectData.remarks.filter(item => item.type_key === null && !item.id).map(item => ({message: item.message}))
                }

                ModerationsService.create(params, moderationId)
                    .then(res => redirectToModerationProjects())
                    .catch(error => console.log(error))
            }

            ModerationsCheckService.create(moderationProjectData, moderationId)
                .then(() => redirectToModerationProjects())
                .catch(() => setLoading(false))
        } else {
            setFormError(["rejectionText"])
            setResetValidate(true)
        }
    }

    return <div className="moderatorAccModal__reject">
        <p className="modalFullScreen__title">Причины отклонения проекта</p>
        <InputText
            name="rejectionText"
            id="rejectionText"
            type='textarea'
            label="Укажите причину отклонения"
            rows={2}
            formError={formError}
            onChange={handleOnChange}
            setFormError={setFormError}
            resetValidate={resetValidate}
            value={rejectionText}
            containerClassName="profileTabs__edit_item moderatorAccModal__reject_inputGroup"
            validator={[{required: true}]}
        />
        <div className="modalFullScreen__btnGroup moderatorAccModal__reject_btnGroup">
            <Button
                onClick={() => hideModal()}
                color="primary"
                className="account__btn_cancel moderatorAccModal__reject_btnDanger"
                outline
            >
                Отмена
            </Button>
            <Button
                color="primary"
                className="account__btn_primary moderatorAccModal__reject_btnPrimary"
                onClick={handleOnClickReject}
            >
                <div className="btn-spinner-group">
                    <Spinner className={`btn-spinner ${loading ? 'btn-spinner-visible' : ''}`}/>
                    Отклонить
                </div>
            </Button>
        </div>
    </div>
}

export default ModerationProjectReject;

import React, {useState} from "react";
import {Spinner} from "reactstrap";
import PropTypes from 'prop-types';
import {SELECT_IMAGE_MODAL, WEBCAM_MODAL} from "@shared/components/modal/constants";

const EditAvatarModal = ({showModal}) => {
    const [spinnerStatus, setSpinnerStatus] = useState(false);

    const openWebCamera = () => {
        setSpinnerStatus(true)
        navigator.mediaDevices.getUserMedia({video: true})
            .then(() => showModal(WEBCAM_MODAL))
            .catch(() => alert('Необходимо разрешить использовать камеру!'))
            .finally(() => setSpinnerStatus(false))
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => showModal(SELECT_IMAGE_MODAL, reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    return (
        <>
            <p className="modalRoot__title">Фото профиля</p>
            <div className="editAvatar__btn-group">
                <div className="editAvatar__file_btn">
                    <label htmlFor="upload-avatar" className="editAvatar__btn">Загрузить файл</label>
                    <input
                        type="file"
                        id="upload-avatar"
                        name="avatar"
                        accept="image/jpeg, image/png"
                        onChange={onSelectFile}
                        onClick={event => event.target.value = null}
                    />
                </div>
                <button className="editAvatar__btn editAvatar__btn_active" onClick={openWebCamera}>
                    <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                    Использовать камеру
                </button>
            </div>
        </>
    )
}

EditAvatarModal.propTypes = {
    showModal: PropTypes.func.isRequired,
    setModalData: PropTypes.func.isRequired,
    setPreviousModal: PropTypes.func.isRequired,
}

EditAvatarModal.defaultProps = {
    showModal: () => () => {
    },
    setModalData: () => () => {
    },
    setPreviousModal: () => () => {
    },
};

export default EditAvatarModal;
import React from "react";

export const ProjectLogo = ({
                                id, setLogoError = () => {
    }
                            }) => {
    const defaultLogo = `${process.env.PUBLIC_URL}/img/projectDefaultLogo.svg`;
    const logoUrl = `${process.env.REACT_APP_API_URL}/projects/avatar/${id}`;

    return <img
        src={logoUrl}
        onError={e => {
            e.target.onerror = null;
            e.target.src = defaultLogo;
            setLogoError(true)
        }}
        alt="project logo"
    />
}
import React, {useEffect} from 'react';
import IconBtn from "@shared/components/form/IconBtn";
import PledgeSelect from './PledgeSelect';
import Property from './Property';
import Movable from './Movable';
import DDU from './DDU';
import Other from './Other';
import {useDispatch, useSelector} from "react-redux";
import {PLEDGE_FORM_IS_COMPLETED, REMOVE_PLEDGE, SET_PLEDGE_VALUE, setProjectFormError} from "@actions/projectsActions";
import {
    contractsRequiredKey,
    dduRequiredKey,
    movableRequiredKey,
    otherRequiredKey,
    propertyRequiredKey
} from "@containers/Projects/AddProject/forms/Pledge/data";

const PledgeForms = ({data, title}) => {
    const dispatch = useDispatch();
    const {id: pledgeId, property, property_type} = data || {};
    const {projectFormError: formError, formIsCompleted, projectEditData} = useSelector(state => state.projects);

    const setFormError = data => {
        setProjectFormError(data, dispatch)
    }

    const setData = event => {
        dispatch({
            type: SET_PLEDGE_VALUE,
            payload: {
                pledgeId,
                name: event.target.name,
                value: event.target.value || null,
            }
        })
    }

    const removePledge = () => {
        dispatch({
            type: REMOVE_PLEDGE,
            payload: pledgeId
        })
    }

    const renderForm = () => {
        switch (property_type) {
            case 'property':
                return <Property
                    data={data}
                    formError={formError}
                    setFormError={setFormError}
                    projectId={projectEditData?.id}
                />
            case "movable":
                return <Movable
                    data={property}
                    setData={setData}
                    formError={formError}
                    setFormError={setFormError}
                    projectId={projectEditData?.id}
                />
            case "ddu":
                return <DDU
                    data={property}
                    setData={setData}
                    formError={formError}
                    setFormError={setFormError}
                    projectId={projectEditData?.id}
                />
            case "other":
                return <Other
                    data={property}
                    setData={setData}
                    formError={formError}
                    setFormError={setFormError}
                    projectId={projectEditData?.id}
                />
            default:
                return null
        }
    }

    const checkStatus = (validData, requiredKeys) => {
        if (data.property.type_key === 'house') {
            for (let key in validData) {
                Object.keys(validData[key]).forEach(item => {
                    if (requiredKeys[key].includes(item)) {
                        if ((Array.isArray(validData[key][item]) && validData[key][item].length === 0) || !validData[key][item])
                            return false;
                    }
                })
            }
        } else {
            Object.keys(validData).forEach(item => {
                if (requiredKeys.includes(item)) {
                    if ((Array.isArray(validData[item]) && validData[item].length === 0) || !validData[item])
                        return false;
                }
            })
        }

        if ((data.property.type_key === 'premise' || data.property.type_key === 'building') && data.property[data.property.type_key].contract_type) {
            const validateForData = data.property[data.property.type_key].contract;

            if (data.property[data.property.type_key].contract_type === 'uses') {
                const requiredKeyUses = contractsRequiredKey.uses[validateForData.rent_confirmed];

                Object.keys(validateForData).forEach(item => {
                    if (requiredKeyUses.includes(item)) {
                        if ((Array.isArray(validateForData[item]) && validateForData[item].length === 0) || !validateForData[item]) {
                            if (!(item === 'uses' && validateForData.uses_type === 'infinity')) return false;
                        }

                    }
                })
            } else {
                Object.keys(validateForData).forEach(item => {
                    if (contractsRequiredKey[data.property[data.property.type_key].contract_type].includes(item)) {
                        if ((Array.isArray(validateForData[item]) && validateForData[item].length === 0) || !validateForData[item])
                            return false;
                    }
                })
            }
        }

        return true;
    }
    // Проверяем заполнены ли все обязательные поля, если да, то активируем кнопку далее
    useEffect(() => {
        let status = true;
        let requiredKeys = [];
        let validData = {};

        if (property_type === 'property') {
            if (data.property.type_key === 'house') {
                validData = {house: data.property.house, land: data.property.land}
            } else {
                validData = data.property[data.property.type_key]
            }
        } else {
            validData = property
        }

        switch (data.property_type) {
            case "ddu":
                requiredKeys = dduRequiredKey;
                break;
            case "movable":
                requiredKeys = movableRequiredKey;
                break;
            case "other":
                requiredKeys = otherRequiredKey;
                break;
            case "property":
                requiredKeys = propertyRequiredKey[data.property.type_key];
                break;
            default:
                return null
        }

        status = checkStatus(validData, requiredKeys)

        if (status !== formIsCompleted.pledgeForm) {
            dispatch({
                type: PLEDGE_FORM_IS_COMPLETED,
                payload: {pledgeId, status}
            })
            //setProjectFormIsCompleted("pledgeForm", status, dispatch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <div className="pledge__forms">
            <p className="addProject__form_title">{title}</p>
            <div className="addProject__relatedCompanies">
                <PledgeSelect selectValue={data.property_type} pledgeId={data.id}/>
                {renderForm()}
                <IconBtn
                    title="Удалить залог"
                    onClick={removePledge}
                    className="yourOption__addBtn relatedCompanies__removeBtn"
                    remove={true}
                />
            </div>
        </div>
    )
}

export default PledgeForms;
import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useQuery} from '@utils/route';
import {AuthService} from "@services/auth.service";
import ResetPassSendToken from '@shared/components/form/resetPassword/sendToken';
import ResetPassNewPassword from '@shared/components/form/resetPassword/newPassword';

const ResetPassword = () => {
    const query = useQuery();
    const history = useHistory();
    const token = query.get('token') ? query.get('token') : null; // Получаем токен из url

    const [followingLink, setFollowingLink] = useState(false); // Переход по ссылке на подтверждение

    useEffect(() => {
        // Проверяем токен
        if (token) {
            AuthService.checkToken(token).then(res => {
                setFollowingLink(true)
            }).catch(error => {
                // Редирект на 404, если перешли по ссылке на подтверждение, но токен не активен
                history.push('/404')
            })
        }
    }, [token, history])

    return (
        <>
            {
                followingLink
                    ? <ResetPassNewPassword token={token}/>
                    : <ResetPassSendToken/>
            }
        </>
    )
}

export default ResetPassword;
import React from 'react';
import DashboardCard from "@shared/components/data/DashboardCard";
import {cardData} from "./helpers";

const NominalAccountStatus = () => {
    return (
        <div className="adminSection__accountStatus">
            <div className="dashboardCard__wrap">
                {
                    cardData.map((item, idx) => (
                        <DashboardCard key={idx} desc={item.desc} value={item.value}/>
                    ))
                }
            </div>
        </div>
    )
}

export default NominalAccountStatus;
import React from "react";
import {GearWarnIcon} from "@shared/img/iconSvg";

const RemarksNotice = ({data = []}) => {
    return data.length
        ? <div className="accStatusNotice__content">
            <ul className="accStatusNotice__remarksList">
                {
                    data.map(item => (
                        <li key={item.id}>
                            <GearWarnIcon/>
                            <span>{item.message}</span>
                        </li>
                    ))
                }
            </ul>
        </div>
        : null
}

export default RemarksNotice;
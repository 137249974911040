import React, {useState} from 'react';
import {CloseBgIcon} from "@shared/img/iconSvg";
import classNames from "classnames";

const AddedList = ({
                       data = [], onChange = () => {
    }, label = '', placeholder = '', className,
                       inputRequired, disabled, formInput, dataType, Subtitle, errorMessage, setErrorMessage = () => {
    }
                   }) => {
    const [listItem, setListItem] = useState('');
    const dataFormat = dataType ? data.filter(item => item.type_key === dataType) : data;

    const addedListClass = classNames({
        [className]: className,
        'addedList': true,
        'addedListBg': !formInput,
        'dataCard': !formInput,
        'disabled': disabled,
        'inputText__wrapper': formInput,
        'addProject__fullInput': formInput
    })
    const addedListInnerClass = classNames({
        'addedList__inner': true,
        'addedList__border': dataFormat.length,
        'addedList__border_error': errorMessage,
        'inputErrorGroup': formInput,
    })
    const addedListLabelClass = classNames({
        'requiredMark': inputRequired && formInput,
        'dataCard__title': !formInput
    })
    const addedListErrorBlock = classNames({
        'error-color': true,
        'addedList__errorBlock': true,
        'addedList__errorMessage': errorMessage
    })


    const renderList = () => {
        const removeListItem = listItem => {
            const idx = data.map(item => item.message).indexOf(listItem.message);

            if (idx > -1) {
                const listCopy = [...data];
                listCopy.splice(idx, 1);

                if (errorMessage && !listCopy.length) setErrorMessage(null)

                onChange(listCopy)
            }
        }

        return dataFormat.map((item, idx) => (
            <div key={idx} className="addedList__item">
                {!item.id || !disabled ? <CloseBgIcon onClick={() => removeListItem(item)}/> : null}
                <span>{item.message}</span>
            </div>
        ))
    }

    const handleKeyDown = e => {
        const keyCode = e.keyCode ? e.keyCode : e.which;

        if ((keyCode === '13' || keyCode === 13) && e.target.value.length) {
            onChange([
                ...data,
                dataType ? {type_key: dataType, message: e.target.value} : {message: e.target.value}
            ])
            setListItem('')
        }

    }

    const handleOnBlur = e => {
        if (e.target.value.length) {
            onChange([
                ...data,
                dataType ? {type_key: dataType, message: e.target.value} : {message: e.target.value}
            ])
            setListItem('')
        }
    }

    return <div className={addedListClass}>
        <div className="addedList__titleGroup">
            <label className={addedListLabelClass}>
                {label}
            </label>
            {Subtitle && <Subtitle/>}
        </div>
        <div className={addedListInnerClass}>
            {renderList()}
            {
                disabled
                    ? null
                    : <input
                        type="text"
                        placeholder={placeholder}
                        autoComplete="off"
                        value={listItem}
                        onChange={e => setListItem(e.target.value)}
                        onKeyDown={handleKeyDown}
                        onBlur={handleOnBlur}
                    />
            }
        </div>
        <div className={addedListErrorBlock}>
            {errorMessage}
        </div>
    </div>
}

export default AddedList;

import React from 'react';
import InfoBlockTitle from "@shared/components/projects/projectDetail/LoanOptions/InfoBlockTitle";
import InfoBlock from "@shared/components/projects/projectDetail/LoanOptions/InfoBlock";
import ProgressBarLine from "@shared/components/projects/projectDetail/LoanOptions/ProgressBarLine";
import ProgressBar from "@shared/components/projects/projectDetail/LoanOptions/ProgressBar";
import ProjectEvents from "@shared/components/projects/projectDetail/LoanOptions/ProjectEvents";
import LoanOptionsInvest from "@shared/components/projects/projectDetail/LoanOptions/LoanOptionsInvest";
import LoanNotificationCollectionCompleted
    from "@shared/components/projects/projectDetail/LoanOptions/LoanNotificationCollectionCompleted";
import YourInvestment from "@shared/components/projects/projectDetail/LoanOptions/YourInvestment";

const LoanOptionsInvestor = ({data}) => {
    switch (data.moderation_status_key) {
        case 'projects_collecting': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <div className='investInfo'>
                        <InfoBlock data={data}/>
                        <YourInvestment data={data}/>
                    </div>
                    <ProgressBarLine data={data}/>
                    <ProgressBar data={data}/>
                    <LoanOptionsInvest data={data}/>
                    <ProjectEvents/>
                </div>
            );
        }
        case 'projects_collecting_completed': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <div className='investInfo'>
                        <InfoBlock data={data}/>
                        <YourInvestment data={data}/>
                    </div>
                    <ProgressBarLine data={data}/>
                    <LoanNotificationCollectionCompleted
                        NotificationColl={'Сбор инвестиций завершен. Скоро переведем собранные средства заёмщику'}
                    />
                    <ProgressBar data={data}/>
                    <ProjectEvents/>
                </div>
            );
        }
        case 'projects_signing_stage': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <div className='investInfo'>
                        <InfoBlock data={data}/>
                        <YourInvestment data={data}/>
                    </div>
                    <ProgressBarLine data={data}/>
                    <LoanNotificationCollectionCompleted
                        NotificationColl={
                            'Сбор инвестиций завершен. Вам необходимо подписать ' +
                            'документы в разделе “Документы ” до 22.12.2021.'
                        }
                    />
                    <ProgressBar data={data}/>
                    <ProjectEvents/>
                </div>
            );
        }
        case 'projects_not_carried_out': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <div className='investInfo'>
                        <InfoBlock data={data}/>
                        <YourInvestment data={data}/>
                    </div>
                    <ProgressBarLine data={data}/>
                    <ProgressBar data={data}/>
                    <ProjectEvents/>
                </div>
            );
        }
        case 'projects_delay': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <div className='investInfo'>
                        <InfoBlock data={data}/>
                        <YourInvestment data={data}/>
                    </div>
                    <ProgressBarLine data={data}/>
                    <ProgressBar data={data}/>
                    <ProjectEvents/>
                </div>
            );
        }
        /*case 'projects_signing_stage': {
            return (
                <div>
                    <InfoBlockTitle data={data}/>
                    <div className='investInfo'>
                        <InfoBlock data={data}/>
                        <YourInvestment data={data}/>
                    </div>
                    <ProgressBarLine data={data}/>
                    <LoanNotificationCollectionCompleted
                        NotificationColl={
                            'Сбор инвестиций завершен. Ожидается окончание подписания документов проекта всеми сторонами.'
                        }
                    />
                    <ProgressBar data={data}/>
                    <ProjectEvents/>
                </div>
            );
        }*/
        default: {
            return null;
        }
    }
};

export default LoanOptionsInvestor;
export const headers = [
    {label: "Дата фактического платежа", key: "paymentDate", width: "10%"},
    {label: "ID инвестора", key: "idInvestor", width: "8%"},
    {label: "ФИО инвестора", key: "fullNameInvestor", width: "16%"},
    {label: "Сумма платежа", key: "amountOfPayment", width: "8.2%"},
    {label: "Основной долг", key: "mainDebt", width: "8.2%"},
    {label: "%", key: "percent", width: "7.2%"},
    {label: "Пени", key: "penalties", width: "7.2%"},
    {label: "НДФЛ к уплате c % и пени", key: "personalIncomeTax", width: "9.2%"},
    {label: "Данные инвесторов", key: "investorData", width: "27%"},
];

export const tableData = [
    {
        id: 0,
        paymentDate: "12.08.2021",
        idInvestor: "1234567",
        fullNameInvestor: "Иванов Иван Иванович",
        amountOfPayment: "20 000 ₽",
        mainDebt: "20 000 ₽",
        percent: "20 000 ₽",
        penalties: "20 000 ₽",
        personalIncomeTax: "20 000 ₽",
        investorData: "ИНН: 1234567  Дата рождения: 16.08.1996 Паспорт серия **** номер ******",
    },
    {
        id: 1,
        paymentDate: "12.08.2021",
        idInvestor: "1234567",
        fullNameInvestor: "Иванов Иван Иванович",
        amountOfPayment: "20 000 ₽",
        mainDebt: "20 000 ₽",
        percent: "20 000 ₽",
        penalties: "20 000 ₽",
        personalIncomeTax: "20 000 ₽",
        investorData: "ИНН: 1234567  Дата рождения: 16.08.1996 Паспорт серия **** номер ******",
    },
    {
        id: 2,
        paymentDate: "12.08.2021",
        idInvestor: "1234567",
        fullNameInvestor: "Иванов Иван Иванович",
        amountOfPayment: "20 000 ₽",
        mainDebt: "20 000 ₽",
        percent: "20 000 ₽",
        penalties: "20 000 ₽",
        personalIncomeTax: "20 000 ₽",
        investorData: "ИНН: 1234567  Дата рождения: 16.08.1996 Паспорт серия **** номер ******",
    },
    {
        id: 3,
        paymentDate: "12.08.2021",
        idInvestor: "1234567",
        fullNameInvestor: "Иванов Иван Иванович",
        amountOfPayment: "20 000 ₽",
        mainDebt: "20 000 ₽",
        percent: "20 000 ₽",
        penalties: "20 000 ₽",
        personalIncomeTax: "20 000 ₽",
        investorData: "ИНН: 1234567  Дата рождения: 16.08.1996 Паспорт серия **** номер ******",
    },
    {
        id: 4,
        paymentDate: "12.08.2021",
        idInvestor: "1234567",
        fullNameInvestor: "Иванов Иван Иванович",
        amountOfPayment: "20 000 ₽",
        mainDebt: "20 000 ₽",
        percent: "20 000 ₽",
        penalties: "20 000 ₽",
        personalIncomeTax: "20 000 ₽",
        investorData: "ИНН: 1234567  Дата рождения: 16.08.1996 Паспорт серия **** номер ******",
    },
    {
        id: 5,
        paymentDate: "12.08.2021",
        idInvestor: "1234567",
        fullNameInvestor: "Иванов Иван Иванович",
        amountOfPayment: "20 000 ₽",
        mainDebt: "20 000 ₽",
        percent: "20 000 ₽",
        penalties: "20 000 ₽",
        personalIncomeTax: "20 000 ₽",
        investorData: "ИНН: 1234567  Дата рождения: 16.08.1996 Паспорт серия **** номер ******",
    },
    {
        id: 6,
        paymentDate: "12.08.2021",
        idInvestor: "1234567",
        fullNameInvestor: "Иванов Иван Иванович",
        amountOfPayment: "20 000 ₽",
        mainDebt: "20 000 ₽",
        percent: "20 000 ₽",
        penalties: "20 000 ₽",
        personalIncomeTax: "20 000 ₽",
        investorData: "ИНН: 1234567  Дата рождения: 16.08.1996 Паспорт серия **** номер ******",
    },
    {
        id: 7,
        paymentDate: "12.08.2021",
        idInvestor: "1234567",
        fullNameInvestor: "Иванов Иван Иванович",
        amountOfPayment: "20 000 ₽",
        mainDebt: "20 000 ₽",
        percent: "20 000 ₽",
        penalties: "20 000 ₽",
        personalIncomeTax: "20 000 ₽",
        investorData: "ИНН: 1234567  Дата рождения: 16.08.1996 Паспорт серия **** номер ******",
    },
    {
        id: 8,
        paymentDate: "12.08.2021",
        idInvestor: "1234567",
        fullNameInvestor: "Иванов Иван Иванович",
        amountOfPayment: "20 000 ₽",
        mainDebt: "20 000 ₽",
        percent: "20 000 ₽",
        penalties: "20 000 ₽",
        personalIncomeTax: "20 000 ₽",
        investorData: "ИНН: 1234567  Дата рождения: 16.08.1996 Паспорт серия **** номер ******",
    },
];
import React from 'react';
import {Button} from "reactstrap";
import {showModal} from "@actions/modalActions";
import {CANCEL_PROJECT} from "@shared/components/modal/constants";
import {useDispatch} from "react-redux";

const LoanOptionsCancelButton = () => {
    const dispatch = useDispatch();

    return (
        <div className='loanOptionsCancelButton'>
            <Button
                size="lg"
                color="danger"
                onClick={() => showModal(CANCEL_PROJECT)(dispatch)}
            >
                Отменить проект
            </Button>
        </div>
    )
};

export default LoanOptionsCancelButton;
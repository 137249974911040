import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import MediaPresentation from "@shared/components/presentation/MediaPresentation";

const Apartment = ({data, idx, fRef}) => {
    const type = data.type.value;
    const {
        address, number, total_area, living_area, apartment_number, rooms_count, floor,
        other_information, certificate_number, certificate_date, extract_egrn_from,
        plans, extracts, certificates, documents, images
    } = data.apartment;

    const dataFormat = [
        {name: type, type: 'subtitle'},
        {name: 'Адрес', desc: address || ''},
        {name: 'Кадастровый номер', desc: number || ''},
        {name: 'Общая площадь', desc: total_area || ''},
        {name: 'Жилая площадь', desc: living_area || ''},
        {name: 'Номер квартиры', desc: apartment_number || ''},
        {name: 'Количество комнат', desc: rooms_count || ''},
        {name: 'Этаж', desc: floor || ''},
        {name: 'Технический план (скан)', desc: plans || '', type: 'file'},
        {name: 'Свидетельство о праве собственности', desc: certificate_number || ''},
        {name: 'Свидетельство о праве собственности от', desc: certificate_date || ''},
        {name: 'Скан свидетельства о праве собственности', desc: certificates || '', type: 'file'},
        {name: 'Выписка из Единого государственного реестра недвижимости, дата', desc: extract_egrn_from || ''},
        {name: 'Скан выписки', desc: extracts || '', type: 'file'},
        {name: 'Иные сведения', desc: other_information || ''},
        {name: 'Иные документы', desc: documents || '', type: 'file'},
        {
            name: 'Фото залога',
            desc: images ? <MediaPresentation files={images} className="moderationProject__medias"/> : ''
        },
    ]

    return <DataCard data={dataFormat} title={`Залог ${idx + 1}`} fRef={fRef}/>
}

export default Apartment;

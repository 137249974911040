import React from 'react';
import TermsProject from "@shared/components/presentation/TermsProject";
import {navigationMenu} from "@containers/Moderator/ModerationProjects/ModerationProjectDetail/helpers";
import AddedList from "@shared/components/form/addedList";
import PlatformServiceForm from "@shared/components/form/moderation/PlatformServiceForm";
import classnames from "classnames";

const ModerationProjectTerms = ({
                                    data, step, remarkList, setRemarkList, rateState, rateDispatch,
                                    rateValidStatus, termsState, termsDispatch, termsValidStatus, showResult
                                }) => {
    const classes = classnames({
        'dataCard__list': true,
        'moderationProject__showResult': showResult
    })

    return (
        <div className={classes}>
            <TermsProject
                data={data.project.condition}
                fRef={navigationMenu[step].links[0].ref}
                state={termsState}
                dispatch={termsDispatch}
                validStatus={termsValidStatus}
            />
            <PlatformServiceForm
                data={data}
                fRef={navigationMenu[step].links[1].ref}
                state={rateState}
                dispatch={rateDispatch}
                rateValidStatus={rateValidStatus}
            />
            <p className="moderationProject__title">Решения модератора</p>
            <AddedList
                disabled={showResult}
                data={remarkList}
                label="Замечания"
                placeholder="Замечание"
                onChange={setRemarkList}
                dataType="moderation_conditions"
                className="moderationProject__remarks"
                fRef={navigationMenu[step].links[1].ref}
            />
        </div>
    )
}

export default ModerationProjectTerms;

import React from 'react';

const InfoBlock = ({data}) => {
    const reserved = data?.condition?.target ? Math.floor(data.condition.target / 3.4) : 0;

    switch (data.moderation_status_key) {
        case 'projects_draft': {
            return (
                <div className="infoBlock__desc">
                    <span className='infoBlock__collected'>Целевая сумма </span>
                    <span className='infoBlock__soma'>{`${reserved} ₽ `}</span>
                </div>
            );
        }
        case 'projects_collecting_completed':
        case 'projects_signing_stage':
        case 'projects_not_carried_out': {
            return (
                <div className="infoBlock__desc">
                    <span className='infoBlock__collected'>Собрано </span>
                    <span className='infoBlock__soma'>{`${reserved} ₽ `}</span>
                    <span className='infoBlock__collected'>из</span>
                    <span className='infoBlock__soma'> 460 000 ₽</span>
                </div>
            );
        }
        case 'projects_checking':
        case 'projects_returned':
        case 'projects_collecting': {
            return (
                <div className="infoBlock__desc">
                    <span className='infoBlock__collected'>Зарезервировано </span>
                    <span className='infoBlock__soma'>{`${reserved} ₽ `}</span>
                    <span className='infoBlock__collected'>из</span>
                    <span className='infoBlock__soma'> 460 000 ₽</span>
                </div>
            );
        }
        case 'projects_payment_stage': {
            return (
                <div className="infoBlock__desc">
                    <span className='infoBlock__collected'>Выплачено </span>
                    <span className='infoBlock__soma'>{`${reserved} ₽ `}</span>
                    <span className='infoBlock__collected'>из</span>
                    <span className='infoBlock__soma'> 460 000 ₽</span>
                </div>
            );
        }
        default: {
            return null;
        }
    }
};

export default InfoBlock;
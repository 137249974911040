import React from 'react';
import DataCard from "@shared/components/data/DataCard";
import {legalFormTypeDesc} from "@utils/auth";

const AccProfileData = ({data}) => {
    const dataFormat = [
        {name: 'Тип профиля', desc: data?.user?.roles ? data.user.roles[0].display_name : ''},
        {name: 'Правовая форма', desc: legalFormTypeDesc[data?.legal_form_type] || ''},
        {name: 'ID профиля', desc: data?.id || '', className: 'green-color'},
        {name: 'Email', desc: data?.user?.email || ''},
    ]

    return <DataCard data={dataFormat} title="Данные профиля"/>
}

export default AccProfileData;

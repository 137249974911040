import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addProject} from "@redux/actions/projectsActions";
import ProjectsList from "@shared/components/modal/projects/AddProject/ProjectsList";


const AddProject = ({hideModal}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {id: profileId} = useSelector(state => state.user.profile);
    const [spinnerStatus, setSpinnerStatus] = useState(false);

    const handleOnClickAddProject = () => {
        if (profileId) {
            setSpinnerStatus(true)

            addProject(profileId)(dispatch)
                .then(() => {
                    history.push("/projects/add");
                    hideModal();
                })
                .finally(() => setSpinnerStatus(false))
        }
    }

    return (
        <div className="designateAccountMain addNewProfile">
            <p className="modalFullScreen__title">Создание инвестиционного проекта</p>
            <ProjectsList hideModal={hideModal}/>
            <ProjectsList hideModal={hideModal} isDraft={true}/>
            <div className="modalFullScreen__btnGroup">
                <Button
                    color="primary"
                    className="account__btn account__btn_primary"
                    onClick={handleOnClickAddProject}
                >
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                        Добавить новый проект
                    </div>
                </Button>
                <Button
                    onClick={() => hideModal()}
                    color="primary"
                    className="account__btn account__btn_cancel"
                    outline
                >
                    Отмена
                </Button>
            </div>
        </div>
    )
}

export default AddProject;
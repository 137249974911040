import React from 'react';
import ProfileNotAccreditedNotice from './general/ProfileNotAccreditedNotice';
import ProfileAccreditedCheckingNotice from './general/ProfileAccreditedCheckingNotice';
import ProfileAccreditedRejectedNotice from './general/ProfileAccreditedRejectedNotice';
import InvestorQualifiedNotice from './investorIndiv/InvestorQualifiedNotice';
import ProfileAccreditedReturnedNotice
    from "@shared/components/account/accStatusNotice/general/ProfileAccreditedReturnedNotice";
import {accStatusKey} from "@utils/statusKey";
import ProfileIsBanned from "@shared/components/account/accStatusNotice/general/ProfileIsBanned";
import {usePresenter} from "./hooks/usePresenter";

const AccStatusNotice = ({profileDataEditingStatus, profileData = {}, checkAccreditation}) => {
    const {
        setFormEditStatus,
        role_key,
        legal_form_type,
        accreditation_status_key,
        isBanned,
        ban_comment
    } = usePresenter(profileData);

    if (isBanned) return <ProfileIsBanned comment={ban_comment || ''}/>

    switch (accreditation_status_key) {
        case accStatusKey.notAccredited:
            return <ProfileNotAccreditedNotice
                role={role_key}
                profileDataEditingStatus={profileDataEditingStatus}
                setFormEditStatus={setFormEditStatus}
            />
        case accStatusKey.checking:
            return <ProfileAccreditedCheckingNotice/>
        case accStatusKey.rejected:
            return <ProfileAccreditedRejectedNotice/>
        case accStatusKey.returned:
            return <ProfileAccreditedReturnedNotice
                profileDataEditingStatus={profileDataEditingStatus}
                setFormEditStatus={setFormEditStatus}
            />
        case accStatusKey.accredited:
            return legal_form_type === 'indiv'
                ? <InvestorQualifiedNotice/>
                : null
        default:
            return null
    }

}

export default AccStatusNotice;
import React from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import UploadField from "@shared/components/uploadField";
import {CalendarIcon} from "@shared/img/iconSvg";

const Other = ({data, setData, formError, setFormError, projectId}) => {
    const {
        name, characteristics, address, other_information, serial_number, date, documents, object_documents, images,
    } = data;

    return (
        <div className="pledge__form otherProperty">
            <InputText
                formError={formError}
                name="name"
                id="nameInput"
                onChange={setData}
                label="Наименование объекта"
                setFormError={setFormError}
                type="text"
                value={name || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                rows={2}
                type="textarea"
                onChange={setData}
                formError={formError}
                name="characteristics"
                id="characteristicsInput"
                value={characteristics || ''}
                setFormError={setFormError}
                validator={[{maxLength: 200}, {required: true}]}
                label="Качественные и количественные характеристики"
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                formError={formError}
                name="address"
                id="addressInput"
                onChange={setData}
                label="Адрес местонахождения"
                setFormError={setFormError}
                type="text"
                value={address || ''}
                validator={[{maxLength: 100}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Документы на объект</label>
                <UploadField
                    files={object_documents}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Документы на объект"
                    onChange={setData}
                    fileKey="object_documents"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    placeholder="макс 5 файлов"
                    required={true}
                />
            </div>
            <InputText
                formError={formError}
                name="serial_number"
                id="serialNumberInput"
                onChange={setData}
                label="Условный или идентификационный/серийный номер"
                setFormError={setFormError}
                type="text"
                value={serial_number || ''}
                validator={[{maxLength: 200}, {required: true}]}
                labelClass="requiredMark"
                containerClassName="addProject__fullInput"
            />
            <InputText
                name="date"
                id="dateInput"
                formError={formError}
                setFormError={setFormError}
                onChange={setData}
                label="Дата приобретения права собственности на объект"
                type="date"
                value={date || ''}
                validator={[{required: true}]}
                labelClass="requiredMark"
                inputWithDesc
                inputWithDescValue={<CalendarIcon/>}
                containerClassName="addProject__middleInput addProject__dateInput date-picker"
            />
            <InputText
                rows={2}
                type="textarea"
                onChange={setData}
                formError={formError}
                name="other_information"
                value={other_information || ''}
                id="otherInformationInput"
                setFormError={setFormError}
                validator={[{maxLength: 500}]}
                label="Иные сведения"
                containerClassName="addProject__fullInput"
            />
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label>Иные документы</label>
                <UploadField
                    files={documents}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Иные документы"
                    onChange={setData}
                    fileKey="documents"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png, .pdf"
                />
            </div>
            <div className="inputText__wrapper addProject__fullInput addProject__inputGreen">
                <label className="requiredMark">Фото залога</label>
                <UploadField
                    files={images}
                    edit={true}
                    parentType="project_id"
                    parentId={projectId}
                    title="Фото залога"
                    onChange={setData}
                    fileKey="images"
                    multiple={true}
                    maxFiles={5}
                    formError={formError}
                    setFormError={setFormError}
                    fileType="project_property"
                    accept=".jpeg, .png"
                    required={true}
                />
            </div>
        </div>
    )
}

export default Other;
import React, {useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {ProfilesOuterService} from '@services/profiles.service';
import {getProfiles} from "@actions/profilesActions";
import {isBorrower} from "@utils/profile";
import InputText from "@shared/components/form/profileForm/InputText";
import classNames from 'classnames';
import classnames from 'classnames';
import {AuthService} from "@services/auth.service";
import {userInfo} from "@actions/userActions";

const AddNewProfile = ({hideModal}) => {
    let data;
    const dispatch = useDispatch();
    const [legalForm, setLegalForm] = useState(null);
    const {profiles} = useSelector(state => state.profiles);
    const [spinnerStatus, setSpinnerStatus] = useState(false);
    const [fio, setFio] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [fioRequired, setFioRequired] = useState(false);
    const [companyNameRequired, setCompanyNameRequired] = useState(false);
    const [legalFormRequired, setLegalFormRequired] = useState(false);


    const addProfileBtnClass = classNames({
        "account__btn": true,
        "account__btn_primary": true,
        lightDisabledBtn: !legalForm || !fio
    })

    // Активность кнопок legalForm
    const getLegalFormClass = type => {
        return classnames({
            'from__custom-radio-btn': true,
            active: legalForm === type,
            requiredFill: !legalForm && legalFormRequired
        })
    }

    const inputOnChange = ({target}, type) => {
        switch (type) {
            case 'companyName':
                if (companyNameRequired) setCompanyNameRequired(false);
                setCompanyName(target.value);
                break;
            case 'fio':
                if (fioRequired) setFioRequired(false);
                setFio(target.value);
                break;
            default:
                break;
        }
    }

    const checkSelectedLegalForm = type => {
        if (profiles.length) return profiles.some(item => item.legal_form_type === type)

        return false
    }

    const handleSendForm = () => {
        // Выделить обязательные поля
        if (!legalForm) {
            setLegalFormRequired(true)
            setTimeout(() => setLegalFormRequired(false), 1000)
            return;
        }
        if (legalForm === 'entity' && !companyName) {
            setCompanyNameRequired(true);
            return;
        }
        if (legalForm && !fio) {
            setFioRequired(true);
            return;
        }


        if (legalForm === 'entity') {
            data = {legal_form_type: legalForm, company_name: companyName, fio}
        } else {
            data = {legal_form_type: legalForm, fio}
        }

        setSpinnerStatus(true);

        ProfilesOuterService.create(data)
            .then(res => {
                // Сразу выбрать созданный профиль
                AuthService.refreshTokens(res.data.data.id).then(() => {
                    getProfiles()(dispatch)
                    userInfo(dispatch)
                })
            })
            .catch(() => {
            })
            .finally(() => {
                setSpinnerStatus(false);
                hideModal()(dispatch);
            })
    }

    return (
        <div className="designateAccountMain addNewProfile">
            <p className="modalFullScreen__title">
                Добавить профиль
            </p>
            <div className="form__form-group form__btn-group addNewProfile__items">
                {
                    isBorrower()
                        ? ''
                        : <button
                            name="indiv"
                            className={getLegalFormClass("indiv")}
                            onClick={() => setLegalForm("indiv")}
                            disabled={checkSelectedLegalForm('indiv')}
                        >
                            <span>физ. лицо</span>
                        </button>
                }
                <button
                    name="entity"
                    className={getLegalFormClass("entity")}
                    onClick={() => setLegalForm("entity")}
                >
                    <span>юр. лицо</span>
                </button>
                <button
                    name="ie"
                    className={getLegalFormClass("ie")}
                    onClick={() => setLegalForm("ie")}
                    disabled={checkSelectedLegalForm('ie')}
                >
                    <span>ип</span>
                </button>
            </div>
            <div className="addNewProfile__form">
                {
                    legalForm === 'entity'
                        ? <InputText
                            id="companyName"
                            name="companyName"
                            onChange={e => inputOnChange(e, 'companyName')}
                            label="Наименование"
                            value={companyName}
                            placeholder='ООО "Таганрог Север-Аллюминий"'
                            labelClass="requiredMark"
                            validator={[{required: true}]}
                            className="addNewProfile__input"
                            resetValidate={companyNameRequired}
                        />
                        : ''
                }

                <InputText
                    id="fio"
                    name="fio"
                    onChange={e => inputOnChange(e, 'fio')}
                    label="ФИО"
                    value={fio}
                    placeholder="Иванов Иван Иванович"
                    labelClass="requiredMark"
                    validator={[{required: true}]}
                    className="addNewProfile__input"
                    resetValidate={fioRequired}
                />
            </div>
            <p className="modalFullScreen__desc">
                Для того, чтобываш новый профиль получил доступ к платформе, его необходимо аккредитовать
            </p>
            <div className="modalFullScreen__btnGroup">
                <Button
                    color="primary"
                    className={addProfileBtnClass}
                    onClick={handleSendForm}
                >
                    <div className="btn-spinner-group">
                        <Spinner className={`btn-spinner ${spinnerStatus ? 'btn-spinner-visible' : ''}`}/>
                        Создать новый профиль
                    </div>
                </Button>
                <Button
                    onClick={() => hideModal()(dispatch)}
                    color="primary"
                    className="account__btn account__btn_cancel"
                    outline
                >
                    Отмена
                </Button>
            </div>
        </div>
    )
}

export default AddNewProfile;
import React from 'react';
import InputText from "@shared/components/form/profileForm/InputText";
import classnames from "classnames";

const QualifiedFormGuarantee = ({
                                    data,
                                    setData,
                                    checkRequired,
                                    setCheckRequired,
                                    viewMode = false,
                                    applicationData
                                }) => {
    const confirmBtnClass = classnames({
        noMiddleName: true,
        errorInput: checkRequired
    })

    const guaranteeClass = classnames({
        qualifiedInvestor__guarantee: true,
        qualifiedInvestor__conditions_item: true,
        qualifiedInvestor__guarantee_view: viewMode
    })

    const inputOnChange = e => {
        const newData = {...data};
        newData.value.guarantee.selected = e.target.value;

        if (checkRequired) setCheckRequired(false);

        setData(newData);
    }

    return <div className={guaranteeClass}>
        {
            viewMode
                ? <p>{applicationData?.text || ''}</p>
                : <InputText
                    type="checkbox"
                    label={data?.value?.guarantee?.text}
                    className={confirmBtnClass}
                    onChange={inputOnChange}
                    value={data?.value?.guarantee.selected}
                />
        }
    </div>

}

export default QualifiedFormGuarantee;
import React from "react";

const YoutubeEmbed = ({width = 240, height = 135, videoLink = ''}) => {
    const embedId = videoLink.split('.be/');

    return <div className="youtubeEmbed" style={{height: `${height}px`, width: `${width}px`}}>
        <iframe
            width={width}
            height={height}
            src={`https://www.youtube.com/embed/${embedId[1]}`}
            frameBorder="0"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
}

export default YoutubeEmbed;


import React, {useState} from 'react';
import MediaPreview from "@shared/components/presentation/MediaPresentation/MediaPreview";
import classnames from "classnames";
import MediaDetail from "@shared/components/presentation/MediaPresentation/MediaDetail";


const MediaPresentation = React.memo(({files, className}) => {
    const [detailImageData, setDetailImageData] = useState(null)
    const mediaPresentationClass = classnames({
        mediaPresentation: true,
        [className]: className
    })

    return (
        <div className={mediaPresentationClass}>
            <div className="mediaPresentation__list">
                {
                    files.length
                        ? files.map(item => (
                            <MediaPreview
                                onClick={setDetailImageData}
                                key={item.id}
                                file={item}
                                size="sm"
                            />
                        ))
                        : null
                }
            </div>
            {
                detailImageData
                    ? <div className="mediaPresentation__detail">
                        <MediaDetail data={detailImageData}/>
                    </div>
                    : null
            }
        </div>
    )
})

export default MediaPresentation;

import React from 'react';
import UploadField from "@shared/components/uploadField";

const QualificationsSelectedConditions = ({data}) => {
    const conditionsRender = () => {
        const selectedData = data.filter(item => item.selected);

        return selectedData.map((item, idx) => (
            <div key={idx} className="qualification__condition_item">
                <p className="qualification__condition_name">{`По условию ${idx + 1}`}</p>
                <p className="qualification__condition_desc">{item.text}</p>
                <div className="qualification__condition_files">
                    {
                        item?.items?.length && item?.items.map((elem, idx) => {
                            if (elem?.files?.length) {
                                return <div key={idx} className="qualification__condition_file">
                                    <label>{elem.label}</label>
                                    <UploadField
                                        files={elem.files}
                                        edit={false}
                                        multiple={true}
                                        maxFiles={10}
                                        fileKey="files"
                                        fileType="qualification"
                                        accept=".jpeg, .png, .pdf"
                                    />
                                </div>
                            }
                            return null
                        })
                    }
                </div>
            </div>
        ))
    }

    return data.length
        ? <div className="dataCard">
            <p className="dataCard__title">Выбранные клиентом уловия и документы по условиям</p>
            <div className="dataCard__inner">
                {conditionsRender()}
            </div>
        </div>
        : null
}

export default QualificationsSelectedConditions;
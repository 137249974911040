import {Http} from '@services/http.init';
import store from "@redux/storeConfig/store";
import history from "@utils/history"
import {clear, get, remove, set} from "utils/storage";
import {SET_ATOKEN_EXP_DATE, SET_IS_AUTH} from "@actions/authActions";
import {ErrorWrapper, ResponseWrapper} from "@utils/wrapper";

import axios from "axios";

const instanceAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    accept: 'application/x.incrowd.v1+json',
    headers: {'Accept-Language': 'ru'}
})

export class AuthService {

    static expired_time = 24 * 60 * 60 / 2
    static debounceRefreshTokens = this._debounce(() => {
        return this.refreshTokens()
    }, 100)

    /**
     * Авторизация
     * @param email{string}
     * @param password{string}
     * @returns {Promise<ResponseWrapper>}
     */
    static async login(email, password) {
        try {
            const response = await instanceAxios.post('/clients/web/login', {email, password})
            setAuthData({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                exp: response.data.expires_in + getNowTime()
            })
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async signup(email, type = "register_request") {
        try {
            const response = await instanceAxios.post('/confirmations/send/email', {email, type})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async checkRegisterEmailCode(email, code, type = "register_request") {
        try {
            const response = await instanceAxios.post('/confirmations/check/email', {email, code, type})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async confirmPhone(phone) {
        try {
            const response = await instanceAxios.post('/confirmations/send/phone', {phone, type: "register_request"})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async checkRegisterPhoneCode(phone, code) {
        try {
            const response = await instanceAxios.post('/confirmations/check/phone', {
                phone,
                code,
                type: "register_request"
            })
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async createAccount(data) {
        try {
            const response = await instanceAxios.post('/register/with-profile', data)
                .then(() => {
                    return this.login(data.email, data.password)
                })
            clearRegisterData()
            setAuthData({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                exp: response.data.expires_in + getNowTime()
            })
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async resetPassGetToken(email, link) {
        try {
            const response = await instanceAxios.post('/password/forgot', {email, reseturl: link})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async checkToken(token) {
        try {
            const response = await instanceAxios.post('/auth/reset/check-token', {token})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async changePassword(token, password, password_confirmation) {
        try {
            const response = await instanceAxios.post('/password/reset', {token, password, password_confirmation})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async userInfo(params = {}) {
        try {
            const response = await new Http({auth: true}).get('/user/profile', {params})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async refreshTokens(profile_id) {
        try {
            const response = await instanceAxios.post('/clients/web/refresh', {
                refresh_token: get('refreshToken'),
                profile_id
            })

            setAuthData({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                exp: response.data.expires_in + getNowTime()
            })
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            _resetAuthData()
            history.push('/login')
            throw new ErrorWrapper(error)
        }
    }

    /**
     * https://stackoverflow.com/questions/35228052/debounce-function-implemented-with-promises
     * @param inner
     * @param ms
     * @returns {function(...[*]): Promise<unknown>}
     * @private
     */
    static _debounce(inner, ms = 0) {
        let timer = null
        let resolves = []

        return function () {
            clearTimeout(timer)
            timer = setTimeout(() => {
                const result = inner()
                resolves.forEach(r => r(result))
                resolves = []
            }, ms)

            return new Promise(resolve => resolves.push(resolve))
        }
    }

    static hasRefreshToken() {
        return Boolean(localStorage.getItem('refreshToken'))
    }

    static isAccessTokenExpired() {
        const accessToken = +(store.getState().auth.accessTokenExpDate)
        const accessTokenExpDate = accessToken - this.expired_time
        const nowTime = Math.floor(new Date().getTime() / 1000)

        return nowTime <= accessTokenExpDate
    }

    static mustRunRefresh() {
        const accessToken = +(store.getState().auth.accessTokenExpDate)
        const accessTokenExpDate = accessToken - this.expired_time
        const nowTime = Math.floor(new Date().getTime() / 1000)
        return accessTokenExpDate <= nowTime && nowTime <= accessToken
    }

    static getBearer() {
        const accessToken = get("accessToken");

        if (accessToken) return `Bearer ${accessToken}`;

        return null
    }

    // Проверить есть ли token / exp
    static checkTokenAndExp() {
        return !!(get("accessToken") && get("exp"))
    }

    static redirectToLogin(message = '') {
        //history.push(`/login?message=${message}`)
        _resetAuthData()
        history.push('/login')
        window.location.reload()
    }
}

/**
 ******************************
 * @private_methods
 ******************************
 */
export function setAuthData({accessToken, refreshToken, exp} = {}) {
    set('accessToken', accessToken);
    set('refreshToken', refreshToken);
    set('exp', exp)
    store.dispatch({type: SET_ATOKEN_EXP_DATE, payload: exp})
    store.dispatch({type: SET_IS_AUTH, payload: true})
}

function _resetAuthData() {
    // reset userData in store
    store.dispatch({type: SET_ATOKEN_EXP_DATE, payload: null})
    // Очистить LocaleStorage
    clear()
}

export function getNowTime() {
    return Math.floor(new Date().getTime() / 1000);
}

function clearRegisterData() {
    remove('email')
    remove('email_code')
    remove('phone')
    remove('phone_code')
    remove('role')
    remove('legal_form_type')
    remove('password')
    remove('fio')
    remove('without_patronymic')
    remove('confidentiality_acceptance')
}

